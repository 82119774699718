import { Button, Col, Row, Skeleton, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";

const ManageWelcome = (props) => {
    const [button1, setBtutton1] = useState(false)
    const [description, setDesc] = useState("");
    const [switchOnOf, setSwitch] = useState(false);


    useEffect(() => {
        setSwitch(props.channel?.send_welcome);
        setDesc(props.channel?.welcome_message);
    }, [props.channel]);

    const saveMessage = async () => {
        const payload = {
            welcome_message: description,
            send_welcome: switchOnOf
        }
        props.updateChannel(payload, "welcome");
    }



    return (
        <Row>
            <Col span={24} className='padding40'>
                {
                    props?.loader ? <Skeleton active></Skeleton> :

                        <Row>
                            <Col xs={{ span: 19 }} lg={{ span: 19 }} md={{ span: 18 }}>
                                <p className="fs15 fw500">Send welcome message to new members</p>
                                <p className="fs12 fw300 md5 ">Create a custom message that new members will see as a
                                    prompt after joining and /or as a direct message to their in chat.</p>
                            </Col>
                            <Col xs={{ span: 5 }} lg={{ span: 5 }} md={{ span: 5 }} className='text-right'>
                                <Switch checked={switchOnOf} onChange={() => { }} />
                            </Col>
                            <Col span={24} className='text-right md20'>
                                <TextArea value={description} onChange={(e) => (setBtutton1(true), setDesc(e.target.value))} rows={4} placeholder="Enter message ..." showCount maxLength={500} />
                            </Col>
                            <Col span={24} className='text-right md70 mb-5'>
                                {
                                    button1 && <Button onClick={() => saveMessage()} type="primary"> Save Changes</Button>
                                }

                            </Col>
                        </Row>
                }
            </Col>
        </Row>
    );
}
export default ManageWelcome;