import { Col, Row, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import DetailSection1 from "./DetailSection1";
import DetailSection2 from "./DetailSection2";
import { useDispatch } from "react-redux";
import { createAction } from "redux-actions";

const PodcastDetails = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const params = useParams();

    useEffect(() => {
        getPodcastDetails();
    }, [])


    const getPodcastDetails = useCallback(async() => {
        setLoading(true);
        await axios({
            method: 'get',
            url:  `market/feed/detail?id=${params['id']}`,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            }
        }).then((res) => {
            setLoading(false);
            const data = res.data.data;
            setData(data);
            updateState(data);

        }).catch((err) => {
            setLoading(false);
        });
    },[params]);

    const updateState = (data) => {
        const PODCAST_DETAIL = createAction('PODCAST_DETAIL');
        dispatch(PODCAST_DETAIL(data.podcast.episodes[0]));
    }
    const updateStatePlay = () => {
        const PODCAST_PLAY = createAction('PODCAST_PLAY');
        dispatch(PODCAST_PLAY(true));
    }
    return (
        <Row>
            <Col span={24} style={{ backgroundColor: '#F5F7FB' }}>
                <Row gutter={24}>
                    <Col span={6}></Col>
                    <Col span={12}>
                        {
                            loading ? <><Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /></> :
                                data && data?.entity_type === "Podcast" ?
                                    <DetailSection1 data={data} /> : <>Something went wrong</>

                        }
                    </Col>
                    <Col span={6}>
                        {
                            loading ? <><Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /></> :
                            data && data?.entity_type === "Podcast" ?
                            <DetailSection2 data={data} /> : <>Something went wrong</>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default PodcastDetails;