import { TwitterOutlined } from "@ant-design/icons";
import { Button, Col, Divider, message, Row, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import actions from "../../../store/actions/index";
import IconsView from "../../utils/IconsView";


const ManageTopic = (props) => {
    const [topicData, setTopicData] = useState([]);
    const [selected, setSelected] = useState([])
    const [changed, setchanged] = useState(false)


    const deleteItem = (e) => {
        var newArr = [...selected]
        const _new = newArr.filter((item) => item.category_id !== e.category_id);
        setSelected(_new);
        setchanged(true)
    };

    useEffect(() => {
        if (props.topicLoaded === false){
            props.channelCategories();
        }
        if (props?.categories !== undefined){
            setSelected(props.categories)
        }

    }, []);

    useEffect(() => {
        if (props._category) {
            setTopicData(props._category)
            props.setTopicLoaded(true)
        }
    }, [props._category]);

    const onSelect = (item) => {
        var newArr = [...selected]
        const _new = newArr.filter((_item) => _item.category_id === item.category_id);
        if (_new.length > 0){
            deleteItem(item)
        }else{
            newArr.push(item)
        setSelected(newArr)
        setchanged(true)
    }
        
    }

    const onSave=async()=> {
        await props.updateChannel(selected.map((i)=>{return i.category_id}), "category")
        // toast.success("Saved")
        // setTimeout(()=>{}, 1000);
        // window.location.reload()
    }

    return (
        <Row>
            <Col span={24} className='padding30'>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 20 }}>
                        <p className="fs16 fw500">Topics</p>
                        <p className="fs12 fw400 md5 ">Topics are a great way to show users what your group is about before they join in search results or when they're exploring.</p>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} className='text-right'>
                        <Divider />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 20 }}>
                        <p className="fs14 fw500">Selected</p>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 20 }} className='topic-card topic-tag md10'>
                        <Row>
                            <Col span={24}>

                                {
                                    selected.map((item, i) => {
                                        return <Tag closable key={i} className='my-2 ms-2' onClose={() => deleteItem(item)}>
                                            <Space >
                                                <IconsView icon={item?.icon} size={22} color={'#3B6BE3'} />
                                                <p className="fs14 fw300"> {item?.name} </p>
                                            </Space>
                                        </Tag>
                                    })
                                }


                            </Col>


                        </Row>
                    </Col>
                    <Col span={24} className='text-right md35'>
                        {
                            changed && <Button type="primary" onClick={()=>onSave()}>Save Changes</Button>
                        }
                        
                    </Col>
                    <Col span={24} className='text-right md20'>
                        <Divider />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 20 }} className=' md10 topic-tag'>
                        <Row>
                            <Col span={24}>


                                {
                                    topicData.map((item, i) => {
                                        return <Tag key={i} className='md10 ms-2 cursor' onClick={() => onSelect(item)}>
                                            <Space >
                                                <IconsView icon={item?.icon} size={22} color={'#3B6BE3'} />
                                                <p className="fs14 fw300"> {item?.name} </p>
                                            </Space>
                                        </Tag>
                                    })
                                }


                            </Col>


                        </Row>
                    </Col>

                </Row>
            </Col>
        </Row>
    );
}
const mapStateToProps = (state) => ({

    _category: state.profile.channel_category,

});
const mapDispatchToProps = (dispatch) => ({
    channelCategories: (v) => dispatch(actions.channelCategories(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ManageTopic)
);