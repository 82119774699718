import { Button, Col, Pagination, Radio, Row, Skeleton, Space } from "antd";
import { ArrowLeft, CalendarPlus } from "@phosphor-icons/react";
import React, { useEffect } from "react";

import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { useHistory, withRouter } from "react-router";
import { useState } from "react";
import IconsView from "../utils/IconsView"
import MyHubNewsletter from "./MyHubNewsletter";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import MyHubNotes from "./MyHubNotes";
import MyHubInSight from "./MyHubInSight";
import MyHubResearchReport from "./MyHubResearchReport";
import MyHubPoll from "./MyHubPoll";
import MyHubPodcast from "./MyHubPodcast";
import BlankState from "../BlankState";

import Toast from "../utils/Toast";
import AllEvents from "../Event/AllEvents";


const MyHubContent = (props) => {
    const [dataFeed, setDataFeed] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loadFeed, setLoadFeed] = useState(false);
    const [entity_type, setentity_type] = useState(0);
    const [eType, setEType] = useState(null);

    const [allEntities, setAllEntities] = useState([]);
    const [total, setTotal] = useState(0);
    const [defaultValue, setdefaultValue] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const history = useHistory();


    useEffect(() => {
        if (props._my_feed_count.length > 0) {
            setDataFeed(props._my_feed_count);
            setLoader(false);
        }

    }, [props._my_feed_count])

    async function getData() {
        await props.getFeedCount();
    }

    useEffect(() => {
        setLoader(true);
        getData();
    }, [])



    const openFeed = async (item) => {
        setEType(item);
        if (item.entity_type === 2 || item.entity_type === 3 || item.entity_type === 4 || item.entity_type === 6 || item.entity_type === 10 || item.entity_type === 7) {
            await props.allNewsLetter({ page: 1, published: true, entity: item.entity_type, offset: 0 });
        }
        setentity_type(item.entity_type);
        setLoadFeed(true);
    }

    function deleteFeed(id) {
        axios({
            method: 'delete',
            url:  'market/feed/delete?id=' + id,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        }).then(function (res) {
            props.allNewsLetter({ page: 1, published: true, entity: entity_type })
        });
    }

    async function createNotes(e) {
        await axios({
            method: 'post',
            url:  'market/feed/note',
            data: {
                title: "Untitled",
                meta_title: "",
                tags: [],
            },
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        })
            .then((response) => {
                window.location.href = ("/notes?id=" + response.data.data.id)
            }).error((error) => {
                Toast(error.response.data.message,"error")
            });;
    }

    const FeedItem = () => {
        return <>
            {
                entity_type === 2 ? <>
                    <Col span={24}>
                        <Row>
                            <Col lg={19} xs={15} className='md10'>

                                <Radio.Group defaultValue={defaultValue} style={{ display: "inline-flex", marginBottom: "20px" }}
                                    buttonStyle="solid" onChange={(evt) => changeEntity("2", evt.target.value)}>

                                    <Radio.Button
                                        value={true}
                                        style={{ width: "max-content", backgroundColor: '#E2E2E2' }}
                                        className="margin-right" >
                                        Published
                                    </Radio.Button>
                                    <Radio.Button
                                        value={false}
                                        className="margin-right"
                                        style={{ width: "max-content" }} >
                                        Draft  </Radio.Button>
                                </Radio.Group>

                            </Col>
                            <Col lg={5} xs={9} className='text-right md10'>
                                <Button type="btn" onClick={() => createNewsLetter()} className="btn create-newsl-btn"> {windowWidth <= 414 ? "Create New" : "Create Newsletter"} </Button>

                            </Col>

                        </Row>

                        <MyHubNewsletter details={(record) => details(record)} data={allEntities} loader={loader} updateState={updateState} />
                        {
                            total > 10 && !loader && <Pagination pageSize={10} total={total} className={"md20"} style={{ marginLeft: "20px", marginBottom: "20px" }} onChange={(evt) => getNewData(evt, defaultValue)}></Pagination>
                        }</Col></> :
                    entity_type === 3 ? <Col span={24} className="md10">
                        <MyHubInSight details={(record) => details(record)} deleteFeed={(id) => deleteFeed(id)} data={allEntities} loader={loader} updateState={updateState} />
                        {
                            total > 10 && !loader && <Pagination responsive pageSize={10} total={total} className={"md20"} style={{ marginLeft: "20px", marginBottom: "20px" }} onChange={(evt) => getNewData(evt, true)}></Pagination>
                        }

                    </Col> :
                        entity_type === 4 ? <Col span={24} className='md10'>
                            <MyHubResearchReport details={(record) => details(record)} deleteFeed={(id) => deleteFeed(id)} data={allEntities} loader={loader} updateState={updateState} />
                            {
                                total > 10 && !loader && <Pagination pageSize={10} total={total} className={"md20"} style={{ marginLeft: "20px", marginBottom: "20px" }} onChange={(evt) => getNewData(evt, true)}></Pagination>
                            }
                        </Col> :
                            entity_type === 7 ? <Col span={24} className='md30'>
                                <MyHubPodcast details={(record) => details(record)} data={allEntities} deleteFeed={(id) => deleteFeed(id)} loader={loader} updateState={updateState} />
                                {
                                    total > 10 && !loader && <Pagination pageSize={10} total={total} className={"md20"} style={{ marginLeft: "20px", marginBottom: "20px" }} onChange={(evt) => getNewData(evt, true)}></Pagination>
                                }
                            </Col> :
                                entity_type === 6 ? <Col span={24} className='md10'>
                                    <Radio.Group
                                        defaultValue={defaultValue} style={{ display: "inline-flex", marginBottom: "21px" }}
                                        buttonStyle="solid" onChange={(evt) => changeEntity("6", evt.target.value)}>
                                        <Radio.Button
                                            value={true}
                                            className="margin-right"
                                            style={{ width: "max-content" }} >
                                            Active  </Radio.Button>
                                        <Radio.Button
                                            value={false}
                                            style={{ width: "max-content" }}
                                            className="margin-right" >
                                            Expired
                                        </Radio.Button>
                                    </Radio.Group>
                                    <MyHubPoll deleteFeed={(id) => deleteFeed(id)} data={(allEntities)} loader={loader} updateState={updateState} />

                                    {
                                        total > 10 && !loader && <Pagination pageSize={10} total={total} className={"md20"} style={{ marginLeft: "20px", marginBottom: "20px" }} onChange={(evt) => getNewData(evt, true)}></Pagination>
                                    }
                                </Col> :
                                    entity_type === 10 ? <>
                                        <Col span={24}>
                                            <Row>
                                                <Col xs={{ span: 24 }} lg={{ span: 24 }} className="text-right">
                                                    <Button onClick={() => createNotes()} className="primary-light-btn" type="text">Create New</Button>
                                                </Col>

                                            </Row>


                                            <MyHubNotes key={"5"} details={(record) => details(record)} data={(allEntities)} deleteFeed={(id) => deleteFeed(id)} loader={loader} updateState={updateState}></MyHubNotes>

                                            {
                                                total > 10 && !loader && <Pagination pageSize={10} total={total} className={"md20"} style={{ marginLeft: "20px", marginBottom: "20px" }} onChange={(evt) => getNewData(evt, true)}></Pagination>
                                            }
                                        </Col>
                                    </> :
                                        entity_type === 101 ? <Col span={24} className='md10'>
                                            <AllEvents/>
                                        </Col> : <></>
            }
        </>
    }

    useEffect(() => {
        if (props?._allnewsletter && props?._allnewsletter?.length !== 0) {
            setAllEntities(props._allnewsletter?.data)
            setLoader(false);
            setTotal(props._allnewsletter.total)
        }
        // else {
        //     setLoader(false)
        // }
    }, [props?._allnewsletter])

    function changeEntity(key, type) {
        setLoader(true)
        setAllEntities([])
        setdefaultValue(type)
        props.allNewsLetter({ offset: 0, published: type, entity: key })
    }

    function details(record) {
        if (record.entity_type === "Notes") {
            history.push("/note/" + record.entity_uuid)
        } else {
            history.push("/feed-view/" + record?.entity_uuid)
        }
    }

    function updateState() {
        
        props.allNewsLetter({ offset: 0, published: defaultValue, entity: entity_type })
    }

    function getNewData(evt, pagination) {
        props.allNewsLetter({ offset: (evt - 1) * 10, published: pagination, entity: entity_type })
    }

   async function createNewsLetter() {
       await axios({
            method: 'post',
            url:  'market/feed/newsletter',
            data: {
                title: "Untitled",
                meta_title: ""
            },
            headers: {
                Authorization: Cookies.get("Logintoken"),

            },
        }).then(function (response) {
            history.push(`/newsletter/${response.data.data.uid}`)
        });
    }

    return (
        <Row>
            {
                loadFeed ? <Col span={24}>
                    <div className="hub-content md10" style={{ background: eType?.color + "14" }}>
                        <Button className="p-0" type={"text"} icon={<ArrowLeft size={18} style={{ marginRight: "5px" }} />}
                            onClick={() => (setLoadFeed(false), getData())}></Button>

                        <Space className="float-right">

                            <IconsView icon={eType?.icon} size={32} color={eType?.color} weight="fill" />
                            <p className="fs14 fw600" style={{ color: eType?.color }}> {eType?.entity} </p>
                        </Space>


                    </div>



                    <Row className="md10">
                        {loadFeed && FeedItem()}
                    </Row>

                </Col> :
                    <Col span={24} className="">
                        {
                            loader ?
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Skeleton active></Skeleton>
                                    </Col>
                                    <Col span={12}>
                                        <Skeleton active></Skeleton>
                                    </Col>
                                    <Col span={12}>
                                        <Skeleton active></Skeleton>
                                    </Col>
                                    <Col span={12}>
                                        <Skeleton active></Skeleton>
                                    </Col>
                                </Row> :

                                <Row gutter={24} className='mb-3'>
                                    {
                                        dataFeed.map((item, i) => {
                                            return <Col xs={{ span: 12, offset: 0 }} lg={{ span: 12 }} sm={{ span: 12, offset: 0 }} key={i} >
                                                <div className="hub-content md25 cursor " onClick={() => openFeed(item)}
                                                    style={{ background: item?.color + "14" }}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <p className="fs14 fw500" style={{ color: item?.color }}> {item?.entity} </p>
                                                        </Col>
                                                        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }} sm={{ span: 24, offset: 0 }} className="md25">
                                                            <Row >
                                                                <Col xs={{ span: 14, offset: 0 }} lg={{ span: 12 }} sm={{ span: 12, offset: 0 }} className="md15">
                                                                    <p className="fs12 fw300">{item?.published > 0 ? `${item?.published} published` : "Create new"} {(item?.entity_type === 2 || item?.entity_type === 4) && item?.draft > 0 ? `| ${item?.draft} draft` : ""}</p>
                                                                </Col>
                                                                <Col xs={{ span: 10, offset: 0 }} lg={{ span: 12 }} sm={{ span: 12, offset: 0 }} className='text-right md5'>
                                                                    <IconsView icon={item?.icon} size={32} color={item?.color} weight="light" />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        })
                                    }
                                    {
                                        dataFeed.length === 0 ? <Col span={24} className="md60">
                                        <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={250} />
                                        </Col>:
                                        <Col xs={{ span: 12, offset: 0 }} lg={{ span: 12 }} sm={{ span: 12, offset: 0 }} >
                                        <div className="hub-content md25 cursor "  onClick={() => openFeed({entity_type:101, entity: "Events", icon: 'CalendarPlus',color:'blue'})}
                                            style={{ background: "14" }}>
                                            <Row>
                                                <Col span={24}>
                                                    <p className="fs14 fw500" style={{ color: 'blue' }}> Events </p>
                                                </Col>
                                                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }} sm={{ span: 24, offset: 0 }} className="md25">
                                                    <Row >
                                                        <Col xs={{ span: 14, offset: 0 }} lg={{ span: 12 }} sm={{ span: 12, offset: 0 }} className="md15">
                                                            <p className="fs12 fw300">My Events | Bookings</p>
                                                        </Col>
                                                        <Col xs={{ span: 10, offset: 0 }} lg={{ span: 12 }} sm={{ span: 12, offset: 0 }} className='text-right md5'>
                                                            <CalendarPlus size={32} color={'blue'} weight="light" />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    }
                                </Row>
                        }
                    </Col>

            }

        </Row>
    );
}

const mapStateToProps = (state) => ({
    _my_feed_count: state.newsletter.my_feed_counts,
    _allnewsletter: state.newsletter.allnewsletter,
});
const mapDispatchToProps = (dispatch) => ({
    getFeedCount: (v) => dispatch(actions.getMyFeedCount()),
    allNewsLetter: (v) => dispatch(actions.allNewsLetter(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MyHubContent)
);
