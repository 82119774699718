import React, { useEffect, useState } from "react";
import "./Newsletter.scss";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { Input, Space, Select, Col, Row, Skeleton, Tooltip } from "antd";
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Cookies from "js-cookie"
import Modal from "antd/lib/modal/Modal";
import axios from "../../store/axios";

import _ from "lodash";
import { ArrowLeft, ArrowRight, CaretDown, CloudArrowUp, CloudCheck } from "@phosphor-icons/react";
import request from "../../store/request";

import { useHistory, useParams } from "react-router";
import NewsletterEditor from "../Editor/NewsletterEditor";
import ContentAdvancedFeature from "../utils/ContentAdvancedFeature";

import Toast from "../utils/Toast";
const { Option } = Select


const WriteNewsletter = (props) => {
  const params = useParams();
  const UUId = params['uuid'];
  const [content, setContent] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [title, setTitle] = useState("");
  const [final, setFinal] = useState("");
  const [edit, setEdit] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const [Totaltags, setTotaltags] = useState([]);
  const [news_id, setNews_id] = useState(null);
  const [timer, setTimer] = useState(true);
  const [advancedFeature, setAdvancedFeature] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [story, setStory] = useState("");
  const [importLoader, setImportLoader] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [children, setChildren] = useState([]);

  const [loader, setLoader] = useState(true);
  const [autoSaving, setAutoSaving] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [html, sethtml] = useState("");
  const [advancedPayload, setAdvancedPayload] = useState({});
  const history = useHistory();

  useEffect(() => {
    setLoader(true);
    props.NewsletterDetails({
      id: UUId,
    });
  }, []);

  useEffect(() => {
    if (props._newsletterdetails.length !== 0 && isUpdated === false) {
      const data = props?._newsletterdetails
      setEdit(data.edit);
      setFinal(data.description);
      setMetaTitle(data.meta_title);
      setTitle(data.title);
      setContent(data.description);
      setNews_id(data.entity_id);
      setIsUpdated(true)
      setLoader(false);
    }
  }, [props]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCallAPI((t) => !t);
    }, 15000);
    setTimer(interval);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    myTimer();
  }, [callAPI]);

  const myTimer = async () => {
    if (news_id !== "" && news_id !== null) {
      setAutoSaving(true);
      await props.CreateNewsletter({
        id: news_id,
        title: title,
        content: final,
        meta_title: metaTitle,
      })
      setAutoSaving(false);
    }

  }

  const handleHTMLChange = (e) => {
    sethtml(e);
  }

  const handleImageUpload = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (data) => {
        // @ts-ignore
        resolve(data.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const CreateNewsletter = async () => {
    if (
      title === "" || final === null || final === ""
    ) {
      Toast("Add some story","error");
      return;
    }
    clearInterval(timer);
    setTagsLoading(true);
    await props.getTags({ text: html });
    setTagModal(true);
    setTagsLoading(false);
  }

  useEffect(() => {
    if (props._tags.length !== 0) {
      setTotaltags(props._tags);
    }
  }, [props._tags]);

  const FinalCreateNewsletter = (data, type) => {
    props.CreateNewsletter({
      title: title,
      content: final,
      meta_title: metaTitle,
      image_url: sessionStorage.getItem('image'),
      tags: data === true ? Totaltags : [],
      id: news_id,
      publish: type,
      ...advancedPayload
    }).then(() => {
      sessionStorage.removeItem("image");
      setFinal(null)
      setTitle("Untitled");
      Toast("Published Successfully","success")
      history.push("/dashboard");
      return
    });
  }

  const EditNewsletter = () => {
    clearInterval(timer)
    props.EditNewsletter({
      id: news_id,
      title: title,
      content: final,
      metaTitle: metaTitle,
      ...advancedPayload
    })
      .then(() => {
        history.push("/my-hub");
      });
  }

  const handelSearch = (evt) => {
    axios({
      method: 'get',
      url:  'market/tag/search?key=' + evt,

      headers: {
        Authorization: Cookies.get("Logintoken"),
      },
    }).then((res) => {
      setChildren(res?.data?.data)
    }).catch((error) => {
      Toast(error.response.data.message,"error")
    });
  }

  const handleChange = (value) => {
    setTotaltags(value);
  };


  const importStory = () => {
    if (story === "") {
      Toast("Provide Link to import","error")
      return
    }
    setImportLoader(true);
    axios({
      method: 'get',
      url:  "market/story/import?url=" + story,
      headers: {
        Authorization: Cookies.get("Logintoken")
      }
    }).then((res) => {
      setContent(res.data.data.content);
      setImportLoader(false);
      Toast("Imported Successfully","success")
    }).catch((err) => {
      Toast(err.response.data.message,"error")
    })
  }

  const _props = {
    name: "file",
    maxCount: 1,
    multiple: false,
    accept: ".jpeg,.png,.jpg",
    action:  "account/file/upload",
    headers: {
      Authorization: Cookies.get("Logintoken"),

    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        // console.log(info)
        sessionStorage.setItem("image", info.file.response.data.url)
      } else if (info.file.status === "error") {
        Toast(`${info.file.name} file upload failed.`,"error");
      }
    },
    customRequest: (options) => request.customRequest(options, "file"),
  };

  return (
    <Row>


      <Col span={24} className="height100vh">

        <Row className="m-px10 m-md10">
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
            <Row className="md10 m-px10">
              <Col xs={24} md={8} className="md20 border-bottom" style={{ paddingBottom: "10px" }}>
                {/* <p className="sub-text m-fs10">Write Your story</p> */}
                <Button onClick={() => history.goBack()} type="text" icon={<ArrowLeft size={24} />}></Button>
              </Col>
              <Col xs={24} md={16} className="text-right md20 border-bottom" style={{ paddingBottom: "10px" }}>
                <Row>

                  <Col span={24} className="text-right mob-text-center">
                    <Space size={20}>

                      <p className="text-grey fs12 fw400">
                        {autoSaving ? <CloudArrowUp color="grey" size={20} /> :
                          <Tooltip title='Saved as draft'> Saved as draft <CloudCheck color="green" size={20} /></Tooltip>}</p>
                      {/* <Button
                        type="dashed"
                        onClick={() => setImportModal(true)}
                      >
                        Import
                      </Button> */}
                      {edit === true ? (
                        <Button type="primary" onClick={() => EditNewsletter()} className="fs14 m-float-left "
                          size={{ xs: "small", sm: "small", md: "large", }}>Publish <ArrowRight size={20} weight='regular' className="ms-2" color="#ffffff" /> </Button>


                      ) : (
                        <Button loading={tagsLoading} type="primary" onClick={() => CreateNewsletter()} className="fs14 m-float-left "
                          size={{ xs: "small", sm: "small", md: "large", }}>Publish <ArrowRight size={20} weight='regular' className="ms-2" color="#ffffff" /> </Button>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className="md30">
                {
                  loader ? <Skeleton.Input block active /> :
                    <Input
                      value={title}
                      className="rr-heading-input ms-1"
                      placeholder="Enter title..."
                      size="large"
                      onChange={(evt) =>
                        setTitle(evt.target.value)
                      }
                      bordered={true}
                      maxLength={100}
                    />
                }
              </Col>

              <Col span={24} className="md20">
                {
                  loader ? <Skeleton.Input block active /> :
                    <Input
                      className="rr-sub-heading-input ms-1"
                      value={metaTitle}
                      height={"auto"}
                      placeholder="Enter subtitle"
                      size="large"
                      onChange={(evt) =>
                        setMetaTitle(evt.target.value)
                      }
                      bordered={true} maxLength={200}

                    />
                }
              </Col>

              <Col span={24} className="md20">
                {
                  loader ? <><Skeleton active /> <Skeleton active /> </> :
                    <NewsletterEditor onChange={(json) => setFinal(json)} onChangeHTML={(html) => handleHTMLChange(html)} intState={final} />
                }
              </Col>
            </Row>
          </Col>
        </Row>


        <Modal centered open={tagModal} onCancel={() => setTagModal(false)} maskClosable={false} title={"Tags Relevent to your Content"} footer={[
          <Button key="back" type="dashed" onClick={() => FinalCreateNewsletter(false, true)}>
            Skip
          </Button>,
          <Button type="primary" onClick={() => FinalCreateNewsletter(true, true)}>
            Publish
          </Button>]}>
          <Row className="padding20">
            <Col span={24}>
              <Select
                mode="tags"
                bordered={true}
                allowClear
                style={{
                  width: '100%',
                }}
                placeholder="Search tags"
                value={Totaltags}
                onChange={(evt) => handleChange(evt)}
                onSearch={(evt) => handelSearch(evt)}
              >
                {children.map((item) => {
                  return <Option value={item}>{item}</Option>
                })}
              </Select>

            </Col>

            <Col span={24} className="md20">
              <Upload {..._props} onRemove={() => sessionStorage.removeItem("image")}>
                <Button icon={<UploadOutlined />}>Upload Banner Image</Button>
              </Upload>
            </Col>
            <Col span={24} className="md20" style={{ marginBottom: "20px" }} >
              <Button className="p-0" type="text" onClick={() => setAdvancedFeature(!advancedFeature)}
              > More Options <CaretDown size={18} weight='bold' style={{ marginLeft: "5px" }} rotate={advancedFeature ? 0 : 90} /></Button>

              {
                advancedFeature && <ContentAdvancedFeature className='md20' onUpdate={(val) => setAdvancedPayload(val)} />
              }

            </Col>
          </Row>
        </Modal>
        <Modal title="Import your story" open={importModal} onCancel={() => setImportModal(false)} maskClosable={false} width={550} footer={false} >
          <Row className="nomargin padding20">
            <Col span={22} offset={1} >
              <Row>
                <Col span={24} className='membership-input md30'>
                  <p className="fs16 fw500 "> Import a story from anywhere on the internet to publish on your Yuja account. </p>
                  <p className="fs14 fw400 md15">Enter a link to your blog post/article/story/manifesto to import</p>
                  <Input placeholder="https://yoursite.in/your-post" value={story} onChange={(evt) => setStory(evt.target.value)} className="md15 " />
                </Col>

                <Col span={24} className='text-center md50'>
                  <p className="fs14 fw400" > You will have a chance to edit it before making it public.</p>
                </Col>
                <Col span={24} className='text-center md50 mb-3'>
                  <Button className="btn btn-primary" loading={importLoader} onClick={() => importStory()} > Import</Button>
                </Col>
              </Row>
            </Col>
          </Row>

        </Modal>

      </Col>
    </Row >
  );

}

const mapStateToProps = (state) => ({
  _newsletterdetails: state.newsletter.newsletterdetail,
  _tags: state.newsletter.tags,
  _newsletter_id: state.newsletter.id,
  _channels: state.channel.channel_list,
});
const mapDispatchToProps = (dispatch) => ({
  CreateNewsletter: (v) => dispatch(actions.CreateNewsletter(v)),
  EditNewsletter: (v) => dispatch(actions.EditNewsletter(v)),
  NewsletterDetails: (v) => dispatch(actions.NewsletterDetails(v)),
  getTags: (v) => dispatch(actions.getTags(v)),
  ChannelList: (v) => dispatch(actions.ChannelList(v)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WriteNewsletter)
);
