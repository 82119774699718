import { Button, Col, Row, Space, Tag,} from "antd";
import React, { useEffect, useState } from "react";
import actions from "../../store/actions/index";
import userToken from "../../store/useToken";
import { useHistory } from "react-router";
import axios from "../../store/axios";
const { CheckableTag } = Tag;

const Preferences = (props) => {
    const [loading, setLoading] = useState(false);
    const [topics, setTopics] = useState([]);
    const { token } = userToken();
    const history = useHistory();
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        getMasterTags();
        gettags();
    }, []);

    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };

    const gettags = async () => {
        axios({
            method: 'get',
            url: 'account/user/preferences',
            headers: {
                Authorization: token
            }
        }).then(res => {
            setSelectedTags(res.data.data['topic']);
        })
    };

    const saveData = async() => {
        setLoading(true);
        await axios({
            method: 'post',
            url: 'account/user/preferences',
            data: {topic: selectedTags},
            headers: {
                Authorization: token
            }
        }).then(() => {
            history.push("/dashboard")
        })
        setLoading(false);
    }

    const getMasterTags = async () => {
        await axios({
            method: 'get',
            url: 'account/master/tags?tag=',
            headers: {
                Authorization: token
            }
        }).then(res => {

            setTopics(res.data.data.map((t)=> {return t['tag'].toLowerCase()}));
        })
    };




    return (
        <Row style={{ minHeight: '100vh' }}>
            <Col span={24}>
                <img className="m-opacity03" src={require("../../assets/images/pref-bg.svg").default} alt="syllo" srcSet=""
                    style={{ position: "fixed", bottom: "0", right: '0' }} />
            </Col>
            <Col lg={{ span: 18, offset: 3 }} xs={{ span: 24, offset: 0 }} className="md70">
                <Row>
                    <Col span={24} className="text-center">
                        <h3 className="fw600">What do you like?</h3>
                    </Col>
                    <Col span={24} className="prefe-card md10">
                        <Row gutter={20}>
                            <Col md={8} xs={24} className="md20">
                                <p className="fs16 fw600">Preferences</p>
                                <p className="fs12 fw400 md15 text-grey">So we can organize and prioritize information
                                    ensuring only relevant content is delivered</p>
                                <p className="fs12 fw600 md15" style={{ color: '#DA6A4D' }}>Select atleast 5 tags</p>
                            </Col>
                            <Col md={16} xs={24} className="md20">
                                <Space wrap>
                                    {
                                        topics.map((i) => {
                                            return <CheckableTag key={i}
                                            style={{textTransform:"capitalize"}}
                                                checked={selectedTags.includes(i)}
                                                onChange={(checked) => handleChange(i, checked)}
                                               >{i}</CheckableTag>
                                        })
                                    }
                                </Space>
                            </Col>
                            {/* <Col md={1} xs={24} className="md40 m-text-center">
                                <Button type="primary" shape="circle" icon={<ShuffleAngular size={22} color="#d9d9d9" weight="fill" />} />
                            </Col> */}
                        </Row>
                    </Col>
                    {/* <Col span={24} className="prefe-card md40">
                        <Row>
                            <Col span={4}>
                                <p className="fs16 fw600"> Creators <br />
                                    you may like</p>
                            </Col>
                            <Col span={18}>
                                <Row gutter={20}>
                                    <Col span={8} >
                                        <Row style={{ background: 'white', borderRadius: '16px', padding: '20px' }}>
                                            <Col span={24}>
                                                <Space align="top">
                                                    <Avatar size={50} />
                                                    <div>
                                                        <p className="fs14 fw600">Jagjit Singh</p>
                                                        <p className="fs10 fw400 text-grey md5">@singforpain</p>
                                                        <p className="fs11 fw500 md10">2.2K Followers</p>
                                                    </div>
                                                </Space>
                                            </Col>

                                            <Col span={24} className="md20">
                                                <Button type="text" block style={{ background: '#000000', color: 'white' }}> Follow </Button>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={8} >
                                        <Row style={{ background: 'white', borderRadius: '16px', padding: '20px' }}>
                                            <Col span={24}>
                                                <Space align="top">
                                                    <Avatar size={50} />
                                                    <div>
                                                        <p className="fs14 fw600">Jagjit Singh</p>
                                                        <p className="fs10 fw400 text-grey md5">@singforpain</p>
                                                        <p className="fs11 fw500 md10">2.2K Followers</p>
                                                    </div>
                                                </Space>
                                            </Col>

                                            <Col span={24} className="md20">
                                                <Button type="text" block style={{ background: '#000000', color: 'white' }}> Follow </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8} >
                                        <Row style={{ background: 'white', borderRadius: '16px', padding: '20px' }}>
                                            <Col span={24}>
                                                <Space align="top">
                                                    <Avatar size={50} />
                                                    <div>
                                                        <p className="fs14 fw600">Jagjit Singh</p>
                                                        <p className="fs10 fw400 text-grey md5">@singforpain</p>
                                                        <p className="fs11 fw500 md10">2.2K Followers</p>
                                                    </div>
                                                </Space>
                                            </Col>

                                            <Col span={24} className="md20">
                                                <Button type="text" block style={{ background: '#000000', color: 'white' }}> Follow </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={2} className="md50 text-right">
                                <Button type="primary" shape="circle" icon={<ShuffleAngular size={22} color="#d9d9d9" weight="fill" />} />
                            </Col>
                        </Row>
                    </Col> */}
                    <Col span={24} className="text-center md45 mb-3">
                        <Button disabled={selectedTags.length<5?true: false} loading={loading} type="primary" onClick={()=>saveData()}> Continue</Button>
                    </Col>
                </Row>
            </Col >
        </Row >
    );
}

const mapStateToProps = (state) => ({
    _topics: state.publics.sectors,
    _stcoks: state.publics.stocks,
    _user_selected: state.publics.user_selected
});
const mapDispatchToProps = (dispatch) => ({
    Sectors: (v) => dispatch(actions.Sectors(v)),
    UserPreferences: (v) => dispatch(actions.UserPreferences(v)),
    userSelectedPreferences: (v) => dispatch(actions.UserSelectedPreferences(v)),
});

export default Preferences;
