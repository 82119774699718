import axios from "../../store/axios";
import Cookies from "js-cookie"

export const customRequest = (options, type) => {
    const data= new FormData()
    data.append("file", options.file)
    data.append("type", type)
    const config= {
      "headers": {
        'content-type': 'multipart/form-data',
        Authorization: Cookies.get("Logintoken"),
      }
    }
    axios.post(options.action, data, config).then((res) => {
      options.onSuccess(res.data, options.file)
    }).catch((err ) => {
      options.onError(err, options.file)
    })
    
  }