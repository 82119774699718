import React, { useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router";
import { Col, Row, Button, Space, message, Checkbox, Result, Tag, Badge, Skeleton, Spin, Card } from "antd";
import AgoraRTC from "agora-rtc-sdk-ng"

import { connect } from "react-redux";
import actions from "../../store/actions/index";
import "../../assets/css/style.scss";
import "./livechat.scss"
import Cookies from "js-cookie";
import axios from "../../store/axios";
import GoliveChat from "./agora/GoliveChat";
import { ChatTeardropText, Eye, Microphone, MicrophoneSlash, PhoneDisconnect, UserRectangle, UsersThree, VideoCamera, VideoCameraSlash, Warning } from "@phosphor-icons/react";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import EventChat from "../VideoRoom/EventChat";




let channelParameters =
{
    // A variable to hold a local audio track.
    localAudioTrack: null,
    // A variable to hold a local video track.
    localVideoTrack: null,
    // A variable to hold a remote audio track.
    remoteAudioTrack: null,
    // A variable to hold a remote video track.
    remoteVideoTrack: null,
    // A variable to hold the remote user id.s
    remoteUid: null,
};

const GoLivev2 = (props) => {
    const url = useParams();
    const room_id = url["id"];

    const [showChat, setShowChat] = useState(false);
    const [chatTab, setChatTab] = useState(false);
    const [eventDetails, setEventDetails] = useState([])
    const [start, setStart] = useState(false);
    const [localStream, setLocalStream] = useState(channelParameters);
    const [cameraMute, setCameraMute] = useState(false);
    const [audioMute, setAudioMute] = useState(false);
    const [client] = useState(AgoraRTC.createClient({ mode: "live", codec: "vp8" }));

    const [role, setRole] = useState(0);
    const [logined, setLogined] = useState(false);
    const [dataRTM, setDataRTM] = useState("");
    const [actionText, setActionText] = useState("Start");
    const [loadings, setLoadings] = useState(true);
    const [loadingsAPI, setLoadingsAPI] = useState(false);
    const [sessionClosed, setSessionClosed] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [hostVideoMuted, setHostVideoMuted] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [duration, setDuration] = useState(0);
    const key = 'joiningup';
    let history = useHistory();


    useEffect(() => {
        props.EventDetails({ id: room_id });
        startRemoteVideo();

        return async () => {
            localStream.localAudioTrack.close();
            localStream.localVideoTrack.close();
            await client.leave();
        }
    }, []);


    useEffect(() => {
        if (props._details.length !== 0) {
            setEventDetails(props._details)

            setLoadings(false)
            if (props._details.isme) {
                setTimeout(() => {
                    startCamara();
                }, 1000);

            }
            var now = moment(new Date()); //todays date
            var end = moment(props._details.schedule_date_from); // another date
            var duration = moment.duration(end.diff(now));
            var days = duration.asMinutes();
            setTimeLeft(days)
        }
    }, [props._details]);

    async function startRemoteVideo() {
        client.on("user-published", async (user, mediaType) => {
            // Subscribe to the remote user when the SDK triggers the "user-published" event.
            await client.subscribe(user, mediaType);
            console.log("Audiance subscribe success");
            // Subscribe and play the remote video in the container If the remote user publishes a video track.
            if (mediaType == "video") {
                // Retrieve the remote video track.
                localStream.remoteVideoTrack = user.videoTrack;
                // Retrieve the remote audio track.
                localStream.remoteAudioTrack = user.audioTrack;
                // Save the remote user id for reuse.
                localStream.remoteUid = user.uid.toString();
                // Specify the ID of the DIV container. You can use the uid of the remote user.
                const localPlayerContainer = document.getElementById("remote-playerlist");
                localPlayerContainer.id = user.uid.toString();
                localStream.remoteUid = user.uid.toString();
                // Append the remote container to the page body.
                localStream.remoteVideoTrack.play(localPlayerContainer);
            }
            // Subscribe and play the remote audio track If the remote user publishes the audio track only.
            if (mediaType == "audio") {
                // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
                localStream.remoteAudioTrack = user.audioTrack;
                // Play the remote audio track. No need to pass any DOM element.
                localStream.remoteAudioTrack.play();
            }

        });
        // Listen for the "user-unpublished" event.
        client.on("user-unpublished", user => {
            // console.log(user.uid + "has left the channel");
            message.success('Session has been closed by the Host!');
            setSessionClosed(true);
        });
        client.on("user-info-updated", async (uid, msg) => {

            if (msg === "mute-video") {
                setHostVideoMuted(true);
            }
            else if (msg === "unmute-video") {
                setHostVideoMuted(false);
            }

            //   case "mute-audio":  console.log(uid)
            //     case "unmute-audio":  console.log(uid)

        });
    }


    async function startCamara() {
        const localPlayerContainer = document.getElementById("remote-playerlist");
        if (localPlayerContainer) {
            localPlayerContainer.id = 0;
            localStream.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            localStream.localVideoTrack = await AgoraRTC.createCameraVideoTrack({ encoderConfig: { 'width': 1920, } });
            localStream.localVideoTrack.play(localPlayerContainer);
        }

    }

    // useEffect(() => {
    //     var data = props._token_details
    //     if (data) {
    //         setRole(data.role)
    //         setDataRTM(data)

    //         if (data.uid !== undefined) {
    //             if (data.role === 1) {
    //                 joinHost(data);

    //             } else {
    //                 callAudience(data);
    //             }
    //         }
    //     }
    // }, [props._token_details])

    useEffect(() => {
        const data = props._joined_user;
        if (data.length !== 0) {
            setRole(data.is_owner ? 1 : 5);
            setDataRTM(data);
            if (data.is_owner) {
                joinHost(data);
            } else {
                callAudience(data);
            }
            console.log(data);
            setLoadingsAPI(false);
        }
    }, [props._joined_user])


    const FitchToken = async () => {
        setLoadingsAPI(true);
        // await props.GetSteamingToken({ room_id: room_id, chat: showChat })
        await props.generateToken({
            schedule_id: room_id,
            role: 1,
        });
    }


    function muteCam(key) {
        let data = localStream;
        data.localVideoTrack.setEnabled(key);
        setLocalStream(data);
        setCameraMute(!cameraMute);
    }

    function muteAudio(key) {
        let data = localStream;
        data.localAudioTrack.setEnabled(key);
        setLocalStream(data);
        setAudioMute(!audioMute);
    }


    async function joinHost(data) {
        // messageApi.open({
        //     key,
        //     type: 'loading',
        //     content: 'Event broadcasting...',
        // });

        await client.setClientRole('host');
        // Join a channel.
        await client.join(data.appId, data.channel_name, data.token, data.user_code);

        // Publish the local audio and video tracks in the channel.
        await client.publish([localStream.localAudioTrack, localStream.localVideoTrack]);
        // Play the local video track.
        console.log("publish success!");

        // setUsers((prevUsers) => {
        //     return [...prevUsers, { uid: data.uid, audio: true, video: true, client: true, videoTrack: client.current.localVideoTrack }]
        // })
        // messageApi.open({
        //     key,
        //     type: 'success',
        //     content: 'Even is live now!',
        //     duration: 4,
        // });
        setStart(true);
        setLogined(true);

    }

    async function callAudience(data) {
        // messageApi.open({
        //     key,
        //     type: 'loading',
        //     content: 'Starting live...',
        // });
        await client.setClientRole('audience');
        // Join a channel.
        await client.join(data.appId, data.channel_name, data.token, data.user_code);
        setStart(true);
        setLogined(true);
    };



    const clickJoinBtn = () => {
        FitchToken()
    };

    const LeaveChannel = () => {

        if (!logined) {
            return
        }

        var url = "v1.0/stream/live/member/leave"
        if (role === 1) {
            url = "v1.0/stream/live"
        }

        axios({
            method: "delete",
            url:  url,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: Cookies.get("Logintoken")
            },
        })
            .then(async function () {
                localStream.localAudioTrack.close();
                localStream.localVideoTrack.close();
                await client.leave();
                history.goBack();
            })
            .catch(function () {
            });

    }

    const openCloseChat = (c) => {
        if (showChat) {
            setShowChat(false); setChatTab(c);
        } else {
            setShowChat(true); setChatTab(c);
        }

    }

    return (
        <Row justify={'center'}>
            {
                loadings ?
                    <Col span={24}>
                        <Row>
                            <Col lg={{ span: 20, offset: 0 }} xs={{ span: 18, offset: 0 }} >
                                <Skeleton active />
                                <Skeleton active />
                            </Col>
                        </Row>
                    </Col> : <>
                        {/* <Col span={24}>
                            <Row gutter={20} justify={'center'}>
                                <Col span={showChat ? 24 : 18} className="live-header">
                                    <Row>
                                        <Col lg={{ span: 20, offset: 0 }} xs={{ span: 18, offset: 0 }} >
                                            <Space>
                                                <Avatar shape="square" size={55} src={eventDetails.img_url} ></Avatar>
                                                <div>
                                                    <p className="fs15 fw500" >{eventDetails.name}</p>
                                                    <span className="fs12 fw400 text-grey"> by
                                                        <a className="fs12 fw400 text-black" href={"/profile/" + eventDetails.user_code}> {eventDetails.creator} </a></span>
                                                </div>

                                            </Space>
                                        </Col>

                                        <Col lg={{ span: 4, offset: 0 }} xs={{ span: 6, offset: 0 }} className='text-right' style={{ alignSelf: "center" }}>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col> */}
                        <Col lg={{ span: 22 }} xs={{ span: 24 }} className='md20 go-live'>

                            {contextHolder}

                            <Row gutter={20} justify={'center'}>

                                <Col lg={showChat ? 17 : 18} md={showChat ? 17 : 18} xs={showChat ? 17 : 24}>
                                    <Spin spinning={loadingsAPI} delay={100}>
                                        <div className="bgblack video-height" id="">
                                            {
                                                hostVideoMuted ? <Col span={24} className='live-bg-img centered' style={{ justifyContent: "center" }} >
                                                    <UserRectangle size={200} color="#666666" weight="light" />
                                                </Col> :
                                                    <Col span={24} className="live-bg-img" id="remote-playerlist" style={{ visibility: hostVideoMuted ? "hidden" : "visible", borderRadius: '10px' }}>
                                                        {
                                                            !eventDetails.isme && !start && <div className="md150 text-center">
                                                                <Card type="inner" className="p-4">
                                                                    <Space>
                                                                        <Avatar shape="square" size={55} src={eventDetails.img_url} ></Avatar>
                                                                        <div>
                                                                            <a className="fs15 fw500 text-black" href={"/event/" + eventDetails.schedule_uid}> {eventDetails.name} </a>
                                                                            <br />
                                                                            <span className="fs12 fw400 text-grey"> by
                                                                                <a className="fs12 fw400 text-black" href={"/profile/" + eventDetails.user_code}> {eventDetails.creator} </a></span>
                                                                        </div>

                                                                    </Space> <br />
                                                                    <p>Event is <Tag color="magenta">live</Tag>. Click to join</p>
                                                                    <Button type="primary" className="md30" onClick={() => clickJoinBtn()}>Join Now</Button>
                                                                </Card>
                                                            </div>
                                                        }

                                                    </Col>
                                            }
                                            <Col style={{ position: "absolute", padding:"10px", bottom: "12px", width: "calc(100% - 24px)",background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)" }}>
                                                <Row gutter={20} align={'middle'}>
                                                    <Col span={4} className="text-left">
                                                        {
                                                            start && <Button type="primary" onClick={() => LeaveChannel()}>Leave</Button>
                                                        }

                                                    </Col>
                                                    <Col className={'text-center'} span={16} >
                                                        {
                                                            eventDetails.isme &&

                                                            <Space>
                                                                <Button type="" shape="round" onClick={() => muteAudio(audioMute)}
                                                                    icon={audioMute ? <MicrophoneSlash size={24} weight="fill" /> : <Microphone size={24} weight="fill" />}
                                                                    size={'default'} />

                                                                <Button type="" shape="round" onClick={() => muteCam(cameraMute)}
                                                                    icon={cameraMute ? <VideoCameraSlash size={24} weight="fill" /> : <VideoCamera size={24} weight="fill" />}
                                                                    size={'default'} />
                                                            </Space>
                                                        }
                                                    </Col>

                                                    <Col span={4}>
                                                        {
                                                            start && <Space align="center">
                                                                {
                                                                    eventDetails.isme && <Button type="text" onClick={() => openCloseChat(false)}
                                                                    icon={<UsersThree size={24} color="#fefefe" />}
                                                                    size={'default'} />
                                                                }
                                                                <Button type="text" onClick={() => openCloseChat(true)}
                                                                    icon={<ChatTeardropText size={24} color="#fefefe" />}
                                                                    size={'default'} />
                                                            </Space>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </div>
                                    </Spin>
                                    {
                                        (eventDetails.isme) && !start && <Row justify={"center"}>
                                            <Col span={24} className={"text-center "}>

                                                {
                                                    <span className="checkbox fs14 fw400" ><Checkbox checked={showChat}
                                                        onChange={(evt) => setShowChat(evt.target.checked)}>Live Chat feature</Checkbox></span>
                                                }

                                                <p className={"fw400 md10 text-grey"}>You can easily interact with your audience with Live Chat feature. Viewers <br /> can freely message & reply in the chat-area.</p>
                                                <p className={"md20"}>
                                                    <Space className="mb-3">
                                                        <Button type="dashed" onClick={() => props.history.goBack()}>Back</Button>
                                                        {
                                                            !start && timeLeft < 0 ?
                                                                <Button loading={loadings} type={"primary"} className="" onClick={() => clickJoinBtn()}>{eventDetails.isme ? actionText : "Watch"}
                                                                </Button> : <p>Time Left to Start : {parseInt(timeLeft)} mins</p>

                                                        }
                                                    </Space>

                                                </p>
                                            </Col>
                                        </Row>
                                    }
                                </Col>

                                {showChat === true ?
                                    <Col span={7}>
                                        <EventChat is_owner={eventDetails.isme} chatOpen={chatTab} id={room_id} />
                                    </Col>
                                    : ""}
                            </Row>


                        </Col>
                    </>
            }

        </Row>
    );
}


const mapStateToProps = (state) => ({
    _token_details: state.stream.token_details,
    _details: state.channel.event_detail,
    _joined_user: state.channel.joined_user,
});
const mapDispatchToProps = (dispatch) => ({
    GetSteamingToken: (v) => dispatch(actions.GetSteamingToken(v)),
    EventDetails: (v) => dispatch(actions.EventDetails(v)),
    generateToken: (v) => dispatch(actions.generateToken(v)),
    deleteToken: (v) => dispatch(actions.deleteToken(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GoLivev2)
);
