import { Button, Col, Divider, Row, Skeleton, Space, Popconfirm} from "antd";
import { SquaresFour, PlugsConnected } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useToken from '../../../store/useToken'
import axios from "../../../store/axios";

const SettingConnect = () => {
    const history = useHistory();
    const { token } = useToken();

    const [apps, setApps] = useState([]);
    const [loader, setloader] = useState(true);


    useEffect(() => {
        if (token) {
            getApps();
        } else {
            history.push('/login?redirect=settings')
        }
    }, [token]);

    const getApps = async () => {
        setloader(true)
        await axios({
            method: 'get',
            url: "lighthouse/apps",
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setApps(data);
        })
        setloader(false);
    };

    const revokeApp = async (item) => {
        await axios({
            method: 'delete',
            url: `lighthouse/app/${item.platform}`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            // Relaod the data
            getApps();
        });

    };

    const connectApp = (item) => {
        if (item?.platform === 'TW') {
            history.push('connect/app/twitter')
        }
        else if (item?.platform === 'LI') {
            history.push('connect/app/linkedin')
        }
    }

    const disConnectApp = (item) => {
        revokeApp(item);
    }

    return (
        <Row style={{ padding: "40px 0px 30px 30px" }} className="m-nopadding">
            <Col span={24}>
                <Row>
                    {/* <Col span={8}>
                        <CaretLeft size={32} color="#676767" weight="thin" />
                    </Col> */}
                    <Col span={24} className="text-center">
                        <Space>
                            <SquaresFour size={32} color="#3B6BE3" weight="thin" />
                            <p className="fs16 fw500"> Connect Apps </p>
                        </Space>
                    </Col>
                </Row>
                <Row className="md15 m-nopadding20" style={{ borderRadius: '12px', border: '1px solid #d9d9d9', padding: '40px'}} >
                    <Col span={24} offset={0} >
                        <p className="fs14 fw400 text-grey">Syllo offers direct posting on your preffered social media apps. You can connect Apps with Syllo and enjoy seemless posting across multiple platforms.</p>
                        <Divider />
                    </Col>
                    {
                        loader && <>
                            <Col span={6}><Skeleton active /> </Col>
                            <Col span={6}><Skeleton active /> </Col>
                            <Col span={6}><Skeleton active /> </Col>
                            <Col span={6}><Skeleton active /> </Col>
                        </>
                    }
                    <Col span={24}>
                        <Row gutter={16} align={'top'}>
                        {
                        apps.map((item, i) => {
                            return <Col xxl={6} xl={6} lg={10} md={12} sm={12} xs={12} key={i}>
                                <div className="md20 text-center" style={{ borderRadius: '18px', border: '1px solid #d9d9d9', padding: '15px' }}>
                                    <img height={32} src={item.icon} />
                                    <p className="fs14 fw600 md10" style={{ color: '#4B5669' }}>{item.name}</p>
                                    {
                                        item?.connected ?
                                            <Popconfirm
                                                onConfirm={()=> disConnectApp(item)}
                                                okText="Yes, Disconnect!" cancelText="No, Cancel"
                                                title={<span>Are you sure to disconnect {item.name} account? <br /> You will be unable to share posts <br /> on {item.name} after disconnection!</span>}
                                                icon={<PlugsConnected size={28} color="red" weight="fill" />}
                                            >
                                                <Button size="small" type="dashed" className="md10 m-fs12" style={{ borderRadius: '4px' }} onClick={(e) => e.preventDefault()}> Disconnect </Button>
                                            </Popconfirm> :
                                            <Button size="small" type="primary" className="md10" onClick={() => connectApp(item)} style={{ borderRadius: '4px' }}> Connect </Button>
                                    }

                                </div>
                            </Col>
                        })
                    }
                        </Row>
                    </Col>
                    

                </Row>
            </Col>
        </Row>
    );
}
export default SettingConnect;