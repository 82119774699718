import { Button, Space } from "antd";
import { Bell, Export } from "@phosphor-icons/react";
import React from "react";
import { RWebShare } from "react-web-share";

const EventTrigger = ({ data }) => {
    return (

        <span >
            <Space size="small">
                <RWebShare
                    data={{
                        text: data?.description,
                        url: window.location.href,
                        title: data?.name,
                    }}
                    
                >
                    <Button type="text" shape="circle">
                    <Export size={22} />
                    </Button>
                </RWebShare>


                <a
                    target="_blank"
                    href={
                        "https://calendar.google.com/calendar/u/0/r/eventedit?text=" +
                        data?.name +
                        "&dates=" +
                        data?.schedule_date_from +
                        "&details=" +
                        data?.name +
                        "%0AMore+at+www.lighthouse.com/finance/quote/MFSL:NSE%0A&sf=true"
                    }
                >
                    <Button type="text" shape="circle">
                    <Bell size={22} />

                    </Button>
                </a>
            </Space>
        </span>



    );
}
export default EventTrigger;
