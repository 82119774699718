import React, { useState } from "react";
import { Button, Col, Modal, Row, Skeleton, Space, Typography } from "antd";

import { useParams } from "react-router-dom";
import { Video } from "@phosphor-icons/react";
import Cookies from "js-cookie";
import axios from "../../store/axios";

const LessonContent = (props) => {

    const [modal, setModal] = useState(false);

    const [data, setData] = useState({});
    const [loader, setloader] = useState(true);

    const params = useParams();

    const { content } = props;

    const getVideo = async (content_id) => {
        setloader(true)
        await axios({
            method: 'get',
            url:  `v1.0/course/${params['id']}/lesson/${content_id}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setloader(false)
            setData(data)
        }).catch((err) => {
            console.log(err)
            setloader(false)
        })
    }

    const onPreview = (content_id) => {
        getVideo(content_id);
        setModal(true);
    }

    const renderVideo = (id) => {
        return <div data-dyntube-key={id}></div>
    }
    return (

        <Row className="md10">

            {
                content?.media_type === "VID" ? <>
                    <Col span={20}>
                        <Space>
                            <Video size={24} className="text-grey" />
                            {
                                content?.status !== "Published" ? <span>Processing</span> : 
                                <Button type="text" className="text-blue" onClick={() => onPreview(content?.content_id)}>Preview</Button>
                            }
                            
                        </Space>
                    </Col>
                    <Col span={4} className="text-end">
                        <p className="text-grey">{content?.duration && content?.duration.split(".")[0]}</p>
                    </Col>
                </>
                    :
                    <Col span={24}> </Col>
            }

            <Modal destroyOnClose={true} title="Video Preview" open={modal} footer={false} onCancel={() => setModal(false)}>
                <Row>
                    <Col span={24}>
                        {
                            loader ? <div className="p-4"><Skeleton active /><Skeleton active /></div> :
                                data?.video_key && renderVideo(data?.video_key)
                        }

                        { }

                    </Col>
                    <Col span={24} className="padding20">
                        <p className="fs16 fw600 text-grey">{props?.lesson?.title}</p>

                        <Typography.Paragraph className="text-grey md5" ellipsis={{rows:5, expandable:true, symbol:"more"}}>{props?.lesson?.description}</Typography.Paragraph>
                       
                    </Col>
                </Row>

            </Modal>

        </Row>

    );
}
export default LessonContent;