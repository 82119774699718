import { Airplay, DotsThreeVertical, Export, GraduationCap } from "@phosphor-icons/react";
import { Avatar, Button, Col, Row, Space, Tag } from "antd";
import React from "react";
import { useState } from "react";


const NewProfileV2 = ({ profile }) => {

    return (
        <Row>
            <Col span={18} offset={3} className="md70">
                <Row>
                    <Col span={5}>
                        <Avatar size={100} />
                        <p className="fs24 fw500 md10"> Gaurav PC </p>
                        <p className="dark-grey fs12 fw300">@gaurv.x</p>
                        <Space size={15} className="md15">
                            <Button icon={<GraduationCap size={20} color="#C71FE2" weight="light" />} style={{ backgroundColor: '#c71fe252', height: '35px', width: '35px', border: '1px solid #2e5cff45' }}></Button>
                            <Button icon={<Airplay size={20} color="#2E5CFF" weight="regular" />} style={{ backgroundColor: '#2e5cff45', height: '35px', width: '35px', border: '1px solid #2e5cff45' }}></Button>
                        </Space>
                    </Col>
                    <Col span={13} className=" md20 ">
                        <Button className="Radius8" style={{ color: '#114410', background: '#E0E9EE' }}>
                            <img src={require('../assets/images/profile/greentick.png')} className="ps-2" alt="" />
                            ICICI Pvt. Ltd.
                        </Button>
                        <p className="fs12 fw400 md10" style={{ color: '#4B5669' }}> Research Analyst </p>
                        <p className="fs14 fw400 md10">
                            Composer, Designer, Marketer <br />
                            “Spread love, motivation & positivity, because hate is <br /> expected from everything & everyone” <br />
                            🏫 NIIT ‘20   |   📌 Delhi, IN
                        </p>
                        <Space size={28} className="md20">
                            <Button type="primary" className="radius18"> Follow </Button>
                            <Button type="dash" className="radius18"> Send a message </Button>
                            <Export size={25} color="#000000" weight="regular" />
                            <DotsThreeVertical size={25} color="#000000" weight="regular" />

                        </Space>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={24} className="text-right">
                                <Tag color="#fff" className="tag-p30" style={{ background: 'linear-gradient(90deg, rgba(231,151,218,1) 0%, rgba(130,120,240,1) 80%)' }}> Expert </Tag>
                            </Col>

                        </Row>
                        <Row className="md45">
                            <Col span={15} >
                                <p className="fs18 fw600"> 56.1 K </p>
                                <p className="fs12 fw300"> Followers </p>
                            </Col>
                            <Col span={9}  >
                                <p className="fs18 fw600 text-center"> 101.1 K </p>
                                <p className="fs12 fw300 text-center"> Likes </p>
                            </Col>

                        </Row>

                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default NewProfileV2;