import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    podcast: {},
    play:false,
}
const PodcastReducer = handleActions({
    PODCAST_DETAIL: (state, action) => {
        return { ...state, podcast: action.payload };
    },
    PODCAST_PLAY: (state, action) => {
        return { ...state, play: action.payload };
    },
}, INITIAL_STATE);

export default PodcastReducer;
