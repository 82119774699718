import React from "react";
import { Typography, Row, Col } from 'antd';
import { withRouter } from "react-router";
import PostViewer from "../Editor/PostViewer";

const { Paragraph } = Typography


const PostBody = ({ item, details }) => {

    const openModal = (e) => {
        e.preventDefault();
        details(item);
    }

    const richText = (description)=>{
        try{
            return <PostViewer intState={description} />
        }catch{
            return <></>
        }
    }

    return <Row>
        <Col span={24}>
            {
                item.title && <h6 className="news-header cursor m-fs16" onClick={(e) => openModal(e)}>{item.title}</h6>
            }
            {
                item.entity_type === "Post" ? <div onClick={(e) => openModal(e)}>
                    {richText(item?.description)}
                </div> :
                    <Paragraph ellipsis={{
                        rows: 3, expandable: true, symbol: 'Read more', onExpand: function (event) { event.stopPropagation() }
                    }} onClick={(e) => openModal(e)} className="mt-1 cursor m-fs14 " style={{ color: "#4B5669" }}>{item.meta_title}</Paragraph>
            }

            {
                item.image_url !== null && item.image_url !== "" && item.entity_type !== "Report"
                && <img onClick={(e) => openModal(e)} className="post-image md10" src={item.image_url} style={{ backgroundColor: "#f0f0f0", width: "95%", borderRadius: "14px", objectFit: item.entity_type === "Newsletter" ? "cover" : "", maxHeight: item.entity_type === "Newsletter" ? "355px" : "" }}></img>
            }
        </Col>
    </Row>
}
export default withRouter(PostBody);