import { Avatar, Col, Row, Dropdown, Space, Button, Typography, Skeleton } from "antd";
import { Bug, DotsThreeVertical, Export } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import ExploreNotes from "./ExploreNotes";
import { useHistory } from "react-router-dom";
import { RWebShare } from "react-web-share";
const { Paragraph } = Typography

const COLORS = ['linear-gradient(276.78deg, #FFE8ED 36.28%, #FFF1CD 100%)', 'linear-gradient(97.04deg, #FFFFFF 0%, #E1E1E1 100%)',
    'linear-gradient(97.04deg, #FFFEE9 0%, #D0F0E1 100%)', 'linear-gradient(0deg, rgba(243, 238, 244, 0.2), rgba(243, 238, 244, 0.2)), linear-gradient(353.31deg, rgba(13, 167, 0, 0.2) -145.4%, rgba(0, 0, 0, 0) 146.61%, rgba(255, 255, 255, 0) 146.61%), linear-gradient(267.54deg, rgba(239, 37, 37, 0.2) 16.77%, rgba(255, 255, 255, 0) 70.29%), linear-gradient(90deg, #FAEFDE 1.48%, rgba(250, 239, 222, 0) 57.38%)']


const ExploreNote = () => {
    const [dataNL, setDataNL] = useState([]);
    const [dataNote, setDataNote] = useState([]);
    const [loadingNL, setLoadingNL] = useState(true);
    const [loadingNote, setLoadingNote] = useState(true);
    const history = useHistory();

    useEffect(() => {
        // API for newsletter
        setLoadingNL(true);
        API1('Newsletter',4);
        // API for Notes
        setLoadingNote(true);
        API1('Note',12);
        // API for People
        // API for Business

    }, [])

    const API1 = async (parent, c) => {
        axios({
            method: 'get',
            url:  `market/explore/${parent}/popular?count=${c}&offset=0`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const __data = res.data.data
            if (parent === 'Note') {
                setDataNote(__data);
                setLoadingNote(false);
            }
            else if (parent === 'Newsletter') {
                setDataNL(__data);
                setLoadingNL(false);
            }
        })
    }
    const menuItems = (i) => {
        var i = [
            // {
            //     label: (
            //         <span className="fw400"> Follow {i?.username}</span>
            //     ),
            //     key: '1',
            //     icon: <SealCheck size={18} color="#000000" weight="thin" />
            // },
            // {
            //     label: (
            //         <span className="fw400"> Block {i?.username}</span>
            //     ),
            //     key: '2',
            //     icon: <Prohibit size={18} color="#000000" weight="thin" />
            // },
            {
                key: '3',
                icon:
                    <RWebShare
                        data={{
                            text: i?.meta_title,
                            url: `${process.env.REACT_APP_URL}feed-view/${i?.entity_uuid}`,
                            title: i?.title,
                        }}

                    ><span><Export size={18} color="#000000" weight="thin" className="me-1" /> Share</span>
                    </RWebShare>
            }, {
                label: (
                    <span className="fw400"> Report issue</span>
                ),
                key: '4',
                icon: <Bug size={18} color="#000000" weight="thin" />
            }
        ]
        return i;
    };
    const menuClick = async ({ key }) => {
        if (key === '2') {

        }
    };
    return (
        
                <Row gutter={12}>
                    <Col lg={18} md={24} className="md20">
                        <p className="fs14 fw600 m-padding20"> Notes </p>
                        {
                            loadingNote ? <div className="md20"><Skeleton active /><Skeleton active /></div> : <ExploreNotes data={dataNote} />
                        }

                    </Col>
                    <Col lg={6} md={24} className="md20">
                        <p className="fs14 fw600 m-padding20"> Newsletter </p>

                        {
                            loadingNL ?  <div className="md20"><Skeleton active /><Skeleton active /></div> 
                            : <Row className="md20">
                                {
                                    dataNL.map((i, j) => {
                                        return <Col key={j} span={24} style={{
                                            background: COLORS[Math.floor(Math.random() * COLORS.length)]
                                        }}>

                                            <div className="explov2-card-newsletter" >
                                                <Paragraph ellipsis={{ rows: 3 }} className="fs16 fw600 cursor"  onClick={() => history.push(`/feed-view/${i.entity_uuid}`)}> {i.title}  </Paragraph>
                                                <Row>

                                                    <Col span={20}>
                                                        <Space className="cursor" onClick={() => history.push(`/profile/${i.user_code}`)}>
                                                            <Avatar src={i.dp} size={25} />
                                                            <p className="fs12 fw400">{i.fname} {i.lname} </p>
                                                        </Space>
                                                    </Col>

                                                    <Col span={4} className="md5">
                                                        <div className="menu-surface-anchor text-right">
                                                            <Dropdown trigger={['click']} menu={{ items: menuItems(i), onClick: ({ key }) => menuClick({ key: key }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                                <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                                            </Dropdown>


                                                        </div>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    })
                                }

                            </Row>
                        }


                    </Col>
                </Row>
    );
}
export default ExploreNote;