import React, { Component } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Button, Row, Col, Space, Skeleton } from "antd";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import Cookies from "js-cookie";
import {
  DownloadOutlined, AudioOutlined, VideoCameraOutlined, AudioMutedOutlined, VideoCameraAddOutlined
} from "@ant-design/icons";
import EventChat from './EventChat';

var url_string = window.location.href
var url = new URL(url_string);
var event_id = url.searchParams.get("id");



var client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });


var remoteUsers = {};
var localTracks = {
  videoTrack: true,
  audioTrack: true
};

var localTrackState = {
  videoTrackMuted: false,
  audioTrackMuted: false
}

class Call extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      channel: this.props.channel,
      joined_user: [],
      videoPlayer: false,
      openChat: false,
      chatTab: true,
      error: "",
      appId: "558e93cd745349658b0def40df192631"
    };
  }

  async componentDidMount() {

    this.setState({
      loader: false,
    });
    this.join(1, 1, event_id);


  }

  startNewCall() {
    this.props
      .createChannel({
        channel_id: this.props.id,
        media_type: "video",
      })
      .then(() => {
        this.componentDidMount();
      });
  }



  muteCall(type, video) {
    this.setState({
      audio_status: type,
      video_status: video
    })
    this.muteAudio();
  }

  unMute(type, video) {
    this.setState({
      audio_status: type,
      video_status: video
    })
    this.unmuteAudio();
  }

  mute_Video(type, video) {
    this.setState({
      audio_status: type,
      video_status: video
    })

    this.muteVideo();

  }
  unmute_Video(type, video) {
    this.setState({
      audio_status: type,
      video_status: video
    })
    this.unmuteVideo();
  }




  async muteAudio() {
    if (!localTracks.audioTrack) return;
    await localTracks.audioTrack.setMuted(true);
    localTrackState.audioTrackMuted = true;
  }

  async muteVideo() {
    if (!localTracks.videoTrack) return;
    await localTracks.videoTrack.setMuted(true);
    localTrackState.videoTrackMuted = true;
  }

  async unmuteAudio() {
    if (!localTracks.audioTrack) return;
    await localTracks.audioTrack.setMuted(false);
    localTrackState.audioTrackMuted = false;
  }

  async unmuteVideo() {
    if (!localTracks.videoTrack) return;
    await localTracks.videoTrack.setMuted(false);
    localTrackState.videoTrackMuted = false;
  }


  screenShare = () => {
    let me = this;
    me.localStreamScreenShare.init(
      function () {
        // console.log("getUserMedia successfully");
        me.localStreamScreenShare.play("agora_local-screenshare");
      },
      function (err) {
        // console.log("getUserMedia failed", err);
      }
    );
  }

  async join(type, video, id) {
    this.setState({
      videoPlayer: true,
      audio_status: type,
      video_status: video
    })
    let data = "";
    client.on("user-published", async (user, mediaType) => {
      debugger
      await client.subscribe(user, mediaType);
      let data = this.state.joined_user;
      if (mediaType === 'video') {

        let __user = document.getElementById("player-wrapper-" + data.user_code)
        if (__user === null) {
          const divAppend = document.getElementById("remote-playerlist");
          const localPlayerContainer = document.createElement("div");
          localPlayerContainer.id = "player-wrapper-" + user.uid;
          localPlayerContainer.className = "col-md-2";
          localPlayerContainer.textContent = data.name;
          localPlayerContainer.style.width = "100%";
          localPlayerContainer.style.height = "200px";
          localPlayerContainer.style.borderRadius = "12px";
          localPlayerContainer.style.textAlign = "center";
          localPlayerContainer.style.padding = "20px";
          localPlayerContainer.style.border = "1px solid #f0f0f0";
          divAppend.append(localPlayerContainer);
        }
        else {

        }

        // play the remote video.
        user.videoTrack.play(`player-wrapper-${user.uid}`);
      }
      if (mediaType === 'audio') {
        user.audioTrack.play();
      }
    });
    client.on("user-joined", (user) => {
      const id = user.uid;
      remoteUsers[id] = user;
    });
    // client.on("user-left",  (user)=>{
    //   const id = user.uid;
    //   delete remoteUsers[id];
    //  this.leave()
    // });
    client.on("user-unpublished", (user) => {
      const id = user.uid;
      delete remoteUsers[id];
      this.leave()
    });

    this.props.generateToken({
      schedule_id: id,
      role: 1,
    })
      .then(async () => {
        data = this.props._joined_user;

        [data.user_code, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
          client.join(data.appId, data.channel_name, data.token || null, data.user_code || null),
          AgoraRTC.createMicrophoneAudioTrack(),
          AgoraRTC.createCameraVideoTrack()
        ]);
        localTracks.videoTrack.play("player-wrapper-" + data.user_code);
        await client.publish(Object.values(localTracks));
        this.setState({
          joined_user: data
        })
      });

  }



  async leave() {
    this.props.deleteToken({
      channel_id: this.props.id,
      media_type: this.props.media,
    });

    client.remoteUsers.forEach((user) => {
      const id = user.uid;
      delete remoteUsers[id];
      const playerContainer = document.getElementById("player-wrapper-" + user.uid);
      playerContainer && playerContainer.remove();
    });
    await client.leave();
    this.props.history.goBack()
  }

  openCloseChat(c) {
    if (this.state.openChat === true) {
      this.setState({ openChat: false, chatTab: c })
    } else {
      this.setState({ openChat: true, chatTab: c })
    }
    if (this.props.collapseAds !== undefined) {
      this.props.collapseAds(this.state.openChat)
    }
  }

  render() {
    return (
      <Row className="">
        <Col span={22} offset={1} className='md35'>
          <Row gutter={20} justify={'center'}>
            {
              this.state.loader ? <Skeleton></Skeleton> :
                <Col span={this.state.openChat ? 17 : 18}>
                  <div className="bgblack video-height" id="">
                    {/* <Row>
                      <Col span={24}>
                      </Col>
                    </Row>                    */}
                    <div className="row" id="remote-playerlist" style={{ border: "1px solid red" }}>

                    </div>
                    <div style={{ position: "absolute", bottom: "10px", width:"calc(100% - 16px)" }}>
                      <Row gutter={20} align={'middle'}>
                        <Col span={6} className="text-left">
                          {/* <Button className='btn-controller' style={{ float: "left" }} shape="round" icon={<DownloadOutlined className='video-icons' />} size={'default'} /> */}
                          <Button type="primary" onClick={() => this.leave(Cookies.get("User_code"))}>Leave</Button>
                        </Col>
                        <Col className={'text-center'} span={12} >
                          <Space>
                            {
                              this.state.audio_status !== 0 ?
                                <Button type="" className='btn-controller' shape="round" onClick={() => this.muteCall(0, this.state.video_status)} icon={<AudioOutlined className='video-icons' />} size={'default'} /> : <Button type="" className='btn-controller' shape="round" onClick={() => this.muteCall(1, this.state.video_status)} icon={<AudioMutedOutlined className='video-icons' />} size={'default'} />
                            }
                            {
                              this.state.video_status !== 0 ? <Button type="" className='btn-controller' shape="round" onClick={() => this.mute_Video(this.state.audio_status, 0)} icon={<VideoCameraOutlined className='video-icons' />} size={'default'} /> : <Button type="" className='btn-controller' shape="round" onClick={() => this.mute_Video(this.state.audio_status, 1)} icon={<VideoCameraAddOutlined className='video-icons' />} size={'default'} />
                            }
                          </Space>
                        </Col>
                        <Col span={6}>
                          <Space align="center">
                            <img onClick={() => this.openCloseChat(false)} className='video-icons cursor' src={require('../../assets/images/events/bi_people.svg').default} alt="" />
                            <img onClick={() => this.openCloseChat(true)} style={{ marginRight: "10px" }} className='video-icons ms24 cursor' src={require('../../assets/images/events/ph_chat-circle-dots-light.svg').default} alt="" />
                          </Space>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
            }
            {this.state.openChat === true ?
              <Col span={7}>
                <EventChat is_owner={this.state.joined_user.is_owner} chatOpen={this.state.chatTab} id={event_id} />
              </Col>
              : ""}
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  _live_videos: state.channel.live_video,
  _joined_user: state.channel.joined_user,
});
const mapDispatchToProps = (dispatch) => ({
  LiveVideos: (v) => dispatch(actions.LiveVideos(v)),
  createChannel: (v) => dispatch(actions.createChannel(v)),
  generateToken: (v) => dispatch(actions.generateToken(v)),
  deleteToken: (v) => dispatch(actions.deleteToken(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Call));
