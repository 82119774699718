import { Card, Col, Row, Space } from "antd";
import React from "react";

const PodcastPlay = ({ item }) => {
    return (
        <a href={`/podcast/${item.entity_uuid}`}>
        <Card className="play-podcast" style={{ borderRadius: '8px' }} >
            <Row>
                <Col xs={24} md={18} lg={18}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <p className="fs20 fw800 text-white">{item?.title}</p>
                            <p className="fs12 fw300 " style={{ color: '#b6d0ff' }}> {item?.author?item?.author:"Author NA"} </p>
                            <p className="fs12 fw300  " style={{ color: '#b6d0ff' }}> {item?.total_episode} Episodes </p>

                        </Col>
                        <Col xs={24} md={20} className="md10">

                            <div className="play-div ">
                                <Space>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9957 12.5535 27.6247 9.2494 25.1876 6.81236C22.7506 4.37532 19.4465 3.0043 16 3ZM20.555 16.8325L14.555 20.8325C14.3904 20.9416 14.1974 20.9998 14 21C13.7348 21 13.4804 20.8946 13.2929 20.7071C13.1054 20.5196 13 20.2652 13 20V12C12.9999 11.8189 13.0489 11.6412 13.1419 11.4858C13.2349 11.3304 13.3684 11.2032 13.528 11.1177C13.6877 11.0323 13.8675 10.9918 14.0484 11.0005C14.2293 11.0093 14.4044 11.067 14.555 11.1675L20.555 15.1675C20.6922 15.2588 20.8047 15.3825 20.8825 15.5278C20.9603 15.673 21.001 15.8352 21.001 16C21.001 16.1648 20.9603 16.327 20.8825 16.4722C20.8047 16.6175 20.6922 16.7412 20.555 16.8325Z" fill="black" />
                                    </svg>
                                    <p className="fs12 fw300 " style={{ color: '#b6d0ff' }}> Play latest episode</p>
                                    {/* <div style={{ color: '#b6d0ff', borderRadius: '1px', width: '80px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                            <circle cx="2" cy="2" r="2" fill="#B6D0FF" />
                                        </svg>
                                    </div> */}
                                </Space>
                            </div>


                        </Col>
                        {/* <Col span={5} className="md40">

                                <img src='https://content.production.cdn.art19.com/images/fc/ce/bc/ec/fccebcec-b187-4cd5-8d39-e657a929a61c/04cd61b0de8ee0f6881fce5137e9bb647e1e590a83bab4724a1d495592b5705776c977cd417a5eeb053ce7674b765344d6db824fd970cdff0e2d063d1de7d4b9.jpeg'
                                    alt="" height={50} style={{ borderRadius: '4px' }} />


                            </Col> */}


                    </Row>

                </Col>
                <Col  xs={0} md={6} lg={6} className="text-end">
                    <img src={item?.image_url}
                        alt="" style={{ borderRadius: '4px', width: "100%" }} />
                </Col>
            </Row>
        </Card>
        </a>
    );
}

export default PodcastPlay;