import React from 'react';
import {Column} from '@ant-design/charts';
import moment from 'moment';

const Page = ({result,type}) => {
  const data = result.map((item)=>{
      return {
        month:moment(item?.created_at).format("MMMM"),value:item?.count
      }
  });

  const config = {
    data,
    xField: 'month',
    yField: 'value',
    point: {
      size: 3,
      shape: 'rect',
    },
  };
    return <Column height={300} {...config} />;

};
export default Page;