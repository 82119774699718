
import { Col, Row,Space, Typography, Button, Skeleton } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { Timer, Users } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import BlankState from "../BlankState";
import MentorshipCard from "../Mentorship/MentorshipCard";


const { Paragraph } = Typography

const 
Mentorship = (props) => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [more, setMore] = useState(false)
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        callAPI();
    }, [])

    function callAPI() {
        axios({
            method: 'get',
            url:  "v1.0/mentorship?user_code=" + props?._user_id + "&count=10&offset=" + offset,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setData(data)
            setLoader(false)
            setMore(data.length === 10)
        })
        setOffset(offset + 10)
    }

    return (
        <Col span={24} className='md15 mb-5'>
            {
                loader && <>
                    <Row >
                        <Col xs={{ span: 6 }} lg={{ span: 5 }}>
                            <Space>
                                <Skeleton.Avatar active={true} shape="square" size={150}></Skeleton.Avatar>
                            </Space>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 19 }} className='ps-3 md20'>
                            <Skeleton active></Skeleton>
                        </Col>
                    </Row>
                </>
            }
            {
                !loader && data.length === 0 && <Row> <Col span={24} className="text-center">
                <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} /></Col></Row> 
            }
            {
                data.map((item) => {
                    return <MentorshipCard {...item} />
                    // return <Row className='mentor-card md10 cursor'  onClick={()=>window.location.href = "/mentorship/"+item?.mentorship_id}>
                    //     <Col xs={{ span: 24 }} lg={{ span: 6 }} className='mentor mentor-bg-img' style={{ backgroundImage: `url(${item?.img_url})` }}> </Col>

                    //     <Col xs={{ span: 24 }} lg={{ span: 18 }} className='ps-3'>
                    //         <Row className=''>
                    //             <Col span={22} className='m-md20' >
                    //                 <Space>
                    //                     <Tag className="online-bg" > {item.type} </Tag>
                    //                     <Badge status="default" />
                    //                     <p className="fs14 fw400 m-0 text-grey" >{item.is_paid ? "Paid" : "Free"}</p>

                    //                 </Space>
                    //             </Col>
                    //             <Col span={2} className='text-right mentor-hover-icon m-md20 '>
                    //                 <Button shape="circle" type="text" icon={<MoreOutlined />}></Button>

                    //             </Col>

                    //             <Col span={24} className='md10'>
                    //                 <p className="fs18 fw400 m-0 lh21">{item.name}</p>
                    //             </Col>
                    //             <Col span={24} className='md15'>
                    //                 <p className="fs14 fw400 m-0 lh24"></p>
                    //                 <Paragraph ellipsis={{ rows: 2 }}>{item.description}</Paragraph>

                    //                 <p className="fs14 fw300 md15  ">
                    //                     <Users size={24} color="#999999" weight="thin" />
                    //                     <span style={{ marginLeft: '10px' }}> {item?.relation}</span>
                    //                 </p>
                    //                 <p className="fs14 fw300 md10 ">
                    //                     <Timer size={24} color="#999999" weight="thin" />
                    //                     <span style={{ marginLeft: '10px' }}> 1 month program</span>
                    //                 </p>
                    //             </Col>
                    //         </Row>
                    //     </Col>
                    // </Row>
                })
            }

           
            <Row>
                {
                    more && <Col span={24} className="text-center md30">
                        <Button type="text" onClick={() => callAPI()}>Load more</Button>
                    </Col>
                }

            </Row>


        </Col >


    );
}
export default Mentorship;
