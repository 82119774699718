import { Col, Row } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PodcastPlay from "../Podcastv2/PodcastPlay";



const ExplorePodcast = ({ data }) => {
    const [items, setItems] = useState(data);
    const history = useHistory();


    return (
        <Row gutter={[0, 18]}>

            {
                items.map((item, k) => {
                    return <Col xs={{span:24, offset:0}} lg={{span:18, offset:3}} key={k}><PodcastPlay item={item} /></Col >
                })
            }

        </Row >
    );
}
export default ExplorePodcast;