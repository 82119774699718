import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, withRouter } from "react-router";
import { Col, Row, Button, Space, message, Checkbox, Result, Skeleton, Tag, Badge } from "antd";
import AgoraRTC from "agora-rtc-sdk-ng"

import { connect } from "react-redux";
import actions from "../../store/actions/index";
import "../../assets/css/style.scss";
import "./livechat.scss"
import axios from "../../store/axios";
import GoliveChat from "./agora/GoliveChat";
import { Eye, Microphone, MicrophoneSlash, PhoneDisconnect, User, UserRectangle, VideoCamera, VideoCameraSlash, Warning } from "@phosphor-icons/react";
import Avatar from "antd/lib/avatar/avatar";
import useToken from '../../store/useToken';

import Toast from "../utils/Toast";


let options =
{
    // Pass your App ID here.
    appId: '558e93cd745349658b0def40df192631',
    // Set the channel name.
    channel: 'liveSyllo',
    // Pass your temp token here.
    token: '',
    // Set the user ID.
    uid: 0,
    // Set the user role
    role: ''
};

let channelParameters =
{
    // A variable to hold a local audio track.
    localAudioTrack: null,
    // A variable to hold a local video track.
    localVideoTrack: null,
    // A variable to hold a remote audio track.
    remoteAudioTrack: null,
    // A variable to hold a remote video track.
    remoteVideoTrack: null,
    // A variable to hold the remote user id.s
    remoteUid: null,
};

const GoLivev2 = (props) => {
    const url = useParams();
    let room_id = url["id"];

    const [showChat, setShowChat] = useState(false)
    const [start, setStart] = useState(false);
    const [localStream, setLocalStream] = useState(channelParameters);
    const [cameraMute, setCameraMute] = useState(false);
    const [audioMute, setAudioMute] = useState(false);
    const [client] = useState(AgoraRTC.createClient({ mode: "live", codec: "vp8" }));

    const [role, setRole] = useState(0);
    const [logined, setLogined] = useState(false);
    const [dataRTM, setDataRTM] = useState("");
    const [actionText, setActionText] = useState("Join");
    const [loadings, setLoadings] = useState(false);
    const [sessionClosed, setSessionClosed] = useState(false);
    const [members, setMembers] = useState(1);
    const [hostVideoMuted, setHostVideoMuted] = useState(false);
    const [duration, setDuration] = useState(0);
    const webSocket = useRef(null);
    const { token } = useToken();

    let history = useHistory();

    useEffect(() => {

        startRemoteVideo();

        if (room_id === "me") {
            setActionText('Go Live')
            startCamara();
        }
        else {
            FitchToken();
        }

        return async () => {
            if (room_id === "me") {
                localStream.localAudioTrack.close();
                localStream.localVideoTrack.close();

            }
            await client.leave();
            room_id = "me"
        }
    }, []);



    async function startRemoteVideo() {
        const localPlayerContainer = document.getElementById("me");
        client.on("user-published", async (user, mediaType) => {
            // Subscribe to the remote user when the SDK triggers the "user-published" event.
            await client.subscribe(user, mediaType);
            // Subscribe and play the remote video in the container If the remote user publishes a video track.

            if (mediaType == "video") {
                // Retrieve the remote video track.
                localStream.remoteVideoTrack = user.videoTrack;
                // Retrieve the remote audio track.
                localStream.remoteAudioTrack = user.audioTrack;
                // Save the remote user id for reuse.
                localStream.remoteUid = user.uid.toString();
                // Specify the ID of the DIV container. You can use the uid of the remote user.
                localPlayerContainer.id = user.uid.toString();
                localStream.remoteUid = user.uid.toString();
                // Append the remote container to the page body.
                localStream.remoteVideoTrack.play(localPlayerContainer);
            }
            // Subscribe and play the remote audio track If the remote user publishes the audio track only.
            if (mediaType == "audio") {
                // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
                localStream.remoteAudioTrack = user.audioTrack;
                // Play the remote audio track. No need to pass any DOM element.
                localStream.remoteAudioTrack.play();
            }
            // Listen for the "user-unpublished" event.
            client.on("user-unpublished", (user) => {

                if (!user._video_muted_ && !user?._audio_muted_) {
                    Toast('Host has closed the live session!', "error")
                    setSessionClosed(true);
                }
            });
        });
    }

    client.on("user-info-updated", async (uid, msg) => {

        if (msg === "mute-video") {
            setHostVideoMuted(true);
        }
        else if (msg === "unmute-video") {
            setHostVideoMuted(false);
        }

        //   case "mute-audio":  console.log(uid)
        //     case "unmute-audio":  console.log(uid)

    });

    useEffect(() => {
        const interval = setInterval(() => getRTCStatsFun(), 999);
    
        return () => clearInterval(interval);
      }, []);

    const getRTCStatsFun =()=>{
        
        const session = client.getRTCStats()
        console.log(session)
        setMembers(session.UserCount);
        setDuration(session.Duration);

    }


    async function startCamara() {
        const localPlayerContainer = document.getElementById("me");
        localPlayerContainer.id = options.uid;
        localStream.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        localStream.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        localStream.localVideoTrack.play(localPlayerContainer);
    }


    const FitchToken = async () => {
        setLoadings(true)
        await props.GetSteamingToken({ room_id: room_id, chat: showChat })
    }


    function muteCam(key) {
        let data = localStream;
        data.localVideoTrack.setEnabled(key);
        setLocalStream(data);
        setCameraMute(!cameraMute);
    }

    function muteAudio(key) {
        let data = localStream;
        data.localAudioTrack.setEnabled(key);
        setLocalStream(data);
        setAudioMute(!audioMute);
    }

    useEffect(() => {
        var data = props._token_details
        if (data !== null) {
            setRole(data.role)
            setDataRTM(data)

            if (data.uid !== undefined) {
                if (data.role === 1) {
                    joinHost(data);
                } else {
                    callAudience(data);
                }
                // connectSylloLiveWebsocket(data.uid);
            }
        }

    }, [props._token_details])

    async function joinHost(data) {
        // message.loading('Going live...')
        Toast("Going Live ...", "success")
        await client.setClientRole('host');
        // Join a channel.
        await client.join(data.app_id, data.room_id, data.token, data.uid);

        // Publish the local audio and video tracks in the channel.
        await client.publish([localStream.localAudioTrack, localStream.localVideoTrack]);

        Toast('You are live now!', "success")
        setStart(true)
        setLogined(true);
        setLoadings(false);
    }

    async function callAudience(data) {
        message.loading('Starting live...')
        await client.setClientRole('audience');
        // Join a channel.
        await client.join(data.app_id, data.room_id, data.token, data.uid);
        setStart(true)
        setLogined(true);
        setLoadings(false);
    };

    const clickJoinBtn = () => {
        FitchToken()
    };

    const LeaveChannel = async () => {

        if (!logined) {
            return
        }

        var url = "v1.0/stream/live/member/leave"
        if (role === 1) {
            url = "v1.0/stream/live"
        }
        await axios({
            method: "delete",
            url:  url,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token
            },
        }).then(async function () {
            if (room_id === 'me') {
                localStream.localAudioTrack.close();
                localStream.localVideoTrack.close();
            }
            await client.leave();
            try {
                await webSocket.current.close();
            }
            catch (err) {

            }

            history.goBack();
        }).catch(function () {
        });

    }

    const connectSylloLiveWebsocket = (member_id) => {
        // ws will fetch members count
        webSocket.current = new WebSocket(process.env.REACT_APP_WS_BASE_URL + `wss/live/member/join?token=${token}&member_id=${member_id}`);
        webSocket.current.onmessage = evt => {
            const message = JSON.parse(evt.data);
            setMembers(message.members);
        }
    }

    useEffect(() => {
        return () => webSocket.current !== null ? webSocket.current.close() : true;
    }, [])

    return (
        <>
            {
                (room_id !== "me") && !sessionClosed &&
                <Row>
                    {
                        loadings ? <Skeleton.Input block active /> :

                            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }} className="live-header">
                                <Row>
                                    <Col lg={{ span: 20, offset: 0 }} xs={{ span: 18, offset: 0 }} >
                                        <Space className="cursor" onClick={() => history.push("/profile/" + dataRTM?.rtm?.user_code)}>
                                            <Avatar size={55} src={dataRTM?.rtm?.img_url} ></Avatar>
                                            <p className="fs15 fw500 cursor" >{dataRTM?.rtm?.name}</p>

                                        </Space>
                                    </Col>

                                    <Col lg={{ span: 4, offset: 0 }} xs={{ span: 6, offset: 0 }} className='text-right' style={{ alignSelf: "center" }}>
                                        <Space >
                                            <User size={16} />
                                            <p className="fs12 fw400" >  {members} watching</p>
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                    }
                </Row>
            }
            <Row>
                {
                    sessionClosed ? <Col xs={{ span: 22, offset: 1 }} lg={{ span: 12, offset: 6 }} className='md60 go-live m-p8  m-ms5 '>

                        <Result

                            title="Live video ended!"
                            subTitle="The session has been closed by the host."
                            icon={<Warning size={100} color="#d51010" />}
                            extra={[
                                <Button type="primary" key="console" onClick={() => history.push("/dashboard")}>
                                    Go Home
                                </Button>,
                            ]}
                        />

                    </Col> :


                        <Col xs={{ span: 24, offset: 0 }} lg={start ? { span: 20, offset: 2 } : { span: 16, offset: 4 }} className='md20 go-live m-p8  m-ms5 '>

                            <Row gutter={16} className="m-padding-right-left ">
                                <Col lg={dataRTM?.extra?.chat && start ? 16 : 24} className="text-right">
                                    <Row >
                                        {
                                            room_id === "me" && start && <div className="watching">

                                                <Space size={3} align="center">
                                                    <Tag color="red"> <Badge color="red"></Badge> LIVE {duration.secondsToMMSS()} min</Tag>
                                                </Space>
                                                <Space size={3} align="center">
                                                    <User size={20} color="white" /> <span style={{ color: "white" }}>{members} watching</span>
                                                </Space>

                                            </div>
                                        }

                                        {
                                            hostVideoMuted ? <Col span={24} className='live-bg-img centered' style={{justifyContent:"center"}} >
                                                <UserRectangle size={200} color="#666666" weight="light" />
                                            </Col> :
                                                <Col span={24} className='live-bg-img' id="me" style={{ visibility: hostVideoMuted ? "hidden" : "visible" }}>
                                                </Col>
                                        }

                                        {/* <Button onClick={()=>getRTCStatsFun()}>state</Button> */}


                                    </Row>

                                    {
                                        (room_id === "me") &&
                                        <Row gutter={16} style={{ marginTop: "-100px" }}>
                                            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} className="text-center md20 " >

                                                <Space>
                                                    <Button type="text" className="chats-btn " onClick={() => muteCam(cameraMute)} icon={
                                                        cameraMute ?
                                                            <VideoCameraSlash size={24} color="#f7e8e8" weight="light" /> : <VideoCamera size={24} color="#f7e8e8" weight="light" />
                                                    }>
                                                    </Button>

                                                    {
                                                        start &&
                                                        <Button type="text" disabled={!start} className="chats-btn " onClick={() => LeaveChannel()} style={{ backgroundColor: "red" }} icon={<PhoneDisconnect size={24} color="#f7e8e8" weight="light" />}>
                                                        </Button>
                                                    }
                                                    <Button type="text" className="chats-btn " onClick={() => muteAudio(audioMute)} icon={
                                                        audioMute ?
                                                            <MicrophoneSlash size={24} color="#f7e8e8" weight="light" /> : <Microphone size={24} color="#f7e8e8" weight="light" />
                                                    }>
                                                    </Button>

                                                    {/* <Button className="btn chats-btn  ">
                                                        <GearSix ag size={24} color="#f7e8e8" weight="light" />
                                                    </Button> */}
                                                </Space>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* Chat */}

                                <Col lg={dataRTM?.extra?.chat && start ? 8 : 0} className="live-chat-v3 m-md70 " style={{ paddingLeft: '10px' }}>
                                    <GoliveChat data={dataRTM}></GoliveChat>
                                </Col>

                            </Row>


                            {
                                (room_id === "me") && !start && <Row justify={"center"}>
                                    <Col span={24} className={"text-center "}>

                                        {
                                            <p className="md70"> <span className="checkbox  fs14 fw400 " ><Checkbox checked={showChat} onChange={(evt) => setShowChat(evt.target.checked)}>Live Chat feature</Checkbox></span></p>
                                        }

                                        <p className={" fs15 fw400 md10"}>You can easily interact with your audience with Live Chat feature. Viewers <br /> can freely message & reply in the chat-area.</p>
                                        <p className={"md20"}>
                                            <Space className="mb-3">
                                                <Button type="dashed" onClick={() => props.history.goBack()}>Back</Button>
                                                {
                                                    !start &&
                                                    <Button loading={loadings} type={"primary"} className="" onClick={() => clickJoinBtn()}>{room_id === "me" ? actionText : "Watch"}
                                                    </Button>

                                                }
                                            </Space>

                                        </p>
                                    </Col>
                                </Row>
                            }
                            {
                                (room_id !== "me") && start &&
                                <Row justify={"center"}>
                                    <Col span={24} className={"text-center md50"}>
                                        <Button loading={loadings} type={"primary"} className="" onClick={() => LeaveChannel()}> Leave </Button>
                                    </Col>
                                </Row>
                            }

                        </Col>
                }

            </Row></>
    );
}


const mapStateToProps = (state) => ({
    _token_details: state.stream.token_details,
});
const mapDispatchToProps = (dispatch) => ({
    GetSteamingToken: (v) => dispatch(actions.GetSteamingToken(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GoLivev2)
);
