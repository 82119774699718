import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Radio, Space, Select, Button } from 'antd';
import './myhub.scss'
import MyHubChannel from './MyHubChannel';
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { useHistory, withRouter, useParams } from "react-router";
import WhatsAppGroup from '../WhatsApp/WhatsAppGroup';
import { GridFour } from "@phosphor-icons/react";
import MyHubMentorship from './MyHubMentorship';
import MyHubContent from './MyHubContent';
import ProfileCourse from '../MainDesktop/ProfileCourse';
import CampusSubscription from './CampusSubscription';
import Cookies from 'js-cookie';
import MyhubLiveClasses from './MyhubLiveClasses';

const { TabPane } = Tabs;
const { Option } = Select;


const MyHub = (props) => {
    const role = parseInt(Cookies.get("__role"));
    const [isOwned, setIsOwned] = useState(role !== 7 ? 1 : 0);
    const [channelFilter, setChannelFilter] = useState()
    const [allEntities, setAllEntities] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [entity, setEntity] = useState(0) // 0 will restrict to hit newsletter api on page load
    const [loader, setLoader] = useState(true)
    const history = useHistory();
    const params = useParams();
    const tab = params?.tab; // It can be null

    useEffect(() => {

        console.log(tab)
    }, [])

    useEffect(() => {
        if (props?._allnewsletter && props?._allnewsletter?.length !== 0) {
            setAllEntities(props._allnewsletter?.data)
            setLoader(false)
            setTotal(props._allnewsletter.total)
        }
        else {
            setLoader(false)
        }
    }, [props._allnewsletter])

    function changeEntity(key, type) {

        if (key == "social-channels") {
            setPage(1)
            setLoader(true);
            setEntity(key);
            setAllEntities([]);
            props.allNewsLetter({ page: 1, published: type, entity: key });
        }

        history.push(`/my-hub/${key}`);
    }


    // channel props
    function GetFilterChannels(data) {
        setChannelFilter(data)
    }

    return (
        <Row style={{ minHeight: "calc(100vh - 69px)" }}>
            <Col xxl={{ span: 10, offset: 7 }} lg={{ span: 12, offset: 6 }} md={{ span: 12, offset: 6 }} xs={{ span: 24, offset: 0 }} className="my-hub-entities md30" >
                <Row className=' mb-2'>
                    <Col md={24} xs={24}>
                        <Space>
                            <GridFour size={29} weight="fill" />
                            <h4 className='fs16  mb-0 ' style={{ color: 'black' }}> My Hub  </h4>
                        </Space>
                    </Col>
                </Row>
                <Tabs defaultActiveKey={tab || "feed"} onChange={(evt) => changeEntity(evt, true)} className="m-me108 m-galaxy-p0 mb-0" >
                    {
                        role !== 7 && <TabPane tab="My Content" key="feed" className='fs14'>
                            <MyHubContent />
                        </TabPane>
                    }

                    <TabPane tab="Channels" key="channels" className='fs14'>
                        <Row>
                            <Col lg={19} xs={16} className='md10' style={{ overflow: "scroll" }}>
                                <Radio.Group
                                    defaultValue={""} style={{ display: "inline-flex", marginBottom: "21px" }}
                                    buttonStyle="solid"
                                    onChange={(evt) => GetFilterChannels(evt.target.value)} >

                                    <Radio.Button
                                        value={""}
                                        className="margin-right"
                                        style={{ width: "max-content" }} >
                                        All  </Radio.Button>
                                    <Radio.Button
                                        value={0}
                                        className="margin-right"
                                        style={{ width: "max-content" }} >
                                        Public  </Radio.Button>
                                    <Radio.Button
                                        value={1}
                                        className="margin-right"
                                        style={{ width: "max-content" }} >
                                        Private  </Radio.Button>
                                    <Radio.Button
                                        value={2}
                                        className="margin-right"
                                        style={{ width: "max-content" }} >
                                        Paid  </Radio.Button>
                                    <Radio.Button
                                        value={3}
                                        style={{ width: "max-content" }}
                                        className="margin-right" >
                                        Free
                                    </Radio.Button>
                                </Radio.Group>

                            </Col>
                            {
                                role !== 7 && <Col lg={5} xs={8} className='channel-subscribed-pop-up'>
                                    <Space direction="vertical">
                                        <Space wrap>
                                            <Select defaultValue={1} className={'width120'} style={{ marginTop: '8px' }} onChange={(key) => setIsOwned(key)}>
                                                <Option value={1}>My Channel</Option>
                                                <Option value={0}>Subscribed</Option>
                                            </Select>
                                        </Space>
                                    </Space>
                                </Col>
                            }


                        </Row>
                        <MyHubChannel is_owned={isOwned} channelFilter={channelFilter} />

                    </TabPane>

                    <TabPane tab="Mentorship" key="mentorship" className='fs14'>
                        <Col span={24}>
                            <MyHubMentorship />
                        </Col>
                    </TabPane>
                    {
                        role !== 7 &&
                        <TabPane tab="Social Channels" key="social-channels" className='fs14' disabled >
                            <Col span={24} className='md10'>
                                <WhatsAppGroup data={allEntities} />
                            </Col>
                        </TabPane>
                    }
                    <TabPane tab="Mini Course" key="mini-course" className='fs14'>
                        <Col span={24} className='md10'>
                            <Row>
                                <Col xs={{ span: 24 }} lg={{ span: 24 }} className="text-right">
                                    <Button onClick={() => history.push("/course")} className="primary-light-btn" type="text">Create New</Button>
                                </Col>

                            </Row>

                            <ProfileCourse menu={true} />
                        </Col>
                    </TabPane>
                    <TabPane tab="Campus Subscription" key="campus" className='fs14' >
                        <Col span={24} className='md10'>
                            <CampusSubscription data={allEntities} />
                        </Col>
                    </TabPane>
                    <TabPane tab="Booked Classes" key="liveclasses" className='fs14' >
                        <Col span={24} className='md10'>
                            <MyhubLiveClasses data={allEntities} />
                        </Col>
                    </TabPane>
                </Tabs>



            </Col>
        </Row>
    );
}


const mapStateToProps = (state) => ({
    _allnewsletter: state.newsletter.allnewsletter,
    _newsletter_id: state.newsletter.id
});
const mapDispatchToProps = (dispatch) => ({
    allNewsLetter: (v) => dispatch(actions.allNewsLetter(v)),
    DeleteNewsletter: (v) => dispatch(actions.DeleteNewsletter(v)),
    CreateNewsletter: (v) => dispatch(actions.CreateNewsletter(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MyHub)
);

