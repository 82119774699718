import React from "react";
import { Col, Row, Space, Modal, Dropdown, Button, Tag, Avatar, } from "antd";
import { Coins, DotsThreeOutlineVertical, Timer } from "@phosphor-icons/react";

import { useHistory } from "react-router";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import Toast from "../utils/Toast";

const MentorshipCard = (props) => {
    const history = useHistory()
    const [modal, contextHolder] = Modal.useModal();

    const items = (is_published) => {
        var i = []

        if (is_published) {
            i.push({
                label: (
                    <span className="fs12 fw400"> Inactive</span>
                ),
                key: '1',
            });
        } else {
            i.push({
                label: (
                    <span className="fs12 fw400"> Active</span>
                ),
                key: '2',
            });
        }

        i.push({
            label: (
                <span className="fs12 fw400"> Delete</span>
            ),
            key: '3',
        })
        return i;
    };

    const confirm = () => {
        modal.confirm({
            centered: true,
            icon: <></>,
            content: <>
                <div >
                    Are you sure? Want to delete mentorship? This action can't be undo.
                </div>
            </>,
            className: "mentorship-delete",
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: (() => {
                axios({
                    method: 'delete',
                    url:  "v1.0/mentorship/new?id=" + props?.mentorship_id,
                    headers: {
                        Authorization: Cookies.get("Logintoken")
                    },
                }).then(() => {
                    Toast("Deleted Successfully!", "success");
                    window.location.reload();
                });
            })
        });
    };


    const menuClick = ({ key, id }) => {
        if (key == '1' || key == '2') {
            const payload = {
                action: key == '1' ? "unpublish" : "publish",
                id: props?.mentorship_id
            }
            axios({
                method: 'put',
                url:  "v1.0/mentorship/new",
                headers: {
                    Authorization: Cookies.get("Logintoken")
                },
                data: payload
            }).then(() => {
                Toast("Updated Successfully", "success");
                window.location.reload();
            });
        }
        else if (key == '3') {
            confirm();
        }
    };


    return (
        <Row className='mentorship-details mb-4' >
            <Col xs={17} lg={15} xl={15}>
                <div style={{ minHeight: "150px", padding: "20px 20px 0 20px" }}>
                    <p className="fs18 fw500 m-fs15 text-blue cursor" onClick={() => history.push(`/mentorship/${props?.mentorship_id}`)} > {props?.name} </p>
                    <span className="fs12 fw300 text-blue">{props?.type} </span>
                    {
                        props?.menu && <> <br />
                            {
                                props?.is_published ? <Tag color="green" className="md25">Active</Tag>
                                    : <Tag color="magenta" className="md25">Inactive</Tag>
                            }</>
                    }

                </div>
                <div className="border-w100" style={{ width: '100%', borderTop: '1px solid #bfb1b16b' }}></div>
                <Row>
                    <Col span={12} style={{ borderRight: "1px solid #bfb1b16b", padding: "10px" }}>
                        <Space>
                            <Avatar style={{ border: "none" }} size={20} src={require('../../assets/images/newdashboard/sylloCoin.svg').default} />
                            {
                                props?.showAmount && props?.is_paid ?
                                    <span style={{ color: "black" }}>{props?.amount} Coins</span> :
                                    <span style={{ color: "black" }}>{props?.is_paid ? "Paid" : "Free"}</span>
                            }

                        </Space>
                    </Col>
                    <Col span={12} style={{ padding: "10px" }}>
                        <Space>
                            <Timer size={20} color="#DA6A4D" weight="regular" />
                            <span className="fs14">{props?.tenure} months</span>

                        </Space>

                    </Col>
                </Row>

            </Col>
            <Col xs={7} lg={9} xl={9} className="mentorship-hub-img text-right" style={{ backgroundImage: `url(${props?.img_url})` }}>
                {
                    props?.menu && <Row>
                        <Col span={24} >
                            <Dropdown trigger={['click']} menu={{ items: items(props?.is_published), onClick: ({ key }) => menuClick({ key: key, item: "" }) }} placement={"bottomCenter"} overlayStyle={{ minWidth: "120px" }}>
                                <Button shape="circle" className="mentorship-btn" onClick={(e) => e.preventDefault()}
                                    icon={<DotsThreeOutlineVertical size={17} color="#0d0d0c" weight="fill" />}></Button>
                            </Dropdown>
                            {contextHolder}
                        </Col>
                    </Row>
                }

            </Col>
        </Row>

    );
}
export default MentorshipCard;