import React, { useState, useEffect } from "react";
import { Col, Row, Input, Space, Button, Divider, Dropdown, Select, Skeleton, message, Menu, Tag } from "antd";
import { MoreOutlined } from '@ant-design/icons';
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import BlankState from "../BlankState";
import { Plus } from "@phosphor-icons/react";

import Toast from "../utils/Toast";

const { Option } = Select;

const BankAccounts = (props) => {
    const [add, setAdd] = useState(false);
    const [loader, setloader] = useState(true);
    const [banks, setbanks] = useState([]);
    const [primarybank, setprimarybank] = useState("")
    const [account_type, setaccount_type] = useState(1);
    const [ifsc, setifsc] = useState("");
    const [name, setname] = useState("");
    const [bank, setbank] = useState([]);
    const [account_number, setaccount_number] = useState("");
    const [caccount_number, setcaccount_number] = useState("");
    const [showrest, setShowRest] = useState(false);
    // const [account_length, setAccount_length] = useState();



    useEffect(() => {
        getBank()
    }, [])


    const getBank = () => {
        axios({
            method: 'get',
            url:  "account/user/bank",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            const data = res.data.data
            const _primary = data.find((a) => a.primary)
            setprimarybank(_primary?.bank_acc_id)
            setbanks(data)
            setloader(false)
        })
    }

    const VerifyBank = (evt) => {
        let key = evt
        if (key.length === 11) {
            props.getBankDetails({
                "ifsc": key,
            }).then(() => {

            })
        }
        setifsc(evt)
    }

    useEffect(() => {
        if (props._bank.length !== 0) {
            setbank(props._bank)
            setShowRest(true)
        }

    }, [props?._bank])


    const addBank = () => {
        if (name === "") {
            Toast("Enter Account Holder Name","error")
            return
        }
        if (account_number === "") {
            Toast("Enter Account Number","error")
            return
        }
        if (account_number !== caccount_number) {
            Toast("Account Number Miss-Match","error")
            return
        }

        let exep = verifyAccountnumber()
        if (exep === 1) {
            return
        }

        props.addBank({
            "ifsc": ifsc,
            "account_number": account_number,
            "account_type": account_type,
            "name": name,
            "bank": bank?.BANK,
            "branch": bank?.BRANCH,
            "address": bank?.ADDRESS,
            "state": bank?.STATE,
            "city": bank?.CITY,
            "district": bank?.DISTRICT,
            "bank_code": bank?.BANKCODE,
            "micr": bank?.MICR
        }).then(() => {
            getBank()
            setAdd(false)
        })
    }


    const setPrimary = (evt) => {
        axios({
            method: 'put',
            url:  "account/user/bank?id=" + evt,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
            setloader(true)
            getBank()

        })
    }

    const menu = (item) => {
        return <Menu>
            {
                !item?.primary && <Menu.Item key={1} onClick={() => setPrimary(item.bank_acc_id)}>Set Primary</Menu.Item>
            }
            {
                !item?.primary && <Menu.Item key={2} >Delete</Menu.Item>
            }
        </Menu>

    };

    function verifyAccountnumber() {
        var length = new RegExp(/^[1-9]\d*$/);
        if (length.test(account_number) == true && account_number.length >= 9) {
            return 0;
        }
        else {
            Toast("Invalid Account Number! Account number should be greater than or equal to 9 digits.","error");
            return 1;
        }
    }

    return (

        <Row style={{ padding: "40px 0px 0px 30px" }} className="m-nopadding">
            <Col xs={{ span: 24 }} lg={{ span: 24 }} >
                <p className="m-0 fs18">Bank Account Detail</p>
                <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 18 }}>
                        <p className="text-grey">Manage Bank Account Information</p>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }} className="text-right" >
                        <Button onClick={() => setAdd(true)} className="primary-light-btn" type="text" icon={<Plus size={18} color="#1DB954" weight="regular" />
                        } style={{ display: 'inline-flex' }}> {' '} <span className="ps-1">Add Bank </span> </Button>
                    </Col>
                </Row>
                <Divider></Divider>
            </Col>

            {
                !add ?
                    <Col xs={{ span: 24 }} lg={{ span: 18 }} className="create-event-radio ">
                        {
                            loader ? <Skeleton></Skeleton> : banks.length === 0 ? <Row>
                                <Col xs={{ span: 22 }} lg={{ span: 22 }} >
                                    <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} />
                                </Col>
                            </Row> :
                                // <Radio.Group value={primarybank} className="md20 bank" onChange={(evt) => setPrimary(evt.target.value)}>

                                <>{banks.map((item, i) => {
                                    return <Row key={i} className='bank-list-card md15' >

                                        <Col xs={{ span: 22 }} lg={{ span: 22 }} style={{ padding: "18px" }}>
                                            <Space >
                                                <p className="fs16 fw500">{item?.bank}</p>
                                                <p className="fs14 fw400 text-grey">{item?.masked_account_number}</p>
                                                {item?.primary && <Tag color="geekblue">Primary</Tag>}

                                            </Space>
                                            <p className="fs12 fw300">{item?.bank}, {item?.branch}, {item?.district}, {item?.state}</p>
                                        </Col>
                                        <Col xs={{ span: 2 }} lg={{ span: 2 }} className='text-right pt-1'>
                                            {
                                                !item.primary && <Dropdown trigger={"click"} overlay={menu(item)} placement="bottom">
                                                    <Button type="text" shape="circle" onClick={(e) => e.preventDefault()} icon={<MoreOutlined />}>
                                                    </Button>
                                                </Dropdown>
                                            }

                                        </Col>

                                    </Row>

                                })}


                                    {/* </Radio.Group> */}</>
                        }
                    </Col> : <>
                        <Col span={24} className="category-input">
                            <Row>
                                <Col span={12} style={{ paddingRight: "10px" }}>
                                    <p style={{ color: "#484848" }} className="sub-text-light">Account Type</p>
                                    <Select
                                        className="category-select md10"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        value={account_type} onChange={(evt) => setaccount_type(evt)}
                                    >
                                        <Option value={1}>Savings</Option>
                                        <Option value={2}>Current</Option>
                                    </Select>
                                </Col>

                                <Col span={12} style={{ paddingLeft: "10px" }}>
                                    <p style={{ color: "#484848" }} className="sub-text-light">IFSC</p>
                                    <Input className="md10" placeholder="e.g. SBIN0000595" value={ifsc} onChange={(evt) => VerifyBank(evt.target.value)}></Input>
                                    <p style={{ color: "#484848", float: "right" }} className="sub-text-light fs12 md10">{bank?.BRANCH} </p>
                                </Col>
                            </Row>
                            {
                                showrest ? <>
                                    <p style={{ color: "#484848" }} className="sub-text-light md30">Beneficiary Name</p>
                                    <Input className="md10" value={name} onChange={(evt) => setname(evt.target.value)} placeholder="Beneficiary Name"></Input>
                                    <p style={{ color: "#484848" }} className="sub-text-light md30">Account Number</p>
                                    {/* <Input type={'number'} value={account_number} onChange={(evt) => setaccount_number(evt.target.value)} className="md10" placeholder="e.g. 343434343434"></Input> */}
                                    <Input type={'number'} value={account_number} onChange={(evt) => setaccount_number(evt.target.value)} className="md10" placeholder="e.g. 343434343434"></Input>
                                    <p style={{ color: "#484848" }} className="sub-text-light md30">Confirm Account Number</p>
                                    <Input type={'number'} value={caccount_number} onChange={(evt) => setcaccount_number(evt.target.value)} className="md10" placeholder="e.g. 343434343434"></Input>
                                </> : ""
                            }
                        </Col>
                        {
                            showrest && <Col span={24} className="md30 text-right mb-3">
                                <Button onClick={() => addBank()} type="primary">Save</Button>
                            </Col>
                        }  </>
            }
        </Row>

    );
}



const mapStateToProps = (state) => ({

    _bank: state.profile.bank_details,
});
const mapDispatchToProps = (dispatch) => ({
    getBankDetails: (v) => dispatch(actions.getBankDetails(v)),
    addBank: (v) => dispatch(actions.addBank(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BankAccounts)
);