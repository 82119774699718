import React, { useEffect, useState } from "react";
import { Row, Col, Space, Avatar, Modal, Typography, Skeleton } from 'antd';
import { useHistory } from "react-router-dom";
import EntityHeader from "./EntityHeader";
import { ChatTeardropText, Export, HandsClapping } from "@phosphor-icons/react";
import "../../assets/css/style.scss";
import PostViewer from "../Editor/PostViewer";
import useToken from "../../store/useToken"
import axios from "../../store/axios";
const { Paragraph } = Typography;


const EntityAnalytics = (props) => {
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(true);
    const history = useHistory();
    const { item } = props;
    const { token } = useToken();


    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoader(true);
        await axios({
            method: 'get',
            url:  `v1.0/analytics/insights/entity/${item?.entity_uuid}`,
            headers: {
                Authorization: token,
            },
        }).then((response) => {
            setData(response?.data.data)
        });
        setLoader(false);
    }

    return <>
        <Row style={{ overflowY: "scroll", height: "70vh" }}>
            {/* Entity body  */}
            <Col span={24}>
                <div className="md20" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)", borderRadius: '16px', padding: "20px" }}>
                    <Row>
                        <Col span={24}>
                            <div className="feed-structure-p" >
                                <div>
                                    <Avatar size="large" src={item?.creator?.avatar_url}> </Avatar>
                                </div>
                                <div className="w-100">
                                    <Row >
                                        <Col span={24}>
                                            <EntityHeader item={{ ...item, name: item?.creator?.full_name, username: item?.creator?.username, user_code: item?.creator?.user_code }} />
                                        </Col>
                                        <Col span={24} className="md10">
                                            {
                                                item?.title && <h6 className="news-header m-fs16">{item?.title}</h6>
                                            }
                                            {
                                                item?.entity_type === "Post" ? <div>
                                                    <PostViewer intState={item?.description} />
                                                </div> :
                                                    <Paragraph ellipsis={{
                                                        rows: 2, expandable: false,
                                                    }} className="mt-1 m-fs14 " style={{ color: "#4B5669" }}>{item?.meta_title}</Paragraph>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </div>
            </Col>
            <Col span={24} className="md20">
                {
                    loader ? <><Skeleton active></Skeleton> <Skeleton active></Skeleton></> :
                        <>
                            <Row>
                                <Col span={24} className="analytics-card">
                                    <Row gutter={16} >
                                        <Col span={10} className="m10">
                                            <div className="div">
                                                <HandsClapping size={32} color="#4b5669" weight="regular" />
                                                <p className="fs24 fw700 md5"> {item?.likes} <span className="fs14 fw400" style={{ color: "#4b5669" }} > Likes </span> </p>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="div">
                                                <ChatTeardropText size={32} color="#4b5669" weight="regular" />
                                                <p className="fs24 fw700 md5"> {item?.comments} <span className="fs14 fw400" style={{ color: "#4b5669" }} > Comments </span> </p>
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <div className="div">
                                                <Export size={32} color="#4b5669" weight="regular" />
                                                <p className="fs24 fw700 md5"> {data?.share} <span className="fs14 fw400" style={{ color: "#4b5669" }} > Share </span> </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col span={24} className="analytics-card md20">
                                    <Row gutter={16} className="">
                                        <Col span={8}>
                                            <div className="div">
                                                <p className="fs14 fw400" style={{ color: "#4b5669" }}> Impressions </p>
                                                <p className="fs24 fw700 md5"> {data?.impressions}  </p>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="div">
                                                <p className="fs14 fw400" style={{ color: "#4b5669" }}> Profile Visit </p>

                                                <p className="fs24 fw700 md5"> {data?.profile_visit}  </p>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div className="div">
                                                <p className="fs14 fw400" style={{ color: "#4b5669" }}> Detail Expands </p>

                                                <p className="fs24 fw700 md5"> {data?.detail_expands}  </p>
                                            </div>
                                        </Col>
                                        <Col span={8} className="md20">
                                            <div className="div">
                                                <p className="fs14 fw400" style={{ color: "#4b5669" }}> Engagements </p>

                                                <p className="fs24 fw700 md5"> {data?.engagements}  </p>
                                            </div>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>


                        </>
                }

            </Col>

        </Row>
    </>
}
export default EntityAnalytics;