import { Col, Row } from "antd";
import React from "react";


const ChannelFooter = () => {
    return (
        <Row>
            <Col span={24}>
                <p className="fs12 fw500" style={{ opacity: '0.5' }}>
                    <a href={"https://syllo.co/community-guidlines.html"}>Terms & Conditions</a>    .   <a href={"https://syllo.co/privacy.html"}> Privacy Policy </a>    .   
                    <a href={"https://syllo.co/content-policy.html"}> EULA Cookie Policy </a> 
                    ©️  Syllo2023 </p>
            </Col>
        </Row>
    );
}
export default ChannelFooter;