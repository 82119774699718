import { CaretRightOutlined } from "@ant-design/icons";
import { LinkedinLogo, TwitterLogo } from "@phosphor-icons/react";
import { Button, Col, Collapse, Divider, Row, Select, Space, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import CoinInput from "../Input/CoinInput";

const { Option } = Select;
const { Panel } = Collapse;

const ContentAdvancedFeature = (props) => {

   const role = parseInt(Cookies.get('__role'));
   const [share_with_business, setShare_with_business] = useState(true);
   const [linkedin, setLinkedIn] = useState(false);
   const [loading, setloading] = useState(false);
   const [paid, setPaid] = useState(true);
   const [twitter, setTwitter] = useState(false);
   const [isTwitterConnected, setIsTwitterConnected] = useState(false);
   const [isLinkedInConnected, setIsLinkedInConnected] = useState(false);

   const [amount, setAmount] = useState(0);
   const [timeline, setTimeline] = useState(true);
   const [disableComments, setDisableComments] = useState(false);
   const [myChannels, setMyChannels] = useState([]);
   const [selectedChannels, setSelectedChannels] = useState(null);
   const [connected, setConnection] = useState(null);
   const [rupee, setRupee] = useState(0);

   useEffect(() => {
      loadChannelList();
      if (props.eventOptions === false) {
         checkSocialMediaAppConnected('TW');
         checkSocialMediaAppConnected('LI');
         if (role === 8) {
            checkBusinessConnected()
         }
      }

   }, [props.eventOptions]);

   useEffect(() => {
      if (props?._channels) {
         setMyChannels(props._channels.owned);
      }
   }, [props?._channels]);

   const loadChannelList = async () => {
      setloading(true);
      await props.ChannelList();
      setloading(false);
   }

   useEffect(() => {
      if (props?.onUpdate) {
         const share_in_app = []
         if (linkedin) {
            share_in_app.push('LI')
         }
         if (twitter) {
            share_in_app.push('TW')
         }
         let data = {
            "collaborators": [],
            "share_with_business": share_with_business,
            "share_in_channels": selectedChannels ? [selectedChannels] : [],
            "share_in_app": share_in_app,
            "timeline": timeline,
            "price": paid ? amount : 0,
            "disable_comments": disableComments,
         }
         props.onUpdate(data);
      }

   }, [linkedin, twitter, selectedChannels, amount, timeline, disableComments, paid]);

   useEffect(() => {
      if (selectedChannels === null || selectedChannels === undefined) {
         setTimeline(true);
      }
   }, [selectedChannels]);

   const onChangeAmount = (v) => {
      if (v > 1000) {
         return
      }
      setRupee(v * 22);
      setAmount(v);
   }


   const checkSocialMediaAppConnected = (platform) => {
      axios({
         method: 'get',
         url: `lighthouse/app/${platform}/status`,
         headers: {
            Authorization: Cookies.get("Logintoken"),

         },
      }).then((response) => {
         const connected = response.data.data?.connected
         if (platform === "TW") {
            setIsTwitterConnected(connected);
         }
         else if (platform === "LI") {
            setIsLinkedInConnected(connected);
         }

      });
   }
   const checkBusinessConnected = () => {
      axios({
         method: 'get',
         url: `v1.0/business/checkConnection`,
         headers: {
            Authorization: Cookies.get("Logintoken"),

         },
      }).then((response) => {

         const connected = response.data.data
         setConnection(connected);

      });
   }

   return (
      <Row className={props?.className ? props?.className : ""}>
         <Col span={24} className="trending-card p-4">
            {
               connected !== null && connected.connected === true && <Row style={{ marginBottom: "20px" }}>
                  <Col md={20} xs={20}>
                     <span>Share to <strong>@{connected?.username} </strong> Timeline</span>
                  </Col>
                  <Col md={4} xs={4} className="text-right">
                     <Switch checked={share_with_business} onChange={(e) => setShare_with_business(e)}></Switch>
                  </Col>


               </Row>
            }
            {
               !props?.isChannelOnly && <Row >
                  <Col md={20} xs={20}>
                     <span>This is a <strong>Paid-content</strong></span>
                  </Col>
                  <Col md={4} xs={4} className="text-right">
                     <Switch checked={paid} onChange={(e) => setPaid(e)}></Switch>
                  </Col>
                  {
                     paid && <Col span={24} className="md10">
                        <CoinInput value={amount} onChange={(e) => onChangeAmount(e)} />
                        {/* <Input max={9999} maxLength={4} type="number" value={amount} onChange={(e) => onChangeAmount(e.target.value)} placeholder="Set the amount of Coins" size="small" addonAfter={<Tag className="text-grey">~ ₹{rupee}</Tag>} /> */}
                     </Col>
                  }

               </Row>
            }

            <Row>

               {
                  !loading && (myChannels.length > 0 || isLinkedInConnected || isTwitterConnected) && <Col span={24} className="md10">  <strong >Also Post on:</strong>  </Col>
               }


               <Col span={24} className="md10">
                  <Space size={10}>
                     {
                        !loading && myChannels.length > 0 && <>
                           <Select style={{ width: '180px' }} allowClear placeholder="Select channel"
                              onChange={(evt) => setSelectedChannels(evt)}>
                              {
                                 myChannels?.map((item, i) => {
                                    return <Option key={i} value={item.uid}>{item.name}</Option>
                                 })
                              }
                           </Select>
                           <Divider type="vertical"></Divider>
                        </>
                     }
                     {
                        isLinkedInConnected && <Tooltip title="Post on LinkedIn">
                           <Button onClick={() => setLinkedIn(!linkedin)} type={linkedin ? "primary" : 'default'} shape="circle" icon={<LinkedinLogo size={18} />}></Button>
                        </Tooltip>
                     }
                     {
                        isTwitterConnected && <Tooltip title="Post on Twitter">
                           <Button onClick={() => setTwitter(!twitter)} type={twitter ? "primary" : 'default'} shape="circle" icon={<TwitterLogo size={18} />}></Button>
                        </Tooltip>
                     }

                  </Space>

               </Col>
               {
                  props.eventOptions === false && <Col span={24}>
                     <Collapse
                        bordered={false}
                        defaultActiveKey={[]}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        className="site-collapse-custom-collapse"
                     >
                        <Panel header={<strong>Advanced</strong>} key="1" className="site-collapse-custom-panel post-collapse-svg ">
                           <Row>
                              <Col md={20} xs={20}>
                                 <span>Do not post on Timeline</span>
                              </Col>
                              <Col md={4} xs={4} className="text-right">
                                 <Switch disabled={selectedChannels === null || selectedChannels === undefined} checked={!timeline} onChange={(c) => setTimeline(!c)}></Switch>
                              </Col>
                           </Row>
                           <Row className="md10">
                              <Col md={20} xs={20}>
                                 <span>Disable Comments</span>
                              </Col>
                              <Col md={4} xs={4} className="text-right">
                                 <Switch checked={disableComments} onChange={(c) => setDisableComments(c)}></Switch>
                              </Col>
                           </Row>
                        </Panel>
                     </Collapse>
                  </Col>

               }
            </Row>
         </Col>

      </Row>
   );
}

const mapStateToProps = (state) => ({
   _channels: state.channel.channel_list,
});
const mapDispatchToProps = (dispatch) => ({
   ChannelList: (v) => dispatch(actions.ChannelList(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentAdvancedFeature));
