
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Tabs, Space, Button, Skeleton, Col, Row } from 'antd';
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import '../Event/event.scss';
import moment from "moment";

import Toast from "../utils/Toast";
import { Clock, MapPin } from "@phosphor-icons/react";

const { TabPane } = Tabs;
class EventsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allEvents: [],
            bookings: [],
            past: [],
            loading: true
        };
    }


    componentDidMount() {
        this.getEvents();
    }

    async getEvents() {
        await this.props.allEventsBookings({ user_code: "" }).then(() => {

            this.setState({
                allEvents: this.props._all_events.all,
                bookings: this.props._all_events.bokings,
                past: this.props._all_events.ended,
                loading: false
            });
        })
    }


    cancelEvent(id) {
        this.props.cancelEvent({ id: id }).then(() => {
            Toast("Cancelled Successfully! Refund will be intiated soon if any", "success")
        })
    }

    render() {
        const { allEvents, bookings, past } = this.state
        return (
            <div style={{ padding: "0px 10px" }}>
                {
                    this.state.loading ? <><Skeleton active /><Skeleton active /></> :

                        <Tabs defaultActiveKey="1" >
                            <TabPane tab="My Events" key="1">
                                <Row gutter={14} className="md5">

                                    {
                                        allEvents.map((_item, i) => {
                                            return <Col xxl={8} lg={12} xs={24} key={i} className="md15">
                                                <Row className="explore-card">


                                                <Col span={24}
                                                        style={{ height: "100px", marginBottom: "10px", backgroundImage: `url(${_item.img_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                                                    </Col>



                                                    <p >
                                                        <Space className="event_card_ mt8 " align={"center"} size={"small"} split={<i style={{ fontSize: "4px", marginTop: "-10px" }} className="fa fa-circle"></i>}>
                                                            {
                                                                _item.is_paid === false ? <span className="text-grey ">Free</span> : <span className="text-grey ">{_item.amount} Coins</span>

                                                            }
                                                        </Space>
                                                    </p>
                                                    <Col span={24}>
                                                        <a href={"/event/" + _item.schedule_uid}><h5 className="heading-text fs20"> {_item.name} </h5></a>
                                                        <p className="text-grey">
                                                            <Space align="center">
                                                                <MapPin size={20} color="#4B5669" weight="fill" />
                                                                {_item.venue === null ? "Online" : _item.venue}
                                                            </Space>
                                                        </p>
                                                        <p className="text-grey md10">
                                                            <Space align="center">
                                                                <Clock size={20} color="#4B5669" />
                                                                {moment(_item.schedule_date).format('Do MMM,YYYY, h:mm:ss a')}
                                                            </Space>
                                                        </p>

                                                    </Col>

                                                </Row>
                                            </Col>
                                        })
                                    }

                                </Row>
                            </TabPane>
                            <TabPane tab="Bookings" key="2">
                                <div className="row">
                                    {
                                        bookings.map((item, i) => {
                                            return <div key={i} className="col-md-4 padding20">
                                                <div className="row explore-card">
                                                    <div className="col-md-12 "
                                                        style={{ height: "100px", marginBottom: "10px", backgroundImage: `url(${item.img_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                                                    </div>

                                                    <div className="col-md-12 ">
                                                        <p className="sub-text">
                                                            <Space className="event_card_" align={"center"} size={"small"} split={<i style={{ fontSize: "5px", marginTop: "-10px" }} className="fa fa-circle"></i>}>
                                                                <span className="sub-text"> {item.venue === null ? "Online" : "Offline"}</span>


                                                                {
                                                                    item.is_paid === false ? <span className="sub-text ">Free</span> : <span className="sub-text ">₹{item.amount} onwards</span>

                                                                }
                                                            </Space>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-12 ">
                                                        <a href={"/event/" + item.schedule_uid}><h5 className="heading-text fs20"> {item.name} </h5></a>
                                                        <p className="sub-text">
                                                            <Space align="center">
                                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 21a29.776 29.776 0 0 1-3.5-3.531C6.9 15.558 5 12.712 5 9.999a7 7 0 0 1 11.952-4.95A6.955 6.955 0 0 1 19 9.999c0 2.713-1.9 5.559-3.5 7.47a29.777 29.777 0 0 1-3.5 3.53zm0-14a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" fill="#000" fill-opacity=".8" />
                                                                </svg>
                                                                {item.venue === null ? "Online" : item.venue}
                                                            </Space>
                                                        </p>
                                                        <p className="sub-text md10">
                                                            <Space align="center">
                                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 2.667a9.334 9.334 0 1 0 .001 18.668A9.334 9.334 0 0 0 12 2.667zm0 17.083c-4.28 0-7.75-3.47-7.75-7.75 0-4.279 3.47-7.75 7.75-7.75 4.28 0 7.75 3.471 7.75 7.75 0 4.28-3.47 7.75-7.75 7.75z" fill="#000" fill-opacity=".8" />
                                                                    <path d="m14.603 14.79-2.716-2.335V6.848c0-.1-.068-.181-.152-.181h-.916c-.084 0-.152.082-.152.181v6.238c0 .06.023.114.063.148l3.15 2.732c.068.059.163.04.213-.039l.545-.883c.05-.084.034-.197-.035-.254z" fill="#000" fill-opacity=".8" />
                                                                </svg>
                                                                {moment(item.schedule_date).format('Do MMM,YYYY, h:mm:ss a')}
                                                            </Space>
                                                        </p>

                                                    </div>
                                                    {
                                                        item.status === "Active" ? <div className="col-md-12 text-center">  <hr></hr> <Button type="text" onClick={() => this.cancelEvent(item.id)} >Cancel Event</Button> </div> : ""
                                                    }

                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </TabPane>
                            <TabPane tab="Past Events" key="3">
                                <div className="row">
                                    {
                                        past.map((_item) => {
                                            return <div className="col-md-4 padding20">
                                                <div className="row explore-card">
                                                    <div className="col-md-12 "
                                                        style={{ height: "100px", marginBottom: "10px", backgroundImage: `url(${_item.img_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                                                    </div>
                                                    <div className="col-md-12 ">
                                                        <p className="sub-text">
                                                            <Space className="event_card_ mt8" align={"center"} size={"small"} split={<i style={{ fontSize: "5px", marginTop: "-10px" }} className="fa fa-circle"></i>}>
                                                                <span className="sub-text"> {_item.venue === null ? "Online" : "Offline"}</span>


                                                                {
                                                                    _item.is_paid === false ? <span className="sub-text ">Free</span> : <span className="sub-text ">₹{_item.amount} onwards</span>

                                                                }
                                                            </Space>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-12 ">
                                                        <a href={"/event/" + _item.schedule_uid}><h5 className="heading-text fs20"> {_item.name} </h5></a>
                                                        <p className="sub-text">
                                                            <Space align="center">
                                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 21a29.776 29.776 0 0 1-3.5-3.531C6.9 15.558 5 12.712 5 9.999a7 7 0 0 1 11.952-4.95A6.955 6.955 0 0 1 19 9.999c0 2.713-1.9 5.559-3.5 7.47a29.777 29.777 0 0 1-3.5 3.53zm0-14a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" fill="#000" fill-opacity=".8" />
                                                                </svg>
                                                                {_item.venue === null ? "Online" : _item.venue}
                                                            </Space>
                                                        </p>
                                                        <p className="sub-text md10">
                                                            <Space align="center">
                                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 2.667a9.334 9.334 0 1 0 .001 18.668A9.334 9.334 0 0 0 12 2.667zm0 17.083c-4.28 0-7.75-3.47-7.75-7.75 0-4.279 3.47-7.75 7.75-7.75 4.28 0 7.75 3.471 7.75 7.75 0 4.28-3.47 7.75-7.75 7.75z" fill="#000" fill-opacity=".8" />
                                                                    <path d="m14.603 14.79-2.716-2.335V6.848c0-.1-.068-.181-.152-.181h-.916c-.084 0-.152.082-.152.181v6.238c0 .06.023.114.063.148l3.15 2.732c.068.059.163.04.213-.039l.545-.883c.05-.084.034-.197-.035-.254z" fill="#000" fill-opacity=".8" />
                                                                </svg>
                                                                {moment(_item.schedule_date).format('Do MMM,YYYY, h:mm:ss a')}
                                                            </Space>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </TabPane>
                        </Tabs>
                }
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    _all_events: state.profile.bookings
});
const mapDispatchToProps = (dispatch) => ({
    allEventsBookings: (v) => dispatch(actions.allEventsBookings(v)),
    cancelEvent: (v) => dispatch(actions.cancelEvent(v)),
});


export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EventsList)
);
