import {  Coins } from "@phosphor-icons/react";
import { Avatar, Button, Col, Row, Space, Spin, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import useToken from "../../store/useToken";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Toast from "../utils/Toast";
import axios from "../../store/axios";


const SylloCoinHistory = () => {
    const [plans, setPlans] = useState([]);
    const [wallet, setWallet] = useState({});
    const [loader, setloader] = useState(false)
    const { token } = useToken();
    const history = useHistory();

    useEffect(() => {
        getWallet();
        getPlans();
    }, [])

    const getPlans = async () => {
        setloader(true);
        await axios({
            method: 'get',
            url: `v1.0/wallet/history`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setPlans(data);
        }).catch((error) => {
            Toast(error.response.data.message,"error")
        })
        setloader(false);
    }

    const getWallet = () => {
        axios({
            method: 'get',
            url: `v1.0/reward/wallet`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setWallet(data);
        }).catch((error) => {
            Toast(error.response.data.message,"error")
        })
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'remark',
            key: 'remark',
            width: "50%",
            render: (key, record) => <>
                <Row>
                    <Col span={24}>
                        <Space size={20}>
                            {
                                record?.trans_type === "TP" ?  <Avatar  src={require('../../assets/images/newdashboard/sylloCoin.svg').default} 
                                size={{xs:30, md:30, lg:45}} /> :  <Tooltip title={record?.creator?.full_name}><Avatar   onClick={()=>history.push(`/profile/`+record?.creator?.user_code)} className="cursor" src={record?.creator?.avatar_url} 
                                size={{xs:30, md:30, lg:45}} /></Tooltip>
                            }
                           
                            <div>
                                <p className="fs16 fw600"> {key} </p>
                                <Space >
                                    <p className="fs12 fw400 dark-grey pt-1"> {moment(record?.created_at).format('DD/MM/YYYY | h:m a')} </p>
                                </Space>

                            </div>
                        </Space>
                    </Col>
                </Row>
            </>
        },
        {
            title: 'Transaction',
            dataIndex: 'trans_type',
            key: 'trans_type',
            render: (key, record) => <Row>
                <Col span={24}>
                    {
                        record.coins === 0 ? <p>Free</p>:
                        <p>{key === "DB" && "-"}{record.coins} Coins</p>
                    }
                    
                    {
                        record.entity ? <Tag className="fs10" color="green">{record.entity}</Tag>:
                        <small className="text-grey">₹{record.amount}</small>
                    }
                    
                </Col>
            </Row>
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (key) => <>{key}  </>,
            responsive: ['md', 'lg']
        }

    ];

    return (
        <Row>
            <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 6 }} className="md50">
                <Row>
                    <Col xs={24} md={12} className="md10">
                        <Space>
                            <img src={require('../../assets/images/newdashboard/sylloCoin.svg').default} height={30} alt="" />
                            <p className="fs24 fw600"> Coins History </p>
                        </Space>
                    </Col>
                    <Col xs={24} md={12} className="text-right m-text-left md10">

                        <Space size={20}>
                            <div className=" text-right">
                                <p className="fw400"> Current Balance </p>
                                <p className="fs14 fw-bold"> {wallet?.coins} Coins </p>

                            </div>
                            <Button onClick={() => history.push('/coins')} type="primary" className="fs14 fw500"> <span className=" pe-2">
                                <Coins size={22} color="#ffffff" weight="light" /></span> Buy More </Button>
                        </Space>


                    </Col>
                    <Col span={24} className="md40 mb-5">

                        <Spin spinning={loader} delay={500}>

                            <Table dataSource={plans} pagination={{ position: ['bottomRight'] }} columns={columns} />

                        </Spin>

                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default SylloCoinHistory;