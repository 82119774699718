import { Button, Space, Tooltip, } from "antd";
import { CalendarPlus, Export, UserCircle } from "@phosphor-icons/react";
import React, { useState } from "react";
import { RWebShare } from "react-web-share";


const EventMenu = ({ item }) => {


    return (

        <div className="event-drawer">
            <Space>
                {
                    item?.profile && <Tooltip title='View Profile'><Button icon={<UserCircle size={24} color="#728096" />} type="text"></Button></Tooltip>
                }

                <Tooltip title='Save to Google Calendar'>
                    <Button icon={<CalendarPlus size={24} color="#728096" />} type="link" target="_blank"
                        href={
                            "https://calendar.google.com/calendar/u/0/r/eventedit?text=" +
                            item?.name +
                            "&dates=" +
                            item?.schedule_date_from +
                            "Z&details=" +
                            item?.description +
                            `More at ${process.env.REACT_APP_URL} `
                        }></Button>
                </Tooltip>

                    <RWebShare
                        data={{
                            text: item?.description,
                            url: `${process.env.REACT_APP_URL}event/` + item?.schedule_uid,
                            title: item?.name,
                        }}

                    >
                       <Tooltip title='Share'> <Button icon={<Export size={24} color="#728096" />} type="text"></Button></Tooltip>
                    </RWebShare>



            </Space>

        </div>
    );
}
export default EventMenu;