import React, { useState, useEffect } from "react";
import { Avatar, Button, Col, Divider, Progress, Row, message, Tag, Skeleton, Space } from "antd";
import { DotsThreeVertical } from "@phosphor-icons/react";
import Cookies from "js-cookie";
import axios from "../../store/axios";
import BlankState from "../BlankState";
import moment from "moment";
import { useHistory } from "react-router";
import LoadMoreView from "../utils/LoadMoreView";


const MentorshipMembers = (props) => {

    const [data, setData] = useState([]);
    const [lastItems, setLastItems] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loader, setLoader] = useState(true);
    const history = useHistory()


    useEffect(() => {
        MentorshipAPI();
    }, [])


    async function MentorshipAPI() {
        setLoader(true)
        await axios({
            method: 'get',
            url:  `v1.0/mentorship/bookings?count=10&offset=${offset}&mybooking=${props?.my}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            setLoader(false)
            const data = res.data.data
            setLastItems(data);
            setData((o) => [...o, ...data]);
        })
        setOffset(offset + 10)
    }


    return (
        <Col span={24} >

            <Row>
                {
                    data.map((item, i) => {
                        return <Col key={i} span={24} className='hub-member mb-4' >
                            <Row>
                                <Col className='cursor' xs={21} lg={20} md={20} xl={20} onClick={() => history.push("/mentorship-booking/" + item?.booking_id)}>
                                    <Space>
                                        <Avatar src={item.learner.img} size={30}></Avatar>
                                        <p className="fs14 fw500" >{item.learner.name}</p>
                                    </Space>
                                </Col>

                                <Col xs={3} lg={4} md={4} xl={4} className='text-end'>
                                    <Button type="text" icon={<DotsThreeVertical size={25} color="#54514f" />}></Button>
                                </Col>
                                <Col span={24} className=''>
                                    <Divider className="mb-1" />
                                </Col>
                            </Row>
                            <Row className='md10 cursor' onClick={() => history.push("/mentorship-booking/" + item?.booking_id)}>
                                <Col xs={18} lg={19} md={18} xl={18} >
                                    <p className="fs16 fw600 m-fs14 line-h14" >{item.mentorship_name}</p>
                                    <p className="fs12 md5 text-grey">{moment(item?.datetime).format("YYYY-MM-DD h:mm A")}</p>
                                </Col>
                                <Col xs={6} lg={5} md={6} xl={6} className='text-end'>
                                    {
                                        item.status === "Active" ? <Tag color="green" className="md25">Active</Tag>
                                            : <Tag color="magenta" className="md25">Completed</Tag>
                                    }

                                </Col>

                            </Row>
                        </Col>
                    })
                }
                {
                    loader && <Skeleton active></Skeleton>
                }


                {
                    data?.length === 0 &&
                    <Col span={24} className="md60 text-center">
                        <BlankState img={require("../../assets/images/blank/no-data-found.svg").default} height={200} />

                    </Col>

                }
                <Col span={24}>
                    <LoadMoreView loader={loader} data={lastItems} offset={offset} onLoadmore={() => MentorshipAPI()} />
                </Col>


            </Row>


        </Col>
    );
}
export default MentorshipMembers;