
import { Col, Row, Button, Tag, Alert, Avatar, Skeleton, Descriptions } from "antd";
import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import Toast from "./Toast";
import axios from "../../store/axios";

const PaymentCreditPage = (props) => {
    const history = useHistory()
    const [loader0, setLoader0] = useState(true);
    const [loader, setLoader] = useState(false);
    const [paySuccess, setLoaderPaySuccess] = useState(false);
    const [wallet, setWallet] = useState({});

    const { title, entity, amount, onChange } = props;

    useEffect(() => {
        fetchWallet();
    }, []);

    const fetchWallet = async () => {
        var params = {};
        if (props?.user_code) {
            params['user_code'] = props?.user_code
            params['entity'] = props?.entity_type === "CHANNEL" ? 103 :
                props?.entity_type === "LIVE_CLASS" ? 105 : 
                props?.entity_type === "MENTORSHIP" ? 102 : 
                ""
        }
        setLoader0(true);
        await axios({
            method: 'get',
            url: `v1.0/wallet`,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
            params: params
        }).then((response) => {
            const data = response.data.data
            setWallet(data);
        });
        setLoader0(false);
    }

    const paytoSubcribe = async (hasPlan = false) => {
        var values = { "id": props?.id, 'hasPlan': hasPlan, ...props }
        if (props?.entity_type) {
            values.entity_type = (props?.entity_type).toUpperCase();
        }
        setLoader(true);
        await axios({
            method: 'post',
            url: "v1.0/wallet/buy",
            data: values,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            if (data.bought) {
                Toast("Success", "success");
                setLoaderPaySuccess(true);
                onChange({
                    status: "success",
                    modal: false,
                    ...data
                })
                return
            }
        }).catch((r) => {
            onChange({
                status: "failed",
                modal: true
            })
            Toast(r.response?.data?.message, "error");
        });
        setLoader(false);
    }

    return (
        <Row className={"padding20"} gutter={20}>
            {
                loader0 ? <Col span={24}>
                    <Skeleton.Button active></Skeleton.Button>
                    <Skeleton active className="md10"></Skeleton>
                    <div className="text-center md20">
                        <Skeleton.Button active></Skeleton.Button>
                    </div>
                </Col> :
                    <>
                        <Col span={24}>
                            <Tag color={"green"}>{entity}</Tag>
                        </Col>
                        <Col lg={17} xs={15} className='md10'>
                            <p className="fs16">{title}</p>
                           <h6 className="md35 bold">Coins Required :  <Avatar style={{ border: "none", marginTop: "-1px" }} size={20} src={require('../../assets/images/newdashboard/sylloCoin.svg').default} /> {parseFloat(amount).toFixed(0)}</h6>
                           

                        </Col>
                        <Col lg={7} xs={9}>
                            <div className={"trending-card text-right"}>
                                <p className="fs12 fw300 m-align-explore ">Current Balance</p>
                                <p className="fs12 m-align-explore "><b>{wallet?.coins} Coins</b></p>
                            </div>
                        </Col>
                        {
                            wallet?.benefit && (!wallet?.benefit?.is_partial || (wallet?.benefit?.used_count < wallet?.benefit?.benefit_units)) ?
                                <Col span={24} className="md10 text-center">
                                    <Alert className="text-left" type="info" message="Note: As a member of campus, you have the privilege of freely accessing and joining content."></Alert>
                                    <Descriptions className="md15" column={2} bordered={true} layout="vertical" style={{ width: "100%" }} >
                                        <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Remaining Free Access">
                                            {wallet?.benefit?.is_partial ? wallet?.benefit?.benefit_units - wallet?.benefit?.used_count : "Unlimited"}</Descriptions.Item>
                                        <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Total no. of Access">
                                            {wallet?.benefit?.is_partial ? wallet?.benefit?.benefit_units : "Unlimited"}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Button className="md20" loading={loader} type={"primary"} onClick={() => paytoSubcribe(true)}>Redeem Now</Button>
                                </Col>
                                :
                                <>
                                    <Col span={24} className={"text-center md30"}>
                                        {
                                            paySuccess ? <Alert message="Payment Success" type="success" showIcon /> :

                                                amount > wallet?.coins ? <Button loading={loader} type={"danger"} onClick={() => history.push("/coins")}>Buy More Coins</Button>
                                                    : <Button loading={loader} type={"primary"} onClick={() => paytoSubcribe()}>Buy Now</Button>
                                        }

                                    </Col>
                                    <Col span={24} className={"text-center md5"}>
                                        {
                                            amount > wallet?.coins ? <p className={"color-red"}>Insufficient Balance</p> : <p className={"fs12 text-grey"}>This a lifetime purchase. You may view all your articles in “My Hub” Tab. </p>
                                        }
                                    </Col>
                                </>
                        }
                    </>
            }


        </Row>

    );
}
export default PaymentCreditPage;
