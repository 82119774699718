import { Avatar, Col, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CAMPUS_WEB } from "../../utils";



const ExploreCampus = ({_data}) => {

    const [data, setData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (_data.length > 0) {
            setData(_data);
        }

    }, [_data]);
    return (
        <Row>
            <Col span={24}>

                <Row gutter={20}>
                    {
                        data.map((item, i) => {
                            return <Col span={12} key={i} className="md20">
                                <a href={`${CAMPUS_WEB}campus/${item?.campus_slag}`}>
                                <div className="expl_card">
                                    <Row className="expl_card_bg" style={{background:`linear-gradient(94deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), url(${item?.banner1_url}), lightgray 50% / cover no-repeat`}}>
                                    {/* {{backgroundImage:`url(${})`}} */}
                                        <Col span={20}>
                                            <p className="fs12 fw400" style={{ color: '#C7C7C7' }}>By  {item?.creator?.full_name} </p>
                                            <p className="fs16 fw600 text-white md5"> {item?.name} </p>
                                        </Col>
                                        <Col span={4} className="text-right">
                                            <Avatar size={40} src={item?.creator?.avatar_url} />
                                           
                                        </Col>
                                    </Row>
                                    <Row style={{ padding: '20px', minHeight:"107.84px" }}>
                                        <Col span={24} >
                                            <p className="fs12 fw500 "> 156K Members </p>
                                        </Col>
                                        <Col span={24} className="md10 camp-tag">
                                            {
                                                item?.programs.map((value, k)=>{
                                                    return <Tag className="md5" key={k}> {value} </Tag>
                                                })
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                </a>

                            </Col>
                        })
                    }

                   

                </Row>

            </Col>
        </Row>
    );
}

export default ExploreCampus;