import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Progress, Button, Avatar } from 'antd';
import './reward.scss'
import { useHistory } from "react-router";
import { CaretLeft } from "@phosphor-icons/react";
import axios from '../../store/axios';
import Cookies from 'js-cookie';


const RewardActivity = () => {
    const history = useHistory();

    const [data, setdata] = useState({});


    useEffect(() => {
        getactivity();
    }, [])


    const getactivity = async () => {
        await axios({
            method: 'get',
            url:  "v1.0/reward/level",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            // setLoading1(false);
            setdata(res.data.data);
        })

    }




    return (
        <Row className='md50 mb-4'>
            <Col xxl={{ span: 18, offset: 3 }} md={{ span: 18, offset: 3 }} xs={{ span: 24, offset: 0 }}>

                <Row>
                    <Col span={16} >
                        <Space className='m-0'>
                            <Button onClick={() => history.goBack()} type="text" className='p-0' icon={<CaretLeft size={22} color="#1c1c1c" />}> Back</Button>

                        </Space>
                    </Col>
                    <Col span={8} className='text-right'>
                        <img src={require('../../assets/images/call/yellowstar.png')} alt="" />
                    </Col>
                    <Col xxl={24} md={24} xs={24} className='md25'>
                        <div className='reward-card '>
                            <Row gutter={30}>
                                <Col lg={7} xs={24}>
                                    <Avatar size={55} />
                                    <h2 className='md25'>Hi Avishek</h2>
                                </Col>
                                <Col lg={10} xs={24} className=''>
                                    <h3 style={{ color: '#013238' }}> {data?.currlevel} </h3>
                                    <Progress
                                        percent={(data?.total_credits*100)/data?.nxtreq_credits}
                                        success={{
                                            percent: 30,
                                        }}
                                        className='md20'
                                    />
                                    <p className='fs18 fw300 reward-text md5 '> {data?.nxtreq_credits-data?.total_credits} credits more to {data?.nxtlevel} </p>

                                </Col>
                                <Col lg={7} xs={24}>
                                    <Row gutter={28} className="md45">
                                       
                                        <Col span={12} className="text-center" >
                                            <div className='coin-circle'>
                                                <span className='fs16'>{data?.today_credits}</span>
                                            </div>
                                            <p className='fs14 fw300 md10'> Today’s Credits </p>
                                        </Col>
                                        <Col span={12} className="text-center">
                                            <div className='coin-circle'>
                                                <span className='fs16'>{data?.total_credits}</span>
                                            </div>
                                            <p className='fs14 fw300 md10'> Total Credits </p>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>

                        </div>
                        <Row className='md60'>
                            <Col span={12} offset={6} className='text-center'>
                                <Space>
                                    <Button type='primary' className='mesg-follw'> Earn</Button>
                                    <Button type='default' className='ms-3 mesg-follw'> Redeem</Button>
                                </Space>


                            </Col>

                            <Col lg={24} xs={{span:21, offset:1}} className='md30'>
                                <p className='fs18 fw400'> Activities</p>

                                <Row  className='md50 '>
                                    <Col lg={6} xs={12}>
                                        <img src={require('../../assets/images/call/activity1.png')} className=' m-w115' width={150}  alt="" />
                                        <p className='fs16 fw400 md15'>PLay 10 Trivia</p>

                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <img src={require('../../assets/images/call/activity2.png')}  className=' m-w115'  width={150}  alt="" />
                                        <p className='fs16 fw400 md15'>Get 50 Shares</p>


                                    </Col>
                                    <Col lg={6} xs={12} >
                                        <img src={require('../../assets/images/call/activity4.png')} className='m-md20 m-w115'  width={150}  alt="" />
                                        <p className='fs16 fw400 md15'>Get 100 Likes</p>


                                    </Col>
                                    <Col lg={6} xs={12} >
                                        <img src={require('../../assets/images/call/activity3.png')} className='m-md20 m-w115'  width={150} alt="" />
                                        <p className='fs16 fw400 md15'>Get 50 Shares</p>


                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}




export default RewardActivity;


