import { Heart, ShareNetwork, WechatLogo } from "@phosphor-icons/react";
import { Button, Card, Col, Row, Space, Badge, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { convertDuration } from "../../utils";
import moment from "moment";
import AudioPlayer from "./AudioPlayer";

const { Paragraph } = Typography;
const DetailSection2 = (props) => {
    const { play, podcast } = useSelector((state) => state.podcast);
    const { data } = props;

    console.log(podcast);

    return (
        <Row>
            <Col span={24} className="text-center md50">
                <img src={podcast?.image?.href}
                    alt="" height={180} style={{ borderRadius: '4px' }} />
                <p className="fs16 fw800 link-dark md20 ">
                    {podcast && podcast.title}
                </p>
                <p className="fs14 fw400 feed-text ">
                    {convertDuration(podcast?.itunes_duration)}  <Badge status="default" />  {moment(podcast?.published).format('Do MMM, YYYY')}
                </p>

            </Col>
            <Col span={24} className="p-2">
                <div style={{ position: "sticky", top: '80px', zIndex: 10 }}>
                    {
                        podcast?.links && <AudioPlayer
                            src={podcast.links.filter((item) => item.type.includes("audio/"))[0].href}
                            autoPlay={false}
                            controls
                            playing={play}
                        />
                    }
                </div>

                <Space size={24} className="md30">

                    <p className="fs14 fw400 link-dark "><Heart size={24} color="#4B5669" weight="fill" /> {data?.likes} likes </p>

                    <p className="fs14 fw400 link-dark "><WechatLogo size={24} color="#4B5669" weight="fill" /> {data?.comments} Comment </p>

                    <p className="fs14 fw400 link-dark ">
                        <ShareNetwork size={24} color="#4B5669" weight="fill" /> Share </p>
                </Space>

                <Row className="md30">
                    <Col span={24}>
                        <p className="fs16 fw600 ">
                            About episode
                        </p>

                        <Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "See" }}>
                            <div className="fs14 fw300 md10" style={{ color: '#999A9B' }} dangerouslySetInnerHTML={{ __html: podcast?.summary }} />
                        </Paragraph>

                        {/* <Space className="md15 podcast-tag" wrap>
                            {
                                podcast?.tags?.map((element, i) => {
                                    return <Tag key={i}> {element?.term} </Tag>
                                })
                            }
                        </Space> */}

                    </Col>
                    <Col span={24} className="md15">
                        <p className="fs14 fw400 ">Rights: {data?.rights}</p>

                    </Col>
                    {
                        data?.next &&
                        <><Col span={24} className="md25">
                            <p className="fs16 fw600">
                                For you
                            </p>
                        </Col>
                            <Col span={24}>
                                <Card className="padding15 card-bg md20" style={{ background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.65) 69.85%), url('${data?.next?.image_url}'), lightgray 50% / cover no-repeat` }}>
                                    <Row className="md120">
                                        <Col span={22} className="">
                                            <Paragraph className="fs14 fw400 text-white" ellipsis={{ rows: 1 }}>
                                                {data?.next?.title}
                                            </Paragraph>
                                            <p className="fs12 fw400" style={{ color: '#C7C7C7' }}> {data?.next?.author} </p>
                                        </Col>
                                        <Col span={2}>
                                            <Button icon={<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                <path d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9957 12.5535 27.6247 9.2494 25.1876 6.81236C22.7506 4.37532 19.4465 3.0043 16 3ZM20.555 16.8325L14.555 20.8325C14.3904 20.9416 14.1974 20.9998 14 21C13.7348 21 13.4804 20.8946 13.2929 20.7071C13.1054 20.5196 13 20.2652 13 20V12C12.9999 11.8189 13.0489 11.6412 13.1419 11.4858C13.2349 11.3304 13.3684 11.2032 13.528 11.1177C13.6877 11.0323 13.8675 10.9918 14.0484 11.0005C14.2293 11.0093 14.4044 11.067 14.555 11.1675L20.555 15.1675C20.6922 15.2588 20.8047 15.3825 20.8825 15.5278C20.9603 15.673 21.001 15.8352 21.001 16C21.001 16.1648 20.9603 16.327 20.8825 16.4722C20.8047 16.6175 20.6922 16.7412 20.555 16.8325Z" fill="white" />
                                            </svg>} type="link" href={`/podcast/${data?.next?.entity_uuid}`}></Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </>
                    }


                </Row>
            </Col>

        </Row>
    );
}

export default DetailSection2;