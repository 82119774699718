import { Button, Col, Modal, Row, Space, Switch, Table, Tag, Tooltip } from "antd";
import { CaretLeft, Info } from "@phosphor-icons/react";
import React from "react";
import { useEffect, useState } from 'react';

import useToken from "../../store/useToken"
import axios from "../../store/axios";
import moment from "moment";
import { useHistory } from "react-router-dom";


const ManageRequests = (props) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [modal, setmodal] = useState(false);
    const [employee, setEmployee] = useState({});
    const [alloReq, setAllowReq] = useState(true);

    const history = useHistory();

    const { token } = useToken();

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const fetchData = (offset) => {
        setLoading(true);
        axios({
            method: 'get',
            url:  `account/business/connections?offset=${offset}`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setData(data.rows);
            setLoading(false);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: data.total,
                    // 200 is mock data, you should read it from server
                    // total: data.totalCount,
                },
            });
        });
    };

    useEffect(() => {
        fetchData(0);
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        console.log(pagination)
        if (pagination.current !== tableParams.pagination?.current) {
            const o = pagination.current * 10
            fetchData(o);
        }

        setTableParams({
            pagination,
            filters,
            ...sorter,
        });


        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const openDetail = (emp) => {
        setEmployee(emp);
        setmodal(true);
    };


    const actionButton = async (id, action) => {

        setLoading(true);
        await axios({
            method: 'post',
            url:  `account/business/connection/action`,
            headers: {
                Authorization: token
            },
            data: { req_id: id, action: action }
        }).then(res => {
            fetchData(0);

        });
        setLoading(false);
        setmodal(false);
    };

    const allowRequestAPI = async (allow) => {
        setAllowReq(allow);
        await axios({
            method: 'post',
            url:  `v1.0/business/page/settings`,
            headers: {
                Authorization: token
            },
            data: { recv_conn_req: allow }
        });
    };


    const columns = [
        {
            title: 'Employee ID',
            dataIndex: 'emp_id',
            render: (name) => `${name}`,
            width: '20%',
        },
        {
            title: 'Name',
            dataIndex: 'emp_name',
            render: (emp_name, r) => <a href={`/profile/${r.user_code}`}>{emp_name}</a>,
        },

        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            sorter: true,
            render: (created_at) => <>{moment(created_at).format('YYYY-MM-DD')}</>,
            responsive: ['md'],
        },
        {
            title: 'Status',
            dataIndex: 'status_str',
            sorter: true,
            render: (status_str, r) => <> <Tag color={r.status === 3 ? 'red' : r.status === 2 ? 'yellow' : 'geekblue'} >{status_str}</Tag> <Button onClick={() => openDetail(r)} className="float-right" type="link" size="small">View</Button> </>,
            width: '20%',
        },
    ];
    return (
        <Row style={{ minHeight: "calc(100vh - 69px)" }}>

            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }} md={{ span: 20, offset: 2 }} className="md50"  >
                <Row >
                    <Col md={14} xs={24} >

                        <Space wrap aria-orientation="horizontal">
                            <Button className="p-0" type={"text"} icon={<CaretLeft size={24} />} onClick={() => history.goBack()}></Button>
                            <p className="fs18 fw600">Connection Requests</p>
                        </Space>
                    </Col>
                    <Col md={10} xs={14} className="text-right">

                        <Space wrap aria-orientation="horizontal">
                            <p className="">Allow Requests</p>
                            <Switch checked={alloReq} onChange={(c)=> allowRequestAPI(c)} />
                            <Tooltip title="Turn off the switch, If you don't want to receive requests!">
                            <Info size={18} className="text-grey" />
                            </Tooltip>
                        </Space>
                    </Col>
                    <Col span={24} className="md30">

                        <Table
                            columns={columns}
                            rowKey={(record) => record.pk}
                            dataSource={data}
                            pagination={data?.length > 10 ? tableParams.pagination: false}
                            loading={loading}
                            onChange={handleTableChange}
                        />

                    </Col>
                </Row>

            </Col>

            <Modal centered title="Employee Detail" open={modal} footer={false} onCancel={() => setmodal(false)} >
                <Row className="padding20">
                    <Col span={24}>
                        {/* <Alert showIcon message="Integration was not successful" type="error" /> */}
                        <Row>
                            <Col span={12} className="md20">
                                <p className="fs14 fw600">Employee Name</p>
                                <p className="fs14 fw500 md10"> {employee.emp_name}</p>
                            </Col>
                            <Col span={12} className="md10 text-right">
                                <Button type="link" className="text-blue" href={`/profile/${employee.user_code}`}>View profile</Button>
                            </Col>

                        </Row>

                        <Row>
                            <Col span={24} className="md20">
                                <p className="fs14 fw600">Employee ID</p>
                            </Col>
                            <Col span={24} className="md10">
                                <p className="fs14 fw500">{employee.emp_id}</p>
                            </Col>
                            <Col span={24} className="md20">
                                <p className="fs14 fw600">Work Email ID</p>
                            </Col>
                            <Col span={18} className="md10">
                                <Space>
                                    <p className="fs14 fw500"> {employee.email ? employee.email : "NA"}</p>
                                    {
                                        employee.email && !employee.email_verified && <Tag color="pink">Not verified</Tag>
                                    }

                                </Space>
                            </Col>

                            <Col span={24} className="md20">
                                <p className="fs14 fw600">Remarks</p>
                            </Col>
                            <Col span={24} className="md10">
                                <p className="fs14 fw400"> {employee.remark}</p>
                            </Col>
                            <Col span={24} className="md20">
                                <p className="fs14 fw600">Status</p>
                            </Col>
                            <Col span={24} className="md10">
                                <p className="fs14 fw400"> {employee.status_str}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} className="text-center md20 mb-2">

                        <Space>
                            {employee.status === 1 ?
                                <Button type="ghost" className="md20" block onClick={() => setmodal(false)}>
                                    Close
                                </Button> :
                                <Button type="primary" className="md20" block onClick={() => actionButton(employee.id, 1)}>
                                    Approve
                                </Button>
                            }
                            {
                                employee.status !== 1 && <Button type="" danger className="md20" block onClick={() => actionButton(employee.id, 3)}>
                                Reject
                            </Button>
                            }
                            
                        </Space>
                    </Col>
                </Row>

            </Modal>

        </Row>
    );
}
export default ManageRequests;