import { createAction } from "redux-actions";
import axios from "../axios";
import Cookies from "js-cookie"


const STREAMING_TOKEN = createAction("STREAMING_TOKEN");
export const GetSteamingToken = (values) => dispatch => {

    return axios({
        method: 'post',
        url: "v1.0/stream/live",
        data: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(STREAMING_TOKEN(res.data.data));
    })
};
