import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    register_details: [],
}
const RegisterReducer = handleActions({
    REGISTER: (state, action) => {
        return { ...state, register_details: action.payload };
    }
}, INITIAL_STATE);

export default RegisterReducer;
