import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Modal, Skeleton } from "antd";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import axios from '../../store/axios';
import Cookies from 'js-cookie';


const CreditsRewards = () => {
    const [collapsed, setCollapse] = useState(true)
    const [modal, setModal] = useState(false);
    const [loading2, setLoading2] = useState(true);
    const [loading, setLoading] = useState(true);
    const [wallet, setWallet] = useState({});
    const [badges, setbadges] = useState([]);


    useEffect(() => {
        getWallet();
        getBadges();
    }, []);

    const getWallet = async () => {
        await axios({
            method: 'get',
            url:  "v1.0/reward/wallet",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            setLoading2(false);
            setWallet(res.data.data);
        })

    }

    const getBadges = async () => {
        await axios({
            method: 'get',
            url:  "v1.0/reward/badges",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            setLoading(false);
            setbadges(res.data.data);
        })

    }

    return (
        <>
            <div className='reward-card'>
                <Row>
                    <Col span={24}>
                        <p className='fs18 fw500 '>Credits</p>
                    </Col>
                </Row>
                {
                    !collapsed &&
                    <>

                        <Row gutter={12} className='credits-bg-card md20'>
                            <Col span={8}>
                                <div className='circle-credits'>
                                    <span className='fs18'>10</span>
                                </div>
                                <p className='fs12 text-white'>Newsletter</p>
                            </Col>
                            <Col span={8}>
                                <div className='circle-credits'>
                                    <span className='fs18'>10</span>
                                </div>
                                <p className='fs12 text-white'>Newsletter</p>
                            </Col>
                            <Col span={8}>
                                <div className='circle-credits'>
                                    <span className='fs18'>10</span>
                                </div>
                                <p className='fs12 text-white'>Newsletter</p>
                            </Col>
                        </Row>
                        <Row gutter={12} className='credits-bg-card md20'>
                            <Col span={8}>
                                <div className='circle-credits'>
                                    <span className='fs18'>10</span>
                                </div>
                                <p className='fs12 text-white'>Newsletter</p>
                            </Col>
                            <Col span={8}>
                                <div className='circle-credits'>
                                    <span className='fs18'>10</span>
                                </div>
                                <p className='fs12 text-white'>Newsletter</p>
                            </Col>
                            <Col span={8}>
                                <div className='circle-credits'>
                                    <span className='fs18'>10</span>
                                </div>
                                <p className='fs12 text-white'>Newsletter</p>
                            </Col>
                        </Row>
                    </>


                }
                {
                    loading2 ? <><Skeleton active /><Skeleton active /></> :
                        <Row>

                            <Col span={16} className="md20">
                                <Row>
                                    <Col span={12}>
                                        <img src={require('../../assets/images/call/smallcrd.png')} alt="" />
                                    </Col>
                                    <Col span={12}>
                                        <div className='coin-circle'>
                                            <span className=''>{wallet?.credits?.convertToMoney()}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="md25">
                                    <Col span={12}>
                                        <img src={require('../../assets/images/call/game.png')} alt="" />
                                    </Col>
                                    <Col span={12}>
                                        <div className='coin-circle'>
                                            <span className=''>{wallet?.coins?.convertToMoney()}</span>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={8} className="text-center md20">
                                <img src={require('../../assets/images/call/orangecirle.png')} width={90} alt="" />
                            </Col>
                            <Col span={24} className="text-center md25">
                                <Button type="text" icon={collapsed ? <CaretDown size={28} color="#000009" /> : <CaretUp size={28} color="#000009" />}
                                    onClick={() => setCollapse(!collapsed)}></Button>
                            </Col>
                        </Row>
                }

            </div>
            <div className='reward-card md45'>
                <Row>
                    <Col span={24}>
                        <p className='fs18 fw500 '>Badges</p>
                    </Col>
                    <Col span={24} className="md20">
                        <Row>
                            {
                                loading ? <><Skeleton active /><Skeleton active /></>
                                    : <>
                                        {badges.map((item, i) => {
                                            return <Col span={8}>
                                                <img src={require('../../assets/images/call/reward7.png')} alt="" />
                                            </Col>
                                        })

                                        }
                                        {
                                            badges.length === 0 && <Col span={24} className='text-center'>
                                                <p>No Badges</p>
                                                <Button type='primary' shape='round' className='md20'>Earn</Button>
                                            </Col>
                                        }
                                    </>

                            }

                        </Row>


                    </Col>

                    <Col span={24} className="text-center md25">
                        <Button type="text" icon={<CaretDown size={28} color="#000009" />} onClick={() => setModal(!modal)}></Button>
                    </Col>
                </Row>
            </div>

            <Modal title="Badges" open={modal} footer={false} onCancel={() => setModal(false)}>
                <div  style={{ padding: '30px' }}>
                    <Row gutter={12} className='credits-bg-card md20'>
                        <Col span={8}>
                            <div className='circle-credits'>
                                <span className='fs18'>10</span>
                            </div>
                            <p className='fs12 text-white'>Newsletter</p>
                        </Col>
                        <Col span={8}>
                            <div className='circle-credits'>
                                <span className='fs18'>10</span>
                            </div>
                            <p className='fs12 text-white'>Newsletter</p>
                        </Col>
                        <Col span={8}>
                            <div className='circle-credits'>
                                <span className='fs18'>10</span>
                            </div>
                            <p className='fs12 text-white'>Newsletter</p>
                        </Col>
                    </Row>
                    <Row gutter={12} className='credits-bg-card md20'>
                        <Col span={8}>
                            <div className='circle-credits'>
                                <span className='fs18'>10</span>
                            </div>
                            <p className='fs12 text-white'>Newsletter</p>
                        </Col>
                        <Col span={8}>
                            <div className='circle-credits'>
                                <span className='fs18'>10</span>
                            </div>
                            <p className='fs12 text-white'>Newsletter</p>
                        </Col>
                        <Col span={8}>
                            <div className='circle-credits'>
                                <span className='fs18'>10</span>
                            </div>
                            <p className='fs12 text-white'>Newsletter</p>
                        </Col>
                    </Row>
                    <Row gutter={12} className='credits-bg-card md20'>
                        <Col span={8}>
                            <div className='circle-credits'>
                                <span className='fs18'>10</span>
                            </div>
                            <p className='fs12 text-white'>Newsletter</p>
                        </Col>
                        <Col span={8}>
                            <div className='circle-credits'>
                                <span className='fs18'>10</span>
                            </div>
                            <p className='fs12 text-white'>Newsletter</p>
                        </Col>
                        <Col span={8}>
                            <div className='circle-credits'>
                                <span className='fs18'>10</span>
                            </div>
                            <p className='fs12 text-white'>Newsletter</p>
                        </Col>
                    </Row>
                </div>
            </Modal>

        </>
    );
}
export default CreditsRewards;