import { createAction } from "redux-actions";
import axios from "../axios";
import Cookies from "js-cookie"
import { values } from "lodash";


const PROFILE = createAction("PROFILE");
export const Profile = (values) => dispatch => {
    return axios({
        method: 'get',
        url: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(PROFILE(res.data.data));
        if (res.data.data.isme){
            Cookies.set("user_code",res.data.data.user_code)
            sessionStorage.setItem("active",res.data.data.is_email_verified)
            Cookies.set("user_name", res.data.data.name);
            Cookies.set("user_pic", res.data.data.img_url);
            // Cookies.set("__role",res.data?.data?.role)
        }
    })
};


export const changePassword = (values) => () => {
    return axios({
        method: 'put',
        url: '/account/auth/change-password',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};


export const ProfileEdit = (values) => () => {
    return axios({
        method: 'put',
        url: '/account/user/profile',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};

const BUSINESS_TYPES = createAction("BUSINESS_TYPES");
export const GetBusinessType = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: '/account/master/business-type',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(BUSINESS_TYPES(res.data.data));
    })
};



const PROFILE_CATALOGUE = createAction("PROFILE_CATALOGUE");
export const ProfileCatalogues = (values) => dispatch => {
    return axios({
        method: 'get',
        url: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(PROFILE_CATALOGUE(res.data.data));
    })
};


const ALL_SUBSCRIPTION = createAction("ALL_SUBSCRIPTION");
export const AllSubscriptions = () => dispatch => {
    return axios({
        method: 'get',
        url: '/account/subscription',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_SUBSCRIPTION(res.data.data));
    })
};




export const cancelSubscription = (values) => () => {
    return axios({
        method: 'delete',
        url: '/account/subscription?id='+values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};


const ALL_PAYMENTS = createAction("ALL_PAYMENTS");
export const AllPayments = () => dispatch => {
    return axios({
        method: 'get',
        url: '/account/user/payment/report',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_PAYMENTS(res.data.data));
    })
};




const ALL_BOOKINGS = createAction("ALL_BOOKINGS");
export const allEventsBookings = (value) => dispatch => {
    return axios({
        method: 'get',
        url: '/account/channel/catelogue?code='+ value.user_code,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_BOOKINGS(res.data.data));
    })
};

export const addLink = (values) => dispatch => {
    return axios({
        method: 'post',
        url: '/account/user/catelogue',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};



export const cancelEvent = (values) => dispatch => {
    return axios({
        method: 'delete',
        url: '/calendar/schedule/event',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};

export const deleteNote = (values) => () => {
    return axios({
        method: 'delete',
        url: 'account/user/catelogue?id='+values.key,
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};


const BANK_DETAILS = createAction("BANK_DETAILS")
export const getBankDetails = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: 'lighthouse/bank/info?ifsc='+values.ifsc,
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(BANK_DETAILS(res.data.data));
    })
};



const USERS_BANK_DETAILS = createAction("USERS_BANK_DETAILS")
export const getAccountDetails = () => (dispatch) => {
    return axios({
        method: 'get',
        url: 'account/user/bank',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(USERS_BANK_DETAILS(res.data.data));
    })
};


export const addBank = (values) => () => {
    return axios({
        method: 'post',
        url: '/account/user/bank',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};



const CHANNEL_CATEGORIES = createAction("CHANNEL_CATEGORIES")
export const channelCategories = (values) => (dispatch) =>{
    return axios({
        method: 'get',
        url: '/channel/category',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(CHANNEL_CATEGORIES(res.data.data));
    })
}

export const FollowUnFollowUser = (values) => async() => {
    return await axios({
        method: values.method,
        url: 'account/user/follow',
        data:values.data,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};




const USERS_FOLLOWERS = createAction("USERS_FOLLOWERS")
export const getMyFollowers = (values) => (dispatch) => {
    return axios({
        method: "get",
        url:  values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(USERS_FOLLOWERS(res.data.data));
    })
};

const GET_MEMBERSHIP = createAction("GET_MEMBERSHIP")
export const getMembershipTiers = (values) => (dispatch) => {
    return axios({
        method: "get",
        url:  "v1.0/membership/tiers?user_code="+values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(GET_MEMBERSHIP(res.data.data));
    })
};



export const getMembershipSubscriptions = () => (dispatch) => {
    return axios({
        method: "get",
        url:  "v1.0/membership/subscriptions",
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(GET_MEMBERSHIP(res.data.data));
    })
};