import React from "react";
import { Row, Col, Button, Skeleton, Dropdown, Tag, Typography, message, Space } from "antd";
import { Dot, DotsThreeVertical, Export, Trash } from "@phosphor-icons/react";
import BlankState from "../BlankState";
import { RWebShare } from "react-web-share";

import Toast from "../utils/Toast";
const { Paragraph } = Typography

const MyHubNotes = ({ data, loader, deleteFeed }) => {


    const onClick = async ({ key, id }) => {

        if (key === '2') {
            await deleteFeed(id);
            Toast('Deleted!',"error")
        }
    };

    const items = (item) => {
        
        return [

            {
                key: '1',
                icon:
                    <RWebShare
                        data={{
                            text: "Syllo: Note",
                            url: `${process.env.REACT_APP_URL}feed-view/${item.entity_uuid}`,
                            title: item?.title,
                        }}

                    ><span><Export size={18} color="#000000" weight="thin" className="me-2" /> Share</span>
                    </RWebShare>
            },{
                label: (
                    <span className=" fw400"> Delete</span>
                ),
                key: '2',
                icon:<Trash size={18} color="#686464" weight="light" />

            }

        ]
    };


    return (
        <Row>
            <Col span={24}>

                <Row gutter={20}>

                    {loader ? <Skeleton></Skeleton> : data.length === 0 ? <Col lg={{ span: 24 }} xs={{ span: 24 }} className="md60 text-center">
                        <BlankState img={require("../../assets/images/blank/no-data-found.svg").default} height={200} />
                    </Col> :
                        data.map((item, i) => {
                            return <Col key={i} lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} className='md20'>
                                <Row className="notes-membership-card">
                                    <Col span={24}  >
                                        <a href={"/note/" + item.entity_uuid} style={{ flexGrow: 1 }}>
                                            <div className="title mb-2">
                                                <Paragraph className="" ellipsis={{ rows: 2 }}>{item.title}</Paragraph>
                                                <div className="menu-surface-anchor">
                                                    {
                                                        <Dropdown trigger={['click']} menu={{ items: items(item), onClick: ({ key }) => onClick({ key: key, id: item.entity_uuid }) }} placement={"bottom"} overlayStyle={{ minWidth: "120px" }}>
                                                            <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                                        </Dropdown>
                                                    }

                                                </div>

                                            </div>
                                        </a>
                                    </Col>

                                    {/* <Col md={{ span: 20 }} xs={{ span: 20 }}  >
                                        <p className="m-0 fs16 fw600 cursor" onClick={() => details(item)}>{item?.title} </p>
                                        {/* <p className="fs14 fw400 md5">{item?.description} </p> 
                                    </Col>

                                <Col md={{ span: 4 }} xs={{ span: 4 }} className="text-right">

                                    <Dropdown trigger={['click']} menu={{ items: items(item), onClick: ({ key, }) => onClick({ key: key, id: item.entity_uuid }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                        <Button type="text" shape="circle" onClick={(e) => e.preventDefault()}
                                            icon={<DotsThreeVertical size={17} color="#000" weight="fill" />}></Button>
                                    </Dropdown>
                                </Col> */}

                                    <Col md={{ span: 24 }} xs={{ span: 24 }}  >
                                        {
                                            item?.blocks && item?.blocks?.map((block, j) => {
                                                return <>
                                                    <Paragraph key={j} ellipsis={{
                                                        rows: 2, symbol: "..."
                                                    }} className="fwnormal fs14 md5" >{block?.data?.text} </Paragraph>
                                                </>
                                            })
                                        }
                                    </Col>
                                    <Col md={{ span: 24 }} xs={{ span: 24 }} style={{ position: "absolute", bottom: "16px" }}>
                                    <Space align='center' size={0} wrap split={<Dot size={24} />}>
                                    <p className='fs12 text-grey' >  {item.likes} Likes </p>
                                    <p className='fs12 text-grey' >  {item.comments} Comments </p>
                                
                                        {
                                            item?.published ? <Tag color="processing">Published</Tag> : <Tag color="warning">Draft</Tag>
                                        }
                                        </Space>
                                    </Col>
                                </Row>
                                {/* <Divider></Divider> */}
                            </Col>
                            //     return <Col lg={{ span: 24 }} xs={{ span: 24 }} className='notes-membership-card  md10'>
                            //     <Row className="single-channel-view cursor">
                            //         <Col lg={{ span: 20 }} xs={{ span: 20 }}  >
                            //             <p className="m-0 fs16 fw600 " onClick={() => details(item)}>{item?.title} </p>
                            //             <p className="fs14 fw400 md5">{item?.description} </p>
                            //         </Col>

                            //         <Col lg={{ span: 4 }} xs={{ span: 4 }} className="text-right  show-on-channel-hover">
                            //             <Tooltip placement="top" title={"Share"}>
                            //                 <Button type={'text'} icon={<Export size={22} color="#686464" weight="light" />}>
                            //                 </Button>
                            //             </Tooltip> <br />
                            //             <Tooltip placement="top" title={"Delete"}>
                            //                 {
                            //                     !item?.published && <Button onClick={() => deleteFeed(item.entity_uuid)} type={'text'} icon={<Trash size={22} color="#686464" weight="light" />}>
                            //                     </Button>
                            //                 }
                            //             </Tooltip>
                            //         </Col>
                            //     </Row>
                            //     <Divider></Divider>
                            // </Col>
                        })
                    }


                </Row>

            </Col>
        </Row>
    );
}
export default MyHubNotes;