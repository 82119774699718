import { Avatar, Col, Row, Space, Button, Layout, Menu } from "antd";
import React, { useState } from "react";

const { Sider } = Layout;


const KidsBudgeting = () => {
    const [menuKey, setMenuKey] = useState('1')

    const menu = () => {
        return <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[menuKey]}
            onSelect={(info) => setMenuKey(info.key)}
            items={[{
                key: "1",
                label: `Budgeting`,
            }, {
                key: "2",
                label: `Mutual Fund`,
            }, {
                key: "3",
                label: `Indian Equity`,
            },
            {
                key: "4",
                label: `Banking`,
            },
            {
                key: "5",
                label: `Stocks`,
            },
            {
                key: "6",
                label: `ETF & ELSS`,
            },
            {
                key: "7",
                label: `Topics1`,
            },
            {
                key: "8",
                label: `Topics2`,
            },


            ]
            }
            style={{ backgroundColor: "#ffffff", minHeight: "72vh" }}
        />
    }


    return (
        <Row gutter={32} className="md20">
            <Col span={7}>
                <div >
                    <Layout className="live-class-layout kids-campus-layout">
                        <Sider
                            breakpoint="lg"
                            collapsedWidth="0"
                            onBreakpoint={(broken) => {
                                console.log(broken);
                            }}
                            onCollapse={(collapsed, type) => {
                                console.log(collapsed, type);
                            }}
                            className="md20"
                            >
                            {menu()}
                        </Sider>
                    </Layout>

                </div>
            </Col>

            <Col span={17}>
                <Row className="budgeting-card">
                    <Col span={16} >
                        <Space>
                            <Avatar size={30} />
                            <p className="fs14 fw500" style={{ color: '#4B5669' }}> Chris Evan </p>
                        </Space>
                    </Col>
                    <Col span={8} className="text-right">

                        <Space>
                            <p className="fs14 fw500" style={{ color: '#4B5669' }}> Axis Bank </p>
                            <img src={require("../../../assets/images/Campusimgae/axis.png")} alt="" height={40} />
                        </Space>
                    </Col>

                    <Col span={24}>
                        <p className="fs16 fw600"> Kids’ Infinity Stone </p>
                    </Col>
                    <Col span={24} className="md20">
                        <Space size={24}>
                            <div className="kids-div fs13 fw400" > Kid's  <span className="fs13 fw500" style={{ color: '#DE9C00' }}>
                                Star </span> </div>
                            <p className="update"> ₹ 599/month </p>

                        </Space>
                    </Col>
                </Row>
                <Row className="budgeting-card md20">
                    <Col span={16} >
                        <Space>
                            <Avatar size={30} />
                            <p className="fs14 fw500" style={{ color: '#4B5669' }}> AbhayBraja </p>
                        </Space>
                    </Col>
                    <Col span={8} className="text-right">

                        <Space>
                            <p className="fs14 fw500" style={{ color: '#4B5669' }}> Smallcase </p>
                            <img src={require("../../../assets/images/Campusimgae/axis.png")} alt="" height={40} />
                        </Space>
                    </Col>

                    <Col span={24}>
                        <p className="fs16 fw600"> Kids’ Infinity Stone </p>
                    </Col>
                    <Col span={24} className="md20">
                        <Space size={24}>
                            <div className="kids-div-blue fs13 fw400" > Kid's  <span className="fs13 fw500" style={{ color: '#3B6BE3' }}>
                                Star </span> </div>
                            <p className="update"> ₹ 599/month </p>

                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="text-center md40">
                        <Button type="primary"> Load more </Button>
                    </Col>
                </Row>

            </Col>


        </Row >
    );
}
export default KidsBudgeting;