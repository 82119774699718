import { Col, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import useToken from "../../store/useToken"
import axios from "../../store/axios";
import _ from "lodash";
import NewsFeed from "../NewsFeed/NewsFeed";

const BusinessTimeline = ({ data }) => {
    const [loading, setLoading] = useState(true);
    const { token } = useToken();


    const [posts, setPosts] = useState([]);
    const [loadMore, setLoadMore] = useState(false);
    const [allCaught, setallCaught] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData(param) {
        const args = { offset: offset, ...param }

        await axios({
            method: 'get',
            url:  `v1.0/business/${data?.username}/timeline?count=10&offset=${offset}`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const _data = res.data?.data

            let _oldData = posts
            if (_data.length < 10) {
                setLoadMore(false);
                setallCaught(true);
            }
            else {
                setLoadMore(true);
                setallCaught(false);
            }
            if (args.offset > 0) {
                _data.map((item) => {
                    return _oldData.push(item)
                })
                setPosts(_oldData)
            } else {
                setPosts(_data);
            }
        });
        setLoading(false);
    }


    function loadMoreData() {
        let _offset = 10 + offset;
        setOffset(_offset);
        fetchData({ offset: _offset });
    }


    function details(record) {
        window.location.href = "/feed-view/" + record?.entity_uuid
    }
    return (
        <Row className="business-about-card md15 m-p10">
            <Col xxl={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }} md={{ span: 14, offset: 5 }} xs={{ span: 24, offset: 0 }}>
                {
                    loading ? <Skeleton active /> :
                        <NewsFeed
                            id={"scrollableDiv10"}
                            data={posts}
                            loadMoreData={() => loadMoreData()}
                            showLike={true}
                            loadMore={loadMore}
                            details={(record) => details(record)}
                            height={"calc(100vh - 190px)"}
                            showFooter={true}
                        />
                }
            </Col>
        </Row>
    );
}
export default BusinessTimeline;