import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import "./Channels.scss";
import {
    Upload,
    Input,
    Typography,
    Image, Col, Row, Button, Result, Space, Switch
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from '../../store/axios';
import Cookies from "js-cookie"
import { Fragment } from "react";
import ImgCrop from 'antd-img-crop';
import { Megaphone } from "@phosphor-icons/react";

import Toast from "../utils/Toast";
const { Paragraph } = Typography;



class CreateChannels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            fileList: [],
            name: "",
            desc: "",
            channel_privacy: 0,
            memberList: 0,
            copylink: "",
            id: "", uid: "",
            loading: false
        };
    }


    async componentDidMount() {
        // await this.props.getAccountDetails().then(() => {
        //     this.setState({
        //         userBanks: this.props._user_bank
        //     })
        // })
        await this.props.channelCategories().then(() => {
            this.setState({
                channel_categories: this.props._channel_category
            })
        })
    }

    async CreateChannels() {
        const { fileList } = this.state;
        const formData = new FormData();

        fileList.forEach((file) => {
            formData.append("chnl_img", file.originFileObj);
            formData.append("name", this.state.name);
            formData.append("desc", this.state.desc);
            formData.append("channel_privacy", this.state.channel_privacy);
            formData.append("member_list", this.state.memberList);
        });

        this.setState({
            uploading: true,
        });

        // You can use any AJAX library you like
        await axios({
            url: "channel/create",
            method: "post",
            data: formData,
            timeout: 1000 * 50,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        }).then((res) => {
            this.setState({ step: 4, copylink: res.data.data.link, uid: res.data.data.uid, id: res.data.data.id });
        }).catch((error) => {
            Toast(error.response.data.message,"error")
        });
        this.setState({ loading: false });
    }

    nextStep() {

        if (this.state.fileList.length === 0) {
            Toast("Add Image","error");
            return;
        }
        if (this.state.name === "") {
            Toast("Channel name is required!","error");
            return;
        }
        this.setState({ loading: true });


        // if (this.state.channel_privacy === "") {
        //     Toast("Provide Channel type");
        //     return;
        // }

        // if (this.state.categories.length === 0) {
        //     Toast("Choose Channel Categories");
        //     return;
        // }

        this.CreateChannels();
    }


    render() {
        const { fileList, channel_categories } = this.state;

        const props = {
            multiple: false,
            accept: ".jpg,.png,.jpeg",
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            onChange: ({ fileList: newFileList }) => {
                this.setState({ fileList: newFileList })
            },
            onPreview: (file) => {
                const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
                if (!isJpgOrPng) {
                    Toast("You can only upload JPG/PNG file!","error");
                    return;
                }
                const isLt2M = file.size / 1024 / 1024 < 5;
                if (!isLt2M) {
                    Toast("Image must smaller than 5MB!","error");
                    return;
                }

                let src = file.url;
                if (!src) {
                    src = new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.originFileObj);
                        reader.onload = () => resolve(reader.result);
                    });
                }
                const image = new Image();
                image.src = src;
                const imgWindow = window.open(src);
                imgWindow.document.write(image.outerHTML);
            },
            fileList,
        };
        return (
            <Row>

                <Col xl={{ span: 8, offset: 8 }} lg={{ span: 10, offset: 7 }} md={{ span: 18, offset: 3 }} xs={{ span: 24, offset: 0 }} className="padding20">


                    {
                        this.state.step !== 4 && <Fragment>

                            <Row className="md35">

                                {/* <Col span={24}>
                                    <Button type="link" shape="circle" className="fs-16" style={{ color: "#504e4e", fontWeight: "400" }} onClick={() => this.props.history.goBack()} icon={<ArrowLeft size={20} color="#030303" weight="light" style={{ marginRight: "5px" }} />} > Go Back</Button>
                                </Col> */}
                                {/* {
                                    this.state.userBanks.length === 0 && <Col span={24} className="md10">
                                        <AlertAddChannel message={"Please add your Bank account details first, before moving further!"} showIcon={true} />
                                    </Col>
                                } */}
                                <Col span={24} className=''>
                                    <Space>
                                        <Megaphone size={32} color="#010101" weight="regular" />
                                        <p className="fs18 fw600">  Create Channel</p>
                                    </Space>
                                </Col>



                                <Col span={24} className="md40">
                                    <Row gutter={10}>
                                        <Col md={6} xs={24}>
                                            {/* <p className="fs16 fw400 md10" style={{ fontWeight: "normal" }} >Upload Image</p> */}
                                            <ImgCrop rotate>
                                                <Upload
                                                    multiple={false}
                                                    listType="picture-card"
                                                    style={{ width: "100px", height: "100px", borderRadius: "100%" }}
                                                    showUploadList={true}
                                                    Tooltip={false}
                                                    {...props}
                                                >
                                                    {fileList.length === 0 && <p className="sub-text"> + <br></br> Add photo </p>}
                                                </Upload>
                                            </ImgCrop>
                                        </Col>

                                        <Col md={18} xs={24}>
                                            <p className="fs14 fw600 md10" style={{ fontWeight: "normal" }} >Name</p>
                                            <Input
                                                className="md10"
                                                onChange={(evt) => this.setState({ name: evt.target.value })}
                                                value={this.state.name}
                                                placeholder="What do you wanna name it?"
                                                maxLength={50}
                                                minLength={2}
                                            ></Input>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col span={24} className="md20">
                                    <Row>
                                        <Col span={24}>
                                            <p className="fs14 fw600" style={{ fontWeight: "normal" }}>Description</p>
                                            <TextArea
                                                className="md10"
                                                maxLength={250}
                                                showCount={true}
                                                placeholder="Tell us more about this channel"
                                                rows={2}
                                                autoSize
                                                onChange={(evt) => this.setState({ desc: evt.target.value })}
                                                value={this.state.desc}
                                            ></TextArea>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="md20">
                                <Col span={24}>
                                    <h5 className="fs14 fw600" >Advanced</h5>
                                </Col>
                                <Col span={24} className="trending-card md10">
                                    <Row className="">
                                        <Col md={19} xs={18}>
                                            <p className="fs14 fw600" >Privacy</p>
                                            <p className="fs12 text-grey" >
                                                {this.state.channel_privacy === 1 ? <>
                                                    This is a <strong>public</strong> group. </> :
                                                    <>
                                                        This is a <strong>private</strong> group.
                                                        People can only discover this group through an invite or share link. </>
                                                }</p>


                                        </Col>
                                        <Col md={5} xs={6} className="text-right">
                                            <Switch checked={this.state.channel_privacy === 1} onChange={(evt) => this.setState({ channel_privacy: evt === true ? 1 : 0 })}
                                            />

                                        </Col>
                                    </Row>
                                    <Row className="md20">
                                        <Col md={19} xs={18}>
                                            <p className="fs14 fw600" >Show Member list</p>
                                            <p className="fs12 text-grey" >
                                                {this.state.memberList === 1 ? <>
                                                    All the<strong> members  </strong> of this channel can see list of members. </> :
                                                    <> Only  <strong>admins </strong>can see list of members.</>
                                                }</p>


                                        </Col>
                                        <Col md={5} xs={6} className="text-right">
                                            <Switch checked={this.state.memberList === 1} onChange={(evt) => this.setState({ memberList: evt === true ? 1 : 0 })}
                                            />

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>

                                <Col span={24} className="md30">
                                    <Button block loading={this.state.loading} type="primary" size="large" onClick={() => this.nextStep(3)}>Create Channel</Button>
                                </Col>
                                <Col span={24} className="md20">
                                    <Button block type="dashed" size="large" onClick={() => this.props.history.goBack()}>Close</Button>
                                </Col>


                            </Row>


                        </Fragment>
                    }




                    {
                        this.state.step === 4 &&
                        <Row>

                            <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
                                <Result
                                    className="md50"
                                    status="success"
                                    title="Channel Created Successfully!"
                                    subTitle="Send exclusive invite to your member  with whom you want to work with."
                                    extra={[
                                        <Paragraph copyable className="grey-background">{this.state.copylink}</Paragraph>,
                                        <Button className="md20" type="primary" key="console" onClick={() => window.location = "/my-hub"}>
                                            Go My Hub
                                        </Button>,
                                        <Button key="buy" onClick={() => window.location = `/channel/${this.state.uid}/${this.state.id}`}>View Channel</Button>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    }


                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    _bank: state.profile.bank_details,
    _user_bank: state.profile.user_bank,
    _channel_category: state.profile.channel_category
});
const mapDispatchToProps = (dispatch) => ({
    getAccountDetails: (v) => dispatch(actions.getAccountDetails(v)),
    getBankDetails: (v) => dispatch(actions.getBankDetails(v)),
    addBank: (v) => dispatch(actions.addBank(v)),
    channelCategories: (v) => dispatch(actions.channelCategories(v)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateChannels)
);
