import { createAction } from "redux-actions";
import axios from "../axios";
import Cookies from "js-cookie"



const ALL_NEWSLETTER = createAction("ALL_NEWSLETTER");
export const allNewsLetter = (values) => dispatch => {
    return axios({
        method: 'get',
        url: '/market/feed/all/personal?entity='+ values.entity+'&offset='+values.offset+"&published="+values.published,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_NEWSLETTER(res.data.data));
    })
};




export const allMarketNewsltter = () => dispatch => {
    return axios({
        method: 'get',
        url: '/newsletter/newsletter/market',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_NEWSLETTER(res.data.data));
    })
};




export const likeFeed = (values) => () => {
    return axios({
        method: values.requesttype,
        url: '/market/feed/like',
        data : {
            "id": values.id,
            "type": values.type
        },
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};



export const votePoll = (values) => () => {
    return axios({
        method: "post",
        url: '/market/feed/poll/vote',
        data : values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};

const SAVED_FEED = createAction("SAVED_FEED");
export const SaveFeed = (values) => (dispatch) => {
    return axios({
        method: values.type,
        url: 'collection/feed',
        data : values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(SAVED_FEED(res.data.data));
    })
};


export const PostFeed = (values) => () => {
    return axios({
        method: "post",
        url: '/market/feed/post',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};



export const PostResearchReport = (values) => () => {
    return axios({
        method: "post",
        url: '/market/feed/rr',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};



export const createPool = (values) => () => {
    return axios({
        method: "post",
        url: '/market/feed/poll',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};



const ALL_MARKET = createAction("ALL_MARKET");
export const allMarketNewsData = (values) => dispatch => {
    return axios({
        method: 'get',
        url: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_MARKET(res.data.data));
    })
};


const ALL_MARKET_NEWS = createAction("ALL_MARKET_NEWS");
export const allMarketNews = (values) => dispatch => {
    return axios({
        method: 'get',
        url: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_MARKET_NEWS(res.data.data));
    })
};




export const savedFeeds = (values) => dispatch => {
    return axios({
        method: 'get',
        url: `/collection/feed?sortBy=${values.sort}&entityType=${values.entity}&offset=${values.offset}&count=10`,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_MARKET(res.data.data));
    })
};



const NEW_DATA = createAction("NEW_DATA");
export const getNewData = (values) => dispatch => {
    return axios({
        method: 'get',
        url:   "market/feed/trigger?id="+values.id+"&"+values.type+"&etype="+values.etype,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(NEW_DATA(res.data.data));
    })
};


const NEWSLETTER_DETAILS = createAction("NEWSLETTER_DETAILS");
export const NewsletterDetails = (values) => dispatch => {
    return axios({
        method: 'get',
        url: 'market/feed/detail?id='+values.id,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(NEWSLETTER_DETAILS(res.data.data));
    })
};

const FEED_COMMENTS = createAction("FEED_COMMENTS");
export const totalComments = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "market/feed/comment?id="+values.id,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(FEED_COMMENTS(res.data.data));
    })
};





const CONTENT_TAGS = createAction("CONTENT_TAGS");
export const getTags = (values) => dispatch => {
    return axios({
        method: 'post',
        url: "market/generate_tags",
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(CONTENT_TAGS(res.data.data));
    })
};

 
export const submitComment = (values) => () => {
    return axios({
        method: 'post',
        url: "market/feed/comment",
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    )
};


export const DeleteNewsletter = (values) => () => {
    return axios({
        method: 'delete',
        url: "market/feed/delete?id="+values.id,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};


const NEWSLETTER_NEW = createAction("NEWSLETTER_NEW");
export const CreateNewsletter = (values) => (dispatch) => {
    return axios({
        method: 'post',
        url: '/market/feed/newsletter',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then((res)=>{
        dispatch(NEWSLETTER_NEW(res.data.data));
    })
};





const NEWSLETTER_SUMMARY = createAction("NEWSLETTER_SUMMARY");
export const CreateNewsletterSummary = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: '/lighthouse/tldr/summary/'+values.id,
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then((res)=>{
        dispatch(NEWSLETTER_SUMMARY(res.data.data));
    })
};

export const EditNewsletter = (values) => () => {
    return axios({
        method: 'put',
        url: 'api/newsletter/newsletter',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};



const SENT_CLIENTS = createAction("SENT_CLIENTS");
export const sendToClients = (values) => (dispatch) => {
    return axios({
        method: 'post',
        url: 'api/newsletter/send',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(SENT_CLIENTS(res.data.items));
    })
};




const NEWSLETTER_DETAIL = createAction("NEWSLETTER_DETAIL");
export const newsletterDetails = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: 'api/newsletter/detail?id='+values.id,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(NEWSLETTER_DETAIL(res.data.item));
    })
};


const ALL_CLIENTS = createAction("ALL_CLIENTS");
export const ClientList = () => dispatch => {
    return axios({
        method: 'get',
        url: 'api/Adviser/Client',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_CLIENTS(res.data.items));
    })
};



const MYHUB_FEED_COUNT = createAction("MYHUB_FEED_COUNT");
export const getMyFeedCount = () => dispatch => {
    return axios({
        method: 'get',
        url:   "market/feed/myhub",
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(MYHUB_FEED_COUNT(res.data.data));
    })
};