import { Button, Col, Row, Switch } from "antd";
import React from "react";

const ManageNotification = () => {
    return (
        <Row>
            <Col span={24} className='padding40'>
                <Row>
                    <Col xs={{ span: 19 }} lg={{ span: 19 }} md={{ span: 18 }}>
                        <p className="fs15 fw500">Send welcome message to new members</p>
                        <p className="fs12 fw300 md5 ">Create a custom message that new members will see as a
                            prompt after joining and /or as a direct message to their in chat.</p>
                    </Col>
                    <Col xs={{ span: 5 }} lg={{ span: 5 }} md={{ span: 5 }} className='text-right'>
                        <Switch />
                    </Col>
                    
                </Row>
            </Col>
        </Row>
    );
}
export default ManageNotification;