import React, { useState } from "react";
import { Col, Row, Input, Button, Divider, Alert } from "antd";
import axios from "../../../store/axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Toast from "../../utils/Toast";



const ChangeMobile = () => {
    const [step, setStep] = useState(1)
    const [mobile, setMobile] = useState("")
    const [otp, setOtp] = useState("")
    const history = useHistory()

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("profile"))
        setMobile(data?.mobile)
    }, [localStorage.getItem("profile")])

    


    const sendMobileOTP = () =>{
        if(mobile === ""){
            Toast("Enter Mobile Number","error")
            return
        }
        if(mobile.length > 10 || mobile.length < 10){
            Toast("Enter Valid Mobile Number","error")
            return
        }
        axios({
            method: 'post',
            url: "account/auth/mobile",
            data:{mobile_number:mobile},
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
           setStep(2)
        }).catch((err)=>{
            Toast(err.response.data.message,"error")
        })
    }



    const verifyOTP = () =>{
        if(otp === ""){
            Toast("Enter OTP")
            return
        }
        axios({
            method: 'put',
            url:  "account/auth/mobile",
            data:{
                "otp": otp,
                "mobile_number": mobile
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
            history.push("/settings")
        }).catch((err)=>{
            Toast(err.response.data.message,"error")
        })
    }

    return (

        <Row style={{ padding: "40px 0px 30px 30px" }}  className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18">Change Mobile</p>
                <Row>
                    <Col span={18}>
                        <p className="text-grey">Personal account information</p>
                    </Col>

                </Row>
                <Divider></Divider>
            </Col>

            {
                step === 1 && <>

                    <Col span={24} className="">
                        <p style={{ color: "#484848" }} className="sub-text-light">Mobile Number</p>
                        <Input type={'number'} maxLength={10} min={10} value={mobile} onChange={(evt)=>setMobile(evt.target.value)} placeholder="Mobile Number" className="md10"></Input>

                        <Alert className="md30" icon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 1.80078C5.02266 1.80078 1.8 5.02344 1.8 9.00078C1.8 12.9781 5.02266 16.2008 9 16.2008C12.9773 16.2008 16.2 12.9781 16.2 9.00078C16.2 5.02344 12.9773 1.80078 9 1.80078ZM9.6 12.6008H8.4V8.40078H9.6V12.6008ZM9 6.90078C8.50313 6.90078 8.1 6.49766 8.1 6.00078C8.1 5.50391 8.50313 5.10078 9 5.10078C9.49688 5.10078 9.9 5.50391 9.9 6.00078C9.9 6.49766 9.49688 6.90078 9 6.90078Z" fill="#146EB4" />
                        </svg>
                        } message="A six digit verification code will be send to existing Mobile Number." type="info" showIcon />


                    </Col>
                    <Col span={24} className="md30 text-right">
                        <Button onClick={() => sendMobileOTP(2)} style={{ paddingLeft: "20px", paddingRight: "20px" }} type="primary">Send Verification Code</Button>

                    </Col></>

            }
            {
                (step === 2 || step === 3) && <>
                    <Col span={24} className="">
                        <Alert icon={<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.0002 13.9332L3.2002 10.1332L4.13353 9.19987L7.0002 12.0665L14.5335 4.5332L15.4669 5.46654L7.0002 13.9332Z" fill="#0BAB38" />
                            <circle cx="8.5" cy="8.5" r="8" stroke="#0BAB38" />
                        </svg>
                        } message="A six digit verification code successfully sent to mobile number" type="success" showIcon />

                    </Col>
                    <Col span={24} className="md30">
                        <p style={{ color: "#484848" }} className="sub-text-light">Verification Code</p>

                    </Col>
                    <Col span={8}>
                        <Input value={otp} onChange={(evt)=>setOtp(evt.target.value)} className="md10" disabled={step === 3}></Input>
                    </Col>
                    <Col span={16} className="md10 text-right">
                        <Button disabled={step === 3} style={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={() => verifyOTP(3)} type="primary">Verify</Button>

                    </Col>
                    <Col span={12} className="md30">
                        <a className="link">Resend</a>

                    </Col>
                    {step === 3 &&
                        <Col span={12} className="md30 text-right">
                            <p style={{ color: "#0BAB38" }} className="sub-text-light">Code Verification success</p>

                        </Col>}
                </>
            }
          


        </Row>

    );
}
export default ChangeMobile;
