import React, { useState } from "react";
import { Col, Row, Input, Space, Button, Upload, message, Drawer, Select, Typography } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { useEffect } from "react";
import axios from "../../store/axios";

import Toast from "../utils/Toast";
import { FileVideo } from "@phosphor-icons/react";
import useToken from "../../store/useToken"

const { TextArea } = Input;
const { Paragraph } = Typography;


const AddLesson = () => {

    const { token } = useToken();
    const params = useParams();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [lessons, setLessons] = useState([]);
    const [drawer, setDrawer] = useState(false);
    const [media_type, ] = useState('VID');
    const [uploadURL, setUploadUrl] = useState({});
    const [content_id, setContentId] = useState(null);


    const history = useHistory();

    const [messageApi, contextHolder] = message.useMessage();
    const key = 'lessionUpload';

    useEffect(() => {
        updateCourse();
        getUploadURL();
    }, []);

    const updateCourse = async () => {
        await axios({
            method: 'get',
            url:  `v1.0/course/${params.id}/lesson`,
            headers: {
                Authorization: token,
            },
        }).then((response) => {
            setLessons(response?.data?.data);
        });
    }

    const getUploadURL = async () => {
        await axios({
            method: 'get',
            url:  `v1.0/course/upload_url`,
            headers: {
                Authorization: token,
            },
        }).then((response) => {
            setUploadUrl(response.data?.data?.url)
        });
    }

    const propsVideo = {
        accept: ".mp4",
        beforeUpload: file => {
            if (title === "") {
                Toast("Enter Title of Lesson", "error")
                return  false;
            }
            if (description === "") {
                Toast("Enter Description of Lesson", "error")
                return false;
            }

            const isLt2M = file.size / 1024 / 1024 < 500;
            if (!isLt2M) {
                Toast("Video file size should be smaller than 0.5GB", "error");
                return false;
            }
            
            return true;
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                addLesson(info.file.response);
            } else if (info.file.status === 'error') {
            }
        }, 
        action: uploadURL,
        onRemove(file){
            setContentId(null)
        }
    };


    async function addObject() {
        var newArr = lessons

        if (title === "") {
            Toast("Enter Title ", "error")
            return
        }
        if (description === "") {
            Toast("Enter Description", "error")
            return
        }
        if (content_id === null) {
            Toast("Upload contents first", "error")
            return
        }

        const _data = {
            'title': title,
            'description': description,
            'content_id': content_id,
            'lesson_id': "",
        }

        messageApi.open({
            key,
            type: 'loading',
            content: 'Adding...',
        });

        await axios({
            method: 'post',
            url:  `v1.0/course/${params.id}/lesson`,
            data: _data,
            headers: {
                Authorization: token,
            },
        }).then(() => {
            newArr.push({
                'content_id': content_id,
                
                ..._data
            })
            setTitle("");
            setDescription("");
            setContentId(null)
            updateCourse();
            messageApi.open({
                key,
                type: 'success',
                content: 'Added!',
                duration: 3,
            });
            onCloseDrawer();
        }).catch((err) => {
            messageApi.open({
                key,
                type: 'error',
                content: err.response.data.message,
            });
        });;
        setLessons(newArr)
    };

    const addLesson = async (dyntube) => {

        var data = {
            "video_id": dyntube?.videoId,
            "video_key": dyntube?.channelKey,
            "media_type": media_type,
            "attachment_url": "",
            "file_url": ""
        }

        await axios({
            method: 'post',
            url:  `v1.0/course/content`,
            data: data,
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            setContentId(res.data.data.id)
        }).catch((err) => {
            message.error(err.response.data.message);
        });

    };

    function removeObject(index) {
        messageApi.open({
            key,
            type: 'loading',
            content: 'Removing...',
        });
        axios({
            method: 'delete',
            url:  `v1.0/course/${params.id}/lesson?id=` + index?.lesson_id,
            headers: {
                Authorization: token,
            },
        }).then(() => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Removed!',
                duration: 3,
            });
            updateCourse();
        });
    };

    const onCloseDrawer = () => {
        setDrawer(false);

    }
    const handleChangeContentType = () => {

    }

    return (


        <Row className="md50">
            <Col lg={{ span: 8, offset: 8 }} xs={{ span: 22, offset: 1 }}>
                <Row>

                    <Col span={24} className="">
                        <p className="fs14 text-grey fw600"> Upload Course Lessons  </p>
                    </Col>
                    <Col span={24} className='courses-card md15'>
                        {
                            lessons.map((item, index) => {
                                return <div key={index} className="courses-h350 md10">
                                    <p className="text-grey" style={{ backgroundColor: '#F0F0F0', padding: '13px' }}>Lesson {index + 1}
                                        {
                                            item?.lesson_id && <span style={{ float: 'right', marginTop: '-3px' }}>
                                                <Button onClick={() => removeObject(item)} type="text" icon={<CloseOutlined />}></Button>
                                            </span>
                                        }
                                    </p>
                                    <div className="p-3">
                                    <p className="fw600">{item?.title}</p>
                                    <Paragraph className="md10 text-grey fw300" ellipsis={{rows:2}}>{item?.description}</Paragraph>

                                    </div>
                                    

                                </div>
                            })
                        }

                        <Space className="md20">
                            <Button onClick={() => setDrawer(true)} type="text" className="primary-light-btn" >Add lesson </Button>
                        </Space>
                    </Col>
                    <Col span={24} className='text-center md40 mb-5'>
                        {contextHolder}
                        <Space size="large">
                            <Button type="dashed" onClick={() => history.goBack()}> Back</Button>
                            <Button type="primary" className="text-white" onClick={() => history.push("/my-hub")}> Save</Button>

                        </Space>
                    </Col>
                </Row>
                <Drawer title="Add lesson" placement="right" onClose={onCloseDrawer} open={drawer} size="large" className="drawer-w100">
                    <Row className="md30">
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <Input size="large" value={title} width={50} showCount placeholder='Title' maxLength={250} onChange={(evt) => setTitle(evt.target.value)} />
                                </Col>
                                <Col span={24} className=" md10">
                                    <TextArea value={description} size="large" width={50} showCount placeholder='Description' maxLength={900} onChange={(evt) => setDescription(evt.target.value)} />
                                </Col>
                                <Col span={24} >
                                    <p className="fs16 fw600 text-grey" > Add contents  </p>
                                </Col>

                                <Col span={24} className="md10 p-3 rounded" style={{ backgroundColor: "#fafafa" }}>
                                    <Row>
                                        <Col span={24}>
                                            <p className="fs14 fw400 text-grey" >Media Type </p>
                                            <Select
                                                className="md5"
                                                defaultValue={media_type}
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={handleChangeContentType}
                                                options={[
                                                    {
                                                        value: 'VID',
                                                        label: 'Video',
                                                    },
                                                    {
                                                        value: 'IMG',
                                                        label: 'Image',
                                                        disabled: true,
                                                    }
                                                ]}
                                            />
                                        </Col>
                                        <Col span={24} className="md10">
                                            {
                                                media_type === "VID" && <Upload
                                                    listType="picture"
                                                    maxCount={1}
                                                    {...propsVideo}
                                                >
                                                    {
                                                        content_id === null && 
                                                        <><Button icon={<FileVideo size={20} className="mr-2"/>}>  Upload Video</Button><br />
                                                        <small>Only supports .mp4 format</small>
                                                        </>
                                                    } 
                                                </Upload>
                                            }
                                        </Col>
                                    </Row>


                                </Col>
                            </Row>

                            <Button type="primary" block className="md20" onClick={() => addObject()}> Save</Button>


                        </Col>
                        {/* <Col span={6} className=''>

                                            <Upload
                                                name="avatar"
                                                disabled={item?.lesson_id ? true : false}
                                                listType="picture-card"
                                                className="avatar-uploader upload-w100"
                                                showUploadList={false}
                                                {...propsEvent}
                                            >
                                                {uploadButton(item?.media_file_url)}
                                            </Upload>
                                        </Col> */}
                    </Row>
                </Drawer>
            </Col>
        </Row>
    );
}
export default AddLesson;