import React from "react";
import { Col, Row, Space, Button, Spin } from "antd";
import { CaretLeft } from '@phosphor-icons/react';
import { useHistory } from "react-router";

const ContentApproval = () => {
    const history = useHistory();
    return (
        <Row style={{ background: '#f9f9f9', minHeight: "calc(100vh - 69px)", borderRadius: "10px" }}>
            <Col lg={16} offset={4} md={{ span: 16, offset: 4 }} xs={{ span: 20, offset: 2 }} className="md50" >
                <Space size={4} style={{ marginLeft: '-12px' }}>
                    <Button onClick={() => history.goBack()} type="text" icon={<CaretLeft size={22} color="#676767" weight="light" />} ></Button>
                    <p className="fs18 fw600"> Content approval requests </p>
                </Space>

                <Spin >

                    <Row className="business-member-card mb-3 md30">
                        <Col span={24} className="text-center">No data</Col>


                    </Row>
                </Spin>
            </Col>
        </Row>
    );

}
export default ContentApproval;