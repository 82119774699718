


import { DotsThreeVertical, Prohibit, Bug } from "@phosphor-icons/react";
import { Avatar, Button, Col, Dropdown, Row, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import useToken from "../../store/useToken"
import { useHistory, useLocation } from "react-router";
import axios from "../../store/axios";
import FollowShare from "../utils/FollowShare";
import BlankState from "../BlankState";



const BusinessConnections = ({ parent }) => {
    const history = useHistory();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token } = useToken();


    useEffect(() => {
        if (token === false) {
            history.push(`/login?redirect=${location.pathname}`);
        }
        fetchData();
    }, []);


    const fetchData = async () => {
        setLoading(true);
        await axios({
            method: 'get',
            url:  `v1.0/business/${parent?.username}/team`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setData(data);
        });
        setLoading(false);
    };
    const onClickPeople = async ({ key, id }) => {

        if (key === '2') {

        }
    };

    const itemsPeople = (item) => {

        return [
            {
                label: (
                    <span className="fs12 fw400"> Block @{item?.username}</span>
                ),
                key: '1',
                icon: <Prohibit size={18} color="#000000" />
            },
            {
                label: (
                    <span className="fs12 fw400"> Report Issue</span>
                ),
                key: '2',
                icon: <Bug size={18} color="#000000" />
            }

        ]
    };

    

    const render =()=>{
        return <Row className="p-3" gutter={20}>
                {data.map((item, i) => { 
                    return <Col key={i} lg={parent?.inModal ? 24 : 8} md={parent?.inModal ? 24 : 24} xs={24}> <Row className='creator-card md30 padding20'>

                        <Col span={21} className=''>
                            <Space size={10} align="top">

                                <Avatar size={60} src={item?.img_url}> </Avatar>
                                <div>
                                    <a href={"/profile/" + item?.user_code}>  <p className="fs16 fw600 mb-0" style={{ textTransform: "capitalize" }}> {item?.name} </p> </a>
                                    <span className="fs12 fw400" style={{ color: '#728096' }}>@{item?.username}</span> <br />

                                    <FollowShare profile={item}></FollowShare>
                                </div>

                            </Space>
                        </Col>
                        <Col span={3} className="text-right">
                            <div className="menu-surface-anchor">
                                {
                                    <Dropdown trigger={['click']} menu={{ items: itemsPeople(item), onClick: ({ key }) => onClickPeople({ key: key, id: item }) }} placement={"bottomCenter"} overlayStyle={{ minWidth: "120px" }}>
                                        <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                    </Dropdown>
                                }

                            </div>
                        </Col>

                    </Row></Col>
                })}

                {
                    data.length === 0 && <Col span={24} className="md40">
                        <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} />
                    </Col>
                }
            </Row>
    }

    return (
        loading ? <Skeleton></Skeleton>: <> {render()}</>
    );
}
export default BusinessConnections;