import React, { Component } from "react";
import { Button, Row, Col, Avatar, Input, Space } from "antd";
import Cookies from "js-cookie";
import ScrollableFeed from 'react-scrollable-feed'
import { AudioOutlined, SendOutlined } from "@ant-design/icons";
import moment from "moment";

class EventChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      showChat: true,
      ws: new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/event/live?token=" + Cookies.get("Logintoken") + "&id=" + this.props.id),
      messages: [],
      members: [],
      text: "",
      role : Cookies.get("user_code"),
    };
  }

  componentWillUnmount() {
    if (this.state.ws.OPEN){
      this.state.ws.close()
    }
    
  }

  componentDidMount() {
    this.state.ws.onmessage = evt => {
      const data = JSON.parse(evt.data)
      var _messages = this.state.messages.concat(data.messages);
      var _members = data.members;
      this.setState(({ messages: _messages, members: _members }))
      this.setState({ loader: false })
    }

    this.state.ws.onclose = () => {
      this.setState({
        ws: new WebSocket(process.env.REACT_APP_WS_BASE_URL + "/wss/event/live?token=" + Cookies.get("Logintoken") + "&id=" + this.props.id)
      })
    }
  }


  submitMessage() {
    if (this.state.text === "") {
      return
    }
    var message = ""
    message = { m: this.state.text }
    this.state.ws.send(JSON.stringify(message))
    this.setState({ text: "" })
  }

  render() {
    return (
      <Row>
        <Col span={24}>
        <div className="event-chat-box" style={{ marginBottom: "20px" }}>
          <Row className="border-bottom chat-tabs m-p0">
            <Col lg={this.props.is_owner ? 12 : 24} xs={{ span: 12 }} className={this.state.showChat ? "active-channel-tab " : "inactive-channel-tab"} onClick={() => this.setState({ showChat: true })}>
              <p className="fs14">Chats</p>
            </Col>
            {
              this.props.is_owner && <Col lg={{ span: 12 }} xs={{ span: 12 }} className={this.state.showChat ? "inactive-channel-tab" : "active-channel-tab"} onClick={() => this.setState({ showChat: false })}>
                <p className="fs14">Participants</p>
              </Col>
            }

          </Row>
          <div className="pb-0 px-3">


            {this.state.showChat === true ?

              <div style={{ height: "67vh", overflowY: "scroll" }}>
                <ScrollableFeed forceScroll={true}>

                  {
                    this.state.messages.map((item, index) => {
                      return <Row className='md15 mb-1' key={index}>

                      <Col span={24}>
                          <Space size={8} align='top'>
                              <Avatar src={item[2][0]} onClick={()=> this.props.history.push(`/profile/${item[10]}`)} className='cursor' size={30}>{item[2][0]}</Avatar>
                              <div>
                                  <Space size={5}>
                                  <a href={`/profile/${item[10]}`} className="fs-12 " >{item[2]}</a>
                                  <small className='text-grey fs10'>{moment(item[4]).format("LT a")}</small> 
                                  </Space>
                                 
                                  <p className='fs14' style={{color:'#4B5669'}}>{item[3]}</p>
                              </div>
                          </Space>
                      </Col>
                      </Row>
                      // if (item[10] === this.state.role) {
                      //   return <Row className='mt24' key={index}>

                      //     <Col span={6}></Col>
                      //     <Col span={18}>

                      //       <div className="messgae-frame-me-you">
                      //         {/* <p className="fs-12 text-muted">You</p> */}

                      //         <div className="messgae-frame-me">
                      //           <p className="msg">{item[3]}</p>
                      //         </div>
                      //       </div>
                      //     </Col>
                      //   </Row>
                      // }
                      // else {
                      //   return <Row className='mt24' key={index}>
                      //     <Col span={2}></Col>
                      //     <Col span={22} className="ps-2">
                      //       <p className="fs-12 text-muted ms8">{item[2]}</p>
                      //     </Col>
                      //     <Col span={2}>
                      //       <Avatar size={30}>{item[2][0]}</Avatar>
                      //     </Col>
                      //     <Col span={18} className="ms-2">
                      //       <div className="messgae-frame ms8">
                      //         <p className="msg">{item[3]}</p>
                      //       </div>
                      //     </Col>
                      //   </Row>
                      // }
                    })
                  }
                </ScrollableFeed>
              </div>
              :
              <div style={{ height: "74vh" }}>

                {
                  this.state.members.map((item, i) => {
                    return <Row className='mt24' key={i}>
                      <Col span={24}>
                       <Space>
                       <Avatar size={30} src={item[1]}></Avatar>
                       <p className="fs14 text-muted text-left">{item[0]}</p>
                       </Space>
                      </Col>
                      {/* <Col span={21}>
                        <div className="mt07rem">
                          <Row justify="space-around">
                            <Col span={18}></Col>
                            <Col span={2} className='text-right'><AudioOutlined className='video-icons' /></Col>
                          </Row>
                        </div>
                      </Col> */}
                    </Row>
                  })
                }

              </div>
            }
          </div>
          {this.state.showChat === true ? <>

            <Row className="input-chat">
              <Col span={24} className="input-chat-box">
                <Input className='fs16' value={this.state.text} onChange={(evt) => this.setState({ text: evt.target.value })} onPressEnter={() => this.submitMessage()} placeholder="Basic usage" suffix={
                  <Button className="btn chat-send-btn" onClick={() => this.submitMessage()}> <SendOutlined /></Button>
                } />
              </Col>

            </Row>
          </> : ""}

        </div>
        </Col>
      </Row>
    );
  }
}

export default EventChat;
