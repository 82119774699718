import React, { useState, useEffect } from "react";
import { Col, Row, Avatar, Space, Menu, Skeleton, Drawer, Button } from "antd";
import '../settings.scss'
import UpdateProfile from "./UpdateProfile";
import EditName from "./EditName";
import ChangeEmail from "./ChangeEmail";
import ChangeRole from "./ChangeRole";
import BusinessProfile from "./BusinessProfile";
import { withRouter } from "react-router";
import axios from "../../../store/axios";
import Cookies from "js-cookie";
import { MenuFoldOutlined } from "@ant-design/icons";
import ChangeMobile from "./ChangeMobile";



var url_string = window.location.href
var url = new URL(url_string);
var step = url.searchParams.get("step");

const EditProfile = (props) => {
    const [current, setCurrent] = useState('1');
    const [role, setRole] = useState(parseInt(Cookies.get('__role')))
    const [data, setData] = useState(null)
    const [loader, setLoader] = useState(true)
    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => {
        if (step == null) {
            setCurrent("1")
        }
        else {
            setCurrent(step)
        }
    }, [step])


    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("profile"))
        if (data) {
            setData(data)
            setLoader(false)
        }
        else {
            axios({
                method: 'get',
                url:  "account/settings",
                headers: {
                    Authorization: Cookies.get("Logintoken"),

                }
            }).then(res => {
                const _data = res.data.data
                setData(_data)
                setRole(_data?.account_type)
                localStorage.setItem("profile", JSON.stringify(_data))
                setLoader(false)
            })
        }
    }, [])

    function onSelectTab(e) {
        setCurrent(e.key)
        setOpenDrawer(false)
    }

    const settings_menu = (

        !loader && <Menu className="setting-menu" defaultSelectedKeys={[current]} onSelect={(e) => onSelectTab(e)}>
            <Space aria-orientation="horizontal">
                <Avatar shape="square" src={<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 2.125C6.74438 2.125 5.3125 3.55688 5.3125 5.3125C5.3125 7.06812 6.74438 8.5 8.5 8.5C10.2556 8.5 11.6875 7.06812 11.6875 5.3125C11.6875 3.55688 10.2556 2.125 8.5 2.125ZM8.5 8.5C5.57397 8.5 3.1875 10.8865 3.1875 13.8125H4.25C4.25 11.4592 6.14673 9.5625 8.5 9.5625C10.8533 9.5625 12.75 11.4592 12.75 13.8125H13.8125C13.8125 10.8865 11.426 8.5 8.5 8.5ZM8.5 3.1875C9.67871 3.1875 10.625 4.13379 10.625 5.3125C10.625 6.49121 9.67871 7.4375 8.5 7.4375C7.32129 7.4375 6.375 6.49121 6.375 5.3125C6.375 4.13379 7.32129 3.1875 8.5 3.1875Z" fill="#7D7D7D" />
                </svg>
                } size={17}></Avatar>
                <p className="menu-head">Personal</p>
            </Space>
            <Menu.Item key={'1'}>Change Username</Menu.Item>
            <Menu.Item key={'2'}>Edit Name & About</Menu.Item>
            <Menu.Item key={'3'}>Edit Email</Menu.Item>
            <Menu.Item key={'6'}>Edit Mobile Number</Menu.Item>

            <Menu.Item key={'4'}>Change Account Type</Menu.Item>


            {/* {
                role === 9 && <>
                    <Space aria-orientation="horizontal" className="md30">
                        <Avatar shape="square" src={<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.50001 1.41699C7.91351 1.41699 7.43751 1.89299 7.43751 2.47949V2.97201C5.60518 3.44431 4.25001 5.10372 4.25001 7.08366V11.3337L2.83334 12.7503V13.4587H7.27426C7.14953 13.6739 7.08368 13.9182 7.08334 14.167C7.08334 14.9494 7.71761 15.5837 8.50001 15.5837C9.28241 15.5837 9.91668 14.9494 9.91668 14.167C9.91591 13.9181 9.84959 13.6738 9.72437 13.4587H14.1667V12.7503L12.75 11.3337V7.08366C12.75 5.10372 11.3948 3.44431 9.56251 2.97201V2.47949C9.56251 1.89299 9.08651 1.41699 8.50001 1.41699ZM8.50001 4.25033C10.0626 4.25033 11.3333 5.52108 11.3333 7.08366V11.3337V11.9202L11.4551 12.042H5.54493L5.66668 11.9202V11.3337V7.08366C5.66668 5.52108 6.93743 4.25033 8.50001 4.25033H8.50001Z" fill="#7D7D7D" />
                        </svg>
                        } size={17}></Avatar>
                        <p className="menu-head">Business</p>
                    </Space>
                    <Menu.Item key={'5'}>Business Profile</Menu.Item>
                </>
            } */}


        </Menu>


    )

    return (

        <Row className="">

            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }} md={{ span: 22, offset: 1 }} className="md35">
                <Row>
                    <Space aria-orientation="horizontal">
                        <Avatar size={24} shape='square' className={"cursor ps-1"} onClick={() => props.history.goBack()} src={<svg width="24" vertical-align="unset" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.45 21.5L17 19.95L9.05 12L17 4.05L15.45 2.5L6 12L15.45 21.5Z" fill="#676767" />
                        </svg>
                        }></Avatar>

                        <p className="mt-1 fs20">Edit Profile</p>
                    </Space>
                </Row>
                <Row className="settings-card md30">
                    <Col xs={{ span: 0 }} lg={{ span: 6 }} md={{ span: 6 }} className='border-right py40'>
                        {settings_menu}

                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 0 }} md={{ span: 0 }} className='mb-2'>
                        <Button onClick={() => setOpenDrawer(true)} type="default" icon={<MenuFoldOutlined />}></Button>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 18 }} md={{ span: 18 }}>
                        {loader ? <Skeleton></Skeleton> : <>

                            {current === '1' && <UpdateProfile data={data} />}
                            {current === '2' && <EditName />}
                            {current === '3' && <ChangeEmail />}
                            {current === '6' && <ChangeMobile />}
                            {current === '4' && <ChangeRole />}
                            {current === '5' && <BusinessProfile />}
                        </>}




                    </Col>
                    <Drawer size='default' placement="left" onClose={() => setOpenDrawer(false)} visible={openDrawer} >
                        <Row>
                            <Col span={24} className="px-3 md20">
                                {settings_menu}

                            </Col>
                        </Row>
                    </Drawer>



                </Row>
            </Col>


        </Row>
    );
}
export default withRouter(EditProfile);
