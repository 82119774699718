import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Select, Upload } from "antd";
import axios from "../../../store/axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import userToken from "../../../store/useToken"
import request from '../../../store/request';

import Toast from "../../utils/Toast";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        Toast('You can only upload JPG/PNG file!', "error");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        Toast('Image must smaller than 2MB!', "error");
    }
    return isJpgOrPng && isLt2M;
};


const Onboard_BusinessInfo = () => {
    const history = useHistory();
    const params = useParams();
    const { token } = userToken();

    const [loader, setLoader] = useState(false);
    const [orgType, setOrgType] = useState(null);
    const [expertise, setExpertise] = useState([]);
    const [erroText, setErrorText] = useState("Address is not available!");
    const [isAvailable, setIsAvailable] = useState(true);
    const [username, setUsername] = useState('');
    const [website, setWebsite] = useState("");
    const [name, setName] = useState("");
    const [logo, setlogo] = useState("");
    const [role, setRole] = useState(null);

    const [messageApi, contextHolder] = message.useMessage();
    const [masterExpertise, setMasterExpertise] = useState([]);
    const [masterCategory, setMasterCategory] = useState([]);

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const key = 'onboard';

    useEffect(() => {
        setRole(params['role']);
        getExpertise();
        getProfession();
    }, []);


    const checkUsername = async (key) => {

        await axios({
            method: 'get',
            url:  `account/auth/username/verify/business?username=${key}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setErrorText("Address is not available!");
            setIsAvailable(!res.data.data.exists)
        }).catch((err) => {

        })

    };

    const onUsernameChange = (value) => {
        // logic
        setUsername(value);
        const re = new RegExp('^(?=.*[a-zA-Z])[a-zA-Z0-9._]+$');
        if (!re.test(value) || value.length < 2 || value.length > 32) {
            setErrorText('Invalid address!');
            setIsAvailable(false);
            return
        } else {
            setIsAvailable(true);
        }
        checkUsername(value);

    }


    const getExpertise = () => {

        axios({
            method: 'get',
            url:  "account/master/expertise",
            headers: {
                Authorization: token
            }
        }).then((res) => {
            const data = res.data.data
            let _n = []
            for (let i = 0; i < data.length; i++) {
                _n.push({ label: data[i].name, value: data[i].expertise_id })
            }
            setMasterExpertise(_n)

        }).catch((err) => {
            Toast(err.response.data.message, "error")
        })

    };
    const getProfession = () => {

        axios({
            method: 'get',
            url:  "account/master/profession",
            headers: {
                Authorization: token
            }
        }).then((res) => {
            const data = res.data.data
            let _n = []
            for (let i = 0; i < data.length; i++) {
                _n.push({ label: data[i].name, value: data[i].business_type_id })
            }
            setMasterCategory(_n)

        }).catch((err) => {
            Toast(err.response.data.message, "error")
        })

    };


    const saveData = async () => {
        if (!isAvailable) {
            return
        }

        if (username === "") {
            Toast("Enter valid username!", "error")
            return
        }

        if (name === "") {
            Toast("Enter valid name!", "error")
            return
        }

        if (role === '9' && (orgType === "" || orgType === null)) {
            Toast("Select Organization type", "error");
            return
        }
        if (expertise.length === 0) {
            Toast("Select Expertise!", "error")
            return
        }
        setLoader(true);

        messageApi.open({
            key,
            type: 'loading',
            content: 'Updating account...',
        });

        const payload = {
            business_type_id: orgType,
            expertise: expertise,
            website: website,
            logo: logo,
            username: username,
            name: name
        }

        await axios({
            method: 'post',
            url:  "account/auth/onboard/professional",
            data: payload,
            headers: {
                Authorization: token
            }
        }).then(() => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Account updated!',
                duration: 3,
            });
            const code = Cookies.get('user_code')
            history.push(`/onboard/${code}/review`)
        }).catch((err) => {
            setLoader(false);
            messageApi.open({
                key,
                type: 'error',
                content: err.response.data.message,
                duration: 3,
            });
        })
        setLoader(false);

    }

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setlogo(info.file.response.data.url)
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const props1 = {
        action:  "account/file/upload",
        headers: {
            Authorization: Cookies.get("Logintoken"),

        },
        customRequest: (options) => request.customRequest(options, "note"),
    };

    return (
        <Row justify={"center"}>
            {contextHolder}
            <Col lg={12} xs={24} className="m-padding20 md70">
                <Row>
                    <Col lg={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
                        <h3 className="login-heading-text">Tell us more <br />
                            about your {role === '10' ? "Institute/School" : "Business"}</h3>

                        <Row gutter={30} className="md20">
                            <Col lg={15} xs={24} className="md10">
                                <p className="fs14 fw600">Your business unique address </p>
                                <Input value={username} onChange={(e) => onUsernameChange(e.target.value)} addonBefore="https://syllo.co/business/" className="md10" />
                                {
                                    !isAvailable && <p className="fs12 fw400 md5 text-red">{erroText}</p>
                                }
                                <p className="fs12 fw400 md10" style={{ color: "#728096" }}>NOTE: Please note that this ID can never be changed again.
                                    Kindly make sure you’re entering correct ID name.</p>
                            </Col>
                            <Col lg={9} xs={24}>

                                <p className="fs14 fw600 m-md20">Logo </p>
                                <Upload
                                    name="company-logo"
                                    listType="picture-card"
                                    className="md10"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                    {...props1}
                                >
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="company-logo"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Col>
                        </Row>
                        <Row className="md30">
                            <Col span={24}>
                                <p className="fs14 fw600">Organization name</p>
                                <Input maxLength={50} onChange={(e) => setName(e.target.value)} value={name} placeholder="e.g., Zerodha" className="md10" />

                            </Col>

                        </Row>
                        <Row gutter={30} className="md30">
                            {
                                role === '9' && <Col span={12} >
                                    <p className="fs14 fw600">Organization Type </p>
                                    <Select
                                        className="md10 select-org-type fs14"
                                        style={{
                                            width: '100%'
                                        }}

                                        value={orgType}
                                        options={masterCategory}
                                        onChange={(newValue) => {
                                            setOrgType(newValue);
                                        }}
                                        placeholder='e.g., Sole proprietorship' />
                                </Col>
                            }
                            <Col lg={{ span: role === '9' ? 12 : 24 }} xs={{ span: 24 }}>
                                <p className="fs14 fw600 m-md20">Expertise</p>
                                <Select
                                    className="md10 select-org-type"
                                    mode="multiple"
                                    style={{
                                        width: '100%'
                                    }}

                                    value={expertise}
                                    options={masterExpertise}
                                    onChange={(newValue) => {
                                        setExpertise(newValue);
                                    }}
                                    maxLength={4}
                                    placeholder='e.g., Financial planning, Loans' />
                            </Col>
                        </Row>
                        <Row className="md35">
                            <Col span={24}>
                                <p className="fs14 fw600">Website</p>
                                <Input onChange={(e) => setWebsite(e.target.value)} value={website} placeholder="e.g, https://syllo.co" className="md10" />

                            </Col>

                        </Row>
                        <Row>
                            <Col span={24} className='md50 mb-4 text-center'>
                                <Button size="large" block loading={loader} onClick={() => saveData()} type="primary" >
                                    Continue
                                </Button>
                            </Col>

                        </Row>
                    </Col>
                </Row>


            </Col>
        </Row >
    );
}
export default Onboard_BusinessInfo;