import React, { PureComponent } from "react";
import { Input, Button } from "antd";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import Toast from "../utils/Toast";

class ResetPassword extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            password: "",
            userId: "",
            password: "",
            cpassword: "",
            sent: false
        };
    }

    async componentDidMount() {
        var url_string = window.location.href; //window.location.href
        var url = new URL(url_string);
        var c = url.searchParams.get("access");
        var user = url.searchParams.get("u")
        await this.setState({
            token: c,
            userId: user
        })
    }

    ResetPassword(evt) {
        evt.preventDefault()
        if (this.state.password !== this.state.cpassword) {
            Toast("Password Miss-Match","error")
            return
        }
        this.props.ResetPassword({
            password: this.state.password,
            token: this.state.token,
            userId: this.state.userId
        }).then(() => {
            this.setState({
                sent: true
            })
            setTimeout(() => {
                this.props.history.push("/login")
            }, 5000);
        })
    }

    render() {
        return (
            <div className="row white-background-login">
                <div className="col-md-4 offset-md-4  border-card md50">
                    <div className="row">
                        {/* <div className="col-md-12">
                            <img src={require("../../assets/images/syllo_text.svg")} alt="logo"></img>
                        </div> */}

                        <div className="col-md-6 offset-md-3 text-center">
                            <img src={require("../../assets/images/Desktop/syllo.svg").default} alt="logo" style={{width:"60%"}}></img>
                        </div>


                        {
                            this.state.sent === false ? <>
                                <div className="col-md-12 md30 text-center">
                                    <p className="sub-text">Enter new password to get started.</p>
                                </div>

                                <div className="col-md-10 offset-md-1 md20 ">
                                    <form className="row text-center" onSubmit={(evt) => this.ResetPassword(evt)}>
                                        <div className="col-md-12">
                                            <Input
                                                size="large"
                                                autoComplete="off"
                                                className="logininput"
                                                required
                                                iconRender={(visible) =>
                                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                                }
                                                placeholder="Enter New Password"
                                                type="password"
                                                onChange={(evt) =>
                                                    this.setState({ password: evt.target.value })
                                                }
                                            />

                                        </div>

                                        <div className="col-md-12 md20">
                                            <Input
                                                size="large"
                                                autoComplete="off"
                                                className="logininput"
                                                required
                                                iconRender={(visible) =>
                                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                                }
                                                placeholder="Confirm New Password"
                                                type="password"
                                                onChange={(evt) =>
                                                    this.setState({ cpassword: evt.target.value })
                                                }
                                            />

                                        </div>


                                        <div className="col-md-12 md50">
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                size="large"
                                                block
                                                onSubmit={() => this.ResetPassword()}>
                                                Reset Password
                                  </Button>
                                        </div>

                                    </form>

                                    <div className="text-right md20">
                                        <Button href='/login' size="small" type="link" className="text-grey">Sign-in</Button>
                                    </div>
                                </div>

                            </> : <div className="col-md-10 offset-md-1 md10">
                                <div className="col-md-12 md20">
                                    <img src={require("../../assets/images/success.PNG").default} alt="logo"></img>
                                </div>
                                <div className="col-md-12 md50">
                                    <p className="sub-text">Password Reset Successfully.  </p>
                                </div>


                                <div className="col-md-12 md100">
                                    <p className="sub-text">You will redirect to login in 5 sec <br></br>  <a className="blue-text underline md50" href="/login">Go Now</a> </p>
                                </div>
                            </div>
                        }



                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    _login: state.login.login_details
});
const mapDispatchToProps = (dispatch) => ({
    ResetPassword: v => dispatch(actions.ResetPassword(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
