import React from "react";
import { Tag } from 'antd';
import { withRouter } from "react-router";

const EntityTags = ({ item, getFilterByTags, tags }) => {

    return <>
        {
            item?.tags && item?.tags.length > 0 &&
            <p className="blue-text md10 new-dashb-tag">
                {
                    !tags && item.tags.map((item, i) => {
                        return <Tag key={i} className={"cursor"} onClick={() => getFilterByTags(item, "", "")}>{item}</Tag>
                        //  <span key={i} className="cursor margin-right  tags-round cursor" onClick={() => getFilterByTags(item, "", "")}>#{item}</span>
                    })
                }
            </p>
        }
    </>
}
export default withRouter(EntityTags);