import React from 'react';
import { Row, Col, Divider, Tag, Tooltip, Skeleton, Typography, Space, Button} from 'antd';
import moment from 'moment';
import { Dot, Export, Trash } from "@phosphor-icons/react";
import BlankState from '../BlankState';
const { Paragraph } = Typography

const MyHubResearchReport = ({ data, loader, deleteFeed, details }) => {

    return (

        <Row className='' >
            {
                loader ? <Skeleton></Skeleton> : data.length === 0 ? <Col span={24} className="md100 text-center">
                    <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} />

                </Col> :


                    data.map((item) => {
                        return <Col span={24} className={"single-channel-view cursor"} style={{ marginBottom: "20px" }}>
                            <Row className=''>
                                <Col md={18} xs={17} >
                                    <Space align='center'>
                                        <Tag color="magenta"  >Report</Tag>
                                        <div className="dot-small"></div>
                                        <p style={{ color: 'background: #868686', textAlign: 'left' }} >  {item.name} </p>
                                    </Space>

                                </Col>
                                <Col md={6} xs={7} className="text-right">
                                    {/* <p>  {moment(item.created_at).format("Do MMM YYYY")} </p> */}
                                <p className='fs12 text-grey'>  {moment(item.created_at).format("Do MMM YYYY")} </p>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col md={21} xs={19} style={{ minHeight: "77px" }}>
                                    <h5 className='cursor' onClick={() => details(item)}>{item.title}</h5>
                                    <Paragraph className="fs14 desc" ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}>
                                        {item.description}
                                    </Paragraph>
                                    <Space align='center' size={0} wrap split={<Dot size={24} />}>
                                    <p className='fs12 text-grey' >  {item.likes} Likes </p>
                                    <p className='fs12 text-grey' >  {item.comments} Comments </p>
                                    <p className='fs12 text-grey' >  {item?.published ? "Published":"Draft"} </p>
                                        
                                        </Space>
                                </Col>
                                <Col md={3} xs={5} className=" text-right show-on-channel-hover">
                                    <Tooltip placement="top" title={"Share"}>
                                        <Button type={'text'} icon={<Export size={18} color="#686464" weight="light" />}>
                                        </Button>


                                    </Tooltip>

                                    <Tooltip placement="top" title={"Delete"}>

                                        <Button onClick={() => deleteFeed(item.entity_uuid)} type={'text'} icon={<Trash size={18} color="#686464" weight="light" />}>
                                        </Button>


                                    </Tooltip>

                                </Col>
                            </Row>
                            <Divider/>
                        </Col>

                    })
            }




        </Row>
    );
}

export default MyHubResearchReport;
