import React, { useState, useEffect, useRef } from 'react';
import { Button, Space } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from 'redux-actions';
import './audio-player.scss';

const AudioPlayer = ({ src }) => {
    const dispatch = useDispatch();
    const { play, podcast } = useSelector((state) => state.podcast);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setVolume] = useState(1); // Volume range is from 0 to 1

    const audioRef = useRef(null);

    const togglePlayPause = (play) => {
        if (play) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    };

    useEffect(() => {
        audioRef.current = new Audio(src);

        const audio = audioRef.current;

        const setAudioData = () => {
            setDuration(audio.duration);
            setCurrentTime(audio.currentTime);
        };

        const setAudioTime = () => setCurrentTime(audio.currentTime);

        audio.addEventListener('loadedmetadata', setAudioData);
        audio.addEventListener('timeupdate', setAudioTime);

        if (play) audio.play();

        return () => {
            audio.pause();
            audio.removeEventListener('loadedmetadata', setAudioData);
            audio.removeEventListener('timeupdate', setAudioTime);
        };
    }, [src]);

    const formatTime = (time) => {
        return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);
    };

    useEffect(() => {
        togglePlayPause(play);
    }, [play]);

    const updateStatePlay = (state) => {
        const PODCAST_PLAY = createAction('PODCAST_PLAY');
        dispatch(PODCAST_PLAY(state));
    }

    const changeProgress = (e) => {
        const newTime = e.target.value;
        setCurrentTime(newTime);
        audioRef.current.currentTime = newTime;
    };

    const changeVolume = (e) => {
        const newVolume = e.target.value;
        setVolume(newVolume);
        audioRef.current.volume = newVolume;
    };

    return (
        <div className="md15" style={{ backgroundColor: '#E6EFFF', borderRadius: '8px', padding: '8px' }}>
            <Space size={12} align='center'>
                {
                    (play) ?
                        <Button onClick={() => updateStatePlay(false)} size="large" type="text" icon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9957 12.5535 27.6247 9.2494 25.1876 6.81236C22.7506 4.37532 19.4465 3.0043 16 3ZM14 20C14 20.2652 13.8946 20.5196 13.7071 20.7071C13.5196 20.8946 13.2652 21 13 21C12.7348 21 12.4804 20.8946 12.2929 20.7071C12.1054 20.5196 12 20.2652 12 20V12C12 11.7348 12.1054 11.4804 12.2929 11.2929C12.4804 11.1054 12.7348 11 13 11C13.2652 11 13.5196 11.1054 13.7071 11.2929C13.8946 11.4804 14 11.7348 14 12V20ZM20 20C20 20.2652 19.8946 20.5196 19.7071 20.7071C19.5196 20.8946 19.2652 21 19 21C18.7348 21 18.4804 20.8946 18.2929 20.7071C18.1054 20.5196 18 20.2652 18 20V12C18 11.7348 18.1054 11.4804 18.2929 11.2929C18.4804 11.1054 18.7348 11 19 11C19.2652 11 19.5196 11.1054 19.7071 11.2929C19.8946 11.4804 20 11.7348 20 12V20Z" fill="#4B5669" />
                            </svg>
                        }></Button> :
                        <Button onClick={() => updateStatePlay(true)} size="large" type="text" icon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9957 12.5535 27.6247 9.2494 25.1876 6.81236C22.7506 4.37532 19.4465 3.0043 16 3ZM20.555 16.8325L14.555 20.8325C14.3904 20.9416 14.1974 20.9998 14 21C13.7348 21 13.4804 20.8946 13.2929 20.7071C13.1054 20.5196 13 20.2652 13 20V12C12.9999 11.8189 13.0489 11.6412 13.1419 11.4858C13.2349 11.3304 13.3684 11.2032 13.528 11.1177C13.6877 11.0323 13.8675 10.9918 14.0484 11.0005C14.2293 11.0093 14.4044 11.067 14.555 11.1675L20.555 15.1675C20.6922 15.2588 20.8047 15.3825 20.8825 15.5278C20.9603 15.673 21.001 15.8352 21.001 16C21.001 16.1648 20.9603 16.327 20.8825 16.4722C20.8047 16.6175 20.6922 16.7412 20.555 16.8325Z" fill="#4B5669" />
                            </svg>
                        }></Button>
                }
                <div style={{ width: "40px"}} className='text-end'>
                    <p className="fs12 " style={{ color: '#4B5669' }}> {formatTime(currentTime)} </p>
                </div>
                {/* <input type="range" min="0" max="1" step="0.01" value={volume} onChange={changeVolume} /> */}
                <div style={{marginTop:"-2px"}}>
                <input className="progress-audio" type="range" min="0" max={duration} value={currentTime} onChange={changeProgress} />

                </div>
                <div >
                    <p className="fs12 " style={{ color: '#4B5669' }}> {formatTime(duration)} </p>
                </div>
            </Space>

        </div>
    );
};

export default AudioPlayer;
