import React from "react";
import { Row, Col, Avatar } from 'antd';
import EntityFooter from "./EntityFooter";
import EntityHeader from "./EntityHeader";
import EntityTags from "./EntityTags";
import PostBody from "./PostBody";
import PollBody from "./PollBody";
import RReportBody from "./RReportBody";
import NoteBody from "./NoteBody"
import GameBody from "./GameBody";
import NewsBody from "./NewsBody";
import LiveBody from "./LiveBody";
import { useHistory } from "react-router-dom";
import "../../assets/css/style.scss";
import PodcastBody from "./PodcastBody";


const EntityFrame = ({ item, getFilterByTags, details, tags, removeObjectAfterUnSaved }) => {
    const history = useHistory();

    const entityBody = (item) => {
        switch (item.entity_type) {
            case "Post":
                return <PostBody details={(item) => details(item)} item={item}></PostBody>
            case "Newsletter":
                return <PostBody details={(item) => details(item)} item={item}></PostBody>
            case "Poll":
                return <PollBody details={(item) => details(item)} _item={item}></PollBody>
            case "Notes":
                return <NoteBody details={(item) => details(item)} item={item}></NoteBody>
            case "Report":
                return <RReportBody details={(item) => details(item)} item={item}></RReportBody>
            case "Game":
                return <GameBody details={(item) => details(item)} item={item}></GameBody>
            case "News":
                return <NewsBody details={(item) => details(item)} item={item}></NewsBody>
            case "Live":
                return <LiveBody item={item} />
            case "Podcast":
                return <PodcastBody item={item} />

        }
    }

    return <>
        <Row>
            <Col span={24}>
                <div className="feed-structure-p" >
                    <div>
                        <Avatar size="large" src={item?.entity_type === "News" ? item?.icon_uri : item?.profile_pic} onClick={() => history.push("/profile/" + item?.user_code)}>{item?.profile_pic ? <></> : item?.name && item?.name.substring(0, 1)}</Avatar>
                    </div>
                    <div className="w-100">
                        <Row >
                            <Col span={24}>
                                <EntityHeader item={item} />
                            </Col>
                            <Col span={24} className="md15">
                                {
                                    entityBody(item)
                                }
                                {
                                    item.entity_type === "Post" || item.entity_type === "Newsletter" || item.entity_type === "Report" || item.entity_type === "Notes" && <EntityTags item={item} getFilterByTags={getFilterByTags} tags={tags} />
                                }
                                {
                                    item.entity_type !== "Game" && item.entity_type !== "Live" && <EntityFooter item={item} details={details} removeObjectAfterUnSaved={(id) => removeObjectAfterUnSaved(id) | null} />
                                }

                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    </>
}
export default EntityFrame;