import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Dropdown, Avatar, Row, Space, Skeleton, } from "antd";
import { DotsThreeVertical, Television, Export, ArrowLeft } from "@phosphor-icons/react";
import Cookies from "js-cookie";
import { useParams, useHistory } from "react-router-dom";

import './TV.scss';
import { RWebShare } from "react-web-share";
import axiosInstance from "../../store/axios";


const TVDetailVideos = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({})
    const history = useHistory();
    const params = useParams();
    const playlist_id = params['playlist_id']
    const epd_id = params['epd_id']

    const items_video = (item) => {
        return [

            {
                // label: (
                //     <span className="fs12 fw400"> Share</span>
                // ),
                key: '1',
                icon: <RWebShare
                    data={{
                        text: "Syllo TV",
                        url: item?.ep_type === "YT" ? `${process.env.REACT_APP_URL}tv/${playlist_id}/play/${item?.epd_id}` : item?.url,
                        title: item?.title,
                    }}

                >
                    <span><Export size={18} color="#000000" weight="thin" className="me-2" /> Share</span>
                </RWebShare>
            }
        ]
    };


    const videoMenuClick = ({ key, id }) => {
        if (key === '1') {

        }
    };

    useEffect(() => {
        TvData();

    }, [])
    function TvData() {
        setLoading(true)
        axiosInstance({
            method: 'get',
            url: `v1.0/tv/playlist/${playlist_id}/episode?epd_id=${epd_id}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setData(data)

            // setData(data?.library)
            // setExtraData(data)
            setLoading(false)
        })

    }

    const openURL = (item, playlist_id) => {

        if (item?.ep_type === "URL") {
            window.open(item?.url)

        } else
            history.push(`/tv/${playlist_id}/play/${item?.epd_id}`)
        TvData()
    }


    return (
        <Row>
            <Col lg={{span:18, offset:3}} md={{span:18, offset:3}} xs={{span:24, offset:0}} className='mb-4'>
                <Row >
                    <Col lg={{span:16, offset:4}} md={{span:16, offset:4}} xs={{span:24, offset:0}} >
                        <Row className="md40">
                            <Col span={3} className="md10">
                                <Space className="cursor" onClick={() => history.goBack()}>
                                    <ArrowLeft size={18} color="#000000" weight="light" className="ms-1" style={{ webkitBoxAlign: 'baseline' }} />
                                    <p className="fs14 fw400"> Back </p>
                                </Space>
                            </Col>
                            <Col span={18} className="text-center" >

                                <Space  >
                                    {
                                        loading ? <Skeleton.Input active /> :
                                            <><Television size={25} weight="light" color="#000000" />
                                                <span className="fs24 fw800" style={{ color: data?.tv?.color }}> {data?.tv?.name} </span>
                                                <span className="fs24 fw800 blue-text"> TV </span></>
                                    }

                                </Space>
                            </Col>
                            <Col span={3} ></Col>
                        </Row>

                    </Col>

                    <Col lg={{span:16, offset:4}} md={{span:16, offset:4}} xs={{span:24, offset:0}}  className=' md20'>
                        <Row className='md25 video-card'>
                            {
                                loading ? <Col span={24}><Skeleton active /> <Skeleton active /><Skeleton active /> </Col> :

                                    <Col span={24}>
                                        <iframe height="398" width={"100%"} src={`https://www.youtube.com/embed/${data?.playing?.videoId}`}
                                            title="YouTube video player" frameborder="1" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            accelerometer="autoplay"></iframe>

                                        {/* <img src={require('../../assets/images/TvImages/videoimg.png')} className='video-img' /> */}
                                    </Col>
                            }
                            <Col span={24} className=' md30 '>
                                <Row>
                                    <Col span={20}>
                                        {
                                            loading ? <Col span={24}><Space aria-orientation="vertical"><Skeleton.Input active />  <Skeleton.Button size="small" active /></Space></Col>:<>
                                                <p className="fs18 fw600 md10"> {data?.playing?.title}</p>
                                                <p className="fs12 fw300 md5"> {data?.tv?.name} TV |  {data?.playing?.playlist_title}</p>
                                            </>}
                                    </Col>
                                    <Col span={4} className="text-right md10">
                                        <RWebShare

                                            data={{
                                                text: data?.playing?.title,
                                                url: window.location.href,
                                                title: data?.tv?.name + " TV",
                                            }}

                                        >
                                            <Button type="ghost" size="small" className=" " icon={<Export size={16} style={{marginRight:"5px"}} />} >
                                                {" "} Share
                                            </Button>
                                        </RWebShare>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className=' md20' >
                                        <p className="fs16 fw500 md10"> More videos from Zerodha</p>
                                    </Col>
                                    <Col span={24} className=' mt-0' >
                                        <Divider className="my-2" />
                                    </Col>
                                </Row>
                                {
                                    loading ? <><Skeleton active /> <Skeleton active /> </> :
                                        <Row className='mb-5'>
                                            {
                                                data && data?.videos?.map((item, i) => {
                                                    return <Col xs={{span:24, offset:0}} lg={{span:22, offset:1}} key={i} >
                                                        <Row className='md15'>
                                                            <Col lg={4} xs={7} className="cursor" onClick={() => openURL(item, playlist_id)}>
                                                                <Avatar src={item?.image_url} size={80} style={{ borderRadius: '4px' }} />
                                                            </Col>
                                                            <Col lg={18} xs={15} className="cursor" onClick={() => openURL(item, playlist_id)}>
                                                                <p className="fs14">{item.title}</p>
                                                                <p className="fs12 fw300 md10 text-grey"> {item?.ep_type}</p>

                                                            </Col>
                                                            <Col span={2} className='text-right'>

                                                                <Dropdown trigger={['click']} menu={{ items: items_video(item), onClick: ({ key, }) => videoMenuClick({ key: key, id: "item?.playlist_id" }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                                    <Button type="text" shape="circle" onClick={(e) => e.preventDefault()}
                                                                        icon={<DotsThreeVertical size={18} color="#696969" />}></Button>
                                                                </Dropdown>

                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                })


                                            }
                                        </Row>
                                }



                            </Col>
                        </Row>








                    </Col>

                </Row>
            </Col>




        </Row>
    );
}
export default TVDetailVideos;