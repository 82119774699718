import { Button, } from "antd";
import {
    Bank, ChartBar, ChartLineUp, ChartPieSlice, Coins, CoinVertical, CurrencyCircleDollar, FilmSlate, GlobeHemisphereEast, Heart, Infinity, Kanban, Money,
    MusicNote, NumberCircleEight, NumberCircleFive, NumberCircleFour, NumberCircleNine, NumberCircleOne,
    NumberCircleSeven, NumberCircleSix, NumberCircleThree, NumberCircleTwo, NumberCircleZero, RocketLaunch, ShieldPlus, SmileySticker, TreeStructure, TrendUp, Vault
}
    from "@phosphor-icons/react";
import React from "react";

const IconsList = (props) => {
    function senValue(value) {
        props?.closeModal(false)
        props.setIcon(value)
    }
    return (
        <div>
            {/* <Space> */}
            <Button type="dashed" onClick={() => senValue('Heart')} className="border md10" icon={<Heart size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('Coins')} className="border md10" icon={<Coins size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('FilmSlate')} className="border md10" icon={<FilmSlate size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('MusicNote')} className="border md10" icon={<MusicNote size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('Money')} className="border md10" icon={<Money size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('Bank')} className="border md10" icon={<Bank size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('ChartBar')} className="border md10" icon={<ChartBar size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('ChartLineUp')} className="border md10" icon={<ChartLineUp size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('ChartPieSlice')} className="border md10" icon={<ChartPieSlice size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleZero')} className="border md10" icon={<NumberCircleZero size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleOne')} className="border md10" icon={<NumberCircleOne size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleTwo')} className="border md10" icon={<NumberCircleTwo size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleThree')} className="border md10" icon={<NumberCircleThree size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleFour')} className="border md10" icon={<NumberCircleFour size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleFive')} className="border md10" icon={<NumberCircleFive size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleSix')} className="border md10" icon={<NumberCircleSix size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleSeven')} className="border md10" icon={<NumberCircleSeven size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleEight')} className="border md10" icon={<NumberCircleEight size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('NumberCircleNine')} className="border md10" icon={<NumberCircleNine size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('TrendUp')} className="border md10" icon={<TrendUp size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('Infinity')} className="border md10" icon={<Infinity size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('Vault')} className="border md10" icon={<Vault size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('CurrencyCircleDollar')} className="border md10" icon={<CurrencyCircleDollar size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('RocketLaunch')} className="border md10" icon={<RocketLaunch size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('Kanban')} className="border md10" icon={<Kanban size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('SmileySticker')} className="border md10" icon={<SmileySticker size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('ShieldPlus')} className="border md10" icon={<ShieldPlus size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('TreeStructure')} className="border md10" icon={<TreeStructure size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('GlobeHemisphereEast')} className="border md10" icon={<GlobeHemisphereEast size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('Coins')} className="border md10" icon={<Coins size={28} color="#000" weight="light" />}></Button>
            <Button type="dashed" onClick={() => senValue('CoinVertical')} className="border md10" icon={<CoinVertical size={28} color="#000" weight="light" />}></Button>
            {/* </Space> */}
        </div>

    );
}
export default IconsList;
