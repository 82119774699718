import { createAction } from "redux-actions";
import axios from "../axios";
import Cookies from "js-cookie"

const CORPORATE_RESULTS = createAction("CORPORATE_RESULTS");
export const CorporateResults = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "calendar/schedule?exch=&name=&symbol=&time="+values.time +"&date=",
        headers: {
            Authorization:  Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(CORPORATE_RESULTS(res.data.data));
    })
};


export const CorporateResultsTop = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "/calendar/event/upcoming?type="+values.type,
        headers: {
            Authorization:  Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(CORPORATE_RESULTS(res.data.data));
    })
};


const EVENT_CREATED = createAction("EVENT_CREATED");
export const createEvent = (values) => async (dispatch) => {
    return await axios({
        method: 'post',
        url: "calendar/schedule/event",
        data : values,
        headers: {
            Authorization:  Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(EVENT_CREATED(res.data.data));
    })
};


const NEWS_FEED = createAction("NEWS_FEED");
export const NewsFeed = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "news/feed?topic=" + values.topic,
        headers: {
            Authorization:  Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(NEWS_FEED(res.data.data));
    })
};



const RELATED_NEWS_FEED = createAction("RELATED_NEWS_FEED");
export const relatedTopics = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "market/feed/related?entity_type="+values.type+"&id=" + values.id,
        headers: {
            Authorization:  Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(RELATED_NEWS_FEED(res.data.data));
    })
};



const INDICES = createAction("INDICES");
export const Indices = () => dispatch => {
    return axios({
        method: 'get',
        url: "market/indices",
        headers: {
            Authorization:  Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(INDICES(res.data.data));
    })
};