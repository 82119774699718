import React, { useEffect } from "react";
import { Row, Col,  Skeleton,  Typography,Tag  } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { useState } from "react";
import useToken from "../../store/useToken"
const { CheckableTag } = Tag;
const { Paragraph } = Typography;

const NewTrending = ({ trending, getFilterByTags, Filtertags, newsloader,selectedTags }) => {
  
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [offset, setOffset] = useState(0);
  const { token } = useToken();


  useEffect(() => {
    // getRecommended();
    setLoading(false);
  }, [])



  // const getRecommended = async () => {
  //   setLoadingBtn(true);

  //   await axios({
  //     method: 'get',
  //     url:  'market/feed/recommended?count=4&offset=' + offset,
  //     headers: {
  //       // Authorizatios: Cookies.get("Logintoken"),

  //     },
  //   }).then(function (response) {
  //     setArticles(response.data.data)
  //   });
  //   setOffset(offset + 4);
  //   setLoadingBtn(false)
  // }


  // function openLink(record) {
  //   if (record?.entity_type === undefined) {
  //     window.open(record?.link);
  //     if (token) {
  //       axios({
  //         method: 'patch',
  //         url: process.env.REACT_APP_PUBLIC_API_BASE_URL + 'account/user/preferences?id=' + record?.entity_id + '&entity_type=News',
  //         headers: {
  //           Authorization: Cookies.get("Logintoken")
  //         }
  //       })
  //     }

  //   }
  //   else if (record?.entity_type === 10) {
  //     window.open("/note/" + record.entity_uuid)
  //   } else {
  //     window.open("/feed-view/" + record?.entity_uuid)
  //   }
  // }


  return (
    <Row>
      {
        newsloader ? <Skeleton></Skeleton> :

          <Col span={24}>
            <p className="fs14 fw600  md30"> Trending </p>
            <Row className="trending-card md10">
              <Col span={24} className="md10 new-dashb-tag dashboard-tag-scroll">
                <Paragraph ellipsis={{ rows: 6, expandable: true, symbol: "see more" }}>{
                  trending.map((item) => {
                    return <CheckableTag key={item}  checked={selectedTags.includes(item)} className={"cursor"}   onChange={(checked) => getFilterByTags(item, "", "",checked)}
                    ><b>{item}</b></CheckableTag>

                  })

                } </Paragraph>


              </Col>

            </Row>
          </Col>
      }
      {/* <Col span={24} className="md20 trending-card">
        {
          loading ? <Skeleton active></Skeleton> : articles && articles[0] &&
            <Row gutter={10} >
              <Col xxl={24} md={24} xs={24}>

                <Paragraph onClick={() => openLink(articles[0])} className="fs16 fw600 text-black cursor" ellipsis={{ rows: "4", tooltip: articles[0]?._title }}>{articles[0]?._title}
                </Paragraph>
                <Space className="md10">

                  <p className="fs11 fw400 " >{articles[0]?.fname} {articles[0]?.lname}</p>
                </Space>
              </Col>


            </Row>
        }
        <Divider/>
        <Row >
          {
            loading ? <Skeleton active></Skeleton> : articles && articles[1] &&
              <Col span={24} >

                <Paragraph onClick={() => openLink(articles[1])} className="fs16 fw600 text-black cursor" ellipsis={{ rows: "4", tooltip: articles[1]?._title }}>{articles[1]?._title}
                </Paragraph>
                <Space className="md10">

                  <p className="fs11 fw400 " >{articles[1]?.fname} {articles[1]?.lname}</p>
                </Space>
              </Col>
          }
        </Row>
        <Divider/>
        <Row >
          {
            loading ? <Skeleton active></Skeleton> : articles && articles[2] &&
              <Col span={24} >
                <Paragraph onClick={() => openLink(articles[2])} className="fs16 fw600 text-black cursor" ellipsis={{ rows: "4", tooltip: articles[2]?._title }}>{articles[2]?._title}
                </Paragraph>
                <Space className="md10">

                  <p className="fs11 fw400 " >{articles[2]?.fname} {articles[2]?.lname}</p>
                </Space>
              </Col>
          }
        </Row>
        <Divider/>

        {
          loading ? <Skeleton active></Skeleton> : articles && articles[3] &&
            <Row gutter={10} className={""}>
              <Col xxl={24} md={24} xs={24}>

                <Paragraph onClick={() => openLink(articles[3])} className="fs16 fw600 text-black cursor" ellipsis={{ rows: "4", tooltip: articles[3]?._title }}>{articles[3]?._title}
                </Paragraph>
                <Space className="md10">

                  <p className="fs11 fw400 " >{articles[3]?.fname} {articles[3]?.lname}</p>
                </Space>
              </Col>

              
            </Row>
        }
        <div className="text-center md30 mb-4">
          <Button type="primary" loading={loadingBtn} icon={<Shuffle size={18}/>} onClick={() => getRecommended()}>
            
          </Button>
        </div>
      </Col>
    */}
    </Row>
  );
}


export default NewTrending;
