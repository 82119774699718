import React, { useEffect, useState } from "react";
import { Col, Row, Divider, Select, Radio, Space, Skeleton, Tooltip } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import moment from "moment";
import { Info } from "@phosphor-icons/react";

const { Option } = Select;



const PostProfile = () => {


    const [insights, setinsights] = useState([])

    const [range, setrange] = useState("last7Days")

    const [active, setActive] = useState("3")
    const [loader, setloader] = useState(true)
    const [toppost, settoppost] = useState([])

    useEffect(() => {
        getRangeInsights(range)
    }, [])


    const getRangeInsights = (evt) => {
        setrange(evt)
        setloader(true)
        axios({
            method: 'get',
            url:  "v1.0/analytics/insights/post?range=" + evt,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            const data = res.data.data
            setinsights(data)
            setloader(false)
        })
        getTopEvents(active)
    }

    const getTopEvents = (evt) => {
        setActive(evt)
        axios({
            method: 'get',
            url:  "v1.0/analytics/insights/post/top?entity=" + evt + "&range=" + range,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            const data = res.data.data
            settoppost(data?.top5)
            setloader(false)
        })
    }


    return (
        <>
            <Row style={{ padding: "40px 0px 30px 30px" }} className="m-nopadding">
                <Col span={24}>
                    <p className="m-0 fs18">Post Insights</p>
                    <Row>
                        <Col xs={{ span: 12 }} lg={{ span: 18 }}>
                            <p className="text-grey">Feed Insights Overview</p>
                        </Col>
                        <Col xs={{ span: 12 }} lg={{ span: 6 }} className=" ">
                            <Select value={range} onChange={(evt) => getRangeInsights(evt)} className="profile-select m-ml25 iphone5Se-ml11" style={{ width: 130 }} >
                                <Option value="last7Days">Last 7 Days</Option>
                                <Option value="last14Days">Last 14 Days</Option>
                                <Option value="last30Days">Last 30 Days</Option>
                                <Option value={"last90Days"}>Last 90 Days</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Divider></Divider>
                </Col>



                {
                    loader ? <Skeleton></Skeleton> : <>

                        <Col span={24} className="md30">
                            {/* <p className="fs12 fw300">You gained <span className="fs12 fw500" style={{ color: '#1DB954' }}>2k</span> more views compared to Jun 23 - July 22  </p> */}
                            <Space>
                                <p className="fs16 fw500 "> Feed Interactions </p>
                                <Tooltip title=" Lorem Ipsum, giving information on its origins,">
                                     <Info size={20} color="#BDC2CC" weight="light" />
                                </Tooltip>                          



                            </Space>

                        </Col>
                        <Col span={24} className='text-center md45 '>
                            <h4 className="fs18 fw500 m-0">{insights?.feed_views}</h4>
                            <span className="fs12 fw300 m-0">
                                {
                                    insights?.feed_views_perc < 0 ? <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.24089 0.90625C3.09214 0.90625 2.94334 0.9639 2.82959 1.08057L0.787924 3.12223L1.61621 3.95052L2.66667 2.90462V8.50098H3.83333V2.91829L4.85986 3.94482L5.68815 3.12223L3.65218 1.08057C3.53843 0.9639 3.38964 0.90625 3.24089 0.90625H3.24089ZM6.74089 3.23389C6.59214 3.23389 6.44334 3.29154 6.32959 3.4082L4.28792 5.45557L5.11621 6.28385L6.16667 5.23796V8.50098H7.33333V5.24023L8.36556 6.27246L9.19385 5.44987L7.15218 3.4082C7.03843 3.29154 6.88964 3.23389 6.74089 3.23389Z" fill="#1DB954" /></svg> : <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75912 8.09375C6.90787 8.09375 7.05667 8.0361 7.17042 7.91943L9.21208 5.87777L8.38379 5.04948L7.33334 6.09538L7.33334 0.499023H6.16667L6.16667 6.08171L5.14014 5.05518L4.31185 5.87777L6.34782 7.91943C6.46157 8.0361 6.61037 8.09375 6.75912 8.09375H6.75912ZM3.25912 5.76611C3.40787 5.76611 3.55667 5.70846 3.67042 5.5918L5.71208 3.54443L4.88379 2.71615L3.83334 3.76204V0.499023H2.66667V3.75977L1.63445 2.72754L0.806158 3.55013L2.84782 5.5918C2.96157 5.70846 3.11037 5.76611 3.25912 5.76611Z" fill="#FF1712" />
                                    </svg>

                                }
                                {parseFloat(insights?.feed_views_perc).toFixed(0)}%</span>
                            <p className="fs12 fw300">{moment(insights?.date_2).format("Do MMMM YYYY")} - {moment(insights?.date_1).format("Do MMMM YYYY")}</p>
                            <p className="fs14 fw300">Total Feed views</p>

                        </Col>
                        <Col span={24}  className="text-center">
                            <Row  className='md35 '>
                                <Col xs={{ span: 24 }} lg={{ span: 22, offset: 1 }}>
                                    <Space size={42} orientation="horizontal ">
                                        <Radio.Group >
                                            <Radio value={"1"} className="profile-post-card   text-center justify-center ">
                                                <svg width="30" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.6328 4.64689C20.3187 3.91948 19.8657 3.2603 19.2992 2.70627C18.7323 2.15058 18.064 1.70898 17.3305 1.40549C16.5699 1.08953 15.7541 0.927808 14.9305 0.929704C13.775 0.929704 12.6477 1.24611 11.668 1.84377C11.4336 1.98674 11.2109 2.14377 11 2.31486C10.7891 2.14377 10.5664 1.98674 10.332 1.84377C9.35234 1.24611 8.225 0.929704 7.06953 0.929704C6.2375 0.929704 5.43125 1.08908 4.66953 1.40549C3.93359 1.71017 3.27031 2.14845 2.70078 2.70627C2.13359 3.25968 1.6805 3.91901 1.36719 4.64689C1.04141 5.40392 0.875 6.20783 0.875 7.03517C0.875 7.81564 1.03438 8.62892 1.35078 9.45627C1.61563 10.1477 1.99531 10.8649 2.48047 11.5891C3.24922 12.7352 4.30625 13.9305 5.61875 15.1422C7.79375 17.1508 9.94766 18.5383 10.0391 18.5945L10.5945 18.9508C10.8406 19.1078 11.157 19.1078 11.4031 18.9508L11.9586 18.5945C12.05 18.536 14.2016 17.1508 16.3789 15.1422C17.6914 13.9305 18.7484 12.7352 19.5172 11.5891C20.0023 10.8649 20.3844 10.1477 20.6469 9.45627C20.9633 8.62892 21.1227 7.81564 21.1227 7.03517C21.125 6.20783 20.9586 5.40392 20.6328 4.64689V4.64689Z" fill="black" fill-opacity="0.6" />
                                                </svg>
                                                {/* <Avatar shape="square" size={35} src={require('../../assets/images/Category/workshops.svg').default} alt="" /> */}
                                                <p className="fs18 fw500  md5"> {insights?.interections?.likes}</p>

                                                <span className="fs12 fw300 m-0">
                                                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.24089 0.90625C3.09214 0.90625 2.94334 0.9639 2.82959 1.08057L0.787924 3.12223L1.61621 3.95052L2.66667 2.90462V8.50098H3.83333V2.91829L4.85986 3.94482L5.68815 3.12223L3.65218 1.08057C3.53843 0.9639 3.38964 0.90625 3.24089 0.90625H3.24089ZM6.74089 3.23389C6.59214 3.23389 6.44334 3.29154 6.32959 3.4082L4.28792 5.45557L5.11621 6.28385L6.16667 5.23796V8.50098H7.33333V5.24023L8.36556 6.27246L9.19385 5.44987L7.15218 3.4082C7.03843 3.29154 6.88964 3.23389 6.74089 3.23389Z" fill="#1DB954" />
                                                    </svg>

                                                    {parseFloat(insights?.interections_per?.likes).toFixed(0)}%
                                                </span>

                                            </Radio>
                                            <Radio value={"2"} className="profile-post-card   m-md10 text-center justify-center ">
                                                <svg width="25" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.9531 6.08503C18.8257 4.53816 17.2507 3.50456 15.5234 3.03816V3.0405C15.1226 2.59519 14.6702 2.18503 14.164 1.81941C10.3273 -0.969655 4.94134 -0.118873 2.14056 3.71785C-0.116475 6.83503 -0.020381 11.028 2.28118 14.0069L2.29993 17.1147C2.29993 17.1897 2.31165 17.2647 2.33509 17.335C2.45931 17.7311 2.88118 17.9491 3.27493 17.8249L6.24212 16.8897C7.02728 17.1686 7.83821 17.328 8.64446 17.3725L8.63274 17.3819C10.721 18.903 13.4585 19.36 15.9687 18.5303L18.9476 19.5007C19.0226 19.5241 19.0999 19.5382 19.1796 19.5382C19.5945 19.5382 19.9296 19.203 19.9296 18.7882V15.6475C21.9945 12.8444 22.0484 8.97019 19.9531 6.08503ZM6.57024 15.2257L6.28899 15.1085L3.96868 15.835L3.94524 13.3975L3.75774 13.1866C1.77493 10.7678 1.64368 7.28269 3.49993 4.72566C5.75931 1.62722 10.0906 0.942845 13.1796 3.17878C16.2781 5.43113 16.9648 9.75535 14.7265 12.835C12.8491 15.4108 9.48821 16.3624 6.57024 15.2257ZM18.4062 14.8272L18.2187 15.0616L18.2421 17.4991L15.9452 16.7257L15.664 16.8428C14.3515 17.3303 12.9523 17.3702 11.6562 17.0069L11.6515 17.0046C13.3835 16.4725 14.9538 15.3944 16.0859 13.8428C17.8765 11.3749 18.1671 8.2741 17.1265 5.63035L17.1406 5.63972C17.6796 6.02644 18.1742 6.50925 18.5937 7.09285C20.2952 9.42722 20.1992 12.6053 18.4062 14.8272Z" fill="black" fill-opacity="0.6" />
                                                </svg>

                                                {/* <Avatar shape="square" size={35} src={require('../../assets/images/Category/workshops.svg').default} alt="" /> */}
                                                <p className="fs18 fw500  md5">  {insights?.interections?.saved}</p>
                                                <span className="fs12 fw300 m-0"><svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.24089 0.90625C3.09214 0.90625 2.94334 0.9639 2.82959 1.08057L0.787924 3.12223L1.61621 3.95052L2.66667 2.90462V8.50098H3.83333V2.91829L4.85986 3.94482L5.68815 3.12223L3.65218 1.08057C3.53843 0.9639 3.38964 0.90625 3.24089 0.90625H3.24089ZM6.74089 3.23389C6.59214 3.23389 6.44334 3.29154 6.32959 3.4082L4.28792 5.45557L5.11621 6.28385L6.16667 5.23796V8.50098H7.33333V5.24023L8.36556 6.27246L9.19385 5.44987L7.15218 3.4082C7.03843 3.29154 6.88964 3.23389 6.74089 3.23389Z" fill="#1DB954" />
                                                </svg>
                                                    {parseFloat(insights?.interections_per?.saved).toFixed(0)}%
                                                </span>
                                            </Radio>
                                            <Radio value={"3"} className="profile-post-card m-md10  m-top10 text-center justify-center ">
                                                <svg width="22" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.625 13.5625C13.957 13.5625 13.3406 13.7969 12.8578 14.1883L8.00156 10.675C8.08288 10.2287 8.08288 9.77133 8.00156 9.325L12.8578 5.81172C13.3406 6.20312 13.957 6.4375 14.625 6.4375C16.1766 6.4375 17.4375 5.17656 17.4375 3.625C17.4375 2.07344 16.1766 0.8125 14.625 0.8125C13.0734 0.8125 11.8125 2.07344 11.8125 3.625C11.8125 3.89687 11.85 4.15703 11.9227 4.40547L7.31016 7.74531C6.62578 6.83828 5.53828 6.25 4.3125 6.25C2.24062 6.25 0.5625 7.92812 0.5625 10C0.5625 12.0719 2.24062 13.75 4.3125 13.75C5.53828 13.75 6.62578 13.1617 7.31016 12.2547L11.9227 15.5945C11.85 15.843 11.8125 16.1055 11.8125 16.375C11.8125 17.9266 13.0734 19.1875 14.625 19.1875C16.1766 19.1875 17.4375 17.9266 17.4375 16.375C17.4375 14.8234 16.1766 13.5625 14.625 13.5625ZM14.625 2.40625C15.2977 2.40625 15.8438 2.95234 15.8438 3.625C15.8438 4.29766 15.2977 4.84375 14.625 4.84375C13.9523 4.84375 13.4062 4.29766 13.4062 3.625C13.4062 2.95234 13.9523 2.40625 14.625 2.40625ZM4.3125 12.0625C3.17578 12.0625 2.25 11.1367 2.25 10C2.25 8.86328 3.17578 7.9375 4.3125 7.9375C5.44922 7.9375 6.375 8.86328 6.375 10C6.375 11.1367 5.44922 12.0625 4.3125 12.0625ZM14.625 17.5938C13.9523 17.5938 13.4062 17.0477 13.4062 16.375C13.4062 15.7023 13.9523 15.1562 14.625 15.1562C15.2977 15.1562 15.8438 15.7023 15.8438 16.375C15.8438 17.0477 15.2977 17.5938 14.625 17.5938Z" fill="black" fill-opacity="0.6" />
                                                </svg>

                                                {/* <Avatar shape="square" size={35} src={require('../../assets/images/Category/workshops.svg').default} alt="" /> */}
                                                <p className="fs18 fw500  md5"> {insights?.interections?.shared} </p>
                                                <span className="fs12 fw300 m-0"><svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.75912 8.09375C6.90787 8.09375 7.05667 8.0361 7.17042 7.91943L9.21208 5.87777L8.38379 5.04948L7.33334 6.09538L7.33334 0.499023H6.16667L6.16667 6.08171L5.14014 5.05518L4.31185 5.87777L6.34782 7.91943C6.46157 8.0361 6.61037 8.09375 6.75912 8.09375H6.75912ZM3.25912 5.76611C3.40787 5.76611 3.55667 5.70846 3.67042 5.5918L5.71208 3.54443L4.88379 2.71615L3.83334 3.76204V0.499023H2.66667V3.75977L1.63445 2.72754L0.806158 3.55013L2.84782 5.5918C2.96157 5.70846 3.11037 5.76611 3.25912 5.76611Z" fill="#FF1712" />
                                                </svg>
                                                    {parseFloat(insights?.interections_per?.shared).toFixed(0)}%
                                                </span>
                                            </Radio>
                                        </Radio.Group>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 24 }} className='md30 radio-text'>
                            <Radio.Group value={active} onChange={(evt) => getTopEvents(evt.target.value)}>
                                <Radio value={"3"} className='fw300'>Post</Radio>
                                <Radio value={"2"}>Newsletter</Radio>
                                <Radio value={"7"}>Podcast</Radio>
                                <Radio value={"4"} className='md15'>Report</Radio>
                            </Radio.Group>
                        </Col>

                        <Col span={24} className='md30'>
                            <Row >
                                <Col span={24}>
                                    <p className="fs14 fw500 md15  "> Top 5 </p>
                                    <p className="fs12 fw300   "> Based on reach {moment(insights?.date_2).format("Do MMMM YYYY")} - {moment(insights?.date_1).format("Do MMMM YYYY")} </p>
                                </Col>

                                {
                                    toppost.map((item) => {
                                        return <Col span={24} className='md40 '>
                                            <p className="fs14 fw400 ">{item?.name}</p>
                                            <p className="fs18 fw500 "> {item?.count}
                                                <span className="fs12 fw300 ms-2 me-2"> <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.24105 0.90625C3.0923 0.90625 2.9435 0.9639 2.82975 1.08057L0.788086 3.12223L1.61637 3.95052L2.66683 2.90462V8.50098H3.8335V2.91829L4.86003 3.94482L5.68831 3.12223L3.65234 1.08057C3.53859 0.9639 3.3898 0.90625 3.24105 0.90625H3.24105ZM6.74105 3.23389C6.5923 3.23389 6.4435 3.29154 6.32975 3.4082L4.28809 5.45557L5.11637 6.28385L6.16683 5.23796V8.50098H7.3335V5.24023L8.36572 6.27246L9.19401 5.44987L7.15235 3.4082C7.0386 3.29154 6.8898 3.23389 6.74105 3.23389Z" fill="#1DB954" />
                                                </svg>
                                                    {item?.perc}%</span>
                                            </p>
                                        </Col>
                                    })
                                }


                            </Row>
                        </Col>


                    </>
                }


            </Row>

        </>
    );
}
export default PostProfile;
