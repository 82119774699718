import React, { useState } from "react";
import { Col, Row, Input, Button, Divider, Alert, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";

import Toast from "../utils/Toast";
const ChangePassword = (props) => {
    // const [visible, setCurrent] = useState('1');

    const [current_pswd, setcurrent_pswd] = useState("");
    const [new_pswd, setnew_pswd] = useState("");
    const [conf_pswd, setconf_pswd] = useState([]);



    const onChangePassword = (evt) => {
        evt.preventDefault();

        if (current_pswd === "") {
            Toast("Enter Current Password","error")
            return
        }

        if (new_pswd === "") {
            Toast("Enter New Password","error")
            return
        }

        if (new_pswd.length < 6) {
            Toast("Enter  Password more than 6 Digits","error")
            return
        }

        if (new_pswd !== conf_pswd) {
            Toast("New Password and confirm passwrd didn't match !!","error")
            return
        }

        props.changePassword(
            {
                p: current_pswd,
                new_p: conf_pswd
            }
        ).then(() => {
            setcurrent_pswd("")
            setnew_pswd("")
            setconf_pswd("")
        })
    }


    return (

        <Row style={{ padding: "40px 0px 0px 30px" }} className="m-nopadding">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <p className="m-0 fs18">Change Password</p>
                <Row>
                    <Col span={18}>
                        <p className="text-grey">Manage Account Information</p>
                    </Col>

                </Row>

                <Divider></Divider>

            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} >
                <p style={{ color: "#484848" }} className="sub-text-light">Current Password</p>
                <Input.Password value={current_pswd} onChange={(evt) => setcurrent_pswd(evt.target.value)} type={'password'} className="md10" placeholder="Enter current password"></Input.Password>
                <p style={{ color: "#484848" }} className="sub-text-light md20">New Password</p>
                <Input.Password value={new_pswd} onChange={(evt) => setnew_pswd(evt.target.value)} type={'password'} className="md10" placeholder="Enter new password"></Input.Password>
                <p style={{ color: "#484848" }} className="sub-text-light md20">Confirm Password</p>
                <Input.Password type={'password'} value={conf_pswd} onChange={(evt) => setconf_pswd(evt.target.value)} className="md10" placeholder="Confirm new password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}></Input.Password>


            </Col>
            <Col span={24} className="md30">
                <Alert icon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1.80078C5.02266 1.80078 1.8 5.02344 1.8 9.00078C1.8 12.9781 5.02266 16.2008 9 16.2008C12.9773 16.2008 16.2 12.9781 16.2 9.00078C16.2 5.02344 12.9773 1.80078 9 1.80078ZM9.6 12.6008H8.4V8.40078H9.6V12.6008ZM9 6.90078C8.50313 6.90078 8.1 6.49766 8.1 6.00078C8.1 5.50391 8.50313 5.10078 9 5.10078C9.49688 5.10078 9.9 5.50391 9.9 6.00078C9.9 6.49766 9.49688 6.90078 9 6.90078Z" fill="#146EB4" />
                </svg>
                } message="Your password should be at least 10 characters long and use a mix of uppercase, lowercase, numbers, and symbols. 
Use passphrases, not passwords. Do not use common dictionary words or phrases - these are predictable and easy to compromise." type="info" showIcon />
            </Col>

            <Col span={24} className="md30 text-right m-mb20 mb-3">
                <Button type="primary" onClick={(evt) => onChangePassword(evt)}>Save Changes</Button>

            </Col>
        </Row>

    );
}

const mapStateToProps = () => ({


});
const mapDispatchToProps = (dispatch) => ({
    changePassword: (v) => dispatch(actions.changePassword(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);


