import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    allnewsletter:[],
    allclients:[],
    sentclients:[],
    newsdetails:[],
    newsletterdetail:[],
    all_market:[],
    all_comments:[],
    tags:[],
    new_data:[],
    id:[],
    saved_data:[],
    my_feed_counts:[],
    summary:[],
    all_news:[]
}
const NewsLetterReducer = handleActions({
    ALL_NEWSLETTER: (state, action) => {
        return { ...state, allnewsletter: action.payload };
    },
    ALL_CLIENTS: (state, action) => {
        return { ...state, allclients: action.payload };
    },SENT_CLIENTS: (state, action) => {
        return { ...state, sentclients: action.payload };
    },
    NEWSLETTER_DETAIL: (state, action) => {
        return { ...state, newsdetails: action.payload };
    },
    NEWSLETTER_DETAILS: (state, action) => {
        return { ...state, newsletterdetail: action.payload };
    },
    ALL_MARKET: (state, action) => {
        return { ...state, all_market: action.payload };
    },
    FEED_COMMENTS :  (state, action) => {
        return { ...state, all_comments: action.payload };
    },
    CONTENT_TAGS:  (state, action) => {
        return { ...state, tags: action.payload };
    },
    NEW_DATA:  (state, action) => {
        return { ...state, new_data: action.payload };
    },
    NEWSLETTER_NEW:  (state, action) => {
        return { ...state, id: action.payload };
    },
    SAVED_FEED:  (state, action) => {
        return { ...state, saved_data: action.payload };
    },MYHUB_FEED_COUNT:  (state, action) => {
        return { ...state, my_feed_counts: action.payload };
    },
    NEWSLETTER_SUMMARY:  (state, action) => {
        return { ...state, summary: action.payload };
    },
    ALL_MARKET_NEWS:(state, action) => {
        return { ...state, all_news: action.payload };
    },
}, INITIAL_STATE);

export default NewsLetterReducer;