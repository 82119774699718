import React from "react";
import { Typography, Row, Col, Tag, Avatar, Badge, Space, Tooltip } from 'antd';
import { useHistory } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { StarFour } from "@phosphor-icons/react";

const { Title } = Typography;

const EntityHeader = (props) => {
    const history = useHistory();
    const [item, setItem] = useState(props?.item);
    const [innerWidth,] = useState(window.innerWidth);


    return (
        <div className={'text-space-wb'}>
            <span className="post-top-text">
                <Title level={5} onClick={() => history.push("/profile/" + item?.user_code)}>{item?.name}</Title>
                <Space size={5}>
                    <p className=" fs12 m-fs12" style={{ color: "#728096" }}> {item?.username && `@${item?.username}`}</p>
                    {
                        innerWidth <= 912 && <Badge status="default"></Badge>
                    }
                    {
                        innerWidth <= 912 && <p className="fs12 m-fs9" style={{ color: "#728096" }}> {moment(item?.published_at).fromNow(true)}</p>
                    }

                </Space>

            </span>

            {item?.entity_type !== "News" &&
                <span>
                    {
                        item?.price > 0 && <Tooltip title={`${parseFloat(item?.price).toFixed(0)} coins`}><StarFour size={20} color="#ffcf24" weight="fill" style={{ marginRight: "5px" }} /></Tooltip>
                    }

                    <Tag color={item?.entity_type === "Poll" ? "cyan" :
                        item?.entity_type === "Notes" ? "magenta" :
                            item?.entity_type === "Post" ? "purple" :
                                item?.entity_type === "Newsletter" ? "blue" :
                                    item?.entity_type === "Report" ? "geekblue" : "green"} style={{ float: "right", paddingRight: '12px' }}>{item?.entity_type}</Tag>
                </span>
            }

        </div>
    );
}

export default EntityHeader;




