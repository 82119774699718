import React, { useEffect } from 'react'
import RtmClient from './rtm-client'
import { Col, Row, Avatar, Button, Input, Space } from "antd";
import {

    blobToImage
} from './common'
import {
    AudioOutlined,
} from '@ant-design/icons';
import ScrollableFeed from 'react-scrollable-feed'
import { useState } from 'react'
import { PaperPlaneRight } from "@phosphor-icons/react";

import Toast from '../../utils/Toast';
import { useHistory } from 'react-router-dom';
import moment from 'moment';


function GoliveChat({ data }) {

    const [messages, setMessages] = useState([])
    const [showChat, setShowChat] = useState(true)
    const [text_msg, setText_msg] = useState("")
    const [channel_name, setChannel_name] = useState(null);
    const [rtm,] = useState(new RtmClient());
    const history = useHistory();


    useEffect(()=>{
        return ()=>{
            logout();
        }
    },[])

    useEffect(() => {
        if (data?.app_id) {
            debugger
            intiateLogin(data)
            setChannel_name(data?.rtm?.channel_name)
        }
    }, [data]);

    rtm.on('ConnectionStateChanged', (newState, reason) => {
        console.log('reason', reason)
        // const view = document.getElementById('<div/>', {
        //     text: ['newState: ' + newState, ', reason: ', reason].join('')
        // })
        // document.getElementById('log').append(view)
        if (newState === 'ABORTED') {
            if (reason === 'REMOTE_LOGIN') {
                // Toast('You have already been kicked off!',"error")
                // document.getElementById('accountName').text('Agora Chatroom')

                // document.getElementById('dialogue-list')[0].innerHTML = ''
                // document.getElementById('chat-message')[0].innerHTML = ''
            }
        }
    })

    rtm.on('MessageFromPeer', async (message, peerId) => {
        if (message.messageType === 'IMAGE') {
            const blob = await rtm.client.downloadMedia(message.mediaId)
            blobToImage(blob, (image) => {
                // const view = document.getElementById('<div/>', {
                //     text: [' peer: ', peerId].join('')
                // })
                // document.getElementById('log').append(view)
                // document.getElementById('log').append(`<img src= 'document.getElementById{image.src}'/>`)
            })
        } else {
            // console.log('message ' + message.text + ' peerId' + peerId)
            // const view = document.getElementById('<div/>', {
            //     text: ['message.text: ' + message.text, ', peer: ', peerId].join('')
            // })
            // document.getElementById('log').append(view)
        }

    });

    rtm.on('MemberJoined', ({ channelName, args }) => {
        const memberId = args[0]
        // console.log('channel ', channelName, ' member: ', memberId, ' joined')

        // const s = ['event: MemberJoined ', ', channel: ', channelName, ', memberId: ', memberId].join('')
        // let divElementText = document.createTextNode(s)
        // document.getElementById('log').append(divElementText)

    });

    rtm.on('MemberLeft', ({ channelName, args }) => {
        const memberId = args[0]
        // console.log('channel ', channelName, ' member: ', memberId, ' joined')
        // const s = ['event: MemberLeft ', ', channel: ', channelName, ', memberId: ', memberId].join('')
        // let divElementText = document.createTextNode(s)
        // document.getElementById('log').append(divElementText)
    });

    rtm.on('ChannelMessage', async ({ channelName, args }) => {
        const [message] = args
     
        try {
            var m = message && message.text && JSON.parse(message.text)
            m._isme = false
            message['text'] = m
            setMessages((prepState) => [...prepState, message]);
          } catch (error) {
            // console.error("Error parsing JSON:", error);
          }
      
    });


    async function intiateLogin(params) {
        try {
            rtm.init(params.app_id)
            window.rtm = rtm

            rtm.login(params.rtm.account_name, params.rtm.token).then(() => {
                // console.log('login');
                rtm._logined = true;
                // toast.success('Login: ' + params.rtm.account_name, ' token: ', params.rtm.token);
                join(params);
            }).catch((err) => {
                // console.log("login error ", err)
            })

        } catch (err) {
            console.error(err)
        }
    }



    async function logout() {
     
        // if (!rtm._logined) {
        //     return
        // }
        await rtm.logout().then(() => {
            // console.log('logout')
            rtm._logined = false
           
        }).catch((err) => {
            // Toast('Logout failed, please open console see more details',"error")
            // console.log(err)
        })
    }

    function join(params) {

        if (rtm.channels[params.rtm.channel_name] ||
            (rtm.channels[params.rtm.channel_name] && rtm.channels[params.rtm.channel_name].joined)) {
            Toast('You already joined', "error")
            return
        }
        rtm.joinChannel(params.rtm.channel_name).then(() => {

            // let divElementText = document.createTextNode(rtm.accountName + ' join channel success')
            // document.getElementById('log').append(divElementText)

            rtm.channels[params.rtm.channel_name].joined = true
        }).catch((err) => {
            Toast('Join channel failed, please open console see more details.', "error")
            // console.error(err)
        })
    }

    function sendChannelMessage(user) {
        if (text_msg.trim() === "") {
            return
        }

        if (!rtm._logined) {
            Toast('Please Login First', "error")
            return
        }

        if (!rtm.channels[channel_name] ||
            (rtm.channels[channel_name] && !rtm.channels[channel_name].joined)
        ) {
            Toast('Please Join first', "error")
        }
        setText_msg("");

        const me = data?.me;
        const ts = new Date().getTime();
        const _msg_obj = {
            _name: me.name, _img: me.img_url, _user_code: me.user_code,
            _msg: text_msg,
            _msg_type: "MSG",
            _ts: ts,
            _host: me.user_code === user.user_code,
            _isme: true
        }
        const _msg_text = JSON.stringify(_msg_obj);

        rtm.sendChannelMessage(_msg_text, channel_name).then(() => {
            setMessages((prepState) => [...prepState, { 'text': _msg_obj, 'messageType': 'TEXT'}])
        }).catch((err) => {
            // Toast('Send message to channel ' + channel_name + ' failed, please open console see more details.', "error")
            console.error(err);
        })
    }

    return <Row>
        <div className="event-chat-box" style={{ marginBottom: "20px", height: '510px' }}>
            <Row className="border-bottom chat-tabs p-0 ">
                <Col span={24} className={showChat ? "active-channel-tab" : "inactive-channel-tab"} onClick={() => setShowChat(true)}>
                    <p className="fs16 fw600" style={{ color: '#1DB954' }}>Messages</p>
                </Col>

            </Row>
            <div className="pb-0" style={{ paddingLeft: "16px", paddingRight: "16px" }}>


                {showChat === true ?

                    <div style={{ height: "395px", overflowY: "scroll" }}>
                        <ScrollableFeed forceScroll={true}>
                            {messages.map((item, index) => {
                                return <Row className='md15 mb-1' key={index}>

                                    <Col span={24}>
                                        <Space size={5} align='top'>
                                            <Avatar src={item.text?._img} onClick={()=> history.push(`/profile/${item.text?._user_code}`)} className='cursor' size={30}></Avatar>
                                            <div>
                                                <Space size={5}>
                                                <p onClick={()=> history.push(`/profile/${item.text?._user_code}`)} className="fs-12 cursor" >{item.text?._name} {item.text?._isme && <small>(Host)</small> }</p>
                                                <small className='text-grey fs10'>{moment(item.text?._ts).format("LT a")}</small> 
                                                </Space>
                                               
                                                <p className='fs14' style={{color:'#4B5669'}}>{item.text?._msg}</p>
                                            </div>
                                        </Space>
                                    </Col>

                                </Row>
                            })}

                        </ScrollableFeed>
                    </div>
                    :
                    <div style={{ height: "74vh" }}>
                        <Row className='mt24'>
                            <Col span={3}>
                                <Avatar size={40}></Avatar>
                            </Col>
                            <Col span={21}>
                                <div className="mt07rem">
                                    <Row justify="space-around">
                                        <Col span={18}><p className="fs16 text-muted text-left">{"Mukesh BHardwaj"}</p></Col>
                                        <Col span={2} className='text-right'><AudioOutlined className='video-icons' /></Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
                {showChat === true ? <>

                    <Row className="input-chat pt-1" gutter={20}>
                        <Col span={20} className="input-chat-box ">
                            <Input value={text_msg} onChange={(e) => setText_msg(e.target.value)} placeholder="Type here ..." name="channelMessage"
                                onPressEnter={() => sendChannelMessage(data?.rtm)} />

                        </Col>
                        <Col span={4} className="text-right">
                            <Button className="no-border" type='text' id="send_channel_message" onClick={(e) => sendChannelMessage(data?.rtm)} icon={<PaperPlaneRight size={24} color="#0079d3" weight="fill" />}></Button>

                            {/* <Button type='primary' className=" new-send-btn" id="send_channel_message" onClick={(e) => sendChannelMessage(data?.rtm)}> <PaperPlaneRight size={20} color="#f7e8e8" weight="light" /></Button> */}
                        </Col>

                    </Row>
                </> : ""}
            </div>


        </div>

    </Row>
}


export default GoliveChat