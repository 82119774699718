import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Progress, Space, Badge, Radio, Button } from 'antd';
import { withRouter } from "react-router";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import moment from "moment";

const PollBody = ({ _item, details }) => {
    const [poolValue, setPoolValue] = useState("");
    const [item, setItem] = useState(_item);


    useEffect(() => {
        setItem(_item);
    }, [_item]);


    const _votePool = (value) => {
        axios({
            method: 'post',
            url: process.env.REACT_APP_PUBLIC_API_BASE_URL + "/market/feed/poll/vote",
            data: {
                choice_id: value,
                poll_perc: 1
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(function (res) {
            setItem({ ...item, ...res.data.data })
        }).catch(function () {
            window.location.href = "/login";
        })
    }

    return <Row>
        <Col span={24} className="pool-card">
            <h6 className="news-header cursor m-fs16" onClick={() => details(item)}>{item.title}</h6>
            {item.voted || !moment().isBefore(moment(item?.expired_at))? (
                <Fragment >
                    <Col span={24} className="poll-border" >
                        <Row >
                            {item.choices && item.choices.map((_item, i) => {
                                return <Col key={i} span={24} className="md10">
                                        <div className="poll-option-progress">
                                            <div className="syllo-progress" style={{ width: `${parseFloat(_item.p).toFixed(1)}%`}}>
                                                <p className="bold">{_item.choice}</p>
                                            </div><span>{parseFloat(_item.p).toFixed(1)}</span>
                                        </div>

                                        {/* <Progress className="poll-text" strokeWidth={10} percent={parseFloat(_item.p).toFixed(1)}/> */}
                                    </Col>
                            })}
                            <Col span={24} className="md20">
                                <Space>
                                    <p className="fs12" style={{ color: "#728096" }}> {item?.vote_count} Votes</p>
                                    <Badge status="default"></Badge>
                                    <p className="fs12" style={{ color: "#728096" }}> {moment().isBefore(moment(item?.expired_at))? moment(item?.expired_at).fromNow():"Poll closed"}</p>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Fragment>
            ) : (
                <Fragment>
                    <Col span={24} className="poll-border">
                        <Row >
                            <Col span={24} className="">
                                <Radio.Group
                                    className="pool"
                                    onChange={(evt) => setPoolValue(evt.target.value)}
                                >
                                    {item?.choices && item?.choices.map((_item, i) => {
                                        return <Radio key={i} value={_item.uuid}>{_item.choice}</Radio>;
                                    })}
                                </Radio.Group>
                            </Col>
                            <Col span={24}>
                                <Row>
                                    <Col span={16} className="md10">
                                        <Space>
                                            <p className="fs12" style={{ color: "#728096" }}> {item?.vote_count} Vote</p>
                                            <Badge status="default"></Badge>
                                    <p className="fs12" style={{ color: "#728096" }}> {moment().isBefore(moment(item?.expired_at))? moment(item?.expired_at).fromNow():"Poll closed"}</p>
                                        </Space>
                                    </Col>

                                    <Col span={8} className="text-right">
                                        <Button type="primary"
                                            disabled={poolValue === ""}
                                            onClick={() => _votePool(poolValue)}
                                        >
                                            Vote
                                        </Button >
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Fragment>
            )}
        </Col>
    </Row>
}
export default withRouter(PollBody);