import { createAction } from "redux-actions";
import axios from "../axios";
import Cookies from "js-cookie"




const ALL_GAMES_CATEGORIES = createAction("ALL_GAMES_CATEGORIES");
export const allGamesCategories = () => dispatch => {
    return axios({
        method: 'get',
        url: 'v1.0/gamify/categories',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_GAMES_CATEGORIES(res.data.data));
    })
};



const ALL_GAMES_REWARDS = createAction("ALL_GAMES_REWARDS");
export const allGamesRewards = () => dispatch => {
    return axios({
        method: 'get',
        url: 'v1.0/reward/rewards',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(ALL_GAMES_REWARDS(res.data.data));
    })
};



const CREATE_THEME = createAction("CREATE_THEME");
export const createThemeGame = (values) => dispatch => {
    return axios({
        method: 'post',
        url: 'v1.0/gamify/game',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(CREATE_THEME(res.data.data));
    })
};



export const createQuestions = (values) => dispatch => {
    return axios({
        method: 'post',
        url: 'v1.0/gamify/game/context',
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};