import { Row, Col, Avatar, Space, Button, Modal, Divider, Tag } from "antd";
import { BellRinging, Export, WarningOctagon } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ManageMembers from "./ManageMembers";
import { RWebShare } from "react-web-share";


const ChannelAbout = ({ channel_details, tab, memberdata }) => {
    const history = useHistory()

    const [about_data, setAbout_data] = useState(true);
    const [memberList, setMemberList] = useState(false)



    useEffect(() => {
        if (tab === "8") {
            setAbout_data(false);
        } else {
            setAbout_data(true);
        }
    }, [tab])
    return (

        <Row>
            <Col span={24} className="md15" style={{ padding: '10px' }}>

                <h2 className="fw500" style={{ color: '#013238' }}>{channel_details?.name}</h2>
                <Row>
                    <Col span={18}>
                        <Space>
                            <Avatar className="cursor" size={30} src={channel_details?.creator?.avatar_url} onClick={() => history.push(`/profile/${channel_details?.creator?.user_code}`)} />
                            <p className="fs12 fw600"><a href={"/profile/" + channel_details?.creator?.user_code}>{channel_details?.creator?.full_name}</a></p>
                        </Space>
                    </Col>
                    {/* {
                        channel_details?.isme ? <></> :

                            <Col span={6}>
                                <Button type='primary' onClick={() => history.push(`/profile/${channel_details?.creator?.user_code}`)}> Follow </Button>
                            </Col>
                    } */}
                </Row>

                {
                    about_data === true ? <Row className="about-card md20">
                        <Col span={24} className="">
                            <p className="fs14 fw600 "> About Channel </p>
                            <p className="fs12 fw400 md10">{channel_details?.desc} </p>
                            <Divider></Divider>
                            <p className="fs12 fw400 "><Tag color="processing">
                                {channel_details?.members?.members} Members
                            </Tag>| <Tag color="success">
                                    {channel_details?.members?.online} online
                                </Tag></p>
                        </Col>
                    </Row> : <Row className=" about-card  md20">
                        <Col span={24}>
                            <Button type="text" className="fs10 fw600 md10" style={{ backgroundColor: '#F0F0F0' }}> What is happening today?</Button>
                            <Button type="text" className="fs10 fw600 md10" style={{ backgroundColor: '#F0F0F0' }}> What is the worst food you’ve eaten?</Button>
                            <Button type="text" className="fs10 fw600 md10" style={{ backgroundColor: '#F0F0F0' }}> How is the weather there?</Button>
                        </Col>
                    </Row>


                }
                <Row gutter={4} className="md20 mb-4 p10">
                    <Col span={5} className="about-icon">
                        <RWebShare
                            data={{
                                text: channel_details?.desc,
                                url: window.location,
                                title: channel_details?.name,
                            }}

                        ><Button type="text"> <Export size={20} color="#000000" weight="thin" /></Button>
                        </RWebShare>

                    </Col>
                    <Col span={5} className="about-icon">
                        <Button type="text"> <BellRinging size={20} color="#000000" weight="thin" /></Button>
                    </Col>
                    <Col span={5} className="about-icon">
                        <Button type="text"> <WarningOctagon size={20} color="#000000" weight="thin" /></Button>
                    </Col>
                    <Col span={5} className="md5">
                        <Button type="text" className="fs11 fw600" style={{ backgroundColor: '#F0F0F0' }} onClick={() => setMemberList(true)}> Member List</Button>
                    </Col>
                </Row>


                <Modal open={memberList} title={"Show Member's List"} centered footer={false} onCancel={() => setMemberList(false)}>
                    <ManageMembers notAdmin={true} members={memberdata} loadMoreMembers={false} loader={false} ></ManageMembers>
                </Modal>

            </Col>
        </Row>


    )
}
export default ChannelAbout;