import { Bank, ChartBar, ChartLineUp, ChartPieSlice, Coins, CoinVertical, CurrencyCircleDollar, FilmSlate, GlobeHemisphereEast, Heart, Infinity, Kanban, Money,
 MusicNote, NumberCircleEight, NumberCircleFive, NumberCircleFour, NumberCircleNine, NumberCircleOne,
  NumberCircleSeven, NumberCircleSix, NumberCircleThree, NumberCircleTwo, NumberCircleZero, RocketLaunch, ShieldPlus, SmileySticker, TreeStructure, TrendUp, Vault,Newspaper, Note, Article, Notebook, ChartBarHorizontal, MonitorPlay, Headphones, Television, GraduationCap, CalendarPlus}
   from "@phosphor-icons/react";
import React from "react";

const IconsView = (props) => {
   const size = props?.size?props?.size:20
   const color = props?.color?props?.color:"#000000"
    return (
       <>
            {
                props.icon === "Heart" ? <Heart size={size} color={color} weight={props.weight} /> :
                props.icon === "Coins" ? <Coins size={size} color={color} weight={props.weight} /> :
                props.icon === "FilmSlate" ? <FilmSlate size={size} color={color} weight={props.weight} /> :
                props.icon === "MusicNote" ? <MusicNote size={size} color={color} weight={props.weight} /> :
                props.icon === "Money" ? <Money size={size} color={color} weight={props.weight} /> :
                props.icon === "Bank" ? <Bank size={size} color={color} weight={props.weight} /> :
                props.icon === "ChartBar" ? <ChartBar size={size} color={color} weight={props.weight} /> :
                props.icon === "ChartLineUp" ? <ChartLineUp size={size} color={color} weight={props.weight} /> :
                props.icon === "ChartPieSlice" ? <ChartPieSlice size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleZero" ? <NumberCircleZero size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleOne" ? <NumberCircleOne size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleTwo" ? <NumberCircleTwo size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleThree" ? <NumberCircleThree size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleFour" ? <NumberCircleFour size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleFive" ? <NumberCircleFive size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleSix" ? <NumberCircleSix size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleSeven" ? <NumberCircleSeven size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleEight" ? <NumberCircleEight size={size} color={color} weight={props.weight} /> :
                props.icon === "NumberCircleNine" ? <NumberCircleNine size={size} color={color} weight={props.weight} /> :
                props.icon === "TrendUp" ? <TrendUp size={size} color={color} weight={props.weight} /> :
                props.icon === "Infinity" ? <Infinity size={size} color={color} weight={props.weight} /> :
                props.icon === "Vault" ? <Vault size={size} color={color} weight={props.weight} /> :
                props.icon === "CurrencyCircleDollar" ? <CurrencyCircleDollar size={size} color={color} weight={props.weight} /> :
                props.icon === "RocketLaunch" ? <RocketLaunch size={size} color={color} weight={props.weight} /> :
                props.icon === "Kanban" ? <Kanban size={size} color={color} weight={props.weight} /> :
                props.icon === "SmileySticker" ? <SmileySticker size={size} color={color} weight={props.weight} /> :
                props.icon === "ShieldPlus" ? <ShieldPlus size={size} color={color} weight={props.weight} /> :
                props.icon === "TreeStructure" ? <TreeStructure size={size} color={color} weight={props.weight} /> :
                props.icon === "GlobeHemisphereEast" ? <GlobeHemisphereEast size={size} color={color} weight={props.weight} /> :
                props.icon === "Coins" ? <Coins size={size} color={color} weight={props.weight} /> :
                props.icon === "CoinVertical" ? <CoinVertical size={size} color={color} weight={props.weight} /> :
                props.icon === "CalendarPlus" ? <CalendarPlus size={size} color={color} weight={props.weight} /> :
                props.icon === "Newspaper" ? 
                <Newspaper size={size} color={color} weight={props.weight} />
//                 <svg width={size} height="39"  viewBox="0 0 55 39" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M9.61005 37.2434H3.17007C2.28007 37.2434 1.56006 36.5235 1.56006 35.6335V14.6735L20.9101 1.77344L24.1301 3.92346L8.00006 14.6735V35.6335C8.00006 36.5235 8.72005 37.2434 9.61005 37.2434Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M14.44 14.6578V35.6178C14.44 36.5078 15.16 37.2278 16.05 37.2278H9.60999C8.71999 37.2278 8 36.5078 8 35.6178V14.6578L24.13 3.90781L27.35 1.75781L30.57 3.90781L27.35 6.0578L14.44 14.6578Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M53.14 14.6635L46.7 19.2635L40.2599 23.8535L37.3199 25.9535H30.2599L14.4399 14.6635L27.3499 6.06345L30.5699 3.91345L33.7899 1.77344L53.14 14.6635Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M53.1402 14.6641V35.6241C53.1402 36.5141 52.4202 37.2341 51.5302 37.2341H16.0602C15.1702 37.2341 14.4502 36.5141 14.4502 35.6241V14.6641L30.2702 25.9541H37.3302L40.2702 23.8541L46.7102 19.2641L53.1502 14.6641H53.1402Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M30.2599 25.9531L14.9399 36.7731" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M52.6298 36.774L37.7998 25.9141" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

                 :
                props.icon === "Note" ? 
                <Note size={size} color={color} weight={props.weight} />
//                 <svg width={20} height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M16.5 23.06H10.98V17.54L27.53 1L33.04 6.51001L16.5 23.06Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M23.3901 5.13281L28.9001 10.6528" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M31.6603 16.1728V31.3428C31.6603 32.1028 31.0403 32.7228 30.2803 32.7228H2.70032C1.94032 32.7228 1.32031 32.1028 1.32031 31.3428V3.76282C1.32031 3.00282 1.94032 2.38281 2.70032 2.38281H17.8703" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

                :
                props.icon === "Article" ? 
                <Article size={size} color={color} weight={props.weight} />
//                 <svg width={size} height="42" viewBox="0 0 45 42" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M32.81 35.8934L40.04 28.4934C40.61 27.9134 40.6001 26.9734 40.0201 26.4034C39.4401 25.8334 38.5 25.8434 37.93 26.4234L28.63 35.9434C27.52 37.0834 27.56 38.9334 28.73 40.0734C29.9 41.2134 31.75 41.2134 32.86 40.0734L42.29 30.4234C44 28.6734 43.97 25.8634 42.22 24.1534C40.47 22.4434 37.66 22.4734 35.95 24.2234L28.72 31.6234" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M21.5401 31.8697H2.7301C1.9701 31.8697 1.3501 31.2497 1.3501 30.4897V2.80969C1.3501 2.04969 1.9701 1.42969 2.7301 1.42969H30.4101C31.1701 1.42969 31.7901 2.04969 31.7901 2.80969V21.6197C31.7901 21.9797 31.6501 22.3297 31.3901 22.5897L22.5001 31.4797C22.2401 31.7397 21.8902 31.8797 21.5302 31.8797L21.5401 31.8697Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M31.6804 22.1797H22.1104V31.7497" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

                 :
                props.icon === "Notebook" ? 
                <Notebook size={size} color={color} weight={props.weight} />
//                 <svg width={size} height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M25.7665 19.3088V4.2302C25.7729 2.45551 24.3365 1.00638 22.5619 1C22.5555 1 22.5427 1 22.5363 1H4.22754" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M12.8457 9.61719H21.4575" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M12.8457 13.9141H21.4575" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M1.82065 6.38506C0.633259 5.05723 0.748144 3.01441 2.07598 1.82064C3.40381 0.63325 5.44664 0.748155 6.64041 2.07599C7.17027 2.66968 7.46391 3.43573 7.46391 4.22733V21.4572C7.46391 23.2383 8.91303 24.6874 10.6941 24.6874C12.4752 24.6874 13.9243 23.2383 13.9243 21.4572C13.9243 20.6656 13.6307 19.8996 13.1008 19.3059H28.1794C29.3668 20.6337 29.2518 22.6765 27.924 23.8703C27.3303 24.4002 26.5642 24.6938 25.7663 24.6938H10.6877" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

                 :
                props.icon === "ChartBarHorizontal" ? 
                <ChartBarHorizontal size={size} color={color} weight={props.weight} />
//                 <svg width={size} height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M4.81982 33.4297V19.6797H15.5098" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M39.94 33.4297H1.77002" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M15.5098 33.4256V10.5156H26.1998" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M36.8902 1.35938H26.2002V33.4294H36.8902V1.35938Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

                 :
                props.icon === "MonitorPlay" ? 
               <MonitorPlay size={size} color={color} weight={props.weight} />
//                 <svg width={size} height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M3.61865 9.9375H29.9302" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M22.9282 1.77344L18.2627 27.4372" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M13.5975 1.77344L8.9248 27.4372" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M1.93018 19.2734H28.2344" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

                :
                props.icon === "Headphones" ? 
               <Headphones size={size} color={color} weight={props.weight} />
//                 <svg width={size} height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M14.6132 9.97656C16.671 9.97656 18.3372 11.6427 18.3372 13.7006V19.66C18.3372 21.7179 16.671 23.384 14.6132 23.384C12.5553 23.384 10.8892 21.7179 10.8892 19.66V13.7006C10.8892 11.6427 12.5553 9.97656 14.6132 9.97656Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M14.6128 26.3594V29.3365" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M21.2779 20.3984C20.8443 24.0807 17.5068 26.7131 13.8246 26.2743C10.743 25.9087 8.31434 23.48 7.94873 20.3984" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M5.90596 18.4041C4.04657 13.5937 6.4387 8.19309 11.2491 6.33369C16.0595 4.4743 21.4601 6.86644 23.3195 11.6768C24.1552 13.8392 24.1552 16.2365 23.3195 18.4041" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M7.0866 26.8144C0.568277 22.6569 -1.34857 14.0076 2.80372 7.48406C6.96124 0.965735 15.6106 -0.95111 22.1341 3.20119C28.6524 7.35871 30.5693 16.008 26.417 22.5316C25.3201 24.2552 23.8577 25.7124 22.1341 26.8144" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

                 :
                props.icon === "GraduationCap" ? <GraduationCap size={size} color={color} weight={props.weight} /> :
                props.icon === "Television" ? <Television size={size} color={color} weight={props.weight} /> :
                props.icon === "Heart" ? <Heart size={size} color={color} weight={props.weight} /> :
               <Heart size={size} color={color} weight={props.weight} /> 
            }
       </>
    );
}
export default IconsView;