import React, { Component } from "react";
import { Affix, Button, Col, Input, Row, Select, Skeleton, Typography, Divider, Drawer, Space, Badge } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import Cookies from "js-cookie";
import { withRouter } from "react-router";
import ScrollableFeed from 'react-scrollable-feed'
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import './messanger.scss'
import { RightSquareOutlined } from "@ant-design/icons";
import { ArrowLeft, Info, PaperPlaneRight } from "@phosphor-icons/react";
import BlankState from "../BlankState";
import axios from "../../store/axios";

const { Paragraph } = Typography
const { Option } = Select

class Messanger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ws: new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/room?&token=" + Cookies.get("Logintoken")),
            users: [], loader: true, messages: [],
            ws1: null, messageLoader: true, sendMessage: "", text: "", activeChat: null, searchedUser: [],
            open_chat_index: -1, visibleDrawer: false, loadingChat: false,
            profile: {}, profile_loader: true
        };
    }


    componentDidMount() {
        this.state.ws.onmessage = evt => {
            const message = JSON.parse(evt.data)
            var users = message;
            // if (users.length !== 0) {
            //     this.setState({ users: users, loader: false, activeChat: users[0] })
            // }
            // else {
            // }
            this.setState({ users: users, loader: false })
        }

        // if(this.state.ws1 !== null){
        //     this.state.ws1.onmessage = evt => {
        //         const message = JSON.parse(evt.data)
        //         var msg = this.state.messages.concat(message);
        //         this.setState({ messages: msg, messageLoader: false})
        //     }
        // }

        this.state.ws.onclose = () => {
            this.setState({
                ws: new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/stream?id=" + this.props.id + "&token=" + Cookies.get("Logintoken"))
            })
        }
    }


    showChat(userCode, index) {
        this.setState({
            messages: [],
            open_chat_index: index,
            visibleDrawer: false,
            loadingChat: true,
            profile: {}
        })
        if (this.state.ws1 !== null) {
            this.state.ws1.close()
        }

        var ws1 = this.state.ws1
        ws1 = new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/chat?token=" + Cookies.get("Logintoken") + "&uc=" + userCode.user_code)

        ws1.onmessage = evt => {
            const data = JSON.parse(evt.data)
            var msg = this.state.messages.concat(data?.messages);
            this.setState({ messages: msg, messageLoader: false, ws1: ws1, activeChat: userCode, loadingChat: false });
            if (msg.length < 100 && this.state.profile.followers === undefined) {
                this.getProfile(userCode.user_code);
            }
        }
    }



    async searchUser(user) {
        if (user === "") {
            return
        }
        if (user.length <= 2) {
            return
        }


        await this.props.searchUser({ text: user }).then(() => {
            this.setState({
                searchedUser: this.props._user_searched
            })
        })
    }

    sendMessage() {
        if (this.state.text === "" || this.state.text === null) {
            return
        }
        const message = { m: this.state.text }
        this.state.ws1.send(JSON.stringify(message))
        this.setState({ text: "" })
    }

    getUser(evt) {
        this.setState({ loadingChat: true })
        let data = {
            user_code: JSON.parse(evt)[2],
            room_name: JSON.parse(evt)[1],
            room_img: JSON.parse(evt)[3]
        }
        this.showChat(data)
    }

    onClose = () => {
        this.setState({ visibleDrawer: false })
    };

    async getProfile(user_code) {
        this.setState({ profile_loader: true })
        await axios({
            method: 'get',
            url:  `account/profile/${user_code}`,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            }
        }).then((res) => {
            this.setState({ profile: res.data.data, profile_loader: false })

        })
    }

    async followUser(user_code) {
        let data = {
            following_user_code: user_code,
            follower: false
        }
        let method = "post"
        await this.props.FollowUnFollowUser({ method, data }).then(() => {
            //pass
            this.getProfile(user_code);
        })
    }



    render() {
        console.log(this.state.messages)

        return (
            <Row>
                <Col span={24} className="profile-v3 messanger-chat">

                    <Row>
                        <Col span={24} >
                            {/* mobile drawer people list */}

                            <Drawer size='default' className="messanger-drw" placement="left" onClose={this.onClose} visible={this.state.visibleDrawer}  >
                                <Row  >
                                    <Col span={24} className="border-right">
                                        {/* <Space>
                                            <ArrowLeft size={16} color="#efefe" onClick={() => this.props.history.goBack()} />
                                            <p className="smaller-heading-text ">  Messages</p>
                                        </Space> */}
                                        <Row>
                                            <Col span={24} className="md15 ">
                                                <Select className="search-name" placeholder="Search for people" value={null} style={{ width: "100%", textAlign: "left" }} onSearch={(evt) => this.searchUser(evt)} onChange={(evt) => this.getUser(evt)} showSearch showArrow={false}>
                                                    {
                                                        this.state.searchedUser.map((item) => {
                                                            return <Option value={JSON.stringify(item)}>{item[1]}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                            <Divider className="mb-0"></Divider>
                                            <Col span={24} className="">
                                                {
                                                    this.state.loader === true ? <Skeleton></Skeleton> : this.state.users.map((item, index) => {
                                                        return <Row gutter={4} key={index} className={this.state.open_chat_index == index ? " chat-group chat-open" : " chat-group"}>
                                                            <Col span={5} >
                                                                <div className="" >
                                                                    <Avatar src={item.room_img} shape={"circle"} size={"medium"}></Avatar>
                                                                </div>

                                                            </Col>
                                                            <Col span={13}>
                                                                <p className="smaller-heading-text cursor fs14" onClick={() => this.showChat(item, index)}>{item.room_name}
                                                                </p>

                                                                {
                                                                    item.last_msg_id !== item.new_msg_id ? <Paragraph ellipsis={{ rows: 1 }} className="sub-text blue-text">{item.new_msg} </Paragraph> : <Paragraph ellipsis={{ rows: 1 }} className="sub-text">{item.new_msg} </Paragraph>
                                                                }

                                                            </Col>
                                                            <Col span={6}>
                                                                <small className="grey-text" style={{ float: "right", fontSize: "12px", marginTop: '-12px' }}>{moment(item.last_active).format("h:mm a")}</small>
                                                            </Col>
                                                        </Row>
                                                    })
                                                }



                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Drawer>
                            <Row style={{ height: "calc(100vh - 69px)" }}>
                                <Col xl={{ span: 16, offset: 4 }} md={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }} className="">
                                    <Row className="h-100" style={{ backgroundColor: '#fafafa' }}>
                                        <Col lg={8} xs={0} className="border-right">
                                            <Row>
                                                <Col span={24} className="md25 border-bottom" style={{ paddingBottom: "22px" }}>
                                                    <Space>
                                                        <ArrowLeft size={20} color="#efefe" onClick={() => this.props.history.goBack()} />
                                                        <p className="smaller-heading-text ">  Messages</p>
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col span={24} className="md10" style={{ padding: "0 15px" }}>
                                                    <Select className="search-name" placeholder="Search for people" value={null} style={{ width: "100%", textAlign: "left" }} onSearch={(evt) => this.searchUser(evt)} onChange={(evt) => this.getUser(evt)} showSearch showArrow={false}>
                                                        {
                                                            this.state.searchedUser.map((item) => {
                                                                return <Option value={JSON.stringify(item)}>{item[1]}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </Col>
                                                {/* <Divider className="mb-0"></Divider> */}
                                                <Col span={24} className="md25" style={{ paddingLeft: "15px" }}>
                                                    {
                                                        this.state.loader === true ? <Skeleton></Skeleton> : this.state.users.map((item, index) => {
                                                            return <Row onClick={() => this.showChat(item, index)} className={this.state.open_chat_index == index ? "chat-group chat-open cursor" : "chat-group cursor"}>
                                                                <Col xl={5} md={5} >
                                                                    <div className="dp" >
                                                                        <Avatar src={item.room_img} shape={"circle"} size={"large"}></Avatar>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={14} md={13}>

                                                                    <p className="smaller-heading-text fs-16">
                                                                        <Paragraph ellipsis={{ rows: 1 }}>{item.room_name} </Paragraph>
                                                                    </p>

                                                                    {
                                                                        item.last_msg_id !== item.new_msg_id ? <Paragraph ellipsis={{ rows: 1 }} className="room-name-rmsg blue-text">{item.new_msg} </Paragraph> : <Paragraph ellipsis={{ rows: 1 }} className="room-name-rmsg">{item.new_msg} </Paragraph>
                                                                    }

                                                                </Col>
                                                                <Col span={5} md={4} className="nopadding">
                                                                    <small className="grey-text md5" style={{ float: "right", fontSize: "12px" }}>{moment(item.last_active).format("h:mm a")}</small>
                                                                </Col>
                                                            </Row>
                                                        })
                                                    }



                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={16} xs={24} className={"border-right"}>
                                            <Row>
                                                <Col span={24} className="px-2 md10 border-bottom height86" style={{ paddingBottom: "20px", height: "60px", padding: '0px 0px' }}>

                                                    <Button type={"primary"} className="m-show mb-2" onClick={() => this.setState({ visibleDrawer: true })} icon={<RightSquareOutlined />}></Button>

                                                    {
                                                        !this.state.messageLoader &&
                                                        <Row className={"md5"}>
                                                            <Col xl={2} md={3} xs={3}>
                                                                <Avatar src={this.state.activeChat.room_img} size={"large"}></Avatar>
                                                            </Col>
                                                            <Col xl={10} md={10} xs={10} className="ps-1">
                                                                <p className=" fs16 fw500 m-fs12" style={{}}>{this.state.activeChat.room_name}</p>
                                                                <p className="fs12 fw500" style={{ color: '#717171', marginTop: '-3px' }}>{this.state.activeChat?.username}</p>
                                                            </Col>
                                                            <Col xl={12} md={11} xs={11} className="text-right">
                                                                <Space className="md10">
                                                                    <small className="grey-text fs12">Active {moment(this.state.activeChat.last_active).fromNow()}</small>
                                                                    <a href={"/profile/" + this.state.activeChat?.user_code}><Info size={20} color="#999999" weight="light" /></a>

                                                                </Space>

                                                            </Col>



                                                        </Row>

                                                    }


                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={24} className="" style={{ overflowY: "scroll", height: "calc(100vh - 216px)", padding: '10px' }}>
                                                    {
                                                        this.state.activeChat === null ? <Row>
                                                            <Col span={24} className="text-center md150">
                                                                <BlankState desc="Select people to chat"
                                                                    img={require("../../assets/images/blank/send-msg.svg").default}
                                                                    height={200}>

                                                                </BlankState>
                                                            </Col>
                                                        </Row> :
                                                            <ScrollableFeed forceScroll={true} className="padding-bottom10">
                                                                {
                                                                    this.state.loadingChat === true ? <>
                                                                        <Skeleton active className={'p-2'} /><Skeleton active className={'p-2'} />
                                                                    </> :

                                                                        this.state.profile_loader ? <Col span={24} className="text-center md80">

                                                                            <Skeleton.Avatar active size={100} /><br /> <br />
                                                                            <Skeleton.Input active></Skeleton.Input>
                                                                            <br /><br />
                                                                            <Space>
                                                                                <Skeleton.Input active></Skeleton.Input>
                                                                                <Skeleton.Input active></Skeleton.Input>
                                                                            </Space>
                                                                        </Col> : <>
                                                                            <Col span={24} className="text-center md80">
                                                                                <Avatar size={100}
                                                                                    src={this.state.activeChat.room_img} ></Avatar>
                                                                                <p className="fs16 fw400 md25">{this.state.activeChat.room_name}</p>
                                                                                <p className="fs11 fw400" style={{ color: '#4B5669' }}>Active {moment(this.state.profile.last_active).fromNow()}</p>

                                                                            </Col>
                                                                            <Col span={24} className="text-center">
                                                                                <p className="fs12 fw400 md25" style={{ color: '#000000' }}>
                                                                                    {this.state.profile?.user_about}
                                                                                </p>
                                                                                <Space>
                                                                                    <p className="fs14 fw600 md25">{this.state.profile?.likes} Likes    </p>
                                                                                    <Badge status="default" className="md20 p-3" ></Badge>
                                                                                    <p className="fs14 fw600 md25 ">{this.state.profile?.followers} Followers   </p>
                                                                                </Space>
                                                                            </Col>
                                                                            <Col span={24} className="text-center md25">
                                                                                <Space>

                                                                                    <Button type="dashed" className="mesg-follw" href={"/profile/" + this.state.activeChat?.user_code}>
                                                                                        View Profile
                                                                                    </Button>
                                                                                    {
                                                                                        this.state.profile?.following ? <Button href={"/profile/" + this.state.activeChat?.user_code} type="primary" className="mesg-follw ms-3" >
                                                                                            Following
                                                                                        </Button> : <Button type="primary" className="mesg-follw ms-3" onClick={() => this.followUser(this.state.activeChat?.user_code)}>
                                                                                            Follow
                                                                                        </Button>
                                                                                    }

                                                                                </Space>
                                                                            </Col>
                                                                            {
                                                                                this.state.messages.map((item, index) => {
                                                                                    if (item[10] === Cookies.get("user_code")) {
                                                                                        return (
                                                                                            <Col span={24} key={index}>
                                                                                                <Row className="md10">


                                                                                                    <p><i className="fas fa-caret-right bootom-caret-right"></i></p>
                                                                                                    <div className="Text-message-right  chat-message-me">
                                                                                                        <Paragraph ellipsis={{ rows: 5, expandable: true, symbol: 'more' }} className="sub-heading-text word-break">{item[3]}</Paragraph>
                                                                                                        <p className="text-right md-5"><small className="grey-text" style={{ fontSize: "10px" }}>
                                                                                                            {" "}
                                                                                                            {moment(item[4]).format("D MMM YY, h:mm a")}
                                                                                                        </small></p>
                                                                                                    </div>
                                                                                                </Row>
                                                                                            </Col>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Col span={24} key={index}>
                                                                                                <Row className="md10">
                                                                                                    {/* <p><i className="fas fa-caret-left bootom-caret"></i></p> 
                                                                                                    <div className="Text-message-left  chat-message">
                                                                                                      
                                                                                                        <Paragraph ellipsis={{ rows: 5, expandable: true, symbol: 'more' }} className="sub-heading-text word-break">{item[3]}</Paragraph>

                                                                                                        <p className="text-right md-5"><small className="grey-text" style={{ fontSize: "10px" }}>
                                                                                                            {" "}
                                                                                                            {moment(item[4]).format("D MMM YY, h:mm a")}
                                                                                                        </small></p>
                                                                                                    </div>*/}

                                                                                                </Row>
                                                                                            </Col>
                                                                                        );

                                                                                    }
                                                                                })
                                                                            }

                                                                        </>



                                                                }

                                                            </ScrollableFeed>
                                                    }

                                                </Col>


                                                {
                                                    !this.state.messageLoader && <Col span={24} style={{ position: "absolute", bottom: 0, width: "100%" }}>
                                                        <Affix>
                                                            <Row className="fixed-chat-input">
                                                                {/* <Col span={4} className="text-center file">
                                                                       <Button className="btn send-btn"> <FileImageOutlined /></Button>
                                                                       <Button className="btn send-btn m-hide"> <FileOutlined /></Button>
                                                                   </Col> */}
                                                                <Col span={21} style={{ paddingLeft: "20px" }}>
                                                                    <Input onPressEnter={() => this.sendMessage()} className='md5' rows={1} placeholder="Start a new message" value={this.state.text} onChange={(evt) => this.setState({ text: evt.target.value })}></Input>
                                                                </Col>
                                                                <Col span={3} className="text-center messanger-icon">
                                                                    <Button className="no-border" onClick={() => this.sendMessage()}> <PaperPlaneRight size={30} color="#0079d3" weight="fill" /></Button>
                                                                </Col>

                                                            </Row>
                                                        </Affix>
                                                    </Col>
                                                }

                                            </Row>
                                        </Col>
                                        {/* <Col span={4} offset={1} className="text-center">


                            {
                                !this.state.messageLoader && <Row>
                                    <Col span={24} className="text-center md50">
                                        <Avatar src={this.state.activeChat.room_img} style={{ height: "100px", width: "100px" }}></Avatar>
                                    </Col>
                                    <Col span={24} className=" text-center">
                                        <h5 className="heading-text text-center md20">{this.state.activeChat.room_name}</h5>
                                    </Col>
                                    <Col span={24} className=" text-center md10">
                                        <Button onClick={() => window.location.href = '/profile/' + this.state.activeChat.user_code} type="ghost" className="btn btn-default" block>View Profile</Button>
                                    </Col>
                                </Row>

                            }


                        </Col> */}
                                    </Row>
                                </Col>

                            </Row>


                        </Col>
                    </Row>

                </Col>

            </Row>

        );
    }
}



const mapStateToProps = (state) => ({
    _user_searched: state.channel.user_searched
});

const mapDispatchToProps = (dispatch) => ({
    searchUser: (v) => dispatch(actions.searchUser(v)),
    FollowUnFollowUser: (v) => dispatch(actions.FollowUnFollowUser(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Messanger));
