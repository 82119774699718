import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    channel_list: [],
    channeldetail: [],
    channel_chat: [],
    public_channel: [],
    profile: [],
    live_video: [],
    joined_user: [],
    live_updates:[],
    live_chat: [],
    event_detail:[],
    channel_payment:[],
    public_events:[],
    channel_members:[],
    upcoming_events:[],
    creator_details:[],
    user_searched:[],
    user_menu:[],
    channel_settings:null
}
const ChannelReducer = handleActions({
    CHANNEL_LIST: (state, action) => {
        return { ...state, channel_list: action.payload };
    },
    CHANNEL_DETAIL: (state, action) => {
        return { ...state, channeldetail: action.payload };
    },
    CHANNEL_CHAT: (state, action) => {
        return { ...state, channel_chat: action.payload };
    },
    PUBLIC_CHANNELS: (state, action) => {
        return { ...state, public_channel: action.payload };
    },
    CHANNEL_PROFILE: (state, action) => {
        return { ...state, profile: action.payload };
    },
    LIVE_VIDEOS: (state, action) => {
        return { ...state, live_video: action.payload };
    },
    JOINED_USER: (state, action) => {
        return { ...state, joined_user: action.payload };
    },
    WEBSOCKET_DATA: (state, action) => {
        return { ...state, live_updates: action.payload };
    },
    WEBSOCKET_CHAT_DATA: (state, action) => {
        return { ...state, live_chat: action.payload };
    },
    EVENT_DETAILS: (state, action) => {
        return { ...state, event_detail: action.payload };
    },
    PAYMENT_LINK: (state, action) => {
        return { ...state, channel_payment: action.payload };
    },
    PUBLIC_EVENTS: (state, action) => {
        return { ...state, public_events: action.payload };
    },
    CHANNEL_MEMBERS: (state, action) => {
        return { ...state, channel_members: action.payload };
    },
    UPCOMING_EVENTS: (state, action) => {
        return { ...state, upcoming_events: action.payload };
    },
    CREATOR_DETAILS: (state, action) => {
        return { ...state, creator_details: action.payload };
    },
    SEARCHED_USER: (state, action) => {
        return { ...state, user_searched: action.payload };
    },
    USER_MENU: (state, action) => {
        return { ...state, user_menu: action.payload };
    },
    CHANNEL_SETTINGS: (state, action) => {
        return { ...state, channel_settings: action.payload };
    },
}, INITIAL_STATE);

export default ChannelReducer;
