import React, { Component } from "react";
import { Input, message, Col, Space, Skeleton, Row, Tabs, Button, Affix, Upload, Image, Tooltip, Badge, Spin } from "antd";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import "./chat.css";
import moment from "moment";
import Cookies from "js-cookie"
import ScrollableFeed from 'react-scrollable-feed'
import { SearchOutlined } from '@ant-design/icons';
import MessageTextCard from './MessageTextCard'
import MessageImgCard from "./MessageImgCard";
import MessageDocCard from "./MessageDocCard";
import MessageDiscussCard from "./MessageDiscussCard";
import { CaretLeft, ChatCircleDots, Image as ImageUpl, PaperPlaneRight, X } from "@phosphor-icons/react"
import axios from "../../store/axios";
import request from "../../store/request";

import Toast from "../utils/Toast";
moment.locale("IN");


class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      messages: [],
      ws: "",
      loader: true,
      ws: null,
      filesModal: false,
      active: null,
      discussion: false,
      discussionTopic: [],
      filesFilter: "", searchData: [],
      imgMsgReady: null,
      imgUploading: false,
    };
  };

  componentDidMount() {
    let _ws = new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/stream?id=" + this.props.id + "&token=" + Cookies.get("Logintoken"))
    this.setState({
      ws: _ws
    })
    _ws.onmessage = evt => {
      const message = JSON.parse(evt.data)
      if (message.length !== 0) {
        if ((this.state.discussion && message[0][15]) || (!this.state.discussion && !message[0][15])) {
          var _messages = this.state.messages.concat(message);
          this.setState(state => ({ messages: _messages }))
          // this.props.updateFiles(messages)
        }
      }
      this.setState({ loader: false })
    }

    _ws.onclose = () => {
      this.setState({
        ws: new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/stream?id=" + this.props.id + "&token=" + Cookies.get("Logintoken"))
      })
    }
  }


  getFiles(evt) {
    axios({
      method: 'get',
      url:  `channel/${this.props._uid}/search?key=` + evt,
      headers: {
        Authorization: Cookies.get("Logintoken")
      }
    }).then(res => {
      const data = res.data.data
      this.setState({
        searchData: data
      })
    }).catch((error) => {
      Toast(error.response.data.message,"error")
    })
  }

  oOpenDission(id) {
    let msg = {
      mid: id[0],
      discussion_opened: true
    }
    this.state.ws.send(JSON.stringify(msg))
    this.setState({ discussion: true, discussionTopic: id, messages: [], active: null })
  }

  async connectWS(id) {
    this.setState({
      loader: true, messages: []
    })

    let msg = {
      mid: id[0],
      discussion_closed: true
    }
    this.state.ws.send(JSON.stringify(msg))
    this.setState({ discussion: false, discussionTopic: "", active: null })
  }

  submitMessage(evt) {
    evt.preventDefault();

    var message = ""

    // If image was selected
    if (this.state.imgMsgReady !== null) {
      message = this.state.imgMsgReady
      if (this.state.text !== "" || this.state.text !== " ") {

        message.m = this.state.text
      }
      this.state.ws.send(JSON.stringify(message))
      this.setState({ text: "", active: null, imgMsgReady: null })
      return
    }
    if (this.state.text === "" || this.state.text === " ") {
      return
    }

    if (this.state.discussionTopic.length === 0) {
      message = { m: this.state.text, chat_type: this.state.active ? 1 : null }
    }
    else {
      message = { m: this.state.text, mid: this.state.discussionTopic[0] }
    }

    this.state.ws.send(JSON.stringify(message));
    this.setState({ text: "", active: null });
  }

  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  paytoSubcribe() {
    this.props.paytoSubcribe({ "channel_id": this.props._uid }).then(() => {

      if (this.props._payment_link.is_paid === false) {
        Toast("Successfully Subscribed","success")
        this.setState({ loader: true })
        setTimeout(() => {
          window.location.reload()
        }, 2000);
        return
      }
      this.displayRazorpay(this.props._payment_link)
    })
  }


  async displayRazorpay(data) {
    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      Toast("Razorpay SDK failed to load. Are you online?","success");
      return;
    }

    const reload = () => {
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }


    const options = {
      key: data.key, // Enter the Key ID generated from the Dashboard
      amount: data.amount.toString(),
      currency: data.currency,
      name: data.name,
      description: data.description,
      image: null,
      order_id: data.order_id,
      handler: async function (response) {
        const data = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        Toast("Payment Suucessfull","sucess")
        reload()
      },
      prefill: data.prefill,
      notes: data.notes,
      theme: data.theme
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  onImageUploadChange(info){
    const reload = (info) => {
      const message = { m: this.state.text, u: info.file.response.data.url, f: info.file.name, s: info.file.size }
      this.setState({ imgMsgReady: message })
      // this.state.ws.send(JSON.stringify(message))
      // this.setState({ text: "" })
    }

    if (info.file.status === 'uploading') {
      this.setState({imgUploading: true})
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.setState({imgUploading: false});
      reload(info);
    } else if (info.file.status === 'error') {
      Toast(`${info.file.name} file upload failed.`,"error");
      this.setState({imgUploading: false});
    }
  }

  render() {
    
    const props = {
      name: 'file',
      accept: ".png,.jpg,.JPEG",
      action:  'account/file/upload',
      headers: {
        Authorization: Cookies.get("Logintoken"),
      },
      onChange: (info)=> this.onImageUploadChange(info),
      customRequest: (options) => request.customRequest(options, "file"),

    };

    // const props1 = {
    //   name: 'file',
    //   accept: ".pdf,.doc,.docx",
    //   action:  'account/file/upload',
    //   data: { type: "chat" },
    //   headers: {
    //    Authorization: Cookies.get("Logintoken"),                    
    //   },
    //   onChange(info) {
    //     if (info.file.status !== 'uploading') {
    //       console.log(info.file, info.fileList);
    //     }
    //     if (info.file.status === 'done') {
    //       reload(info)
    //     } else if (info.file.status === 'error') {
    //       Toast(`${info.file.name} file upload failed.`);
    //     }
    //   },
    // };




    const channel_chat = this.state.messages;
    const userCode = Cookies.get("user_code");
    // const findDocImgItesm = (item) => {
    //   return item[1] === 'IMG' && item[1] === 'DOC' && item[12] === this.state.filesFilter
    // }
    // const findDocItesm = (item) => {
    //   return item[1] !== 'IMG' && item[12] === this.state.filesFilter
    // }

    const findImgItesm = (item) => {
      return item[1] === 'IMG'
    }
    return (


      <Row gutter={10}>
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Row className=' cardv3-head mb-0 trending-card' style={{ padding: this.state.discussion ? "0px" : "", borderBottom: 0 }}>
            <Col lg={{ span: 24 }} xs={{ span: 24 }} className={this.state.discussion ? "border-card-chat p-0" : "chat"} style={{ height: "55vh" }}>

              {
                this.state.discussion && <Row>
                  <Col span={24} className="border-bottom channel-discussion-head">
                    <Row>
                      <Col xs={{ span: 15 }} lg={{ span: 16 }}>
                        <Button type="text" shape="circle" onClick={() => this.connectWS(this.state.discussionTopic)}
                          icon={<CaretLeft color="#FFFFFFB2" size={18} />}>

                        </Button>
                        <span className="fs16" style={{ color: "#fff", verticalAlign: "sub" }}>{this.state.discussionTopic[3]}</span>
                        <br />
                        <Space>
                          <p className="fs14" style={{ color: "#ffffff99", marginLeft: "20px" }}>{this.state.discussionTopic[2]}</p> <Badge status="default" color=" #ffffff99 " ></Badge>
                          <span className="fs14" style={{ color: "#ffffff99 " }}> {moment(this.state.discussionTopic[5]).format("h:mm a")}</span>
                        </Space>

                      </Col>
                      <Col xs={{ span: 9 }} lg={{ span: 8 }} className='text-right'>
                        <Space align="center" size={10} >
                          <span style={{ color: "#ffffff99" }}>Discussion</span>
                          <img src={require('../../assets/images/channel/discu.svg').default} alt="" />

                          <Button type="text" shape="circle" icon={<X color="#fff" size={18} />} onClick={() => this.connectWS(this.state.discussionTopic)}></Button>
                        </Space>
                      </Col>

                    </Row>
                  </Col>
                </Row>
              }
              <ScrollableFeed forceScroll={true} className="padding-bottom10">
                <Row >
                  {this.state.loader === true ? <Skeleton className="md200"></Skeleton> :
                    channel_chat.length !== 0 ?
                      channel_chat.map((item, index) => {
                        if (item[17] === 1) return <Col span={24} key={index}> <MessageDiscussCard oOpenDission={(item) => this.oOpenDission(item)} index={index} item={item} userCode={userCode} /> </Col>
                        else if (item[17] === 2) return
                        else if (item[1] === "TXT") return <Col span={24} key={index}> <MessageTextCard item={item} userCode={userCode} /> </Col>
                        else if (item[1] === "IMG") return <Col span={24} key={index}> <MessageImgCard item={item} userCode={userCode} /> </Col>
                        else if (item[1] === "DOC") return <Col span={24} key={index}> <MessageDocCard item={item} userCode={userCode} /> </Col>

                      })
                      : <div className="col-md-12" > <p className="md200 text-center">No Chat History Available</p> </div>
                  }
                </Row>
              </ScrollableFeed>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24} >
                  <Affix offsetBottom={0} >

                    <form className="p-2" onSubmit={(evt) => this.submitMessage(evt)} style={{ backdropFilter: "blur(8px)" }}>
                      <Row gutter={10} className={this.state.imgMsgReady || this.state.imgUploading ? 'chat-image-select-border':''}>
                        {
                          this.state.imgMsgReady !== null && <Col span={24} className="mb-2">
                            <Image ac preview={false} src={this.state.imgMsgReady.u} style={{ width: "auto", maxHeight: "50px", borderRadius: "10px" }}>
                              
                            </Image>
                            <Tooltip title="Remove image">
                            <Button onClick={()=>this.setState({imgMsgReady:null})} type="text" size="small" style={{verticalAlign:"top", marginLeft:"-20px", backgroundColor:"#ffffff6b"}} icon={<X size={15}/>}></Button>
                            </Tooltip>
                            
                          </Col>
                        }
                        {
                          this.state.imgUploading && <Col span={24} className="mb-2">
                            <Spin size="small">Uploading...</Spin>
                          </Col>
                        }
                        

                        <Col lg={18} xs={14} md={18}>

                          <Input
                            value={this.state.text}
                            className={this.state.imgMsgReady || this.state.imgUploading ? 'border-none recent-input':'recent-input'}
                            style={{ color: "black", fontSize: "20px" }}
                            onChange={(evt) =>
                              this.setState({ text: evt.target.value })}
                            cleanOnEnter
                            onEnter={(evt) =>
                              this.submitMessage(evt)}
                            placeholder={this.state.active ? "Discussion Message" : "Type Message"}
                          >
                          </Input>

                        </Col>
                        <Col lg={6} xs={10} md={6} className="chat-icons">
                          {
                            this.props.joined && !this.state.discussion &&
                            <Space>
                              {/* <Button type="text"> <SmileySticker size={22} weight="light" color="#000000" /></Button> */}
                              <Upload {...props} progress={false} disabled={!this.props.joined} showUploadList={false}>
                                <Tooltip title={'Select Image'}>
                                  <Button type='text' shape="circle" style={{ backgroundColor: '#fafafa' }}
                                    icon={<ImageUpl weight="light" color="#000000" size={22} />}>
                                  </Button></Tooltip>
                              </Upload>
                              <Tooltip title={'Select to start discussion'}>
                                <Button type='text' onClick={() => this.setState({ active: !this.state.active })} >
                                  {
                                    this.state.active ? <ChatCircleDots size={22} weight="fill" color="#000000" /> :
                                      <ChatCircleDots size={22} weight="light" color="#000000" />
                                  }
                                </Button></Tooltip>
                              {
                                this.props.joined === false ? <Button type="primary" onClick={() => this.paytoSubcribe()}> Join
                                </Button> : <Button type="text" htmlType={"submit"} style={{ padding: "0" }} icon={<PaperPlaneRight weight="fill" size={24} />}>

                                </Button>
                              }
                            </Space>
                          }
                        </Col>
                      </Row>
                      {/* <Col span={24}>
                        <Row>
                          <Col xs={{ span: 16 }} lg={{ span: 16 }} className='md10 mb-3'>

                            {
                              this.props.joined && !this.state.discussion &&
                              <Space className="ps-2 mb-2">
                                <Tooltip title={'Select to start discussion'}>
                                  <Button type='text' onClick={() => this.setState({ active: !this.state.active })} className={this.state.active ? "chat-icon-active" : 'chat-icon'}>
                                    <svg width="20" height="20" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.95841 0.125C3.18416 0.125 2.54175 0.767414 2.54175 1.54167V3.66667L0.416748 5.79167H3.95841H13.1667C13.941 5.79167 14.5834 5.14925 14.5834 4.375V1.54167C14.5834 0.767413 13.941 0.125 13.1667 0.125H3.95841ZM3.95841 1.54167H13.1667V4.375H3.95841V1.54167ZM3.95841 7.20833C3.18416 7.20833 2.54175 7.85075 2.54175 8.625V10.75L0.416748 12.875H3.95841H13.1667C13.941 12.875 14.5834 12.2326 14.5834 11.4583V8.625C14.5834 7.85075 13.941 7.20833 13.1667 7.20833H3.95841ZM3.95841 8.625H13.1667V11.4583H3.95841V9.33333V8.625Z" fill="#818181" />
                                    </svg>
                                  </Button></Tooltip>

                                <Upload {...props} progress={false} disabled={!this.props.joined} showUploadList={false}>
                                  <Tooltip title={'Select Image'}>
                                    <Button type='text' shape="circle" style={{ backgroundColor: '#fafafa' }}
                                      icon={<ImageUpl size={24} />}>
                                    </Button></Tooltip>
                                </Upload>

                              </Space>
                            }

                          </Col>

                          <Col xs={{ span: 8 }} lg={{ span: 8 }} className='text-right md10 pe-2 pb-3'>
                            {
                              this.props.joined === false ? <Button type="primary" className="md10" onClick={() => this.paytoSubcribe()}>Subscribe
                              </Button> : <Button type="primary" className="md10" htmlType={"submit"} style={{ padding: "0" }} icon={<PaperPlaneRight size={24} />}>

                              </Button>
                            }
                          </Col>

                        </Row>
                      </Col>  */}
                    </form>

                  </Affix>
                </Col>
              </Row>
            </Col>
          </Row>

        </Col>
        <Col className="gutter-row right-panel m-md25" lg={{ span: 8 }} xs={{ span: 0 }}>
          <Row>
            <Col lg={{ span: 24, offset: 0 }} xs={{ span: 22, offset: 1 }} className='channel_chat'>
              <Input placeholder="Search..." onChange={(evt) => this.getFiles(evt.target.value)} disabled={!this.props.joined} prefix={<SearchOutlined />} />
            </Col>
            <Col span={24} className="trending-card md20" style={{ height: "400px", overflowY: "scroll" }}>
              <Row className="md10">
                <Col span={15}>
                  <p className="fs13 fw600 md5"> Recent Files </p>
                </Col>
                {/* <Col span={9} className="text-right">
                  <Button type="primary"> see more </Button>

                </Col> */}

              </Row>
              <Row>
                {
                  this.state.searchData.length !== 0 &&
                  <Col span={24} className='md15 tabs-card'>
                    <Tabs defaultActiveKey="3">
                      <Tabs.TabPane tab="Messages" key="3">

                        {this.state.searchData.map((item) => {

                          if (item[1] === "TXT") {
                            return <Row className="pb-2 mb-2 border-bottom p-3">
                              <Col span={24}>
                                <p className="fs12 fw400" style={{ color: '#009A2B' }}>{item[2]}</p>
                                <p className="fs14 fw400 word-break" >{item[3]}</p>
                                <p className="fs10 fw400 text-grey">{moment(item[5]).fromNow()}</p>
                              </Col>
                            </Row>
                          }
                        })}


                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Images" key="1">
                        {this.state.searchData.map((item) => {

                          if (item[1] === "IMG") {
                            return <Row className="pb-2 mb-2 border-bottom p-3">
                              <Col span={18}>
                                <p className="fs12 fw400" style={{ color: '#6361C3' }}>{item[2]}</p>
                                <p className="fs14 fw400 word-break" >{item[6]}</p>
                                <p className="fs10 fw400 text-grey">{moment(item[5]).fromNow()}</p>
                              </Col>
                              <Col span={6} className="text-right">
                                <Image src={item[8]} style={{ height: "55px" }} className='m-chat-w70 m-text-ms20 chat-img-top img-fluid' alt="" />
                              </Col>

                            </Row>
                          }
                        })}
                      </Tabs.TabPane>

                    </Tabs>
                  </Col>
                }

                <Col span={24} className="md15 tabs-card">
                  {channel_chat.filter(findImgItesm).reverse().slice(0, 50).map((item, k) => {
                    if (item[8] !== null) {
                      return <Row key={k} className="pb-2 mb-2  p-3">
                        <Col span={18}>
                          <p className="fs12 fw400" style={{ color: '#6361C3' }}>{item[2]}</p>
                          <p className="fs14 fw400 word-break" >{item[6]}</p>
                          <p className="fs10 fw400 text-grey">{moment(item[5]).fromNow()}</p>
                        </Col>
                        <Col span={6} className="text-right">
                          <Image src={item[8]} style={{ height: "55px" }} className='m-chat-w70 m-text-ms20 chat-img-top img-fluid' alt="" />
                        </Col>
                      </Row>
                    }
                  })}
                </Col>
              </Row>
            </Col>


          </Row>
        </Col>
      </Row>


    );
  }
}

const mapStateToProps = (state) => ({
  _live_chat: state.channel.live_chat,
  _payment_link: state.channel.channel_payment
});

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (v) => dispatch(actions.sendMessage(v)),
  getPostChatMessageWebsocketData: (v) => dispatch(actions.getPostChatMessageWebsocketData(v)),
  paytoSubcribe: (v) => dispatch(actions.paytoSubcribe(v)),

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));
