import React, { Component } from "react";
import "../../assets/css/style.scss";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { Skeleton, Row, Col, Divider, Popover, Checkbox, Button, Radio } from "antd";
import NewsFeed from "../NewsFeed/NewsFeed";
import { Fragment } from "react";
import { Article, BookmarkSimple, Newspaper, NewspaperClipping, Note, Notebook, Sliders} from "@phosphor-icons/react"
import BlankState from "../BlankState";
import Tooltip from "antd/es/tooltip";



class MyBookmarked extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            results: [],
            loader: true,
            newsloader: true,
            market: [],
            active: 5,
            sort: "recent",
            entity: "",
            related: [], Filtertags: "",
            offset: 0, allCaught: false, loadMore: false
        };
    }

    async componentDidMount() {
        this.getSavedFeed();
    }

    async getSavedFeed(data) {
        const args = { sort: this.state.sort, entity: this.state.entity, offset: this.state.offset, ...data }
        await this.props
            .savedFeeds(args)
            .then(() => {
                let _oldData  = this.state.market
                let obj = {newsloader: false}
                if (this.props._allmarket.length < 10) {
                    obj['allCaught'] = true
                    obj['loadMore'] = false
                }
                else {
                    obj['allCaught'] = false
                    obj['loadMore'] = true
                }
                if (args.offset > 0) {
                    this.props._allmarket.map((item) => {
                        return _oldData.push(item)
                    })
                    obj['market'] = _oldData;
                }else{
                    obj['market'] = this.props._allmarket;
                }
                this.setState(obj)
            });
    }

    async filterBookmarks(entity = this.state.entity, sort = this.state.sort) {
        this.setState({ newsloader: true, entity: entity, sort: sort});
        this.getSavedFeed({ entity: entity, sort: sort, offset: 0 });
    }

    loadMore() {
        let offset = 10 + this.state.offset
        this.setState({ offset: offset});
        this.getSavedFeed({offset: offset});
    }

    removeObjectAfterUnSaved(id){
        const items = [...this.state.market]
        this.setState({market:  items.filter((obj) => obj.entity_id !== id)});
    }




    async getFilterByTags(tag, media, author) {
        this.setState({
            newsloader: true
        })
        let domain = "/market/feed/all?entity=" + this.state.active + "&tags=" + tag + "&media=" + media + "&author=" + (author !== "" ? author.user_code : "")
        this.props
            .allMarketNewsData(domain)
            .then(() => {
                this.props._allmarket.map((item) => {
                    item.collapsed = false
                })

                this.setState({
                    market: this.props._allmarket,
                    newsloader: false,
                    Filtertags: tag || media || author?.name
                });
            });
    }


    reload(id, key, type) {
        if (key === true) {
            this.props.relatedTopics({ id: id, type: type }).then(() => {
                this.setState({
                    related: this.props._related
                })
            })
        }
        else {
            this.setState({ ...this.state })
        }
    }


    details(record) {
        window.location.href = "/feed-view/" + record?.entity_uuid
    }


    render() {
        const content = (
            <><div className="feed-filter">
                <Checkbox.Group style={{ width: "100%" }} onChange={(e) => this.filterBookmarks(e)}>

                    <div className="checkbox fs14 fw400"><Checkbox value={"1"}> <NewspaperClipping size={24} /> News </Checkbox></div>
                    <div className="checkbox fs14 fw400"><Checkbox value={"3"}> <Article size={24} /> Posts </Checkbox></div>
                    <div className="checkbox fs14 fw400"><Checkbox value={"2"}> <Newspaper size={24} /> Newsletters </Checkbox></div>
                    <div className="checkbox fs14 fw400"><Checkbox value={"4"}> <Notebook size={24} />  Report </Checkbox></div>
                    <div className="checkbox fs14 fw400" ><Checkbox value={"10"}> <Note size={24} />  Notes </Checkbox></div>

                </Checkbox.Group>

                <Divider className="my-2"></Divider>
            </div>
                <div className="feed-filter">
                    <p className="fs14 fw400 text-grey"> Sort By </p>
                    <Radio.Group
                        defaultValue={"recent"} style={{ display: "inline-flex", marginBottom: "6px" }}
                        buttonStyle="solid"
                        className="md10"
                        onChange={(evt) => this.filterBookmarks(this.state.entity, evt.target.value)} >

                        <Radio.Button
                            value={"recent"}
                            className="margin-right"
                            style={{ width: "max-content" }} >
                            Recent  </Radio.Button>
                        <Radio.Button
                            value={"old"}
                            className="margin-right"
                            style={{ width: "max-content" }} >
                            Old  </Radio.Button>

                        {/* <Radio.Group className="md10">
                       
                            <Radio value={1}>Recent
                            </Radio>
                            <Radio value={2} >
                                Old
                            </Radio> */}


                    </Radio.Group>
                </div ></>
        );

        return (<>
            <Row>
                <Col xxl={{ span: 10, offset: 7 }} xl={{ span: 10, offset: 7 }} lg={{ span: 14, offset: 5 }} md={{ span: 12, offset: 6 }} xs={{ span: 24, offset: 0 }}>
                    <Row>
                        <Col span={24} className="md30 m-px28" >
                            <Row className="md10">
                                <Col span={18}>
                                    <h4 className='fs16 mb-0' style={{ color: 'black' }}> <BookmarkSimple size={29} weight="fill" /> Saved </h4>
                                </Col>

                                <Col span={6} className='text-right' style={{ paddingRight: "10px" }}>
                                    <Tooltip title="Filter" >
                                        <Popover content={content} placement="rightBottom" trigger={"click"} title=" Filter" className="popover-text" overlayClassName="main-feed-filter">
                                            <Button className="m-filtersize" size="middle" type={"text"}
                                                icon={<Sliders size={17} color="#4B5669" />} > </Button>
                                        </Popover>
                                    </Tooltip>
                                </Col>
                                <Col span={24}>
                                    <Divider className="mt-3 mb-0"></Divider>
                                </Col>

                                {/* {this.state.Filtertags !== "" && <Col span={24}> <p><Space size="small" align="center"> Tags : <span className="tags-round">#{this.state.Filtertags} <span><X className="cursor" style={{ marginLeft: "5px" }} onClick={() => this.setState({ Filtertags: "", newsloader: true }, () => this.componentDidMount())}></X></span>  </span>  </Space> </p></Col>} */}

                                <Col span={24}>
                                    {this.state.newsloader ? (
                                        <Fragment className="md10">
                                            <Skeleton
                                                className="md20"
                                                avatar
                                                active
                                                paragraph={{ rows: 4 }}
                                            />
                                            <Skeleton
                                                className="md20"
                                                avatar
                                                active
                                                paragraph={{ rows: 4 }}
                                            />
                                            <Skeleton
                                                className="md20"
                                                avatar
                                                active
                                                paragraph={{ rows: 4 }}
                                            />
                                        </Fragment>
                                    ) : this.state.market.length === 0 ? <>

                                        <div className="md30">
                                        <BlankState img={require("../../assets/images/blank/no-data-found.svg").default} height={250} />
                                        </div>

                                    </> : (
                                        <>
                                            <NewsFeed
                                                id={"scrollableDiv56"}
                                                Filtertags={this.state.Filtertags}
                                                data={this.state.market}
                                                related={this.state.related}
                                                loadMore={this.state.loadMore}
                                                loadMoreData={() => this.loadMore()}
                                                getFilterByTags={(tag, media, author) => this.getFilterByTags(tag, media, author)}
                                                showLike={false}
                                                removeObjectAfterUnSaved={(id) => this.removeObjectAfterUnSaved(id)}
                                                details={(record) => this.details(record)}
                                                height={"calc(100vh - 158px)"}
                                            />


                                        </>
                                    )}
                                </Col>

                            </Row>

                        </Col>

                    </Row>
                </Col>

            </Row>

        </>
        );
    }
}

const mapStateToProps = (state) => ({
    _allnewsletter: state.newsletter.allnewsletter,
    _allmarket: state.newsletter.all_market,
    _related: state.results.related,
});
const mapDispatchToProps = (dispatch) => ({
    allMarketNewsData: (v) => dispatch(actions.allMarketNewsData(v)),
    relatedTopics: (v) => dispatch(actions.relatedTopics(v)),
    savedFeeds: (v) => dispatch(actions.savedFeeds(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MyBookmarked)
);
