import { Layout, Menu, Row, Col, Button, Space } from 'antd';
import React, { useState } from 'react';
import { Link } from "react-router-dom"
import { useEffect } from 'react';
import { Compass, House, GraduationCap, Television, GameController, Gift } from "@phosphor-icons/react";
import "./style.scss"
import AppHeader from '../Header/Header';
import axios from '../../store/axios';  
import SkeletonAvatar from 'antd/lib/skeleton/Avatar';
import Avatar from 'antd/lib/avatar/avatar';
import { CAMPUS_WEB } from '../../utils';

const { Header, Content, Sider } = Layout;
var _ = require("lodash");



const PublicLayout = ({ children, token }) => {
    const [loader, setLoader] = useState(false)
    const [active, setActive] = useState("")
    const [items, setItems] = useState(localStorage.getItem("channels") && JSON.parse(localStorage.getItem("channels")) || []);


    useEffect(() => {
        let menu = localStorage.getItem("channels") && JSON.parse(localStorage.getItem("channels"))
        if (token && (menu === null || menu === undefined)) {
            setLoader(true);
            axios({
                method: 'get',
                url:  "channel/list/creator?id=",
                headers: {
                    Authorization: token,

                }
            }).then(res => {
                const data = res.data.data
                setItems(_.cloneDeep(data))
                setLoader(false)
                localStorage.setItem("channels", JSON.stringify(data))
            })
        }
        else {
            setLoader(false)
        }
    }, [])


    useEffect(() => {
        let url = window.location.href.split("/")

        if (url[3] === "dashboard") {
            setActive("1")
        }
        if (url[3] === "explore") {
            setActive("2")
        }
        if (url[3] === "live-talks") {
            setActive("3")
        }

        if (url[3] === "my-hub") {
            setActive("4")
        }
        if (url[3] === "bookmarked") {
            setActive("5")
        }
        if (url[3] === "channel") {
            setActive(url[4])
        }
        if (url[3] === "reels") {
            setActive("7")
        }
        if (url[3] === "tv") {
            setActive("8")
        }
    })


    return (
        <Layout >

            <Sider style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,

            }} collapsed={true} className='nav-bg m-hide'>
                <div className="logo" />
                {
                    loader ? <SkeletonAvatar></SkeletonAvatar> :
                        <Menu theme="light" className='sidebar-menu ' tooltip={false} placeholder={false} selectedKeys={[active]} onClick={(evt) => setActive(evt?.key)} mode="vertical"  >
                            <Menu.Item key="1" icon={<House size={30} weight={active === "1" ? "fill" : "thin"} />}>
                                <Link to={"/"}>  Dashboard </Link>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<Compass size={30} weight={active === "2" ? "fill" : "thin"} />}>
                                <Link to={"/explore"}>Explore</Link>
                            </Menu.Item>
                          
                         
                            <Menu.Item key="4" icon={<GraduationCap size={30} weight={active === "4" ? "fill" : "thin"} />}>
                                {/* <Link to={{ pathname:  }}>Campus</Link> */}
                                <a href={`${CAMPUS_WEB}`}>Campus</a>
                            </Menu.Item>

                            {/* {
                                token && <Menu.Item key="5" icon={<BookmarkSimple size={30} weight={active === "5" ? "fill" : "thin"} />}>
                                    <Link to={"/bookmarked"}>Bookmarked</Link>
                                </Menu.Item>
                            } */}


                            {/* <Menu.Item key="7" icon={<Radio size={30} weight={active === "7" ? "fill" : "thin"} />}>
                                <Link to={"/reels/view"}>Reels</Link>
                            </Menu.Item> */}
                            {
                                token && <Menu.Item key="8" icon={<Television size={30} weight={active === "8" ? "fill" : "thin"} />}>
                                    <Link to={"/explore/tv"}>Syllo TV</Link>
                                </Menu.Item>
                            }

                            {

                                token && <Menu.Item key="9" icon={<Gift size={30} weight={active === "9" ? "fill" : "thin"} />} >
                                    <Link to={"/rewards"}>Rewards</Link>
                                </Menu.Item>
                            }

                            {
                                token && <Menu.Item key="6" icon={<GameController size={30} weight={active === "6" ? "fill" : "thin"} />} onClick={() => window.location.open("https://games.syllo.co/dashboard")}>
                                    Game
                                </Menu.Item>
                            }

                            <hr></hr>
                            {
                                token && items.slice(0, 3).map((item) => {
                                    return <Menu.Item key={item.uid} icon={<Avatar className="bordered" size={35} shape="circle" src={item.img_url}>  </Avatar>}>
                                        <Link to={`/channel/${item.uid}/${item.channel_id}`} >{item.name}</Link>
                                    </Menu.Item>
                                })
                            }
                            {
                                token && items.length >= 3 && <p className={"text-center"}><small><Link to={"/my-hub"}>View All</Link></small></p>
                            }
                        </Menu>
                }

            </Sider>
            <Layout className="site-layout" style={{ marginLeft: 80, backgroundColor: "#f0f0f0" }}>
                <Header
                    style={{
                        padding: 0,
                        zIndex: "10000"
                    }}
                >
                    {
                        token ? <AppHeader left={true} right={true} showBorder={true} /> : <Row style={{ backgroundColor: "#f0f0f0" }}>
                            <Col span={12} className="ps-2"  >
                                <img src={require("../../assets/images/Desktop/syllo.svg").default} alt="logo" width={"16%"} className={"m-logo"} style={{ marginTop: "2px" }}></img>
                            </Col>
                            <Col span={12} className={"text-right"}>
                                <Space> <Button type='ghost' href={'/login?redirect=' + window.location.pathname}>Login</Button>
                                    <Button type='primary' className='text-white' href='/sign-up'>Get Started</Button> </Space>
                            </Col>
                        </Row>
                    }
                </Header>
                <Content
                    style={{
                        margin: '0 10px 0px 10px',
                        backgroundColor: "white",
                        borderRadius: "10px",
                        minHeight: "100vh"
                    }}
                >
                    {children}
                </Content>

            </Layout>
        </Layout>
    );
};
export default PublicLayout;