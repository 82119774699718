import React from "react";
import { Row, Col, Card, Button, Avatar } from 'antd';

const GameBody = ({ item, details }) => {
    return <Row>
        <Col span={24} className="pool-card">
            <Card className="game-card2" cover={
                <div className="card-cover" style={{ backgroundImage: `url(${item?.game?.img_url})` }} >
                    <img alt="example" src={item?.game?.img_url} />
                </div>}
            >
                <Row className="padding15">
                    <Col lg={{ span: 24 }} xs={{ span: 24 }}>
                        <Row>
                            <Col lg={{ span: 20 }} xs={{ span: 18 }}>
                                <h6 > {item?.title} </h6>
                                <p classname="fs14" style={{ color: "#728096" }}>{item?.game?.category}</p>
                            </Col>
                            <Col lg={{ span: 4 }} xs={{ span: 6 }} className='text-right'>
                                {

                                }
                                <Button type="primary" className="text-white" href={`https://games.syllo.co/game-details/${item?.game?.gamify_id}`} target={"_blank"} > {item?.game?.played ? "Result" : "Play"}</Button>

                            </Col>
                        </Row>
                    </Col>
                    <Col lg={{ span: 24 }} xs={{ span: 24 }}>
                        <Row>
                            <Col lg={{ span: 10 }} xs={{ span: 8 }}>
                                <p classname="fs12" style={{ color: "#728096" }}>{item?.game?.playing_count} Plays</p>

                            </Col>
                            <Col lg={{ span: 14 }} xs={{ span: 16 }} className="text-right paddingRight10 ">
                                <Avatar.Group maxCount={3} className='' maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', marginTop: '1px', }}>
                                    {item?.game?.playing_imgs.map((_item) => {
                                        return <Avatar src={_item} />
                                    })}
                                </Avatar.Group>
                            </Col>
                        </Row>

                    </Col>

                </Row>
            </Card>
        </Col>
    </Row>
}
export default GameBody;