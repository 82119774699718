import React from "react";
import { Row, Col, Button, Input, Image } from "antd";
import '../../components/Channel/style.scss'

const PodcastClaim = ({ podcast_data }) => {

    function finance(e) {
        e.finance();

    }
    return (
        <>
            <Row>
                <Col span={18} offset={3} className='md35'>
                    <Row className="mb-2">
                        <Col span={24} className="upload-podcastv3">
                            <p className="text-center fs18 pt-4 pb-4" style={{ color: 'white', height: '70px' }}> Podcast Claim</p>
                        </Col>
                        <Col span={20} offset={2}>

                            <p className="pt-3 ps-3 podcast-color fw-bold fs16" >{podcast_data?.title}</p>
                            <p className="ps-3">By {podcast_data?.author_detail?.name}</p>
                            <p className="p-3 podcast-color"> {podcast_data?.subtitle}</p>

                        </Col>
                        <Col span={6} className='text-center'>
                            <Image src={podcast_data?.image?.href} preview={false} style={{ border: '1px solid grey' }} className={"md20"}></Image>
                        </Col>

                        <Col span={24} className='mt-5'>
                            <h5 className="fs18" style={{ color: '#474747' }}>Verify that you own this podcast</h5>
                            <p className="podcast-color">We’ll send an 8-digit code to the email address associated with your RSS feed.</p>
                        </Col>

                        <Col span={24} className='mt-4'>
                            <span className="podcast-color fw-bold">{podcast_data?.author_detail?.email}</span>
                            <Button type="primary" className=" ms-5 send-code-btn fs16 fw-normal"> Send Verification Code</Button>
                        </Col>
                        <Col span={8} className='md40'>
                            <Input placeholder="Enter Code" className="fs16" />
                        </Col>

                        <Col span={24}>
                            <p className="mt-4 fs16 fw-normal" style={{ color: '#8E8E8E' }}>If you don’t recognize the email address, please contact <strong>support@yuja.one.</strong> Include the name of your podcast, the link to your <br /> RSS feed and the email address of your Yuja account.</p>
                        </Col>
                        <Col span={24} className='text-right'>
                            <Button className='verify px-4 p-1 mt-5 ' type="" size={"default"}>Next</Button>
                        </Col>

                    </Row>
                </Col>
            </Row>

        </>

    );
}
export default PodcastClaim;