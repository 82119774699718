
import React from "react";
import { Col, Row, Image } from "antd";
import moment from "moment";

const MessageImgCard = ({  item, userCode }) => {

    return <Row className="md20">
        <Col span={24} xs={{span: 24}}  >
            {
                item[4] === userCode ?
                    <div className="Text-message-right chat-message-me">
                        <Row gutter={5}>
                            {/* <Col lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }} >
                                <h5 style={{ color: item[11], fontSize: "16px", fontWeight: "500" }}>{item[2]}</h5>
                                
                            </Col> */}
                            <Col lg={{ span: 24, offset:0 }} xs={{ span: 24, offset: 0 }}  className="md10"  >
                                <Image preview={false} src={item[8]} style={{ width: "100%", maxHeight: "400px", borderRadius: "10px" }}></Image>
                            </Col>
                            
                            <Col span={24} >
                            <p className="sub-heading-text word-break md5"> {item[3] !== "" ? item[3] : item[6]}</p>
                                <p className="fs12 text-grey">{parseFloat(item[9] / 1024).toFixed(2)} Kb</p>
                                {/* {item[12] !== null && <p> 
                                    <span className="cursor margin-right  tags-round cursor">{"#" + item[12]}</span></p>} */}
                                <p className="text-right md5"><small className="grey-text fs11">
                                    {" "}
                                    {moment(item[5]).format("h:mm a")}
                                </small></p>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div className="Text-message-left  chat-message">
                        <Row gutter={5}>
                            <Col span={24} >
                                <h5 style={{ color: item[11], fontSize: "16px", fontWeight: "500" }}>{item[2]}</h5>
                                
                            </Col>
                            <Col span={24} className="md10">
                                <Image preview={false} src={item[8]} style={{ width: "100%", maxHeight: "400px", borderRadius: "10px" }} ></Image>
                            </Col>
                            <Col span={24} >
                            <p className="sub-heading-text word-break md5"> {item[3] !== "" ? item[3] : item[6]}</p>
                                <p className="fs12 text-grey">{parseFloat(item[9] / 1024).toFixed(2)} Kb</p>
                                {/* {item[12] !== null && <p> <span className="cursor margin-right  tags-round cursor">{"#" + item[12]}</span></p>} */}
                                <p className="text-right md-10"><small className="grey-text fs11">
                                    {" "}
                                    {moment(item[5]).format("h:mm a")}
                                </small></p>
                            </Col>

                        </Row>


                    </div>
            }
        </Col>
    </Row>
}

export default MessageImgCard;