import React from 'react';
import { Row, Col, Divider, Avatar, Tag, Button, Dropdown, Skeleton, Typography, Space } from 'antd';
import moment from 'moment';
import { Dot, DotsThreeVertical, Export, Trash } from "@phosphor-icons/react";
import BlankState from '../BlankState';
import PodcastPlay from '../Podcastv2/PodcastPlay';
import { RWebShare } from 'react-web-share';
import Toast from "../utils/Toast";


const MyHubPodcast = ({ data, loader, deleteFeed }) => {


    const onClick = async ({ key, id }) => {

        if (key === '2') {
            await deleteFeed(id);
            Toast('Deleted!', "error")
        }
    };

    const items = (item) => {

        return [

            {
                key: '1',
                icon:
                    <RWebShare
                        data={{
                            text: "Syllo: Note",
                            url: `${process.env.REACT_APP_URL}feed-view/${item.entity_uuid}`,
                            title: item?.title,
                        }}

                    ><span><Export size={18} color="#000000" weight="thin" className="me-2" /> Share</span>
                    </RWebShare>
            }, {
                label: (
                    <span className=" fw400"> Delete</span>
                ),
                key: '2',
                icon: <Trash size={18} color="#686464" weight="light" />

            }

        ]
    };

    return (

        <Row >

            {
                loader ? <Skeleton></Skeleton> :
                    data.length === 0 ? <Col span={24} className="md100 text-center">
                        <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} />

                    </Col> :

                        data.map((item, i) => {
                            return <Col key={i} span={24} className='mb-3'>
                                <Row className='mb-1'>
                                    <Col md={18} xs={17} >
                                        <Space align='center'>
                                            <Tag color="cyan">Podcast</Tag>
                                            <div className="dot-small"></div>
                                            <p style={{ color: 'background: #868686' }} > {moment(item.created_at).format("Do MMM YYYY")} </p>
                                        </Space>

                                    </Col>
                                    <Col md={6} xs={7} className="text-right">
                                        <div className="">
                                            {
                                                <Dropdown trigger={['click']} menu={{ items: items(item), onClick: ({ key }) => onClick({ key: key, id: item.entity_uuid }) }} placement={"bottom"} overlayStyle={{ minWidth: "120px" }}>
                                                    <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" weight='bold'/>} />
                                                </Dropdown>
                                            }

                                        </div>
                                    </Col>
                                </Row>
                                <PodcastPlay item={item} />
                                {/* <Row className='mt-3'>
                                    <Col md={3} xs={6}>
                                        <Avatar size={65} src={item.image_url} style={{ borderRadius: '6px' }}></Avatar>
                                    </Col>
                                    <Col md={21} xs={18}>

                                        <Row>
                                            <Col span={20}>
                                                <h6 className='cursor' onClick={() => details(item)}>{item.title}</h6>
                                                <Paragraph ellipsis={{
                                                    rows: 2, expandable: true, symbol: 'more'
                                                }} className="post-heading-text md10 fwnormal fs14 desc" >{item.description} </Paragraph>
                                            </Col>
                                            <Col span={4} className="text-right pt-3 show-on-channel-hover">
                                                <Tooltip placement="top" title={"Delete"}>
                                                    <Button type={'text'} onClick={() => deleteFeed(item.entity_uuid)} icon={<div className="ptn-box centered justify-center mb-0" style={{ width: "28px", float: "unset" }}>
                                                        <Avatar size={24} src={require('../../assets/images/channel/icon-delete.png')}></Avatar>
                                                    </div>}>
                                                    </Button>
                                                </Tooltip>

                                            </Col>
                                            <Col md={9} xs={18} className="hub-podcast">
                                                <Row style={{ width: '100%' }}>
                                                    <Col span={5} className="" style={{ alignSelf: "center" }}>
                                                        <Avatar size={35} src={require('../../assets/images/playbutton.png')}></Avatar>

                                                    </Col>
                                                    <Col span={7}  >
                                                        <p style={{ marginTop: '8px' }}>2.3 min </p>

                                                    </Col>
                                                    <Col span={12} >
                                                        <p style={{ marginTop: '8px', color: '#000000', fontWeight: '600', textAlign: 'right' }} >First Episode</p>

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Divider></Divider>
                                    </Col>
                                </Row> */}
                            </Col>

                        })
            }


        </Row>
    );
}

export default MyHubPodcast;
