import { Avatar, Button, Col, Divider, Row, Space, Skeleton, Tabs, Menu, Dropdown } from "antd";
import Search from "antd/lib/input/Search";
import { Microphone, Television } from "@phosphor-icons/react";
import React from "react";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BlankState from "../BlankState";
import axios from "../../store/axios";





const SearchTV = () => {

    const [key, setKey] = useState("")
    const [allTv, setAllTv] = useState([]);
    const [searchTv, setAllSearchTv] = useState([]);
    const [allLive, setAllLive] = useState([]);
    const [moreloader, setmoreloader] = useState(false)
    const [loader, setLoader] = useState(true)
    const [loadMore, setLoadMore] = useState(false)
    const [loadMoreLive, setLoadMoreLive] = useState(false)
    const [offset, setOffset] = useState(0)
    const [result, setResult] = useState(false)
    const [activeTab, setActiveTab] = useState("1")
    const history = useHistory();

    const onSearch = async () => {
        setResult(false)
        const config = {
            method: 'GET',
            url: `v1.0/tv/explore?count=10&key=${key}&offset=0`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            },
        }
        await axios(config).then((res) => {
            const __data = res.data.data
            setAllSearchTv(__data)
            setResult(true)
        }).catch(() => {

        })
    };

    useEffect(() => {
        getAllTV(false);
        getAllLive(false)
    }, [])


    const getAllTV = async (more) => {
        if (more) {
            setmoreloader(true)
        }
        const config = {
            method: 'GET',
            url: `v1.0/tv/explore?count=10&key=${key}&offset=${offset}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            },
        }

        await axios(config).then((res) => {
            const __data = res.data.data
            if (__data && __data.length >= 10) {
                setLoadMore(true)
                setOffset(offset + 10)
            }
            else {
                setLoadMore(false)

            }
            let _data = allTv
            setAllTv(_data.concat(__data))
            setLoader(false)
            setmoreloader(false)
        }).catch((error) => {

        })
    }


    const getAllLive = async (more) => {
        if (more) {
            setmoreloader(true)
        }
        const config = {
            method: 'GET',
            url: `v1.0/stream/live?count=10&offset=${offset}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            },
        }

        await axios(config).then((res) => {
            const __data = res.data.data
            if (__data && __data.length >= 10) {
                setLoadMoreLive(true)
                setOffset(offset + 10)
            }
            else {
                setLoadMoreLive(false)

            }
            let _data = allTv
            setAllLive(_data.concat(__data))
            setLoader(false)
            setmoreloader(false)
        }).catch(() => {

        })
    }

    const menu = (
        <Menu
            items={
                searchTv.length === 0 ? [] : searchTv.map((item) => {
                    return {
                        key: item?.id,
                        label: (
                            <a rel="noopener noreferrer" href={(`/tv/${item?.user_code}`)}>
                                <Avatar src={item?.img_url}></Avatar> {item?.fname}
                            </a>
                        )
                    }
                })
            }
        />
    );

    return (
        <Row style={{ backgroundColor: '#fafafa' }}>
            <Col span={24} className='tv-bg text-center'>
                <Space className="md50" >
                    <Television size={28} weight="light" color="#FFFFFF" />
                    <span className="fs24 fw700 text-white"> Syllo  </span>
                    <span className="fs24 fw700 blue-text"> TV </span>
                </Space>
            </Col>
            <Col span={24}>
                <Row >
                    <Col lg={{ span: 10, offset: 7 }} onMouseLeave={() => setResult(false)} xs={{ span: 22, offset: 1 }} xxl={{ span: 8, offset: 8 }} className='learner-top-card '>
                        <Dropdown overlay={menu} open={result} destroyPopupOnHide>
                            <Search
                                className="search-input"
                                placeholder="Search"
                                allowClear
                                onChange={(value) => setKey(value.target.value)}
                                enterButton={key === "" ? null : undefined}
                                size="large"
                                onSearch={onSearch}
                            ></Search>
                        </Dropdown>

                        <Divider className="my-2"></Divider>
                        <Space size={20}>
                            <a href="/explore/tag?tag=mutualfund" className="link-dark">#mutualfund</a>
                            <a href="/explore/tag?tag=insurance" className="link-dark">#insurance</a>
                            <a href="/explore/tag?tag=loan" className="link-dark">#loan</a>
                        </Space>

                    </Col>
                </Row>
            </Col>

            <Col md={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 16, offset: 4 }} style={{ minHeight: "calc(67vh)" }}>
                <Row>
                    <Col md={{ span: 22, offset: 1 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 20, offset: 2 }} className='md30 mb-5'>
                        <Row>
                            <Col lg={{ span: 24 }} xs={{ span: 24, offset: 0 }} className="">
                                <p className="text-grey"> Syllo TV offers Live streaming & Broadcasting features, alongside various others benefits. Recording
                                    and publishing live streams has never been easier. With few steps, you can set-up your personal TV
                                    channel, and avail all of our benefits. </p>

                            </Col>
                            <Col lg={{ span: 24 }} xs={{ span: 24, offset: 0 }} className=''>
                                <Divider className="my-3" />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 24 }} xs={{ span: 24, offset: 0 }}>
                                <Tabs defaultActiveKey={activeTab}
                                    onChange={(evt) => setActiveTab(evt)}
                                    tabBarExtraContent={
                                        activeTab === "1" ?
                                            <Col lg={{ span: 24 }} xs={{ span: 24, offset: 0 }} className='text-end'>
                                                <Button onClick={() => history.push("/tv")} type="dashed" className=""> Manage My TV</Button>
                                            </Col> : <Col lg={{ span: 24 }} xs={{ span: 24, offset: 0 }} className='text-end'>
                                                <Button href={"/go-live/me"} type="primary" className="" icon={<Microphone size={22} color="white" weight="fill" style={{marginRight:"5px"}} />}> Go Live</Button>
                                            </Col>} items={[
                                                {
                                                    label: 'Syllo TV',
                                                    key: '1',
                                                    children:
                                                        <Row gutter={16} >
                                                            {loader ? <Skeleton></Skeleton> :
                                                                allTv.map((item, i) => {
                                                                    return <Col key={i} xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: 8 }} className='md25 cursor' onClick={() => history.push(`/tv/${item?.user_code}`)}>
                                                                        <div className="TV-search-card  ">
                                                                            <Avatar src={item.img_url} size={60} />
                                                                            <p className="fs16 fw500 md25 "> {item?.fname} {item?.lname} <span className="fs16 fw500 blue-text  ">  TV </span> </p>

                                                                        </div>
                                                                    </Col>
                                                                })
                                                            }
                                                            {
                                                                moreloader && <Skeleton></Skeleton>
                                                            }

                                                            {
                                                                loadMore && <Col span={24} className={"md20 text-center"}>
                                                                    <Button onClick={() => getAllTV(true)}>Load More</Button>
                                                                </Col>
                                                            }
                                                        </Row>,
                                                },
                                                {
                                                    label: 'Live',
                                                    key: '2',
                                                    children: <Row gutter={16} >
                                                        {loader ? <Skeleton></Skeleton> : <>{
                                                            allLive.map((item, i) => {
                                                                return <Col key={i} xs={{ span: 24 }} md={{ span: 12 }} xxl={{ span: 8 }} className='md25 cursor' onClick={() => history.push(`/go-live/${item?.channel_name}`)}>
                                                                    <div className="TV-search-card  ">
                                                                        <Avatar src={item?.streamer?.avatar_url} size={60} />
                                                                        <p className="fs16 fw500 md25 "> {item?.streamer?.full_name}  <span className="fs16 fw500 blue-text  ">  TV </span> </p>

                                                                    </div>
                                                                </Col>
                                                            })
                                                            }

                                                            {
                                                                allLive.length === 0 && <Col span={24} className="md60">
                                                                <BlankState img={require("../../assets/images/blank/go-live.svg").default} height={250} />
                                                            </Col>
                                                            }
                                                            </>


                                                        }
                                                        {
                                                            moreloader && <Skeleton></Skeleton>
                                                        }

                                                        {
                                                            loadMoreLive && <Col span={24} className={"md20 text-center"}>
                                                                <Button onClick={() => getAllLive(true)}>Load More</Button>
                                                            </Col>
                                                        }
                                                    </Row>,
                                                }
                                            ]}>

                                </Tabs>
                            </Col>

                        </Row>

                    </Col>
                </Row>
            </Col>
        </Row>


    );
}
export default SearchTV;