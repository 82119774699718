import React, { useEffect, useState } from "react";
import { Button, Input, message, Space, Spin } from "antd";
import "./Login.scss";
import "./login-responsive.scss";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter, useHistory } from "react-router";
import { useGoogleLogin } from '@react-oauth/google';
import Cookies from "js-cookie";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { CAMPUS_WEB } from "../../utils";



const SignIN = (props) => {
  const [loader1, setLoader1] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  const [messageApi, contextHolder] = message.useMessage();
  const key = 'login';


  useEffect(() => {
    const response = props._login;
    localStorage.removeItem("current");

    if (response.length !== 0) {
      if (response?.account_step === "_onboard") {
        history.push("/onboard", {
          verified: false,
          is_preferanced: response?.is_preferanced
        });
      }
      else if (response?.account_step === "_onboard.c") {
        history.push("/onboard/creator");
      }
      else if (response?.account_step === "_onboard.b") {
        history.push(`/onboard/business/${response?.role}`);
      }
      else if (response?.is_preferanced === false) {
        history.push("/preference", {
          verified: false,
        });
      } else {
        sessionStorage.setItem("active", response?.is_email_verified)
        Cookies.set("channels", "false")
        let path = "/dashboard"
        const params = new URL(window.location.href).searchParams;
        let redirectTo = params.get('redirect');
        if (redirectTo !== null && redirectTo !== "" && redirectTo !== "/" && redirectTo !== "/sign-up") {
          path = redirectTo
        }
        
        localStorage.removeItem('redirect');
        if (path.includes(CAMPUS_WEB) || path.includes("https://games.syllo.co")) {
          window.location.href = path
        }
        else {
          history.push(path);
        }
      }
    }
  }, [props._login])


  const login = (evt) => {
    evt.preventDefault();
    props
      .Login({
        email: email,
        password: password
      })
      .then(() => {
        let route = "/account/user/profile"
        props.Profile(route).then(() => {
          localStorage.setItem("profile", JSON.stringify(props._profile));
        });
      });
  }

  useEffect(() => {

    if (props._login_social !== null) {
      const response = props._login_social
      messageApi.open({
        key,
        type: 'success',
        content: 'Signed in!',
        duration: 3
      });
      setLoader1(false)

      if (response?.account_step === "_onboard") {
        history.push("/onboard", {
          verified: false,
          is_preferanced: response?.is_preferanced
        });
      }
      else if (response?.is_preferanced === false) {
        history.push("/preference", {
          verified: false,
        });
      }
      else {
        sessionStorage.setItem("active", response?.is_email_verified)
        Cookies.set("channels", "false")
        let path = "/dashboard"
        const params = new URL(window.location.href).searchParams;
        let redirectTo = params.get('redirect');
        if (redirectTo !== null && redirectTo !== "" && redirectTo !== "/sign-up") {
          path = redirectTo
        }
        localStorage.removeItem('redirect');
        if (path.includes(CAMPUS_WEB) || path.includes("https://games.syllo.co")) {
          window.location.href = path
        }
        else {
          history.push(path);
        }

      }

    }

  }, [props._login_social])

  const handleLogin = async (credentialResponse) => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Signing...',
    });

    const payload = {
      code: credentialResponse.code
    }
    await props.LoginSocial(payload);
    messageApi.open({
      key,
      type: 'error',
      content: 'Signin failed',
      duration: 2
    });

  }
  const loginGoogle = useGoogleLogin({
    onSuccess: handleLogin,
    flow: "auth-code",
    ux_mode: "popup",
    select_account: true,
    scope: "email profile",
  });




  return (
    <div className="row background-login height100vh">

      <div className="col-lg-4 col-md-0 col-ipro-2">

      </div>
      <div className="col-lg-4 col-sm-7 col-ipro-8 border-card m-0 md50" style={{ height: '600px', maxWidth:'435px' }}>
        <div className="row m-padding20">
          <div className="col-md-12 ">
            <h5 className="login-heading-text text-center">Log in</h5>
          </div>

          <div className="col-md-12">
            <p className="sub-text text-center">Don’t have an account? <a href="/sign-up" className="blue-text cursor">Sign Up</a></p>
          </div>

          <div className="col-md-4 offset-md-4">
            <hr style={{ backgroundColor: "#bbb" }}></hr>
          </div>



          <div className="col-md-10 offset-md-1 md10">
            <form
              className="row"
              style={{}}
              onSubmit={(evt) => login(evt)}
            >
              <div className="col-md-12">
                <label>Email</label> <br></br>
                <Input
                  size="large"
                  type="email"
                  autoComplete="off"
                  className="logininput"
                  required
                  value={email}
                  placeholder="Email address"
                  onChange={(evt) =>
                    setEmail(evt.target.value)
                  }
                />
              </div>
              <div className="col-md-12 md20">
                <label>Password</label> <br></br>
                <Input.Password
                  size="large"
                  autoComplete="off"
                  required
                  value={password}
                  onChange={(evt) =>
                    setPassword(evt.target.value)
                  }
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="Password"
                  type="password"
                />
                <p className="text-right blue-text cursor">
                  <a href="/forgot-password">
                    <small>Forgot Password?</small>
                  </a>
                </p>
              </div>

              <div className="col-md-12 md20">
                <Button
                  htmlType="submit"
                  className=""
                  type="primary"
                  block size="large"
                >
                  Sign In
                </Button>
              </div>

            </form>
          </div>

          <div className="col-md-10 offset-md-1  md10  ">
            <div className="row">
              <div className="col-md-4 offset-md-4 text-center">
                <img src={require("../../assets/images/login/or.png")} className="img-fluid m-or-img"></img>
              </div>

              <div className="col-md-12 md10">
                {contextHolder}
                <div className="div-img text-center cursor" onClick={() => { setLoader1(true); loginGoogle(); }}>
                  <Space>
                    {
                      loader1 ? <Spin /> : <>
                        <img src={require('../../assets/images/login/google.png')} height={30} />
                        <p className="fs14 fw600 ">Sign in with Google</p>
                      </>
                    }
                  </Space>
                </div>
              </div>


              {/* <div className="col-md-6 md10">
                <p className="cursor round"><svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M22.5 11.0672C22.5 4.95496 17.5751 0 11.5 0C5.42486 0 0.5 4.95496 0.5 11.0672C0.5 16.5912 4.52256 21.1697 9.78125 22V14.2663H6.98828V11.0672H9.78125V8.62898C9.78125 5.85525 11.4235 4.32313 13.9361 4.32313C15.1396 4.32313 16.3984 4.53929 16.3984 4.53929V7.26287H15.0114C13.6449 7.26287 13.2188 8.11595 13.2188 8.99117V11.0672H16.2695L15.7818 14.2663H13.2188V22C18.4775 21.1697 22.5 16.5912 22.5 11.0672Z" fill="#1877F2"></path></svg> Facebook</p>
              </div> */}

            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-0 col-sm-0 col-ipro-2 m-padding-bauto">

      </div>
    </div>


  );

}

const mapStateToProps = (state) => ({
  _login: state.login.login_details,
  _profile: state.publics.profile,
  _login_social: state.login.login_social
});
const mapDispatchToProps = (dispatch) => ({
  Login: (v) => dispatch(actions.Login(v)),
  LoginSocial: (v) => dispatch(actions.LoginSocial(v)),
  Profile: (v) => dispatch(actions.Profile(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIN));
