import React, { useState } from "react";
import { Row, Col, Button, Input, Modal, Image } from "antd";
import '../../components/Channel/style.scss'
import axios from "../../store/axios";
import Cookies from "js-cookie";
import PodcastClaim from "./PodcastClaim";



const ChannelUploadpodcast = ({reload}) => {
    const [claim, setClaim] = useState(false)
    const [claimModal, setClaimModal] = useState(false)
    const [podcast_link, setPodcast_link] = useState("")
    const [podcast_data, setPodcastData] = useState(null)

    function cliamPodcast() {
        axios({
            method: 'post',
            url:  'market/feed/podcast/link/verify',
            data: {
                rss_url: podcast_link,
            },
            headers: {
               Authorization: Cookies.get("Logintoken"),                    
            },
        }).then(function (response) {
                setPodcastData(response.data.data.feed)
                if (response.data.data.status === "READY_TO_UPLOAD") {
                    setClaim(false)
                }
                else {
                    setClaim(true)
                }
            })
            .catch(function (error) {
                // console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    function savepodcast(){
        axios({
            method: 'post',
            url:  'market/feed/podcast',
            data: {
                    "rss_url":podcast_link,
                    "tags":[],
                    "channel_uid":  "",
                    "publish_in_feed": true
            },
            headers: {
               Authorization: Cookies.get("Logintoken"),                    
            },
        }).then(function () {
            reload(false)
            })
            .catch(function (error) {
                // console.log(error);
            })
            .then(function () {
                // always executed
            }); 
    }
    return (
        <>
            <Row className="mb-2">
                <Col span={24} className="upload-podcastv3">
                    <p className=" fs18 pt-4 pb-4 ps-3" style={{ color: 'white', height: '70px' }}>Upload Podcast</p>

                </Col>
            </Row>
            <Row>
                <Col span={20} offset={2} className='pt-4'>
                    <h5> Link to RSS Feed </h5>
                    <Row>
                        <Col span={21}>
                            <Input placeholder="https://feed.example.com/rss/podcast-my" onChange={(evt) => setPodcast_link(evt.target.value)} value={podcast_link} />
                        </Col>
                        <Col span={3} className="text-right">
                            <Button  type="primary" size={"default"} onClick={() => cliamPodcast()}>Verify</Button>
                        </Col>
                        <Col span={24}>
                            <p className="mt-1">Make sure your podcast content meets our <a>guidelines.</a></p>
                        </Col>

                        {
                            podcast_data && <Col span={24} className='mt-4 radius8' style={{ border: '1px solid grey' }}>
                                <Row>
                                    <Col span={18}>
                                        <p className="pt-3 ps-3 podcast-color fw-bold fs16" >{podcast_data?.title}</p>
                                        <p className="ps-3">By {podcast_data?.author_detail?.name}</p>
                                        <p className="p-3 podcast-color"> {podcast_data?.subtitle}</p>
                                        {
                                            claim && <p className="ps-3 mt-3" style={{ color: '#2B4CF9' }}>It's already uploaded. <span className={"cursor"} onClick={() => setClaimModal(true)} style={{ color: '#2B4CF9' }}>Claim it</span></p>
                                        }

                                    </Col>
                                    <Col span={6} className='text-center'>
                                        <Image src={podcast_data?.image?.href} preview={false} style={{ border: '1px solid grey' }} className={"md20"}></Image>
                                    </Col>
                                </Row>
                            </Col>
                        }


                    </Row>
                    <Row className={"height100px"}></Row>
                    {/* <Row>
                        <Col span={24} className='mt-5'>
                            <h5>Tags</h5>
                        </Col>
                        <Col span={18}>
                            <Input placeholder="Search keywords" />
                        </Col>
                        <Col span={21} className='mt-2'>
                            <Tag closable onClose={''} style={{Color:'#565656',backgroundColor:'#E5E5E5', borderRadius:'5px'}}>
                                Finance
                            </Tag>
                            <Tag closable  style={{backgroundColor:'#E5E5E5',Color:'#565656', borderRadius:'5px'}}>
                                Finance
                            </Tag>
                        </Col>
                    </Row> */}
                    <Row className={"height100px"}>
                        <Col span={3} className='text-right'>
                           
                           {
                                !claim && <Button type='primary' className=' mt-5 mb-3'  onClick={()=>savepodcast()}  size={"default"}>Save</Button>
                           }
                            
                        </Col>
                    </Row>
                    <Modal visible={claimModal} footer={false} centered onCancel={() => setClaimModal(false)}>
                        <PodcastClaim podcast_data={podcast_data} ></PodcastClaim>
                    </Modal>

                </Col>

            </Row>
        </>

    );
}
export default ChannelUploadpodcast;