import { Avatar, Button, Col, Dropdown, Row, Space, Typography } from "antd";
import { Bug, DotsThreeVertical, Export, Prohibit, SealCheck } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RWebShare } from "react-web-share";
const { Paragraph } = Typography

const COLORS = ['linear-gradient(276.78deg, #FFE8ED 36.28%, #FFF1CD 100%)', 'linear-gradient(97.04deg, #FFFFFF 0%, #E1E1E1 100%)',
    'linear-gradient(97.04deg, #FFFEE9 0%, #D0F0E1 100%)', 'linear-gradient(0deg, rgba(243, 238, 244, 0.2), rgba(243, 238, 244, 0.2)), linear-gradient(353.31deg, rgba(13, 167, 0, 0.2) -145.4%, rgba(0, 0, 0, 0) 146.61%, rgba(255, 255, 255, 0) 146.61%), linear-gradient(267.54deg, rgba(239, 37, 37, 0.2) 16.77%, rgba(255, 255, 255, 0) 70.29%), linear-gradient(90deg, #FAEFDE 1.48%, rgba(250, 239, 222, 0) 57.38%)']



const ExploreNewsletter = ({ data }) => {
    const [items, setItems] = useState([]);
    const history = useHistory();

    function createSlots(array, slotSize) {
        const slots = [];
        const length = array.length;

        for (let i = 0; i < length; i += slotSize) {
            const slot = array.slice(i, i + slotSize);
            slots.push(slot);
        }

        return slots;
    }

    useEffect(() => {
        if (data.length > 0 > 0) {
            const slots = createSlots(data, 3);
            console.log(slots)
            setItems(slots);
        }

    }, [data]);
    const menuItems = (i) => {
        var i = [
            // {
            //     label: (
            //         <span className="fw400"> Follow {i?.username}</span>
            //     ),
            //     key: '1',
            //     icon: <SealCheck size={18} color="#000000" weight="thin" />
            // },
            // {
            //     label: (
            //         <span className="fw400"> Block {i?.username}</span>
            //     ),
            //     key: '2',
            //     icon: <Prohibit size={18} color="#000000" weight="thin" />
            // },
            {
                key: '3',
                icon:
                    <RWebShare
                        data={{
                            text: "Expert",
                            url: `${process.env.REACT_APP_URL}feed-view/${i?.entity_uuid}`,
                            title: i?.title,
                        }}

                    ><span><Export size={18} color="#000000" weight="thin" className="me-1" /> Share</span>
                    </RWebShare>
            }, {
                label: (
                    <span className="fw400"> Report issue</span>
                ),
                key: '4',
                icon: <Bug size={18} color="#000000" weight="thin" />
            }
        ]
        return i;
    };

    const menuClick = async ({ key }) => {
        if (key === '2') {

        }
    };
    return (
        <Row>
            <Col span={24} className="md20">
                {
                    items.map((item, k) => {
                        return Math.random() < 0.5 ? <Row className="" key={k}>
                            {
                                item.map((i, key) => {
                                    return <Col key={key} md={8} className="cursor" xs={24} onClick={()=>history.push(`/feed-view/${i?.entity_uuid}`)} style={{
                                        background: COLORS[Math.floor(Math.random() * COLORS.length)]
                                    }}>

                                        <div className="explov2-card-newsletter">
                                            <Paragraph ellipsis={{ rows: 3 }}  className="fs16 fw600 cursor"> {i.title}  </Paragraph>
                                            <Row>

                                                <Col span={20}>
                                                    <Space className="cursor" onClick={() => history.push(`/profile/${i.user_code}`)}>
                                                        <Avatar src={i.dp} size={25} />
                                                        <p className="fs12 fw400">{i.fname} {i.lname} </p>
                                                    </Space>
                                                </Col>

                                                <Col span={4} className="md5">
                                                    <div className="menu-surface-anchor text-right">
                                                        <Dropdown trigger={['click']} menu={{ items: menuItems(i), onClick: ({ key }) => menuClick({ key: key }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                            <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                                        </Dropdown>


                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                })
                            }

                        </Row> : <Row key={k}>
                            <Col span={12}>
                                <Row>
                                    {
                                        item.length > 0 &&

                                        <Col span={24} style={{ background: COLORS[Math.floor(Math.random() * COLORS.length)] }} className="cursor" xs={24} onClick={()=>history.push(`/feed-view/${item[0]?.entity_uuid}`)}>
                                            <div className="explov2-card-newsletter" >
                                                <Paragraph ellipsis={{ rows: 3 }} className="fs16 fw600 cursor"> {item[0].title}  </Paragraph>
                                                <Row>
                                                    <Col span={20}>
                                                        <Space className="cursor" onClick={() => history.push(`/profile/${""}`)}>
                                                            <Avatar size={25} src={item[0].dp} />
                                                            <p className="fs12 fw400">{item[0].fname} {item[0].lname}</p>
                                                        </Space>
                                                    </Col>

                                                    <Col span={4} className="md5">
                                                        <div className="menu-surface-anchor text-right">
                                                            <Dropdown trigger={['click']} menu={{ items: menuItems(item[0]), onClick: ({ key }) => menuClick({ key: key }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                                <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                                            </Dropdown>


                                                        </div>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    }

                                    {
                                        item.length > 1 &&
                                        <Col span={24} style={{ background: COLORS[Math.floor(Math.random() * COLORS.length)] }} className="cursor" xs={24} onClick={()=>history.push(`/feed-view/${item[1]?.entity_uuid}`)}>
                                            <div className="explov2-card-newsletter" >
                                                <Paragraph ellipsis={{ rows: 3 }} className="fs16 fw600 cursor"> {item[1].title}  </Paragraph>
                                                <Row>
                                                    <Col span={20}>
                                                        <Space className="cursor" onClick={() => history.push(`/profile/${""}`)}>
                                                            <Avatar size={25} src={item[1].dp} />
                                                            <p className="fs12 fw400">{item[1].fname} {item[1].lname}</p>
                                                        </Space>
                                                    </Col>

                                                    <Col span={4} className="md5">
                                                        <div className="menu-surface-anchor text-right">
                                                            <Dropdown trigger={['click']} menu={{ items: menuItems(item[1]), onClick: ({ key }) => menuClick({ key: key }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                                <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                                            </Dropdown>


                                                        </div>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    }

                                </Row>

                            </Col>
                            {
                                item.length > 2 &&
                                <Col span={12} style={{ background: COLORS[Math.floor(Math.random() * COLORS.length)] }} className="cursor" xs={24} onClick={()=>history.push(`/feed-view/${item[2]?.entity_uuid}`)}>
                                    <div className="explov2-card-newsletter" style={{ minHeight: "380px" }}>
                                        <div>
                                            {
                                                !(item[2].image_url === "" || item[2].image_url === null) && <img className="ns-img" src={require('../../assets/images/newdashboard/group.png')} alt="" width={'100%'} />
                                            }
                                            
                                            <Paragraph ellipsis={{ rows: 3 }} className="fs16 fw600 cursor md15"> {item[2].title}  </Paragraph>
                                        </div>
                                        <Row>
                                            <Col span={20}>
                                                <Space className="cursor" onClick={() => history.push(`/profile/${item[2].user_code}`)}>
                                                    <Avatar src={item[2].dp} size={25} />
                                                    <p className="fs12 fw400">{item[2].fname} {item[2].lname}</p>
                                                </Space>
                                            </Col>

                                            <Col span={4} className="md5">
                                                <div className="menu-surface-anchor text-right">
                                                    <Dropdown trigger={['click']} menu={{ items: menuItems(item[2]), onClick: ({ key }) => menuClick({ key: key }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                        <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                                    </Dropdown>


                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            }

                        </Row>
                    })
                }



            </Col >
        </Row >
    );
}
export default ExploreNewsletter;