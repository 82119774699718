

import { Col, Row, Button,Space } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import {  withRouter } from "react-router";
import { RWebShare } from "react-web-share";
import { connect } from "react-redux";
import actions from "../../store/actions/index";


const FollowShare = (props) => {
    const { profile } = props
    const [follow, setFollow] = useState(false)

    useEffect(()=>{
        setFollow(profile?.following || profile?.is_following)
    },[props?.profile])

    function FollowUser(key, id, type) {
      
        var _user_id = profile.user_code || profile?.creator?.user_code;
        let data = {
            following_user_code: id === undefined ? _user_id : id,
            follower: key
        }
        let method = type
        props.FollowUnFollowUser({ method, data }).then(() => {
            setFollow(!key)
        })
    }
    
    return (
        <Row className={"md10"}>
            <Col span={24}>
                <Space className="md15">
                    {
                       
                        !profile?.isme && !follow ? <Button type="primary" className="radius18" onClick={() => FollowUser(false, profile.user_code, "post")}> Follow </Button>
                            : !profile?.isme && follow ? <Button type="dashed" className="radius18" onClick={() => FollowUser(true, profile.user_code, "delete")}> Following </Button> : ""
                    }
                    <RWebShare
                                                        data={{
                                                            text: profile?.full_name,
                                                            url: `${process.env.REACT_APP_URL}profile/` + profile?.user_code,
                                                            title: profile?.full_name,
                                                        }}

                                                    >
                                                        <Button shape="circle"  style={{padding:"0px 20px"}} type={"dashed"}>Share </Button>
                                                    </RWebShare>
                </Space>
            </Col>
        </Row>

    );
}


const mapStateToProps = (state) => ({
    _profile: state.profile.profile,
});
const mapDispatchToProps = (dispatch) => ({
  
    FollowUnFollowUser: (v) => dispatch(actions.FollowUnFollowUser(v)),
   
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FollowShare)
);