import { Badge, Col, Row, Space } from "antd";
import axios from "../../store/axios";
import { Buildings, Cardholder, GraduationCap, Graph, TelevisionSimple, UsersThree, Nut,CheckSquareOffset } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useToken from "../../store/useToken"
import { CAMPUS_WEB } from "../../utils";

const AdminConsole = () => {
    const history = useHistory();
    const [data, setData] = useState(false);
    const [username, setusername] = useState('');
    const { token } = useToken();


    const fetchData = async() => {
        await axios({
            method: 'get',
            url:  `account/business/connections/pending`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setData(data.pending);
            setusername(data.username);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Row style={{ minHeight: "calc(100vh - 69px)", alignContent: "flex-start" }}>
            <Col xxl={{ span: 8, offset: 8 }} md={{ span: 10, offset: 7 }} xs={{ span: 24, offset: 0 }} className="md80">
                <Space>
                    <Nut size={32} color="#010101" weight="regular" />
                    <p className="fs18 fw600">Admin Console</p>
                </Space>

            </Col>
            <Col xxl={{ span: 10, offset: 7 }} lg={{span: 12, offset: 6}} md={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }}
             className="md30 admin-console m-console-pa7 mb-5" >
                <Row gutter={[16,16]} style={{overflow:'scroll'}}>
                    <Col xs={12} md={8} >
                        <div className="admin-card text-center cursor" onClick={() => history.push(`/business/${username}`)}>
                            <Buildings size={40} color="#4B5669" weight="fill" />
                            <p className="fs14 fw400 md10 text-grey" > Business Page </p>
                        </div>
                    </Col>
                    <Col xs={12} md={8} >
                        <div className="admin-card text-center cursor">
                            <Cardholder size={40} color="#4B5669" weight="fill" />
                            <p className="fs14 fw400 md10 text-grey" style={{marginLeft:'-6px'}} > Subscriptions </p>
                        </div>
                    </Col>
                    <Col xs={12} md={8} >
                        <div className="admin-card text-center cursor ">
                            <Graph size={40} color="#4B5669" weight="fill" />
                            <p className="fs14 fw400 md10 text-grey" > Analytics </p>
                        </div>
                    </Col>

                    <Col xs={12} md={8} >
                        <a href={`${CAMPUS_WEB}campus/me`}>
                        <div className="admin-card text-center cursor ">
                            <GraduationCap size={40} color="#4B5669" weight="fill" />
                            <p className="fs14 fw400 md10 text-grey" > Campus </p>
                        </div>
                        </a>
                    </Col>
                    <Col xs={12} md={8} >
                        <div className="admin-card text-center cursor" onClick={() => history.push('/tv')}>
                            <TelevisionSimple size={40} color="#4B5669" weight="fill" />
                            <p className="fs14 fw400 md10 text-grey" > TV </p>
                        </div>
                    </Col>
                    <Col xs={12} md={8} >

                        <div className="admin-card text-center cursor" onClick={() => history.push('/business/members', {state: data})}>
                            <Badge dot={data}>
                                <UsersThree size={40} color="#4B5669" weight="fill" />
                                <p className="fs14 fw400 md10 text-grey" > Members </p>
                            </Badge>
                        </div>
                    </Col>
                    <Col xs={12} md={8}>
                        <div className="admin-card text-center cursor" onClick={() => history.push('/business/post-request', {state: data})}>
                            <Badge dot={data}>
                            <CheckSquareOffset size={40} weight="fill" color="#4b5669" />
                                <p className="fs14 fw400 md10 text-grey" > Post Approval </p>
                            </Badge>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default AdminConsole;