import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Dropdown, Avatar, Row, Space,  Skeleton } from "antd";
import { DotsThreeVertical, Television, Export, ArrowLeft } from "@phosphor-icons/react";
import Cookies from "js-cookie";
import axios from "../../store/axios";

import './TV.scss';
import { useParams, useHistory } from "react-router-dom";
import IconsView from "../utils/IconsView";
import { RWebShare } from "react-web-share";



const TVDetails = () => {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([])
    const [extradata, setExtraData] = useState([])
    const [openList, setOpenList] = useState(false)
    const [list, setlist] = useState({})


    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        TvData();
    }, [])
    function TvData() {
        setLoading(true)
        axios({
            method: 'get',
            url: `v1.0/tv/playlist/get?user_code=${params["user_code"]}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data

            setData(data?.library)
            setExtraData(data)
            setLoading(false)
        })

    }

    const items_video = [

        {
            label: (
                <span className="fs12 fw400"> Share</span>
            ),
            key: '1',
            icon: <RWebShare data={{title:"TV Details",url:window.location.href,image_url:""}} ><Export size={18} color="#000000" weight="thin" /></RWebShare>
        },
        // {
        //     label: (
        //         <span className="fs12 fw400"> Delete video</span>
        //     ),
        //     key: '2',
        //     icon: <Trash size={18} color="#000000" weight="thin" />
        // }

    ];

    const videoMenuClick = ({ key, id }) => {
        if (key === '4') {
            
        }
    };

    const funOpenList = (item) => {
        setOpenList(true)
        setlist(item)
    }

    const openURL = (item, playlist_id) => {

        if (item?.ep_type === "URL") {
            window.open(item?.url)

        } else
            history.push(`/tv/${playlist_id}/play/${item?.epd_id}`)
    }

    const getCout = (items) => {

      let v = 0

      for (let i=0;i<items.length;i++){
        v = v+ (items[i].items.length)
      }
      return v
    }




    return (
        <Row >
            <Col lg={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0}} xxl={{ span: 12, offset: 6 }} className='mb-4'>
                <Row >
                    <Col lg={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 22, offset: 1 }}>
                        <Row className="md40">
                            <Col lg={{ span: 3 }} xs={{ span: 3, offset: 0 }} xxl={{ span: 3, offset: 0 }} className="md10">
                                <Space className="cursor" onClick={() => openList ? setOpenList(false) : history.goBack()}>
                                    <ArrowLeft size={18} color="#000000" weight="light" className="ms-1 " style={{ webkitBoxAlign: 'baseline' }} />
                                    <p className="fs14 fw400"> Back </p>
                                </Space>
                            </Col>
                            <Col lg={{ span: 18, offset: 0 }} xs={{ span: 20, offset: 0 }} xxl={{ span: 20, offset: 0 }} className="text-center m-align-end " >

                                <Space wrap >
                                    {
                                        loading ? <Skeleton.Input active /> :
                                            <><Television size={25} weight="light" color="#000000" className=""  />
                                                <span className="fs24 fw800  m-md20" style={{ color: extradata?.creator?.color }}> {extradata?.creator?.name} </span>
                                                <p className="fs24 m-fs20 fw800 blue-text"> TV </p></>
                                    }

                                </Space>
                            </Col>
                            <Col lg={{ span: 3 }}  xs={{ span: 3, offset: 0 }} xxl={{ span: 1, offset: 0 }} ></Col>
                        </Row>

                    </Col>

                    <Col lg={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 22, offset: 1 }}  className='TV-card m-p10 md20'>
                        {
                            openList ? <>
                                <Row className='playlist-card '>
                                    <Col xs={{span:5}} lg={{span:5}} className='green-card text-center'>
                                        <IconsView size={32} color="#02C062" weight="fill" icon={list?.icon}></IconsView>
                                    </Col>
                                    <Col xs={{span:19}} lg={{span:19}} className='playlist-grey-card'>
                                        <p className="fs14 fw500">{list?.title} </p>
                                        <p className="fs12 fw400 blue-text"> {list?.items.length} Videos | {extradata?.creator?.name}</p>
                                    </Col>

                                </Row>
                                <Divider></Divider>
                                <Row>
                                    <Col lg={{ span: 20, offset: 2 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 22, offset: 1 }}>
                                        {
                                            list?.items.map((item, i) => {
                                                return <Row key={i}>
                                                    <Col className="cursor" lg={5} xs={7} onClick={() => openURL(item, list?.playlist_id)}>
                                                        <Avatar src={item?.image_url} size={80} style={{ borderRadius: '4px' }} />
                                                    </Col>
                                                    <Col lg={17} xs={15} className="md10 cursor" onClick={() => openURL(item, list?.playlist_id)}>
                                                            <p className="fs14">{item.title}</p>
                                                        <p className="fs12 fw300 md10">{item?.ep_type}</p>

                                                    </Col>
                                                    <Col span={2} className='text-right'>

                                                        <Dropdown  trigger={['click']} menu={{ items: items_video, onClick: ({ key, }) => videoMenuClick({ key: key, id: item?.epd_id }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                            <Button type="text" shape="circle" onClick={(e) => e.preventDefault()}
                                                                icon={<DotsThreeVertical size={17} color="#696969"  />}></Button>
                                                        </Dropdown>

                                                    </Col>
                                                    <Col span={24} >
                                                        <Divider></Divider>
                                                    </Col>
                                                </Row>
                                            })
                                        }

                                    </Col>
                                </Row>


                            </>
                                : <><Row >
                                    <Col xs={{span:17}} lg={{span:18}}>
                                        <Space>
                                            <Avatar src={extradata?.creator?.img_url} />
                                            <p className="fs14 fw400">{extradata?.creator?.name} </p>
                                        </Space>
                                    </Col>
                                    <Col xs={{span:7}} lg={{span:6}} className='text-right'>
                                        {
                                            extradata.length === 0? <Skeleton.Button active/> :
                                            extradata?.isme ? <Button onClick={() => history.push("/tv")} type="dashed" className=""> Manage </Button> :
                                                <Button type="primary" onClick={() => history.push(`/${extradata?.creator?.is_org?'business':'profile'}/${extradata?.creator?.user_code}`)} > Profile </Button>
                                        }

                                    </Col>
                                    <Col span={24} className='md25'>
                                        <p className="fs16 fw600"> Library <span className="fs12 fw300">{
                                            data && <>({getCout(data)} Videos) </>
                                        } </span> </p>

                                    </Col>
                                    <Col span={24} className='md15'>
                                        <Divider className="my-1" />
                                    </Col>
                                </Row>
                                    {
                                        data && data.map((item, i) => {
                                            return <Row key={i} className='md25 playlist-card' onClick={() => funOpenList(item)}>
                                                <Col span={5} className='green-card text-center'>
                                                    <IconsView size={32} color="#02C062" weight="fill" icon={item?.icon}></IconsView>
                                                </Col>
                                                <Col span={19} className='playlist-grey-card'>
                                                    <p className="fs14 fw500">{item.title} </p>
                                                    <p className="fs12 fw300"> {item.items.length} Videos </p>
                                                </Col>

                                            </Row>
                                        })
                                    }</>
                        }



                    </Col>
                </Row>
            </Col>




        </Row>
    );
}
export default TVDetails;