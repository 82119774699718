import { Avatar, Button, Col, Divider, Row, Space, Tag, Dropdown, Menu, Modal, Select, Skeleton, Spin } from "antd";
import { CaretLeft, CaretRight, Copy, DotsThreeOutlineVertical, Export, MagnifyingGlass, PaperPlaneTilt, Trash, UserPlus } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "../../store/axios";
import moment from "moment";
import "./react-alice.scss"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import MentorshipCard from "./MentorshipCard";
import { useHistory, useParams, useLocation } from "react-router";
import { RWebShare } from "react-web-share";

import Toast from "../utils/Toast";
import MentorshipAddSlotModal from "./MentorshipAddSlotModal";
import useToken from "../../store/useToken";
import PaymentCreditPage from "../utils/paymentCredit";


const { Option } = Select;
function getBookingTimeSlots(start, end) {
    const arr = [];
    var time = start
    while (time < end) {
        const m = moment(time, "HH:mm:ss")
        arr.push(m.format('HH:mm'));
        m.add(30, 'minutes');
        time = m.format('HH:mm');
    }
    return arr
}

const MentorshipDetails = () => {
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState(null)
    const [date, setdate] = useState(null)
    const params = useParams();
    const location = useLocation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [collaborator, setCollaborator] = useState(false);
    const [collaborators, setCollaborators] = useState([]);
    const [mainIndex, setMainIndex] = useState(0);
    const [mainAnimation, setMainAnimation] = useState(false);
    const [thumbIndex, setThumbIndex] = useState(0);
    const [thumbAnimation, setThumbAnimation] = useState(false);
    const [thumbs, setThumbs] = useState([])
    const [nameLoader, setnameLoader] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null);

    const [slot, setSlot] = useState(false);

    // Payment Config
    const [paymentModal, setPaymentModal] = useState(false);


    const history = useHistory()
    const { token } = useToken();




    useEffect(() => {
        if (token === false) {
            history.push(`/login?redirect=${location.pathname}`)
            return
        }
        getMentorshipDetails();

    }, [])

    const getMentorshipDetails = async () => {
        await axios({
            method: 'get',
            url: `v1.0/mentorship/${params["id"]}/detail`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            setData(res.data.data)
        }).catch((error) => {
            console.log(error)
        });
        setLoader(false);
    }


    const searchCollaborator = (evt) => {
        setnameLoader(true)
        axios({
            method: 'get',
            url: `v1.0/mentorship/collaborators?name=${evt}&offset=0&count=20`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            setCollaborators(res.data.data)
            setnameLoader(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    const sendRequest = () => {
        axios({
            method: 'post',
            url: `v1.0/mentorship/${params["id"]}/collaborate`,
            data: {
                user_code: selectedUser.value
            },
            headers: {
                Authorization: token
            }
        }).then(res => {
            Toast("Sent Successfully ", "success")
            setCollaborator(false)
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        }).catch((error) => {
            console.log(error)
        })
    }


    const sendRequestGain = (evt) => {
        axios({
            method: 'put',
            url: `v1.0/mentorship/${params["id"]}/collaborate`,
            data: {
                action: evt
            },
            headers: {
                Authorization: token
            }
        }).then(res => {
            Toast(evt === "ACTIVE" ? "Sent Successfully " : "Deleted Successfully", "success")
            setCollaborator(false)
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        }).catch((error) => {
            console.log(error)
        })
    }


    const menu = (data) => (
        data.isme && data.collaborator == null ?
            <Menu
                items={[

                    {
                        label: (
                            <span className="fs12 fw400"> <Copy size={18} color="#181816" weight="thin" /> Copy Link</span>
                        ),
                        key: '1',
                    },
                    {
                        label: (
                            <span className="fs12 fw400" onClick={() => showCollabort()}> <UserPlus size={18} color="#3c3a39" weight="thin" /> Add collaborator</span>
                        ),
                        key: '2',
                    }
                ]}
            /> :
            <Menu
                items={[

                    {
                        label: (
                            <span className="fs12 fw400"> <Copy size={18} color="#181816" weight="thin" /> Copy Link</span>
                        ),
                        key: '1',
                        onClick: () => navigator.clipboard.writeText(window.location.href)
                    }
                ]}
            />
    );
    const mentor = (data) => (

        data.status === "DECL" ? <Menu
            items={[

                {
                    label: (
                        <span className="fs12 fw400 cursor" onClick={() => sendRequestGain("ACTIVE")}> <PaperPlaneTilt size={17} color="#3c3a39" /> Resend Request</span>
                    ),
                    key: '1',
                },
                {
                    label: (
                        <span className="fs12 fw400" onClick={() => sendRequestGain("DEL")}> <Trash size={17} color="#3c3a39" /> Remove collaborator </span>
                    ),
                    key: '2',
                }
            ]}
        /> : <Menu
            items={[

                {
                    label: (
                        <span className="fs12 fw400" onClick={() => sendRequestGain("DEL")}> <Trash size={17} color="#3c3a39" /> Remove collaborator </span>
                    ),
                    key: '2',
                }
            ]}
        />
    );

    const showModal = (date) => {
        setdate(date)
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showCollabort = () => {
        setCollaborator(true)
    };
    const Ok = () => {
        setCollaborator(false);
    };

    const Cancel = () => {
        setCollaborator(false);
    };
    const slotOk = () => {
        setSlot(false);
    }
    const slotCancel = () => {
        setSlot(false);
        getMentorshipDetails();
    }

    const addSlot = () => {
        setSlot(true);
    }



    const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
        return items.map((item, i) => (
            <div className="thumb" onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
                {item}
            </div>
        ));
    };

    const slideNext = () => {
        if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex + 1);
        }
    };

    const slidePrev = () => {
        if (!thumbAnimation && thumbIndex > 0) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex - 1);
        }
    };


    const syncThumbs = (e) => {
        setThumbIndex(e.item);
        setThumbAnimation(false);

        if (!mainAnimation) {
            setMainIndex(e.item);
        }
    };

    const goToProfile = (code) => {
        history.push("/profile/" + code)
    };

    useEffect(() => {
        if (data != null) {
            const items = data?.slots?.map((item, i) => {

                return <div data-value={i} style={{ width: "100px" }}>
                    <div className={item?.active ? "mentorship-calender item cursor" : "item mentorship-calender-disabled"}
                        onClick={() => item?.active && showModal(item)}>
                        <p className="fs16 fw500">{moment(item.date).format("DD")} </p>
                        <span className="fs14 fw400 text-grey">{moment(item.date).format("MMM")} </span>
                    </div>

                </div>
            });

            // items.push(<Popover content={<Calendar size={"small"}></Calendar>} trigger={"click"}><Button type="primary" className="md20" > More</Button></Popover>)
            setThumbs(thumbItems(items, [setThumbIndex, setThumbAnimation]))
        }

    }, [data]);

    const selectTime = (time) => {
        setSelectedTime(time === selectedTime ? null : time);
    }

    const closePayment = () => {
        setPaymentModal(false);
    }

    const onCreditUpdate = (res) => {
        setPaymentModal(res.modal);
        if (res.status === "success") {
            // Toast("Booking successfully done!", "success");
            //   this.componentDidMount();
            history.push(`/mentorship-booking/${res?.booking_id}`)
        }
    }


    return (
        <Row>

            <Col xs={{ span: 24 }} lg={{ span: 14, offset: 5 }} className='md40 nopadding'>
                {
                    loader ? <><Skeleton></Skeleton><Skeleton></Skeleton></> :
                        <Row>
                            <Col xs={{ span: 18 }} lg={{ span: 19 }} >
                                <Button onClick={() => history.goBack()} className="p-0" type="link" icon={<CaretLeft size={24} color="#1c1c1c" />}> </Button>
                            </Col>
                            <Col xs={{ span: 6 }} lg={{ span: 5 }} className='text-right'>
                                <Space>

                                    <RWebShare
                                        data={{
                                            text: "Mentorship",
                                            url: `${process.env.REACT_APP_URL}mentorship/${data?.mentorship_id}`,
                                            title: data?.name,
                                        }}

                                    >
                                        <Button type="text" className="me-3" shape={"circle"} icon={<Export size={20} color="#586A89" />}></Button>
                                    </RWebShare>

                                    <Dropdown trigger={['click']} overlay={menu(data)} placement={"bottom"} overlayStyle={{ minWidth: "141px" }}>

                                        <Button type="text" shape="circle" className="thread-icon"
                                            icon={<DotsThreeOutlineVertical size={17} color="#586A89" weight="fill" />}></Button>
                                    </Dropdown>
                                </Space>

                            </Col>
                            <Col xs={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} className="md20">
                                <MentorshipCard {...data} showAmount={true} />
                            </Col>
                            {/* <img src={require('../../assets/Resources/mentorship.png')} className='md-100' alt="" />  */}
                            <Col span={24} >
                                <Row>
                                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 16, offset: 0 }} className='md40 pe-3'>
                                        <Row>
                                            <Col span={24}>
                                                <p className="fs14 fw400">
                                                    {data?.description}
                                                </p>

                                                {data?.venue && <> <p className="fs15 fw500 md35">
                                                    Venue:
                                                </p>
                                                    <p className="fs14 fw300 ">
                                                        {data?.venue}
                                                    </p></>}



                                            </Col>
                                            <Col span={24} className='md10'>
                                                <p className="fs15 fw500">
                                                    Few Learnings:
                                                </p>
                                                <ul className='md15'>
                                                    {data?.syllabus && data?.syllabus.map((item, i) => {
                                                        return <li key={i}>{item}</li>
                                                    })}
                                                </ul>

                                            </Col>
                                            {
                                                data?.tags && data?.tags.length > 0 &&
                                                <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }} className='md20'>
                                                    {
                                                        data?.tags.map((item, i) => {
                                                            return <Tag key={i}>{item}</Tag>
                                                        })
                                                    }


                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 0 }} >
                                        <Row className='mentorship-details  md40 pt13'>
                                            <Col span={24}>
                                                <p className="fs14 fw500">Mentor:</p>
                                                <Row>
                                                    <Col span={24} className='md15'>
                                                        <Space>
                                                            <Avatar size={45} src={data?.creator?.img}></Avatar>
                                                            <div className="pt-2">
                                                                <p className="fs14 fw500 cursor m-0" onClick={() => goToProfile(data?.creator?.user_code)}> {data?.creator?.name} </p>
                                                                <span className="fs12 text-grey m-0">
                                                                    Host
                                                                </span>
                                                            </div>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            {
                                                data?.collaborator && <Col span={24}>
                                                    <Row>
                                                        <Col span={24} className='md15'>
                                                            <Space>
                                                                <Avatar size={45} src={data?.collaborator?.img_url}></Avatar>
                                                                <div className="pt-2">
                                                                    <p className="fs14 fw500 cursor m-0" onClick={() => goToProfile(data?.collaborator?.user_code)}> {data?.collaborator?.name} </p>
                                                                    {
                                                                        data?.collaborator.status === "PEND" ? <span className="fs10 fw300" style={{ color: '#DE0905' }}>Pending</span> : data?.collaborator.status === "DECL" ? <span className="fs10 fw300" style={{ color: '#DE0905' }}>Declined</span> : <span className="fs12 text-grey" >Collaborator</span>}

                                                                </div>
                                                                {
                                                            data?.isme && 
                                                                <Dropdown overlay={mentor(data)} trigger={"click"} placement={"bottom"} overlayStyle={{ minWidth: "141px" }}>

                                                                    <Button type="text" shape="circle" className="thread-icon"
                                                                        icon={<DotsThreeOutlineVertical size={17} color="#54514f" weight="fill" />}></Button>
                                                                </Dropdown>

                                                            
                                                        }
                                                            </Space>

                                                        </Col>

                                                    </Row>
                                                </Col>
                                            }

                                        </Row>

                                    </Col>
                                    <Col span={24} className='md20'>
                                        <Divider></Divider>
                                    </Col>
                                </Row>


                                <Row className="mb-5">
                                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }}>
                                        <p className="fs16 fw600 md20">
                                            Let’s connect, When should be meet?
                                        </p>

                                    </Col>
                                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }} className="md30">
                                        {
                                            thumbs.length > 0 &&

                                            <Row gutter={16} style={{ alignItems: "center" }}>
                                                <Col xs={{ span: 3 }} lg={{ span: 2 }} className="">
                                                    <Button onClick={slidePrev} type="text" icon={<CaretLeft size={24} color="#3c3a39" />}> </Button>

                                                </Col>

                                                <Col xs={{ span: 18, }} lg={{ span: 20 }}>
                                                    <AliceCarousel
                                                        activeIndex={thumbIndex}
                                                        autoWidth
                                                        disableDotsControls
                                                        disableButtonsControls
                                                        items={thumbs}
                                                        mouseTracking={false}
                                                        onSlideChanged={syncThumbs}
                                                        touchTracking={!mainAnimation}
                                                        keyboardNavigation />
                                                </Col>

                                                <Col xs={{ span: 3 }} lg={{ span: 2 }} >
                                                    <Button type="text" onClick={slideNext} icon={<CaretRight size={24} className={"cursor"} color="#3c3a39" />}> </Button>

                                                </Col>

                                                {data?.isme &&
                                                    <Col span={24} className="md20">
                                                        <Button type="primary" onClick={() => addSlot()}>Update slots</Button>
                                                    </Col>
                                                }

                                            </Row>
                                        }
                                        {
                                            (thumbs.length === 0 && data?.isme) &&
                                            <Row>
                                                <Col span={24} className="mentor-detail-card md20 mb-2 text-center">
                                                    <p className="fs14 fw400"> No booking slots added. Please add at-least 15 slots. </p>
                                                    <Button type="primary" className="md20" onClick={() => addSlot()}> Add Booking Slots </Button>
                                                </Col>
                                            </Row>
                                        }

                                    </Col>

                                    {data?.mybookings && data?.mybookings.length > 0 &&
                                        <>
                                            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }} className="md30">
                                                <Divider></Divider>
                                                <p className="fs16 fw600">
                                                    My bookings
                                                </p>

                                            </Col>
                                            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }} className="md10">
                                                <Row gutter={10}>
                                                    {
                                                        data?.mybookings.map((item, key) => {
                                                            return <Col xs={24} md={12} lg={8} className="md10" key={key}>

                                                                <div className="bordered p-3" style={{ borderRadius: '16px', backgroundColor: 'whitesmoke' }}>
                                                                    <Space size={20} align="end">
                                                                        <div style={{ width: "50px" }}>
                                                                            <div className={"bordered p-2 rounded text-center"}>
                                                                                <p className="fs16 fw500">{moment(item?.datetime).format("DD")} </p>
                                                                                <span className="fs14 fw400 text-grey">{moment(item?.datetime).format("MMM")} </span>
                                                                            </div>

                                                                        </div>
                                                                        <div className="text-right">
                                                                            {/* <Tag type="primary" size="small">{moment(item?.datetime).fromNow()}</Tag> */}
                                                                            <Button type="primary" onClick={() => history.push(`/mentorship-booking/${item?.booking_id}`)}>View</Button>
                                                                            <p className="text-grey fs12 md5">Booked at: {moment(item?.created_at).format("DD MMM YYYY")}</p>
                                                                        </div>

                                                                    </Space>

                                                                </div>
                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            </Col>
                                        </>
                                    }
                                </Row>

                            </Col>
                        </Row>
                }
            </Col>

            <Modal title="Add Slots" open={slot} onOk={slotOk} onCancel={slotCancel} width={385} footer={false}>
                <MentorshipAddSlotModal onCancel={slotCancel} slots={data?.slots} startTime={data?.start_time} endTime={data?.end_time} />
            </Modal>
            <Modal open={isModalOpen} centered maskClosable={false} onCancel={handleCancel} width={385} footer={false}>
                <Row>
                    <Col span={24} className='padding25'>
                        <Row>
                            {moment(date?.on_hold_till) > moment() &&
                                <Col span={24} className="md45 mb-2">
                                    <div className="note-card">
                                        <p className="fs12 fw400 "> Booking slot ({date?.date}) is on hold till {moment(date?.on_hold_till).format("hh:mm a")} </p>
                                    </div>
                                </Col>
                            }
                            <Col span={24}>
                                <p className="fs14 fw500">Mentorship duration</p>
                                <div className="text-center md10" style={{ background: '#eee', border: '1px solid rgba(0, 0, 0, 0.08)', borderRadius: '30px', padding: '10px' }}>
                                    <p className="fs12 fw400 solt-text"> {moment(date?.date).format("DD MMM YYYY")} | 30 mins </p>
                                </div>
                            </Col>
                            <Col span={24} className="md20">
                                <p className="fs14 fw500"> What time works best? </p>
                                <p className="fs10 fw400 solt-text"> UTC +5:30 New Delhi, Mumbai, Calcutta </p>
                            </Col>
                            <Col span={24} className="md20" style={{ maxHeight: "150px", overflowY: "scroll" }}>
                                {
                                    getBookingTimeSlots(data?.start_time, data?.end_time).map((item, i) => {
                                        return <div key={i} className="text-center mb-2 cursor bordered rounded" style={selectedTime === item ? { padding: '12px', background: "#e8eaf6" } : { padding: '12px' }} onClick={() => selectTime(item)}>
                                            <p className={"fs12 solt-text"} style={selectedTime === item ? { fontWeight: "600" } : {}}> {item} </p>
                                        </div>
                                    })
                                }

                            </Col>


{/*                         
                            <Col span={24} className="md20">
                                <div className="note-card">
                                    <p className="fs12 fw400 "> Note: You have only 2 remaining mentorship slot booking. </p>
                                </div>
                            </Col> */}

                            <Col span={12} className="md20">
                                <Button type="dashed" onClick={handleCancel}>Close</Button>
                            </Col>
                            <Col span={12} className='text-end md20'>
                                {
                                    !(moment(date?.on_hold_till) > moment()) && !data?.isme &&
                                    <Button type="primary" onClick={() => setPaymentModal(true)}
                                        disabled={selectedTime === null}>{!loader && data?.amount ? "Pay " + data?.amount : "Confirm"}</Button>
                                }

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
            <Modal title="Add collaborator/Invite" open={collaborator} onOk={Ok} onCancel={Cancel} footer={false}>
                <Row>
                    <Col span={24} className='padding25'>
                        <Row className='md15'>
                            <Col span={24} >
                                <p className="fs15 fw500 ">Search people</p>
                            </Col>
                            <Col span={24} className='md15 collabort-input'>
                                <Select style={{ width: "100%" }} loading={nameLoader} size="large" showSearch placeholder="Type name" labelInValue
                                    filterOption={false} onSearch={(evt) => searchCollaborator(evt)} prefix={<MagnifyingGlass size={20} color="#3c3a39" />} notFoundContent={nameLoader ? <Spin size="small" /> : null} onChange={(evt) => setSelectedUser(evt)} showArrow>
                                    {
                                        collaborators.map((d) => {
                                            return <Option value={d.user_code}>{d.fname}</Option>
                                        })
                                    }

                                </Select>

                            </Col>
                            <Col span={24} className='md25 collabort-input'>
                                {selectedUser &&

                                    <p> <Space>
                                        <Avatar src={collaborators.filter((a) => a.user_code === selectedUser).img_url} size={45}></Avatar>
                                        <p className="fs14 fw500 ">{selectedUser.label}</p>
                                    </Space>
                                    </p>
                                }

                            </Col>
                            <Col span={24} className='md45'>
                                <p className="fs15 fw300 text-grey line-h14 ps-2">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used</p>
                            </Col>
                        </Row>
                        <Row className='md40'>
                            <Col span={24} className='text-end '>
                                <Button type="primary" disabled={selectedUser == null ? true : false} onClick={() => sendRequest()}>Send Request</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
            <Modal centered open={paymentModal} maskClosable={false} onCancel={closePayment} footer={false} >
                <PaymentCreditPage onChange={(val) => onCreditUpdate(val)}
                    title={data?.name}
                    entity={"Mentorship"}
                    amount={data?.amount}
                    entity_type="MENTORSHIP"
                    id={params["id"]}
                    slot_id={date?.slot_id}
                    user_code={data?.creator?.user_code}
                    time={selectedTime}
                ></PaymentCreditPage>
            </Modal>
        </Row>
    )
}
export default MentorshipDetails;

