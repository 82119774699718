import { Row, Col, Menu, Drawer, message } from "antd";
import React, { useState, useEffect } from "react";
import ManageMembers from "./ManageMembers";
import ManageNotification from "./ManageNotification";
import ManageSetting from "./ManageSetting";
import ManageTopic from "./ManageTopic";
import ManageWelcome from "./ManageWelcome";
import {  withRouter } from "react-router-dom"
import { connect } from "react-redux";
import actions from "../../../store/actions/index";

import Toast from "../../utils/Toast";

const ChannelManage = (props) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [current, setCurrent] = useState("1");
    const [loader, setLoader] = useState(true);
    const [topicLoaded, setTopicLoaded] = useState(false);
    const [memberdata, setMemberData] = useState([]);
    const [channel, setChannel] = useState({});
    const [id,] = useState(props?.id);


    useEffect(() => {
        setLoader(true);
        props.getChannelSetting({ id: id });
        props.channelsMembers({ id: id })
    }, [id])

    useEffect(() => {
        if (props._channel_settings !== null) {
            setChannel(props._channel_settings);
            setLoader(false);

        }
    }, [props._channel_settings])

    useEffect(() => {
        if (props._members) {
            setMemberData(props._members)
            setLoader(false)
        }
    }, [props._members])



    const settings_menu = (
        <Menu className="setting-menu" defaultSelectedKeys={[current]} onSelect={(e) => onSelectTab(e)}>

            <Menu.Item key={'1'}>Settings</Menu.Item>
            <Menu.Item key={'2'}>Members</Menu.Item>
            <Menu.Item key={'5'}>Topics</Menu.Item>
            <Menu.Item key={'3'}>Notification </Menu.Item>
            <Menu.Item key={'4'}>Welcome</Menu.Item>

        </Menu>
    )

    function onSelectTab(e) {
        setCurrent(e.key)
        setOpenDrawer(false)
    }


    async function updateChannel(checked, evt) {
        var values = {}
        if (evt === 'name') {
            values = { id: id, ...checked }
        } else if (evt === 'member_list') {
            values = { id: id, show_member_online_list: checked }
        } else if (evt === 'private') {
            values = { id: id, private: checked }
        }
        else if (evt === 'show_chat_history') {
            values = { id: id, show_chat_history: checked }
        } else if (evt === 'banner') {
            values = { id: id, banner_img_url: checked }
        }
        else if (evt === 'category') {
            values = { id: id, category: checked }

        } else if (evt === 'img_url') {
            values = { id: id, img_url: checked }

        } else if (evt === 'is_paid') {
            values = { id: id, is_paid: checked }
        }
        else if (evt === 'amount') {
            values = { id: id, ...checked }
        }
        else if (evt === 'welcome') {
            values = { id: id, ...checked }
        }
        setLoader(true);
        await props.ChannelSetting(values).then(() => Toast("Saved!","success"));
        await props.getChannelSetting({ id: id });
        setLoader(false);

        if (evt === "name" || evt === "banner") {
            props.refreshPage();
        }
    }

    return (
        <Row>
            <Col span={24} className="manage-card md30">
                <Row>
                    <Col xs={{ span: 0 }} lg={{ span: 6 }} md={{ span: 6 }} className=" border-right py40 ">
                        {settings_menu}
                        <Drawer size='default' placement="left" onClose={() => setOpenDrawer(false)} visible={openDrawer} >
                            <Row>
                                <Col span={24} className="px-3 md10">
                                    {settings_menu}

                                </Col>
                            </Row>
                        </Drawer>

                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 18 }} md={{ span: 18 }}>
                        {current === '1' && <ManageSetting channel={channel} loader={loader} deleteChannel={() => props.deleteChannel({ id: id })} updateChannel={updateChannel} />}
                        {current === '3' && <ManageNotification />}
                        {current === '4' && <ManageWelcome channel={channel} loader={loader} updateChannel={updateChannel} />}
                        {current === '2' && <ManageMembers members={memberdata} loader={loader} />}
                        {current === '5' && <ManageTopic topicLoaded={topicLoaded} setTopicLoaded={setTopicLoaded} categories={channel?.categories} updateChannel={updateChannel} />}


                        {/*
                         */}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
const mapStateToProps = (state) => ({
    _members: state.channel.channel_members,
    _channel_settings: state.channel.channel_settings
});
const mapDispatchToProps = (dispatch) => ({
    ChannelSetting: (v) => dispatch(actions.ChannelSetting(v)),
    getChannelSetting: (v) => dispatch(actions.getChannelSetting(v)),
    deleteChannel: (v) => dispatch(actions.deleteChannel(v)),
    channelsMembers: (v) => dispatch(actions.channelsMembers(v)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ChannelManage)
);
