import { Col, Divider, Row, Switch, Collapse, Checkbox } from "antd";
import React from "react";

const { Panel } = Collapse;

const EmailSettings = () => {
    return (
        <Row style={{ padding: "40px 0px 0px 30px" }} className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18 fw400"> Email Settings </p>
                <Row>
                    <Col span={18}>
                        <p className="text-grey ">Manage Your Email Preferences</p>
                    </Col>
                </Row>
            </Col>
            <Divider />
            <Col span={24}>
                <Row>
                    <Col span={19}>
                        <p className="fs16 fw500"> Emails from Syllo </p>
                        <p className="fs14 fw400 md15"> Weekly newsletters </p>
                        <p className="fs12 fw300 md10">The best stories on Syllo personalized based on your interests, as well as outstanding stories selected by our editors. </p>
                    </Col>
                    <Col span={5} className="text-right md25">
                        <span > <Switch /> </span>
                    </Col>
                </Row>
                {/* <Row>
                    <Col span={19}>
                        <p className="fs14 fw400 md20">Recommended reading</p>
                        <p className="fs12 fw300 md10">Featured newsletters, notes, podcasts, polls, research reports and collections that we think you’ll enjoy based on your reading history. </p>
                    </Col>
                    <Col span={5} className="text-right md25">
                        <span > <Switch /> </span>
                    </Col>
                </Row> */}
                <Row>
                    <Col span={20} className='md10'>
                        <div className="md5 membership-collapse mb-2">

                            <Collapse bordered={false} collapsible="header" defaultActiveKey={[]}>
                                <Panel header="Custom" className="md5" key="1">
                                    <Divider className="m-0" />
                                    <div className="md5">
                                        <Checkbox.Group >
                                            <span className="checkbox fs14 fw400"><Checkbox value={"entity=2"}> Newsletters </Checkbox></span> <br />
                                            <span className="checkbox fs14 fw400"><Checkbox value={"entity=1"}>Newsletters </Checkbox></span> <br />
                                            <span className="checkbox fs14 fw400"><Checkbox value={"entity=3"}> Notes </Checkbox></span> <br />
                                            <span className="checkbox fs14 fw400"><Checkbox value={"entity=7"}> Podcasts </Checkbox></span> <br />

                                        </Checkbox.Group>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </Col>
                </Row>
                <Divider />
            </Col>
            <Col span={24} className='md10'>

                <Row>
                    <Col span={19}>
                        <p className="fs16 fw500"> Emails from writers and publications </p>
                        <p className="fs14 fw400 md20">Emails from writers</p>
                        <p className="fs12 fw300 md10">Receive emails from writers you’ve subscribed to when they publish- </p>
                    </Col>
                    <Col span={5} className="text-right md25">
                        <span > <Switch /> </span>
                    </Col>
                </Row>
                <Row>
                    <Col span={20} className='md10'>
                        <div className="md5 membership-collapse mb-2">

                            <Collapse bordered={false} collapsible="header" defaultActiveKey={[]}>
                                <Panel header="Custom" className="md5" key="1">
                                    <Divider className="m-0" />
                                    <div className="md5">
                                        <Checkbox.Group >
                                            <span className="checkbox fs14 fw400"><Checkbox value={"entity=2"}> Newsletters </Checkbox></span> <br />
                                            <span className="checkbox fs14 fw400"><Checkbox value={"entity=1"}>Newsletters </Checkbox></span> <br />
                                            <span className="checkbox fs14 fw400"><Checkbox value={"entity=3"}> Notes </Checkbox></span> <br />
                                            <span className="checkbox fs14 fw400"><Checkbox value={"entity=7"}> Podcasts </Checkbox></span> <br />

                                        </Checkbox.Group>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </Col>
                </Row>

            </Col>

            {/* <Divider /> */}


        </Row>
    );
}
export default EmailSettings;