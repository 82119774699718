import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    games_categories: [],
    games_rewards:[],
    game_theme_response:[]
}
const GamifyReducer = handleActions({
    ALL_GAMES_CATEGORIES: (state, action) => {
        return { ...state, games_categories: action.payload };
    },
    ALL_GAMES_REWARDS :(state, action) => {
        return { ...state, games_rewards: action.payload };
    },
    CREATE_THEME:(state, action) => {
        return { ...state, game_theme_response: action.payload };
    },
}, INITIAL_STATE);

export default GamifyReducer;
