import React, { PureComponent } from "react";
import { Avatar, Divider,Popover } from "antd"
import Cookies from "js-cookie"
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router-dom"
import SkeletonAvatar from "antd/lib/skeleton/Avatar";
import { BookmarkSimple, Compass, GameController, GridFour, House, MonitorPlay } from "@phosphor-icons/react";
import {Link} from "react-router-dom"

class FixedNavigation extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            subscribed_channels: [],
            loader: true,role:""
        };
    }

    componentDidMount() {
        
        this.props.CreatorDetails().then(() => {
            this.setState({
                subscribed_channels: this.props._channel_details, loader: false
            })
        })

        let data = JSON.parse(localStorage.getItem("profile"))
        this.setState({
            role:data?.role
        })
    }

    Logout() {
        Cookies.remove("Logintoken");
    Cookies.remove("CSRFToken");
    Cookies.remove("channels");
    Cookies.remove("user_pic");
    Cookies.remove("user_code");
    Cookies.remove("user_name");
    Cookies.remove("_role");
    localStorage.removeItem("profile");
    localStorage.removeItem("channels");
    window.location.href = "/login";
    }


    render() {
        
        return (
            <div className="row m-0 navigation-sidebar ">
                <div className="col-md-12 pe-0 pt-4" style={{ borderBottom: "1px solid #f0f0f0", height: "60px", backgroundColor: "#f0f0f0" }}>
                    {/* <img src={require("../../assets/images/logo.svg")} alt="logo" width={"50%"} style={{marginTop:"-10px"}}></img> */}
                </div>

                <div className={this.props.dashboard ? "col-md-12 pe-0 text-center md50 active" : "col-md-12 pe-0 text-center md50 non-active"}>

                    <Link to="/dashboard" className="sub-text text-center" style={{ fontSize: "14px" }}>
                    <House size={30} weight="thin" />
                   
                        {/* <Avatar size={30} shape="square" src={require("../../assets/images/navigation/home (1).svg")}>   </Avatar>  <br></br> */}
                        {/* Dashboard */}
                    </Link>

                </div>




                <div className={this.props.explore ? "col-md-12 pe-0 md35 text-center active" : "col-md-12 pe-0 md35 text-center non-active"}>

                    <Link to="/explore" className="sub-text text-center " style={{ fontSize: "14px" }}>
                    <Compass size={30} weight="thin" />                      
                       
                    </Link>

                </div>



                <div className={this.props.live ? "col-md-12 pe-0 md35 text-center active" : "col-md-12 pe-0 md35 text-center non-active"} >
                    <Link to="/live-talks" className="sub-text text-center" style={{ fontSize: "14px" }}>
                    <MonitorPlay size={30} weight="thin" /> 
                    </Link>
                </div>





               {
                //    this.state.role === 8 && 
                    <div className={this.props.channels ? "col-md-12 pe-0 md35 text-center active" : "col-md-12 pe-0 md35 text-center non-active"}>
                   <Link to="/my-hub" className="sub-text text-center" style={{ fontSize: "14px" }}>
                   <GridFour size={29} weight="thin" />
                       {/* My Zone */}
                   </Link>

               </div>
               }
               
                <div className={this.props.bookmarked ? "col-md-12 pe-0 md35 text-center active" : "col-md-12 pe-0 md35 text-center non-active"}>
                <Link to="/bookmarked" className="sub-text text-center" style={{ fontSize: "14px" }}>
                <BookmarkSimple size={30} weight="thin" />
                   </Link>
                </div>


                <div className={this.props.games ? "col-md-12 pe-0 md35 text-center active" : "col-md-12 pe-0 md35 text-center non-active"}>
                <a href={`https://games.yuja.one/dashboard`} target={"_blank"} className="sub-text text-center" style={{ fontSize: "14px" }}>
                <GameController size={30}  weight="thin" />
                   </a>
                </div>
               


                <div className="col-md-10 offset-md-1 text-center ps-4 pe-2" >
                    <Divider style={{ border: "1px solid #dddcdc" }}></Divider>
                </div>

                {
                    this.state.loader ? <div className="col-md-12 pe-0 text-center">
                        <SkeletonAvatar active></SkeletonAvatar>
                    </div> : <>{
                        this.state.subscribed_channels.slice(0, 2).map((item, index) => 
                        {
                            const content = (
                               <>
                                      <a key={index} href={`/channel/${item.uid}/${item.channel_id}`}><Avatar className="bordered" size={40} shape="circle" src={item.img_url}>  </Avatar></a> <br></br>
                                      {/* <Avatar className="bordered md5" size={40} shape="circle" src={item?.live?.img_url}>  </Avatar> */}
                                      
                               </>
                              );
                            return <div key={index} className="col-md-10 offset-md-1 text-center ps-4 pe-2 md20" > 
                            {
                                item.live === null ?  <a style={{marginLeft:"-10px"}} href={`/channel/${item.uid}/${item.channel_id}`}><Avatar className="bordered" size={40} shape="circle"  src={item.img_url}>  </Avatar></a> : <Popover placement="bottom" overlayClassName={"spaces-card"} content={content} trigger="click">
                                <Avatar className="bordered" size={40} shape="circle" src={item.img_url}>  </Avatar>
                              </Popover>
                            }
                            </div>
                        })
                    } {

                        }  </>
                }





                {
                    this.state.subscribed_channels.length > 4 && <div className="col-md-12 pe-0 md35 text-center">
                        <Link href={"/channels"}>See All</Link>
                    </div>
                }






                {/* <div className="col-md-12 text-center md10 cursor" style={{ position: "absolute", bottom: "10px",width:"70%" }} onClick={() => this.Logout()}>
              
                      
                        <Avatar  size={30} shape="square"  src={require("../../assets/images/navigation/icon6.png")}>  </Avatar> 
                       
                       
                   
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    _channel_details: state.channel.creator_details,
});
const mapDispatchToProps = (dispatch) => ({
    CreatorDetails: (v) => dispatch(actions.CreatorDetails(v)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FixedNavigation));
