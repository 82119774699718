import { Col, Row, Tabs, Space, Button } from "antd";
import React from "react";
import KidsBudgeting from "./KidsBudgeting";
import KidsPremium from "./KidsPremium";

const { TabPane } = Tabs;



const KidsCampus = () => {

    

    return (
        <Row>
            <Col span={24} className="camp-kids-bg" style={{ height: '350px' }}>
                <Row >
                    <Col span={16} offset={4} className="md80">
                        <Space size={24} >
                            <Button className="Radius8 text-grey fs14 fw600">Campus</Button>
                            <Button type="text" className="kids">Campus for kids</Button>
                        </Space>
                        <Row className="md40">
                            <Col span={24}>
                                <Tabs defaultActiveKey="2">
                                    <TabPane tab="Topics" key="1">
                                        <KidsBudgeting />
                                    </TabPane>
                                    <TabPane tab="Kids Premium" key="2">
                                        <KidsPremium />
                                    </TabPane>
                                    <TabPane tab="Kids Star" key="3">
                                        Tab 3
                                    </TabPane>
                                    <TabPane tab="Expert" key="4">
                                        <KidsPremium />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Col>
           
        </Row>
    );
}
export default KidsCampus;