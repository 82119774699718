import React from "react";
import { Tag, Space, Divider,  Avatar, Button } from 'antd';
import moment from 'moment';
import { RWebShare } from "react-web-share";
import { Heart, Export, BookmarkSimple, ChatCircle, StarFour } from "@phosphor-icons/react";



const Reels = ({ item, getFilterByTags, details, likeFeed, DeleteFeed, SaveFeed, tags, showLike, showFooter }) => {
    return <div className="row">
        <div className="col-lg-1 col-xsm-2 md10">
            <Avatar size="large" src={item.profile_pic}></Avatar>
        </div>
        <div className="col-lg-11 col-xsm-10 md10 ">
            {/* <p className="post-top-text m-md10">  <Tag color="geekblue">{item.entity_type}</Tag>   
                <sub>
                    <a className="margin-right cursor m-fs14 feed-text" href={"/profile/" + item?.user_code}>{item.name}</a>
                </sub>
                <span className="">{item?.paid_content && <StarFour size={20} color="#f8d03f" weight="fill" />}</span>
                <span className="fs14 m-fs14" style={{ float: "right", paddingRight: '12px', marginTop: '10px' }}>{moment(item.published_at).fromNow()}</span>
            </p> */}
            <span className="post-top-text m-md10">
                <sub className="md5">
                    <a className="margin-right cursor m-fs14" href={"/profile/" + item?.user_code} >{item.name}</a>
                </sub>
                <Tag  color="geekblue" style={{ float: "right", paddingRight: '12px' }}>{item.entity_type}</Tag>
                <p className="">{item?.paid_content && <StarFour size={20} color="#f8d03f" weight="fill" />}</p>
                <p className="fs14 m-fs14 md5" >{moment(item.published_at).fromNow()}</p>

            </span>

            <h6 className="news-header cursor mt-1 m-fs16 md10" onClick={() => details(item)}>{item.title}</h6>


            <div className="row" >
                <div className="col-md-12">
                    <div className=" reels-card">
                        <div className="reel-video">
                            <video src={item?.link} autoPlay={false} controlsList="nodownload" controls style={{ height: "100%", width: "100%" }}></video>
                        </div>

                    </div>

                </div>


                {
                    showFooter !== false && <div className="col-md-12 md20 nopadding">

                        <div className="row nomargin">

                            <Space className="m-justify-space-bw" split={<Divider type="vertical" />} size="small" >
                                <div>
                                    <Space>
                                        <Button type={"text"} className={"blue-hover"} shape={"circle"} onClick={() => details(item)} icon={<ChatCircle size={22} weight="thin" className={"border-blue"} />}></Button>
                                        {item.comments === 0 ? "" : item.comments}</Space>
                                </div>
                                <div>
                                    <p className="comment"><span>
                                        {
                                            item.liked === true ?
                                                <Button type={"text"} className={"heart-hover"} shape={"circle"} icon={<Heart size={22} weight="fill"  color={"#e71313"}  />} onClick={() => likeFeed(false, item)}>     </Button>
                                                : <Button type={"text"} className={"heart-hover"} shape={"circle"} icon={<Heart size={22} weight="thin" className={"border-red"}   />} onClick={() => likeFeed(true, item)}>     </Button>
                                        }
                                    </span> {item.likes === 0 ? "" : item.likes}</p>
                                </div>
                                <div>
                                    <RWebShare
                                        data={{
                                            text: item.description,
                                            url: `${process.env.REACT_APP_URL}feed-view/` + item?.entity_id,
                                            title: item.title,
                                        }}
                                        // onClick={() => console.log("shared successfully!")}
                                    >
                                        <Button type={"text"} className={"blue-hover"} shape={"circle"} icon={<Export className={"border-blue"} size={22} weight="thin" />}>
                                        </Button>
                                    </RWebShare>

                                </div>
                                <div>
                                {
                                        !item.saved &&  showLike &&
                                        <Button type={"text"} className={"blue-hover"} shape={"circle"} onClick={() => SaveFeed(item.entity_id, item.entity_type)} icon={<BookmarkSimple className={"border-blue"} size={22} weight="thin" />}>
                                        </Button> 
                                    }


                                    {
                                    !showLike  && item.saved && <Button type={"text"} className={"blue-hover"} shape={"circle"} onClick={() => DeleteFeed(item.entity_id, item.entity_type)} icon={<BookmarkSimple className={"border-blue"} size={22} weight="thin" />}>
                                    </Button>
                                    }

                                </div>
                            </Space>
                        </div>

                    </div>
                }


            </div>
        </div>

    </div>
}
export default Reels;