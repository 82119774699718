import { CaretRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { ArrowLeft, CellSignalMedium, Checks, Clock, DotsThreeVertical, Pencil, ShareNetwork, Translate, Trash, Wallet } from "@phosphor-icons/react";
import { Affix, Anchor, Button, Card, Col, Collapse, Dropdown, Modal, Row, Skeleton, Space, Tag, Typography, Form, Input } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Toast from "../utils/Toast";
import LessonContent from "./LessonContent";
import { useHistory } from "react-router-dom";
import PaymentCreditPage from "../utils/paymentCredit";
import { RWebShare } from "react-web-share";
import axiosInstance from "../../store/axios";
import useToken from "../../store/useToken";


const { Link } = Anchor
const { Panel } = Collapse;



const CourseDetails = () => {
    const history = useHistory();
    const [data, setData] = useState({});
    const [paymentModal, setPaymentModal] = useState(false);
    const params = useParams();
    const [modal, contextHolder] = Modal.useModal();
    const [loader, setloader] = useState(true);
    const [loader2, setloader2] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [add_form] = Form.useForm();
    const { token } = useToken();

    const showModal = () => {
        setAddModal(true);
    };


    const handleCancel = () => {
        setAddModal(false);
    };

    const formProps = {
        name: 'validateOnly',
        layout: 'vertical',
        requiredMark: false
    }

    const onFinish = async (values) => {
        const payload = { learnings: [values] };
        setloader2(true);
        await axiosInstance.post(`v1.0/course/${params['id']}/learnings`,
            payload, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setloader2(false);
            setAddModal(false);
            getData();
            add_form.resetFields()
        });
    };

    const deleteLearning = async (pk) => {
        await axiosInstance.delete(`v1.0/course/${params['id']}/learnings?learning_id=${pk}`,
            {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            getData();

        });
    }


    useEffect(() => {
        const script = document.createElement('script');

        script.type = "text/javascript";
        script.src = "https://embed.dyntube.com/v1.0/dyntube.js";
        script.async = !0;

        document.getElementsByTagName("head")[0].appendChild(script)
        return () => {
            document.getElementsByTagName("head")[0].removeChild(script);
        }
    }, []);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setloader(true)
        await axiosInstance({
            method: 'get',
            url: "v1.0/course/" + params['id'],
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setloader(false);
            setData(data);
        }).catch((err) => {
            // history.goBack()
            console.log(err)
            Toast(err.response.data.message, "error");
            setloader(false)
        })
    }

    const deleteCourse = async () => {
        setloader(true);
        await axios({
            method: 'delete',
            url:  "v1.0/course",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setloader(false);
            history.goBack();
        }).catch((err) => {
            // history.goBack()
            console.log(err)
            setloader(false)
        })
    }

    const closePayment = () => {
        setPaymentModal(false);
    };

    const onCreditUpdate = (res) => {
        setPaymentModal(res.modal);
        if (res.status === "success") {
            // Toast("Booking successfully done!", "success");
            //   this.componentDidMount();
            window.location.reload();
            // history.push(`/mentorship-booking/${res?.booking_id}`)
        }
    }

    const confirmDelete = () => {
        modal.confirm({
            centered: true,
            icon: <></>,
            content: <>
                <div >
                    Are you sure? Want to delete this course? <br /> <p className="fw600">{data?.title}</p>
                </div>
            </>,
            className: "mentorship-delete",
            okText: 'Delete Course',
            cancelText: 'Cancel',
            onOk: (() => {
                deleteCourse();
            })
        });
    };

    const onClick = async ({ key, id }) => {
        if (key === '1') {
            history.push(`/course/${id}/edit`)
        } else if (key === '2') {
            confirmDelete();
        }
    };

    const items = (item) => {

        return [
            {
                label: (
                    <span className=" fw400"> Update</span>
                ),
                key: '1',
                icon: <Pencil size={18} color="#686464" weight="light" />

            },
            {
                label: (
                    <span className=" fw400"> Delete</span>
                ),
                key: '2',
                icon: <Trash size={18} color="#686464" weight="light" />

            }

        ]
    };


    return (
        <Row style={{ backgroundColor: '#F7F7F9', minHeight: "calc(100vh - 64px)" }}>
            {contextHolder}
            {
                loader ? <Col lg={{ span: 18, offset: 3 }} xs={{ span: 24, offset: 0 }} className="md45">
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </Col> :

                    <Col lg={{span:24, offset:0}} xs={{span:22, offset:1}}>
                        <Row>
                            <Col lg={{ span: 18, offset: 3 }} xs={{ span: 24, offset: 0 }} className="md60">
                                <Button className="p-0 " type={"text"}
                                    icon={<ArrowLeft size={17} style={{ marginRight: "5px" }} />}
                                    onClick={() => history.goBack()}> Back</Button>
                                <Row className="md5" gutter={[12, 12]}>
                                    <Col lg={{ span: 15, }} xs={{ span: 24 }} >
                                        <Row>
                                            <Col span={24}>
                                                <p className="fs30 fw700" color="#728096">{data?.title}  </p>
                                                <p className="fs16 dark-grey md10" > By <a href={`/${data?.creator?.is_business ? 'business' : 'profile'}/${data?.creator?.user_code}`}>{data?.creator?.full_name}</a> </p>
                                            </Col>
                                            <Col span={24} className="course-details-tag md15">
                                                {
                                                    data?.tags && data?.tags.slice(0, 2
                                                    ).map((tag, i) => {
                                                        return <Tag key={`tags-${i}`}>{tag}</Tag>
                                                    })
                                                }
                                            </Col>
                                            <Col span={24} className="md25">
                                                <Space size={12}>
                                                    {
                                                        !data?.isme && <>
                                                            {
                                                                data?.enrolled ? <Button type="primary"> Resume </Button> :
                                                                    <Button type="primary" onClick={() => setPaymentModal(true)}> Register now </Button>
                                                            }
                                                        </>
                                                    }

                                                    <RWebShare
                                                        data={{
                                                            text: `By ${data?.creator?.full_name}`,
                                                            url: `${process.env.REACT_APP_URL}course/${data?.course_id}`,
                                                            title: `Course: ${data?.title}`,
                                                        }}

                                                    ><Button type="default" className="share-btn" icon={<ShareNetwork size={20} color="#1F1F1F" />}> </Button>
                                                    </RWebShare>

                                                    {
                                                        data?.isme &&
                                                        <Dropdown trigger={['click']} menu={{ items: items(data), onClick: ({ key }) => onClick({ key: key, id: data?.course_id }) }} placement={"bottom"} overlayStyle={{ minWidth: "120px" }}>
                                                            <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} weight="bold" />} />
                                                        </Dropdown>
                                                    }

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={{ span: 9, }} xs={{ span: 24 }} className="text-right">
                                        <img src={data?.img_url} alt="" style={{ width: "100%", borderRadius: "8px", height: "215px", objectFit: "cover" }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="md45 course-detail-shadow">
                            <Col lg={{ span: 18, offset: 3 }} xs={{ span: 24, offset: 0 }}>
                                <Row>
                                    <Col lg={{span:6}} xs={{span:8}} >
                                        <Row gutter={16}>
                                            <Col span={3}>
                                                <Wallet size={18} color="#929EAE" />
                                            </Col>
                                            <Col span={21}>
                                                <p className="fs14 fw400"> Price</p>
                                                <p className="fs14 fw400"> {data?.is_paid ? `${data?.amount} Coins` : "Free"} </p>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col lg={{span:6}} xs={{span:8}}>
                                        <Row gutter={16}>
                                            <Col span={3}>
                                                <Clock size={18} color="#929EAE" />
                                            </Col>
                                            <Col span={21}>
                                                <p className="fs14 fw400"> Duration</p>
                                                <p className="fs14 fw400"> {data?.duration}</p>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col lg={{span:6}} xs={{span:8}}>
                                        <Row gutter={16}>
                                            <Col span={3}>
                                                <CellSignalMedium size={20} color="#929EAE" />
                                            </Col>
                                            <Col span={21}>

                                                <p className="fs14 fw400"> Course level</p>
                                                <p className="fs14 fw400"> {data?.level}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={{span:6}} xs={{span:8}} className="m-mt10">
                                        <Row gutter={16}>
                                            <Col span={3}>
                                                <Translate size={18} color="#929EAE" />
                                            </Col>
                                            <Col span={21}>
                                                <p className="fs14 fw400"> Language</p>
                                                <p className="fs14 fw400"> {data?.language}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ padding: '45px 20px' }}>
                            <Col lg={{ span: 18, offset: 3 }} xs={{ span: 24, offset: 0 }}>
                                <Row gutter={24}>
                                    <Col lg={{span:17}} xs={{span:24}}>
                                        <Row>
                                            <Col span={24} id="about">
                                                <p className="fs18 fw600"> About Course </p>
                                                <p className="fs16 fw300">
                                                    {data?.description}
                                                </p>

                                            </Col>
                                            {
                                                (data?.isme || (data?.learnings && data?.learnings.length > 0)) &&

                                                <Col lg={{span:24}} xs={{span:24}} id="what-yll-learn">
                                                    <p className="fs18 fw600 md20"> What will you learn </p>
                                                    <Card
                                                        style={{
                                                            marginTop: '15px',
                                                            padding: '25px'
                                                        }}
                                                    >
                                                        <Row gutter={[12, 12]}>
                                                            {
                                                                data?.learnings && data?.learnings.map((item, i) => {
                                                                    return <Col lg={{span:12}} xs={{span:24}} key={`syll-${i}`}>
                                                                        <Space align="start" aria-orientation="horizontal">
                                                                            <Checks size={24} color="#3B6BE3" />
                                                                            <span className="fs14 fw400 "> {item?.learning} </span>
                                                                            {
                                                                                data?.isme && <Button onClick={() => deleteLearning(item?.pk)} type="text" icon={<DeleteOutlined />} />
                                                                            }

                                                                        </Space>
                                                                    </Col>
                                                                })
                                                            }
                                                            {
                                                                data?.isme && <Col span={24} className="text-center md20">
                                                                    {
                                                                        data?.learnings && data?.learnings.length === 0 && <><Typography.Text>Result not found</Typography.Text> <br /></>
                                                                    }

                                                                    <Button type="primary" className="md10" onClick={showModal} > Add </Button>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            }
                                            <Col lg={{span:24}} xs={{span:24}} className="course-details-tag md25" id="topics">
                                                <p className="fs18 fw600"> Topics </p>
                                                <Space className="md10">
                                                    {
                                                        data?.tags && data?.tags.map((tag, i) => {
                                                            return <Tag key={`tags-${i}`}>{tag}</Tag>
                                                        })
                                                    }
                                                </Space>
                                            </Col>
                                            <Col lg={{span:24}} xs={{span:24}} className=" md30" id="content">
                                                <p className="fs18 fw600"> Course Content </p>
                                                <Collapse
                                                    bordered={false}
                                                    defaultActiveKey={['1']}
                                                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                    className="coll-top md15" style={{ backgroundColor: '#ffffff', borderRadius: "8px" }}


                                                >
                                                    {
                                                        data?.lessons && data?.lessons.map((item, index) => {
                                                            return <Panel key={index} header={(index + 1) + " - " + item?.title} className="site-collapse-custom-panel">
                                                                <p className="text-grey">{item.description}</p>

                                                                {
                                                                    item?.contents.map((content, ind) => {
                                                                        return <LessonContent key={ind} lesson={item} content={content} />
                                                                    })
                                                                }


                                                            </Panel>
                                                        })
                                                    }
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={{span:7}} xs={{span:24}}>
                                        <Affix offsetTop={64}>
                                            <Card
                                                style={{
                                                    marginTop: '15px',
                                                    padding: '25px'
                                                }}>
                                                <p className="fs15 fw500 dark-grey">{data?.title}</p>
                                                <Anchor>
                                                    <Link href="#about" className="md10" title="About Course" />
                                                    <Link href="#what-yll-learn" title="What will you learn" />
                                                    <Link href="#topics" title="Topics" />
                                                    <Link href="#content" title="Course Content" />

                                                </Anchor>,
                                            </Card>
                                        </Affix>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Col >
            }
            <Modal centered open={paymentModal} maskClosable={false} onCancel={closePayment} footer={false} >
                <PaymentCreditPage onChange={(val) => onCreditUpdate(val)}
                    title={data?.title}
                    entity={"Campus"}
                    amount={data?.amount}
                    entity_type="COURSE"
                    id={data?.course_id}
                ></PaymentCreditPage>
            </Modal>
            <Modal title="Add learning text" open={addModal} onOk={() => add_form.submit()} onCancel={handleCancel} okText="Save" okButtonProps={{ htmlType: "submit", loading: loader2 }}>
                <Row>
                    <Col span={24} style={{ padding: "25px 25px 0 25px" }}>
                        <Form form={add_form} {...formProps} onFinish={onFinish}>
                            <Form.Item name="title" required rules={[{ required: true, }, { max: 200, }]}>
                                <Input.TextArea rows={3} maxLength={200} placeholder="Write here " />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </Row >
    );
}
export default CourseDetails;