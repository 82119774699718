import { Col, Row, Skeleton } from "antd";
import React from "react";

const BusinessSkeleton = () => {
    return (
        <Row style={{ minHeight: "calc(100vh - 69px)" }}>
            <Col span={24} className="business-about-card">
                <Skeleton active />
            </Col>
            <Col md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }} className="md20">
                <div style={{ marginTop: '-95px' }}>
                    <Skeleton.Avatar size={140} active />
                </div>
                <Row className="md25">
                    <Col xs={24} md={16}>
                        <Skeleton.Input active size="large" /> <br />
                        <Skeleton.Input active size="small" className="md10" />
                        <Skeleton.Input active block className="md10" />

                    </Col>
                    <Col xs={24} md={8} className="md25 text-right m-text-left">
                        <Skeleton.Button shape="circle" active />
                        <Skeleton.Button shape="circle" active /> <br />
                        <Skeleton.Button active size="large" className="md10" /> {' '}
                        <Skeleton.Button active size="large" className="md10"/>
                    </Col>
                </Row>
                <Row>
                <Col span={24} className="md50">
                <Skeleton active />
                <Skeleton active />
            </Col>
                </Row>
            </Col>
            
        </Row>
    );
}
export default BusinessSkeleton;