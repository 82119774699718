import React, { useState, useEffect } from 'react';
import { Row, Col, Progress, Button, Skeleton } from 'antd';
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import moment from 'moment';


var url_string = window.location.href
var url = new URL(url_string);
var _id = url.searchParams.get("id");
const CHARS = ['A', 'B', "C", 'D', 'E', 'F', "G", 'H', 'I', 'J', 'K', 'L']

const PollView = (props) => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState(null)

    useEffect(() => {
        if (_id === null){
            props.history.push({
                pathname: "/my-hub",
            });
            return
        }
        
        setLoader(true)
        props.NewsletterDetails({id:_id})
    }, [])


    useEffect(()=>{
        if(props?._newsletterdetails.length !== 0){
            setData(props?._newsletterdetails)
            setLoader(false)
            // console.log(props?._newsletterdetails)
        }
    },[props])

    return (

        <Row>
            <Col span={24} className="">
                <Row>
                    
                    <Col lg={22} xs={24} >
                        <Row>
                            <Col span={24} className="channel-details-v3" style={{ backgroundColor: "white" }}>

                            
                                <Row className='md50'>
                                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4}}>
                                        <Row className='md50 m-px10' >
                                            <Col lg={1} xs={2}>
                                                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                                                    <rect width="24" height="24" rx="4" fill="#FFE4CC" />
                                                    <rect x="4" y="20" width="16" height="16" transform="rotate(-90 4 20)" fill="url(#pattern0)" />
                                                    <defs>
                                                        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                            <use href="#image0_0_1" transform="scale(0.0208333)" />
                                                        </pattern>
                                                        <image id="image0_0_1" width="48" height="48" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACa0lEQVRoge2YsWsTURzHP++lLXGwCg7apNAk7Rbav0Bdqh2chEzuLh0UHEMlPAvRzcGlgzq7CE4KQnWxf0Ehi9ALjW2CQ4fEoUF79xzMaQ15l975LiF4n+kd9/vd7/vl/d6D30FCQkLCOBHDAloql9NalNHiFjAHpGLW5AIt4C2ufpKp1veDggMNNFV+DU+8Bs5bFBiGjpaUssrZNgUYDbRULqc9ucv4xPt0kCxnlNMY9HLKlKW1KPNHfF1KcfeI9E5R1b7HodKnpoozF7zjaxJeADlgFk0ZWB8Ub9yBZqXwBZgHkFKsXlF7H+MQbOKgUliV4LdOI7PpLAyKkwHfmPMXR6R3bIo7C2157tOpx6wpLsjA79sm7rYZRF9N480XZGAiMB7iIJqVwmPgAZAOmdpFiKeZR3sbUeoOIvQO6F8H/x7hxQOk0fp+hDwjoQ0I0AjxDOhGqNft5VojUgv1WsBaG/wLE3+IEwPjZuINRDrEXx8uLXrSve0hIuX3I9EnUk6/uaw+O2FzIwlwpfcexOLQaeiMaASud7IOLIXN/T9bKCWn1lzvxx2BsDLsaPS3lJx+FSU3koFer1aj5Npm4lsoMTBuJt5AXAON9cHFRFwDjfXBxURcA431wcVEMtCMmyADrr+oqeLMCLT8RV9N1xQXZKDpLy7RvWpDVBgucnz91OOBKS7IwDt/4Xn65aEq3BjFTtRUceawkr8pPJ4P0tKP+efuRn6BlNgFZm0KjEAbyYrp97pxBzLV+r6WlIBObNKG0/agZBIPQ26hrHK2kSwDW0CDgMNkEbdXawvJyvym82EENRMSEhIi8hN4PapkF1wPoAAAAABJRU5ErkJggg==" />
                                                    </defs>
                                                </svg>
                                            </Col>
                                            <Col lg={23} xs={22}>
                                                <h4 className='fs16 ms-2 pt-1 mb-0 m-pl10' style={{ color: 'black' }}>Poll
                                                    <div className="dot-small"></div>
                                                    <span className='fs14 ms-2' style={{ color: '#757474' }}>{data !== null ? moment(data.created_at).format('Do MMMM YYYY') : ""}</span>
                                                </h4>
                                            </Col>
                                        </Row>
                                        <Row className='md20  m-px10'>
                                            <Col lg={22} xs={24} className='poll-box'  >
                                                {
                                                    loader ? <> <Skeleton active></Skeleton> <Skeleton active></Skeleton> </> 
                                                    : <>
                                                    <Col span={24} >
                                                    <p className='fs16'> {data?.title} </p>
                                                    <Row className='p-0 md15'>

                                                        {
                                                            data.choices.map((item, index)=>{
                                                                return <><Col span={1} className='p-0 text-center mt-2 option-count' style={{ backgroundColor: '#F0F1FF' }} >
                                                                <p className='p-0 text-center '> {CHARS[index]} </p>
                                                            </Col>
                                                            <Col span={23} className='fs16 p-0  pollv2-progressbar'  >
    
                                                                <Progress strokeColor="#caceff" percent={item.p} className='p-2' strokeLinecap="square" strokeWidth={30} >
                                                                </Progress>
                                                                <p className='ps-3 option'>{item.choice}</p>
    
                                                            </Col></>
                                                            })
                                                        }
                                                        
                                                        {/* <Col span={1} className='p-0 text-center mt-2 option-count' style={{ backgroundColor: '#F0F1FF' }} >
                                                            <p className='p-0 text-center '> B </p>
                                                        </Col>
                                                        <Col span={23} className='fs16 p-0  pollv2-progressbar'  >

                                                            <Progress percent={34} strokeColor="#bdffbd" className='p-2' strokeLinecap="square" strokeWidth={30} >
                                                            </Progress>
                                                            <p className='ps-3 option'>option-countoption-countoption-countoption-countoption-count</p>

                                                        </Col>
                                                        <Col span={1} className='p-0 text-center mt-2 option-count' style={{ backgroundColor: '#F0F1FF' }} >
                                                            <p className='p-0 text-center'> C </p>
                                                        </Col>
                                                        <Col span={23} className='fs16 p-0  pollv2-progressbar'  >
                                                            <Progress strokeColor="#caceff" percent={34} className='p-2' strokeLinecap="square" strokeWidth={30} >
                                                            </Progress>
                                                            <p className='ps-3 option'>option 1</p>
                                                        </Col> */}
                                                    </Row>

                                                </Col>
                                                <Col span={24} className='md40' style={{ backgroundColor: ' #F6F6F6', borderRadius: '8px', minHeight: '120px' }}>
                                                    <Row className='p-4'>
                                                        {/* <Col span={1}></Col> */}
                                                        <Col lg={6} xs={8} className='pt-2'>
                                                            <p className='text-pollv2'>Total Votes</p>
                                                            <p className='fw500'>{data?.vote_count}</p>
                                                        </Col>
                                                        <Col lg={6} xs={8}  className='pt-2'>
                                                            
                                                            <p className='text-pollv2'>Status</p>
                                                            <p className='m-md0' style={{ color: '#EE7103', marginTop: '8px' }}>End</p>
                                                        </Col>
                                                        <Col lg={6} xs={8} className='pt-2'>
                                                            <p className='text-pollv2 m-pt5'>Share</p>
                                                            <div className='text-middle display-galaxy m-md9'>
                                                                <a href=""><img src="https://img.icons8.com/material/344/linkedin--v1.png" alt="" style={{ height: '28px' }} /></a>
                                                                <a href=""><img src="https://img.icons8.com/material/344/twitter--v1.png" alt="" className='m-mx0' style={{ height: '28px', margin: '10px' }} /></a>
                                                                <a href=""><img src="https://img.icons8.com/material/344/facebook--v1.png" alt="" style={{ height: '28px' }} /></a>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6} xs={24} className='text-right p-2 m-text-center md40'>
                                                            <Button type="primary" className='delete-pollv2-btn m-md10-poll'>Delete poll</Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                    </>
                                                }
                                                
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>

    );
}


const mapStateToProps = (state) => ({
    _newsletterdetails: state.newsletter.newsletterdetail
});
const mapDispatchToProps = (dispatch) => ({
    NewsletterDetails: (v) => dispatch(actions.NewsletterDetails(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PollView)
);
