import { Avatar, Button, Col, Divider, Dropdown, Row, Skeleton, List } from "antd";
import { DotsThreeVertical, Export, Prohibit, UserCirclePlus, UserPlus } from "@phosphor-icons/react";
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom"
import { RWebShare } from "react-web-share";
// import { connect } from "react-redux";

const ManageMembers = (props) => {
    const [memberdata, setMemberData] = useState([]);
    const [memberdataNew, setMemberDataNew] = useState([]);
    const [offset, setOffset] = useState(0);

    const params = useParams();

    var _uid = params['uuid'];

    useEffect(() => {
        setMemberData([])
        setMemberDataNew([]);
        setOffset(0);
    }, [_uid]);

    useEffect(() => {
        setMemberData([...memberdata, ...props?.members])
        setMemberDataNew(props?.members);
    }, [props?.members]);

    useEffect(() => {
        console.log(props?.loader)
    }, [props?.loader]);


    const items_video = (item) => {

        let a = [

            {
                key: '1',
                icon: <RWebShare
                    data={{
                        text: "",
                        url: `${process.env.REACT_APP_URL}profile/${item?.user_code}`,
                        title: item?.name,
                    }}

                ><span className="fs12 fw400"> <Export size={18} color="#000000" weight="thin" /> Share</span></RWebShare>
            },

            {
                label: (
                    <span className="fs12 fw400 text-red"> Block User</span>
                ),
                key: '3',
                icon: <Prohibit size={18} color="#DE0905" weight="light" />
            },

        ]

        if (item.following === 0) {
            a.splice(1, 0, {
                label: (
                    <span className="fs12 fw400"> Follow</span>
                ),
                key: '4',
                icon: <UserCirclePlus size={18} color="#000000" weight="light" />
            })
        }
        return a;
    };

    const videoMenuClick = ({ key, id }) => {
        if (key == '4') {

        }
        console.log(id);
    };


    const loadMore = () => {
        let o = offset + 20
        props.loadMoreMembers({ offset: o })
        setOffset(o);
    }


    return (
        <Row>
            <Col span={24} className='mb-5 padding40 m-px10'>
                <Row className="m-md15">
                    {
                        !props?.notAdmin && <>
                            <Col xs={{ span: 17 }} lg={{ span: 19 }} md={{ span: 18 }}>
                                <p className="fs16 fw500">Members</p>

                            </Col>
                            <Col xs={{ span: 7 }} lg={{ span: 5 }} md={{ span: 5 }} className='text-right'>
                                <RWebShare
                                    data={{
                                        text: props?.desc,
                                        url: window.location,
                                        title: props?.name,
                                    }}

                                ><Button type="dashed" icon={<UserPlus size={18} style={{ marginRight: "5px" }} />}> {" "}Invite</Button>
                                </RWebShare>

                            </Col>


                            <Col span={24}>
                                <Divider />
                            </Col></>
                    }


                    {

                        <Col span={24}
                            id="scrollableDiv3"
                            style={{
                                height: "68vh",
                                overflow: 'auto',
                            }}
                        >

                            <InfiniteScroll
                                dataLength={memberdata.length}
                                next={loadMore}
                                hasMore={memberdataNew.length >= 20}
                                loader={<Skeleton avatar={{ size: 40 }} paragraph={{ rows: 1 }} active />}
                                endMessage={<></>}
                                scrollableTarget="scrollableDiv3"
                            >

                                <List
                                    dataSource={memberdata}
                                    renderItem={(item) => (
                                        <List.Item key={item.user_code}>
                                            <List.Item.Meta
                                                avatar={<Avatar size={40} src={item?.dp}></Avatar>}
                                                title={<p className="fs14 fw600" style={{ color: item.color }}>{item?.name}</p>}
                                                description={<a href={`/profile/${item?.user_code}`}><p className="fs12 fw300 text-grey">@{item?.username}</p></a>}
                                            />
                                            <Dropdown trigger={['click']} menu={{ items: items_video(item), onClick: ({ key, }) => videoMenuClick({ key: key, id: item }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                <Button type="text" shape="circle" onClick={(e) => e.preventDefault()}
                                                    icon={<DotsThreeVertical size={17} weight="bold" />}></Button>
                                            </Dropdown>

                                            {/* <Row>
                                                <Col span={24} className='hover-bg '>
                                                    <Row className='  '>
                                                        <Col xs={{ span: 22 }} lg={{ span: 18 }} md={{ span: 18 }} >
                                                            <Row>
                                                                <Col xs={{ span: 5 }} lg={{ span: 4 }} md={{ span: 4 }} >
                                                                    <Avatar size={48} src={item?.dp}></Avatar>
                                                                </Col>
                                                                <Col xs={{ span: 19 }} lg={{ span: 20 }} md={{ span: 20 }} >
                                                                    <p className="fs14 fw600 md5" style={{ color: item.color }}>{item?.name}</p>
                                                                    <a href={`/profile/${item?.user_code}`}><p className="fs12 fw300 text-grey">@{item?.username}</p></a>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={{ span: 2 }} lg={{ span: 6 }} md={{ span: 6 }} className='text-right'>
                                                            <Dropdown trigger={['click']} menu={{ items: items_video(item), onClick: ({ key, }) => videoMenuClick({ key: key, id: item }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                                <Button type="text" shape="circle" onClick={(e) => e.preventDefault()}
                                                                    icon={<DotsThreeVertical size={17} color="#000" weight="fill" />}></Button>
                                                            </Dropdown>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                </Row> */}
                                        </List.Item>

                                    )}
                                />
                            </InfiniteScroll>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    );
}

export default ManageMembers;