import { Button, Col, Row, Space, Select, Calendar, Tooltip, Drawer, Alert } from "antd";
import { Sparkle } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router";
import axios from "../../store/axios";
import useToken from "../../store/useToken";
import Toast from "../utils/Toast";

const { Option } = Select;

function getNextWeekdays(days) {
    const weekdays = [];
    let currentDate = moment().add(1, 'day');

    while (weekdays.length < days) {
        const dayOfWeek = currentDate.day();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { // 0 is Sunday, 6 is Saturday
            weekdays.push(currentDate.clone());
        }
        currentDate.add(1, 'day');
    }

    return weekdays;
}
function getNextWeekends(days) {
    const weekends = [];
    let currentDate = moment().add(1, 'day');

    while (weekends.length < days) {
        const dayOfWeek = currentDate.day();
        if (dayOfWeek === 0 || dayOfWeek === 6) { // 0 is Sunday, 6 is Saturday
            weekends.push(currentDate.clone());
        }
        currentDate.add(1, 'day');
    }

    return weekends;
}

function getNext30Days() {
    const startDate = moment().add(1, 'day'); // Current date
    const dates = [];

    for (let i = 0; i < 30; i++) {
        const date = startDate.clone().add(i, 'days');
        dates.push(date);
    }

    return dates;
}

const TIME_START = ["09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30",
    "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00"];
const TIME_END = ["09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30",
    "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00"];


const MentorshipAddSlotModal = (props) => {
    const [loader, setLoader] = useState(false);
    const params = useParams();

    const [tempDateAction, setTempDateAction] = useState();
    const [drawer, setDrawer] = useState(false);
    const [nextWeekDays, setNextWeekDays] = useState([]);
    const [nextWeekendDays, setNextWeekendDays] = useState([]);
    const [next30Days, setNext30Days] = useState([]);
    const [alertMesg, setalertMesg] = useState(false);
    const [alertMesg2, setalertMesg2] = useState("");
    const [masterTimeStart, setMasterTimeStart] = useState(TIME_START);
    const [masterTimeEnd, setMasterTimeEnd] = useState(TIME_END);

    const [selectedAvailableSlots, setSelectedAvailableSlots] = useState([]);
    const [timeStart, setTimeStart] = useState(TIME_START[0]);
    const [timeEnd, setTimeEnd] = useState(TIME_END[TIME_END.length - 1]);

    const { token } = useToken();



    useEffect(() => {
        const nwd = getNextWeekdays(30);
        setNextWeekDays(nwd);
        const nwed = getNextWeekends(30);
        setNextWeekendDays(nwed);
        const days30 = getNext30Days();
        setNext30Days(days30);
        if (props?.slots){
            setSelectedAvailableSlots(props.slots.map((i)=>moment(i.date)));
            setTimeStart(moment(props.startTime, "HH:mm:ss").format("HH:mm"));
            setTimeEnd(moment(props.endTime,"HH:mm:ss").format("HH:mm"));
        }
    }, [])


    function disabledDate(current) {
        // Can not select days before today and today
        return moment().add(-1, 'days')  >= current || current && current.valueOf() < Date.now();
    }

    

    const SlotCancel = ()=>{
        props.onCancel();
    }
       
    

    const onDateSelect = (date) => {
        date = moment(date.format("YYYY-MM-DD"));
        setTempDateAction(date);
        // do'nt add past date
        if (date <= moment()) {
            return
        }
        // check same date exists
        const arr_len = selectedAvailableSlots.filter((i) => { return i.isSame(date); }).length;
        if (arr_len > 0) {
            return
        }
        const newArr = [...selectedAvailableSlots, date];
        setSelectedAvailableSlots(newArr.sort((a, b) => a - b)); // sort 
    }

    const removeSlot = (date) => {
        const arr = selectedAvailableSlots.filter((i) => { return i !== date });
        setSelectedAvailableSlots(arr);
        setTempDateAction(date);
    }
    const onPanelChange = (date, mode) => {
        setTempDateAction(date);
    }

    const style = {
        borderRadius: '10px',
        border: "1px solid grey",
        padding: '16px',
        cursor: "cell",
        backgroundColor: "ghostwhite",
    }

    const onGeneratedDaysAdd = (data, s) => {
        setDrawer(false);
        setSelectedAvailableSlots(data);
        setalertMesg(true);
        setalertMesg2(s);
    }

    const onChangeStartTime = (value) => {
        const ar = TIME_END.filter((v) => { return v > value });
        setMasterTimeEnd(ar);
        if (timeEnd < value) {
            setTimeEnd(ar[0]);
        }
        setTimeStart(value);
    }
    const onChangeEndTime = (value) => {
        const ar = TIME_START.filter((v) => { return v < value });
        setMasterTimeStart(ar);
        if (timeStart > value) {
            setTimeEnd(ar[0]);
        }
        setTimeEnd(value);
    }

    const updateSlots = async () => {
        if (selectedAvailableSlots.length < 6){
            Toast("Please select at-least six available days!", "error");
            return
        }
        
        const payload = {
            "id": params["id"],
            "action": "slot_update",
            "slots": selectedAvailableSlots.map((d)=> d.format("YYYY-MM-DD")),
            "start_time": timeStart,
            "end_time": timeEnd
        }

        setLoader(true);
        await axios({
            method: 'put',
            url:  "v1.0/mentorship/new",
            data: payload,
            headers: {
                Authorization: token
            }
        }).then(() => {
            Toast("Slots updated!", "success");
            props.onCancel(); // close the modal

        }).catch((err) => {
        })
        setLoader(false);
    }


    return (
        <Row style={{ height: "75vh", overflowY: "scroll", scrollBehavior: "smooth" }}>

            <Col span={24} className='padding25'>
                <p className="fs14 fw500 "> Time </p>
                <Space size={12} className="md10" align="center">
                    <Select defaultValue={timeStart} value={timeStart} onChange={(v) => onChangeStartTime(v)} style={{ width: "100px" }}>
                        {
                            masterTimeStart.map((i, j) => {
                                return <Option key={j} value={i}>{i}</Option>
                            })
                        }
                    </Select>
                    <p className="fs15 fw500">To </p>
                    <Select defaultValue={timeEnd} value={timeEnd} onChange={(v) => onChangeEndTime(v)} style={{ width: "100px" }}>
                        {
                            masterTimeEnd.map((i, j) => {
                                return <Option key={j} value={i}>{i}</Option>
                            })
                        }
                    </Select>
                </Space>

                <Row>
                    <Col span={20} className="md20">
                        <p className="fs14 fw500 "> Select available days </p>
                    </Col>
                    <Col span={4} className="md15 text-right">
                        <Button onClick={() => setDrawer(true)} size="small" type="text" icon={<Sparkle size={18} />}></Button>
                    </Col>
                    <Col span={24} className="md10">
                        <Calendar
                            //   monthCellRender={monthCellRender}
                            onPanelChange={onPanelChange}
                            onSelect={(d) => onDateSelect(d)}
                            disabledDate={(d) => disabledDate(d)}
                            // value={moment("2023-08-11")}
                            className="bordered cal-dropdown"
                            fullscreen={false}
                        />
                    </Col>
                </Row>
                <Row gutter={16} className="md20" style={{ overflow: "hidden" }}>
                    {alertMesg && <Col span={24}>
                        <Alert message={alertMesg2} type="success" closable afterClose={() => setalertMesg(false)} />
                    </Col>}
                    <Col span={24} className="md10">
                        <Space size={4} wrap>
                            {
                                selectedAvailableSlots.map((item, i) => {

                                    return <Tooltip title="Click to remove" key={i}>
                                        <div style={{ width: "60px", transition: "ease-in" }} className="cursor" onClick={() => removeSlot(item)}>
                                            <div className={"mentorship-calender p-2 w-auto"} style={item.isSame(tempDateAction) ? { borderColor: "blue" } : {}}>
                                                <h6 className="fs14 mb-0">{moment(item).format("DD")} </h6>
                                                <span className="fs12 text-grey">{moment(item).format("MMM")} </span>
                                            </div>
                                        </div>
                                    </Tooltip>
                                })
                            }
                        </Space>

                    </Col>
                    <Col span={24} className="md30">
                        <p className="fs14 fw400 solt-text"> In publishing and graphic design, Lorem ipsum is a placeholder text commonly used </p>
                    </Col>
                    <Col span={24} className="text-right md20 mb-2">
                        <Space size={20}>
                            <Button type="dashed" onClick={ SlotCancel} >Cancel</Button>
                            <Button type="primary" loading={loader} onClick={() => updateSlots()}>Save</Button>
                        </Space>
                    </Col>

                </Row>
            </Col>
            <Drawer
                title=""
                placement="right"
                className="drawer-w-75"
                closable={true}
                onClose={() => setDrawer(false)}
                open={drawer}
                getContainer={false}
                width={270}
            >
                {
                    nextWeekDays.length > 0 &&

                    <Row className="md20">
                        <Col span={24}>
                            <div style={style} onClick={() => onGeneratedDaysAdd(next30Days, "Added next 30 days")}>
                                <h6>Add next 30 days</h6>
                                <small className="text-grey">All days</small> <br />
                                <small>{next30Days[0].format('DD MMM YYYY')} -{'>'} {next30Days[next30Days.length - 1].format('DD MMM YYYY')}</small>
                            </div>

                        </Col>
                        <Col span={24} className="md10">
                            <div style={style} onClick={() => onGeneratedDaysAdd(nextWeekDays, "Added weekdays only")}>
                                <h6>Add weekdays only</h6>
                                <small className="text-grey">Only Mon, Tue, Wed, Thu and Fri</small> <br />
                                <small>{nextWeekDays[0].format('DD MMM YYYY')} -{'>'} {nextWeekDays[nextWeekDays.length - 1].format('DD MMM YYYY')}</small>
                            </div>
                        </Col>
                        <Col span={24} className="md10">
                            <div style={style} onClick={() => onGeneratedDaysAdd(nextWeekendDays, "Added weekends only")}>
                                <h6>Add weekends only</h6>
                                <small className="text-grey">Only next 30 days (Sat and Sun)</small> <br />
                                <small>{nextWeekDays[0].format('DD MMM')}, {nextWeekDays[1].format('DD MMM')}, {nextWeekDays[2].format('DD MMM YYYY')} ...</small>
                            </div>

                        </Col>
                    </Row>
                }
            </Drawer>
        </Row>
    )
}
export default MentorshipAddSlotModal;

