import { createAction } from "redux-actions";
import axios from "../../store/axios";
import paxios from "../axios"
import Cookies from "js-cookie";


const SECTORS = createAction("SECTORS");
export const Sectors = (values) => dispatch => {
    return paxios({
        method: 'get',
        url: 'account/master/tags?tag='+values.tag,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(SECTORS(res.data.data));
    })
};


const NIFTY_50 = createAction("NIFTY_50");
export const Nifty50 = () => dispatch => {
    return paxios({
        method: 'get',
        url: 'market/eq/nifty50stocks',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(NIFTY_50(res.data.data));
    })
};


const SENSEX = createAction("SENSEX");
export const Sensex = () => dispatch => {
    return paxios({
        method: 'get',
        url: 'market/eq/sensex',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(SENSEX(res.data.data));
    })
};

export const UserPreferences = (values) => () => {
    return paxios({
        method: 'post',
        url: 'account/user/preferences',
        data: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};



const USER_SELECTED_PREFERENCES = createAction("USER_SELECTED_PREFERENCES");
export const UserSelectedPreferences = () => (dispatch) => {
    return paxios({
        method: 'get',
        url: 'account/user/preferences',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(USER_SELECTED_PREFERENCES(res.data.data));
    })
};



export const UserTagPreferences = () => (dispatch) => {
    return paxios({
        method: 'get',
        url: 'news/tags/trending',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(USER_SELECTED_PREFERENCES(res.data.data));
    })
};


export const UserVideoTagPreferences = () => (dispatch) => {
    return paxios({
        method: 'get',
        url: 'market/feed/video/tags',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(USER_SELECTED_PREFERENCES(res.data.data));
    })
};

const PROFILE = createAction("PROFILE");
export const Profile = () => (dispatch) => {
    return paxios({
        method: 'get',
        url: 'account/user/dpname',
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(PROFILE(res.data.data));
        Cookies.set("User_code", res.data.data.user_code)
        Cookies.set("User_name", res.data.data.name)
    })
};


const LIST_OF_STOCKS = createAction("LIST_OF_STOCKS");
export const listofStocks = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_API_PARENT + 'stock/security/search?name=' + values.value + '&count=10',
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(LIST_OF_STOCKS(res.data.items));
    })
};


export const listofSectors = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_API_PARENT + 'MutualFund/sector/color?sector=' + values.value + '&count=10',
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(LIST_OF_STOCKS(res.data.items));
    })
};


const LTP = createAction("LTP");
export const getLtp = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_API_PARENT + 'stock/ltp?security_id=' + values.value,
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(LTP(res.data.item));
    })
};


export const createSignal = (values) => () => {
    return axios({
        method: 'post',
        url: process.env.REACT_APP_API_PARENT + 'signal/signal',
        data: values,
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    })
};



const ALL_SIGNALS = createAction("ALL_SIGNALS");
export const allSignals = () => (dispatch) => {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_API_PARENT + 'signal/signal',
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(ALL_SIGNALS(res.data.items));
    })
};



export const FilterTags = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_API_PARENT + "signal/signal?type=" + values.type + "&tag=" + values.tag,
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(ALL_SIGNALS(res.data.items));
    })
};


export const PinSignal = (values) => () => {
    return axios({
        method: 'post',
        url: process.env.REACT_APP_API_PARENT + "signal/pin?pin=" + values.pin,
        data: values,
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    })
};


export const deleteSignal = (values) => () => {
    return axios({
        method: 'delete',
        url: process.env.REACT_APP_API_PARENT + "signal/signal?signal_id=" + values.id,
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    })
};

const LIVE_VALUE_STOCK = createAction("LIVE_VALUE_STOCK");
export const GetLiveValue = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_API_PARENT + 'stock/security/price?security_id=' + values.id,
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(LIVE_VALUE_STOCK(res.data.item));
    })
};


const STOCK_PRICE = createAction("STOCK_PRICE");
export const getPrice = (values) => (dispatch) => {
    return axios({
        method: 'post',
        url: process.env.REACT_APP_API_PARENT+ 'stock/security/price',
        data: values,
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(STOCK_PRICE(res.data.items));
    })
};


const MY_SIGNALS = createAction("MY_SIGNALS");
export const MySignals = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_API_PARENT + 'signal/signal/my',
        data: values,
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(MY_SIGNALS(res.data.items));
    })
};


const MY_STOCKS = createAction("MY_STOCKS");
export const AllStocks = values => dispatch => {
    return paxios({
        method: 'get',
        url:'market/watchlist',
        data: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(MY_STOCKS(res.data.data));
    })
};

const STOCKS_LIVE_DATA = createAction("STOCKS_LIVE_DATA");
export const getLivePrice = values => dispatch => {
    return axios({
        method: 'post',
        url: process.env.REACT_APP_API_PARENT + 'stock/security/price',
        data: values,
        headers: {
            "X-lighthouse": Cookies.get("Logintoken")
        }
    }
    ).then(res => {
        dispatch(STOCKS_LIVE_DATA(res.data.items));
    })
};