
import * as results from './CorporateResults';
import * as publics from './PublicApi';
import * as login from "./Login";
import * as register from "./Register"
import * as channel from "./Channel"
import * as newsletter from "./Newsletter"
import * as profile from "./Profile"
import * as gamify from "./Gamify"
import * as streaming from "./Streaming"
import * as entity from "./Entity"

export default {
    ...results,
    ...publics,
    ...login,
    ...register,
    ...channel,
    ...newsletter,
    ...profile,
    ...gamify,
    ...streaming,
    ...entity
};