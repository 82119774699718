import React from "react";
import { Row, Col, Typography, Image } from 'antd';

const { Paragraph } = Typography;
function addDefaultSrc() {
    return "https://img.freepik.com/free-vector/white-abstract-background_23-2148806276.jpg?size=626&ext=jpg"
}
const NewsBody = ({ item, details }) => {
    return <Row gutter={10}>
        <Col md={18} xs={24}>
            <h6 className="news-header cursor m-fs16" onClick={() => details(item)}>{item?.title}</h6>
            <Paragraph
                ellipsis={{ rows: 3, expandable: true, symbol: "Read more" }}
                className="post-heading-text md10 m-fs15  "
                style={{ color: "#4B5669" }}
            >
                {item?.description}
            </Paragraph>
        </Col>
        <Col md={6} xs={0}>
            {item?.image_url !== null && item?.image_url !== "" && (
                <Image
                    onError={() => addDefaultSrc()}
                    preview={false}
                    fallback={"https://img.freepik.com/free-vector/white-abstract-background_23-2148806276.jpg?size=626&ext=jpg"}

                    src={item.image_url}
                    style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "6px",
                        backgroundColor: "#f0f0f0"
                    }}
                ></Image>
            )}

        </Col>
    </Row>
}
export default NewsBody;