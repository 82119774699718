import { Space } from 'antd';
import React, { useState, useEffect } from 'react';

function CountdownTimer({ startTime }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [timeLeft]);

  function calculateTimeLeft() {
    const difference = startTime - new Date();
    if (difference <= 0) {
      // The class has already started or the start time is in the past
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  return (
    <div>
      <Space split={':'}>
        {
          timeLeft.days > 0 &&
          <div className="small-div">
            <p className="fs16 fw600"> {timeLeft.days}<small>d</small> </p>
          </div>
        }
        {
          timeLeft.hours > 0 &&
          <div className="small-div">
            <p className="fs16 fw600"> {timeLeft.hours}<small>h</small> </p>
          </div>
        }
        <div className="small-div">
          <p className="fs16 fw600"> {timeLeft.minutes}<small>m</small> </p>
        </div>
        <div className="small-div">
          <p className="fs16 fw600"> {timeLeft.seconds}<small>s</small> </p>
        </div>
      </Space>
      <br />
      <p className="fs14 fw400 reward-text md5"> Time remain to start </p>
    </div>
  );
}

export default CountdownTimer;
