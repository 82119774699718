import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    login_details: [],
    login_social: null,
}
const LoginReducer = handleActions({
    LOGIN: (state, action) => {
        return { ...state, login_details: action.payload };
    },
    LOGIN_SOCIAL:(state, action) => {
        return { ...state, login_social: action.payload };
    }
}, INITIAL_STATE);

export default LoginReducer;
