import { Col, Divider, Row } from "antd";
import React from "react";
import ChannelFooter from "../Channel/v3/ChannelFooter";

const CampFooter = () => {
    return (
        <Row className="mb-5">
            <Col lg={{ span: 24 }} xs={{ span: 22, offset: 1 }}>
                <Divider />
                <p className="camp-text fs14 fw700 md30"> ©️Syllo 2023 . Campus </p>
                <ChannelFooter />
            </Col>
        </Row>
    );
}
export default CampFooter;