import { Col, Row, Skeleton, Divider } from "antd";
import React from "react";
import LiveOnline from "./LiveOnline";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { data } from "jquery";


const LiveTalks = () => {
    const [ws, setWS] = useState()
    const [talks, setTalks] = useState([])
    const [loader, setLoader] = useState(true)


    useEffect(() => {
        let connection = new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/main?token=" + Cookies.get("Logintoken"))
        setWS(connection)

        connection.onmessage = evt => {
            const message = JSON.parse(evt.data)
            setTalks(message)
            setLoader(false)
        }

        connection.onopen = evt => {
            evt.preventDefault()
            const message = { module: "live_talks" }
            connection.send(JSON.stringify(message))
        }

        connection.onclose = () => {
            setWS(new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/main?token=" + Cookies.get("Logintoken")))
        }
    }, [])


    return (
        <Row className="height100vh dashboard-page " >


            <Col xs={{ span: 24 }} lg={{ span: 11, offset: 6 }} className='md70 m-md20 padding20'  >
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
                        <h6 className="today-text"  >Today’s talks</h6 >
                    </Col>
                    <Col span={24}>
                        <Divider></Divider>
                    </Col>
                    {/* <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }} className='md40 dashboard-tag-scroll' >
                                        <Space  >
                                            <Tag className="yuja-tag bg-light-green fs14 fw400">Workshop</Tag>
                                            <Tag className="yuja-tag">Courses</Tag>
                                            <Tag className="yuja-tag">Technology</Tag>
                                            <Tag className="yuja-tag">Wealth Management</Tag>

                                        </Space>
                                    </Col> */}
                    <Col span={24} >
                        {
                            loader ? <Row >
                                <Col xs={{ span: 24 }} lg={{ span: 4 }} className='md20'>
                                    <Skeleton.Image active={true} />
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 20 }} className='md20'>
                                    <Skeleton active={true} ></Skeleton>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 4 }} className='md20'>
                                    <Skeleton.Image active={true} />
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 20 }} className='md20'>
                                    <Skeleton active={true} ></Skeleton>
                                </Col>
                            </Row>
                                : <Row className="mb-5">
                                    {
                                        talks?.live.map((item) => {
                                            return <LiveOnline data={item} />
                                        })
                                    }
                                </Row>}
                       
                    </Col>

                </Row>
            </Col>


        </Row>
    );
}
export default LiveTalks;
