import { Col, Row, Divider, Button, Space, Input, Modal, Skeleton, Menu, Dropdown } from "antd";
import { Plus, ChatCircleDots, Copy, DotsThreeOutlineVertical, Export, PaperPlaneRight, Trash, X, DotsThreeVertical, Link } from "@phosphor-icons/react";
import '../channel_chat.scss';
import React, { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";
import moment from "moment";
import MessageTextCard from "../../ChannelLiveChat/MessageTextCard";
import InputEmoji from 'react-input-emoji'
import _ from "lodash";
import axios from "../../../store/axios";
import Avatar from "antd/lib/avatar/avatar";
import ScrollableFeed from "react-scrollable-feed";

import Toast from "../../utils/Toast";


const ChannelDrops = ({ id, _uid, joined }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [create_drop, setCreate_drop] = useState(false);
    const [loaded, setLoaded] = useState(false)
    const [messages, setmessages] = useState([])
    const [loader, setloader] = useState(true)
    const [discussionloader, setdiscussionloader] = useState(true)
    const [discussion, setdiscussion] = useState([])
    const [discussionmsg, setdiscussionmsg] = useState([])
    const [discussionserver, setdiscussionserver] = useState(null)
    const [ws, setws] = useState(null)
    const [text, settext] = useState("")
    const [drops, setDrops] = useState([])
    const [dropmessage, setDropMessage] = useState(null)

    useEffect(() => {
        let _ws = new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/stream?chat_type=2&id=" + id + "&token=" + Cookies.get("Logintoken"))
        setws(_ws)
        _ws.onmessage = evt => {
            const message = JSON.parse(evt.data)
            if (message.length !== 0) {
                let a = message.filter((item) => { return item[17] === 2 }) // 2= drop
                setmessages(messages => {
                    return [...messages, ...a];
                })
            }
            setloader(false)
        }
        axios({
            method: 'get',
            url:  "channel/chat/template",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            setDrops(res.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])



    const onClick = ({ key }) => {
        Toast(`Click on item ${key}`,"info");
    };

    const closeThread = () => {
        if (discussionserver !== null) {
            discussionserver.close();
        }
        setLoaded(false);
    };

    const openThread = (item) => {
        if (discussionserver !== null) {
            discussionserver.close();
        }
        openDiscussion(item);
    };



    const submitMessage = () => {

        let message = { m: text, mid: discussionmsg[0] }
        discussionserver.send(JSON.stringify(message))
        settext("")
    }

    const openDiscussion = (item) => {
        setLoaded(true)
        setdiscussionmsg(item)
        setdiscussionloader(true)
        setdiscussion([])
        let threadws = new WebSocket(process.env.REACT_APP_WS_BASE_URL + `wss/channel/thread?token=${Cookies.get("Logintoken")}&id=${_uid}&thread_id=${item[0]}`)
        setdiscussionserver(threadws)

        threadws.onmessage = evt => {
            const _message = JSON.parse(evt.data).messages
            if (_message.length !== 0) {
                setdiscussion(discussion => {
                    return [...discussion, ..._message];
                })
            }
        }
        setdiscussionloader(false)
    }

    const menu = (
        <Menu
            onClick={onClick}
            items={[
                {
                    label: (
                        <span className="fs12 fw400"> <Export size={18} color="#000000" weight="thin" /> Share</span>
                    ),
                    key: '1',
                },
                {
                    label: (
                        <span className="fs12 fw400"> <Copy size={18} color="#181816" weight="thin" /> Copy Link</span>
                    ),
                    key: '2',
                },
                {
                    label: (
                        <span className="fs12 fw400" style={{ color: '#DE0905' }}> <Trash size={18} color="#DE0905" weight="thin" /> Delete</span>
                    ),
                    key: '3',
                }
            ]}
        />
    );
    const userCode = Cookies.get("user_code");
    let _drops = _.groupBy(drops, "category_title")
    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeCreateDrop = () => {
        setCreate_drop(false);
        setIsModalOpen(true);
    };

    const showdrop = (item) => {
        setDropMessage(item?.message)
        setCreate_drop(true);
        setIsModalOpen(false);
    };

    const createDrops = () => {
        let _message = { m: dropmessage, "chat_type": 2 } // 2 for drop
        ws.send(JSON.stringify(_message))
        setCreate_drop(false);
        setIsModalOpen(false);
    }

    const dropOptions = () => {
        return [
            {
                label: (
                    <span className=" fw400"> Share</span>
                ),
                key: '1',
                icon: <Export size={18} color="#686464" weight="light" />
            },
            {
                label: (
                    <span className=" fw400"> Copy link</span>
                ),
                key: '2',
                icon: <Link size={18} color="#686464" weight="light" />
            },
            {
                label: (
                    <span className="fw400"> Delete</span>
                ),
                key: '3',
                icon: <Trash size={18} color="#686464" weight="light" />
            }

        ]
    };

    const dropOptionsClick = ({ key, item }) => {

        if (key == '1') {

        }
    };


    return (

        <Row gutter={20} >
            <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                <div className='channel-threads' style={{ height: "66vh", overflowY: "scroll" }}>
                    {/* <Divider> <p className="fs12 fw300  ">today</p></Divider> */}
                    <Row gutter={16} >
                        <Col lg={18} md={24} xs={24}  >

                            {
                                loader ? <Skeleton></Skeleton> :
                                    messages.map((item, key) => {
                                        return <Row key={key} className='mb-3 drops-card drop cursor'    onClick={() => openThread(item)}>
                                            <Col span={20}>
                                                <Space>
                                                    <Avatar shape="circle" src={item[16] ? item[16] : require('../../../assets/images/channel/user.png')} alt="" size={30} />
                                                    <p className="fs14 fw500" style={{ color: '#6361C3' }}>{item[2]}</p>
                                                    <p className="fs12 fw300 text-grey ">{moment(item[5]).fromNow()}</p>
                                                </Space>
                                            </Col>
                                            <Col span={4} className="text-right">
                                                <Dropdown trigger={['click']} menu={{ items: dropOptions(), onClick: ({ key }) => dropOptionsClick({ key: key, item: item }) }} placement={"bottomCenter"} overlayStyle={{ minWidth: "120px" }}>
                                                    <Button onClick={(e) => e.stopPropagation()} type="text" shape="circle" icon={<DotsThreeVertical size={20} color="#54514f" />} />
                                                </Dropdown>
                                            </Col>
                                            <Col span={24} className=''>
                                                <Space>
                                                    {/* <img src={require('../../../assets/Resources/Vector-drop.png')} alt="" height={10} /> */}
                                                    <div className="drop-div md10">
                                                        <p className="fs14 fw600">{item[3]}</p>
                                                    </div>

                                                </Space>
                                            </Col>
                                            {
                                                item[16] > 0 ? <Col span={24} className='text-right md15'>
                                                    <Space>
                                                        <Avatar shape="circle" src={item[16] ? item[16] : require('../../../assets/images/channel/user.png')} alt="" size={26} />
                                                        <p className="fs12 fw300" >{item[13]} replies</p>

                                                    </Space> </Col> : <></>
                                            }


                                        </Row>
                                    })
                            }


                        </Col>

                    </Row>
                    <Row>
                        {
                            joined && <Col span={24} className='text-right drop-btn'>
                                <Button type="primary" shape="circle" onClick={showModal} style={{ position: "fixed", bottom: '30px', right: "calc(50vw - 243px)" }}>
                                    <Plus size={32} color="#fff" weight="regular" />
                                </Button>
                            </Col>
                        }

                    </Row>
                </div>
            </Col>


            <Col xs={{ span: 24 }} lg={{ span: 10 }} className='m-md15'>
                <Row className='chat-threads '>
                    <Col span={24}>

                        {
                            loaded ?
                                <Row>
                                    <Col span={24} className="">
                                        <Row className="p-3">
                                            <Col span={20} >
                                                <div className=" text-center" style={{ borderRadius: '25px', padding: '10px', backgroundColor: '#f0f0f0' }}>
                                                    <p className="fs14 fw600  "> {discussionmsg[3]}</p>
                                                </div>
                                            </Col>
                                            <Col span={4} className='text-right'>
                                                <Button type="text" shape="circle" onClick={() => closeThread()} icon={<X color="#000000" size={18} />}></Button>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col span={24} style={{ height: "44vh", overflowY: "scroll" }}>
                                        <ScrollableFeed forceScroll={true}>
                                            <Row>

                                                <Col span={22} offset={1} className="md">
                                                    <Row className="thread-bg " style={{ borderRadius: '16px' }}>
                                                        <Col span={18} className=''>
                                                            <Space>
                                                                <Avatar shape="circle" src={discussionmsg[16] ? discussionmsg[16] : require('../../../assets/images/channel/user.png')} alt="" size={30} />
                                                                <p className="fs14 fw500" style={{ color: '#146EB4' }}>{discussionmsg[2]}</p>
                                                                <p className="fs12 fw300 text-grey ">{moment(discussionmsg[5]).fromNow()}</p>
                                                            </Space>
                                                        </Col>
                                                        <Col span={6} className='text-right'>
                                                            <Button type="text" shape="circle" onClick={(e) => e.preventDefault()} className="thread-icon" icon={<DotsThreeOutlineVertical size={15} color="#0d0d0c" weight="fill" />}>

                                                            </Button>

                                                        </Col>
                                                        <Col span={24} className='md10'>
                                                            <p className="fs14 fw400">{discussionmsg[3]}</p>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24} className='md25 ps-2 pe-2'>
                                                    <Divider> <p className="fs12 fw300  ">Replies</p></Divider>
                                                </Col>

                                                {
                                                    discussionloader ? <Skeleton></Skeleton> :
                                                        discussion.map((item, index) => {
                                                            return <Col span={24} key={index}> <MessageTextCard item={item} userCode={userCode} /> </Col>
                                                        })
                                                }
                                            </Row>
                                        </ScrollableFeed>
                                    </Col>


                                    {/* <Col span={21} className='md20 thread-chat-card'>
                                        <Row>
                                            <Col span={24}>
                                                <p className="fs12 fw300 text-blue md5 ">Mukesh Bhardwaj</p>
                                                <p className="fs14 fw400  md5">Hi, The sound and picture are great! Yeah! you’re right.</p>
                                                <p className="fs12 fw300 text-grey md5">5:30 pm</p>

                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col span={21} className='md20 thread-right-card'>
                                        <Row>
                                            <Col span={24}>
                                                <p className="fs12 fw300 text-blue md5 ">Mukesh Bhardwaj</p>
                                                <p className="fs14 fw400 md5">Hello Mr., Let’s have a discussion</p>
                                                <p className="fs12 fw300 text-grey md5">5:30 pm</p>

                                            </Col>

                                        </Row>
                                    </Col> */}
                                    <Col span={24} className='pt13'>
                                        <Row>
                                            <Col span={21} >
                                                <InputEmoji
                                                    value={text}
                                                    className={'chat-msg-input mt40'}
                                                    style={{ color: "black", fontSize: "20px" }}
                                                    onChange={(evt) =>
                                                        settext(evt)}
                                                    cleanOnEnter
                                                    onEnter={() =>
                                                        submitMessage()}
                                                    placeholder="Type a message"
                                                />

                                            </Col>
                                            <Col span={3} className="text-center send-icon ">
                                                <Button type='primary' className="" onClick={() => submitMessage()} > <PaperPlaneRight size={22} color="#fff" weight="fill" /></Button>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row> :
                                <Row style={{ minHeight: "60vh" }}>
                                    <Col span={16} offset={4} className='text-center md70 div-bg'>
                                        <Button type="text" shape="circle" className="" icon={<ChatCircleDots size={30} color="#2e2e2d" weight="thin" style={{ marginTop: '-25px', marginLeft: '-14px' }} />}>
                                        </Button>
                                        <p className="fs16 fw400 md25">Select a Drop</p>
                                        <p className="fs12 fw400 md10 text-grey">Hop into any thread and be the first to create one!</p>
                                    </Col>
                                </Row>
                        }

                    </Col>
                </Row>

            </Col>



            <Modal centered title="Start a conversation" width={600} open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={false}>
                <Row>
                    <Col span={24} className='padding25' style={{ height: "82vh", overflowY: "scroll" }}>

                        <Row gutter={16} className='md25'>
                            {
                                _drops && Object.keys(_drops).map((item, i) => {
                                    return <Col span={8} key={i}>
                                        <div className="drop-bg-pink text-center cursor">
                                            <p className="fs14 fw400">{item}</p>
                                        </div>
                                    </Col>
                                })
                            }


                        </Row>
                        <Row className='md10'>
                            {
                                _drops && Object.keys(_drops).map((item) => {
                                    return <> <Col span={24} className={'md20'}>
                                        <p className="fs16 fw400">{item}</p>
                                    </Col>
                                        <Row gutter={25}>
                                            {
                                                Object.values(_drops[item]).map((_item) => {
                                                    return <Col span={12}>
                                                        <div className="blue-card text-center cursor md15" onClick={() => showdrop(_item)} >
                                                            <img src={require('../../../assets/Resources/Vector-drop.png')} alt="" height={15} />
                                                            <p className="fs14 fw500 text-blue md10">{_item.message}</p>
                                                        </div>
                                                    </Col>
                                                })

                                            }
                                        </Row>
                                    </>
                                })
                            }
                        </Row>


                    </Col>
                </Row>
            </Modal>
            <Modal title="Create a drop" width={480} open={create_drop} onCancel={closeCreateDrop} footer={false}>
                <Row>
                    <Col span={24} className='padding25'>
                        <Row >
                            <Col span={24} className='drop-input' >
                                <Input placeholder="Enter topic...." value={dropmessage} onChange={(evt) => setDropMessage(evt.target.value)} />
                                <p className="fs12 fw400 md15 text-grey">Your group members will receive this prompt and see it in the chat</p>
                            </Col>
                        </Row>
                        <Row className='md75 mb-3'>
                            <Col span={24} className="drop-btn">
                                <Button type="primary" block disabled={dropmessage !== null || dropmessage !== "" ? false : true} onClick={() => createDrops()}>
                                    Create drop
                                </Button>
                            </Col>

                        </Row>



                    </Col>
                </Row>
            </Modal>
        </Row>

    );
}
export default ChannelDrops;