import React from "react";
import { Col, Row, Divider, Button, Tag, Dropdown, Menu, Avatar, message, Space } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import Toast from "../utils/Toast";


const WhatsAppGroup = ({data}) => {

    

    const onClick = ({ key }) => {
       Toast(`Click on item ${key}`,"info");
    };

    const menu = (
        <Menu
            onClick={onClick}
            items={[
                {
                    label: 'Delete',
                    key: '1',
                }
            ]}
        />
    );

    return (
        <Row className="m-nopadding">
            <Col span={24}>
                <Space>
                    <img src={require('../../assets/images/WhatsAppImg/telegram.png')} alt='' height={45}></img>
                    <p className="m-0 fs18 fw600">Paid Groups/Channels</p>
                </Space>
                <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 18 }}>
                        <p className=" fs12 fw300 text-grey md5 ">Manage your private paid Telegram Channels </p>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }} className="text-right">
                        <Button href="/group/create" className="primary-light-btn" type="text">Create New</Button>
                    </Col>
                </Row>
                <Divider></Divider>
            </Col>

         
         {
             data?.map((item,index)=>{
                 return < Col lg={{ span: 24 }} xs={{ span: 24 }} key={index} className='setting-membership-card padding20 md20'>
                 <Row className="">
                     <Col lg={{ span: 3 }} xs={{ span: 5 }} className=''>
                         <Avatar src={item?.img} size={55}></Avatar>
                     </Col>
                     <Col lg={{ span: 17 }} xs={{ span: 14 }} className=''>
                         <p className="m-0 fs14 fw400">{item?.title} </p>
                         <p className="fs12 fw400">Subscription Plan</p>
                         <Tag color="blue" className="md10">₹{item.price}/month</Tag>
                     </Col>
 
                     <Col lg={{ span: 4 }} xs={{ span: 4 }} className='text-right'>
                         <div className="div">
                             <Dropdown trigger={['click']} overlay={menu}>
                                 <Button type="text" shape="circle" onClick={(e) => e.preventDefault()} icon={<MoreOutlined />}>
                                 </Button>
                             </Dropdown>
                         </div>
                         <Tag color="blue" className="md10">{item.status} </Tag>
                     </Col>
                 </Row>
             </Col>
             })
         }
             
        </Row >
    );
}
export default WhatsAppGroup;