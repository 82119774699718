import { Row, Col, Avatar, Space, Tag } from "antd";
import React from "react";


const About = ({ profile }) => {
    return (
        <Row>
            <Col span={24} className='md10'>
                <p className="fs14 fw400">
                    {profile?.user_about}
                </p>
            </Col>
            <Col span={24} className='md10'>
                {
                    profile.social && profile?.social.map((item) => {
                        return item.link !== "" && <a href={item.link} target={"_blank"}>  {
                            item.name && <Avatar shape={"square"} src={require("../../assets/images/profile/" + item.name)} className='mt-3' />
                        } </a>
                    })
                }


            </Col>
            <Col span={24} className='md10 mb-5'>
                <Space >
                    {
                        profile?.desc_keys && profile?.desc_keys.map((item) => {
                            return <Tag className="yuja-tag  fs14 fw400">{item}</Tag>
                        })
                    }


                </Space>
            </Col>
        </Row>
    );
}
export default About;