import React from 'react';
import { Row, Col, Tag, Typography, Button, Skeleton, Dropdown, message, Space } from 'antd';
import moment from 'moment';
import { Dot, DotsThreeVertical, Export, Trash } from "@phosphor-icons/react";
import BlankState from '../BlankState';
import { RWebShare } from "react-web-share";

import Toast from '../utils/Toast';
const { Paragraph } = Typography

const MyHubPoll = ({ data, loader, deleteFeed }) => {
    const items = (item) => {
        var i = [
            {
                key: '1',
                icon:
                    <RWebShare
                        data={{
                            text: "Poll: "+item.title,
                            url: `${process.env.REACT_APP_URL}feed-view/${item.entity_uuid}`,
                            title: item?.title,
                        }}
    
                    ><span><Export size={18} color="#000000" weight="thin" className="me-1" /> Share</span>
                    </RWebShare>
            },{
                label: (
                    <span className="fw400"> Delete Poll</span>
                ),
                key: '2',
                icon: <Trash size={18} color="#000000" weight="thin" />
            }
        ]
        return i;
    };

    const menuClick = async ({ key, item }) => {
        if (key === '2') {
            await deleteFeed(item.entity_uuid);
            Toast('Deleted!',"success")
        }
    };
    return (
        <Row >

            {
                loader ? <Skeleton></Skeleton> : data.length === 0 ? <Col md={21} xs={21} className="md100 text-center">
                    <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} />


                </Col>
                    : data.map((item, i) => {
                        return <Col key={i} span={24} className='single-poll-view cursor'>
                            <a href={"/feed-view/" + item.entity_uuid} style={{ flexGrow: 1 }}>
                                <div className="title mb-2">
                                    <Paragraph className="" ellipsis={{ rows: 2 }}>{item.title}</Paragraph>
                                    <div className="menu-surface-anchor">
                                        {
                                            <Dropdown trigger={['click']} menu={{ items: items(item), onClick: ({ key }) => menuClick({ key: key, item: item }) }} placement={"bottom"} overlayStyle={{ minWidth: "120px" }}>
                                                <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                            </Dropdown>
                                        }

                                    </div>

                                </div>
                                </a>
                                {/* <Tag color="green"> {item.vote_count}</Tag> */}

                                <p className='votes md5'>Published at {moment(item.created_at).format("Do MMM YYYY, h:mm A")}</p>
                                <Space align='center' size={2} wrap split={<Dot size={24} />}>
                                    <p className='fs12 text-grey' >  {item.likes} Likes </p>
                                    <p className='fs12 text-grey' >  {item.comments} Comments </p>
                                    <p className='fs12 text-grey' >  {item.vote_count} Votes </p>
                                </Space>
                            </Col>

                       
                    })
            }

        </Row>
    );
}

export default MyHubPoll;
