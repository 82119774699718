import React, { Component } from "react";
import {  Dropdown,  Menu, Tag, } from "antd";
import "./Signal.scss";
import { withRouter } from "react-router";
import {
  MoreOutlined,
  DeleteOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";
import actions from "../../store/actions/index";

class SignalCard extends Component {
  constructor(props) {
    super(props);
    this.state = { share: false, shareUrl: "" };
  }

  render() {
    const menu = (data) => (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => this.props.PinSignal(data.signal_id, true)}
        >
          Pin
        </Menu.Item>
        <Menu.Item key="1">Track & Archive</Menu.Item>
        
      </Menu>
    );
    const { data } = this.props;
    return (
      <div className="row">
        <div className="col-md-11 signal-card  md20">
          <div className="row">
            <div className="col-md-12 md10">
              <div className="row nomargin">
                <div className="col-md-8">
                  <h5 className="sub-text">{data.creator_name}</h5>
                  <h5 className="smaller-heading-text">{data.scrip_name}</h5>
                </div>
                <div className="col-md-4 text-right">
                  <p className="sub-text">
                    {moment(data.created_at).calendar()}
                  </p>
                  <p className="sub-text">
                    <span className="text-success">{data.status}</span>

                    {this.props.my === true ? (
                      <span>
                        <DeleteOutlined
                          className="cursor margin-left md-10"
                          onClick={() =>
                            this.props.deleteSignal(data.signal_id)
                          }
                        />
                      </span>
                    ) : (
                      <span>
                        <Dropdown overlay={menu(data)} trigger={["click"]}>
                          <MoreOutlined className="cursor margin-left" />
                        </Dropdown>
                      </span>
                    )}

                    {data.pinned === true ? (
                      <PushpinOutlined
                        className="cursor margin-left"
                        onClick={() =>
                          this.props.PinSignal(data.signal_id, false)
                        }
                      />
                    ) : (
                      ""
                    )}
                  </p>
                </div>



                <div className="col-md-4 md10">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="smaller-heading-text"><strong>Stoploss</strong></h5>
                    </div>
                    <div className="col-md-12">
                      <Tag color="red" className="md-10"> {parseFloat(data.sl).toFixed(2)}</Tag>
                      <p className="sub-text">
                        {" "}
                        {parseFloat(
                          ((data.sl - data.cmp) / data.cmp) * 100
                        ).toFixed(2)}{" "}
                        % Away
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 md10">
                  <div className="row">
                    <div className="col-md-12">
                      <span
                        className={
                          data.ltpshow === true ? "strong cursor margin-right" : "cursor margin-right"
                        }
                        onClick={() => this.props.changeContent(data, true)}
                      >
                        LTP
                      </span>{" "}

                      <span
                        onClick={() =>
                          this.props.changeContent(data, false)
                        }
                        className={
                          data.ltpshow === false
                            ? "strong cursor "
                            : "cursor"
                        }
                      >
                        BP
                      </span>
                    </div>
                    <div className="col-md-12 ">
                      <Tag className="md10">
                        <p className="sub-text">
                          {" "}
                          {data.ltpshow === true
                            ? parseFloat(data.prices.p).toFixed(2)
                            : parseFloat(data.tp).toFixed(2)}
                          {data.change > 0 ? (
                            <span
                              style={{ marginLeft: "20px" }}
                              className="text-success"
                            >
                              {parseFloat(data.prices.pch).toFixed(2)} %
                            </span>
                          ) : (
                            <span
                              style={{ marginLeft: "20px" }}
                              className="text-danger"
                            >
                              {parseFloat(data.prices.pch).toFixed(2)} %
                            </span>
                          )}
                        </p>
                      </Tag>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 md10">
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="smaller-heading-text"><strong>TP</strong></h5>
                    </div>
                    <div className="col-md-12">
                      <h5 className="smaller-heading-text">
                        {parseFloat(data.tp).toFixed(2)}
                      </h5>
                      <p className="sub-text md-10">
                        {parseFloat(
                          ((data.tp - data.cmp) / data.cmp) * 100
                        ).toFixed(2)}{" "}
                        % to Reach
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 md20 grey-background">
                  <p>
                    {data.tags.map((item) => {
                      return (
                        <span
                          className="margin-right cursor capitilize sub-text"
                          onClick={() => this.props.FilterTags(item)}
                        >
                          #{item.label}
                        </span>
                      );
                    })}
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  _live_stocks: state.publics.live_stock_value,
});
const mapDispatchToProps = (dispatch) => ({
  GetLiveValue: (v) => dispatch(actions.GetLiveValue(v)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignalCard)
);
