import { Col, Row, Button, Input, Divider, Switch, Select } from "antd";
import FixedNavigation from "../FixedNavigation/FixedNavigation";
import React from "react";


const { Option } = Select;

const CreatePoll = () => {
    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }} >
                    <Row>
                       
                        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }} className='md35  padding20' >
                            <Row>
                                <Col span={24} className='md35 text-center m-md20' >
                                    <p className="fs16 fw500">Create Poll</p>
                                </Col>
                                <Col span={24} className='md40'>
                                    <p className="fs16 fw400">Question</p>
                                    <Input
                                        className="rr-heading-input fs14 fw400 ms-1 md15"
                                        placeholder="Type your question..."
                                        size="large"
                                        bordered={true} />
                                </Col>
                                <Col span={24} >
                                    <Divider className='m-0' />
                                </Col>
                                <Col span={24} className='md30'>
                                    <p className="fs16 fw400">Question</p>
                                    <p className="text-grey"> Type your answers here in sequential order</p>
                                </Col>
                                <Col span={21} className='md25' >
                                    <Input placeholder="false" />
                                </Col>
                                <Col span={21} className='md20' >
                                    <Input placeholder="false" />
                                </Col>
                                <Col span={21} className='md20' >
                                    <Input placeholder="false" />
                                </Col>
                                <Col span={24} className='md20  '>
                                    <Button type="primary" className='addmore-btn '> Add more option </Button>
                                </Col>
                                <Col xs={{span:24, offset:0}} lg={{span:18}} className='md30  '>
                                    <p className="fs16 fw400">Expire in (dd hh mm)</p>
                                    <Row gutter={20} className='md20'>
                                        <Col className="text-center" xs={{span:6, offset:0}} lg={{span:3}}>
                                            <div ><Input max={7} maxLength={1} size="large" type={"number"} placeholder="Day" /></div>
                                        </Col>
                                        <Col className="text-center" xs={{span:6, offset:0}} lg={{span:3}}>
                                            <div ><Input max={23} maxLength={2} size="large" type={"number"} placeholder="Hour" /></div>
                                        </Col>
                                        <Col className="text-center" xs={{span:6, offset:0}} lg={{span:3}}>
                                            <div ><Input max={59} maxLength={2} size="large" type={"number"} placeholder="Min" /></div>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col span={24} className='md35'>
                                    <p className="fs16 fw400">Where to post?</p>
                                </Col>
                                <Col xs={{span:24, offset:0}} lg={{span:24}}className='md15 poll-card '>
                                    <Row>
                                        <Col xs={{span:12, offset:0}} lg={{span:12}} className='md5'>
                                            <p className="fs16 fw400 ">Post in feed  <Switch style={{ marginLeft: '25px' }} /> </p>
                                        </Col>
                                        <Col xs={{span:12, offset:0}} lg={{span:5}} className='md5'>
                                            <p className="fs16 fw400 ">Post in channel </p>
                                        </Col>
                                        <Col xs={{span:12, offset:0}} lg={{span:7}} className='category-select m-md20 '>
                                            <Select defaultValue="Channel Name first" size="large" style={{ width: 250 }} >
                                                <Option value="jack">Jack</Option>
                                                <Option value="lucy">Lucy</Option>
                                                <Option value="disabled" disabled>
                                                    Disabled
                                                </Option>
                                                <Option value="Yiminghe">yiminghe</Option>
                                            </Select>
                                        </Col>
                                    </Row>


                                </Col>
                                <Col span={24} className='md35 category-select'>
                                    <p className="fs16 fw400">Tags (optional)</p>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '60%', }}
                                        placeholder="Enter tags"
                                        defaultValue={['English']}
                                        className='md20'
                                        optionLabelProp="label"
                                        size="large"
                                    >
                                        <Option value="English" label="China">
                                            <div className="demo-option-label-item">
                                                <span role="img" aria-label="English">
                                                    🇨🇳
                                                </span>
                                                China (中国)
                                            </div>
                                        </Option>

                                    </Select>
                                </Col>
                                <Col xs={{span:24, offset:0}} lg={{span:22, offset:3}} className='mb-5 md35 text-center '>
                                    <Button type="primary" className='new-post-btn '> Post </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row >
        </>
    );
}
export default CreatePoll;
