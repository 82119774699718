import { Col, Divider, Row, Switch } from "antd";
import React from "react";

const PushNotification = () => {
    return (
        <Row style={{ padding: "40px 0px 0px 30px" }} className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18 fw400"> Notifications </p>
                <Row>
                    <Col span={18}>
                        <p className="text-grey ">Manage Your Notification Preferences</p>
                    </Col>
                </Row>
            </Col>
            <Divider />
            <Col span={24}>
                <Row>
                    <Col span={19}>
                        <p className="fs16 fw400"> Push Notifications  </p>
                        <p className="fs12 fw300 md5"> Control all push notification from syllo.  </p>
                    </Col>
                    <Col span={5} className="text-right md25">
                        <span > <Switch /> </span>
                    </Col>
                </Row>
            </Col>
            <Divider />
            <Col span={24}>
                <Row>
                    <Col span={19}>
                        <p className="fs16 fw400">Social notifications</p>
                        <p className="fs12 fw300 md5">Notifications when someone follows you </p>
                    </Col>
                    <Col span={5} className="text-right md25">
                        <span > <Switch /> </span>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default PushNotification;