import React, { Fragment } from "react";
import { Button, Skeleton, List, Divider, Tooltip, Modal, Row, Col } from 'antd';

import axios from "../../store/axios";
import InfiniteScroll from 'react-infinite-scroll-component';
import { CaretCircleDown, CaretCircleUp,X } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import { useState } from "react";

import FeedDetails from "../GlobalComponents/FeedDetails";
import CreateNotesReader from "../Notes/CreateNotesReader";
import EntityFrame from "../FeedEntity/EntityFrame";
import useToken from "../../store/useToken";





const NewsFeed = (props) => {
    const history = useHistory();
    const { data, related, Filtertags, loadMoreData, loadMore, showFooter, id } = props
    const [detailModal, setDetailsModal] = useState(false);
    const [record, setRecord] = useState(null);
    const [entityType, setEntityType] = useState(null);
    const {token} = useToken();


    const details = (record) => {

        setDetailsModal(false)

        if (token && record.entity_type === "News") {
            window.open(record.link);
            axios({
                method: 'patch',
                url: process.env.REACT_APP_PUBLIC_API_BASE_URL + 'account/user/preferences?id=' + record.entity_id + '&entity_type=News',
                headers: {
                    Authorization: token
                }
            })
            return
        }else if (record.entity_type === "Notes") {
            history.push("/note/" + record.entity_uuid)
            // window.location.href = 
            return
        }

        setEntityType(record.entity_type)
        setRecord(record)

        setDetailsModal(true)

        return
    }

    const showRealted = (item, key) => {
        item.collapsed = key
        props.reload(item.entity_id, key, item.entity_type)
    }
    const entityFrame = (item, tags, showFooter, related) => {
        return <EntityFrame related={related}
            getFilterByTags={(_item, value, key) => props.getFilterByTags(_item, value, key)}
            details={(item) => details(item)} item={item} tags={tags}
            removeObjectAfterUnSaved={(id)=>props.removeObjectAfterUnSaved(id)}
            showFooter={showFooter}
            showLike={props.showLike}
        />
    }

    return (
        <div className="row" id={id}
            style={{
                overflow: 'auto',
                height: props?.height ? props?.height: "calc(100vh - 136px)",
            }}>

            <InfiniteScroll
                dataLength={data.length}
                next={() => loadMoreData()}
                hasMore={loadMore}
                loader={<div className={"padding20"}><Skeleton avatar paragraph={{ rows: 1 }} active /></div>}
                endMessage={<Divider plain>That's all, nothing more 🤐</Divider>}
                scrollableTarget={id}
            >
                <List
                    dataSource={data}
                    className={"nopadding"}
                    renderItem={(item, i) => (
                        <List.Item key={i}>
                            <Fragment key={i}>
                                <div className={"hoverme"} style={{ padding: "15px", width: "100%" }} >
                                    {entityFrame(item, Filtertags, false, showFooter, false)}

                                    {/* Related News */}
                                    {
                                        item.collapsed === true ? <Tooltip title={"collapse related"}>
                                            <Button onClick={() => showRealted(item, false)} type={"text"} shape={"circle"} className='nopadding' style={{ float: "right",marginTop: "-31px" }} icon={<CaretCircleUp size={22} weight="thin" />}>
                                            </Button></Tooltip> :
                                            item.related > 0 ? <Tooltip title={"more related"} >
                                                <Button onClick={() => showRealted(item, true)} style={{ marginTop: "-32px", float: "right" }} type={"text"} shape={"circle"} className='nopadding' icon={<CaretCircleDown size={22} weight="thin" />}>
                                                </Button>
                                            </Tooltip> : ""
                                    }

                                    {
                                        item.collapsed === true ? <div style={{ borderLeft: "2px solid #f0f0f0", padding: "12px", borderRadius:"16px", backgroundColor:"#efefef", marginTop:"14px" }}>

                                            <div className="col-md-12 ">
                                                <p className="sub-text fw600 md10 mb-3">Related with this</p>
                                            </div>
                                            {
                                                related.map((_item) => {
                                                    return <div className={'border-top'} style={{ padding: "10px 0px" }}>
                                                        {entityFrame(_item, Filtertags, false, showFooter, true)}
                                                    </div>
                                                })
                                            }
                                        </div> : <></>
                                    }
                                    
                                </div>
                            </Fragment>
                        </List.Item>
                    )}
                />
            </InfiniteScroll>

            {
                detailModal && <Modal destroyOnClose closeIcon={<Tooltip title={"Close"}><X size={32} weight="bold" style={{marginRight:"20px",marginTop:"30px"}} /></Tooltip>}  width={"100%"} style={{ top: 0, maxWidth: "100vw", borderRadius: "0px", paddingBottom:"0px" }} overflow open onCancel={() => setDetailsModal(false)} footer={false}>
                    <Row>
                        <Col span={24} style={{ overflowY: "scroll", height: "100vh" }}>
                            {
                                entityType === "Notes" ? <CreateNotesReader d={record}></CreateNotesReader> : <FeedDetails id={record}></FeedDetails>
                            }

                        </Col>
                    </Row>

                </Modal>
            }

        </div>
    );
}

export default NewsFeed;




