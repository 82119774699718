import React, { useState } from 'react';
import { Row, Col, Avatar, Space, Button, Input, Menu, Dropdown } from "antd";
import './notes.scss';
import axios from '../../store/axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Chat } from "@phosphor-icons/react";

import Toast from '../utils/Toast';

const ViewNotes = ({ comments, showComment, item, uuid, showSubComment, subcomments, setSelectedComment, setItem }) => {
    const [comment, setcomment] = useState("")
    const [subcomment, setsubcomment] = useState(false)
    const [parent, setparent] = useState(null)


    const onClick = (item) => {
        axios({
            method: 'delete',
            url:  'market/feed/comment',
            data: {
                id: item?.uuid
            },
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        })
            .then(() => {
                showComment()
                setcomment("")
            }).catch((error) => {
               Toast(error.response.data.message,"error")
            });
    };

    const menu = (item) => (
        <Menu
            onClick={() => onClick(item)}
            items={[
                // {
                //     label: 'Edit Comment',
                //     key: '1',
                // },
                {
                    label: 'Delete',
                    key: '2',
                }
            ]}
        />
    );


    const commentOnFeed = () => {
        axios({
            method: 'post',
            url:  'market/feed/comment',
            data: {
                comment: comment,
                block_id: item?.id,
                id: uuid
            },
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        })
            .then(() => {
                showComment()
                setcomment("")
            }).catch((error) => {
               Toast(error.response.data.message,"error")
            });
    }

    const commentOnSubFeed = () => {
        axios({
            method: 'post',
            url:  'market/feed/comment',
            data: {
                comment: comment,
                block_id: parent?.block_id,
                id: uuid,
                parent_id: parent?.uuid,
            },
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        })
            .then(() => {
                showComment()
                setcomment("")
                setsubcomment(false)
            }).catch((error) => {
               Toast(error.response.data.message,"error")
            });

    }

    const timeAgo = (time) => {
        moment.updateLocale('en', {
            relativeTime: {
                future: "in %s",
                past: "%s ago",
                s: number => number + "s ago",
                ss: '%ds ago',
                m: "1m ago",
                mm: "%dm ago",
                h: "1h ago",
                hh: "%dh ago",
                d: "1d ago",
                dd: "%dd ago",
                M: "a month ago",
                MM: "%d months ago",
                y: "a year ago",
                yy: "%d years ago"
            }
        });

        let secondsElapsed = moment().diff(time, 'seconds');
        let dayStart = moment("2018-01-01").startOf('day').seconds(secondsElapsed);

        if (secondsElapsed > 300) {
            return moment(time).fromNow(true);
        } else if (secondsElapsed < 60) {
            return dayStart.format('s') + 's ago';
        } else {
            return dayStart.format('m') + 'm ago';
        }
    }

    return (
        <Row>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}  >

                {
                    item && <Row className='view-card'>
                        {/* Input */}
                        <Col span={24}>
                            <Input placeholder="Reply..." value={comment} onChange={(evt) => setcomment(evt.target.value)} className="md15" size="small" maxLength={250} />
                        </Col>
                        <Col span={24} className='md15 text-right'>
                            <Space>
                                <Button type="dashed"  onClick={() => {
                                    setItem(null)
                                }}>Cancel</Button>
                                <Button type="primary" onClick={() => commentOnFeed()}>Post</Button>
                            </Space>
                        </Col>

                    </Row>
                }

                {
                    comments.map((item) => {
                        return <Row className='view-card note-card-shadow md20 radius18'>
                            <Col xs={{ span: 18, offset: 0 }} lg={{ span: 18, offset: 0 }}>
                                <Space>
                                    <Avatar size={35} src={item?.img_url}></Avatar>
                                    <a href={`/profile/` + item.user_code}><p className="fs14 fw500  ">{item?.name}</p></a>
                                    <p className="fs10 fw400  text-grey ps-2">{timeAgo(item?.updated_at)}</p>
                                </Space>
                            </Col>
                            <Col span={6} xs={{ span: 6, offset: 0 }} className="text-right md5  ">
                                <Space>
                                    <Button size="small" type="text" className='note-icon' shape='circle' onClick={() => {
                                        setparent(item)
                                        setsubcomment(true)
                                    }}> <Chat size={20} weight="thin" /></Button>
                                    {
                                        item?.me &&  <Dropdown trigger={["click"]} overlay={menu(item)}>
                                        <Button onClick={(e) => e.preventDefault()} shape='circle' className='note-icon' size="small" type="text"> <img src={require('../../assets/images/dots.svg').default} alt="" /></Button>
                                    </Dropdown>
                                    }
                                   
                                </Space>
                            </Col>
                            <Col span={24}>
                                <p className="fs14 fw400 md10 cursor" onClick={() => setSelectedComment(item)}> {item?.comment}</p>
                            </Col>




                            {
                                subcomment && item?.uuid === parent?.uuid && <Col span={24}>  <Row>
                                    {/* Input */}
                                    <Col span={24} className="md10">
                                        <Input placeholder="Reply..." value={comment} shape='circle' onChange={(evt) => setcomment(evt.target.value)} className="md15" size="small" maxLength={250} />
                                    </Col>
                                    <Col span={24} className='md15 text-right'>
                                        <Space>
                                            <Button type="dashed" shape='default' onClick={() => {
                                                setsubcomment(false)
                                            }}>Cancel</Button>
                                            <Button type="primary" onClick={() => commentOnSubFeed()}>Post</Button>
                                        </Space>
                                    </Col>

                                </Row></Col>
                            }


                            {/* Comment */}
                            {
                                           item?.replies > 0 && <Col span={24}>
                                           <Row className='md10'>
                                               <Col span={16}>
                                                   <Button  onClick={() => showSubComment(item)} type='default' className=' green-note'> {item?.replies} reply </Button>
                                               </Col>
                                           </Row>
                                       </Col>
                                           
                                           }
                            

                            {
                                item?.show_reply && <>
                                    {
                                        subcomments.map((item) => {
                                            return <Col lg={{ span: 24 }} xs={{ span: 24 }} className='ps-3'>
                                                <Row className='md10 card-comment'>
                                                    <Col span={19} style={{ alignSelf: 'center' }}>
                                                        <Space align='baseline'>
                                                            <Avatar size={35} src={item?.img_url}></Avatar>
                                                            <a href={`/profile/` + item.user_code}> <p className="fs14 fw500 ">{item?.name}</p></a>
                                                            <p className="fs10 fw400 text-grey ps-2">{timeAgo(item?.updated_at)}</p>
                                                        </Space>
                                                    </Col>
                                                    <Col lg={{span:5}} xs={{span:5}} className="text-right">
                                                        {
                                                            item?.me && <Space align='baseline'>
                                                            <Dropdown trigger={["click"]} overlay={menu(item)}>
                                                                <Button onClick={(e) => e.preventDefault()} size="small" type="text"> <img src={require('../../assets/images/dots.svg').default} alt="" /></Button>
                                                            </Dropdown>
                                                        </Space>
                                                        }
                                                        
                                                    </Col>
                                                    <Col span={24}>
                                                        <p className="fs14 fw400 md10"> {item?.comment}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        })
                                    }
                                </>
                            }


                        </Row>
                    })
                }




            </Col>
        </Row>

    );
}
export default ViewNotes;