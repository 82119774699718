import {  CreditCard } from "@phosphor-icons/react";
import { Button, Col,  Row, Space, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import useToken from "../../store/useToken";
import { useHistory } from "react-router-dom";

import Toast from "../utils/Toast";
import axios from "../../store/axios";




const SylloCoin = () => {
    const [plans, setPlans] = useState([]);
    const [wallet, setWallet] = useState({});
    const [loader, setloader] = useState(false)
    const { token } = useToken();
    const history = useHistory();
    useEffect(() => {
        getWallet();
        getPlans();
    }, [])

    const getPlans = async () => {
        setloader(true);
        await axios({
            method: 'get',
            url: `v1.0/wallet/plans`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setPlans(data);
        }).catch((error) => {
            Toast(error.response.data.message,"error")
        })
        setloader(false);
    }

    const getWallet = () => {
        axios({
            method: 'get',
            url: `v1.0/reward/wallet`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setWallet(data);
        }).catch((error) => {
            Toast(error.response.data.message,"error")
        })
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const buyCoinsRZP = async (planId) => {
        var values = { "plan_id": planId }
        setloader(true);
        await axios({
            method: 'post',
            url: "lighthouse/payment/init",
            data: values,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            displayRazorpay(data)
        });
        setloader(false);
    }

    const manuallyVerifyPaymentStatus = async (data) => {
        await axios({
            method: 'post',
            url: "lighthouse/payment/verify",
            data: data,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            if (res?.data?.data?.status) {
               Toast("Payment Success!!","success");
                // setloader(true)
                getWallet();
            
                history.goBack()
            }
            else {
                Toast("Payment Failed !","error")
            }
        })

    }

    async function displayRazorpay(config) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            Toast("Razorpay SDK failed to load. Are you online?","error");
            return;
        }

        const options = {
            ...config,
            handler: async function (response) {
                console.log(response)
                const data = {
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_signature: response.razorpay_signature,
                    "is_subscription": false
                };
                manuallyVerifyPaymentStatus(data);
               Toast("Verifying payment status...","success");
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }



    return (
        <Row>
            <Col xl={{ span: 12, offset: 6  }} lg={{span:14, offset:5}}  md={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }} className="md50" >
                <Row>
                    <Col xs={24} md={12} className="md10">
                        <Space>
                            <img src={require('../../assets/images/newdashboard/sylloCoin.svg').default} height={30} alt="" />
                            <p className="fs24 fw600"> Coins </p>
                        </Space>
                    </Col>
                    <Col xs={24} md={12} className="text-right m-text-left md10">

                        <Space size={20}>
                            <div className=" text-right">
                                <p className="fw400"> Current Balance </p>
                                <p className="fs14 fw-bold"> {wallet?.coins} Coins </p>

                            </div>
                            <Button onClick={()=>history.push('/coins/history')} type="primary" className="fs14 fw500"> <span className=" pe-2">
                                <CreditCard size={22} color="#ffffff" weight="light" /></span> Coin History </Button>
                        </Space>


                    </Col>
                    <Col span={24} className="md40 mb-5">
                            <p className="fs14 fw400 text-grey"> Buy More Coins   </p>
                       
                        <Spin spinning={loader} delay={500}>

                            <Row gutter={{ lg: 20, md: 20, xs: 10 }} >
                                {
                                    plans.map((item, k) => {
                                        return <Col key={k} lg={8} md={12} xs={12} className="md20">
                                            <div className="coin-card text-center">

                                                {
                                                    item.offer_tag ?
                                                        <Space size={8}>
                                                            <Tag className="fs11" color={item.offer_tag}>{item.offer_name}</Tag>
                                                            {
                                                                item.offer_off > 0 && <Tag color="green"> {item.offer_off}% Off </Tag >
                                                            }

                                                        </Space> : <div style={{ height: "20px" }}></div>
                                                }
                                                <span>
                                                    <h3 className="m-0 fw600"> {item.name} </h3>
                                                    <p className="fs16  fw400 text-grey"> coins </p>
                                                </span>
                                                <Button type="ghost" onClick={() => buyCoinsRZP(item.plan_id)} >₹ {item.amount} </Button>
                                            </div>
                                        </Col>
                                    })
                                }


                            </Row>

                        </Spin>
                        {/* <Row>
                            <Col span={8} offset={8} className="md50 mb-5 text-center">
                                <Button type="primary" className="" > Buy Now </Button>
                            </Col>
                        </Row> */}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default SylloCoin;