import React, { useState } from "react";
import { Col, Row, Input, Button, Divider, Alert } from "antd";
import axios from "../../../store/axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useHistory } from "react-router";

import Toast from "../../utils/Toast";

const UpdateProfile = ({ data }) => {
    const [username, setusername] = useState("")
    const history = useHistory()

    useEffect(() => {
            setusername(data?.username)
    }, [data])

    const UpdateProfile = () => {
        if (username === "") {
            Toast("Enter Username","error")
            return
        }
        axios({
            method: 'put',
            url:  "account/user/profile",
            data: {
                username: username
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
            history.push("/settings")
        }).catch((err) => {
            Toast(err.response.data.message,"error")
        })
    }

    return (

        <Row style={{ padding: "40px 0px 30px 30px" }} className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18">Change Username</p>
                <Row>
                    <Col span={18}>
                        <p className="text-grey">Username is a unique identification in Syllo, It’s a public identity</p>
                    </Col>

                </Row>

                <Divider></Divider>

            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 16 }} md={{ span: 16 }}>
                <p style={{ color: "#484848" }} className="sub-text-light">Username</p>
                <Input value={username} onChange={(evt) => setusername(evt.target.value)}></Input>
            </Col>
            <Col span={24} className="md30">
                <Alert icon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1.80078C5.02266 1.80078 1.8 5.02344 1.8 9.00078C1.8 12.9781 5.02266 16.2008 9 16.2008C12.9773 16.2008 16.2 12.9781 16.2 9.00078C16.2 5.02344 12.9773 1.80078 9 1.80078ZM9.6 12.6008H8.4V8.40078H9.6V12.6008ZM9 6.90078C8.50313 6.90078 8.1 6.49766 8.1 6.00078C8.1 5.50391 8.50313 5.10078 9 5.10078C9.49688 5.10078 9.9 5.50391 9.9 6.00078C9.9 6.49766 9.49688 6.90078 9 6.90078Z" fill="#146EB4" />
                </svg>
                } message="A username can only contain alphanumeric characters (letters A-Z, numbers 0-9) with the exception of underscore (_) or dot (.), as noted above. Check to make sure your desired username doesn't contain any symbols, dashes, or spaces." type="info" showIcon />
            </Col>

            <Col span={24} className="md30 text-right">
                <Button type="primary" onClick={() => UpdateProfile()}>Save Changes</Button>

            </Col>
        </Row>

    );
}
export default UpdateProfile;
