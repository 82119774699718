import React from "react";
import { toast } from "react-hot-toast";

const Toast = (message, type) => {
  switch (type) {
    case "success":
      return toast.success(
        message,{
            style: {
                padding: '12px 30px',
                background:"#EAF9E7",
                fontWeight:"600"
              },
        }
      );
    case "error":
      return toast.error(
        message,{
            style: {
                padding: '12px 30px',
                background:"#f9e7e7"
              },
        }
      );
    case "warning":
      return toast.error(
        <div>
          <p>{message}</p>
        </div>
      );
    default:
      return toast.error(
        <div>
          <p>message</p>
        </div>
      );
  }
};
export default Toast;