import React, { useEffect, useState } from "react";
import { Space, Button, Divider, message, Row, Col, Modal } from 'antd';
import { RWebShare } from "react-web-share";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { useHistory, withRouter } from "react-router";
import moment from "moment";
import { BookmarkSimple, ChatCircleText, Export, HandsClapping } from "@phosphor-icons/react";
import useToken from "../../store/useToken";
import {useSelector } from 'react-redux';
import Toast from "../utils/Toast";
import EntityComments from "./EntityComments";

moment.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: 'a min',
        ss: '%d seconds',
        m: "a min",
        mm: "%d min",
        h: "1 hour", //this is the setting that you need to change
        hh: "%d hours",
        d: "today",
        dd: "%d days",
        w: "a week",
        ww: "%d weeks",
        M: "1 month", //change this for month
        MM: "%d months",
        y: "a year",
        yy: "%d years"
    }
});

const EntityFooter = (props) => {
    const { item } = props;

    const { entity } = useSelector((state) => state.entity);
    const [saved, setSaved] = useState(item?.saved);
    const [liked, setLiked] = useState(item?.liked);
    const [likes, setLikes] = useState(item?.likes);
    const [comments, setComments] = useState(item?.comments);
    const [innerWidth,] = useState(window.innerWidth);
    const [modal, setModal] = useState(false);
    const { token } = useToken();
    const history = useHistory();

    useEffect(() => {
        if (entity !== null && entity.entity_uuid === item.entity_uuid) {
            setSaved(entity?.saved);
            setLiked(entity?.liked);
            setLikes(entity?.likes);
            setComments(entity?.comments);
        }
    }, [entity]);


    const deleteFeed = async(id, entity_type) => {
        if (!token) {
            history.push('/login');
            return
        }
        setSaved(false);
       await props.SaveFeed({
            "id": id,
            "entity_type": entity_type,
            "type": "Delete"
        });
        props.removeObjectAfterUnSaved(id)
    }

    const saveFeed = (id, entity_type) => {
        if (!token) {
            history.push('/login');
            return
        }
        setSaved(true);
        props.SaveFeed({
            "id": id,
            "entity_type": entity_type,
            "type": "Post"
        })
        Toast('Saved Successfully !',"success");
    }

    const likeEntity = (inc) => {
        if (!token) {
            history.push('/login');
            return
        }
        let _likes = likes
        if (inc) {
            _likes = _likes + 1
        } else {
            _likes = _likes - 1
        }
        setLiked(inc);
        setLikes(_likes);
        props.likeFeed({
            id: item?.entity_uuid,
            type: item.entity_type,
            requesttype: inc ? "post" : "delete",
        });

    }

    return (
        <Row>
            <Col lg={24} md={24} xs={24}>
                <div className="mt-3 text-space-wb feed-footer" style={{float:item?.entity_type !== "News" ? "" : "right"}}>
                    {
                        item?.entity_type !== "News" && <Space split={<Divider type="vertical" />} size={4} >
                            <Space size={2} align="center">
                                {
                                    liked === true ?
                                        <Button type={"text"} className={"blue-hover"} shape={"circle"} icon={<HandsClapping size={22} weight="fill" color={"#3B6BE3"} />} onClick={() => likeEntity(false)} ></Button>
                                        : <Button type={"text"} className={"blue-hover"} shape={"circle"} icon={<HandsClapping size={22} weight="thin" className={"border-blue"} />} onClick={() => likeEntity(true)}></Button>
                                }
                                {likes > 0 && likes}
                            </Space>
                            <Space size={2} align="center">
                                <Button type={"text"} className={"blue-hover"} shape={"circle"} icon={<ChatCircleText className={"border-blue"} size={22} weight="thin" />} onClick={() => setModal(true)}></Button>
                                {comments > 0 && comments}
                            </Space>
                        </Space>
                    }
                    
                    <Space split={<Divider type="vertical" />} size={4} >
                        {
                            innerWidth > 912 && <p className="fs12" style={{ color: "#728096" }}> {moment(item?.published_at).fromNow(true)}</p>
                        }


                        {
                            saved === true ? <Button className={"blue-hover"} type={"text"} shape={"circle"} icon={<BookmarkSimple className={"border-blue"} size={22}  color={"#3B6BE3"} weight="fill" />} onClick={() => deleteFeed(item.entity_id, item.entity_type)}></Button> :
                                <Button className={"blue-hover"} type={"text"} shape={"circle"} icon={<BookmarkSimple className={"border-blue"} size={22} weight="thin" />} onClick={() => saveFeed(item.entity_id, item.entity_type)}></Button>
                        }
                        <RWebShare
                            data={{
                                text: item?.description,
                                url: `${process.env.REACT_APP_URL}feed-view/${item?.entity_id}`,
                                title: item?.title,
                            }}>
                            <Button className={"blue-hover"} type={"text"} shape={"circle"} icon={<Export className={"border-blue"} size={22} weight="thin" />}>
                            </Button>
                        </RWebShare>
                        {
                            item?.related > 0 && <div style={{ marginRight: "30px" }}></div>
                        }

                    </Space>
                   
                   
                </div>
            </Col>
            <Modal centered title={`${comments} Comments`} destroyOnClose open={modal} footer={false} onCancel={() => setModal(false)} maskClosable={false}>
                <Row>
                    <Col span={22} offset={1}>
                        <EntityComments commentCount={(value)=>setComments(value)} item={item} />
                    </Col>
                </Row>

            </Modal>
        </Row>
    );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    SaveFeed: (v) => dispatch(actions.SaveFeed(v)),
    likeFeed: (v) => dispatch(actions.likeFeed(v)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EntityFooter)
);




