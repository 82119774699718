import React from "react";
import { Avatar, Col, Row, Skeleton, Space } from "antd";
import { useHistory } from "react-router-dom";
import { Buildings, Heart } from "@phosphor-icons/react";

const EventsCount = ({ counts, loading }) => {
    const history = useHistory();
    return (
        <Row>

            {
                loading ? <><Skeleton active /> <Skeleton active /></> : <>
                    <Col span={24} className="update-card cursor" onClick={()=>history.push(`/results`)}>
                        <Row >
                            <Col span={24} className="">
                                <Space>
                                <Buildings size={24} weight="fill" />
                                    <p className="fs14 fw600 ">  Corporate Results </p>

                                </Space>
                            </Col>
                            <Col span={24}>
                                <a href={"/results"} className="fs12 fw400 md10 " style={{ color: '#4B5669' }}>{counts?.month_corporate ? counts?.month_corporate : "No"} Results this month. {counts?.today_corporate ? counts?.today_corporate : "No"} Result today</a>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={24} className="update-card md20 cursor"  onClick={()=>history.push(`/results`)}>
                        <Row >
                            <Col span={24} className="">
                                <Space>
                                    <Avatar size={25} src={'https://media.syllo.co/master/logo.svg'} />
                                    <p className="fs14 fw600 ">   Syllo Events </p>
                                </Space>

                            </Col>
                            <Col span={24}>
                                <a href={"/results"} className="fs12 fw400 md10 " style={{ color: '#4B5669' }}>{counts?.month_syllo ? counts?.month_syllo : "No"} Events this month.  {counts?.today_syllo ? counts?.today_syllo : "No"}  events today</a>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={24} className="update-card md20 mb-2 cursor"  onClick={()=>history.push(`/results`)}>
                        <Row >
                            <Col span={24} className="">
                                <Space>
                                <Heart size={24} weight="fill" color="#e65100"/>
                                    <p className="fs14 fw600 ">  Favourite Events </p>
                                </Space>


                            </Col>
                            <Col span={24}>
                                <a href={"/results"} className="fs12 fw400 md10 " style={{ color: '#4B5669' }}>{counts?.month_fav ? counts?.month_fav : "No"} events this month. {counts?.today_fav ? counts?.today_fav : "No"} events today</a>
                            </Col>

                        </Row>
                    </Col>
                </>
            }
        </Row >
    );
}

export default EventsCount;