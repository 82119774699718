import React, { useState, useEffect } from "react";
import { Col, Row, Divider, Select, Skeleton } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import AreaChart from "../Charts/AreaChart"
import PieChart from "../Charts/PieChart"
import moment from "moment";

const { Option } = Select

const InsightsProfile = () => {
    const [insights, setinsights] = useState([])
    const [chart, setchart] = useState([])
    const [range, setrange] = useState("last7Days")
    const [loader, setloader] = useState(true)
    const [chartloader, setchartloader] = useState(true)
    const [pie, setpie] = useState([])
    useEffect(() => {
        getRangeInsights(range)
    }, [])


    const getRangeInsights = (evt) => {
        setrange(evt)
        setloader(true)
        setchartloader(true)
        axios({
            method: 'get',
            url:  "v1.0/analytics/insights?range=" + evt,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            const data = res.data.data
            setinsights(data)
            setloader(false)
        })

        axios({
            method: 'get',
            url:  "v1.0/analytics/insights/graph?range=" + evt,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            const data = res.data.data

            setchart(data?.acc_reach_count)
            setpie(data?.follower_gender_count)
            setchartloader(false)
        })
    }

    return (
        <>
            <Row style={{ padding: "40px 0px 30px 30px" }} className="m-nopadding">
                <Col span={24}>
                    <p className="m-0 fs18">Profile Insights</p>
                    <Row>
                        <Col xs={{ span: 12 }} lg={{ span: 18 }}>
                            <p className="text-grey">Profile Insights Overview</p>
                        </Col>
                        <Col xs={{ span: 12 }} lg={{ span: 6 }} className="  ">
                            <Select value={range} onChange={(evt) => getRangeInsights(evt)} className="profile-select m-ml25" style={{ width: 130 }} >
                                <Option value="last7Days">Last 7 Days</Option>
                                <Option value="last14Days">Last 14 Days</Option>
                                <Option value="last30Days">Last 30 Days</Option>
                                <Option value={"last90Days"}>Last 90 Days</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Divider></Divider>
                </Col>

                {
                    loader ? <Skeleton></Skeleton> :
                        <Col span={24} className="md30">
                            <p className="fs12 fw300">Analytics from {moment(insights?.date_2).format("Do MMMM YYYY")} - {moment(insights?.date_1).format("Do MMMM YYYY")} </p>
                            <Row className="md25">
                                <Col span={11} className='insight-card text-center'>
                                    <h4 className="fs18 fw500 m-0">{insights?.acc_reach_count}</h4>
                                    <span className="fs12 fw300 mt-0" style={{marginTop:'-5px'}}>
                                        {
                                            insights?.reach_perc < 0 ? <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.24089 0.90625C3.09214 0.90625 2.94334 0.9639 2.82959 1.08057L0.787924 3.12223L1.61621 3.95052L2.66667 2.90462V8.50098H3.83333V2.91829L4.85986 3.94482L5.68815 3.12223L3.65218 1.08057C3.53843 0.9639 3.38964 0.90625 3.24089 0.90625H3.24089ZM6.74089 3.23389C6.59214 3.23389 6.44334 3.29154 6.32959 3.4082L4.28792 5.45557L5.11621 6.28385L6.16667 5.23796V8.50098H7.33333V5.24023L8.36556 6.27246L9.19385 5.44987L7.15218 3.4082C7.03843 3.29154 6.88964 3.23389 6.74089 3.23389Z" fill="#1DB954" /></svg> : <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.75912 8.09375C6.90787 8.09375 7.05667 8.0361 7.17042 7.91943L9.21208 5.87777L8.38379 5.04948L7.33334 6.09538L7.33334 0.499023H6.16667L6.16667 6.08171L5.14014 5.05518L4.31185 5.87777L6.34782 7.91943C6.46157 8.0361 6.61037 8.09375 6.75912 8.09375H6.75912ZM3.25912 5.76611C3.40787 5.76611 3.55667 5.70846 3.67042 5.5918L5.71208 3.54443L4.88379 2.71615L3.83334 3.76204V0.499023H2.66667V3.75977L1.63445 2.72754L0.806158 3.55013L2.84782 5.5918C2.96157 5.70846 3.11037 5.76611 3.25912 5.76611Z" fill="#FF1712" />
                                            </svg>

                                        }

                                        {parseFloat(insights?.reach_perc).toFixed(1)}%</span>
                                    <p className="fs14 fw300">Accounts reached</p>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={11} className='insight-card text-center'>
                                    <h4 className="fs18 fw500 m-0">{insights?.total_followers}</h4>
                                    <span className="fs12 fw300 m-0">
                                        {
                                            insights?.followers_perc < 0 ? <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.24089 0.90625C3.09214 0.90625 2.94334 0.9639 2.82959 1.08057L0.787924 3.12223L1.61621 3.95052L2.66667 2.90462V8.50098H3.83333V2.91829L4.85986 3.94482L5.68815 3.12223L3.65218 1.08057C3.53843 0.9639 3.38964 0.90625 3.24089 0.90625H3.24089ZM6.74089 3.23389C6.59214 3.23389 6.44334 3.29154 6.32959 3.4082L4.28792 5.45557L5.11621 6.28385L6.16667 5.23796V8.50098H7.33333V5.24023L8.36556 6.27246L9.19385 5.44987L7.15218 3.4082C7.03843 3.29154 6.88964 3.23389 6.74089 3.23389Z" fill="#1DB954" /></svg> : <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.75912 8.09375C6.90787 8.09375 7.05667 8.0361 7.17042 7.91943L9.21208 5.87777L8.38379 5.04948L7.33334 6.09538L7.33334 0.499023H6.16667L6.16667 6.08171L5.14014 5.05518L4.31185 5.87777L6.34782 7.91943C6.46157 8.0361 6.61037 8.09375 6.75912 8.09375H6.75912ZM3.25912 5.76611C3.40787 5.76611 3.55667 5.70846 3.67042 5.5918L5.71208 3.54443L4.88379 2.71615L3.83334 3.76204V0.499023H2.66667V3.75977L1.63445 2.72754L0.806158 3.55013L2.84782 5.5918C2.96157 5.70846 3.11037 5.76611 3.25912 5.76611Z" fill="#FF1712" />
                                            </svg>

                                        }
                                        {parseFloat(insights?.followers_perc).toFixed(1)}%</span>
                                    <p className="fs14 fw300">Total followers</p>
                                </Col>
                            </Row>
                        </Col>
                }
                <Col span={24} className="md40 text-center">
                    {
                        chartloader ? <Skeleton></Skeleton> :chart.length !== 0 &&  <AreaChart  result={chart}></AreaChart>
                    }

                </Col>



                <Col span={24} className='md30'>
                    <Row >
                        {
                            pie.length !== 0 &&<Col span={24} className='md15 ps-0'>
                            <p className="fs14 fw500 ">Followers and non Followers</p>
                            <p className="fs12 fw300 ">Based on reach</p>
                        </Col>
                        }
                        
                    </Row>

                </Col>


                <Col span={24}>
                    {
                        chartloader ? <Skeleton></Skeleton> : pie.length !== 0 && <PieChart  result={pie}></PieChart>
                    }
                </Col>


            </Row>

        </>
    );
}
export default InsightsProfile;
