import React, { Fragment, PureComponent } from "react";
import SignalCard from "./SignalCard";
import { Input, Checkbox, Radio, Select, message } from "antd";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import Loader from "../Loader/Loader";
import TextArea from "antd/lib/input/TextArea";
import Toast from "../utils/Toast";
const { Option } = Select;

class CreateSignal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stocksList: [],
      selectedStock: "",
      ltp: "",
      choosedStock: "",
      price: "",
      change: "",
      security_id: "",
      products: [],
      my_signals: [],
      loader: true,
    };
  }

  async componentDidMount() {
    await this.props.MySignals().then(() => {
      this.props._my_signals.map((item) => {
        item.ltpshow = true;
      });
      this.setState({
        my_signals: this.props._my_signals,
        loader: false,
      });
    });
  }

  async serchStock(evt) {
    await this.props.listofStocks({ value: evt }).then(() => {
      this.setState({
        stocksList: this.props._stcoks,
      });
    });
  }

  changeContent(data, value) {
    data.ltpshow = value;
    this.setState({
      ...this.state.my_signals,
    });
  }
  async getLtp(evt) {
    let data = JSON.parse(evt);
    await this.props.getLtp({ value: data.security_id }).then(() => {
      let _data = {
        stocks: [
          {
            exch: data.exchange,
            exch_type: data.exchange_type,
            symbol: data.trading_symbol,
          },
        ],
      };

      this.props.getPrice(_data).then(() => {
        this.setState({
          selectedStock: this.props._ltp,
          ltp: this.props._ltp.ltp,
          price: this.props._price[0].p,
          change: this.props._price[0].pch,
          security_id: data.security_id,
        });
      });
    });
  }

  createSignal(evt) {
    evt.preventDefault();

    if (this.state.sl > this.state.ltp) {
      Toast("Stop loss can't be more than LTP","error");
      return;
    }

    if (this.state.trans_type === "") {
      Toast("Choose Transaction Type","error");
      return;
    }

    if (this.state.products.length === 0) {
      Toast("Choose Some product types","error");
      return;
    }

    this.props
      .createSignal({
        security_id: this.state.security_id,
        products: this.state.products,
        cmp: this.state.ltp,
        tp: this.state.tp,
        sl: this.state.sl,
        range: this.state.range,
        trans_type: this.state.trans_type,
      })
      .then(() => {
        this.props.history.push("/signals");
      });
  }

  FilterTags(item, value) {
    this.props
      .FilterTags({
        type: item.type || value,
        tag: item.label || item.join(","),
      })
      .then(() => {
        this.setState({
          all_signals: this.props._all_signals,
          loader: false,
        });
      });
  }

  deleteSignal(item) {
    this.props
      .deleteSignal({
        id: item,
      })
      .then(() => {
        Toast("Deleted Successfully","success");
        this.componentDidMount();
      });
  }

  render() {
    const options = [
      { label: "Intraday", value: "Intraday" },
      { label: "Overnight", value: "Overnight" },
      { label: "Short Term", value: "Short Term" },
      { label: "Long Term", value: "Long Term" },
    ];
    const children = this.state.stocksList;
    return (
      <div className="row">      

        <div className="col-md-11  col-xsm-12 navigation-body-width">
          <div className="row">

            <div className="col-md-6 md35">
              <div className="row">
                <div className="col-md-12 ">
                  <h5 className="sub-text md10">  <i className="fa fa-arrow-left  cursor" onClick={() => this.props.history.goBack()} style={{ fontSize: "14px", color: "#666666", marginRight: "15px" }}></i> Create Signal</h5>
                </div>
                <div className="col-md-8 md20">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Search Instrument</label> <br></br>
                      <Select
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        size="large"
                        style={{ width: "100%" }}
                        placeholder="Search stock"
                        showSearch
                        onSearch={(evt) => this.serchStock(evt)}
                        onChange={(evt) => this.getLtp(evt)}
                      >
                        {children.map((item) => {
                          return (
                            <Option value={JSON.stringify(item)}>
                              {" "}
                              {item.full_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>

                {this.state.selectedStock === "" ? (
                  <></>
                ) : (
                  <Fragment>
                    <div className="col-md-8 md35">
                      <form
                        className="row nomargin"
                        onSubmit={(evt) => this.createSignal(evt)}
                      >
                        <div className="col-md-12 grey-background">
                          <div className="row">
                            <div className="col-md-6 md10">
                              <h5 className="smaller-heading-text">
                                {this.state.selectedStock.name}
                              </h5>
                            </div>
                            <div className="col-md-6 text-right md10">
                              <h5 className="smaller-heading-text">
                                {this.state.price}
                                {this.state.change > 0 ? (
                                  <span
                                    className="text-success"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    ( {this.state.change} %)
                                  </span>
                                ) : (
                                  <span
                                    className="text-danger"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    ( {this.state.change} %)
                                  </span>
                                )}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 md20 nopadding">
                          <Checkbox.Group
                            options={options}
                            onChange={(evt) => this.setState({ products: evt })}
                          />
                        </div>

                        <div className="col-md-3 text-center md20 nopadding">
                          <label>LTP</label> <br></br>
                          <Input
                            bordered={true}
                            disabled
                            value={this.state.ltp}
                            className="grey-background text-center"
                          ></Input>
                        </div>
                        <div className="col-md-3 text-center md20">
                          <label>Target</label> <br></br>
                          <Input
                            bordered={true}
                            required
                            onChange={(evt) =>
                              this.setState({ tp: evt.target.value })
                            }
                            className="grey-background text-center"
                          ></Input>
                        </div>
                        <div className="col-md-3 text-center md20 ">
                          <label>SL</label> <br></br>
                          <Input
                            bordered={true}
                            required
                            onChange={(evt) =>
                              this.setState({ sl: evt.target.value })
                            }
                            className="grey-background text-center"
                          ></Input>
                        </div>
                        <div className="col-md-3 text-center md20 nopadding">
                          <label>Range</label> <br></br>
                          <Input
                            bordered={true}
                            required
                            onChange={(evt) =>
                              this.setState({ range: evt.target.value })
                            }
                            className="grey-background text-center"
                          ></Input>
                        </div>

                        <div className="col-md-12 md20 nopadding">
                          <Radio.Group
                            defaultValue="a"
                            buttonStyle="solid"
                            onChange={(evt) =>
                              this.setState({ trans_type: evt.target.value })
                            }
                          >
                            <Radio.Button value="BUY">Buy</Radio.Button>
                            <Radio.Button value="SELL">Sell</Radio.Button>
                            <Radio.Button value="HOLD">Hold</Radio.Button>
                          </Radio.Group>
                        </div>

                        <div className="col-md-12 md20 nopadding">
                          <label>Advice</label> <br></br>
                          <TextArea rows={5}></TextArea>
                        </div>

                        <div className="col-md-12 nopadding md20">
                          <button className="btn btn-default" type="submit">
                            Create{" "}
                            <span>
                              <i
                                className="fa fa-arrow-right"
                                style={{ float: "right" }}
                              ></i>
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-4 md35">
                      <i
                        class="fa fa-times cursor md20"
                        aria-hidden="true"
                        onClick={() =>
                          this.setState({
                            selectedStock: "",
                            products: [],
                            trans_type: "",
                          })
                        }
                      ></i>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
            <div className="col-md-6  border-left  height100vh">
              <div className="row">
                <div className="col-md-12 md35">
                  <h5 className="heading-text">Active Signals</h5>
                </div>
                {this.state.loader === true ? (
                  <div className="col-md-12 text-center">
                    <Loader></Loader>
                  </div>
                ) : (
                  <div className="col-md-12 padding30t">
                    {this.state.my_signals.map((item) => {
                      return (
                        <SignalCard
                          data={item}
                          my={true}
                          deleteSignal={(item) => this.deleteSignal(item)}
                          FilterTags={(item) => this.FilterTags(item)}
                          changeContent={(data, value) =>
                            this.changeContent(data, value)
                          }
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  _stcoks: state.publics.stocks,
  _ltp: state.publics.ltp,
  _price: state.publics.stock_price,
  _my_signals: state.publics.my_signals,
});
const mapDispatchToProps = (dispatch) => ({
  listofStocks: (v) => dispatch(actions.listofStocks(v)),
  getLtp: (v) => dispatch(actions.getLtp(v)),
  createSignal: (v) => dispatch(actions.createSignal(v)),
  getPrice: (v) => dispatch(actions.getPrice(v)),
  MySignals: (v) => dispatch(actions.MySignals(v)),
  FilterTags: (v) => dispatch(actions.FilterTags(v)),
  deleteSignal: (v) => dispatch(actions.deleteSignal(v)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateSignal)
);
