import React, { PureComponent } from "react";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import Loader from "../Loader/Loader"
import Cookies from "js-cookie"

class EmailVerify extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            EmailVerify: true
        };
    }
     writeCookie (cname, cvalue, days) {
        var dt, expires,host;
        dt = new Date();
    
        if(window.location.host.includes(".syllo.co")){
            host = ".syllo.co"
        }
        else{
            host = window.location.host.split(":")[0]
        }
        dt.setTime(dt.getTime()+(days*24*60*60*1000));
        expires = "; expires="+dt.toGMTString();
        document.cookie = cname+"="+cvalue+expires+'; domain='+host;
        }
    

    componentDidMount() {
        var url_string = window.location.href; //window.location.href
        var url = new URL(url_string);
        var c = url.searchParams.get("access");
        var user = url.searchParams.get("u")

        if (c !== null || undefined) {
            this.writeCookie("Logintoken",c,1)
            this.props.EmailVerify({
                token:c,
                u: user
            }).then(()=>{
                this.setState({EmailVerify:true,loader:false})
                if (this.props._login.is_preferanced === false){

                    setTimeout(() => {
                        this.props.history.push("/preference")
                    }, 5000);
                  
                }
                else {
                    setTimeout(() => {
                        this.props.history.push("/dashboard")
                    }, 5000);
                }
            })
        }




        let data = this.props.history.location.state
        if (data !== undefined || null) {
            this.setState({
                EmailVerify: data.verified, loader: false
            })
        }
    }


    render() {
        return (
            <div className="row white-background-login">
                <div className="col-md-4 offset-md-4 text-center border-card md100">
                    <div className="row">
                        {/* <div className="col-md-12">
                            <img src={require("../../assets/images/syllo_text.svg")} alt="logo"></img>
                        </div> */}

                        <div className="col-md-6 offset-md-3 text-center">
                            <img src={require("../../assets/images/Desktop/syllo.svg").default} alt="logo" className="img-fluid" style={{width:"40%"}}></img>
                        </div>



                        {
                            this.state.loader === true ? <Loader className="md50" /> : this.state.EmailVerify === true ? <><div className="col-md-12 md50">
                                <p className="sub-text">Congratulations, your account has been <br></br> created and verified successfully.</p>
                            </div>

                                <div className="col-md-12 md20">
                                    <img src={require("../../assets/images/success.PNG")} alt="logo"></img>
                                </div>

                                <div className="col-md-12 md50">
                                    <p className="sub-text">You will redirect to dashboard in 5 sec </p>
                                </div></> : <>
                                <div className="col-md-12 md50">
                                    <p className="sub-text">Congratulations, your account has been <br></br> created and please verify your account.</p>
                                </div>


                                <div className="col-md-12 md100">
                                    <p className="sub-text">We have sent link to registered Email Address.  </p>
                                </div>
                            </>
                        }



                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    _login:state.login.login_details
});
const mapDispatchToProps = (dispatch) => ({
    
    EmailVerify: v => dispatch(actions.EmailVerify(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailVerify));
