import { Button, Col, Row, Space, Select, Input } from "antd";
import React, { useEffect, useState } from "react";
import 'react-alice-carousel/lib/alice-carousel.css';
import axios from "../../store/axios";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { useHistory } from "react-router";
import Toast from "../utils/Toast";
import useToken from "../../store/useToken";
import { MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;
const ABCD = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

const CreatePoll = (props) => {
    // Input
    const [question, setQuestion] = useState(null);
    const [options, setOptions] = useState([{ index: "A", option: null }, { index: "B", option: null }]);
    const [days, setDays] = useState(1);
    const [hours, setHours] = useState(0);
    const [mins, setMins] = useState(0);
    const [total_tags, setTotalTags] = useState([]);

    const [masterTags, setMasterTags] = useState([]);

    const [loader, setLoader] = useState(false);
    const [loaderTags, setLoaderTags] = useState(false);

    const history = useHistory();
    const { token } = useToken();


    useEffect(() => {
        setQuestion(null);
        setOptions([{ index: "A", option: null }, { index: "B", option: null }]);
        setDays(1);
        setHours(0);
        setMins(0);
        setTotalTags([]);
    }, []);

    const createPoll = async () => {

        if (question === "" || question === null) {
            Toast("Please enter question", "error")
            return
        }
        if (days === 0 && mins === 0 && hours === 0) {
            Toast("Please enter poll expiry time!", "error")
            return
        }

        const questions = []
        options.map((item) => {
            if (!(item.option === "" || item.option === null)) {
                questions.push(item.option);
            }
        })
        if (questions.length < 2) {
            Toast("Please enter atleast 2 options!", "error")
            return
        }

        const data = {
            "question": question,
            "days": parseInt(days),
            "hours": parseInt(hours),
            "minutes": parseInt(mins),
            "choices": questions,
            'tags': total_tags
        }
        setLoader(true);

        await props.createPool(data).then(() => {
            Toast("Created Successfully!", "success");
            props.onClose();
            setTimeout(() => {
                history.push('/dashboard');
            }, 1000);
        }).catch((e) => {
            console.log(e)
        });
        setLoader(false);
    }

    const onChangeQuestion = (value) => {
        setQuestion(value);
    }

    const addOptionText = (evt, index) => {
        const _pools = options
        _pools[index].option = evt.target.value
        setOptions([..._pools]);
    }

    const remove = (index) => {

        const data = options.filter((k, i) => i !== index);
        data.map((item, i) => {
            item.index = ABCD[i];
        });
        setOptions(data);
        //arrange

    }

    const addPoolOption = () => {
        if (options.length > 4) {
            Toast("Only Four options allowed!", "error")
            return
        }

        setOptions(old => [...old, {
            index: options[options.length - 1].index === "B" ? "C" : "D",
            option: null
        }]);
    }

    const onChangeDays = (value) => {
        if (value < 0 || value > 7) {
            return
        }
        setDays(value);
    }
    const onChangeHours = (value) => {
        if (value < 0 || value > 24) {
            return
        }
        setHours(value);
    }
    const onChangeMins = (value) => {
        if (value < 0 || value > 59) {
            return
        }
        setMins(value);
    }

    const handelSearch = async (evt) => {
        setLoaderTags(true);
        await axios({
            method: 'get',
            url:  'market/tag/search?key=' + evt,

            headers: {
                Authorization: token,
            },
        }).then((res) => {
            setMasterTags(res.data?.data);
        }).catch((error) => {
            console.log(error);
        });
        setLoaderTags(false);
    }


    return (
        <Row>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }}>

                <Row style={{ overflowY: "scroll", height: "73vh" }}>

                    <Col span={24} className="md20">
                        <TextArea value={question} showCount autoSize rows={3} maxLength={100} placeholder="Enter Question here..." bordered={false}
                            onChange={(evt) => onChangeQuestion(evt.target.value)} className="grey-background"></TextArea>
                        <p className=" md20">Options</p>
                        <small className="text-grey"> Type your options here in sequential order</small>
                    </Col>

                    <Col span={24}>
                        <Row>
                            {
                                options.map((item, index) => {
                                    return <Col key={index} span={24} className="md10">
                                        <Row>
                                            <Col span={24} className="">
                                                <Input placeholder={`Option ${item.index}`} suffix={index > 1 && <MinusCircleOutlined className={"cursor"} onClick={() => remove(index)} />}
                                                    maxLength={50} value={item.option} onChange={(evt) => addOptionText(evt, index)}>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </Col>
                                })
                            }
                            {
                                options.length < 4 && <Col span={24} className="md10">
                                    <Row>
                                        <Col span={20} className="grey-text md10" >
                                            <Button onClick={() => addPoolOption()} className="primary-light-btn" type="text">Add more option</Button>

                                        </Col>
                                    </Row>
                                </Col>
                            }

                        </Row>

                    </Col>

                    <Col span={24}>
                        <p className=" md20">Expire in (DD HH MM)</p>
                        <Space className="md10">

                            <Input style={{ width: "60px" }} max={7} maxLength={1} placeholder="DD" type="number" value={days} onChange={(evt) => onChangeDays(evt.target.value)}></Input>
                            <Input style={{ width: "60px" }} max={23} maxLength={2} placeholder="HH" type="number" value={hours} onChange={(evt) => onChangeHours(evt.target.value)} ></Input>
                            <Input style={{ width: "60px" }} max={59} maxLength={2} placeholder="MM" type="number" value={mins} onChange={(evt) => onChangeMins(evt.target.value)}></Input>
                        </Space>
                    </Col>


                    <Col span={24} className='md20 category-select'>
                        <p className="">Tags (optional)</p>
                        <Select
                            mode="tags"
                            bordered={false}
                            allowClear
                            size="large"
                            className="md10 fs14"
                            style={{
                                width: '100%',
                            }}
                            placeholder="Enter tags"
                            value={total_tags}
                            onChange={(evt) => setTotalTags(evt)}
                            onSearch={(evt) => handelSearch(evt)}
                            loading={loaderTags}
                        >
                            {masterTags.map((item, i) => {
                                return <Option key={i} value={item}>{item}</Option>
                            })}
                        </Select>
                    </Col>
                    {/* {
    (this.state.role === 8 || this.state.role === 9) &&
    <Col span={24}>
      <Button className=" p-0 md15" type="text" onClick={() => this.setState({ advancedFeature: !this.state.advancedFeature })}
      > More Options <CaretDown size={18} weight='bold' style={{ marginLeft: "5px" }} rotate={this.state.advancedFeature ? 0 : 90} /></Button>

      {
        this.state.advancedFeature && <ContentAdvancedFeature isChannelOnly={true} className='md20' onUpdate={(val) => this.setState({ advancedPayload: val })} />
      }

    </Col>
  } */}

                    <Col span={24} className="md20 text-right mb-5" >
                        <Space>  <Button type="dashed" shape='default' onClick={() => props.onClose()}>Cancel</Button>
                            <Button type="primary" loading={loader} onClick={() => createPoll()}>Create Poll</Button> </Space>
                    </Col>


                </Row>

            </Col>

        </Row>

    );
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
    createPool: (v) => dispatch(actions.createPool(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePoll));
