import React from "react";
import { Row, Col, Card, Space } from 'antd';
import { withRouter } from "react-router";
import PodcastPlay from "../Podcastv2/PodcastPlay";


const PodcastBody = ({ item }) => {

    return <Row>
        <Col span={24} >
            <PodcastPlay item={item}/>
        </Col>
    </Row>
}
export default withRouter(PodcastBody);