import { Col, Divider, Row, Button, Badge } from "antd";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";



const TagPreferences = (props) => {
    const [stocks, setstocks] = useState([]);
    const [sectors, setsectors] = useState([]);
    const [selectedtopics, setselectedtopics] = useState([]);
    const [loader, setloader] = useState(true);

    useEffect(() => {
        props.UserSelectedPreferences()
    }, [])


    useEffect(() => {

        if (props?._user_selected.length !== 0) {
            setstocks(props?._user_selected["stock"])
            setsectors(props?._user_selected["sector"])
            setselectedtopics(props?._user_selected["topic"])
            setloader(false)
        }
    }, [props?._user_selected])

    return (
        <Row style={{ padding: "40px 0px 40px 30px" }} className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18"> Tags Preferences </p>
                <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 18 }}>
                        <p className="text-grey">Manage Your Feed Thinking</p>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }} className="text-right">
                        <Button href="/preference" className="primary-light-btn" type="text">Update Preference</Button>
                    </Col>
                </Row>
            </Col>
            <Divider />
            <Col span={24} className="md30">


                {
                    stocks.map((item) => {
                        return <Badge className="margin-right selected-badge"> {item} </Badge>
                    })
                }

                {
                    sectors.map((item) => {
                        return <Badge className="margin-right selected-badge"> {item}  </Badge>
                    })
                }

                {
                    selectedtopics.map((item) => {
                        return <Badge className="margin-right selected-badge"> {item}
                        </Badge>
                    })
                }


            </Col>

        </Row>
    );
}


const mapStateToProps = (state) => ({
    _user_selected: state.publics.user_selected,

});
const mapDispatchToProps = (dispatch) => ({
    UserSelectedPreferences: (v) => dispatch(actions.UserSelectedPreferences(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TagPreferences)
);