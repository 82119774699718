import { createAction } from "redux-actions";
import Cookies from "js-cookie"
import axios from "../axios";

const CHANNEL_LIST = createAction("CHANNEL_LIST");
export const ChannelList = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "channel/list?filter="+values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(CHANNEL_LIST(res.data.data));
    })
};



const CHANNEL_DETAIL = createAction("CHANNEL_DETAIL");
export const ChannelDetails = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "channel/details?id=" + values.id,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(CHANNEL_DETAIL(res.data.data));
    })
};



const CREATOR_DETAILS = createAction("CREATOR_DETAILS");
export const CreatorDetails = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "channel/list/creator?id=" + values?.id,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(CREATOR_DETAILS(res.data.data));
    })
};




export const ChannelSetting = (values) => () => {
    return axios({
        method: 'put',
        url: "channel/settings",
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};

export const deleteChannel = (values) => () => {
    return axios({
        method: 'delete',
        url: `channel/delete?id=${values.id}`,
       
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};

const CHANNEL_SETTINGS = createAction("CHANNEL_SETTINGS");
export const getChannelSetting = (params) => dispatch => {
    return axios({
        method: 'get',
        url: `channel/settings?id=${params.id}`,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(CHANNEL_SETTINGS(res.data.data));
    });
};


const CHANNEL_MEMBERS = createAction("CHANNEL_MEMBERS");
export const channelsMembers = (values) => dispatch => {
    return axios({
        method: 'get',
        url: `channel/members?count=20&offset=${values.offset}&id=${values.id}`,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(CHANNEL_MEMBERS(res.data.data));
    })
};

const SEARCHED_USER = createAction("SEARCHED_USER");
export const searchUser = (values) => (dispatch) => {
    return axios({
        method: 'get',
        url: "account/user/find?username="+values.text,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(SEARCHED_USER(res.data.data));
    })
};




export const createAnnouncement = (values) => () => {
    return axios({
        method: 'post',
        url: "channel/announcement",
        data: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};

export const deleteAnnouncement = (values) => () => {
    return axios({
        method: 'delete',
        url: "channel/announcement?id="+values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};

const CHANNEL_CHAT = createAction("CHANNEL_CHAT");
export const GetAllMessage = (values) => dispatch => {
    return axios({
        method: 'get',
        url:  "channel/conversation/message/fetch?channel_id=" + values.id,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(CHANNEL_CHAT(res.data.data));
    })
};





const WEBSOCKET_DATA = createAction("WEBSOCKET_DATA");
export const getWebsocketData = (values) => dispatch => {
  let URL = process.env.REACT_APP_WS_BASE_URL +"wss/channel?token="+ Cookies.get("Logintoken") + "&id=" + values;
var ws = new WebSocket(URL);
  ws.binaryType = "arraybuffer";
  ws.onopen = () => {
    var _req =  {"data": "CH|C|"+values}; 
    ws.send(JSON.stringify(_req));
  };

  // websocket onclose event listener
  ws.onclose = (e) => {
      setTimeout(function() {
        ws.onopen()
      }, 1000);
  };

  ws.addEventListener("message", function (event) {
    let strData = (event.data);
    var result = strData;
   return dispatch(WEBSOCKET_DATA(JSON.parse(result)));
  });

  // websocket onerror event listener
  ws.onerror = (err) => {
    console.error(
      "Socket encountered error: ",
      err.message,
      "Closing socket"
    );

    ws.close();
  };
};







const PUBLIC_CHANNELS = createAction("PUBLIC_CHANNELS");
export const AllPublicChannel = (values) => dispatch => {
    let url = ""
    if(values !== undefined){
        url = "channel/explore?category="+values
    }
    else{
url = "channel/explore"
    }
    return axios({
        method: 'get',
        url: url,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(PUBLIC_CHANNELS(res.data.data));
    })
};



const PUBLIC_EVENTS = createAction("PUBLIC_EVENTS");
export const AllPublicEvents = (values) => dispatch => {
    let url = ""
    if(values !== undefined){
        url = "calendar/schedule/event?category="+values
    }
    else{
url = "calendar/schedule/event"
    }
    return axios({
        method: 'get',
        url: url,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(PUBLIC_EVENTS(res.data.data));
    })
};




const UPCOMING_EVENTS = createAction("UPCOMING_EVENTS");
export const UpComingEvents = () => dispatch => {
    return axios({
        method: 'get',
        url: "calendar/schedule/event/upcoming",
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(UPCOMING_EVENTS(res.data.data));
    })
};

const PAYMENT_LINK = createAction("PAYMENT_LINK");
export const paytoSubcribe = (values) => dispatch => {
    return axios({
        method: 'post',
        url: "lighthouse/payment/init",
        data:values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(PAYMENT_LINK(res.data.data));
    })
};



export const paytoMembershipSubcribe = (values) => dispatch => {
    return axios({
        method: 'post',
        url: `v1.0/membership/tier/${values?.tier_id}/join`,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(PAYMENT_LINK(res.data.data));
    })
};





const CHANNEL_PROFILE = createAction("CHANNEL_PROFILE");
export const ChannelProfile = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "channel/user/profile?user_code=" + values.id,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(CHANNEL_PROFILE(res.data.data));
    })
};




const LIVE_VIDEOS = createAction("LIVE_VIDEOS");
export const LiveVideos = (values) => dispatch => {
    return axios({
        method: 'post',
        url: "channel/streaming/token?channel_id=" + values.channelId + "&media_type=" + values.mediaType,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(LIVE_VIDEOS(res.data.data));
    })
};



export const deleteToken = (values) => () => {
    return axios({
        method: 'delete',
        url: "channel/streaming/token?channel_id=" + values.channel_id + "&media_type=" + values.media_type,
        data: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};

export const createChannel = (values) => dispatch => {
    return axios({
        method: 'post',
        url: "channel/streaming/token",
        data: values,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};



const JOINED_USER = createAction("JOINED_USER");
export const generateToken = (values) => dispatch => {
    return axios({
        method: 'get',
        url: "channel/streaming/token/generate?schedule_id=" + values.schedule_id,
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(JOINED_USER(res.data.data));
    })
};


export const followChannelCreator = (values) => async() => {
    return await axios({
        method: 'post',
        url: "/channel/join",
        data: {
            id: values.id
        },
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};



export const unfollowChannelCreator = (values) => () => {
    return axios({
        method: 'delete',
        url: "/channel/left",
        data: {
            id: values.id
        },
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    })
};


const EVENT_DETAILS = createAction("EVENT_DETAILS");
export const EventDetails = (values) => async(dispatch) => {
    return await axios({
        method: 'get',
        url: "calendar/schedule/detail?id="+values.id,
        data: {
            id: values.id
        },
        headers: {
            Authorization: Cookies.get("Logintoken")
        }
    }).then(res => {
        dispatch(EVENT_DETAILS(res.data.data));
    })
};