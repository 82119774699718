import { Col, Divider, Row } from "antd";
import React, { useEffect } from "react";

const MembershipTierRedirect = () => {

    useEffect(() => {
        window.location.href = `${process.env.REACT_APP_CAMPUS_WEB}create-campus`;
    }, [])


    return (
        <Row style={{ padding: "40px 0px 30px 30px" }} className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18 fw400">Membership Programs</p>
                <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 18 }}>
                        <p className="text-grey ">Manage membership programs/plans </p>
                    </Col>
                </Row>
                <Divider></Divider>
            </Col>

            <Col span={24} className="md10 text-center">
                <p >Redirecting ...</p>
            </Col>
        </Row >
    );
}


export default MembershipTierRedirect;