import { Col, Row, Radio, Button } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import CountdownTimer from "../LiveClass/CountdownTimer";
import moment from "moment";
import { useHistory } from "react-router-dom";
import BlankState from "../BlankState";


const MyhubLiveClasses = () => {

    const [data, setData] = useState([]);
    const [datavalue, setdatavalue] = useState(1);
    const history = useHistory();

    const ChangeFilter = (e) => {
        setdatavalue(e.target.value);
    }

    useEffect(() => {
        Hubgetdata();
    }, [datavalue])

    const Hubgetdata = async () => {
        await axios({
            method: 'get',
            url:  `v1.0/liveclass/booked?status=${datavalue}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setData(data);
        })
    }

    const showJoinButton = (date) => {
        const givenDatetime = moment(date);
        const currentDatetime = moment();
        if (givenDatetime.isSameOrAfter(currentDatetime, "date")) {
            return true;
        } else {
            return false;
        }
    }


    return (
        <Row>
            <Col span={24} className="md10">
                <Row>
                    <Col span={16} className="">
                        <Radio.Group defaultValue={1} onChange={(e) => ChangeFilter(e)} value={datavalue} style={{ display: "inline-flex", marginBottom: "21px" }}
                            buttonStyle="solid" className={"m-mb10"}>
                            <Radio.Button
                                value={1}
                                className="margin-right"
                                style={{ width: "max-content" }} key={1}>

                                Upcoming
                            </Radio.Button>
                            <Radio.Button
                                value={2}
                                className="margin-right"
                                style={{ width: "max-content" }} key={2} >
                                Inactive
                            </Radio.Button>


                        </Radio.Group>
                    </Col>
                    <Col span={8} className="text-right">
                        <img src={require("../../assets/images/Campusimgae/liveclass.svg").default} alt="" height={30} />
                    </Col>
                </Row>
                {

                    data.map((item, i) => {
                        return <Row key={i}>
                            <Col span={24} className="hub-member md15">
                                <p className="cursor fs16 fw600 camp-text" onClick={() => history.push(`/live-class/${item.liveclass?.class_id}`)} > {item.liveclass?.title} </p>
                                <p className="fs12 fw500 text-grey mb-2"> {item.liveclass?.creator} </p>
                                {
                                    showJoinButton(item.liveclass?.datetime) && <CountdownTimer startTime={moment(item.liveclass?.datetime)} />
                                }

                                <Row className="md10">
                                    <Col span={16} >
                                        <p className="fs14 fw400 camp-text"> At {moment(item.liveclass?.datetime).format('h:mm A, ll')} </p>
                                    </Col>
                                    {
                                        showJoinButton(item.liveclass?.datetime) && <Col span={8} className="text-right">
                                            <Button onClick={() => history.push(`/live-class/${item.liveclass?.class_id}`)} type="primary" shape="rounded"> Join now </Button>
                                        </Col>
                                    }

                                </Row>
                            </Col>
                        </Row>
                    })
                }
                {
                    data.length === 0 && <div className="md60">
                        <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={250} />
                    </div>
                }


            </Col>
        </Row>
    );
}
export default MyhubLiveClasses;