import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    profile:[],
    profile_catalogue:[],
    bank_details:[],
    user_bank:[],
    subscription:[],
    payments:[],bookings:[],followers:[],channel_category:[], business_types:[], membership_tiers:[]
}
const ProfileReducer = handleActions({
    PROFILE: (state, action) => {
        return { ...state, profile: action.payload };
    },
    PROFILE_CATALOGUE: (state, action) => {
        return { ...state, profile_catalogue: action.payload };
    },
    BANK_DETAILS: (state, action) => {
        return { ...state, bank_details: action.payload };
    },
    USERS_BANK_DETAILS: (state, action) => {
        return { ...state, user_bank: action.payload };
    },
    ALL_SUBSCRIPTION: (state, action) => {
        return { ...state, subscription: action.payload };
    },
    ALL_PAYMENTS: (state, action) => {
        return { ...state, payments: action.payload };
    },
    ALL_BOOKINGS: (state, action) => {
        return { ...state, bookings: action.payload };
    },
    USERS_FOLLOWERS: (state, action) => {
        return { ...state, followers: action.payload };
    },
    CHANNEL_CATEGORIES: (state, action) => {
        return { ...state, channel_category: action.payload };
    },
    BUSINESS_TYPES: (state, action) => {
        return { ...state, business_types: action.payload };
    },
    GET_MEMBERSHIP: (state, action) => {
        return { ...state, membership_tiers: action.payload };
    }
    
}, INITIAL_STATE);

export default ProfileReducer;