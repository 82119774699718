import { Avatar, Button, Col, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
const { Paragraph } = Typography


const ExploreNotes = ({ data }) => {
    const [spans, setSpans] = useState([]);
    const [items, setItems] = useState([]);
    const history = useHistory();

    function generateArray1000() {
        const SPANS = [[6, 6, 6, 6], [6, 12, 6], [6, 6, 6, 6], [12, 6, 6], [6, 6, 12], [6, 6, 6, 6], [6, 12, 6], [12, 6, 6], [6, 6, 6, 6], [6, 6, 12]]
        const arr = [];

        while (arr.length < 100 * 3) {
            const randomElement = SPANS[Math.floor(Math.random() * SPANS.length)];
            randomElement.map((e) => {
                arr.push(e);
            })
        }
        return arr;
    }

    useEffect(() => {
        const r = generateArray1000();
        setSpans(r);
    }, [])

    useEffect(() => {
        if (data.length > 0 && spans.length > 0) {
            let arr = []
            for (let i = 0; i < data.length; i++) {
                arr.push({ ...data[i], span: spans[i] });
            }
            setItems(arr);
        }

    }, [data, spans]);
    return (
        <Row className="md20">
            {
                items.map((item, k) => {
                    return <Col key={k} xs={12} md={item.span} style={{ background: item?.color === "#fff" ? "#f9f9f9" : item?.color ? item.color : '#f9f9f9' }}>
                        <div className="expl-notes-card">
                            <p className="fs16 fw600 cursor" onClick={() => history.push(`/note/${item.entity_uuid}`)}> {item.title}</p>
                            {
                                item.span === 12 && item.meta_title &&
                                <Paragraph ellipsis={{ rows: 3 }} className="fs12 fw400">{item.meta_title} </Paragraph>
                            }
                            <Space className="cursor" onClick={() => history.push(`/profile/${item.user_code}`)}>
                                <Avatar src={item.dp} size={25} />
                                <p className="fs12 fw400"> {item.fname} {item.lname} </p>
                            </Space>
                        </div>
                    </Col>
                })
            }

        </Row>
    );
}
export default ExploreNotes;