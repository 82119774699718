import { Button, Col, Input, message, Row, Select } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import userToken from "../../../store/useToken"

import Toast from "../../utils/Toast";
import axios from "../../../store/axios";

const Onboard_CreatorInfo = () => {
    const history = useHistory();
    const {token} = userToken();

    const [loader, setLoader] = useState(false);
    const [website, setWebsite] = useState("");
    const [creatorCategory, setCreatorCategory] = useState(null);
    const [expertise, setExpertise] = useState([]);

    const [masterExpertise, setMasterExpertise] = useState([]);
    const [masterCategory, setMasterCategory] = useState([]);

    const [messageApi, contextHolder] = message.useMessage();

    const key = 'onboard.c';

    useEffect(() => {
        getExpertise();
        getProfession();
        console.log(history.location.state)
    }, []);


    const getExpertise =() => {
        
        axios({
            method: 'get',
            url: "account/master/expertise",
            headers: {
                Authorization: token
            }
        }).then((res) => {
            const data = res.data.data
            let _n = []
            for (let i=0;i<data.length;i++){
                _n.push({label: data[i].name, value: data[i].expertise_id})
            }
            setMasterExpertise(_n)

        }).catch((err) => {
            Toast(err.response.data.message)
        })

    };
    const getProfession =() => {
        
        axios({
            method: 'get',
            url: "account/master/profession",
            headers: {
                Authorization: token
            }
        }).then((res) => {
            const data = res.data.data
            let _n = []
            for (let i=0;i<data.length;i++){
                _n.push({label: data[i].name, value: data[i].business_type_id})
            }
            setMasterCategory(_n)

        }).catch((err) => {
            Toast(err.response.data.message,"error")
        })

    };

    const saveData = async () => {

        if (creatorCategory === "" || creatorCategory === null) {
            Toast("Select What best describes you?","error")
            return
        }
        if (expertise.length === 0) {
            Toast("Select Expertise!","error")
            return
        }
        setLoader(true)

        messageApi.open({
            key,
            type: 'loading',
            content: 'Updating account...',
        });

        const payload = {
            business_type_id: creatorCategory,
            expertise: expertise,
            website: website
        }

        await axios({
            method: 'post',
            url: "account/auth/onboard/professional",
            data: payload,
            headers: {
                Authorization: token
            }
        }).then(() => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Account updated!',
                duration: 3,
            });
            const code = Cookies.get('user_code')
            history.push(`/onboard/${code}/review`)
        }).catch((err) => {
            messageApi.open({
                key,
                type: 'error',
                content: err.response.data.message,
                duration: 3,
            });
        })
        setLoader(false)

    }

    return (
        <Row className="m-padding20">
            <Col span={24}>
                <img className="m-opacity03" src={require("../../../assets/images/syllo_bg_grey.svg").default} alt="syllo" srcset=""
                    style={{ position: "fixed", bottom: "0", right: "0" }} />
            </Col>

            <Col lg={{ span: 20, offset: 2 }} xs={{ span: 24, offset: 0 }}>
                <Row >
                    <Col span={24} className='md70 text-center m-md20'>
                        <img src={require("../../../assets/images/Desktop/syllo.svg").default} alt="syllo" srcset="" height={40} />
                    </Col>
                    <Col lg={14} xs={24} className="md70">

                        <h2>Tell us more about you</h2>

                        <img className="m-hide" src={require("../../../assets/images/ignup/creator-info.svg").default} alt="syllo" srcset=""
                            style={{ width: "70%" }} />
                    </Col>
                    <Col lg={{ span: 10, offset: 0 }} xs={{ span: 22, offset: 1 }} className="md70 m-md20">
                        <Row>
                            {/* <Col span={20}>
                                <p className="fs14 fw600">Username </p>
                                <Input value={username} onChange={(e) => onUsernameChange(e.target.value)} placeholder="i.e, myname_official" className="md10" type="text" maxLength={32} />

                                {
                                    !isAvailable && <p className="fs12 fw400 md5 text-red">Username is not available!</p>
                                }
                                <p className="fs12 fw400 md5 " style={{ color: "#728096" }}>
                                    We have created a unique username for you. You may update this. It’s a public identity for your account at Syllo.
                                    Allowed only alpha-numeric characters including underscore (_) and dot (.)</p>
                            </Col> */}
                            <Col span={24} className="md30">
                                <p className="fs14 fw600">What best describes you?</p>
                                <p className="fs12 fw400 md5" style={{ color: "#728096" }}>
                                    Categories help people find accounts like yours. You can change this at any time.</p>
                                <Select
                                    className="md10 select-org-type fs14"
                                    style={{
                                        width: '100%'
                                    }}

                                    value={creatorCategory}
                                    options={masterCategory}
                                    onChange={(newValue) => {
                                        setCreatorCategory(newValue);
                                    }}
                                    placeholder='Search' />
                            </Col>
                            <Col span={24} className="md30">
                                <p className="fs14 fw600">Expertise</p>
                                <p className="fs12 fw400 md5" style={{ color: "#728096" }}>
                                    Categories help people find accounts like yours. You can change this at any time.</p>
                                <Select
                                    className="md10 select-org-type fs14"
                                    style={{
                                        width: '100%'
                                    }}
                                    mode="multiple"
                                    value={expertise}
                                    options={masterExpertise}
                                    onChange={(newValue) => {
                                        setExpertise(newValue);
                                    }}
                                    placeholder='Search' />
                            </Col>
                            <Col span={24} className="md30">
                                <p className="fs14 fw600">Website</p>
                                <Input onChange={(e)=>setWebsite(e.target.value)} value={website} placeholder="i.e, https://syllo.co" className="md10" />

                            </Col>

                            <Col span={24} className='md50 mb-3 text-center'>
                                {contextHolder}
                                <Button size="large" block disabled={loader} onClick={() => saveData()} type="primary" >
                                    Continue
                                </Button>
                            </Col>
                        </Row>
                    </Col >
                </Row >
            </Col>
        </Row >
    );
}
export default Onboard_CreatorInfo;