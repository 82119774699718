import React from "react";
import { Row, Col, Typography } from 'antd';
import { withRouter } from "react-router";


const { Paragraph } = Typography;

const RReportBody = ({ item, details }) => {

    const openModal = (e)=>{
        e.stopPropagation();
        details(item);
    }
    return <Row>
        <Col span={24}>
            <h6 className="news-header cursor m-fs16" onClick={(e) => openModal(e)}>{item?.title}</h6>
            <Paragraph
            onClick={(e) => openModal(e)}
                ellipsis={{ rows: 3, expandable: true, symbol: "Read more", onExpand: function(event){event.stopPropagation()} }}
                className="post-heading-text m-fs15"
                >
                {item?.meta_title}
            </Paragraph>

        </Col>
    </Row>
}
export default withRouter(RReportBody);