import React, { useState } from "react";
import { Col, Row, Input, Button, Divider, Avatar, Radio, message, Upload } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "../../../store/axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import request from "../../../store/request";
import { useHistory } from "react-router";
import Toast from "../../utils/Toast";

const { TextArea } = Input;

const EditName = () => {
    
    const [name, setname] = useState("")
    const [gender, setgender] = useState("")
    const [about, setabout] = useState("")
    const [img, setimg] = useState("")
    const [img_banner, setbannerimg] = useState("")

    const history = useHistory()

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("profile"))
        setname(data?.full_name || data?.name)
        setgender(data?.gender)
        setabout(data?.user_about)
        setimg(data?.img_url)
        setbannerimg(data?.banner_url)
    }, [localStorage.getItem("profile")])

    const UpdateProfile = () => {

        axios({
            method: 'put',
            url:  "account/user/profile",
            data: {
                name: name,
                gender: gender,
                desc: {
                    main: about
                }
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
            history.push("/settings?step=2")
            // window.location.href = 
        }).catch((err) => {
            Toast(err.response.data.message,"error")
        })
    }
    const props = {
        name: 'profile_img',
        accept: ".png,.jpg,.JPEG",
        action:  'account/user/profile/pic',
        headers: {
           Authorization: Cookies.get("Logintoken"),               
          
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                Toast(`${info.file.name} file uploaded successfully`,"success");
                localStorage.removeItem("profile")
                setTimeout(() => {
                    // window.location.href = 
                    window.location.href = ("/profile-update?step=2")
        }, 2000);
            } else if (info.file.status === 'error') {
                Toast(`${info.file.name} file upload failed.`,"error");
            }
        }, 
        customRequest: (options) => request.customRequest(options, "profile_img"),
    };
    const props1 = {
        name: 'profile_banner',
        accept: ".png,.jpg,.JPEG",
        action:  'account/user/profile/pic',
        headers: {
           Authorization: Cookies.get("Logintoken"),               
       
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                Toast(`${info.file.name} file uploaded successfully`,"error");
                localStorage.removeItem("profile")
                setTimeout(() => {
                    window.location.href = ("/profile-update?step=2")
                }, 2000);
            } else if (info.file.status === 'error') {
                Toast(`${info.file.name} file upload failed.`,"error");
            }
        },
        customRequest: (options) => request.customRequest(options, "profile_banner"),
    };
    return (

        <Row style={{ padding: "40px 0px 30px 30px" }} className="m-nopadding">
            <Col span={24}>
                <p className=" m-0 fs18">Edit Profile</p>
                <Row>
                    <Col span={18}>
                        <p className="text-grey">Personal account information</p>
                    </Col>

                </Row>

                <Divider></Divider>

            </Col>
            <Col lg={18} xs={15}>

                <p style={{ color: "#484848", marginTop: "25px" }} className="sub-text-light">Display Picture</p>

            </Col>
            <Col lg={6} xs={9}>
                <div style={{ float: "right" }}>
                    <Avatar src={img} size={70} className="edit-avatar" >{name.substring(0, 1)}</Avatar>
                    <Upload className="edit-avatar-upload"  {...props} showUploadList={false} preview={false}> <Button className="edit-avatar-btn" type="primary" icon={<EditOutlined size={20} />} shape="circle"></Button></Upload>
                </div>
            </Col>


            {/* <Col span={18} >

                <p style={{ color: "#484848", marginTop: "25px" }} className="sub-text-light">Banner Picture</p>

            </Col>
            <Col span={6}>
                <div style={{ float: "right" }}>
                    <Avatar src={img_banner} shape={"circle"} size={70} className="edit-avatar" ></Avatar>
                    <Upload className="edit-avatar-upload"  {...props1} showUploadList={false} preview={false}> 
                    <Button className="edit-avatar-btn" type="ghost" icon={<EditOutlined size={17} />}></Button></Upload>
                </div>
            </Col>
            <Col span={24}>
                <Divider></Divider>
            </Col> */}

            <Col span={24} className="md10">
                <p style={{ color: "#484848" }} className="sub-text-light">Name</p>
                <Input value={name} onChange={(evt) => setname(evt.target.value)} className="md10"></Input>

                <p style={{ color: "#484848" }} className="sub-text-light md30">Gender</p>
                <Radio.Group value={gender} onChange={(evt) => setgender(evt.target.value)} className="md10 radio-gender">
                    <Radio value={1}>Male</Radio>
                    <Radio value={2}>Female</Radio>
                    <Radio value={3}>Other</Radio>
                </Radio.Group>

                <p style={{ color: "#484848" }} className="sub-text-light md20">About</p>
                <TextArea className="md10" maxLength={249} value={about} onChange={(evt) => setabout(evt.target.value)} rows={3}></TextArea>
            </Col>

            <Col span={24} className="md30 text-right">
                <Button type="primary" onClick={() => UpdateProfile()}>Save Changes</Button>

            </Col>
        </Row>

    );
}
export default EditName;
