import { Alert, Button, Card, Checkbox, Col, Input, Row, Space, Steps, Tag, Typography, message } from "antd";
import React, { useState } from "react";
import axios from "../../store/axios";
import Cookies from "js-cookie";

const { Text, Link } = Typography;




const CreatePodcastV2 = () => {
    const [count, setCount] = useState(0);
    const [step, setStep] = useState(0);
    const [stepOTP, setStepOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rss_url, setrss_url] = useState(null);
    const [checked, setchecked] = useState(false);
    const [feed, setFeed] = useState(null);
    const [otp, setOTP] = useState(null);
    const [validation_key, setvalidation_key] = useState(null);
    const [email, setemail] = useState(null);
    const [linkStatus, setlinkStatus] = useState("");
    const [messageApi, contextHolder] = message.useMessage();

    const checkRSSlink = () => {
        if (rss_url === null || rss_url === "") {
            messageApi.error("Please provide a RSS link!");
            return
        }

        if (checked === false) {
            messageApi.error("Please tick checkbox")
            return
        }

        setLoading(true);
        axios({
            method: 'post',
            url:  `market/feed/podcast/rss`,
            data: { rss_url: rss_url },
            headers: {
                Authorization: Cookies.get("Logintoken"),
            }
        }).then((res) => {
            // TvData()
            setLoading(false);
            ; const data = res.data.data;
            setFeed(data.feed);
            setlinkStatus(data.status);
            setCount(data.count);
            if (data.status === "READY_TO_UPLOAD") {
                setStep(1);
            }
        }).catch((err) => {
            setLoading(false);
            messageApi.error(err.response.data.message)
        });
    };

    const sendVerificationCode = () => {
        if (rss_url === null || rss_url === "") {
            messageApi.error("Please provide a RSS link!");
            return
        }

        setLoading(true);
        axios({
            method: 'post',
            url:  `market/feed/podcast/email`,
            data: { rss_url: rss_url },
            headers: {
                Authorization: Cookies.get("Logintoken"),
            }
        }).then((res) => {
            // TvData()
            setLoading(false);
            const data = res.data.data;
            setemail(data.email);
            setvalidation_key(data.validation_key);
            setStepOTP(true);
        }).catch((err) => {
            setLoading(false);
            messageApi.error(err.response.data.message)
        });
    };

    const verificationCode = () => {
        if (otp === null || otp.length < 6) {
            messageApi.error("Please enter correct verification code");
            return
        }
        const payload = {
            rss_url: rss_url,
            otp: otp,
            validation_key: validation_key,
            tags: [],
            channel_uid: null,
            publish_in_feed: true,
            price: 0
        }

        setLoading(true);
        axios({
            method: 'post',
            url:  `market/feed/podcast/email/verify`,
            data: payload,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            }
        }).then((res) => {
            // TvData()
            setLoading(false);
            const data = res.data.data;
            setemail(data.email);
            setvalidation_key(data.validation_key);
            setStepOTP(false);
            setStep(2);
            messageApi.success("Podcast published successfully");

        }).catch((err) => {
            setLoading(false);
            messageApi.error(err.response.data.message)
        });
    };

    const onChange = (e) => {
        setchecked(e.target.checked);
    };

    return (

        <Row>
            {contextHolder}
            <Col span={18} offset={3} className="md40" style={{ marginBottom: "20px" }}>
                <h2 className="text-center fs20 fw600"> Create Podcast </h2>
                <Row>
                    <Col span={24} className="md20">
                        <Card className="Podcast-card">
                            <Row gutter={8}>
                                <Col span={16} style={{ padding: "40px 8px 40px 40px" }}>
                                    <Row>
                                        <Col span={24}>
                                            <Steps
                                                direction="horizontal"
                                                size="small"
                                                current={step}
                                                items={[
                                                    {
                                                        title: 'RSS Link',

                                                    },
                                                    {
                                                        title: 'Verification',

                                                    },
                                                    {
                                                        title: 'Published',

                                                    },
                                                ]}
                                            />
                                        </Col>
                                        {
                                            step === 0 ? <>
                                                <Col span={24} className="md50">
                                                    <p className=" fs16 fw400" style={{ color: '#474747' }}> Link to RSS Feed </p>
                                                    <Input placeholder="https://feed.example.com/rss/podcast-my" onChange={(e) => setrss_url(e.target.value)} className="md10" size="large" />

                                                    {linkStatus === 'ALREADY_UPLOADED' &&
                                                        <><br />
                                                            <Text type="danger">{linkStatus}</Text>
                                                        </>
                                                    }
                                                </Col>
                                                <Col span={24} className="md25 checkbox fs14 fw400">
                                                    <Checkbox checked={checked} onChange={onChange}>  Make sure your podcast content meets our<Button type="link" style={{ textDecoration: 'underline',padding:'2px' }}> guidelines </Button>. </Checkbox>
                                                </Col>
                                                <Col span={24} className="md15">
                                                    <Button type="primary" onClick={() => checkRSSlink()} loading={loading}>
                                                        Continue
                                                    </Button>
                                                </Col>
                                            </> : step === 1 ? <>
                                                <Col span={24} className="md60">
                                                    <Row>
                                                        <Col span={20}>
                                                            <p className=" fs24 fw700" style={{ color: '#474747' }}> {feed?.title} </p>
                                                            <p className=" fs14 fw400 md10" style={{ color: '#4B5669' }}> By  {feed?.author} </p>
                                                            <p className=" fs14 fw400 md15" style={{ color: '#B4BBC7' }}> {count} Episodes </p>
                                                            <Space className="md15 podcast-tag" wrap>
                                                                {
                                                                    feed?.tags?.map((element, i) => {
                                                                        return <Tag key={i}> {element?.term} </Tag>
                                                                    })
                                                                }
                                                            </Space>
                                                            {
                                                                !stepOTP ? <>
                                                                    <div className="md50">
                                                                        <p className=" fs14 fw700" style={{ color: '#4B5669' }}> Verify that you own this podcast </p>
                                                                        <p className=" fs12 fw400 md10" style={{ color: '#4B5669' }}>
                                                                            We’ll send a verification code to the email address associated with your Podcast RSS link.
                                                                        </p>
                                                                    </div>
                                                                    <div className="md25">
                                                                        <Button loading={loading} type="primary" onClick={() => sendVerificationCode()}>
                                                                            Send Verification Code
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                                    : <>

                                                                        <div className="md50">
                                                                            <p className=" fs14 fw700" style={{ color: '#4B5669' }}> Verify that you own this podcast </p>
                                                                            <Alert message={`Verification code has been sent to ${email}`} type="success" className="md10" showIcon style={{ alignItems: 'stretch' }} />
                                                                        </div>
                                                                        <div className="md25">
                                                                            <Space size={24}>
                                                                                <Input maxLength={8} placeholder="Enter Code" type="text" value={otp} onChange={(e) => setOTP(e.target.value)} />
                                                                                <Button loading={loading} type="primary" onClick={() => verificationCode()}>
                                                                                    Verify
                                                                                </Button>
                                                                            </Space>
                                                                        </div>
                                                                        <p className=" fs14 fw400 md12" style={{ color: '#B4BBC7' }}>
                                                                            For unrecognized email addresses, contact support@syllo.co with your podcast name, RSS feed link,
                                                                            and Syllo account email.
                                                                        </p>
                                                                    </>

                                                            }

                                                        </Col>
                                                        <Col span={4} className="">
                                                            <img src={feed?.image?.href}
                                                                alt="" height={150} style={{ borderRadius: '4px' }} />
                                                        </Col>
                                                    </Row>
                                                </Col>


                                            </> :
                                                <>
                                                    <Col span={24} className="md60">
                                                        <Row>
                                                            <Col span={20}>
                                                                <p className=" fs24 fw700" style={{ color: '#474747' }}> {feed?.title} </p>
                                                                <p className=" fs14 fw400 md10" style={{ color: '#4B5669' }}> By  {feed?.author} </p>
                                                                <p className=" fs14 fw400 md15" style={{ color: '#B4BBC7' }}> {count} Episodes </p>
                                                                <Space className="md15 podcast-tag" wrap>
                                                                    {
                                                                        feed?.tags?.map((element, i) => {
                                                                            return <Tag key={i}> {element?.term} </Tag>
                                                                        })
                                                                    }
                                                                </Space>
                                                                {/* <div className="md50 checkbox">
                                                                    <p className=" fs14 fw700 md50" style={{ color: '#4B5669' }}>
                                                                        Publish your podcast
                                                                    </p>
                                                                    <Checkbox onChange={onChange} className="md15">  Make sure your podcast content meets our  <span style={{ textDecoration: 'underline' }}> guidelines </span>. </Checkbox>

                                                                </div>
                                                                <div className="md25">
                                                                    <Button type="primary" onClick={() => setStep(1)}>
                                                                        Publish
                                                                    </Button>
                                                                </div> */}
                                                                <div className="md20">
                                                                <Alert message={`Podcast published successfully!`} type="success" className="md10" showIcon style={{ alignItems: 'stretch' }} />

                                                                </div>
                                                            </Col>
                                                            <Col span={4} className="">
                                                                <img  src={feed?.image?.href}
                                                                    alt="" height={150} style={{ borderRadius: '4px' }} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </>
                                        }


                                    </Row>
                                </Col>
                                <Col span={8} className="podcast-bg-img text-right">

                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default CreatePodcastV2;