import React from "react";
import { Col, Row, Space, Divider, Input, Button, Checkbox, message, List, Avatar, Upload, Modal, Select, Radio } from "antd";
import '../../Campus/campus.scss';
import { UploadSimple, UserPlus, X, XCircle, Info } from "@phosphor-icons/react";
import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import { useState } from "react";
import axios from "../../../store/axios";
import { LoadingOutlined } from "@ant-design/icons";
import request from "../../../store/request";
import { useHistory, useLocation } from "react-router-dom";
import useToken from "../../../store/useToken";

import Toast from "../../utils/Toast";

const { Option } = Select;

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUploadT = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        Toast('You can only upload JPG/PNG file!', "error");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        Toast('Image must smaller than 2MB!', "error");
    }
    return isJpgOrPng && isLt2M;
};
const beforeUploadB = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        Toast('You can only upload JPG/PNG file!', "error");
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
        Toast('Image must smaller than 4MB!', "error");
    }
    return isJpgOrPng && isLt2M;
};

const CreateCampusKids = () => {
    const history = useHistory();
    const location = useLocation();

    const [loader, setLoader] = useState(false);
    const [name, setName] = useState("My First Campus for Kids");
    const [description, setDescription] = useState(null);
    const [module_desc, setModuleDescription] = useState(null);
    const [categories, setCategories] = useState([]);
    const [programs, setPrograms] = useState([1]);
    const [video_url, setVideoUrl] = useState(null);
    const [topics, setTopics] = useState([]);
    const [check, setCheck] = useState(false);
    const [children, setChildren] = useState([]);
    const [campusdata, setCampusData] = useState(null);

    // testimonials
    const [testimonialModal, setTestimonialModal] = useState(false);
    const [testimonials, setTestimonials] = useState([]);
    const [loadingT, setLoadingT] = useState(false);
    const [imageUrlT, setImageUrlT] = useState("");
    const [avatarT, setAvatarT] = useState("");
    const [nameT, setNameT] = useState("");
    const [descT, setDescT] = useState("");

    // Modules/Benefits
    const [modules, setModules] = useState([]);
    const [nameM, setNameM] = useState("");
    const [catIdM, setCatIdM] = useState(null);

    // banner 1
    const [banner1_url, setBanner1Url] = useState("");
    const [loadingB1, setLoadingB1] = useState(false);
    const [blobB1, setBlobB1] = useState("");

    // Add plan modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editbenefits, setEditBenefits] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    const key = 'campusCreate';
    const { token } = useToken();


    useEffect(() => {
        axios({
            method: 'get',
            url:  "v1.0/campus",
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setCampusData(data);
            if (data?.name === null || data?.name === undefined) {
                return
            }
            setName(data?.name)
            setDescription(data?.description);
            setBanner1Url(data?.banner1_url);
            setBlobB1(data?.banner1_url);
            setVideoUrl(data?.video_url);
            setTestimonials(data?.testimonials);
            setModules(data?.benefits);
            setTopics(data?.topics);
            setModuleDescription(data?.module_description)
            let arr = []
            for (let i = 1; i <= data?.programs.length; i++) {
                arr.push(i);
            }
            if (arr.length > 0) {
                setPrograms(arr);
            }
        })
    }, []);

    useEffect(() => {
        axios({
            method: 'get',
            url:  "v1.0/membership/benefit/category",
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data;
            let arr = []
            for (let i = 0; i < data.length; i++) {
                arr.push({
                    value: data[i].category_id,
                    label: data[i].title,
                })
            }
            setCategories(arr);
        })
    }, []);

    const createCampus = async () => {
        if (token === false) {
            history.push(`/login?redirect=${location.pathname}`)
        }
        if (name === "" || name === " " || name === null) {
            Toast("Please enter valid name of campus!", "error")
            return
        }
        if (description === "" || description === " " || description === null) {
            Toast("Please write a short description about this campus!", "error")
            return
        }
        if (banner1_url === "") {
            Toast("Please upload main banner image!")
            return
        }
        if (modules.length < 3) {
            Toast("Please add at-least three modules; you are offering!", "error")
            return
        }
        if (!check) {
            Toast("Check our terms & condition", "error")
            return
        }
        let data = {
            "name": name,
            "description": description,
            "topics": topics,
            "banner1_url": banner1_url,
            "video_url": video_url,
            "module_desc": module_desc,
            "programs": programs,
            "testimonials": testimonials,
            "modules": modules
        }

        messageApi.open({
            key,
            type: 'loading',
            content: 'Updating campus...',
        });
        setLoader(true);
        await axios({
            method: 'post',
            url:  "v1.0/campus",
            data: data,
            headers: {
                Authorization: token
            }
        }).then(() => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Campus updated!',
                duration: 3,
            });
            history.push('/campus/me');
            return
        }).catch(() => {
            setLoader(false);
        })
        setLoader(false);
    }


    const handelSearch = (evt) => {
        axios({
            method: 'get',
            url:  'market/tag/search?key=' + evt,

            headers: {
                Authorization: token,
            },
        }).then((res) => {
            setChildren(res?.data?.data)
        }).catch((error) => {
            Toast(error.response.data.message, "error")
        });
    }

    const handleChange = (value) => {
        setTopics(value)
    };
    const props1 = {
        action:  "account/file/upload",
        headers: {
            Authorization: token,

        },
        customRequest: (options) => request.customRequest(options, "camp"),
    };

    const propsImg1 = {
        name: 'file',
        multiple: false,
        accept: ".png,.jpeg,.jpg",
        ...props1,
        onChange(info) {
            if (info.file.status === 'uploading') {
                // console.log(info.file, info.fileList);
                setLoadingB1(true);
                return;
            }
            if (info.file.status === 'done') {
                setBanner1Url(info.file.response.data.url);
                Toast(`${info.file.name} file uploaded.`, "success");
                getBase64(info.file.originFileObj, (url) => {
                    setLoadingB1(false);
                    setBlobB1(url);
                });
            }
            else if (info.file.status === 'error') {
                Toast(`${info.file.name} file upload failed.`, "error");
            }
        }
    };


    const options = [
        {
            label: 'Kids Premium',
            value: 1,
            disabled: true,
            checked: true
        },
        {
            label: 'Kids Star',
            value: 2,
        }
    ];
    const masterPrograms = ['Kids Premium', 'Kids Star'];

    const onChangeProgram = (checkedValues) => {
        setPrograms(checkedValues);
    };
    const testimonialsAdd = () => {
        if (nameT === "" || descT === "") {
            return
        }
        let s = testimonials;
        s.push({ avatar: avatarT, name: nameT, description: descT });
        setTestimonials(s);
        setNameT(""); setDescT(""); setAvatarT(""); setImageUrlT(null);
        setTestimonialModal(false);
    };
    const modulesAdd = () => {
        if (catIdM === null || nameM === "") {
            Toast("Provide details", "error")
            return
        }
        let s = modules;
        s.push({ category_id: catIdM, name: nameM });
        setModules(s);
        setCatIdM(null); setNameM("");
    };
    const testimonialsDelete = (index) => {
        setTestimonials(testimonials.filter((v, i) => i !== index));
    };
    const modulesDelete = (index) => {
        setModules(modules.filter((v, i) => i !== index));
    };



    const uploadButton = (
        <div>
            {loadingT ? <LoadingOutlined /> : <UploadSimple />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const handleChangeImgT = (info) => {
        if (info.file.status === 'uploading') {
            setLoadingT(true);
            return;
        }
        if (info.file.status === 'done') {
            setAvatarT(info.file.response.data.url)
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoadingT(false);
                setImageUrlT(url);
            });
        }
    };

    const onCHangeBenefits = (v, option) => {
        setCatIdM(v);
        setNameM(option.label)
    }


    const showModal = () => {
        setIsModalOpen(true);

    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    
    const editModal = () => {
        setEditBenefits(true);
    }

    const editOk = () => {
        setEditBenefits(false);
    };

    const editCancel = () => {
        setEditBenefits(false);
    };

    return (

        <Row>
            <Col md={8} xs={24} className="padding20">
                <Row>
                    <Col span={24} className=''>
                        <p className="fs20 fw600">Create Campus for kids</p>
                    </Col>
                    <Col span={24}>
                        <Divider></Divider>
                    </Col>
                    <Col span={24} className='md1hjg0 campus-input'>
                        <Input max={50} maxLength={50} value={name}
                            onChange={(evt) => setName(evt.target.value)} placeholder="Name of Campus" type="text" />
                    </Col>
                    <Col span={24} className='md20 '>
                        <TextArea max={500} showCount maxLength={500} value={description}
                            onChange={(evt) => setDescription(evt.target.value)} placeholder="Description..."> </TextArea>
                    </Col>
                    <Col span={24} className='md20 '>

                        <Upload
                            name="avatar-testi"
                            listType="picture-card"
                            className="avatar-uploader upload-block"
                            showUploadList={false}
                            beforeUpload={beforeUploadB}
                            {...propsImg1}>
                            {blobB1 ? (
                                <img
                                    src={blobB1}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                <div>
                                    {loadingB1 ? <LoadingOutlined /> : <UploadSimple />}
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload Banner 1
                                    </div>
                                </div>
                            )}
                            {/* <p className="ant-upload-drag-icon">
                                <UploadSimple size={24} />
                            </p>
                            <p className="ant-upload-hint">
                                Upload banner 1
                            </p> */}
                        </Upload>
                    </Col>
                    <Col span={24} className='md20 checkbox'>
                        <Checkbox.Group options={options} value={programs} defaultValue={[1]} onChange={onChangeProgram} />

                    </Col>
                    <Col span={24} className='md20'>
                        <p className="fs16 fw600">Testimonials</p>

                        <div className="campus-blue-card md20">
                            <List
                                dataSource={testimonials}
                                renderItem={(item, i) => (
                                    <List.Item key={i}
                                        actions={[<Button onClick={() => testimonialsDelete(i)} type="text" icon={<X size={20} />}></Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.avatar} />}
                                            title={<span className="fw600">{item.name}</span>}
                                            description="Instagram"
                                        />
                                    </List.Item>
                                )}
                            />
                            <Button block icon={<UserPlus size={18} style={{ marginRight: "5px" }} />}
                                onClick={() => setTestimonialModal(true)}> Add more</Button>

                        </div>

                    </Col>

                    <Col span={24} className='md20'>
                        <div className="text-center" style={{ padding: "30px", border: "1px solid #E6E6E6", borderRadius: "10px" }}>
                            <p className="fs18 fw500" >  Syllo Kids <span style={{ color: '#2F49CF' }}> Premium</span></p>
                            <Button onClick={showModal} className="md10" type="primary" ghost>Add</Button>
                        </div>
                    </Col>
                    {
                        programs && programs.length === 2 && <Col span={24} className='md20'>
                            <div className="text-center" style={{ padding: "30px", border: "1px solid #E6E6E6", borderRadius: "10px" }}>
                                <p className="fs18 fw500" >  Syllo Kids <span style={{ color: '#EA9600' }}> Star</span></p>
                                <Button onClick={showModal} className="md10" type="primary" ghost>Add</Button>
                            </div>
                        </Col>
                    }


                    <Col span={24} className='md20 '>

                        <Select
                            mode="tags"
                            bordered={true}
                            allowClear
                            style={{
                                width: '100%',
                                marginTop: "15px", borderRadius: "4px"
                            }}
                            placeholder="Enter tags"
                            value={topics}
                            onChange={(evt) => handleChange(evt)}
                            onSearch={(evt) => handelSearch(evt)}
                        >
                            {children.map((item) => {
                                return <Option value={item}>{item}</Option>
                            })}
                        </Select>
                    </Col>


                    <Col span={24} className='md60 text-center '>

                        <p className="fs14 fw400 checkbox"> <Checkbox checked={check} onChange={(evt) => setCheck(evt.target.checked)} className="margin-right">You’re agree with <span className="blue-text">terms & condition  </span></Checkbox>
                        </p>

                    </Col>
                    <Col span={24} className='md30 text-center '>
                        {contextHolder}
                        <Button loading={loader} onClick={() => createCampus()} type="primary">Create Campus</Button>
                    </Col>
                </Row>
            </Col>
            <Col md={16} xs={0}>
                <Row>
                    <Col span={24} className="camp-bg-img" style={{
                        height: "450px",
                        background: `linear-gradient(92.89deg, rgba(0, 0, 0, 0.88) 2.06%, rgba(0, 0, 0, 0) 98.29%), url(${blobB1})`
                    }}>
                        <Row>
                            <Col span={20} offset={2} className="md100">
                                <Space>
                                    <Avatar shape="square" size={20} src={campusdata?.icon} alt="" />
                                    <p className="fs14 fw400 text-white">{campusdata?.creator_name}’s</p>
                                </Space>
                                <h1 className="fw600 fs28 md5 text-white" style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{name}</h1>
                                <p className="fs12 fw400 text-white">{description}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} className="camp-gry-crd">
                        <Row className="mb-5" >
                            <Col span={20} offset={2}>
                                <Row gutter={42} justify={"center"} style={{ marginTop: '-160px' }}>
                                    {
                                        programs && programs.map((i, j) => {
                                            return <Col key={j} span={8}>
                                                <div className="camp-card padding20" style={{ minHeight: "200px" }}>
                                                    <h5 className="text-white">{masterPrograms[i - 1]}</h5>

                                                    <div className="text-center md65">
                                                        <Button type="primary" className=""> Know More </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        })
                                    }
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={24} className="md70">
                                <h5 className="text-center" style={{ color: "#013238" }}> Testimonials</h5>
                            </Col>
                            <Col span={18} offset={3} className="text-center md55">
                                <Row gutter={28}>
                                    {
                                        testimonials && testimonials.map((i, j) => {
                                            return <Col span={8} key={j}>
                                                <Avatar size={100} src={i.avatar} />
                                                <p className="fs12 fw400 md10">{i.description} </p>
                                            </Col>
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>

                    </Col>

                </Row>
            </Col>
            <Modal title="Add testimonial" open={testimonialModal}
                onCancel={() => setTestimonialModal(false)} okText="Add" onOk={testimonialsAdd}>
                <Row className="md30">
                    <Col span={20} offset={2}>
                        <Space size={20}>
                            <Upload
                                name="avatar-testi"
                                listType="picture-circle"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUploadT}
                                onChange={handleChangeImgT}
                                {...props1}
                            >
                                {imageUrlT ? (
                                    <img
                                        src={imageUrlT}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>

                            <Input max={30} maxLength={30} value={nameT}
                                onChange={(evt) => setNameT(evt.target.value)} placeholder="Username" />
                        </Space>

                        <TextArea className="md20" max={150} showCount maxLength={150} value={descT}
                            onChange={(evt) => setDescT(evt.target.value)} placeholder="Description..."> </TextArea>

                        <div className="mb-5"></div>
                    </Col>

                </Row>
            </Modal>

            <Modal title="Syllo Kids Premium" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} className="premium-modal">
                <Row style={{maxHeight:"70vh", overflowY:"scroll"}}>
                    <Col span={24}>
                        <Input size="small" placeholder="Tagline" className=" explo-input" />
                    </Col>
                    <Col span={24} className="md20">
                        <Space>
                            <p className="fs14 fw600"> What you’ll get? </p>
                            <Info size={20} color="#bdc2cc" />
                        </Space>
                    </Col>
                    <Col span={24} className="modal-get-card md20">
                        <p className="fs14 fw400" style={{ color: '#4D4D4D;' }}> Let your listener know what they can expect from this plan </p>
                        <div className="modal-div md10">
                            <Row>
                                <Col span={20}>
                                    <p style={{ marginTop: '6px' }}> Weekly Newsletter </p>
                                </Col>
                                <Col span={4} className="text-right">
                                    <Button type="link" className="nopadding"> Edit </Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-div md10">
                            <Row>
                                <Col span={20}>
                                    <p style={{ marginTop: '6px' }}> Group Classes - upto 10</p>
                                </Col>
                                <Col span={4} className="text-right">
                                    <Button type="link" className="nopadding"> Edit </Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-div md10">
                            <Row>
                                <Col span={20}>
                                    <p style={{ marginTop: '6px' }}> Private Channels</p>
                                </Col>
                                <Col span={4} className="text-right">
                                    <Button type="link" className="nopadding"> Edit </Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="md20">
                            <Button type="default" ghost style={{ border: '1px solid #3B6BE3', color: '#3B6BE3' }}
                                icon={<UserPlus size={18} color="#3B6BE3" className="me-2" />}>
                                Add
                            </Button>
                        </div>
                    </Col>
                    <Col span={24} className="md20">
                        <p className="fs14 fw600"> Topics</p>

                    </Col>
                    <Col span={24} className="modal-get-card md20">
                        <Row>
                            <Col span={20}>
                                <p className="camp-text fs14 fw500 md5"> Budgeting </p>
                            </Col>
                            <Col span={4} className="text-right">
                                <Button type="text" icon={<XCircle size={25} color="#4b5669" />}>
                                </Button>
                            </Col>
                        </Row>
                        <Row className="md10">
                            <Col span={20}>
                                <p className="camp-text fs14 fw500 md5"> One-to-One Mentorship </p>
                            </Col>
                            <Col span={4} className="text-right">
                                <Button type="text" icon={<XCircle size={25} color="#4b5669" />}>
                                </Button>
                            </Col>
                        </Row>
                        <Row className="md10">
                            <Col span={20}>
                                <p className="camp-text fs14 fw500 md5"> Channels Access</p>
                            </Col>
                            <Col span={4} className="text-right">
                                <Button type="text" icon={<XCircle size={25} color="#4b5669" />}>
                                </Button>
                            </Col>
                        </Row>
                        <Row className="md10">
                            <Col span={20}>
                                <p className="camp-text fs14 fw500 md5"> Master Course Access </p>
                            </Col>
                            <Col span={4} className="text-right">
                                <Button type="text" icon={<XCircle size={25} color="#4b5669" />}>
                                </Button>
                            </Col>
                        </Row>
                        <Row className="md10">
                            <Col span={20} >
                                <p className="camp-text fs14 fw500 md5"> Notes Access </p>
                            </Col>
                            <Col span={4} className="text-right">
                                <Button type="text" icon={<XCircle size={25} color="#4b5669" />}>
                                </Button>
                            </Col>
                        </Row>
                        <div className="md20">
                            <Button type="default" ghost style={{ border: '1px solid #3B6BE3', color: '#3B6BE3' }}
                                icon={<UserPlus size={18} color="#3B6BE3" className="me-2" />}>
                                Add
                            </Button>
                        </div>
                    </Col>
                    <Col span={24} className="md25">
                        <p className="fs14 fw600"> Price </p>

                    </Col>
                    <Col span={24} className="md20">
                        <Input addonBefore="₹" defaultValue="499" />
                    </Col>
                    <Col span={24} className="md25">
                        <p className="fs14 fw600"> Select Tenure </p>
                        <p className="fs12 fw400 update md10"> Write something here</p>
                        <Radio.Group
                            defaultValue={0} style={{ display: "inline-flex", marginBottom: "21px" }}
                            buttonStyle="solid" className={"m-mb10 md15"}>
                            <Radio.Button
                                value={0}
                                className="margin-right"
                                style={{ width: "max-content" }} key={1}>

                                1 Month
                            </Radio.Button>
                            <Radio.Button
                                value={2}
                                className="margin-right"
                                style={{ width: "max-content" }} key={3}>
                                6 Month  </Radio.Button>
                            <Radio.Button
                                value={3}
                                className="margin-right"
                                style={{ width: "max-content" }} key={4}>
                                9 Month</Radio.Button>
                            <Radio.Button
                                value={3}
                                className="margin-right"
                                style={{ width: "max-content" }} key={4}>
                                1 Year</Radio.Button>


                        </Radio.Group>
                    </Col>
                    <Col span={24} className="text-center md30">
                        <Button type="primary"> Add Plan </Button>
                    </Col>
                </Row>
            </Modal>
            <Modal title="Edit benefits" open={editbenefits} onOk={editOk} onCancel={editCancel} width={550} footer={false} >
                    <Row className="nomargin padding20">
                        <Col span={22} offset={1} >
                            <Row>
                                <Col span={24}>
                                    <p className="fs16 fw400"> Topic</p>
                                    <Input size="small" placeholder="Enter topic" className=" explo-input md10" />

                                </Col>
                                <Col span={24} className="md20">
                                    <p className="fs16 fw400"> Collaborator </p>
                                    <div className="membership-select md10">
                                        <Select style={{ width: "100%", }} className="" placeholder="Search">
                                            <Option value="">Select benefit category</Option>
                                            <Option > LiveClass </Option>
                                        </Select>
                                    </div>

                                </Col>
                                <Col span={24} className="md20">
                                   <p  className="fs16 fw400"> Description </p>
                                   <TextArea rows={4} placeholder="Enter description" maxLength={6} className="md10" />
                                </Col>
                               
                                <Col span={24} className=' md20 mb-3 text-right'>
                                    <Space>
                                        <Button type={"dashed"}>Close</Button>
                                        <Button type="primary" > Save</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal>


        </Row>

    );
}
export default CreateCampusKids;