import React, { useState } from "react";
import { Col, Row, Input, Space, Button, Modal, Select, Radio, Tooltip} from "antd";
import { CloseOutlined } from '@ant-design/icons';
import './membership.scss';
import { useEffect } from "react";
import axios from "../../store/axios";
import Cookies from "js-cookie";

import { GraduationCap, Info } from "@phosphor-icons/react";
import Editor from "../Editor/LexicalEditor";

import Toast from "../utils/Toast";
import { useHistory } from "react-router";
import CoinInput from "../Input/CoinInput";
var _ = require("lodash");



const { Option } = Select;

const CreateMemberShip = () => {

    const [title, settitle] = useState("")
    const [price, setprice] = useState("0")
    const [description, setdescription] = useState("")
    const [benefits, setbenefits] = useState([])
    const [banner_url, setbanner_url] = useState("https://picsum.photos/200/300")
    const [user_limit, setuser_limit] = useState(0)
    const [categories, setcategories] = useState([])
    const [selectedcategory, setselectedcategory] = useState("")
    const [categoryedit, setcategoryedit] = useState("")
    const [partialvalue, setpartialvalue] = useState(1)
    const [tenure, setTenure] = useState(6)
    const [loading, setLoading] = useState(false);

    const history = useHistory();



    useEffect(() => {
        axios({
            method: 'get',
            url:  "v1.0/membership/benefit/category",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setcategories(data)
        })
    }, [])

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
        setselectedcategory("")
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const removeCategory = (evt) => {
        let _data = [...benefits]
        _.remove(_data, { "category_id": evt })
        setbenefits(_data)
    }

    const editCategory = (evt) => {
        setselectedcategory(evt)
        setcategoryedit(benefits.find((a) => a.category_id === evt))
        showModal()
    }

    const setBenefits = () => {
        let _data = [...benefits]
        let exits = _data.find((a) => a.category_id === categoryedit?.category_id)

        if (exits) {
            _.remove(_data, { "category_id": exits?.category_id })
        }
        _data.push(categoryedit)
        setbenefits(_data)
        handleCancel()
        setselectedcategory("")
        setcategoryedit("")
    }

    // const props1 = {
    //     name: 'file',
    //     accept: ".png,.jpg,.JPEG",
    //     action:  "account/file/upload",
    //     data: { type: "tiers" },
    //     headers: {
    //         Authorization: Cookies.get("Logintoken"),
    //     },
    //     onChange(info) {
    //         if (info.file.status !== 'uploading') {
    //             // console.log(info.file, info.fileList);
    //         }
    //         if (info.file.status === 'done') {
    //             toast.success(`${info.file.name} file uploaded successfully`);
    //             setbanner_url(info.file.response.data.url)
    //         } else if (info.file.status === 'error') {
    //             Toast(`${info.file.name} file upload failed.`);
    //         }
    //     },
    //     customRequest: (options) => request.customRequest(options, "tiers"),

    // };
    // const handleEditorChange = (e) => {
    //     e.target.getContent();
    //     setdescription(e.target.getContent())
    // }

    const createMemberShip = async() => {

        if (title === "") {
            Toast("Enter Plan Name","error")
            return
        }

        if (description === "") {
            Toast("Enter Some Description","error")
            return
        }
        if (description.length > 500) {
            Toast("Description has more than 500 characters!","error")
            return
        }

        if (price === "") {
            Toast("Enter Plan Price","error")
            return
        }

        // if (banner_url === "") {
        //     Toast("Upload Banner Image")
        //     return
        // }

        if (benefits.length === 0) {
            Toast("Enter Atleast One Benefit","error")
            return
        }


        let data = {
            "title": title,
            "description": description,
            "price": parseInt(price),
            "banner_url": banner_url,
            "user_limit": parseInt(user_limit),
            "benefits": benefits,
            'tenure': tenure
        }
        setLoading(true);

        await axios({
            method: 'post',
            url:  "v1.0/membership/tier",
            data: data,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
            history.push('/settings');
        }).catch((err) => {
            Toast(err.response.data.message)
        });
        setLoading(false);
    }
    
    return (
        <Row className="md40">
            <Col lg={{ span: 10, offset: 7 }} xs={{ span: 22, offset: 1 }}>
                <Row>
                    <Col span={24} className=''>
                        <Space>
                            <GraduationCap size={32} color="#010101" weight="regular" />
                            <p className="fs16 fw600">  Campus Membership Tier </p>
                        </Space>
                    </Col>
                    <Col span={24} className="md30">
                        <p className="fs16 fw500"> Name </p>
                        <Input value={title} onChange={(evt) => settitle(evt.target.value)} placeholder="Tier name" className="md20" />
                    </Col>
                    <Col span={24} className="md30">
                        <p className="fs16 fw500"> Tier description </p>

                        {/* <EditNews
                            style={{ height: "100px" }}
                            value={description}
                            handleEditorChange={(html, text) =>
                                handleEditorChange(html, text)
                            }
                        /> */}

                        <Editor className="md10" tools={{history:false, code:false, link: false, heading:false}}
                             borderBottom={true} onChange={(html)=>setdescription(html)}/>

                    </Col>

                    <Col span={24} className="md30">
                        <p className="fs16 fw500"> Select Tenure </p>
                        <p className="fs12 fw300 text-grey" > You can drag files to a specific area, to upload. Alternatively, you can also upload by selecting. </p>
                        <Radio.Group value={tenure} className="md20 membership-radio" onChange={(evt) => setTenure(evt.target.value)}>
                            <Radio value={0}>Free</Radio>
                            <Radio value={1}>1 Month</Radio>
                            <Radio value={6}>6 Month</Radio>
                            <Radio value={12} className="m-md10">1 Year</Radio>
                        </Radio.Group>
                    </Col>
                    {
                        tenure !== 0 && <Col span={24} className="md30">
                            <p className="fs16 fw500"> Required Coins </p>
                            <CoinInput className="md20" value={price} onChange={(value) => setprice(value)} />
                            {/* <Input type={"number"} value={price} onChange={(evt) => onChangeCoins(evt.target.value)} 
                                prefix={<Avatar style={{ border: "none" }} size={24} src={require('../../assets/images/newdashboard/sylloCoin.svg').default} />} 
                                    placeholder="i.e., 499" className="md20 prefix-icons" suffix={<span className="text-grey">Coins</span>} /> */}
                        </Col>
                    }


                    <Col span={24} className="md15">
                        <Space>
                            <p className="fs16 fw500 "> Tier benefits </p>
                            <Tooltip title=" Lorem Ipsum, giving information on its origins,">
                                <Info size={20} color="#BDC2CC" weight="light" />
                            </Tooltip>



                        </Space>
                        <div className="tier-benefits-card md20 memb-input">
                            <p className="fs12 fw400 text-grey">Let your listener know what they can expect from this membership tier</p>

                            {
                                benefits?.map((item) => {
                                    return <Input suffix={
                                        <Space>
                                            <Button type={'text'} className='blue-text cursor' onClick={() => editCategory(item?.category_id)}>Edit</Button>
                                            <Button type={'text'} onClick={() => removeCategory(item?.category_id)} icon={<CloseOutlined />}></Button>
                                        </Space>
                                    } value={item?.title} disabled className="md20 prefix-icons " />
                                })
                            }

                            <Button type="text" className="primary-light-btn md25" onClick={showModal}> add benefits </Button>
                            <Col span={24} className="md20 info-card">
                                <Space>
                                    <Info size={18} color="#FEA300" weight="fill" />
                                    <p className="fs12 fw400" style={{ color: '#FEA300' }}>You have added some modules in Campus but hav’nt included in  your tier benefits.</p>
                                </Space>
                            </Col>
                        </div>
                    </Col>
                    {/* <Col span={24} className="md30">
                                            <p className="fs16 fw400"> Cover Image (required) </p>
                                            <Dragger
                                                className="avatar-uploader md20"
                                                listType="picture-card"
                                                showUploadList={false}
                                                style={{ width: "100%" }}
                                                {...props1}
                                            // onChange={handleChange}
                                            >
                                                {banner_url ? (
                                                    <img
                                                        src={banner_url}
                                                        alt="avatar"
                                                        style={{
                                                            width: '100%',
                                                            height: "100px"
                                                        }}
                                                    />
                                                ) : (
                                                    <Button>Upload</Button>
                                                )}
                                            </Dragger>
                                            <p className="fs12 fw400 text-grey md15 lh21">Recommended size: 460 by 200 pixels</p>
                                        </Col>
                                        <Col span={24} className="md100">
                                            <p className="fs16 fw400"> Advanced <span className="fs14">(optional)</span>
                                            </p>
                                            <div className="tier-benefits-card md20 membership-checkbox ">
                                                <p className="fs14 fw500" style={{ color: '#4D4D4D' }}>Tier Settings</p>
                                                <span className="checkbox fs14 fw400 checkbox-text"><Checkbox checked={showlimit} onChange={(evt) => setshowlimit(evt.target.checked)}> Limit the number of people who can join this tier </Checkbox> </span>


                                                {
                                                    showlimit && <>  <p className="fs16 fw400 md10"> Number of people </p><Input value={user_limit} onChange={(evt) => setuser_limit(evt.target.value)} type={"number"} placeholder="Enter number of people" className="md10" /></>
                                                }

                                            </div>

                                        </Col> */}

                    <Col span={24} className='text-center md40 mb-4'>
                        <Space size={20}>
                            <Button type={"dashed"} onClick={() =>history.goBack()} >Cancel</Button>
                            <Button type="primary" onClick={() => createMemberShip()} style={{ padding: '4px 35px' }} loading={loading}> Save </Button>
                        </Space>
                    </Col>
                </Row>
            </Col>


            <Modal title="Edit benefits" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={550} footer={false} >
                <Row className="nomargin padding20">
                    <Col span={22} offset={1} >
                        <Row>

                            <Col span={24} className="">
                                <p className="fs16 fw400"> Category </p>
                                <div className="md10 membership-select ">
                                    <Select
                                        value={selectedcategory}
                                        onChange={(evt) => {
                                            setselectedcategory(evt);
                                            setcategoryedit(categories.find((a) => a.category_id === evt))
                                        }}
                                        style={{ width: "100%", }}  >
                                        <Option value="">Select benefit category</Option>
                                        {
                                            categories?.map((item,k) => {
                                                return <Option key={k} disabled={benefits.find((a) => a.category_id === item?.category_id) ? true : false} value={item?.category_id}>{item?.entity_type}</Option>
                                            })
                                        }
                                    </Select>
                                </div>


                                <div className="col-md-12 md10 nopadding">

                                    {
                                        categoryedit?.is_partial && <Radio.Group className="membership-radio" onChange={(evt) => setpartialvalue(evt.target.value)} value={partialvalue}>
                                            <Space direction="horizontal">
                                                <Radio value={1}>All</Radio>
                                                <Radio value={2}>
                                                    Partial
                                                </Radio>
                                                {partialvalue === 2 ? <Input onChange={(evt) => {
                                                    let current = JSON.parse(JSON.stringify(categoryedit));
                                                    current.benefit_units = parseInt(evt.target.value);
                                                    setcategoryedit(current)
                                                }} placeholder="Quantity" type="number" style={{ width: 100, marginLeft: 10 }} /> : null}
                                            </Space>
                                        </Radio.Group>
                                    }

                                </div>
                                

                            </Col>
                            {
                                categoryedit && <Col span={24} className='membership-input md20'>
                                    <p className="fs16 fw400"> Title </p>
                                    <Input placeholder="Ad-free content" value={categoryedit?.title} className="md10" onChange={(evt) => {
                                        let current = JSON.parse(JSON.stringify(categoryedit));

                                        current.title = evt.target.value;
                                        setcategoryedit(current)
                                    }} />
                                </Col>
                            }
                            <Col span={24} className='membership-input md50 text-center'>
                                <a href="https://syllo.co/privacy.html" className="fs12 fw400" style={{ color: '#146EB4', textDecoration: 'underline' }}> Make sure this benefit meets guidelines</a>
                            </Col>
                            <Col span={24} className='text-center md20 mb-3'>
                                <Space>
                                    <Button type={"dashed"} onClick={handleCancel}>Cancel</Button>
                                    <Button type="primary" onClick={() => setBenefits()} > Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Modal>


        </Row>
    );
}
export default CreateMemberShip;