import { Col, Row, Tag, Avatar, Space, Button, Badge, Skeleton, Dropdown } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import React from "react";
import { useState } from "react";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import moment from "moment";
import { DotsThreeVertical, Export } from "@phosphor-icons/react";
import { RWebShare } from "react-web-share";
import { useHistory } from "react-router-dom";
const url = new URLSearchParams(window.location.search);
var _tag = url.get("tag");

const ExploreTagSelected = () => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(null)
    const [moreloader, setmoreloader] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [offset, setOffset] = useState(0)
    const [tags, setTags] = useState([])
    const history = useHistory();

    const menuClick = ({ key, id }) => {
        if (key === '4') {

        }
    };

    const menuItems = (item) => {
        // ``
        return [

            {
                // label: (
                //     <span className="fs12 fw400"> Share</span>
                // ),
                key: '1',
                icon: <RWebShare
                    data={{
                        text: "Syllo Channel",
                        url: `${process.env.REACT_APP_URL}channel/${item.uid}/${item.channel_id}`,
                        title: item?.name,
                    }}

                >
                    <Button block className={"blue-hover text-left"} type={"text"} shape={"circle"} icon={<Export size={18} color="#000000" weight="thin" className="me-2" />}>
                        Share</Button>
                </RWebShare>
            },
            // {
            //     label: (
            //         <span className="fs12 fw400"> Delete video</span>
            //     ),
            //     key: '2',
            //     icon: <Trash size={18} color="#000000" weight="thin" />
            // }

        ]
    };

    const searchUser = (more) => {
        if (more) {
            setmoreloader(true)
        }
        else {
            setLoader(true)
        }
        axios({
            method: 'get',
            url:  `market/feed/search/tag?count=10&offset=${offset}&entity&key&tag=` + _tag,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const __data = res.data.data
            if (__data && __data.items.length >= 10) {
                setLoadMore(true)
                setOffset(offset + 10)
            }
            else {
                setLoadMore(false)

            }
            setData(data.concat(__data.items))
            setTags(tags.concat(__data.tags))
            setLoader(false)
            setmoreloader(false)
        })

    }



    useEffect(() => {
        searchUser(false)
    }, [])


    return (
        <Row>

            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }} md={{ span: 20, offset: 2 }} className='md50 m-px20'>
                <Row>
                    <Col xs={{ span: 20 }} lg={{ span: 22 }} >
                        <Space aria-orientation="horizontal" >
                            <Avatar shape="square" size={34} src={<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.75 10.2086H20.4164V13.125H8.75V10.2086ZM8.75 16.0414H26.25V18.9577H8.75V16.0414ZM8.75 21.875H13.1101V24.7914H8.75V21.875Z" fill="black" fill-opacity="0.6" />
                                <path d="M20.4165 4.375L16.0415 0V2.91637H5.83375C5.45062 2.91626 5.07122 2.99164 4.71723 3.1382C4.36325 3.28477 4.04161 3.49965 3.77069 3.77056C3.49978 4.04148 3.2849 4.36312 3.13833 4.71711C2.99177 5.07109 2.91639 5.45049 2.9165 5.83362V29.1664C2.9165 29.9401 3.22386 30.6821 3.77095 31.2292C4.31804 31.7763 5.06005 32.0836 5.83375 32.0836H11.6665V29.1664H5.83375V5.83362H16.0415V8.75L20.4165 4.375ZM14.5838 30.625L18.9588 35V32.0827H29.1665C29.5496 32.0829 29.9289 32.0075 30.2828 31.861C30.6368 31.7145 30.9584 31.4997 31.2293 31.2289C31.5002 30.958 31.7151 30.6365 31.8617 30.2826C32.0083 29.9287 32.0838 29.5494 32.0838 29.1664V5.83275C32.0838 5.44969 32.0083 5.07039 31.8617 4.7165C31.7151 4.36261 31.5002 4.04107 31.2293 3.77025C30.9584 3.49943 30.6368 3.28463 30.2828 3.13812C29.9289 2.99161 29.5496 2.91626 29.1665 2.91637H23.3338V5.83275H29.1665V29.1664H18.9588V26.25L14.5838 30.625Z" fill="black" fill-opacity="0.6" />
                            </svg>
                            }>

                            </Avatar>
                            <p className="fs24 fw500">{_tag}</p>
                        </Space>
                    </Col>
                    <Col xs={{ span: 4 }} lg={{ span: 2 }} className='text-right md5'>
                        <Button type="text" className={"cursor"} onClick={() => window.location.href = "/explore"} icon={<CloseOutlined />}></Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }} md={{ span: 20, offset: 2 }} className="md50 m-px20">
                {loader === null ? "" :
                    loader ? <Skeleton></Skeleton> : <>
                        <div style={{ overflowX: "scroll" }}>


                            {/* <Space >
                                {
                                    tags?.map((item) => {
                                        return <Tag className="yuja-tag cursor" onClick={() => window.location.href = "/explore/tag?tag=" + item}>{item}</Tag>
                                    })
                                }
                            </Space> */}
                        </div>
                        <Row gutter={30}>
                            {
                                data && data.map((item) => {
                                    return item?.entity === "Creator" ?
                                        <Col xs={{ span: 24 }} lg={{ span: 12 }} >
                                            <a href={"/profile/" + item?.user_code}><div className='creator-card md30'>
                                                <Row className="p-3">
                                                    <Col span={20} className=''>
                                                        <Avatar size={50} src={item?.img_url}> </Avatar>
                                                    </Col>
                                                    <Col span={4} className='text-right'>
                                                        <Button type="text" shape="round"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                                                            <rect width="18" height="18" fill="url(#pattern0)" />
                                                            <defs>
                                                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                                    <use href="#image0_665_1089" transform="scale(0.0416667)" />
                                                                </pattern>
                                                                <image id="image0_665_1089" width="24" height="24" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAXklEQVRIie2SQRGAMAwEN9gAR/iIJjoVgiDwcUggZZoX2Xcye4+F4gWLHrr7DjRAkrz3fkb+loExB7ACm5m16NOI4BNhgSQHbuAyM8+b9Dsq03mCyjSNynSeoDJN4wH1RDAQOJ+vlwAAAABJRU5ErkJggg==" />
                                                            </defs>
                                                        </svg></Button>
                                                    </Col>
                                                    <Col span={24} className='md10'>
                                                        <p className="fs16 fw600 mb-0"> {item?.fullname} </p>
                                                        <span className="fs12 fw400 mt-0" style={{ color: '#90949A' }}>@{item?.username}</span>
                                                    </Col>
                                                </Row>
                                                
                                            </div></a></Col> : item?.entity === "channel" ? <Col xs={{ span: 24 }} lg={{ span: 12 }} >

                                                <div className='creator-card md30'>
                                                    <Row className="">
                                                        <Col span={24} className='text-right exp-bg-img pt-2' style={{ backgroundColor: "rgb(224 224 224)", backgroundImage: `url(${item?.banner_img_url})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                                            <Dropdown trigger={['click']} menu={{ items: menuItems(item), onClick: ({ key, }) => menuClick({ key: key, id: item }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                                <Button type="text" shape="circle" onClick={(e) => e.preventDefault()}
                                                                    icon={<DotsThreeVertical size={20} color="#000" weight="fill" />}></Button>
                                                            </Dropdown>
                                                        </Col>
                                                        <Col className="cursor" span={24} onClick={() => history.push(`/channel/${item.uid}/${item.channel_id}`)}>
                                                            <Row>
                                                                <Col span={24} className='text-center md35'>
                                                                    <Avatar size={44} src={item?.img_url} />
                                                                    <p className="fs16 fw600 mb-0"> {item?.name}</p>
                                                                    <span className="fs12 fw400 mt-0" style={{ color: '#90949A' }}>@{item?.creator_name}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row className="padding15 md30">
                                                                <Col span={19}>
                                                                    <Space split={"|"} wrap>
                                                                        {
                                                                            item?.category.map((_item, i) => {
                                                                                return <p key={i} className="12 fw300">{_item?.name}</p>
                                                                            })
                                                                        }


                                                                    </Space>
                                                                </Col>
                                                                <Col span={5} className=''>
                                                                    <p className="12 fw300">{item?.member_count} members</p>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col> :

                                            <Col xs={{ span: 24 }} lg={{ span: 12 }}> <a href={"/event/" + item.schedule_uid}><div className='yujaevent-card'>
                                                <Row>
                                                    <Col span={24} className='bg-event-img'
                                                        style={{ backgroundImage: `url(${item?.img_url})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
                                                    >
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24} className='padding20'>
                                                        <Row className=''>

                                                            <Col span={24} className='md10'>
                                                                <p className="fs20 fw600 m-0 lh24">{item?.name}</p>
                                                            </Col>
                                                            <Col span={24} className='md10'>
                                                                <p className="fs12 fw400 m-0">{moment(item?.schedule_date_from).format("Do MMM | h:mm a")} - {moment(item?.schedule_date_to).format("Do MMM YY | h:mm A")} </p>
                                                                <p className="fs12 fw400 m-0">{item?.is_paid ? "Paid offering |" : ""}  {item?.venue}</p>
                                                                <p className="fs12 fw500 md15 "><Tag color="blue" > {(item?.medium)} </Tag> {item?.is_paid && <Tag color="orange" >Paid</Tag>}</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </div></a></Col>
                                })
                            }



                        </Row>


                        {
                            moreloader && <Skeleton></Skeleton>
                        }

                        {
                            loadMore && <Col span={24} className={"md20 text-center"} style={{marginBottom:"20px"}}>
                                <Button onClick={() => searchUser(true)}>Load More</Button>
                            </Col>
                        }
                    </>
                }

            </Col>

        </Row>
    );
}
export default ExploreTagSelected;
