import { Col, Row, Divider, Button, Space, Badge, Skeleton } from "antd";
import { ChatCircleDots, DotsThreeOutlineVertical, PaperPlaneRight, X } from "@phosphor-icons/react";
import '../channel_chat.scss';
import React, { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";
import moment from "moment";
import MessageTextCard from "../../ChannelLiveChat/MessageTextCard";
import InputEmoji from 'react-input-emoji'
import _ from "lodash";
import ScrollableFeed from "react-scrollable-feed";



const ChannelThreads = ({ id, _uid }) => {

    const [loaded, setLoaded] = useState(false)
    const [messages, setmessages] = useState([])
    const [loader, setloader] = useState(true)
    const [discussionloader, setdiscussionloader] = useState(true)
    const [discussion, setdiscussion] = useState([])
    const [discussionmsg, setdiscussionmsg] = useState([])
    const [discussionserver, setdiscussionserver] = useState(null)
    const [text, settext] = useState("")

    useEffect(() => {
        let _ws = new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/stream?id=" + id + "&token=" + Cookies.get("Logintoken"))
        _ws.onmessage = evt => {
            const message = JSON.parse(evt.data)
            if (message.length !== 0) {
                let a = message.filter((item) => { return item[17] === 1 })
                setmessages(messages => {
                    return [...messages, ...a];
                })
            }
            setloader(false)
        }
    }, [])



    // const onClick = ({ key }) => {
    //    Toast(`Click on item ${key}`);
    // };



    const submitMessage = () => {

        let message = { m: text, mid: discussionmsg[0] }
        discussionserver.send(JSON.stringify(message))
        settext("")
    }

    const openDiscussion = (item) => {
        setLoaded(true)
        setdiscussionmsg(item)
        setdiscussionloader(true)
        setdiscussion([])
        let threadws = new WebSocket(process.env.REACT_APP_WS_BASE_URL + `wss/channel/thread?token=${Cookies.get("Logintoken")}&id=${_uid}&thread_id=${item[0]}`)
        setdiscussionserver(threadws)

        threadws.onmessage = evt => {
            const _message = JSON.parse(evt.data).messages
            if (_message.length !== 0) {
                setdiscussion(discussion => {
                    return [...discussion, ..._message];
                })
            }
        }
        setdiscussionloader(false)
    }

    // const menu = (
    //     <Menu
    //         onClick={onClick}
    //         items={[
    //             {
    //                 label: (
    //                     <span className="fs12 fw400"> <Export size={18} color="#000000" weight="thin" /> Share</span>
    //                 ),
    //                 key: '1',
    //             },
    //             {
    //                 label: (
    //                     <span className="fs12 fw400"> <Copy size={18} color="#181816" weight="thin" /> Copy Link</span>
    //                 ),
    //                 key: '2',
    //             },
    //             {
    //                 label: (
    //                     <span className="fs12 fw400" style={{ color: '#DE0905' }}> <Trash size={18} color="#DE0905" weight="thin" /> Delete</span>
    //                 ),
    //                 key: '3',
    //             }
    //         ]}
    //     />
    // );
    const userCode = Cookies.get("user_code");
    // let _messages = _.groupBy(messages, "")

    return (

        <Row gutter={20}>
            <Col xs={{span:24}} lg={{span:14}}>
                <Row className='channel-threads gutter-row'>

                    {
                        loader ? <Skeleton></Skeleton> :
                            <Col span={24}>
                                {/* <Divider> <p className="fs12 fw300  ">today</p></Divider> */}
                                <Row className="" style={{ minHeight: "59vh", overflowY:"scroll" }}>

                                    {
                                        messages.map((item) => {
                                            return <Col lg={{span:16}} xs={{span:24}} className=" md15 cursor" onClick={() => openDiscussion(item)}>
                                                <Row className="discussion-head">
                                                    <Col xs={{ span: 12 }} lg={{ span: 24 }} className='text-right'>
                                                        <Space align="center" size={10} >
                                                            <span style={{ color: "#ffffff99" }}>{item[13]} replies</span>
                                                            <Badge status="default" color=" #ffffff99 " ></Badge>
                                                            <img src={require('../../../assets/images/channel/discu.svg').default} alt="" height={20} />

                                                        </Space>
                                                    </Col>
                                                </Row>
                                                <Row className="thread-bg">
                                                    <Col span={18} className='md15'>
                                                        <Space>
                                                            <img src={item[16] ? item[16] : require('../../../assets/images/channel/user.png')} alt="" width={40} />
                                                            <p className="fs14 fw500" style={{ color: '#146EB4' }}>{item[2]}</p>
                                                            <p className="fs12 fw300 text-grey ">{moment(item[5]).fromNow()}</p>
                                                        </Space>
                                                    </Col>
                                                    <Col span={6} className='text-right'>
                                                        <Button type="text" shape="circle" onClick={(e) => e.preventDefault()} className="thread-icon" icon={<DotsThreeOutlineVertical size={15} color="#0d0d0c" weight="fill" />}>

                                                        </Button>

                                                    </Col>
                                                    <Col span={24} className='md10'>
                                                        <p className="fs14 fw400">{item[3]}</p>

                                                    </Col>
                                                </Row>
                                            </Col>
                                        })
                                    }


                                </Row>

                            </Col>
                    }
                </Row>

            </Col>

            <Col xs={{span:24}} lg={{span:10}} className='m-md15 mb-2'>
                <Row className='chat-threads '>
                    <Col span={24}>

                        {
                            loaded ?
                                <Row>
                                    <Col span={24} className="thread-chat">
                                        <Row>
                                            <Col xs={{ span: 20 }} lg={{ span: 20 }}>
                                                <p className="fs14 fw400 text-white md5"> {discussionmsg[3]}</p>
                                            </Col>
                                            <Col span={4} className='text-right'>
                                                <Button type="text" shape="circle" onClick={() => setLoaded(false)} className='text-right' icon={<X color="#fff" size={18} />}></Button>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col span={24} style={{ height: "50vh", overflowY: "scroll" }}>
                                        <ScrollableFeed forceScroll={true}>
                                            <Row>

                                                <Col span={24}>
                                                    <Row className="thread-bg radius0">
                                                        <Col span={18} className='md15'>
                                                            <Space>
                                                                <img src={discussionmsg[16] ? discussionmsg[16] : require('../../../assets/images/channel/user.png')} alt="" width={40} />
                                                                <p className="fs14 fw500" style={{ color: '#146EB4' }}>{discussionmsg[2]}</p>
                                                                <p className="fs12 fw300 text-grey ">{moment(discussionmsg[5]).fromNow()}</p>
                                                            </Space>
                                                        </Col>
                                                        <Col span={6} className='text-right'>
                                                            <Button type="text" shape="circle" onClick={(e) => e.preventDefault()} className="thread-icon" icon={<DotsThreeOutlineVertical size={15} color="#0d0d0c" weight="fill" />}>

                                                            </Button>

                                                        </Col>
                                                        <Col span={24} className='md10'>
                                                            <p className="fs14 fw400">{discussionmsg[3]}</p>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24} className='md25 ps-2 pe-2'>
                                                    <Divider> <p className="fs12 fw300  ">Replies</p></Divider>
                                                </Col>

                                                {
                                                    discussionloader ? <Skeleton></Skeleton> :
                                                        discussion.map((item, index) => {
                                                            return <Col span={24} key={index}> <MessageTextCard item={item} userCode={userCode} /> </Col>
                                                        })
                                                }

                                            </Row>
                                        </ScrollableFeed>
                                    </Col>


                                    {/* <Col span={21} className='md20 thread-chat-card'>
                                        <Row>
                                            <Col span={24}>
                                                <p className="fs12 fw300 text-blue md5 ">Mukesh Bhardwaj</p>
                                                <p className="fs14 fw400  md5">Hi, The sound and picture are great! Yeah! you’re right.</p>
                                                <p className="fs12 fw300 text-grey md5">5:30 pm</p>

                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col span={21} className='md20 thread-right-card'>
                                        <Row>
                                            <Col span={24}>
                                                <p className="fs12 fw300 text-blue md5 ">Mukesh Bhardwaj</p>
                                                <p className="fs14 fw400 md5">Hello Mr., Let’s have a discussion</p>
                                                <p className="fs12 fw300 text-grey md5">5:30 pm</p>

                                            </Col>

                                        </Row>
                                    </Col> */}
                                    <Col span={24} className='pt13'>
                                        <Row>
                                            <Col span={21} >
                                                <InputEmoji
                                                    value={text}
                                                    className={'chat-msg-input mt40'}
                                                    style={{ color: "black", fontSize: "20px" }}
                                                    onChange={(evt) =>
                                                        settext(evt)}
                                                    cleanOnEnter
                                                    onEnter={() =>
                                                        submitMessage()}
                                                    placeholder="Type a message"
                                                />

                                            </Col>
                                            <Col span={3} className="text-center send-icon ">
                                                <Button type='primary' className="" onClick={() => submitMessage()} > <PaperPlaneRight size={22} color="#fff" weight="fill" /></Button>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row> :
                                <Row style={{ minHeight: "63vh" }}>
                                    <Col span={16} offset={4} className='text-center md70 div-bg'>
                                        <Button type="text" shape="circle" className="" icon={<ChatCircleDots size={30} color="#2e2e2d" weight="thin" style={{ marginTop: '-25px', marginLeft: '-14px' }} />}>
                                        </Button>
                                        <p className="fs16 fw400 md25">Select a Thread</p>
                                        <p className="fs12 fw400 md10 text-grey">Hop into any thread and be the first to create one!</p>
                                    </Col>
                                </Row>
                        }

                    </Col>
                </Row>

            </Col>

        </Row>

    );
}
export default ChannelThreads;