import React from 'react';
import { Row, Col, Avatar, Skeleton,  Button, Dropdown, Typography, Space } from 'antd';
import moment from "moment"
import { withRouter } from "react-router";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { Dot, DotsThreeVertical, Export, Note, PencilLine, Trash } from "@phosphor-icons/react";
import BlankState from '../BlankState';
import { RWebShare } from 'react-web-share';
import { useEffect } from 'react';

import Toast from '../utils/Toast';
const { Paragraph } = Typography

const MyHubNewsletter = (props) => {


    function editNewsletter(data) {
        props.history.push({
            pathname: `/newsletter/${data.entity_uuid}`,
            state: {
                edit: true,
                record: data
            }
        })
    }

    useEffect(() => {
        console.log(props)
    }, [props])

    function generateSummary(data2) {
        props.CreateNewsletterSummary({
            id: data2.entity_uuid
        })
    }


    function deleteNewsletter(data2) {
        props.DeleteNewsletter({
            id: data2.entity_uuid
        }).then(() => {
            Toast("Deleted Successfully","success")
            props.updateState();
        })
    }


    // function details(record) {
    //     window.location.href = "/feed-view/" + record?.entity_uuid
    // }

    const onClick = (key, id ) => {
        if (key === '3') {
            deleteNewsletter(id);
            return
        } else if (key === '4') {
            editNewsletter(id);
            return
        } else if (key === '2') {
            generateSummary(id);
            return
        }
    };

    const items = (item) => {
        let i = [
            {
                key: '1',
                icon:
                    <RWebShare
                        data={{
                            text: "Syllo: Note",
                            url: `${process.env.REACT_APP_URL}feed-view/${item.entity_uuid}`,
                            title: item?.title,
                        }}

                    ><span><Export size={18} color="#000000" weight="thin" className="me-1" /> Share</span>
                    </RWebShare>
            },

        ]

        if (!item.published) {
            i.push({
                label: (
                    <span className="fs12 fw400"> Edit</span>
                ),
                key: '4',
                icon: <PencilLine size={18} color="#000000" weight="thin" />
            })
        }

        if (!item?.has_summary && item.published) {
            i.push({
                label: (
                    <span className="fs12 fw400"> Generate Summary</span>
                ),
                key: '2',
                icon: <Note size={18} color="#000000" weight="thin" />
            })
        }
        i.push({
            label: (
                <span className="fs12 fw400"> Delete</span>
            ),
            key: '3',
            icon: <Trash size={18} color="#000000" weight="thin" />
        })

        return i
    };





    return (<>
        {
            props?.loader === true ? <Skeleton></Skeleton> : props?.data.length === 0 ? <Row> <Col span={24} className="md60 text-center">
                <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} />

            </Col></Row> :


                props?.data.map((item, i) => {
                    return <Row key={i} className='single-poll-view'>
                        <Col md={3} xs={6}>
                            <Avatar size={70} src={item.image_url} style={{ borderRadius: '6px' }}></Avatar>
                        </Col>
                        <Col md={21} xs={18}>
                            <a href={"/feed-view/" + item.entity_uuid} style={{ flexGrow: 1 }}>
                                <div className="title mb-2">
                                    <Paragraph className="" ellipsis={{ rows: 2 }}>{item.title}</Paragraph>
                                    <div className="menu-surface-anchor">
                                        {
                                            <Dropdown trigger={['click']} menu={{ items: items(item), onClick: ({ key }) => onClick(  key,  item ) }} placement={"bottomCenter"} overlayStyle={{ minWidth: "120px" }}>
                                                <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                            </Dropdown>
                                        }

                                    </div>

                                </div>
                            </a>
                                    <p className='fs12 text-grey'> Published at {moment(item.created_at).format("Do MMM YYYY, h:mm A")} </p>
                            <Space align='center' size={2} wrap split={<Dot size={24} />}>
                                    <p className='fs12 text-grey' >  {item.likes} Likes </p>
                                    <p className='fs12 text-grey' >  {item.comments} Comments </p>
                                </Space>
                            
                        </Col>

                    </Row>
                })

        }


    </>
    );
}


const mapStateToProps = (state) => ({
    _newsletter_id: state.newsletter.id,
    _newsletter_summary: state.newsletter.summary
});
const mapDispatchToProps = (dispatch) => ({
    DeleteNewsletter: (v) => dispatch(actions.DeleteNewsletter(v)),
    CreateNewsletter: (v) => dispatch(actions.CreateNewsletter(v)),
    CreateNewsletterSummary: (v) => dispatch(actions.CreateNewsletterSummary(v)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MyHubNewsletter)
);
