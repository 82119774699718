import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
// import renderHTML from "react-render-html";
import { Avatar, Button, Skeleton, Image, Radio, Row, Col, Divider, Space, Tag, Badge, Modal } from 'antd';
import moment from 'moment';
import Cookies from "js-cookie";
import { RWebShare } from "react-web-share";
import NewsFeed from "../NewsFeed/NewsFeed";
import { ArrowLeft, BookmarkSimple, ChartBar, ChatCircle, Export, HandsClapping } from "@phosphor-icons/react";
import ReactPlayer from "react-player";
import NewsletterReadView from "../Editor/NewsletterReadView";
import PaymentCreditPage from "../utils/paymentCredit";

import Toast from "../utils/Toast";
import FollowShare from "../utils/FollowShare";
import EntityComments from "../FeedEntity/EntityComments";
import EntityAnalytics from "../FeedEntity/EntityAnalytics";


class NewsletterDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "", comments: 0,
      value: '',
      news: false, news_link: "", dp: Cookies.get('user_pic'),
      ws: null,
      loader: true, poolValue: "",
      sortby: 'Recent',
      token: Cookies.get("Logintoken"),
      creditModal: false,
      entity_uuid: "",
      modal: false
    };
  }

  async componentDidMount() {
    let uuid = ""
    if (this.props.id) {
      uuid = this.props.id?.entity_uuid
    }
    else {
      uuid = this.props.params.uuid
    }
    this.setState({ entity_uuid: uuid });


    this.props.NewsletterDetails({
      id: uuid,
    }).then(() => {
      const res = this.props._newsletterdetails;
      this.setState({
        data: res,
        loader: false
      });
      if (res.price > 0 && !res.isme && !res.unlocked) {
        this.setState({ creditModal: true });
      }
      // set entity detials in state to manage like, comment and bookmarks
      this.props.setEntityInStore(res);
    });

    if (this.state.token === undefined) {
      return
    }

    // const _ws = new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/feed?euid=" + (this.props.id?.entity_uuid || this.props.params.uuid) + "&token=" + this.state.token);

    // this.setState({ ws: _ws })

    // _ws.onmessage = evt => {
    //   const message = JSON.parse(evt.data)
    //   var comments = message.concat(this.state.comments)
    //   this.setState({ comments: comments, loader: false })
    // }

    // this.state.ws.onclose = () => {

    //   this.setState({
    //     ws: new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/feed?euid=" + params['uuid'] + "&token=" + Cookies.get("Logintoken"))
    //   })

    // }

  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the state has changed
    if (this.state.data !== prevState.data) {
      // Call your function here
      this.props.setEntityInStore(this.state.data);
    }
    if (this.state.comments !== prevState.comments) {
      // Call your function here
      this.props.setEntityInStore({...this.state.data, comments: this.state.comments});
    }
  }

  componentWillUnmount(){
    this.props.setEntityInStore(null);
  }

  likeFeed = (data, record) => {
    if (this.state.token === undefined) {
      return
    }

    this.setState({ data: { ...this.state.data, liked: data } });

    this.props
      .likeFeed({
        id: record.entity_uuid,
        type: record.entity_type,
        requesttype: data === true ? "post" : "delete",
      }).then(() => {

      })

  }
  details(record) {
    window.location.href = "/feed-view/" + record?.entity_uuid
  }

  SaveFeed(id, entity) {
    if (this.state.token === undefined) {
      return
    }
    this.setState({ data: { ...this.state.data, saved: true } })

    this.props.SaveFeed({
      "id": id,
      "entity_type": entity,
      "type": "Post"
    });
  }

  DeleteFeed(id, entity) {
    if (this.state.token === undefined) {
      return
    }
    this.setState({ data: { ...this.state.data, saved: false } })
    this.props.SaveFeed({
      "id": id,
      "entity_type": entity,
      "type": "Delete"
    }).then(() => {

    })
  }

  // handleSubmit = () => {
  //   if (this.state.token === undefined) {
  //     Toast("Please login to comment!", "error")
  //     return
  //   }

  //   if (!this.state.value) {
  //     Toast("Type Comment", "error")
  //     return;
  //   }
  //   const msg = { msg: this.state.value }
  //   this.state.ws.send(JSON.stringify(msg))
  //   this.setState({ value: "" })
  // };

  // handleChange = e => {
  //   this.setState({
  //     value: e.target.value !== " " ? e.target.value : "",
  //   });
  // };



  votePool() {
    if (this.state.token === undefined) {
      Toast("Please login to vote!", "error")
      return
    }
    this.props.votePoll({
      choice_id: this.state.poolValue,
      poll_perc: 1
    }).then(() => {
      this.props.componentDidMount()
    })
  }


  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  paytoSubcribe(_id) {
    // this.props.paytoSubcribe({ "entity_id": _id }).then(() => {
    //   this.displayRazorpay(this.props._payment_link)
    // })

  }




  async displayRazorpay(data) {
    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      Toast("Razorpay SDK failed to load. Are you online?", "error");
      return;
    }

    const reload = () => {
      this.setState({ loader: true })
      setTimeout(() => {
        this.componentDidMount()
      }, 2000);
    }


    const options = {
      key: data.key, // Enter the Key ID generated from the Dashboard
      amount: data.amount.toString(),
      currency: data.currency,
      name: data.name,
      description: data.description,
      image: null,
      order_id: data.order_id,
      handler: async function (response) {
        const data = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        Toast("Payment Suucessfull", "error")
        reload()
      },
      prefill: data.prefill,
      notes: data.notes,
      theme: data.theme
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  onCreditUpdate(res) {
    this.setState({ creditModal: res.modal });
    if (res.status === "success") {
      this.componentDidMount();
    }
  }

  setModal(_open) {
    this.setState({ modal: _open })
  }


  render() {
    const { comments, data } = this.state;

    return (


      <Row className="height100vh feed-view-page">
        <Col span={6}></Col>
        <Col lg={{ span: 12 }} xs={{ span: 24, offset: 0 }} >
          <div className="row mb-5">
            <div className="col-xl-12 col-md-12 md50 m-md20">

              {
                (this.props.id === null || this.props.id === undefined) && <div className="row nomargin">
                  <div className="col-md-12">
                    <Button type="link" shape="circle" className="fs-16" style={{ color: "#504e4e", fontWeight: "400" }} onClick={() => this.props.history.goBack()} icon={<ArrowLeft size={20} color="#030303" weight="light" style={{ marginRight: "5px" }} />} > Back</Button>

                  </div>

                  <div className="col-md-12  ">
                    <Divider></Divider>
                  </div>
                </div>
              }


              <div className="row nomargin ">

                {
                  this.state.loader === true ? <div className="col-md-12 padding20"><Skeleton active></Skeleton></div> :

                    <>
                      {/* Header */}
                      <div className="col-md-12 m-mt40 ">
                        <h5 className="heading-text">{data.title}</h5>
                        <p className="sub-text md10">{data.meta_title}</p>
                      </div>
                      <div className="col-md-12 md20">
                        <div className="row" style={{ alignItems: "center" }}>
                          <div className="col-xl-1 col-xsm-2 col-md-3" style={data?.org ? { paddingTop: "20px" } : {}}>
                            <Avatar className="cursor" src={data?.creator?.avatar_url} size="large" onClick={() => this.props.history.push(`/profile/${data?.creator?.user_code}`)}></Avatar>
                            {
                              data?.org && <Avatar size={25} src={data?.org?.logo} className="m-ml3 mini-md23" style={{ marginLeft: '30px', marginTop: '-40px', background: "white" }} />
                            }

                          </div>
                          <div className="col-xl-5 col-xsm-10 col-md-4 m-iphonex-pl35">
                            <Space>
                              <p className="m-pl8 fs16 cursor" onClick={() => this.props.history.push(`/profile/${data?.creator?.user_code}`)}>{data?.creator?.full_name}</p>
                              {/* <Badge status='default' className="ps-2" />
                              <FollowShare profile={data}></FollowShare> */}
                            </Space>
                            <br></br>
                            <Space>
                              {
                                data?.org && <>
                                  <span className="fs12 fw400 text-grey"> Published in
                                    <a className="fs12 fw400 text-black" href={`/business/${data?.org?.username}`}> {data?.org?.name} </a></span>
                                  <Badge status="default" />
                                </>
                              }

                              <span className="m-pl8 fs12 fw400 text-grey">{moment(data.created_at).fromNow()}</span>
                            </Space>

                          </div>
                          <div className="col-xl-6 col-xsm-12 col-md-5 text-last ">
                            <Space align="center">
                              <Button type={"text"} className={"blue-hover"} href={"#comment"} shape={"circle"} icon={<ChatCircle size={22} className={"border-blue"} weight="thin" />}></Button>

                              {
                                data.liked === true ?
                                  <Button type={"text"} className={"blue-hover"} shape={"circle"} icon={<HandsClapping size={22} weight="fill" color={"#3B6BE3"} />} onClick={() => this.likeFeed(false, data)} ></Button>
                                  : <Button type={"text"} className={"blue-hover"} shape={"circle"} icon={<HandsClapping size={22} weight="thin" className={"border-blue"} />} onClick={() => this.likeFeed(true, data)}></Button>
                              }
                              <RWebShare
                                data={{
                                  text: data.description,
                                  url: `${process.env.REACT_APP_URL}feed-view/${data?.entity_uuid}`,
                                  title: data.title,
                                }}

                              >
                                <Button className={"blue-hover"} type={"text"} shape={"circle"} icon={<Export className={"border-blue"} size={22} weight="thin" />}>
                                </Button>
                              </RWebShare>


                              {
                                data.entity_type !== "poll" &&
                                  data.saved === true ? <Button className={"blue-hover"} type={"text"} shape={"circle"} icon={<BookmarkSimple className={"border-blue"} size={22} color={"#3B6BE3"} weight="fill" />} onClick={() => this.DeleteFeed(data.entity_id, data.entity_type)}></Button> :
                                  <Button className={"blue-hover"} type={"text"} shape={"circle"} icon={<BookmarkSimple className={"border-blue"} size={22} weight="thin" />} onClick={() => this.SaveFeed(data.entity_id, data.entity_type)}></Button>
                              }


                            </Space>



                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Divider></Divider>
                      </div>
                      {/* Content */}

                      {!data?.isme && data?.paid_content ? <div className="col-md-11 offset-md-1 md20 paid-content">
                        <div className="row">
                          <div className="col-md-12">
                            <p> {this.state.data.title}</p>
                            <p>{this.state.data.meta_title}</p>
                          </div>


                          <div className="col-md-12 fixed-bottom text-center cursor">
                            {
                              this.state.data.direct_buy && < >
                                <p className={"cursor"} onClick={() => this.paytoSubcribe(this.state.data?.entity_uuid)}><b>Buy Now</b></p>
                                <p>or</p>
                              </>
                            }
                            <p className={"cursor"} onClick={() => window.location.href = "/profile/" + this.state.data.creator?.user_code}> <Avatar src={require("../../assets/images/lock.svg")} size={"small"} style={{ border: "none", float: "left" }}></Avatar>   <b>View Plans! - </b>Unlock deeper insights</p>
                          </div>
                        </div>
                      </div> : data.entity_type === "Poll" ? <div className="col-md-12">
                        <div className="pool-card md10">
                          <div className="row">
                            <div className="col-md-12">

                              <h6 className="news-header cursor md5">{data.title}</h6>
                            </div>

                            <div className="col-md-12">
                              {/* <p className="blue-text">{data.vote_count} people voted</p> */}
                              <Space>
                                <p className="fs12" style={{ color: "#728096" }}> {data?.vote_count} Vote</p>
                                <Badge status="default"></Badge>
                                <p className="fs12" style={{ color: "#728096" }}> {moment().isBefore(moment(data?.expired_at)) ? moment(data?.expired_at).fromNow() : "Poll closed"}</p>
                              </Space>
                            </div>

                            {/* {
                              data.voted === true ? <Fragment>
                                <div className="col-md-12">
                                  <div className="row">
                                    {
                                      data.choices.map((_data) => {
                                        return <div className="col-md-12 md10">
                                          <p className="bold">{_data.choice}</p>
                                          <p><Progress percent={parseFloat(_data.p).toFixed(1)} /></p>
                                        </div>
                                      })
                                    }


                                  </div>
                                </div>
                              </Fragment> : <Fragment>

                                <div className="col-md-12 md20 nopadding">
                                  <Radio.Group className="pool" onChange={(evt) => this.setState({ poolValue: evt.target.value })}>
                                    {
                                      data.choices.map((_data) => {
                                        return <Radio value={_data.uuid}>{_data.choice}</Radio>
                                      })
                                    }


                                  </Radio.Group>
                                </div>

                                <div className="col-md-12 md20">
                                  <button className="btn btn-default" onClick={() => this.votePool()}>Vote</button>
                                </div>
                              </Fragment>
                            } */}

                            <div className="col-md-12 md20">
                              <div className="row nomargin">

                                {data.voted || !moment().isBefore(moment(data?.expired_at)) ? (
                                  <Fragment >
                                    <div className="col-md-12 poll-border">
                                      <div className="row">
                                        {data.choices && data.choices.map((_item, i) => {
                                          return <div key={i} className="col-md-12 md10">
                                            <div className="poll-option-progress">
                                              <div className="syllo-progress" style={{ width: `${parseFloat(_item.p).toFixed(1)}%` }}>
                                                <p className="bold">{_item.choice}</p>
                                              </div><span>{parseFloat(_item.p).toFixed(1)}</span>
                                            </div>
                                          </div>
                                        })}

                                      </div>
                                    </div>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <div className="col-md-12 poll-border">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <Radio.Group
                                            className="pool"
                                            onChange={(evt) => this.setState({ poolValue: evt.target.value })}
                                          >
                                            {data?.choices && data?.choices.map((_item, i) => {
                                              return <Radio key={i} value={_item.uuid}>{_item.choice}</Radio>;
                                            })}
                                          </Radio.Group>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-8 md10">
                                            </div>

                                            <div className="col-md-4 text-right">
                                              <Button type="primary"
                                                disabled={this.state.poolValue === ""}
                                                onClick={() => this.votePool(this.state.poolValue)}
                                              >
                                                Vote
                                              </Button >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                )}

                              </div>
                            </div>



                          </div>
                        </div>

                      </div> :
                        data.entity_type === "Newsletter" ?
                          <div className="col-md-12 m-p0 m-ps0">

                            <div className="row ">


                              {
                                data?.entity_type !== "Report" && data?.image_url !== null && data?.image_url !== "" &&
                                <div className="col-md-12 md20 text-center" style={{ padding: "0 27px" }}>
                                  {/* <Image src={} preview={false} height={"340px"} width={"100%"} style={{ borderRadius: "8px" }}></Image> */}
                                  <div style={{ height: "340px", backgroundImage: `url(${data?.image_url})`, borderRadius: "8px", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                </div>
                              }
                              {
                                !data?.isme && data?.price > 0 && !data?.unlocked ?
                                  <div className="col-md-12 md10 text-center">
                                    This is a paid content <br />
                                    <Button className="md20" type="primary" onClick={() => this.setState({ creditModal: true })}>Unlock Now</Button>
                                  </div> :
                                  <div className="col-md-12 md10">

                                    <NewsletterReadView className='p-3' intState={data?.description} />
                                    {/* {renderHTML(this.state.data.description)} */}
                                  </div>

                              }



                              <div className="col-md-12 md20 mb-3 ms-2 new-dashb-tag">
                                {
                                  this.state.data.tags.map((item) => {
                                    return <Tag key={item}
                                    >{item}</Tag>
                                  })
                                }
                              </div>
                            </div>


                          </div> :
                          data.entity_type === "Reels" ? <>
                            <div className="col-md-12 m-p0 m-ps0 ">
                              <div className=" reels-card">
                                <div className="reel-video">
                                  {/* <video src={this.state.data?.link} controls controlsList="nodownload" autoPlay={false} style={{ height: "100%", width: "100%" }}></video> */}
                                  <ReactPlayer loop playIcon={true} url={this.state.data?.link} height={"100%"} width={"100%"} style={{ "width": "100%", "height": "100%", "left": "0px", "top": "0px", position: "relative", zIndex: "1" }} ></ReactPlayer>
                                  {/* <ReactPlayer url={this.state.data?.link} style={{height:"100%",width:"100%"}} controls controlsList="nodownload" autoPlay /> */}
                                </div>

                              </div>

                            </div>

                          </> :
                            <div className="col-md-12">
                              <div className="row m-p0 m-ps0">
                                {
                                  !this.state.data?.isme && this.state.data?.price > 0 && !this.state.data?.unlocked ?
                                    <div className="col-md-12 md10 text-center">
                                      This is a paid content <br />
                                      <Button className="md20" type="primary" onClick={() => this.setState({ creditModal: true })}>Unlock Now</Button>
                                    </div> :
                                    <div className="col-md-12">
                                      {
                                        data.entity_type === "Report" || data.entity_type === "Post" ?
                                          <NewsletterReadView intState={this.state.data.description} /> : <p>{this.state.data.description}</p>
                                        // data?.description && renderHTML(this.state.data.description) : <p>{this.state.data.description}</p>
                                      }

                                    </div>
                                }

                                {
                                  data.image_url !== null && data.entity_type === "Post" &&

                                  <>
                                    <Image preview={false} src={data.image_url} style={{ width: "100%", marginTop: "20px", borderRadius: "8px" }}></Image>
                                  </>
                                }

                              </div>

                            </div>}
                    </>
                }


                {
                  data.podcast && <div className="col-md-12 ms-1  ">
                    {data?.podcast?.episodes.map((item) => {

                      return <p className={"md20 "} style={{ marginLeft: '10px' }}>{item.author}    <br></br> <audio src={(item.links).filter((a) => (a.type).split("/")[0] === "audio")[0].href} controls></audio>

                        { }
                      </p>
                    })}
                  </div>
                }

                {/* Footer */}
                <div className="col-md-12">
                  <Divider></Divider>
                  {
                    data?.isme && <>
                      <Button onClick={() => this.setModal(true)} type="text" style={{ color: "#4B5669" }}>
                        <Space>
                          <ChartBar size={24} color="#4B5669" />
                          <span style={{ color: "#4B5669" }}>View Analytics</span>
                        </Space>
                      </Button>

                      <Divider></Divider>
                    </>
                  }

                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-8 col-xsm-5">
                      <p className="sub-text fw600 md5">{comments} Comments</p>
                    </div>
                    <div className="col-md-4 col-xsm-7 text-right">

                      <Space align="center">

                        {
                          this.state.data.liked === true ?
                            <Button type={"text"} className={"blue-hover"} shape={"circle"} icon={<HandsClapping size={22} weight="fill" color={"#3B6BE3"} />} onClick={() => this.likeFeed(false, this.state.data)} ></Button>
                            : <Button type={"text"} className={"blue-hover"} shape={"circle"} icon={<HandsClapping size={22} weight="thin" className={"border-blue"} />} onClick={() => this.likeFeed(true, this.state.data)}></Button>
                        }



                        <RWebShare
                          data={{
                            text: this.state.data.description,
                            url: `${process.env.REACT_APP_URL}feed-view/` + this.state.data?.entity_uuid,
                            title: this.state.data.title,
                          }}

                        >
                          <Button className={"blue-hover"} type={"text"} shape={"circle"} icon={<Export size={22} className={"border-blue"} weight="thin" />}>
                          </Button>
                        </RWebShare>
                        {
                          this.state.data.entity_type !== "poll" &&
                            this.state.data.saved === true ? <Button className={"blue-hover"} type={"text"} shape={"circle"} icon={<BookmarkSimple className={"border-blue"} color={"#3B6BE3"} size={22} weight="fill" />} onClick={() => this.DeleteFeed(this.state.data.entity_id, this.state.data.entity_type)}></Button> :
                            <Button className={"blue-hover"} type={"text"} shape={"circle"} icon={<BookmarkSimple className={"border-blue"} size={22} weight="thin" />} onClick={() => this.SaveFeed(this.state.data.entity_id, this.state.data.entity_type)}></Button>
                        }

                      </Space>


                    </div>
                  </div>

                </div>


                <div className="col-md-12">

                  {
                    this.state.entity_uuid &&
                    <EntityComments commentCount={(value) => this.setState({ comments: value })} item={{ entity_uuid: this.state.entity_uuid }} />
                  }
                </div>

                {
                  !this.state.loader && this.state.data?.next && data?.unlocked && <>

                    <div className="col-md-12 ">
                      <Divider></Divider>
                    </div>
                    <div className="col-md-9">
                      <p className="fs16 fw600">Next story by the Creator</p>
                    </div>
                    <div className="col-md-3 text-right">
                      <Button type="text" href={"/profile/" + this.state.data?.creator?.user_code} className="fs14 fw400" style={{ color: "#0000007a" }}>See all</Button>
                    </div>


                    {/* Next Stroty */}



                    <div className="col-md-12 md20">
                      <NewsFeed
                        Filtertags={[]}
                        data={[this.state.data?.next]}
                        related={false}
                        getFilterByTags={(tag, media, author) => ""}
                        likeFeed={(data, record) =>
                          this.likeFeed(data, record)
                        }
                        showLike={false}
                        componentDidMount={() => this.componentDidMount()}
                        reload={(id, key, type) => ""}
                        details={(record) => this.details(record)}
                        SaveFeed={(id, entity) => this.SaveFeed(id, entity)}
                        DeleteFeed={(id, entity) => this.DeleteFeed(id, entity)}
                        showFooter={false}
                        height="auto"
                      />
                    </div>
                  </>
                }


              </div>
            </div>

          </div>
        </Col>
        <Col lg={6} xs={0}>
          {
            data && data.org && <Row>
              <Col span={20} offset={1} className="mb-4">
                <div className="expl-business-card md80 " style={{ backgroundColor: '#f4f4f4' }}>

                  <img className="bn" src={data?.org?.banner_url} alt="" />
                  <div className="card-b">
                    <Avatar style={{ border: "none", marginTop: "-45px" }} src={data?.org?.logo} size={80} ></Avatar>
                    <p className="fs20 fw600">{data?.org?.name}</p>
                    <p className="fs12 text-grey">@{data?.org?.username}</p>
                    <Row className="">
                      <Col span={24}>
                        <p className="fs12 text-grey md5"> {data?.org?.followers} followers </p>
                      </Col>
                      <Col span={24}>
                        <FollowShare profile={{ ...data?.org, ...data }}></FollowShare>

                      </Col>
                    </Row>
                  </div>


                </div>
                {/* <div className="expl-business-card md50 " style={{ border: '1px solid rgba(237, 237, 237, 0.10)', padding: '20px', backgroundColor: 'rgba(237, 237, 237, 0.10)' }}>
                <div>
                  <Row>
                    <Col span={4}>
                      <Avatar size={40} ></Avatar>
                    </Col>
                    <Col span={20}>

                      <p className="fs14 fw600">Abhay Braja</p>
                      <p className="fs11 text-grey">@abhaybraja</p>
                    </Col>
                  </Row>
                  <Row className="md20">
                    <Col span={24} className="md20">
                      
                      <FollowShare profile={{...data?.org,...data}}></FollowShare>
                    </Col>
                  </Row>
                </div>


              </div> */}
              </Col>
            </Row>
          }

        </Col>

        <Modal centered open={this.state.creditModal} closable={false} onCancel={() => this.setState({ creditModal: false })} footer={false} >
          <PaymentCreditPage onChange={(val) => this.onCreditUpdate(val)}
            title={data?.title} entity={data?.entity_type}
            amount={data?.price}
            entity_type={data?.entity_type}
            id={this.state.entity_uuid}
          ></PaymentCreditPage>
        </Modal>
        <Modal title={"Post Analytics"} open={this.state.modal} footer={false} onCancel={() => this.setModal(false)} maskClosable={false}>
          <Row>
            <Col span={22} offset={1}>
              <EntityAnalytics item={data} />
            </Col>
          </Row>

        </Modal>
      </Row>

    );
  }
}

const mapStateToProps = (state) => ({
  _newsletterdetails: state.newsletter.newsletterdetail,
  _comments: state.newsletter.all_comments, _payment_link: state.channel.channel_payment,
});
const mapDispatchToProps = (dispatch) => ({
  NewsletterDetails: (v) => dispatch(actions.NewsletterDetails(v)),
  totalComments: (v) => dispatch(actions.totalComments(v)),
  submitComment: (v) => dispatch(actions.submitComment(v)),
  votePoll: (v) => dispatch(actions.votePoll(v)),
  likeFeed: (v) => dispatch(actions.likeFeed(v)),
  SaveFeed: (v) => dispatch(actions.SaveFeed(v)),
  paytoSubcribe: (v) => dispatch(actions.paytoSubcribe(v)),
  setEntityInStore: (v) => dispatch(actions.SetEntityInStore(v)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsletterDetails)
);
