import React, { useEffect, useRef, useState } from "react";
import { Comment, Avatar, Form, Button, List, Input, Row, Col, Spin, Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import useToken from "../../store/useToken";
import Toast from "../utils/Toast";

const { TextArea } = Input;

const EntityComments = (props) => {
    const [comments, setComments] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [profile, setProfile] = useState({});
    const webSocket = useRef(null);
    const { token } = useToken();
    const [value, setValue] = useState('');

    const history = useHistory();

    useEffect(() => {

        const _profile = JSON.parse(localStorage.getItem("profile"));
        setProfile(_profile);

        webSocket.current = new WebSocket(process.env.REACT_APP_WS_BASE_URL + `wss/feed?euid=${props?.item?.entity_uuid}&token=${token}`);
        webSocket.current.onmessage = evt => {
            const message = JSON.parse(evt.data);
            const parsedComments = [];
            message.map((i) =>
                parsedComments.push({
                    author: <a href="#">{i[1]}</a>,
                    avatar: <Avatar src={i[2]}></Avatar>,
                    content: <p>{i[3]}</p>,
                    datetime: moment(i[4]).fromNow(),
                })
            );
            if (scrolling && message.length < 10) {
                setHasMore(false);
            }
            if (scrolling) {
                setComments(old => [...old, ...parsedComments]);
            } else {
                setComments(old => [...parsedComments, ...old]);

            }
            setLoading(false);
        }

        return () => webSocket.current.close();
    }, []);

    useEffect(() => {
        try{
            props?.commentCount(comments.length);
        }catch{

        }        
    }, [comments]);


    const CommentList = ({ comments }) => (
        <List
            dataSource={comments}
            itemLayout="horizontal"
            renderItem={item => <List.Item key={item.id}>
                <Comment {...item} />
            </List.Item>
            }
        />
    );

    const handleChange = e => {
        e.preventDefault();
        setValue(e.target.value);
    };

    const handleSubmit = async () => {
        if (!value) {
            return;
        }
        if (token === false) {
            Toast("Please login to comment!", "error")
            return
        }

        setSubmitting(true);
        const msg = { msg: value.trim() }
        await webSocket.current.send(JSON.stringify(msg));
        setValue('');
        setSubmitting(false);
        setScrolling(false);

    };


    const handleInfiniteOnLoad = async () => {
        setScrolling(true);
        setLoading(true);
        console.log("callback triggered API");

        // if (data.length > 14) {
        //     message.warning('Infinite List loaded all');
        //     setLoading(false);
        //     setHasMore(false);
        //     return;
        // }
        const msg = { offset: offset }
        await webSocket.current.send(JSON.stringify(msg));
        setLoading(false);
        setOffset(offset + 10);
    };

    return <Row>
            <Col span={24}>
            <Comment
                    avatar={
                        <Avatar
                            src={profile?.img_url}
                            alt={profile?.full_name}
                        />
                    }
                    content={
                        <>
                            <Form.Item >
                                <TextArea placeholder={'Add a comment'} rows={1} maxLength={250} onChange={handleChange} value={value} />
                            </Form.Item>
                            <Form.Item className="text-end mb-2">
                                <Button htmlType="submit" loading={submitting} onClick={handleSubmit} type="primary">
                                    Add Comment
                                </Button>
                            </Form.Item>
                        </>
                    }
                />
                <Divider className="m-0"></Divider>

                {comments.length > 0 ?
                    <div className="comments-infinite-container">
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={!loading && hasMore}
                            useWindow={false}
                        >
                            <CommentList comments={comments} />
                            {loading && hasMore && (
                                <div className="comments-loading-container">
                                    <Spin />
                                </div>
                            )}
                        </InfiniteScroll>
                    </div> :
                    <div className="text-center py-5" style={{height:"150px"}}>
                        <h5>No comments found</h5>
                        <p className="text-grey">You'll be the first, If you add a comment on this post.</p>
                    </div>
                }
                
            </Col>
        </Row>
}
export default EntityComments;