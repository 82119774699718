import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { combineReducers } from "redux";
import Results from "./CorporateResultsReducer"
import Public from "./PublicApiReducer"
import Login from "./LoginReducer"
import Register from "./RegisterReducer"
import Channel from "./ChannelReducer"
import Newsletter from "./NewsletterReducer"
import Profile from "./ProfileReducers"
import Gamify from "./GamifyReducer"
import Streaming from "./StreamingReducer";
import PodcastReducer from './PodcastReducer';
import EntityReducer from './EntityReducer';

export default combineReducers({
    results : Results,
    publics : Public,
    login : Login,
    register : Register,
    channel:Channel,
    newsletter : Newsletter,
    profile : Profile,
    gamify:Gamify,
    stream:Streaming,
    podcast:PodcastReducer,
    entity:EntityReducer
});

export const useSelector = useAppSelector;
export const useDispatch = () => useAppDispatch();
