import { Avatar, Badge, Button, Col, Drawer, Modal, Row, Space, Tabs, Tag, Tooltip } from "antd";
import { Airplay, Export, GraduationCap, SealCheck } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import BusinessAbout from "./BusinessAbout";
import { useParams, useHistory, useLocation, withRouter } from "react-router";
import useToken from "../../store/useToken"
import axios from "../../store/axios";
import BusinessSkeleton from "./BusinessSkeleton";
import { RWebShare } from "react-web-share";
import NewChannels from "../MainDesktop/NewChannels";
import _ from "lodash";
import BusinessTimeline from "./BusinessTimeline";
import ManagePage from "./ManagePage";
import BusinessConnections from "./BusinessConnections";
import { CAMPUS_WEB } from "../../utils";
import { connect } from "react-redux";
import actions from "../../store/actions/index";

const { TabPane } = Tabs

const BusinessPage = (props) => {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const [username, setUN] = useState(params['username']);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingCh, setLoadingCh] = useState(true);
    const [channels, setAllChannels] = useState([]);
    const { token } = useToken();
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [loadingF, setLoadingF] = useState(false);



    useEffect(() => {
        if (token === false) {
            history.push(`/login?redirect=${location.pathname}`);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (data !== null) {
            if (data.exists === false) {
                return history.goBack();
            }
            setLoading(false);
            getChannels(data?.user_code);
        }
    }, [data]);

    const fetchData = async () => {
        await axios({
            method: 'get',
            url:  `v1.0/business/${username}`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setData(data);
        })
    };

    const reload = () => {
        fetchData();
    };

    async function getChannels(user) {
        setLoadingCh(true);
        await axios({
            method: 'get',
            url:  'channel/list/public?user_code=' + user,
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            setAllChannels(res.data.data.public)
        });
        setLoadingCh(false);
    }

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const followUnFollowBusiness = async (key, type) => {
        setLoadingF(true);
        let payload = {
            following_user_code: data?.user_code,
            follower: key
        }
        let method = type ? type : 'post'
        props.FollowUnFollowUser({ method, data: payload }).then(() => {
            fetchData();
        });
        setLoadingF(false);
    };

    const goToLink = (key) => {
        if (key === "4"){
            window.location.href = `${CAMPUS_WEB}campus/${_.find(data.actions, { icon: 'GraduationCap' }).slug}`;
        }
    }

    return (

        loading ? <BusinessSkeleton /> :
            <>
                <Row className="m-minute10-marginx">
                    <Col span={24} className="business-img" style={data.banner_url !== null ? { backgroundImage: `url(${data.banner_url})` } : {}}>
                        <div className="text-right md30 padding10">
                            <Tag className="fs12 fw500 text-white tag-p20 me-0" style={{ background: 'linear-gradient(90deg, #463660 0%, #996488 100%)' }}> Business </Tag>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
                        <div style={{ marginTop: '-95px' }}>
                            <Avatar shape="square" style={{ border: "none", backdropFilter: "blur(11px)", borderRadius: "50%" }} src={data?.logo} size={140}></Avatar>
                        </div>
                        <Row className="md25">
                            <Col xs={24} md={16}>
                                <h1 className="mb-0"> {data?.name} </h1>
                                <p className="fs14 fw600 dark-grey m-0"> @{data?.username} </p>
                                <p className="fs14 fw400 md20 dark-grey">
                                    {data?.about}  </p>
                            </Col>
                            <Col xs={24} md={8} className="md25 text-right m-text-left">
                                <Space className="text-center" size={10}>

                                    {
                                        data.actions.map((item, k) => {
                                            return (
                                                item.icon === 'GraduationCap' ? <Tooltip key={k} title={"Campus"}>
                                                    <Button type="link" key={k} href={`${CAMPUS_WEB}campus/${item.slug}`} icon={<GraduationCap size={20} color="#C71FE2" weight="light" />}
                                                        style={{ backgroundColor: '#c71fe238', height: '35px', width: '35px', border: '1px solid #c71fe238' }}></Button>
                                                </Tooltip> : item.icon === 'Television' ? <Tooltip key={k} title="TV">
                                                    <Button key={k} onClick={() => history.push(`/tv/${item.slug}`)} icon={<Airplay size={20} color="#2E5CFF" weight="regular" />} style={{ backgroundColor: '#2e5cff45', height: '35px', width: '35px', border: '1px solid #2e5cff45' }}></Button>
                                                </Tooltip> : <></>
                                            )
                                        })
                                    }

                                </Space> <br />
                                <Space className="md25" align="center" size={5}>
                                    <p className="fs14 fw600 "> {data.stats.followers} <span className="dark-grey"> Followers </span></p>
                                    <Badge status="default"></Badge> <p className="fs14 fw600 "> {data.stats.followings}<span className="dark-grey"> Following </span></p>
                                </Space><br />
                                <Space className="md25" size={16}>
                                    {
                                        data.amiowner ? <Button type="primary" onClick={showDrawer} > Edit </Button> :
                                            data.following ?
                                                <Button loading={loadingF} onClick={() => followUnFollowBusiness(true, "delete")} type="dashed"> Following </Button>
                                                : <Button loading={loadingF} onClick={() => followUnFollowBusiness(false, "post")} type="primary"> Follow </Button>
                                    }

                                    {/* <Button type="dashed"> Message </Button> */}
                                    <RWebShare
                                        data={{
                                            text: data.about,
                                            url: window.location,
                                            title: data.name,
                                        }}

                                    ><Button onClick={(e) => e.preventDefault()} type="text" className="text-grey" icon={<Export size={22}></Export>}></Button>
                                    </RWebShare>

                                </Space>


                            </Col>
                        </Row>
                        <Row className="m-minute10-marginx">
                            <Col span={24} className="md30 mb-5" style={{ minHeight: "80vh" }}>
                                <Tabs defaultActiveKey="1" onChange={goToLink}>
                                    <TabPane tab="About" key="1">
                                        <BusinessAbout data={data} />
                                    </TabPane>
                                    <TabPane tab="Timeline" key="2">
                                        <BusinessTimeline data={data} />
                                    </TabPane>
                                    <TabPane tab="Channels" key="3">
                                        <Row className="business-about-card md15 m-p10">
                                            <Col xxl={{ span: 16, offset: 4 }} md={{ span: 14, offset: 5 }} xs={{ span: 24, offset: 0 }}>
                                                <NewChannels channels={channels} loader={loadingCh} />
                                            </Col>
                                        </Row>

                                    </TabPane>
                                    {
                                        _.find(data.actions, { icon: 'GraduationCap' }) &&
                                        <TabPane tab="Campus" key="4">

                                            {/* <MainCampus page={true} slug={_.find(data.actions, { icon: 'GraduationCap' }).slug} /> */}

                                        </TabPane>
                                    }
                                    <TabPane tab="Connections" key="5">

                                        <BusinessConnections parent={data}></BusinessConnections>

                                    </TabPane>

                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                    <Drawer
                        title="Update Page"
                        width={720}
                        onClose={onClose}
                        open={open}
                        className="business"
                        bodyStyle={{
                            paddingBottom: 80,
                            
                        }}>
                        <ManagePage page={data} reload={reload} />
                    </Drawer>
                    <Modal title="Follow more" open={modalOpen} onCancel={() => setModalOpen(false)} footer={false}>
                        <BusinessConnections parent={{ ...data, inModal: true }} ></BusinessConnections>
                    </Modal>
                </Row>
            </>
    );
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
    FollowUnFollowUser: (v) => dispatch(actions.FollowUnFollowUser(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BusinessPage)
);