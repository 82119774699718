import React, { useState } from "react";
import { Col, Row, Button, Radio, Select, Input, Avatar, Space, message, Upload } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { ListPlus, X } from "@phosphor-icons/react";
import { useHistory } from "react-router";
import CoinInput from "../Input/CoinInput";

import Toast from "../utils/Toast";

const { Option } = Select;
const { TextArea } = Input;



const CreateMentorship = () => {

    const [loading, setLoading] = useState(false);
    const [step, setstep] = useState(1)
    const [name, setname] = useState("")
    const [description, setdescription] = useState("")
    const [amount, setamount] = useState(0)
    const [type, settype] = useState(1)
    const [mentorship_relation, setmentorship_relation] = useState(1)
    const [venue, setvenue] = useState("")
    const [tenure, settenure] = useState("1")
    const [tags, setags] = useState([])
    const [title_img, settitle_img] = useState([])
    const [event_type, setevent_type] = useState(1)
    const [addItem, setAddItem] = useState(false);
    const [learningsArr, setLearningsArr] = useState([])
    const [itemValue, setItemValue] = useState("")
    const [imageUrl, setImageUrl] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    const key = 'creatementor';

    const history = useHistory();

    const CreateMentorship = async () => {

        if (name === "" || description === "" || type === "" || mentorship_relation === "" || tenure === "") {
            return
        }
        if (title_img.length < 1) {
            Toast("Upload image!", "error")
            return
        }

        messageApi.open({
            key,
            type: 'loading',
            content: 'Uploading...',
        });
        setLoading(true);
        const formData = new FormData();
        title_img.forEach(file => {
            formData.append('title_img', file);
        });

        formData.append('name', name);
        formData.append('description', description);
        formData.append('amount', amount);
        formData.append('type', type);
        formData.append('amount', amount);
        formData.append('mentorship_relation', mentorship_relation);
        formData.append('tenure', tenure);
        formData.append('venue', venue);
        for (let i = 0; i < learningsArr.length; i++) {
            formData.append('syllabus', learningsArr[i]);
        }
        for (let j = 0; j < tags.length; j++) {
            formData.append('tags', tags[j]);
        }

        await axios({
            method: 'post',
            url:  "v1.0/mentorship/new",
            data: formData,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((resp) => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Success!',
                duration: 3,
            });
            history.push(`/mentorship/${resp.data.data.id}`);
        }).catch((err) => {
            messageApi.open({
                key,
                type: 'error',
                content: err.response?.data?.message,
            });
        })
        setLoading(false);
    }


    const pushinArray = (item) => {

        setAddItem(false)
        if (item === "") {
            return
        }

        let arr = [...learningsArr]
        arr.push(item)
        setLearningsArr(arr)
        setItemValue("")
    }

    const deleteItem = (index) => {
        let arr = [...learningsArr]
        arr.splice(index, 1)
        setLearningsArr(arr)
    }

    const nextStep = (step) => {
        if (step === 2) {
            if (name === "") {
                Toast("Enter Title/Subject of mentorship !!", "error")
                return
            }
            if (tenure === "") {
                Toast("Enter Program Tenure !!", "error")
                return
            }
            // if (!isLive && streaming_link === "") {
            //     Toast("Enter Registration/Streaming link !!","error")
            //     return
            // }
            if (event_type === 1 && amount === 0) {
                Toast("Enter Price of Mentorship !!", "error")
                return
            }
            setstep(step)

        }
        else {
            if (description === "") {
                Toast("Enter Description of Mentorship!!", "error")
                return
            }
            if (title_img.length === 0) {
                Toast("Upload Image!!", "error")
                return
            }

        }

    }
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const propsEvent = {
        accept: ".png,.jpg,.JPEG",
        onRemove: file => {
            const index = title_img.indexOf(file);
            const newFileList = title_img.slice();
            newFileList.splice(index, 1);
            settitle_img(newFileList);
            setImageUrl("");
        },
        beforeUpload: file => {
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                Toast("You can only upload JPG/PNG file!", "error");
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                Toast("Image must smaller than 5MB!", "error");
                return;
            }
            getBase64(file, (url) => {
                setImageUrl(url);
            });
            settitle_img([...title_img, file]);
            return false;
        },
        title_img,
    };


    return (
        <Row>


            <Col lg={{ span: 10, offset: 7 }} xs={{ span: 22, offset: 1 }} className='md50' >
                <Row>
                    <Col span={24} className='text-center'>
                        <p className="fs16 fw600 "> Create 1-to-1 Mentorship Program </p>
                    </Col>

                </Row>
                {
                    step === 1 ? <>

                        <Row className='md20 create-event-radio'>


                            <Col span={24} className=''>
                                <p className="fs16 fw400 ">What type of mentorship?</p>
                            </Col>
                            <Col span={24} className="m-align-explore md25">
                                <Radio.Group value={type} onChange={(evt) => settype(evt.target.value)}>
                                    <Radio value={1} className="category-card category-border  text-center m-md10 ">
                                        <Avatar shape="square" size={35} src={require('../../assets/images/newdashboard/newdigital.svg').default} alt="" />
                                        <p className="fs14 fw400 text-grey md5"> Online</p>
                                    </Radio>

                                    <Radio value={2} className="category-card ms-3 category-border text-center m-md10 " >
                                        <Avatar shape="square" size={35} src={require('../../assets/images/newdashboard/offline.svg').default} alt="" />
                                        <p className="fs14 fw400 text-grey md5"> Offline</p>
                                    </Radio>

                                </Radio.Group>

                            </Col>

                        </Row>
                        <Row className='md20'>
                            <Col span={24}>
                                <p className="fs16 fw400 mb-2">Title/ Subject of the mentorship</p>
                                <Input value={name} onChange={(evt) => setname(evt.target.value)} placeholder="Enter mentorship title" block maxLength={100} showCount />
                            </Col>

                            {/* <Col lg={{ span: 12 }} xs={{ span: 24 }} className="ps-4 m-ps0 m-md20">
                                <p className="fs16 fw400">Mentorship</p>
                                <Space className="md10 radio">
                                    <Radio.Group value={mentorship_relation} defaultValue={1} onChange={(evt) => setmentorship_relation(evt.target.value)}>
                                        <Radio value={1}>One-to-One</Radio>
                                        <Radio value={2} disabled>Group <small>(Coming Soon)</small></Radio>
                                    </Radio.Group>
                                </Space>
                            </Col> */}

                            <Col span={24} className="md20">
                                <p className="fs16 fw400">Program of</p>
                                <Select key="age_group" placeholder={"Select Tenure"} value={tenure} onChange={(evt) => settenure(evt)}
                                    className="md10" style={{ width: '100%' }} size="large">
                                    <Option value="1">1 Month</Option>
                                    <Option value="3">3 Months</Option>
                                    <Option value="6">6 Months</Option>
                                    <Option value="12">12 Months</Option>
                                    <Option value="24">24 Months</Option>
                                </Select>
                            </Col>

                            {
                                type === 2 && <Col span={24} className={"md20"}>
                                    <p className="fs16 fw400">Mentorship Venue</p>
                                    <TextArea size="large" autoSize value={venue} onChange={(evt) => setvenue(evt.target.value)} showCount placeholder='Enter venue' maxLength={150} className='md10' />
                                </Col>
                            }
                        </Row>
                        <Row className="md20">
                            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                <p className="fs16 fw400">Program fee</p>
                                <Space className="md10 radio">
                                    <Radio.Group value={event_type} onChange={(evt) => setevent_type(evt.target.value)} >
                                        <Radio value={1}>Paid</Radio>
                                        <Radio value={2}>Free</Radio>
                                    </Radio.Group>

                                </Space>
                            </Col>
                            {
                                event_type === 1 && <Col lg={{ span: 12 }} xs={{ span: 24 }}>

                                    <p className="fs16 fw400 "> Price</p>
                                    <CoinInput className="md10" value={amount} onChange={(value) => setamount(value)} />
                                    {/* <small className="fw400 md5 text-grey">*20% commission will be charged</small> */}
                                </Col>
                            }




                        </Row>
                        <Row className="md70">
                            <Col span={23} className='text-center mb-5'>
                                <Space>

                                    <Button type="dashed" onClick={() => history.goBack()}>Close</Button>
                                    <Button type="primary" onClick={() => nextStep(2)}>Save & Next</Button>

                                </Space>
                            </Col>
                        </Row>

                    </> : <>
                        <Row className='md20'>
                            <Col span={24}  >
                                <Avatar size={24} shape='square' className={"cursor ps-1"} onClick={() => setstep(1)} src={<svg width="24" vertical-align="unset" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.45 21.5L17 19.95L9.05 12L17 4.05L15.45 2.5L6 12L15.45 21.5Z" fill="#676767" />
                                </svg>
                                }></Avatar>
                            </Col>
                            <Col span={24} className="md20" >
                                <p className="fs16 fw400" > Description</p>
                            </Col>
                            <Col span={24} >
                                <TextArea size="large" value={description} onChange={(evt) => setdescription(evt.target.value)} width={50} showCount placeholder='Enter mentorship description' maxLength={1000} className='md10' />
                            </Col>
                        </Row>
                        <Row className="md20">
                            <Col span={24}  >
                                <p className="fs16 fw400" > Few Learnings</p>
                            </Col>
                            <Col span={24} className="md20">
                                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.05)", borderRadius: '8px', padding: "20px" }}>
                                    <ul>
                                        {
                                            learningsArr.map((item, i) => {
                                                return <li key={i}><Row key={i}>
                                                    <Col span={22}>
                                                        {item}
                                                    </Col>
                                                    <Col span={2} className="text-right">
                                                        <Button onClick={() => deleteItem(i)} type={"text"} icon={<X />}></Button>
                                                    </Col>
                                                </Row></li>

                                            }
                                            )
                                        }
                                    </ul>


                                    {
                                        addItem ? <div className="md10">
                                            <Input onChange={(e) => setItemValue(e.target.value)} placeholder="Enter topic..." maxLength={100} showCount></Input>
                                            <Row className="md10">
                                                <Col span={24} className="text-end">
                                                    <Space>
                                                        <Button onClick={() => setAddItem(false)} type="dashed">Close</Button>
                                                        <Button onClick={() => pushinArray(itemValue)} type="primary">Add</Button>
                                                    </Space></Col>
                                            </Row>
                                        </div> : <Button onClick={() => setAddItem(true)} icon={<ListPlus />} type="primary"><span className="ms-2"></span> add item</Button>
                                    }


                                </div>
                            </Col>
                        </Row>
                        <Row className="md20" gutter={20}>
                            <Col span={24}  >
                                <p className="fs16 fw400" > Upload image</p>
                                <Space align="start" wrap>
                                    <Upload
                                        className="md10"
                                        listType="picture-card"
                                        showUploadList={false}
                                        {...propsEvent}
                                    >
                                        {imageUrl ? (
                                            <img
                                                src={imageUrl}
                                                alt="avatar"
                                                style={{
                                                    width: '100%',
                                                    height: "100px"
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                    narginLeft: '20px'
                                                }}
                                            >
                                                Upload <br /> Image
                                            </div>
                                        )}

                                    </Upload>

                                    <ul className="md10 text-grey">
                                        <li className="fs11 fw400" >Image must be in jpg, jpeg or png format.</li>
                                        <li className="fs11 fw400" > Image size should not be greater than 500 Kb.</li>
                                        <li className="fs11 fw400" > Not be greater than height: 300px and width: 300px.</li>
                                    </ul>
                                </Space>
                            </Col>
                            <Col span={24} className='md20' >
                                <p className="fs16 fw400" > Tags (optional)</p>

                                <Select mode="tags" value={tags} style={{ width: '100%', marginTop: "10px" }} placeholder="Tags Mode" onChange={(evt) => setags(evt)}>
                                    {tags}
                                </Select>

                            </Col>
                        </Row>
                        <Row className="md30 mb-5">
                            <Col span={24} className='text-center'>
                                <p className="fs14 fw400 " >Make sure your mentorship content meets our <a href="https://syllo.co/privacy.html" className="fw600">guidelines.</a></p>
                            </Col>
                            <Col span={24} className='text-center md35'>
                                <Space>
                                    <Button type="dashed" onClick={() => setstep(1)} > Back </Button>
                                    <Button loading={loading} type="primary" className="ms-2" onClick={() => CreateMentorship()}>Create & Publish</Button>
                                    {contextHolder}
                                </Space>

                            </Col>
                        </Row>

                    </>
                }

            </Col>

        </Row>


    );
}
export default CreateMentorship;