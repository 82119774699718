import React, { useEffect } from 'react';

const RedirectComponent = ({ to }) => {
    useEffect(() => {
        // Redirect to the desired URL
        window.location.href = `${process.env.REACT_APP_SYLLOLIVE_WEB}${to}`;
    }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount()

    return (
        <div>
            <p>Redirecting ...</p>
            {/* You can also provide a link as a fallback */}
            <a href={`${process.env.REACT_APP_SYLLOLIVE_WEB}${to}`}>Click here if you are not redirected automatically</a>
        </div>
    );
};

export default RedirectComponent;
