import React from "react";
import { Avatar, Dropdown, Menu, Modal, Input, Upload, Button, notification, Space, Col, Row, Drawer, Badge, Tooltip, Popover, Divider } from "antd";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { CloseOutlined } from "@ant-design/icons";
import Cookies from "js-cookie"
import '../../assets/css/responsive.scss'
import "../../assets/css/style.scss";
import _ from "lodash";
import axios from "../../store/axios";
import Notification from "../Notification/Notification";
import {
  Article, GearSix, Newspaper, Note, Notebook, SignOut, User, Nut, Image,
  BookmarkSimple, GridFour, Compass, House, Megaphone, ChartBarHorizontal, CalendarPlus, Microphone,
  GraduationCap, Television, GameController, PlusCircle, List, ChatsTeardrop, Bell, CalendarBlank, ChatTeardrop, CaretDown, Gift
} from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import request from '../../store/request/index'
import ContentAdvancedFeature from "../utils/ContentAdvancedFeature";
import PostEditor from "../Editor/PostEditor";

import Toast from "../utils/Toast";
import CampFooter from "../CampusV2/CampFooter";
import CreatePoll from "./CreatePoll";
import { CAMPUS_WEB } from "../../utils";
import SwitchButton from "./SwitchButton";


export class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      code: "",
      visible: false,
      todaythought: "",
      research: false,
      report_tags: [],
      title: "",
      content: "",
      img: null,
      messages: [],
      ws: new WebSocket(process.env.REACT_APP_WS_URL_NOTIFICATION + "wss/notification?t=" + Cookies.get("Logintoken")),
      role: Cookies.get("__role"),
      poolsModal: false,
      DropdownCollapse: false,
      notification_count: 0,
      visibleDrawer: false,
      load_channel_once: true, total_tags: [], price: 0, premium: false, switch: false, img_url: "",
      new_message: false, loader: false, advancedFeature: false, advancedPayload: {}, file_uploading: false, wallet: {},
      currentProfile: 'default', profile_data: {},
    };
  }


  componentDidMount() {
    var url = JSON.parse(localStorage.getItem("profile"));
    if (url === undefined || url === null || (url && url.length === 0)) {
      let route = "/account/user/profile"
      this.props.Profile(route).then(() => {
        localStorage.setItem("profile", JSON.stringify(this.props._profile));
        this.setState({
          name: this.props._profile.full_name,
          code: this.props._profile.user_code,
          img: this.props._profile.img_url,
          role: this.props._profile.role,
          new_message: this.props._profile?.new_message,
          profile_data: this.props._profile
        });
      });
    }
    else {
      this.setState({
        name: url?.full_name,
        code: url?.user_code,
        img: url?.img_url, role: url?.role,
        new_message: url?.new_message,
        profile_data: url,
        role: url?.role,
      });
      // set current profile from local storage
      const _current_profile = localStorage.getItem("current");
      if (_current_profile === "business") {
        this.setState({ currentProfile: 'business' });
      }
    }


    this.state.ws.onmessage = evt => {
      const message = JSON.parse(evt.data)
      var messages = this.state.messages.concat(message);

      this.openNotificationWithIcon((message.severity).toLowerCase(), message.message, message.description)

      this.setState(state => ({ messages: messages, loader: false, notification_count: this.state.notification_count + 1 }))
    }

    // this.state.ws.onclose = () => {
    //   this.setState({
    //     ws: new WebSocket(process.env.REACT_APP_WS_URL_NOTIFICATION + "wss/notification?t=" + Cookies.get("Logintoken"))
    //   })
    // }


    // this.fetchNotifications(); uncomment this line in production
    this.fetchWallet();
  }

  openNotificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
      top: 100,
    });
  };


  PostFeed(evt) {
    evt.preventDefault();
    this.setState({
      loading: true
    })
    if (this.state.advancedPayload && this.state.advancedPayload?.price > 0 && this.state.title === "") {
      Toast("Title Required!", "error")
      return
    }
    if (this.state.todaythought === "") {
      Toast("Write Some Thoughts!", "error")
      return
    }

    this.props
      .PostFeed({
        "image_url": this.state.img_url,
        content: this.state.todaythought,
        title: this.state.title,
        ...this.state.advancedPayload

      })
      .then(() => {
        sessionStorage.removeItem("image")
        this.setState({
          loading: false
        })
        Toast("Created Successfully!", "success")
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      });
  }


  Logout() {
    Cookies.remove("Logintoken");
    Cookies.remove("CSRFToken");
    Cookies.remove("channels");
    Cookies.remove("user_pic");
    Cookies.remove("user_code");
    Cookies.remove("user_name");
    Cookies.remove("__role");
    localStorage.removeItem("profile");
    localStorage.removeItem("channels");
    window.location.href = "/login";
    // axios({
    //   method: 'post',
    //   url:  'account/auth/logout',

    //   headers: {
    //     'Authorization': Cookies.get("Logintoken"),

    //   },
    // })
    //   .then(() => {
    //     Cookies.remove("Logintoken")
    //     Cookies.remove("_role")
    //     localStorage.removeItem("profile")
    //     localStorage.removeItem("channels")
    //     window.location.href = "/login";
    //   }).catch((error) => {
    //     Toast(error.response.data.message)
    //   });
  }

  PostResearchReport(evt) {
    evt.preventDefault();

    if (this.state.title === "" || this.state.content === "" || this.state.img_url === "") {
      Toast("Provide Details", "error")
      return
    }


    this.props
      .PostResearchReport({
        "title": this.state.title,
        "content": this.state.content,
        "tags": this.state.total_tags,
        "file_url": this.state.img_url,
      })
      .then(() => {
        sessionStorage.removeItem("image")
        setTimeout(() => {
          Toast("Created Successfully!", "success")
          window.location.reload()
        }, 2000);
      });
  }

  profile() {
    if (this.state.currentProfile === 'business' && this.state.profile_data?.connection) {
      window.location.href = (`/business/${this.state.profile_data?.connection?.username}`);
    } else {
      window.location.href = ("/profile/" + this.state.code);
    }
  }

  showDrawer = () => {
    this.setState({ visibleDrawer: true })
  };
  onClose = () => {
    this.setState({ visibleDrawer: false })
  };

  async createNewsLetter() {
    await axios({
      method: 'post',
      url: 'market/feed/newsletter',
      data: {
        title: "Untitled",
        meta_title: ""
      },
      headers: {
        Authorization: Cookies.get("Logintoken"),

      },
    }).then((response) => {
      window.open(`/newsletter/${response.data.data.uid}`, '_self');
    }).error((error) => {
      Toast(error.response.data.message, "error")
    });;
  }


  createNotes() {
    axios({
      method: 'post',
      url: 'market/feed/note',
      data: {
        title: "Untitled",
        meta_title: "",
        tags: [],
      },
      headers: {
        Authorization: Cookies.get("Logintoken"),

      },
    })
      .then((response) => {
        window.location.href = ("/notes?id=" + response.data.data.id)
      }).error((error) => {
        Toast(error.response.data.message, "error")
      });;
  }

  async checkSocialMediaAppConnected(platform) {
    await axios({
      method: 'get',
      url: `lighthouse/app/${platform}/status`,
      headers: {
        Authorization: Cookies.get("Logintoken"),

      },
    }).then((response) => {
      const connected = response.data.data?.connected
      if (platform === "TW") {
        this.setState({ isTwitterConnected: connected });
        if (!connected) {
          this.setState({ isTwitterConnected: connected, postPublishInTwitter: false, pollPublishInTwitter: false });
        }
      }
      else if (platform === "LI") {
        this.setState({ isLinkedInConnected: connected });
        if (!connected) {
          this.setState({ isLinkedInConnected: connected, postPublishInLinkedIn: false });
        }
      }

    });
    this.setState(platform === "TW" ? { twitterStatus: true } : { linkedInStatus: true });

  }
  async fetchWallet() {
    await axios({
      method: 'get',
      url: `v1.0/reward/wallet`,
      headers: {
        Authorization: Cookies.get("Logintoken"),

      },
    }).then((response) => {
      const data = response.data.data
      this.setState({ wallet: data })
    });
  }

  async fetchNotifications() {
    await axios({
      method: 'get',
      url: process.env.REACT_APP_URL_NOTIFICATION + 'notification/get?offset=0&count=10&read=false',
      headers: {
        'Authorization': Cookies.get("Logintoken"),
      },
    }).then((response) => {
      this.setState({
        notification_count: _.filter(response.data.data?.notifications, { status: "UNREAD" }).length
      })
    });
  };

  async switchUserProfileAPI() {
    const _profile = this.state.currentProfile === 'default' ? 'business' : 'default'
    await axios({
      method: 'post',
      url: `account/user/switch_profile`,
      headers: {
        'Authorization': Cookies.get("Logintoken"),
      },
      data: { profile: _profile }
    }).then((response) => {
      const _prof = response.data.data?.is_business ? 'business' : 'default';
      this.setState({
        currentProfile: _prof,
      });
      localStorage.setItem("current", _prof);
      if (_prof === 'business') {
        Toast("Switched to business profile", "success");
      }else{
        Toast("Switched to personal profile", "success");
      }
    });
    window.location.reload();
  }

  connectNowApp(app) {
    this.setState({
      postPublishInTwitter: false, pollPublishInTwitter: false,
      postPublishInLinkedIn: false
    });
    window.open(`/connect/app/${app}`);
  }


  _createitems = (item) => {
    return [
      {
        label: (
          <span>Post</span>
        ),
        key: '4',
        icon: <Article size={20} weight="regular" />
      }, {
        label: (
          <span>Note</span>
        ),
        key: '2',
        icon: <Note size={20} weight="regular" />
      },
      {
        label: (
          <span>Poll</span>
        ),
        key: '5',
        icon: <ChartBarHorizontal size={20} weight="regular" />
      },
      {
        label: (
          <span>Newsletter</span>
        ),
        key: '1',
        icon: <Newspaper size={20} weight="regular" />
      }, {
        label: (
          <span>Report</span>
        ),
        key: '7',
        icon: <Notebook size={20} weight="regular" />
      }, {
        label: (
          <span>Podcast</span>
        ),
        key: '8',
        icon: <Microphone size={20} weight="regular" />
      }, {
        label: (
          <span>Channel</span>
        ),
        key: '3',
        icon: <Megaphone size={20} weight="regular" />
      }, {
        label: (
          <span>Event</span>
        ),
        key: '6',
        icon: <CalendarPlus size={20} weight="regular" />
      },

    ]
  };

  onClickCreate = ({ key }) => {

    if (key === '1') {
      this.createNewsLetter();
      return
    }
    else if (key === '2') {
      this.createNotes();
      return
    }
    else if (key === '4') {

      this.setState({ visible: true, DropdownCollapse: false });
    }
    else if (key === '5') {

      this.setState({ poolsModal: true, DropdownCollapse: false });
    }
    else if (key === '7') {
      window.location.href = "/create-report";
      return
    }
    else if (key === '8') {
      window.location.href = "/publish-podcast";
      return
    }
    else if (key === '3') {
      window.location.href = "/create-channel";
      return
    }
    else if (key === '6') {
      window.location.href = "/event/new";
      return
    }
  };

  onClickDrawerMenu = (route) => {
    if (route === "/campus/me") {
      window.location.href = `${CAMPUS_WEB}campus/me`
      return
    }
    this.setState({ visibleDrawer: false });
    this.props.history.push(route);
  }


  render() {

    const { advancedPayload } = this.state
    const profile = (
      <Menu >
        <Menu.Item className={'border-bottom'} style={{ textTransform: "capitalize" }}>
          <span className={"fw600"} style={{ color: "#4B5669" }}> {this.state.currentProfile === 'business' ? this.state.profile_data?.connection?.name : this.state.name}</span>
        </Menu.Item>
        {
          (this.state.profile_data?.connection?.is_admin) &&
          <Menu.Item >
            <SwitchButton switchUserProfileAPI={() => this.switchUserProfileAPI()} current={this.state.currentProfile} profile_data={this.state.profile_data} />
          </Menu.Item>
        }

        <Menu.Item key="0" onClick={() => this.profile()}>
          <User size={20} weight="regular" style={{ marginRight: "5px" }} /> <span className={"fw300"}> Profile </span>
        </Menu.Item>
        <Menu.Item key="12" > <a href={`${CAMPUS_WEB}campus/me`}><GraduationCap size={20} weight="regular" style={{ marginRight: "5px" }} /> <span className={"fw300"}>Campus</span></a></Menu.Item>
        <Menu.Item key="32" ><a href={"/my-hub"}><GridFour size={20} weight="regular" style={{ marginRight: "5px" }} />
          <span className={"fw300"}> My Hub</span></a></Menu.Item>
        {
          (this.state.currentProfile === 'business'  || this.state.role === 9) &&
          <Menu.Item key="2" ><a href={"/console"}><Nut size={20} weight="regular" style={{ marginRight: "5px" }} />
            <span className={"fw300"}> Console</span></a></Menu.Item>
        }
        <Menu.Item key="3" className={'border-bottom'}><a href={"/settings"}><GearSix size={20} weight="regular" style={{ marginRight: "5px" }} />
          <span className={"fw300"}> Settings</span></a></Menu.Item>


        <Menu.Item key="4" onClick={() => this.Logout()}><SignOut size={20} weight="regular" style={{ marginRight: "5px" }} /> <span className={"fw300"}>Logout</span></Menu.Item>
      </Menu>
    );
    const setImage = (data) => {
      this.setState({ img_url: data })
    }

    const content = (<div>

      <Notification></Notification>

    </div>
    );

    const post_props = {
      name: "file",
      accept: ".jpeg,.png,.jpg",
      action: "account/file/upload",
      data: { type: "post" },

      headers: {
        Authorization: Cookies.get("Logintoken"),

      },
      onRemove: (file) => {
        this.setState({ img_url: "" })
      },
      onChange: (info) => {

        if (info.file.status === "uploading") {
          this.setState({ file_uploading: true })
        }
        if (info.file.status === "done") {
          this.setState({ file_uploading: false });
          setImage(info.file.response.data.url);
        } else if (info.file.status === "error") {
          Toast(`${info.file.name} file upload failed.`, "error");
        }
      },
      customRequest: (options) => request.customRequest(options, "post"),
    };

    return (
      <>
        <Drawer size='default' placement="left" onClose={this.onClose} open={this.state.visibleDrawer} closeIcon={<></>} closable={true} >
          <Row className="md10 mobile-nav-drawer">
            <Col span={24}>

              <img src={require("../../assets/images/Desktop/syllo.svg").default} alt="logo" className="ps-2 md20" style={{ height: "30px", width: "auto" }} ></img>
            </Col>

            <Col span={24}>
              <Divider></Divider>
            </Col>
            <Col span={24}>
              <button onClick={() => this.onClickDrawerMenu('/dashboard')} className="menu"> <House size={24} /> Home</button>
              <button onClick={() => this.onClickDrawerMenu('/explore')} className="menu"> <Compass size={24} /> Explore</button>
              {
                (this.state.role === 8 || this.state.role === 9) &&
                <button onClick={() => this.onClickDrawerMenu('/campus/me')} className="menu"> <GraduationCap size={24} /> Campus</button>
              }
              <button onClick={() => this.onClickDrawerMenu('/bookmarked')} className="menu"> <BookmarkSimple size={24} /> Bookmarks</button>
              {/* <button onClick={() => this.onClickDrawerMenu('/reels/view')}  className="menu"> <Radio size={24} /> Reels</button> */}
              <button onClick={() => this.onClickDrawerMenu('/explore/tv')} className="menu"> <Television size={24} /> Syllo TV</button>
              <button onClick={() => this.onClickDrawerMenu('/rewards')} className="menu"> <Gift size={24} /> Rewards</button>

              <button onClick={() => window.open('https://games.syllo.co/dashboard',)} className="menu"> <GameController size={24} /> Games</button>
              <button onClick={() => this.onClickDrawerMenu('/results')} className="menu"> <CalendarBlank size={24} /> Calendar</button>
              <button className="menu"> <Bell size={24} /> Notification</button>
              <button onClick={() => this.onClickDrawerMenu('/messenger')} className="menu"> <ChatTeardrop size={24} /> Messages</button>

              {/* <Menu mode="inline" className="md10 m-drawer">
              <Menu.Item key="1" onClick={() => this.props.history.push('/dashboard')}  >
                <a className="sub-text">
                  <Space>
                    <House size={27} weight="thin" />
                    <p>Home</p>
                  </Space><br></br>
                </a>


              </Menu.Item>

              <Menu.Item key="3" onClick={() => this.props.history.push('/explore')}>
                <a className="sub-text">
                  <Space>
                    <Compass size={27} weight="thin" />
                    <p> Explore</p>
                  </Space>
                  <br></br>
                </a>

              </Menu.Item>
              {
                (this.state.role === 8 || this.state.role === 9) && <Menu.Item key="2" onClick={() => this.props.history.push('/campus-update')}>
                  <a className="sub-text  ">
                    <Space className="md10">
                      <GraduationCap size={27} weight={"thin"} />
                      <p> Campus </p>

                    </Space>  <br></br>

                  </a>
                </Menu.Item>
              }

              <Menu.Item key="6" onClick={() => this.props.history.push('/bookmarked')} >
                <a className="sub-text ">
                  <Space>
                    <BookmarkSimple size={26} weight="thin" />
                    Bookmarks
                  </Space>
                  <br></br>

                </a>
              </Menu.Item>
              <Menu.Item key="7" onClick={() => this.props.history.push('/reels/view')} >
                <a className="sub-text">
                  <Space>
                    <Radio size={27} weight="thin" />
                    Reels
                  </Space>

                  <br></br>

                </a>
              </Menu.Item>
              <Menu.Item key="8" onClick={() => this.props.history.push('/explore/tv')} >
                <a className="sub-text">
                  <Space >
                    <Television size={27} weight={"thin"} />
                  <p>   Syllo TV </p>
                  </Space>
                  <br></br>

                </a>
              </Menu.Item>

              <Menu.Item key="4" onClick={() => this.props.history.push('/rewards')} >
                <a className="sub-text">
                  <Space >
                    <Gift size={30} weight={"thin"} />
                    <p> Rewards </p>
                  </Space>
                  <br></br>

                </a>
              </Menu.Item>

              <Menu.Item key="9">
                <a href="https://games.syllo.co/dashboard" className="sub-text">
                  <Space >
                    <GameController size={26} weight="thin" />
                    Game
                  </Space>
                  <br></br>

                </a>
              </Menu.Item>
              <Menu.Item key="10" onClick={() => this.props.history.push('/results')} >
                <a className="sub-text">
                  <Space className="" >
                    <CalendarBlank size={27} weight={"thin"} />
                    Calendar
                  </Space>
                  <br></br>

                </a>
              </Menu.Item>
              <Menu.Item key="11" onClick={() => this.props.history.push('/messanger')} >
                <a className="sub-text">
                  <Space className="" >
                    <ChatTeardrop size={27} weight={"thin"} />
                    Messages
                  </Space>
                  <br></br>

                </a>
              </Menu.Item>
            </Menu> */}
            </Col>
            <Col span={24}>

              <CampFooter />
            </Col>
          </Row>
          <div style={{ position: "absolute", bottom: "0", backgroundColor: "white", padding: "20px 0", width: "calc(100% - 25px)" }}>
            <Space>
              <Button type="primary" ghost>
                Send Feedback
              </Button>
              <Button type="link">
                Help
              </Button>
            </Space>
          </div>
        </Drawer>

        <Row className={this.props?.className ? this.props.className + " header" : "header"} >

          <Col lg={{ span: 0 }} xs={{ span: 4 }} className={"m-show"}>
            <List className="cursor" size={30} weight="bold" style={{ marginLeft: "12px", zIndex: 10 }} onClick={() => { this.setState({ visibleDrawer: true }) }} />
          </Col>

          <Col lg={{ span: 14 }} xs={{ span: 0 }} className={"m-hide"} >

            <Row >
              <Col span={16} className="ms-2"  >
                <a href="/dashboard">
                  <img src={require("../../assets/images/Desktop/syllo.svg").default} alt="logo" height={"24px"} className={"m-logo"} style={{ marginTop: "4px" }}></img>
                </a>

              </Col>

            </Row>
          </Col>

          {
            this.props.right && <Col lg={{ span: 10 }} xs={{ span: 20 }} style={{ alignSelf: "center" }}>
              <Row >
                <Col lg={{ span: 24 }} xs={{ span: 24 }} className="text-right  m-p0 ps-0 " >
                  <Space size={20} style={{ float: "left" }} className="m-ipadpro-m33 text-center">

                    {
                      (this.state.role === 8 || this.state.role === 9) &&

                      <Dropdown menu={{
                        items: this._createitems(), onClick: ({ key }) => this.onClickCreate({ key: key })
                      }} overlayClassName={"width50"} trigger={["click"]} placement={"bottomRight"} >

                        <Button onClick={(e) => e.preventDefault()} style={{ backgroundColor: "var(--primary-color)", color: "white", padding: "4px 10px" }} icon={<PlusCircle size={20} weight="fill" style={{ marginRight: "8px", marginTop: "-2px" }}></PlusCircle>}> Create</Button>
                      </Dropdown>
                    }
                    {
                      (this.state.wallet?.coins !== undefined && ((this.state.role === 8 || this.state.role === 9) && this.state.currentProfile === 'default')) &&
                      <Tooltip title="Coins">
                        <Button className="credit" onClick={() => this.props.history.push('/coins')}>
                          <Space size={5}>
                            <Avatar style={{ border: "none" }} size={32} src={require('../../assets/images/newdashboard/sylloCoin.svg').default} />
                            <p> {(this.state.wallet.coins).convertToMoney()} </p>
                          </Space>

                        </Button>
                      </Tooltip>
                    }


                  </Space>

                  <Space align="baseline" size={2}>
                    {
                      this.state.wallet?.coins !== undefined && (this.state.role === 7) &&
                      <Tooltip title="Coins">
                        <Button className="credit me-1" onClick={() => this.props.history.push('/coins')}>
                          <Space size={5}>
                            <Avatar style={{ border: "none" }} size={32} src={require('../../assets/images/newdashboard/sylloCoin.svg').default} />
                            <p> {(this.state.wallet.coins).convertToMoney()} </p>
                          </Space>

                        </Button>
                      </Tooltip>
                    }
                    <Link to="/bookmarked" className="margin-right m-margin-right0 m-hide">
                      <Tooltip title='Saved' placement="bottom">
                        <Button type="text" className="header">
                          <BookmarkSimple size={24} color="#4B5669" />
                        </Button>
                      </Tooltip>
                    </Link>
                    <Popover placement="bottomRight" onClick={() => this.setState({ notification_count: 0 })} content={content} trigger="click" overlayClassName="notification-radius " className="margin-right m-margin-right0 m-hide">
                      <Badge count={this.state.notification_count}>
                        <Tooltip placement="bottom" title='Notifications'>
                          <Button type="text" className="header">
                            <Bell size={24} color="#4B5669" />
                          </Button>
                        </Tooltip>
                      </Badge>
                    </Popover>
                    <Link to="/results" className="margin-right m-margin-right0 m-hide ">
                      <Tooltip placement="bottom" title='Calendar'>
                        <Button type="text" className="header">
                          <CalendarBlank size={24} color="#4B5669" />
                        </Button>
                      </Tooltip>
                    </Link>

                    {
                      this.state.currentProfile !== 'business' &&

                      <Link to="/messenger" className="margin-right m-margin-right0 m-hide">
                        <Tooltip placement="bottom" title='Messages'>
                          <Button type="text" className="header">
                            <Badge dot={this.state.new_message}>
                              <ChatsTeardrop size={24} color="#4B5669" />
                            </Badge>
                          </Button>
                        </Tooltip>
                      </Link>
                    }

                    <Dropdown overlay={profile} overlayStyle={{ padding: "10px" }} overlayClassName={"width50 header-dropdown"} trigger={["click"]} placement={"bottomCenter"} >
                      <Avatar className="cursor dp m-ml0" size={50} style={{ marginRight: "20px", marginLeft: "20px", backgroundColor: this.state.img === null ? "#673ab7" : "" }} src={this.state.currentProfile === 'business' ? this.state.profile_data?.connection?.logo : this.state.img} >
                        {this.state.img === null ? this.state.name.substring(0, 1) : ""}
                      </Avatar>
                    </Dropdown>

                  </Space>
                </Col>
              </Row>
            </Col>
          }

          <Modal centered maskClosable={false} open={this.state.visible} closable={true} footer={false} onCancel={() => this.setState({ visible: false, img_url: "" })}>
            <Row className="p-4">
              <Col span={24}>
                {
                  advancedPayload && advancedPayload?.price > 0 && <Input className="post-heading-input fs16 " placeholder="Enter Title" maxLength={50} value={this.state.title} onChange={(evt) => this.setState({ title: evt.target.value })} />
                }

                <PostEditor isMaxLength={true} className="md20" onChange={(evt) => this.setState({ todaythought: evt })}></PostEditor>
                {/* <TextArea className=" md30" rows={3} bordered={true} placeholder="What's in you mind..." showCount maxLength={500} value={this.state.todaythought} onChange={(evt) => this.setState({ todaythought: evt.target.value })} /> */}
              </Col>
              {
                this.state.img_url && <Col span={24} className="md20" style={{ marginBottom: "20px" }}>
                  <div className="modal-img">
                    <img src={(this.state.img_url)} width={'100%'} alt="" />
                    <button className="img-post-icon" onClick={() => {
                      this.setState({ img_url: "" })
                    }}><CloseOutlined /></button>
                  </div>
                </Col>
              }

              {
                this.state.img_url === "" && <Col span={10} className="md20" >
                  <Upload {...post_props} showUploadList={false}>
                    <Tooltip title="Upload picture">
                      <Button loading={this.state.file_uploading} type="dashed" icon={<Image className="me-2" size={18} />}> Upload Image</Button>
                    </Tooltip>
                  </Upload>

                </Col>
              }

              {
                (this.state.role === 8 || this.state.role === 9) &&
                <Col span={this.state.img_url === "" ? 14 : 24} className="text-right md20">
                  <Button className="p-0" type="text" onClick={() => this.setState({ advancedFeature: !this.state.advancedFeature })}
                  > More Options <CaretDown size={18} weight='bold' style={{ marginLeft: "5px" }} rotate={this.state.advancedFeature ? 0 : 90} /></Button>
                </Col>
              }

              <Col span={24}>
                {
                  this.state.advancedFeature && <ContentAdvancedFeature className='md20' onUpdate={(val) => this.setState({ advancedPayload: val })} />
                }

              </Col>

              <Col span={24} className="text-right md30">

                <Space><Button type="dashed" shape='default' className="margin-right" onClick={() => this.setState({ visible: false })}>Cancel</Button>
                  <Button type="primary" className="" loading={this.state.loader} onClick={(evt) => this.PostFeed(evt)} disabled={this.state.todaythought === "" ? true : false}> Post</Button>
                </Space>
              </Col>


            </Row>

          </Modal>



          <Modal title="Create Poll" open={this.state.poolsModal} centered footer={false} closable={false}>
            <CreatePoll onClose={() => this.setState({ poolsModal: false })} />
          </Modal>


        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  _profile: state.publics.profile,
});
const mapDispatchToProps = (dispatch) => ({
  Profile: (v) => dispatch(actions.Profile(v)),
  PostFeed: (v) => dispatch(actions.PostFeed(v)),
  PostResearchReport: (v) => dispatch(actions.PostResearchReport(v)),
  createPool: (v) => dispatch(actions.createPool(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader));
