import React, { useEffect, useState } from "react";
import { Col, Row, Input, Space, Button, Upload, Radio, message, Divider, Select } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import axios from "../../store/axios";
import Cookies from "js-cookie";

import Toast from "../utils/Toast";
import CoinInput from "../Input/CoinInput";
import useToken from "../../store/useToken";

const { TextArea } = Input;
const { Option } = Select;

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};


const CreateCourses = () => {

    const params = useParams();
    const history = useHistory();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [lan, setLan] = useState("English");
    const [language, setLanguage] = useState("English");
    const [amount, setAmount] = useState(null);
    const [banner_file, setBannerFile] = useState(null);
    const [fee, setFee] = useState(true);
    const [title_imgurl, settitle_imgurl] = useState("");
    const [title_img,] = useState([]);
    const [masterTags, setMasterTags] = useState([]);
    const [loaderTags, setLoaderTags] = useState(false);
    const [total_tags, setTotalTags] = useState([]);

    const [level, setLevel] = useState(null);
    const [duration, setDuration] = useState(null);

    const { token } = useToken();

    const [messageApi, contextHolder] = message.useMessage();
    const key = 'eventupdatable';
    const course_id = params["id"]; // if undefined: means creating new course
    const [timerId, setTimerId] = useState(null);
    useEffect(() => {
        if (course_id !== undefined) {
            fetchCourse();
        }
    }, [])

    const fetchCourse = async () => {
        await axios({
            method: 'get',
            url:  `v1.0/course/${course_id}`,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        }).then((response) => {
            const data = response.data.data
            setTitle(data?.title);
            setLan(data?.language);
            setLanguage(data?.language);
            setDescription(data?.description);
            settitle_imgurl(data?.img_url);
            setFee(data?.is_paid);
            if (data?.is_paid) {
                setAmount(data?.amount);
            }
            setTotalTags(data?.tags)
        }).catch(() => {
            history.goBack();
        });
    }


    const uploadButton = (
        title_imgurl ? (
            <img
                src={title_imgurl}
                alt="avatar"
                style={{
                    width: '100%',
                    height: "100%",
                    borderRadius: "10px"
                }}
            />
        ) : (
            <div>
                <PlusOutlined />
                <div
                    style={{
                        marginTop: 8,
                    }}
                >
                    Upload
                </div>
            </div>
        )
    );


    function languageHandler(value) {
        setLan(value)
        if (value !== 'Other') {
            setLanguage(value)
        }

    }
    function feeHandler(value) {
        setFee(value)
        if (!value) {
            setAmount('0')
        }
    }

    async function onSubmit() {
        if (!course_id && (banner_file === "" || banner_file === null)) {
            return message.error("Upload course image.")
        }
        if (title === "" || title === null) {
            return message.error("Enter course title.")
        }
        if (description === "" || description === null) {
            return message.error("Enter course description.")
        }
        if (lan === 'Other' && (language === "" || language === null)) {
            return message.error("Enter course language.")
        }
        if (fee && (amount === null || amount === "0" || amount < 0)) {
            return message.error("Enter course amount.")
        }
        if (duration === null || duration === "") {
            message.error("Enter course duration. i.e. 3 hours")
            return 
        }
        if (total_tags.length > 5 || total_tags.length === 0) {
            return message.error("Please enter atleast 3 tags and maximum 5 tags");
        }
        messageApi.open({
            key,
            type: 'loading',
            content: 'Uploading...',
        });
        const formData = new FormData();
        if (!course_id) {
            // edit
            formData.append('banner_file', banner_file);
        }

        formData.append('title', title);
        formData.append('description', description);
        formData.append('amount', amount);
        formData.append('language', language);
        total_tags.forEach(tag => {
            formData.append('tags', tag);
        })
        if (course_id) {
            formData.append('course_id', course_id);
        }
        await axios({
            method: 'post',
            url:  'v1.0/course',
            data: formData,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        }).then((response) => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Uploaded!',
                duration: 3,
            });

            history.push("/course/" + response?.data?.data?.id + "/lesson")
        }).catch((err) => {
            messageApi.open({
                key,
                type: 'error',
                content: err.response.data.message,
            });
        });
    }

    const propsEvent = {
        accept: ".png,.jpg,.JPEG",
        beforeUpload: file => {
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                Toast("You can only upload JPG/PNG file!", "error");
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                Toast("Image must smaller than 3MB!", "error");
                return;
            }
            getBase64(file, (url) => {
                settitle_imgurl(url);
            });
            setBannerFile(file);
            return false;
        },
        title_img,
    };

    const APICallTags = async (evt) => {
        await axios({
            method: 'get',
            url:  'market/tag/search?key=' + evt,

            headers: {
                Authorization: token,
            },
        }).then((res) => {
            setMasterTags(res.data?.data);
        }).catch((error) => {
            console.log(error);
        });
        setLoaderTags(false);
    };

    const handelSearch = (evt) => {
        setLoaderTags(true);
        // Clear the previous timer
        clearTimeout(timerId);
        // Set a new timer to invoke API call after 500ms
        const newTimerId = setTimeout(() => {
            // Perform your API call here
            APICallTags(evt);
        }, 500);

        // Save the new timer ID
        setTimerId(newTimerId);
    }

    return (


        <Row className="md50">
            <Col lg={{ span: 8, offset: 8 }} xs={{ span: 22, offset: 1 }}>
                <Row>
                    <Col span={24} className="text-center">
                        <p className="fs16 fw500"> {course_id === undefined ? "Create" : "Update"} Mini Course </p>
                        <Divider></Divider>
                    </Col>
                    <Col span={24} className="">
                        <p className="fs14 text-grey fw600"> Upload course image </p>

                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader upload-w100 upload-block md10"
                            showUploadList={false}
                            {...propsEvent}
                        >
                            {uploadButton}
                        </Upload>
                    </Col>
                    <Col span={24} className="md20">
                        <p className="fs14 text-grey fw600"> Course Title </p>
                        <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter course title" className="md10" />
                    </Col>
                    <Col span={24} className="md20">
                        <p className="fs14 text-grey fw600"> Description </p>
                        <TextArea value={description} onChange={(e) => setDescription(e.target.value)} size="large" width={50} showCount placeholder='Enter course description' maxLength={1000} className='md10' />

                    </Col>
                    <Col span={24} className="md20 radio">
                        <p className="fs14 text-grey fw600"> Course Language  </p>
                        <Space className="md15 dashboard-radio ">
                            <Radio.Group value={lan} onChange={(e) => languageHandler(e.target.value)}>
                                <Radio value={'English'}>English</Radio>
                                <Radio value={'Hindi'}>Hindi</Radio>
                                <Radio value={'Other'}>Other</Radio>
                            </Radio.Group>
                        </Space>
                        {
                            lan === 'Other' && <Input value={language} onChange={(e) => setLanguage(e.target.value)} placeholder="Enter language" className="md10" />
                        }

                    </Col>
                    <Col span={24} className="md20 radio">
                        <Row>
                            <Col span={10} >
                                <p className="fs14 text-grey fw600"> Course Fee  </p>
                                <Space className="md15  dashboard-radio">
                                    <Radio.Group value={fee} onChange={(e) => feeHandler(e.target.value)}>
                                        <Radio value={true}>Paid</Radio>
                                        <Radio value={false}>Free</Radio>
                                    </Radio.Group>
                                </Space>
                            </Col>
                            {
                                fee && <Col span={14}>
                                    <p className="fs14 text-grey fw600"> Coins </p>
                                    <CoinInput value={amount} onChange={(value) => setAmount(value)} className="md10" />
                                </Col>
                            }

                        </Row>
                    </Col>
                    <Col span={24} className='md20 category-select'>
                        <Row gutter={[12, 12]}>
                            <Col md={12} xs={24}>
                                <p className="fs14 text-grey fw600">Course Level </p>
                                <Select value={level} defaultValue={2} size="large" className="md10" style={{ width: '100%' }} onChange={(value) => setLevel(value)}>
                                    <Option value={1}>Beginner</Option>
                                    <Option value={2}>Intermediate</Option>
                                    <Option value={3}>
                                        Advanced
                                    </Option>
                                </Select>
                            </Col>
                            <Col md={12} xs={24}>
                                <p className="fs14 text-grey fw600">Course Duration </p>

                                <Input value={duration} onChange={(e) => setDuration(e.target.value)} maxLength={20} placeholder="2 hours" className="md10" />

                            </Col>


                        </Row>


                    </Col>

                    <Col span={24} className='md20 category-select'>
                        <p className="fs14 text-grey fw600">Tags</p>
                        <Select
                            mode="multiple"
                            bordered={false}
                            allowClear
                            size="large"
                            className="md15 fs14"
                            style={{
                                width: '100%',
                            }}
                            placeholder="Enter tags"
                            value={total_tags}
                            onChange={(evt) => setTotalTags(evt)}
                            onSearch={(evt) => handelSearch(evt)}
                            loading={loaderTags}
                        >
                            {masterTags.map((item, i) => {
                                return <Option key={i} value={item}>{item}</Option>
                            })}
                        </Select>
                    </Col>
                    <Col span={24} className='text-end md10 mb-5'>
                        <Divider></Divider>
                        {contextHolder}
                        <Space>
                            <Button type="dashed" onClick={() => history.goBack()} > Back</Button>
                            <Button type="primary" onClick={() => onSubmit()} > Next</Button>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>

    );
}
export default CreateCourses;