import { CalendarCheck, CaretLeft, DotsThreeVertical, Export, MagnifyingGlass, Faders, Trash, UserCircleGear, Plus, Question, CheckSquareOffset, TelevisionSimple, GraduationCap, Nut } from "@phosphor-icons/react";
import { Avatar, Badge, Button, Col, Divider, message, Dropdown, Input, Row, Space, Spin, Modal, Checkbox, Alert } from "antd";
import React, { useEffect, useState } from "react";
import useToken from "../../store/useToken"
import { useHistory, useLocation } from "react-router";
import axios from "../../store/axios";
import moment from "moment";
import { RWebShare } from "react-web-share";



const BusinessMembers = () => {
    const history = useHistory();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const { token } = useToken();
    const [invite, setInvite] = useState(false);
    const [admin, setAdmin] = useState(false);

    // Invite users
    const [email, setEmail] = useState(null);
    const [designation, setDesignation] = useState(null);
    const [empCode, setEmpCode] = useState(null);

    // Make admin
    const [selectedUser, setSelectedUser] = useState(null);
    const [checked, setChecked] = useState(false);



    useEffect(() => {
        if (token === false) {
            history.push(`/login?redirect=${location.pathname}`);
        }
        fetchData();
    }, []);


    const fetchData = async () => {
        setLoading(true);
        await axios({
            method: 'get',
            url:  `v1.0/business/team/members`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setData(data);
        });
        setLoading(false);
    };

    const makeAdmin = async () => {
        setLoading(true);
        const _data = {
            memberId: selectedUser?.pk,
            admin_access: !selectedUser?.is_admin
        }
        await axios({
            method: 'patch',
            url:  `v1.0/business/team/members/change_role`,
            headers: {
                Authorization: token
            },
            data: _data
        }).then(res => {
            fetchData();

        });
        setLoading(false);
    };

    const onClick = async ({ key, item }) => {

        if (key === '2') {
            setSelectedUser(item);
            setAdmin(true);
        }
    };

    const items = (item) => {
        const array = [{
            key: '1',
            icon:
                <RWebShare
                    data={{
                        text: item?.role,
                        url: `${process.env.REACT_APP_URL}profile/${item.user_code}`,
                        title: item?.name,
                    }}

                ><span><Export size={18} color="#000000" weight="thin" className="me-2" /> Share Profile</span>
                </RWebShare>
        }];

        if (!item.is_me) {
            array.push({
                label: (
                    <span className="fw400"> Change Role</span>
                ),
                key: '2',
                icon: <Nut size={18} />

            });
        }
        if (!item.is_admin) {
            array.push({
                label: (
                    <span className="text-red fw400"> Remove Connection</span>
                ),
                key: '4',
                icon: <Trash size={18} color="red" />

            });
        }
        return array;
    };
    const showModal = () => {
        setInvite(true);
    }

    const postInvite = async () => {

        if (email === null || email.length < 5) {
            return message.error("Enter correct email address")
        }
        else if (designation === null || designation === "") {
            return message.error("Enter designation")
        }
        else if (empCode === null || empCode === "") {
            return message.error("Enter employee/member code")
        }

        const _data = {
            email: email,
            designation: designation,
            emp_code: empCode,
        }
        setLoading2(true);
        await axios({
            method: 'post',
            url:  `account/business/connection/invites`,
            headers: {
                Authorization: token
            },
            data: _data
        }).then(res => {
            setLoading2(false);
            message.success("Invite successfully sent");
            setInvite(false);
            setEmail(null);
            setEmpCode(null);
            setDesignation(null);
        }).catch(err => {
            message.error(err.response.data.message);
            setLoading2(false);
        });
    };

    const handleOk = () => {
        if (!checked) {
            message.error("It seems you haven't selected the checkbox. Please ensure that you tick the checkbox before proceeding");
            return
        }
        makeAdmin();
        setAdmin(false);
    };
    const handleCancel = () => {
        setInvite(false);
        setEmail(null);
        setEmpCode(null);
        setDesignation(null);
    };

    const onClose = e => {
        console.log(e, 'I was closed.');
    };

    return (
        <Row style={{ background: '#f9f9f9', minHeight: "calc(100vh - 69px)", borderRadius: "10px" }}>
            <Col lg={16} offset={4} md={{ span: 16, offset: 4 }} xs={{ span: 20, offset: 2 }} className="md50" >
                <Row>
                    <Col span={24}>
                        <Space size={3} style={{marginLeft:'-8px'}}>
                            <Button onClick={() => history.goBack()} type="text"  icon={<CaretLeft size={22}  color="#676767" weight="light" />} ></Button>
                            <p className="fs18 fw600"> Members </p>
                        </Space>
                    </Col>
                </Row>
                <Row gutter={18} className="md30">
                    <Col lg={8} md={12} xs={24}>
                        <p className="fs14 fw400"> Search Member </p>
                        <Input size="small" className="md10" placeholder="Search by member name..." prefix={<MagnifyingGlass size={22} className="ms-1" color="#000000" weight="light" />} />
                    </Col>
                    {/* <Col lg={8} md={12} xs={24} className="m-md25 m-top10 m-top0 " >
                        <p className="fs14 fw400"> Role </p>

                        <Select defaultValue="Select" className="md15 " style={{ width: 180 }}>
                            <Option value="jack">RIA</Option>
                            <Option value="lucy">Lucy</Option>

                        </Select>
                    </Col> */}
                    <Col lg={16} md={12} xs={24} className="text-right md35 m-text-left   ">
                        <Space>

                            <Button type="primary" className="m-top10 m-top0" icon={<Plus size={20} className="pe-2" />} onClick={showModal}>  Invite </Button>

                            <Badge dot={location?.state?.state}>
                                <Button className="m-top10 m-top0" onClick={() => history.push('/business/connection')}> View Requests </Button>
                            </Badge>
                        </Space>
                    </Col>
                </Row>
                <Spin spinning={loading}>

                    <Row className="business-member-card mb-3 md30">
                        {
                            data.length === 0 && <Col span={24} className="text-center">No member</Col>
                        }
                        {
                            data.map((item, k) => {
                                return <Col key={k} span={24} className="mb-2">
                                    <Row>
                                        <Col span={12}>
                                            <Row gutter={24}>
                                                <Col span={3}>
                                                    <Avatar src={item?.img_url} size={45} />
                                                </Col>
                                                <Col span={21}>
                                                    <p className="fs16 fw600"> {item?.name} {item?.is_admin ? <span style={{ fontSize: "12px", color: "green" }}>(Admin)</span> : ""}</p>
                                                    <Space className="md5">
                                                        <UserCircleGear size={18} color="#AFBACB" weight="light" />
                                                        <p className="fs12 fw400 dark-grey pt-1"> {item?.profession_type} </p>
                                                        <CalendarCheck size={18} color="#AFBACB" className="ms-2" weight="light" />
                                                        <p className="fs12 fw400 dark-grey pt-1"> Joined {moment(item?.updated_at).format('DD-MMM-YYYY')} </p>
                                                    </Space>

                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col span={12} className="text-right md10">
                                            <Space>
                                                <Button type="link" href={`/profile/${item?.user_code}`}> View Profile </Button>
                                                <Dropdown trigger={['click']} menu={{ items: items(item), onClick: ({ key }) => onClick({ key: key, item: item }) }} placement={"bottomCenter"} overlayStyle={{ minWidth: "120px" }}>
                                                    <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                                </Dropdown>
                                                {/* <Button className=""  > <DotsThreeVertical size={22} color="#000000" weight="light" /> </Button> */}
                                            </Space>
                                        </Col>
                                        {
                                            (k + 1) !== data.length && <Col span={24}>
                                                <Divider></Divider>
                                            </Col>
                                        }

                                    </Row>
                                </Col>
                            })
                        }

                    </Row>
                </Spin>
            </Col>
            <Modal title="Invite member" okText="Send" open={invite} onOk={postInvite} onCancel={handleCancel} okButtonProps={{ loading: loading2 }}>
                <Row className="padding25">
                    <Col span={24}>
                        <p className="fs14 fw400"> Work email address <Question size={15} /> </p>
                        <Input value={email} maxLength={50} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="i.e.,jhon@example.com" className="md10" />
                    </Col>
                    <Col span={24} className="md15">
                        <p className="fs14 fw400"> Designation/Position  </p>
                        <Input value={designation} maxLength={30} onChange={(e) => setDesignation(e.target.value)} placeholder="i.e., Research Analytics" className="md10" />

                    </Col>
                    <Col span={24} className="md15">
                        <p className="fs14 fw400"> Employee Code  </p>
                        <Input value={empCode} maxLength={24} onChange={(e) => setEmpCode(e.target.value)} placeholder="i.e.,E1234" type="text" className="md10" />
                    </Col>

                </Row>
            </Modal>
            <Modal title="Update admin" centered okText="I agree" open={admin} onOk={handleOk} onCancel={() => setAdmin(false)}>
                <Row className="padding25">
                    <Col span={24}>
                        <p className="fs14 fw500"> Permissions:  </p>
                        <div className="member-div md10">
                            <Space>
                                <CheckSquareOffset size={14} weight="fill" color="#4b5669" />
                                <p className="fs14 fw400"> Content approval  </p>
                            </Space>
                        </div>
                        <div className="member-div md10">
                            <Space>
                                <GraduationCap size={14} weight="fill" color="#4b5669" />
                                <p className="fs14 fw400"> Campus management </p>
                            </Space>
                        </div>
                        <div className="member-div md10">
                            <Space>
                                <TelevisionSimple size={14} weight="fill" color="#4b5669" />
                                <p className="fs14 fw400"> TV management </p>
                            </Space>
                        </div>
                        <div className="member-div md10">
                            <Space>
                                <Faders size={14} weight="fill" color="#4b5669" />
                                <p className="fs14 fw400"> Console access  </p>
                            </Space>
                        </div>
                    </Col>

                    <Col span={24} className="md30 checkbox">
                        <Checkbox value={checked} onChange={(e) => setChecked(e)}> {selectedUser?.is_admin ? 'Remove admin privileges from' : 'Grant admin privileges to'} <span style={{ fontWeight: 'bold' }}> {selectedUser?.name} </span> </Checkbox>

                    </Col>
                    <Col span={24} className="md30 checkbox">
                        <p className="fs14 fw400"> By checking the box, the existing admin signifies their intent to make {selectedUser?.name} an admin. Make sure that the action
                            is authorized and follows the necessary protocols within your organization or system. </p>

                    </Col>
                    {/* <Col span={24} className="md20">
                        <Alert
                            message="Error Text"
                            description="Error Description Error Description Error Description Error Description Error Description Error Description"
                            type="error"
                            closable
                            onClose={onClose}
                        />
                    </Col> */}
                </Row>
            </Modal>
        </Row>
    );
}
export default BusinessMembers;