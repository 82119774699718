import React from "react";
import { Avatar, Button, Col, Dropdown, Input, Menu, Row, Space } from "antd";
import { ImageSquare, Microphone, Note, NotePencil, Scan, Share } from "@phosphor-icons/react";
import Carousel from 'better-react-carousel'
import "./../../assets/css/style.scss";


const SylloMate = () => {

    const items = [
        {
            label: (
                <span className="fs12 fw400"> <Note size={18} color="#000000" weight="thin" /> Share as Post</span>
            ),
            key: '1',
        },
        {
            label: (
                <span className="fs12 fw400"> <NotePencil size={18} color="#181816" weight="thin" /> Share as Note</span>
            ),
            key: '2',
        },


    ];



    return (
        <Row className="syllomate-img">
            <Col span={20} offset={2} className="md50" >
                <Row>
                    <Col span={16}>
                        <Row gutter={16}>
                            <Col span={2} >
                                <Avatar size={50} />
                            </Col>
                            <Col span={15} className="md5" >
                                <Input placeholder="ask SylloMate anything" className="p-4"></Input>
                            </Col>
                            <Col span={7} >
                                <Row className="syllo-icons md5 text-center">
                                    <Col span={6}>
                                        <Button type="text" ><Microphone size={24} color="#000000" weight="thin" /></Button>
                                    </Col>
                                    <Col span={6}>
                                        <Button type="text"> <ImageSquare size={24} color="#000000" weight="thin" /></Button>
                                    </Col>
                                    <Col span={6}>
                                        <Button type="text"><Scan size={26} color="#000000" weight="thin" /></Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="md45">
                            <Col span={24}>
                                <h3> Hi! I’m SylloMate </h3>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} className="">
                        <Row>
                            <Col span={24} className="md15 mb-2 syllo-card">
                                <p className="fs18 fw600">Ask SylloMate these questions</p>
                                <div className="md20" style={{ backgroundColor: '#ededed', borderRadius: '10px', padding: '14px' }}>
                                    <p className="fs12 fw500">
                                        Given the choice of anyone in the world, who would you want to look up on the internet? Why?
                                    </p>
                                </div>
                                <div className="md10" style={{ backgroundColor: '#ededed', borderRadius: '10px', padding: '14px' }}>
                                    <p className="fs12 fw500">
                                        What is the right number of email lists to automatically subscribe me to? How did you decide?
                                    </p>
                                </div>
                                <div className="md10" style={{ backgroundColor: '#ededed', borderRadius: '10px', padding: '14px' }}>
                                    <p className="fs12 fw500">
                                        Do you prefer telling me stuff or letting me buy stuff?
                                    </p>
                                </div>
                                <div className="md10" style={{ backgroundColor: '#ededed', borderRadius: '10px', padding: '14px' }}>
                                    <p className="fs12 fw500">
                                        Before answering a question, what algorithm do you run.
                                    </p>
                                </div>
                            </Col>
                            <Col span={24} className="md20">
                                <Space>
                                    <Button type="default" className="syllobtn" >Need Help</Button>
                                    <Button className="syllobtn">Report Issue</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} >
                        <Row>
                            <Col span={24} >
                                <Avatar size={40} />
                                <div style={{ backgroundColor: '#f0f0f0', borderRadius: '12px', padding: '25px', marginTop: '-15px', marginLeft: '13px' }}>
                                    <p className="fs14 fw400">
                                        AI systems with Chegg's extensive content library and subject-matter experts to make learning AI systems with
                                        Chegg's extensive content library and subject-matter experts to make learning AI systems with Chegg's extensive content library
                                    </p>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={14} offset={5} className="md30">
                        <Row>
                            <Col span={22}>
                                <div className="text-right" style={{ marginRight: '-20px' }} >
                                    <Avatar size={40} />
                                </div>
                                <div style={{ backgroundColor: '#f0f0f0', borderRadius: '12px', padding: '27px', marginTop: '-15px', marginLeft: '13px' }}>
                                    <p className="fs14 fw400">
                                        Filler materials are particles added to resin or binders (plastics, composites, concrete) that can
                                        improve specific properties, make the product cheaper, or a mixture of both.[1]
                                        The two largest segments for filler material use is elastomers and plastics.[2] Worldwide, more than 53 million tons of fillers (with a total sum of approximately US$18 billion) are used every year in application areas such as paper, plastics, rubber, paints, coatings, adhesives, and sealants. As such, fillers, produced by more than 700 companies, rank among the world's major raw materials and are contained in a variety of goods for daily consumer needs. The top filler materials used are ground calcium carbonate (GCC), precipitated calcium carbonate (PCC), kaolin, talc, and carbon black. Filler materials are particles added to resin or binders (plastics, composites, concrete) that can improve specific properties, make the product cheaper, or a mixture of both.
                                        [1] The two largest segments for filler material use is elastomers and plastics.[2] Worldwide, more than 53 million tons of fillers.
                                    </p>
                                </div>

                            </Col>
                            <Col span={2} className="syllo-icons text-right md250 ps-4">
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="right"
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Button type="text" shape="circle" icon={<Share size={26} color="#000000" weight="thin" />} />
                                    </a>
                                </Dropdown>



                            </Col>

                        </Row>

                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="md30 mb-4">
                        <p className="fs16 fw600">Recent Searches</p>
                        <div className='md10'>
                            <Carousel cols={3} rows={1} gap={14} loop className="">
                                <Carousel.Item >
                                    <div className="md20" style={{ borderRadius: '12px', backgroundColor: '#f0f0f0', padding: '12px' }}>
                                        <p className="fs14 text-black fw500 md10">What was the value of American Dollar wrt to Indian currency in 1949</p>
                                        <p className="fs11 fw400 md10">AI-guided support tailored to your style, your pace, and your preferences with
                                            personalized assessments and practice tests. AI-guided support tailored  ...</p>
                                        <div className="text-center">
                                            <Button type="primary" className="md20"> open chat </Button>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item >
                                    <div className="md20" style={{ borderRadius: '12px', backgroundColor: '#f0f0f0', padding: '12px' }}>
                                        <p className="fs14 text-black fw500 md10">What was the value of American Dollar wrt to Indian currency in 1949</p>
                                        <p className="fs11 fw400 md10">AI-guided support tailored to your style, your pace, and your preferences with
                                            personalized assessments and practice tests. AI-guided support tailored  ...</p>
                                        <div className="text-center">
                                            <Button type="primary" className="md20"> open chat </Button>
                                        </div>                                    </div>
                                </Carousel.Item>
                                <Carousel.Item >
                                    <div className="md20" style={{ borderRadius: '12px', backgroundColor: '#f0f0f0', padding: '12px' }}>
                                        <p className="fs14 text-black fw500 md10">What was the value of American Dollar wrt to Indian currency in 1949</p>
                                        <p className="fs11 fw400 md10">AI-guided support tailored to your style, your pace, and your preferences with
                                            personalized assessments and practice tests. AI-guided support tailored  ...</p>
                                        <div className="text-center">
                                            <Button type="primary" className="md20"> open chat </Button>
                                        </div>                                    </div>
                                </Carousel.Item>
                                <Carousel.Item >
                                    <div className="md20" style={{ borderRadius: '12px', backgroundColor: '#f0f0f0', padding: '12px' }}>
                                        <p className="fs14 text-black fw500 md10">What was the value of American Dollar wrt to Indian currency in 1949</p>
                                        <p className="fs11 fw400 md10">AI-guided support tailored to your style, your pace, and your preferences with
                                            personalized assessments and practice tests. AI-guided support tailored  ...</p>
                                        <div className="text-center">
                                            <Button type="primary" className="md20"> open chat </Button>
                                        </div>                                    </div>
                                </Carousel.Item>
                                <Carousel.Item >
                                    <div className="md20" style={{ borderRadius: '12px', backgroundColor: '#f0f0f0', padding: '12px' }}>
                                        <p className="fs14 text-black fw500 md10">What was the value of American Dollar wrt to Indian currency in 1949</p>
                                        <p className="fs11 fw400 md10">AI-guided support tailored to your style, your pace, and your preferences
                                            with personalized assessments and practice tests. AI-guided support tailored  ...</p>
                                        <div className="text-center">
                                            <Button type="primary" className="md20"> open chat </Button>
                                        </div>                                    </div>
                                </Carousel.Item>
                                <Carousel.Item >
                                    <div className="md20" style={{ borderRadius: '12px', backgroundColor: '#f0f0f0', padding: '12px' }}>
                                        <p className="fs14 text-black fw500 md10">What was the value of American Dollar wrt to Indian currency in 1949</p>
                                        <p className="fs11 fw400 md10">AI-guided support tailored to your style, your pace, and your preferences with
                                            personalized assessments and practice tests. AI-guided support tailored  ...</p>
                                        <div className="text-center">
                                            <Button type="primary" className="md20"> open chat </Button>
                                        </div>                                    </div>
                                </Carousel.Item>
                                <Carousel.Item >
                                    <div className="md20" style={{ borderRadius: '12px', backgroundColor: '#f0f0f0', padding: '12px' }}>
                                        <p className="fs14 text-black fw500 md10">What was the value of American Dollar wrt to Indian currency in 1949</p>
                                        <p className="fs11 fw400 md10">AI-guided support tailored to your style, your pace, and your preferences
                                            with personalized assessments and practice tests. AI-guided support tailored  ...</p>
                                        <div className="text-center">
                                            <Button type="primary" className="md20"> open chat </Button>
                                        </div>                                    </div>
                                </Carousel.Item>
                                <Carousel.Item >
                                    <div className="md20" style={{ borderRadius: '12px', backgroundColor: '#f0f0f0', padding: '12px' }}>
                                        <p className="fs14 text-black fw500 md10">What was the value of American Dollar wrt to Indian currency in 1949</p>
                                        <p className="fs11 fw400 md10">AI-guided support tailored to your style, your pace, and your preferences
                                            with personalized assessments and practice tests. AI-guided support tailored  ...</p>
                                        <div className="text-center">
                                            <Button type="primary" className="md20"> open chat </Button>
                                        </div>                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default SylloMate;