import { Avatar, Badge, Card, Col, Row, Space, Tag, Typography, Tooltip, Pagination, Button } from "antd";
import { PlayCircleFilled, PauseCircleFilled } from "@ant-design/icons"
import moment from "moment";
import React, { useEffect, useState } from "react";
import { convertDuration } from "../../utils";
import { createAction } from "redux-actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const { Paragraph } = Typography;

const DetailSection1 = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [current, setCurrent] = useState(1);
    const { play, podcast } = useSelector((state) => state.podcast);
    const itemsPerPage = 10;

    const { data } = props;
    useEffect(() => {
        const p = document.getElementById("podcastdescription");
        p.innerHTML = data?.description;
    }, []);

    const setPodcastEp = (episode) => {
        const PODCAST_DETAIL = createAction('PODCAST_DETAIL');
        dispatch(PODCAST_DETAIL(episode));
        updateStatePlay(true);
    }

    const updateStatePlay = (state) => {
        const PODCAST_PLAY = createAction('PODCAST_PLAY');
        dispatch(PODCAST_PLAY(state));
    }

    const onChange = (page) => {
        console.log(page);
        setCurrent(page);
    };

    function showItems() {
        const startIndex = (current - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data?.podcast.episodes.slice(startIndex, endIndex).map((element, index) => (
            <Col key={startIndex + index} span={24} className="md15">
                <Card className="padding15" style={{ borderRadius: '10px', border: ' 1px solid rgba(0, 0, 0, 0.08)' }}>
                    <Row>
                        <Col span={22}>
                            <Space size={26} align="start">
                                <img src={element?.image?.href}
                                    alt="" height={120} width={120} style={{ borderRadius: '5px' }} />
                                <div>

                                    <p className="fs14 fw600 link-dark  ">
                                        {element?.title}
                                    </p>
                                    <p className="fs12 fw400" style={{ color: '#999A9B' }}>
                                        {convertDuration(element.itunes_duration)}  <Badge status="default" />  {moment(element.published).format('Do MMM, YYYY')}
                                    </p>

                                    {
                                        (element?.itunes_episodetype === "trailer" || element?.itunes_explicit) &&

                                        <Space>
                                            {
                                                element?.itunes_episodetype === "trailer" && <Tag color="blue" className="md5">Trailer</Tag>
                                            }{
                                                element?.itunes_explicit && <Tooltip title="Explicit"><Tag color="orange" className="md5">E</Tag></Tooltip>
                                            }
                                        </Space>
                                    }
                                    <Paragraph ellipsis={{ rows: 3 }}>
                                        <div className="fs12 fw300 md10" style={{ color: '#999A9B' }} dangerouslySetInnerHTML={{ __html: element.summary }} />
                                    </Paragraph>
                                </div>
                            </Space>
                        </Col>
                        <Col span={2} className="md text-end">
                            {
                                (podcast && podcast.id === element.id && play) ? 
                                <Button onClick={()=>updateStatePlay(false)} size="large" type="text" icon={<PauseCircleFilled />}></Button>:
                            <Button onClick={()=>setPodcastEp(element)}  size="large" type="text" icon={<PlayCircleFilled />}></Button>
                        }

                        </Col>
                    </Row>
                </Card>

            </Col>
        ));
    }

    return (
        <Row>
            <Col span={24}>
                <Card className="play-podcast" style={{ borderRadius: '3px' }}>
                    <Row>
                        <Col span={17}>
                            <Row gutter={12}>
                                <Col span={24}>
                                    <p className="fs10 fw400 " style={{ color: '#b6d0ff' }}> Podcast</p>

                                    <p className="fs20 fw800 text-white">{data?.title}</p>
                                    <p className="fs12 fw300 " style={{ color: '#b6d0ff' }}> {data?.author} </p>
                                    <p className="fs12 fw300 " style={{ color: '#b6d0ff' }}> {data?.total_episode} Episodes</p>

                                </Col>
                                <Col span={24} className="md55">
                                    <div >
                                        <Space size={12} direction="horizontal" className="cursor" onClick={() => history.push(`/profile/${data?.creator?.user_code}`)}>
                                            <Avatar size={45} src={data?.creator?.avatar_url} />
                                            <div className="">
                                                <p className="fs15 fw400 " style={{ color: '#b6d0ff' }}> {data?.creator?.full_name} </p>
                                                <p className="fs14 fw400 " style={{ color: '#D3D3D3' }}> @{data?.creator?.username} </p>
                                            </div>
                                        </Space>
                                    </div>
                                </Col>


                            </Row>

                        </Col>
                        <Col span={7} className="text-right">
                            <img src={data?.image_url}
                                alt="" height={170} style={{ borderRadius: '4px' }} />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={24} className=" padding30">
                <Row>
                    <Col span={24}>
                        <p className="fs14 fw400 link-dark" id="podcastdescription">
                            {/* {data?.description} */}
                        </p>
                        <Space className="md15 podcast-tag" wrap>
                            {
                                data?.tags?.slice(0, 5).map((element, i) => {
                                    return <Tag key={i}> {element} </Tag>
                                })
                            }
                        </Space>
                    </Col>
                    <Col span={24} className="md25">
                        <p className="fs16 fw600 ">
                            All Episodes
                        </p>

                    </Col>
                    {showItems()}

                    {
                        data?.podcast.episodes.length > 10 &&
                        <Col span={24} className="md20">
                            <Pagination showSizeChanger={false} current={current} onChange={onChange} total={data?.podcast.episodes.length} />
                        </Col>
                    }





                </Row>
            </Col>
        </Row>
    );
}

export default DetailSection1;