import React, { useEffect, useState } from 'react';
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";


import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import './style.scss'
import NewMentionsPlugin from './plugins/MentionsPlugin';
import { MentionNode } from './nodes/MentionNode';
import { MaxLengthPlugin } from './plugins/MaxLength';
import {$getRoot} from 'lexical';
import { HashtagNode } from './nodes/HashtagNode';
import { HashtagPlugin } from './plugins/HashtagPlugin';

function Placeholder() {
  return <div className="editor-placeholder">What's your thought?</div>
}

const getTextFromNodes=(nodesJson)=>{
  if (nodesJson === null || nodesJson === undefined){
    return ""
  }

  let arr = []
  const childs = nodesJson.root.children
  for (let i=0;i<childs.length;i++){
    childs[i].children.map((j)=> {
        if(j.type ==='text'){
          arr.push(j.text)
        }
    })
  }
  return arr.join(' ')
}


export default function PostEditor(props) {
  const [textLen, setTextLen] = useState(0);


  const onChange = (editorState, editor) => {
    const _json = editorState.toJSON();
    const stringifiedEditorState = JSON.stringify(_json);

    editor.update(() => {
      const root = $getRoot();
      setTextLen(root.getTextContentSize());

      if (props?.onChange) {
        props.onChange(stringifiedEditorState)
      }
      if (props?.onChangeHTML) {
        // const htmlString = $generateHtmlFromNodes(editor);
        props.onChangeHTML(getTextFromNodes(_json));

      }

      // console.log(htmlString)
    })


  }

  const _editorConfig = {
    // The editor theme
    namespace: 'sylloEditor',
    theme: ExampleTheme,
    onError(error) {
      throw error;
    },
    nodes: [
      HashtagNode,
      AutoLinkNode,
      LinkNode,
      MentionNode
    ],
  };
  const [editorConfig, setConf] = useState(_editorConfig)
 
const {isMaxLength} = props
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={props.className + " editor-container"}>
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input post-editor" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          {
            isMaxLength && <div className='text-right' style={{paddingRight:"10px"}}><small className='text-grey'>{textLen}/1000 </small></div>
          }
          
            {isMaxLength && <MaxLengthPlugin maxLength={1000} />}
          <OnChangePlugin onChange={onChange} />
          <HashtagPlugin />
          <AutoFocusPlugin />
          <NewMentionsPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
        </div>
      </div>
    </LexicalComposer>
  );
}
