
import { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';

export default function useToken() {
  // useToken will check authenticity of the token and also verifies the exp. if all is well then returns token string else null
  const getToken = () => {
    const tokenString = Cookies.get("Logintoken");
    return tokenString !== undefined? tokenString: false;
  };

  const [token, setToken] = useState(getToken());

  useEffect(() => {
    if (token !== undefined) {
      try {
        var decoded = jwt_decode(token);
        if (Date.now() >= decoded.exp * 1000) {// validate exp
          setToken(false);
        }
      } catch {
        setToken(false);
      }
    } else {
      setToken(false);
    }

  }, [token]);

  const saveToken = userToken => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  };
}
