import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    sectors: [],
    nifty50: [],
    sensex: [],
    user_selected: [], profile: [],
    stocks: [],
    ltp: [],
    stock_price: [],
    all_signals: [],
    live_stock_value: [],
    my_signals: [],
    stock_live_price: [],
    my_stocks:[]
}
const PublicApi = handleActions({
    SECTORS: (state, action) => {
        return { ...state, sectors: action.payload };
    },
    NIFTY_50: (state, action) => {
        return { ...state, nifty50: action.payload };
    },
    SENSEX: (state, action) => {
        return { ...state, sensex: action.payload };
    },
    USER_SELECTED_PREFERENCES: (state, action) => {
        return { ...state, user_selected: action.payload };
    },
    PROFILE: (state, action) => {
        return { ...state, profile: action.payload };
    },
    LIST_OF_STOCKS: (state, action) => {
        return { ...state, stocks: action.payload };
    },
    LTP: (state, action) => {
        return { ...state, ltp: action.payload };
    },
    STOCK_PRICE: (state, action) => {
        return { ...state, stock_price: action.payload };
    },
    ALL_SIGNALS: (state, action) => {
        return { ...state, all_signals: action.payload };
    },
    LIVE_VALUE_STOCK: (state, action) => {
        return { ...state, live_stock_value: action.payload };
    },
    MY_SIGNALS: (state, action) => {
        return { ...state, my_signals: action.payload };
    },
    STOCKS_LIVE_DATA: (state, action) => {
        return { ...state, stock_live_price: action.payload };
    },
    MY_STOCKS:(state, action) => {
        return { ...state, my_stocks: action.payload };
    },
}, INITIAL_STATE);

export default PublicApi;
