import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    entity: null,
    selected_entity_id: null,
}
const EntityReducer = handleActions({
    ENTITY_SET: (state, action) => {
        return { ...state, entity: action.payload };
    },
}, INITIAL_STATE);

export default EntityReducer;
