import { Alert, Button, Checkbox, Col, Divider, Modal, Row, Skeleton, Space, message } from "antd";
import axios from "../../../store/axios";
import { ShieldCheck, } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import useToken from '../../../store/useToken'


const SettingConnect = () => {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const { token } = useToken();
    const [loader, setloader] = useState(false);
    const [modal, setmodal] = useState(false);
    const [connectedNow, setconnectedNow] = useState(false);
    const [check, setCheck] = useState(false);
    const [appId, setAppId] = useState("");
    const [loader1, setLoader1] = useState(true);

    const [app, setApp] = useState({});

    useEffect(() => {
        const p = location.search;
        if (p.includes('error=')) {
            //error=access_denied
            setmodal(true);
        } else if (p.includes('code=')) {
            // send authorization code
            setloader(true);
            if (appId !== "") {
                sendAccessCode(appId);
            }
        }
    }, [location, appId]);

    useEffect(() => {
        const app_id = params['app']

        if (app_id === "twitter") {
            getApp('TW');
            setAppId('TW');
        }
        else if (app_id === "linkedin") {
            getApp('LI');
            setAppId('LI');
        }
    }, []);

    const getApp = async (app_id) => {
        await axios({
            method: 'get',
            url:  `lighthouse/app/${app_id}`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setApp(data);
        })
        setloader(false);
        setLoader1(false);
    }

    const sendAccessCode = async (app_id) => {
        if (app?.connected === true) {
            return
        }
        setloader(true);
        await axios({
            method: 'post',
            url:  `lighthouse/app/connect/${app_id}`,
            headers: {
                Authorization: token
            },
            data: { resp_url: window.location.href }
        }).then(res => {
            setconnectedNow(true);
            setloader(false);
        })
    }

    const connectTwitter = async () => {
        if (check === false) {
            message.warn("Please accept our Terms of Service and Privacy Policy!")
            return
        }
        setloader(true);
        await axios({
            method: 'get',
            url:  `lighthouse/app/authorize/${app?.platform}`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            window.open(data.url, "_self");
            return
        })
        setloader(false);
    };


    return (
        <Row className="md30" style={{ minHeight: "87vh" }}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }} md={{ span: 8, offset: 8 }}>
                <Row>
                    <Col span={24} className="text-center">
                        <p className="fs16 fw500"> Connect {app?.name} </p>

                    </Col>
                </Row>
                <Row className="md15" style={{ borderRadius: '10px', border: '1px solid #d9d9d9', padding: '50px', overflow: 'hidden' }} >

                    {
                        loader1 ? <><Skeleton active /><Skeleton active /></>
                            :
                            <Col span={24} >
                                <Row gutter={18} className="">

                                    <Col span={17}>
                                        <p className="fs14 fw600 md5">Connect your {app?.name} Account</p>
                                        <p className="fs14 fw400 text-grey md15"> {app?.desc} </p>
                                    </Col>
                                    <Col span={7}>
                                        <div className="text-center" style={{ borderRadius: '18px', border: '1px solid #d9d9d9', padding: '15px' }}>
                                            <img height={32} src={app?.icon} />
                                            <p className="fs14 fw600 md10" style={{ color: '#4B5669' }}>{app?.name}</p>
                                        </div>
                                    </Col>
                                    <Col span={24} className="md30">
                                        <Checkbox className="agree" onChange={(e) => setCheck(e.checked)}>
                                            <p className="fs14 fw400 text-black"> By clicking  Get Started, you agree to our <span style={{ color: '#3B6BE3' }}>Terms of Service</span> and <span style={{ color: '#3B6BE3' }}>
                                                Privacy Policy
                                            </span></p>
                                        </Checkbox>
                                    </Col>
                                </Row>

                                <Divider />
                                {
                                    app?.connected ? <Alert type="success" message="Already connected!" showIcon action={
                                        <Button size="small" type="primary" onClick={() => history.push('/settings')}>
                                            Go back
                                        </Button>
                                    } />
                                        : connectedNow ? <Alert type="success" message="Account successfully connected!" showIcon action={
                                            <Button size="small" type="primary" onClick={() => history.push('/settings')}>
                                                Go Back
                                            </Button>
                                        } />
                                            : <Button type="primary" className="md10" block loading={loader} onClick={() => connectTwitter()}>
                                                Get Started
                                            </Button>
                                }

                                <Space className="md10">
                                    <ShieldCheck size={32} color="#EA9600" weight="fill" />
                                    <p className="fs14 fw400 text-grey">
                                        Your data is securely stored with AWS. Your control Your data... always
                                    </p>
                                </Space>
                            </Col>
                    }
                </Row>
            </Col>
            <Modal centered title="Oops! Something went wrong" open={modal} footer={false} onCancel={() => setmodal(false)} >
                <Row>
                    <Col className="padding20" span={24}>
                        <Alert showIcon message="Integration was not successful" type="error" />
                        <p className="text-grey md20">Please try again later!</p>
                        <Button type="primary" className="md20" block onClick={() => setmodal(false)}>
                            Done
                        </Button>
                    </Col>
                </Row>

            </Modal>
        </Row>
    );
}
export default SettingConnect;