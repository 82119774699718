import { Button, Tooltip, Avatar } from "antd";
import React, { useEffect } from "react";

import { SwapOutlined } from "@ant-design/icons";


const SwitchButton = (props) => {
    const {profile_data, current, switchUserProfileAPI} = props;

    useEffect(() => {

    }, []);

    return (
        <>
            <span style={{ marginTop: '5px' }}>
                <Avatar src={current !== 'business'?profile_data?.connection?.logo:profile_data?.img_url} size={32}></Avatar> {current !== 'business'?'Business':profile_data?.full_name}
            </span>
            <Tooltip title={`Switch to ${current !== 'business'?'business':'my'} profile`}>
                <Button onClick={() => switchUserProfileAPI()} icon={<SwapOutlined />} style={{ float: 'right' }} />
            </Tooltip>
        </>

    );
}


export default SwitchButton;
