import React, { PureComponent } from "react";
import { Input,Button } from "antd";
import '../../assets/css/style.scss'
import './ForgotPassword.scss'
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { ArrowLeft } from "@phosphor-icons/react";

class ForgotPassword extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            sent: false
        };
    }

    ForgotPassword(evt) {
        evt.preventDefault()
        this.props.ForgotPassword({
            email: this.state.email,
        }).then(() => {
            this.setState({
                sent: true
            })
            setTimeout(() => {
                this.props.history.push("/login")
            }, 5000);
        })
    }

    render() {
        return (
            <div className="row white-background-login">
                <div className="col-md-4 offset-md-4  border-card md100">
                    <div className="row m-padding20">
                        {/* <div className="col-md-12">
                            <img src={require("../../assets/images/syllo_text.svg")} alt="logo"></img>
                        </div> */}

                        <div className="col-md-12 text-center">
                        <img src={require("../../assets/images/syllo_new.svg").default} alt="logo" style={{height:"50px"}}></img>
                        </div>



                        {
                            this.state.sent === false ?

                                <>
                                    <div className="col-md-12 text-center md30">
                                        <p className="sub-text">Enter email address to get reset password link.</p>
                                    </div>

                                    <div className="col-md-10 offset-md-1 md50">
                                        <form
                                            className="row"
                                            style={{ display: this.state.emailIDType }}
                                            onSubmit={(evt) => this.ForgotPassword(evt)}
                                        >
                                            <div className="col-md-12">
                                                <Input
                                                    size="large"
                                                    type="email"
                                                    autoComplete="off"
                                                    required
                                                    placeholder="Email address"
                                                    onChange={(evt) =>
                                                        this.setState({ email: evt.target.value })
                                                    }
                                                />
                                            </div>

                                            <div className="col-md-12 md20">
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    block
                                                    size="large"
                                                    onSubmit={() => this.ForgotPassword()}>
                                                    Send Reset Link
                                           </Button>
                                                <p className="text-left md10"><a href="/login" className={"sub-text"}> <ArrowLeft size={18} color="#030303" style={{marginTop:"-3px"}} weight="light" />  Back to Login</a></p>
                                            </div>

                                        </form>
                                    </div> </> : <div className="col-md-10 offset-md-1 text-center md10">
                                    <div className="col-md-12 md20">
                                        <img src={require("../../assets/images/success.PNG")} alt="logo"></img>
                                    </div>
                                    <div className="col-md-12 md50">
                                        <p className="sub-text">We have sent link to registered Email Address.  </p>
                                    </div>


                                    <div className="col-md-12 md100">
                                    <p className="sub-text">You will redirect to login in 5 sec <br></br>  <a className="blue-text underline md50" href="/login">Go Now</a> </p>
                                </div>
                                </div>
                        }

                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    _login: state.login.login_details
});
const mapDispatchToProps = (dispatch) => ({
    ForgotPassword: v => dispatch(actions.ForgotPassword(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));
