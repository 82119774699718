import React from "react";
import { Row, Col, Skeleton } from "antd";
import './reels.scss'
import ReelsViewCard from "./ReelsViewCard";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import ReactFullpage from "@fullpage/react-fullpage";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import BlankState from "../BlankState";


const ReelsView = (props) => {

    const [reels, setReels] = useState([])
    const [loader, setLoader] = useState(true)
    useEffect(() => {
        axios({
            method: 'get',
            url:  "market/feed/reels?count=5&offset=0&tags=&author=",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setReels(data)
            setLoader(false)

        })
    }, [])

    const stopVideo = (index) => {
        let data = reels[index]
        setReels(reels.map(item =>
            item.entity_id === data?.entity_id
                ? { ...item, play: false, mute: true }
                : item
        ))
    }

    const playVideo = (index) => {
        let data = reels[index]
        setReels(reels.map(item =>
            item.entity_id === data?.entity_id
                ? { ...item, play: true, mute: false }
                : item
        ))
    }



    const muteVideo = (data, type) => {
        setReels(reels.map(item =>
            item.entity_id === data?.entity_id
                ? { ...item, mute: type }
                : item
        ))
    }
    const startVideo = (data, type) => {
        setReels(reels.map(item =>
            item.entity_id === data?.entity_id
                ? { ...item, play: type }
                : item
        ))
    }




    const likeFeed = (data, type) => {
        
        setReels(reels.map(item =>
            item.entity_id === data?.entity_id
                ? { ...item, likes: type ? data.likes + 1 : data.likes - 1, liked: type }
                : item
        ))

        props
            .likeFeed({
                id: data.entity_id,
                type: type,
                requesttype: type === true ? "post" : "delete",
            })

    }



    return (
        <Row >
            <Col xs={{span: 24, offset:0}} lg={{span:22, offset:1}} className='md20'>
                {
                    loader ? <Skeleton></Skeleton> : reels.length === 0 ? <Row>

                        <Col span={12} offset={6} className=" text-center md150">
                        <BlankState img={require("../../assets/images/blank/reels.svg").default} height={200} />


                        </Col>
                    </Row> :
                        <ReactFullpage
                            scrollingSpeed={1000}
                            onLeave={(origin) => {
                                console.log("origin", origin)
                                stopVideo(origin.index)
                            }}
                            afterLoad={(origin, destination) => {
                                console.log("destination", destination)
                                playVideo(destination.index)
                            }}
                            render={({ }) => {
                                return (

                                    <ReactFullpage.Wrapper>
                                        {reels.map((item, index) => {
                                            return <div key={index} className={"section"}> <ReelsViewCard startVideo={(data, type) => startVideo(data, type)} likeFeed={(data, type) => likeFeed(data, type)} data={item} muteVideo={(data, type) => muteVideo(data, type)} />
                                            </div>
                                        })}
                                    </ReactFullpage.Wrapper>


                                );
                            }}
                        />
                }
                {/* {
                    reels.length === 0 && <Row>

                        <Col span={12} offset={6} className=" text-center">
                            <BlankState />

                        </Col> check kar lo yhoper bala
                    </Row>
                } */}

            </Col>


        </Row>



    );
}

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => ({
    likeFeed: (v) => dispatch(actions.likeFeed(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReelsView)
);
