import React, { Component } from "react";
import {  Result, Skeleton } from "antd";
import { withRouter } from "react-router";


class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        load: false,
      });
    }, 50000);
  }

  render() {
    return (
      <div className="row text-center">
        {this.state.load === true ? (
          <div className="col-lg-12 col-xsm-12">
            <Skeleton active></Skeleton>
          </div>
        ) : (
          <div className="col-md-12 text-center">
            <Result
              status="500"
              title="500"
              subTitle="Sorry, something went wrong."
              extra={
                <p
                  className="md20 cursor sub-text underline"
                  onClick={() => window.location.reload()}
                >
                  Reload
                </p>
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Loader);
