import React from "react";
import { Row, Col, Typography} from 'antd';

const { Paragraph } = Typography;

const NoteBody = ({ item, details }) => {
    return <Row>
        <Col span={24} className="pool-card">
            <h6 className="news-header cursor m-fs16" onClick={() => details(item)}>{item?.title}</h6>

            <Paragraph onClick={() => details(item)}
                        ellipsis={{ rows: 5, expandable: true, symbol: <strong>See more</strong> }}
                        className="post-heading-text m-fs15"
                        style={{ color: "#4B5669" }}
                    >
                        {item?.meta_title}
                    </Paragraph>
            {/* <div onClick={(e) => (e.defaultPrevented(), details(item))}>
            {
                item?.blocks && item?.blocks.map((_item, i) => {
                    return <Paragraph key={i}
                        ellipsis={{ rows: 3, expandable: true, symbol: <strong>See more</strong> }}
                        className="post-heading-text m-fs15"
                        style={{ color: "#4B5669" }}
                    >
                        {renderHTML(_item?.data?.text)}
                    </Paragraph>
                })
            }
            </div> */}
        </Col>
    </Row>
}
export default NoteBody;