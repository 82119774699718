import React, { Component } from 'react'
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { Table, Menu, Dropdown, Col, Divider, Button, Row } from 'antd';
import moment from 'moment';
import Loader from '../Loader/Loader';
import { DotsThreeCircleVertical } from "@phosphor-icons/react"
import { toast } from "react-hot-toast";
import Toast from '../utils/Toast';
class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptions: [],
            loader: true
        };
    };

    componentDidMount() {
        this.props.AllSubscriptions().then(() => {
            this.setState({ subscriptions: this.props._subscriptions, loader: false })
        })
    }

    cancelSubscription(id) {
        this.props.cancelSubscription(id).then(() => {
            Toast("Cancelled Succesfully", "success")
            this.componentDidMount()
        })
    }

    render() {
        const menu = (data) => (
            <Menu>
                <Menu.Item key="0" onClick={() => this.cancelSubscription(data.id)}>
                    Cancel Subscription
                </Menu.Item>
            </Menu>
        );
        const columns = [
            {
                title: 'Subscription',
                dataIndex: 'item',
                key: 'item',
                width: "45%",
                render: (key, record) => <>
                    <Row>
                        <Col span={24}>{record.item}</Col>
                        <Col span={24} className="md10"><p className="text-light-grey fw300">By: {record.owner_name}</p></Col>
                        <Col span={24}>
                            <p className="text-light-grey fw300">Start date: {record?.joining_date !== null && moment(record?.joining_date).format("YYYY-MM-DD")}
                                {record?.subscription_expire_date !== null && <span> ~ {moment(record?.subscription_expire_date).format("YYYY-MM-DD")}</span>}
                            </p>
                        </Col>

                    </Row>
                </>
            },
            // {
            //     title: 'Start Date',
            //     dataIndex: 'joining_date',
            //     key: 'joining_date',
            //     render: (key) => 
            // },
            // {
            //     title: 'Owner',
            //     dataIndex: 'owner_name',
            //     key: 'owner_name',
            // },
            {
                title: 'Amount',
                dataIndex: 'subscription_amount',
                key: 'subscription_amount',
            },
            // {
            //     title: 'End Date',
            //     dataIndex: 'subscription_expire_date',
            //     key: 'subscription_expire_date',
            //     render: (key) => key !== null && moment(key).format("YYYY-MM-DD")
            // },
            {
                title: 'Tenure',
                dataIndex: 'tenure',
                key: 'tenure',
                render: (key) => key + " Months",
                width: "16%",
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (key, record) => <>
                    <p><span>{key}</span>
                        <span style={{ float: "right" }}>
                            {record.status !== "Cancelled" &&
                                <Dropdown overlay={menu(record)} trigger={['click']}>
                                    <span > <Button type="text" icon={<DotsThreeCircleVertical weight="thin"></DotsThreeCircleVertical>}></Button>
                                    </span>
                                </Dropdown>
                            }
                        </span></p>
                </>,
                 responsive: ['md', 'lg']
            }

        ];

        return (
            <Row style={{ padding: "40px 0px 40px 30px" }} className="m-nopadding ">
                <Col lg={24} xs={24} >
                    <Row>
                        <Col span={24}>
                            <p className="m-0 fs18"> Subscriptions </p>
                            <Row>
                                <Col span={24}>
                                    <p className="text-grey">View Channel subscriptions active/in-active.</p>
                                </Col>

                            </Row>
                        </Col>
                        <Divider />
                        <Col lg={{span:24}}  xs={{span:24 }} className=''>
                            {
                                this.state.loader === true ? <Loader className="md20"></Loader> : <Table dataSource={this.state.subscriptions} bordered={false} pagination={{ position: ["bottomRight"] }} columns={columns} />
                            }
                        </Col>
                        
                    </Row>
                </Col>
            </Row>
        )
    }
};



const mapStateToProps = (state) => ({
    _subscriptions: state.profile.subscription,
});
const mapDispatchToProps = (dispatch) => ({
    AllSubscriptions: (v) => dispatch(actions.AllSubscriptions(v)),
    cancelSubscription: (v) => dispatch(actions.cancelSubscription(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Subscription)
);
