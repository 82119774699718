import React from 'react';
import { Button, Empty } from 'antd';

const BlankState = (props) => (
  <Empty
    image={props.img? props.img: "https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"}
    imageStyle={{
      height: props.height? props.height:60,
    }}
    description={
      <span>
        
        {/* {
          props.desc ? props.desc : <></>
        } */}
      </span>
    }
  >
    {/* <Button type="primary">Create Now</Button> */}
  </Empty>
);
export default BlankState;