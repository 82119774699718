import { Avatar, Button, Col, Row, Skeleton } from "antd";
import { XCircle } from "@phosphor-icons/react";
import React from "react";

const RewardActivity = ({ loading }) => {
    return (
        <Row >
            {
                loading ? <><Skeleton active /> <Skeleton active /></> :
                    <Col span={24} className="update-card cursor">
                        <Row gutter={10} justify={"center"}>

                            {/* <Col span={4}>
                                <Avatar size={55} />
                            </Col>
                            <Col span={17} className="ps-4">
                                <p className="fs14 fw600">Almost there!!</p>
                                <p className="fs12 fw400 md5">You are so close to winning
                                    this badge.</p>
                                <Button type='primary' className="md20"> Show me my badges </Button>
                            </Col>
                            <Col span={3} className="cursor">
                                <XCircle size={32} color="#d9d9d9" weight="fill" />
                            </Col> */}
<p className="text-center">Coming Soon</p>
                        </Row>
                   

                    </Col>
            }
        </Row >
    );
}

export default RewardActivity;