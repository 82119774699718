import React, { useEffect, useState } from 'react';
import ExampleTheme from "./themes/ExampleTheme";
// import {$generateHtmlFromNodes, $} from '@lexical/html';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import './style.scss'
import ImagesPlugin from './plugins/ImagesPlugin';
import ToolbarPluginNS from './plugins/ToolbarPluginNS';
import { ImageNode } from './nodes/ImageNode';
import { Affix } from 'antd';
import NewMentionsPlugin from './plugins/MentionsPlugin';
import { MentionNode } from './nodes/MentionNode';
import { HashtagPlugin } from './plugins/HashtagPlugin';
import { HashtagNode } from './nodes/HashtagNode';

function Placeholder() {
  return <div className="editor-placeholder">Write story here...</div>;
}

const getTextFromNodes=(nodesJson)=>{
  if (nodesJson === null || nodesJson === undefined){
    return ""
  }

  let arr = []
  const childs = nodesJson.root.children
  for (let i=0;i<childs.length;i++){
    childs[i].children.map((j)=> {
        if(j.type ==='text'){
          arr.push(j.text)
        }
    })
  }
  return arr.join(' ')
}


export default function NewsletterEditor(props) {


  const onChange = (editorState, editor) => {
    // editorState.read(() => {
    //   // Read the contents of the EditorState here.
    //   const root = $getRoot();
    //   const selection = $getSelection();
    // });
    // JSON formate
    const _json = editorState.toJSON();
    const stringifiedEditorState = JSON.stringify(_json);
    // console.log(editorState.toJSON())

    editor.update(() => {
      if (props?.onChange) {
        props.onChange(stringifiedEditorState)
      }
      if (props?.onChangeHTML) {
        // const htmlString = $generateHtmlFromNodes(editor);
        props.onChangeHTML(getTextFromNodes(_json));

      }

      // console.log(htmlString)
    })


  }

  const _editorConfig = {
    // The editor theme
    namespace: 'sylloEditor',
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    nodes: [
      HeadingNode,
      HashtagNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
      HorizontalRuleNode,
      MentionNode
    ],
    editorState: props?.intState !== ''? props?.intState: null
  };
  const [editorConfig, setConf] = useState(_editorConfig)

  useEffect(()=>{
    if (props?.intState != ""){
      const c = {...editorConfig,  editorState: props?.intState}
      setConf(c);
      // const root = $getRoot();
      // root.importJSON(JSON.parse(props?.intState))
    }
  },[props?.intState]);
  

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={props.className + " editor-container"}>
        {/* <Affix offsetTop={100}> */}
          <ToolbarPluginNS tools={props?.tools} uploadFile={props?.uploadFile} style={props?.borderBottom ? { borderBottom: "1px solid #d5d5d5" } : {}} />
        {/* </Affix> */}
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={onChange} />
          <HashtagPlugin />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <NewMentionsPlugin />
          <ListPlugin />
          <ImagesPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <HorizontalRulePlugin />
      
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>
  );
}
