import React from 'react';
import { Row, Col, Divider, Avatar, Tag, Button, Skeleton, Space, Dropdown, Badge } from 'antd';
import moment from 'moment';
import { Dot, Trash } from "@phosphor-icons/react";
import BlankState from '../BlankState';
import { RWebShare } from 'react-web-share';
import { DotsThreeVertical, Export } from '@phosphor-icons/react';

import PostViewer from '../Editor/PostViewer';
import Toast from '../utils/Toast';



const MyHubInSight = ({ data, loader, deleteFeed, details }) => {

    const onClick = async (key, id) => {
        if (key === '2') {

            await deleteFeed(id.entity_uuid);
            Toast('Deleted!', "success")
        }
    };

    const items = (item) => {
        let i = [
            {
                key: '1',
                icon:
                    <RWebShare
                        data={{
                            text: "Syllo: Note",
                            url: `${process.env.REACT_APP_URL}feed-view/${item.entity_uuid}`,
                            title: item?.title,
                        }}

                    ><span><Export size={20} color="#000000" weight="thin" className="me-1" /> Share</span>
                    </RWebShare>
            }, {
                label: (
                    <span className="fw400"> Delete</span>
                ),
                key: '2',
                icon: <Trash size={20} color="#000000" weight="thin" />
            }

        ]

        return i
    };

    return (

        <Row>

            {
                loader ? <Skeleton></Skeleton> : data.length === 0 ? <Col span={12} offset={6} className="md100 text-center">
                    <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} />

                </Col> : data.map((item, index) => {
                    return <Col span={24} key={index} className='single-channel-view'>

                        <Row >
                            <Col md={22} xs={21} >
                                <Space align='center' size={2} wrap split={<Dot size={24} />}>
                                    <p className='fs12 text-grey'>  {moment(item.created_at).format("Do MMM YYYY, h:mm A")} </p>
                                    <p className='fs12 text-grey' >  {item.likes} Likes </p>
                                    <p className='fs12 text-grey' >  {item.comments} Comments </p>
                                </Space>

                            </Col>
                            <Col md={2} xs={3} className="text-right">
                                {
                                    <Dropdown trigger={['click']} menu={{ items: items(item), onClick: ({ key }) => onClick(key, item) }} placement={"bottomCenter"} overlayStyle={{ minWidth: "120px" }}>
                                        <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                    </Dropdown>
                                }
                            </Col>
                        </Row>
                        <Row gutter={10} className='md15'>
                            <Col lg={item.image_url?18:24} xs={item.image_url?20:24}>
                                <a href={"/feed-view/" + item.entity_uuid} style={{ flexGrow: 1 }} className='fs16 fw600'>
                                    {item.title}
                                </a>
                                <a href={"/feed-view/" + item.entity_uuid} style={{ flexGrow: 1 }}>
                                    <div className="title">

                                        <PostViewer intState={item?.description} />
                                        {/* <Paragraph onClick={() => details(item)} ellipsis={{ rows: 2 }}>{item.description}</Paragraph> */}

                                    </div>
                                </a>
                            </Col>
                            {
                                item.image_url && <Col lg={6} xs={4}>
                                <img src={item.image_url} alt="Post image" srcset="" width={'100%'} />
                                </Col>
                            }
                            
                        </Row>
                        <Divider className='mt-1'></Divider>
                    </Col>
                })
            }




        </Row>
    );
}

export default MyHubInSight;
