
import { Row, Col, Input, Button, Space, Select, Divider, Tooltip, message, Modal, Upload, Popover } from "antd";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./constants";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { CloudArrowUp, FilePlus, Paperclip, Trash, Sliders, Palette, Gear, CaretDown } from "@phosphor-icons/react";
import request from "../../store/request";
import { DownOutlined } from "@ant-design/icons";
import EmojiPicker, { EmojiStyle, Emoji } from "emoji-picker-react";
import ContentAdvancedFeature from "../utils/ContentAdvancedFeature";

import Toast from "../utils/Toast";

const { Option } = Select



const ReactEditorJS = new createReactEditorJS();


var url_string = window.location.href
var url = new URL(url_string);
var _id = url.searchParams.get("id");

const ReactEditor = (props) => {

    const [title, settitle] = useState("")
    const [meta_title, setmeta_title] = useState("")
    const [tags, settags] = useState([])
    const [loader, setloader] = useState(false)
    const [children, setchildren] = useState([])
    const [attachment, setattachment] = useState(false)
    const [files, setfiles] = useState([])
    const [bcolor, setBColor] = useState("#fff")
    const [fsize, setFSize] = useState("6px")
    const [emoji, setEmoji] = useState('1f5d2-fe0f')
    const [settings, setsettings] = useState(false);
    const [saving, setSaving] = useState(false);
    const [advancedFeature, setAdvancedFeature] = useState(true);
    const [advancedPayload, setAdvancedPayload] = useState({});

    const handleChange = (value) => {
        if (value.length >= 5) {
            return
        }
        settags(value)
    };


    const editorCore = useRef(null)
    const handleInitialize = useCallback((instance) => {
        editorCore.current = instance
    }, [])

    const handleSave = useCallback(async () => {
        const savedData = await editorCore.current.save();
        setloader(true)
        axios({
            method: 'put',
            url:  'market/feed/note',
            data: {
                id: _id,
                publish: false,
                ...savedData
            },
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        }).then(() => {
            setloader(false)
        }).catch((error) => {
            Toast(error.response.data.message, "error")
        });;

    }, [])

    const handlePublish = async () => {
        if (title === "") {
            Toast("Enter title!", "error")
            return
        }
        if (meta_title === "") {
            Toast("Add Some Description to note!", "error")
            return
        }
        setSaving(true);
        await axios({
            method: 'post',
            url:  'market/feed/note',
            data: {
                title: title,
                meta_title: meta_title,
                tags: tags,
                id: _id, publish: true,
                bg: bcolor, radius: fsize,
                emoji: emoji,
                ...advancedPayload
            },
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        }).then(() => {
            Toast("Created Successfully!", "success")
            setTimeout(() => {
                window.location.href = ("/dashboard")
            }, 500);

        }).catch((error) => {
            Toast(error.response.data.message, "error")
        });
        setSaving(false);

    }

    const handelSearch = (evt) => {
        axios({
            method: 'get',
            url:  'market/tag/search?key=' + evt,

            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        }).then((res) => {
            setchildren(res?.data?.data)
        }).catch((error) => {
            Toast(error.response.data.message, "error")
        });
    }


    const props1 = {
        name: 'file',
        accept: ".png,.jpg,.JPEG,.pdf,.docx",
        action:  `market/feed/note/${_id}/attachment`,
        headers: {
            Authorization: Cookies.get("Logintoken"),

        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                setfiles(info?.file?.response?.data)
            } else if (info.file.status === 'error') {
                Toast(`${info.file.name} file upload failed.`, "error");
            }
        },
        customRequest: (options) => request.customRequest(options, "note"),
    };

    const deleteFile = (id) => {
        axios({
            method: 'delete',
            url:  `market/feed/note/${_id}/attachment`,
            data: { attachment_id: id },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setfiles(data)
        }).catch((error) => {
            Toast(error.response.data.message, "error")
        })
    }

    const handleEmogiClick = (e) => {
        setEmoji(e.unified)
    };

    const emojicontent = () => {
        return <Row style={{ width: 'auto' }}>
            <Col span={24}>
                <EmojiPicker onEmojiClick={(e) => handleEmogiClick(e)} previewConfig={{ showPreview: false }} emojiStyle={EmojiStyle.GOOGLE} />
            </Col>
        </Row>
    };


    return (
        <Row className="p-1" justify={"center"} style={{ minHeight: "calc(100vh - 69px)" }}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} className='md50 m-md20 radius10' style={{ minHeight: "60vh", overflow: "auto", borderRadius: fsize, padding: "15px", backgroundColor: bcolor, overflow: "visible" }}>
                <Row >
                    <Col xs={{ span: 3 }} lg={{ span: 2 }} className="text-center note-pop md10">
                        <Emoji unified={emoji} size="46" emojiStyle={EmojiStyle.GOOGLE}></Emoji> <br />
                        <Popover trigger={['click']} content={emojicontent()}>
                            <Button type="text" icon={<DownOutlined />}></Button>
                        </Popover>
                    </Col>
                    <Col xs={{ span: 21 }} lg={{ span: 22 }} className="notes-input">
                        <Row>
                            <Col span={18} className="md10">
                                <Input maxLength={40} placeholder="Important Notes" style={{ backgroundColor: "transparent" }} value={title} onChange={(evt) => settitle(evt.target.value)} className="fs25" size="large" />

                            </Col>  
                            <Col span={6} className='text-right md10 '>
                                <span className='  '>
                                    <Button loading={saving} type="primary" onClick={() => handlePublish()} className="fs14 m-float-left " size={{ xs: "small", sm: "small", md: "large", lg: "large" }} >Publish</Button>

                                </span>

                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Row gutter={24} >
                                    <Col span={18}>
                                        <div className="note-div md10">
                                            <Input maxLength={100} placeholder="Enter description" style={{ backgroundColor: "transparent" }} value={meta_title} onChange={(evt) => setmeta_title(evt.target.value)} className=" fs12 fw400 " />

                                        </div>
                                    </Col>
                                    <Col span={6} className='text-right'>
                                        <Space >
                                            <img src={require('../../assets/images/uploadClound.svg').default} alt="img" />
                                            <span className="text-grey fs10">  {loader ? "Saving..." : "Saved"} </span>
                                        </Space>
                                        <br />
                                        <Space className="m-mx0 ">
                                            <p className="md10 m-ml15">
                                                <Tooltip placement="top" title={<span>Upload file</span>}>
                                                    <Button onClick={() => setattachment(true)} type="text" className="fs14"
                                                        icon={<Paperclip size={20} weight="regular" />}></Button>
                                                </Tooltip>
                                           

                                                <Tooltip placement="top" title={<span>Settings</span>}>
                                                    <Button onClick={() => setsettings(true)} type="text" className="fs14" icon={<Gear size={20} weight="regular" />}></Button>
                                                </Tooltip>

                                            </p>


                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16} className='select-text md5'>
                                        <Select
                                            mode="tags"
                                            bordered={false}
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Enter tags"
                                            value={tags}
                                            onChange={handleChange}
                                            onSearch={(evt) => handelSearch(evt)}
                                        >
                                            {children.map((item) => {
                                                return <Option value={item}>{item}</Option>
                                            })}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>



                    <Col span={22} offset={1}>
                        <Divider style={{ borderColor: ' rgba(0, 0, 0, 0.25)' }} />
                    </Col>

                </Row>
                <Row>
                    <Col span={24}>
                        <ReactEditorJS
                            tools={EDITOR_JS_TOOLS}
                            placeholder='Let`s write an awesome story!'
                            onChange={() => handleSave()}
                            onInitialize={handleInitialize}
                        // autofocus={true}
                        />
                    </Col>
                </Row>


            </Col>
            <Modal title="Upload Attachments" open={attachment} width={476} footer={false} onCancel={() => setattachment(false)}>
                <Row>
                    <Col span={24} className='padding25'>

                        {
                            files.length === 0 && <Row>
                                <Col span={24} className='text-center md10 notes-modal-card '>
                                    <CloudArrowUp size={50} color="var(--primary-color)" weight="light" />
                                    <p>No File Uploaded</p>
                                </Col>
                            </Row>
                        }


                        {
                            files.map((item) => {
                                return <Row className='md25'>
                                    <Col span={18} >
                                        <a className="fs14 fw400" href={item?.uri} target={"_blank"}>{item?.name}</a>
                                    </Col>
                                    <Col span={6} className='text-right '>
                                        <Trash size={20} className={"cursor"} color="#000000b3" onClick={() => deleteFile(item?.attachment_id)} weight="light" />
                                    </Col>
                                </Row>
                            })
                        }


                        <Upload
                            name="avatar"
                            multiple={false}
                            showUploadList={false}

                            Tooltip={false}
                            {...props1}
                        >

                            <Button type="primary" className="md30">
                                <FilePlus size={20} weight="light" /> <span className="ps-1"> Add file </span></Button>
                        </Upload>


                        {
                            files.length !== 0 && <Row>
                                <Col span={24} className='md35' >

                                    <Button type="primary" className="fs14 fw400" onClick={() => setattachment(false)} block>Save</Button>
                                </Col>
                            </Row>
                        }

                    </Col>
                </Row>
            </Modal>

            <Modal open={settings} onCancel={() => setsettings(false)} maskClosable={false} footer={[
                <Button type="dashed" onClick={() => setsettings(false)}>
                    Close
                </Button>]}>
                <Row className="padding20">
                    <Col span={24}>
                        <Space>
                            <Sliders size={20} />
                            <p className="fs16 fw600">Settings</p>
                        </Space>
                        <Divider className="md10" />
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <p className="fs14">Background color</p>

                            </Col>
                            <Col span={24} className='m-align-explore md20'>
                                <Space wrap>
                                    <div type="text" className="cursor" onClick={() => setBColor("#fff")} style={{ border: "1px solid grey", borderRadius: '4px', backgroundColor: 'white', height: '28px', width: '28px' }} />
                                    <div type="text" className="cursor" onClick={() => setBColor("#E8EAED")} style={{ borderRadius: '4px', backgroundColor: '#E8EAED', border: bcolor === "#E8EAED" ? "1px solid #efefef" : "1px solid white", height: '28px', width: '28px' }} />
                                    <div type="text" className="cursor" onClick={() => setBColor("#FFF475")} style={{ borderRadius: '4px', border: bcolor === "#FFF475" ? "1px solid #000" : "1px solid #FFF475", backgroundColor: '#FFF475', height: '28px', width: '28px' }} />
                                    <div type="text" className="cursor" onClick={() => setBColor("#CCFF90")} style={{ borderRadius: '4px', border: bcolor === "#CCFF90" ? "1px solid #000" : "1px solid #CCFF90", backgroundColor: '#CCFF90', height: '28px', width: '28px' }} />
                                    <div type="text" className="cursor" onClick={() => setBColor("#A7FFEB")} style={{ borderRadius: '4px', border: bcolor === "#A7FFEB" ? "1px solid #000" : "1px solid #A7FFEB", backgroundColor: '#A7FFEB', height: '28px', width: '28px' }} />
                                    <div type="text" className="cursor" onClick={() => setBColor("#AECBFA")} style={{ borderRadius: '4px', border: bcolor === "#AECBFA" ? "1px solid #000" : "1px solid #AECBFA", backgroundColor: '#AECBFA', height: '28px', width: '28px' }} />
                                    <div type="text" className="cursor" onClick={() => setBColor("#D7AEFB")} style={{ borderRadius: '4px', border: bcolor === "#D7AEFB" ? "1px solid #000" : "1px solid #D7AEFB", backgroundColor: '#D7AEFB', height: '28px', width: '28px' }} />
                                    <div type="text" className="cursor" onClick={() => setBColor("#F28B82")} style={{ borderRadius: '4px', border: bcolor === "#F28B82" ? "1px solid #000" : "1px solid #F28B82", backgroundColor: '#F28B82', height: '28px', width: '28px' }} />
                                </Space>

                            </Col>
                        </Row>
                    </Col>

                    <Col span={24} className="md10" style={{ marginBottom: "20px" }} >
                        <Button className="p-0" type="text" onClick={() => setAdvancedFeature(!advancedFeature)}
                        > More Options <CaretDown size={18} weight='bold' style={{ marginLeft: "5px" }} rotate={advancedFeature ? 0 : 90} /></Button>

                        {
                            advancedFeature && <ContentAdvancedFeature className='md20' onUpdate={(val) => setAdvancedPayload(val)} />
                        }

                    </Col>
                </Row>
            </Modal>
        </Row>
    );

}


export default ReactEditor;

