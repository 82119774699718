import React, { useState, useEffect } from "react";
import { Col, Row, Input, message, Upload, Radio, Space, Button, Checkbox, Avatar, Tooltip, Modal, Alert, Badge, Collapse,  } from "antd";
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";
import actions from "../../store/actions/index";
import { useHistory, withRouter } from "react-router";
import { connect } from "react-redux";
import axios from "../../store/axios";

import request from "../../store/request";
import Toast from "../utils/Toast";

const { Panel } = Collapse;

const CreateWhatsApp = (props) => {


    const [group, setgroup] = useState("telegram");
    const [g_name, setGName] = useState("");
    const [img, setImg] = useState("");
    const [modal, setModal] = useState(false);
    const [oneTime, setOneTime] = useState(true);
    const [description, setDescription] = useState("")
    const [price, setprice] = useState(0)
    const [tiresModal, settiresModal] = useState(false)
    const [tiers, setTiers] = useState([])
    const [selected, setselected] = useState(null)
    const [selectedTier, setSelectedTier] = useState([])
    const history = useHistory();

    const uploadProps = {
        name: 'file',
        accept: ".png,.jpg,.JPEG",
        action:  'account/file/upload',
        data: { type: "social_group" },
        headers: {
           Authorization: Cookies.get("Logintoken"),                    
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                Toast(`${info.file.name} file uploaded successfully`,"success");
                setImg(info.file.response.data.url)

            } else if (info.file.status === 'error') {
                Toast(`${info.file.name} file upload failed.`,"error");
            }
        },
        customRequest: (options) => request.customRequest(options, "social_group"),
    };

    const createGroup = () => {

        if (g_name === "") {
            Toast("Enter Group Name","error")
            return
        }

        if (description === "") {
            Toast("Enter Group Description","error")
            return
        }

        if (selected === null && price === 0) {
            Toast("Enter Price","error")
            return
        }

        axios({
            method: 'post',
            url:  "lighthouse/auth/telegram/channel",
            data: {
                "group_name": g_name,
                "desc": description,
                "img_url": img,
                "price": selected ? 0 : price,
                "tier_id": selected
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            setModal(true)
        }).catch((err) => {
            Toast(err.response.data.message,"error")
        });

    }

    useEffect(() => {
        props.getMemebrship();
    }, [])

    useEffect(() => {
        setTiers(props._tiers)
    }, [props._tiers])



    const setSelectedTiers = (item) => {
        setselected(item)
        setSelectedTier(tiers.find((a) => a.tier_id === item))
    }

    return (
        <div className="row">
            <div className="col-md-12 dashboard-page">
                <div className="row">
                  

                    <div className="col-xsm-12 ">
                        <div className="row">
                          
                            <Row className="md70">
                                <Col lg={{ span: 10, offset: 7 }} xs={{ span: 22, offset:1 }} className=''>
                                    <Row>
                                        <Col span={24}>
                                            <Radio.Group value={group} onChange={(evt) => setgroup(evt.target.value)}>
                                                <Radio value={"telegram"} className='text-white'>Telegram</Radio>
                                                <Radio value={"Whatsapp"} disabled>Whatsapp</Radio>
                                            </Radio.Group>
                                        </Col>
                                        <Col span={24} className='m-md15' >
                                            <div className="whatsapp-div">
                                                <p className="fs16 fw500">Create  Group <Tooltip title={<span>info</span>}><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.00002 0.664062C4.40692 0.664062 0.666687 4.4043 0.666687 8.9974C0.666687 13.5905 4.40692 17.3307 9.00002 17.3307C13.5931 17.3307 17.3334 13.5905 17.3334 8.9974C17.3334 4.4043 13.5931 0.664062 9.00002 0.664062ZM9.00002 2.33073C12.6914 2.33073 15.6667 5.30599 15.6667 8.9974C15.6667 12.6888 12.6914 15.6641 9.00002 15.6641C5.30861 15.6641 2.33335 12.6888 2.33335 8.9974C2.33335 5.30599 5.30861 2.33073 9.00002 2.33073ZM8.16669 4.83073V6.4974H9.83335V4.83073H8.16669ZM8.16669 8.16406V13.1641H9.83335V8.16406H8.16669Z" fill="#BDC2CC" />
                                                </svg></Tooltip>
                                                </p>
                                                <p className="fs14 fw400 text-grey">Create paid private group on {group}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="md35">
                                        <Col span={18}  >
                                            <p className="fs14 fw400 ">Group Name</p>
                                            <Input value={g_name} onChange={(evt) => setGName(evt.target.value)} placeholder="i.e, My Paid Group" className="md10" />
                                        </Col>

                                        <Col span={6}  >
                                            <div style={{ float: "right" }}>
                                                <Avatar src={img} size={70} className="edit-avatar" >{g_name.substring(0, 1)}</Avatar>
                                                <Upload className="edit-avatar-upload"  {...uploadProps} showUploadList={false} preview={false}> <Button className="edit-avatar-btn" type="primary" icon={<EditOutlined size={20} />} shape="circle"></Button></Upload>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="md20">
                                        <Col span={24}  >
                                            <p className="fs14 fw400 ">Description</p>
                                            <Input value={description} onChange={(evt) => setDescription(evt.target.value)} placeholder="Group description" size="large" className="md10" />
                                            <p className="fs12 fw400 text-grey md5">Provide a description about your group which helps a user in purchasing a subscription</p>
                                        </Col>
                                    </Row>
                                    <Row className="md25">
                                        <Col span={24}>
                                            <p className="fs16 fw400 ">Set Access Fee</p>
                                        </Col>
                                        <Col span={24} className='access-fee md20'>

                                            <Radio.Group className="radio" value={oneTime} onChange={(e) => setOneTime(e.target.value)}>
                                                <Radio value={true} >One-Time Price</Radio>
                                                <Radio value={false}>Subscription Plan</Radio>
                                            </Radio.Group>

                                            {
                                                oneTime === true ? <>
                                                    <p className="fs14 fw400 md10 ">Price</p>
                                                    <Row >
                                                        <Col lg={{ span: 24}} xs={{ span: 24 }}>
                                                            <Input type={"number"} value={price} onChange={(evt) => setprice(evt.target.value)} prefix={<span className="fs16 fw500">₹</span>} placeholder="i.e., 499" className="md10 prefix-icons" />

                                                        </Col>
                                                    </Row>
                                                    <p className="fs11 fw400 md5 " style={{ fontStyle: 'italic' }}>You can choose to collect a one-time price for your group which will give lifetime access to your user</p>

                                                </> :
                                                    <>
                                                        <Row>
                                                            {
                                                                selectedTier.length !== 0 ? < Col lg={{ span: 24 }} xs={{ span: 24 }} className='subscription-card md10'>

                                                                    <Row className="">
                                                                        <Col lg={{ span: 5 }} xs={{ span: 5 }} >
                                                                            <div className="image-card text-center">
                                                                                <Avatar src={selectedTier?.banner_url} shape="square" size={60}></Avatar>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={{ span: 15 }} xs={{ span: 14 }} className='md15 ps-4'>
                                                                            <p className="m-0 fs14 fw400">{selectedTier?.title}</p>
                                                                            <p className="fs12 fw300 md5 ">₹{selectedTier?.price}/month</p>
                                                                        </Col>
                                                                        <Col lg={{ span: 4 }} xs={{ span: 4 }} className='text-right'>
                                                                            <Button type="text" onClick={() => {
                                                                                setSelectedTier([])
                                                                                setselected(null)
                                                                            }} icon={<CloseOutlined />}></Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col> : <Button type="text" onClick={() => settiresModal(true)} className="primary-light-btn md30" > Select Membership </Button>
                                                            }

                                                        </Row>

                                                    </>
                                            }



                                        </Col>
                                    </Row>
                                    <Row className="md25">
                                        <Col span={24}>
                                            <p className="fs16 fw400 ">Advanced (optional)</p>
                                        </Col>
                                        <Col span={24} className='access-fee md20' >
                                            <p className="fs14 fw500 ">Syllo Channele</p>
                                            <div className="checkbox">
                                                <Checkbox value={1} >Create a new channel in Syllo with the same information given above</Checkbox> <br />
                                                <Checkbox value={2}>Add to existing membership</Checkbox>

                                            </div>


                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} className='md30 text-center mb-4'>
                                            <Space>
                                                <Button type="dashed" className="" onClick={() => history.goBack()}>Cancel</Button>
                                                <Button type="primary" className="ms-3" onClick={() => createGroup()}>Save</Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                                <Modal open={modal} width={500} footer={false} closable={false}>
                                    <Row>
                                        <Col span={20} offset={2} className='padding20 md20' >
                                            <Row>
                                                <Col span={24} className='text-center'>
                                                    <img src={require('../../assets/images/WhatsAppImg/whatsapp.png')} alt="" />
                                                    <p className="fs16 fw500 md20"> Your paid {group} Group request is submitted succesfully with us!</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24} className=' md30'>
                                                    <Alert type="info" message="Our team will get in touch with you under 24 working hours to proceed ahead!" showIcon={false} />
                                                </Col>
                                                <Col span={24} className='md30 text-center mb-3'>
                                                    <Button type="primary" onClick={() => window.location.href = "/my-hub"}> View Groups</Button>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Modal>

                                <Modal open={tiresModal} width={650} title={'Membership Tiers'} footer={false} closable={false}>
                                    <Row className='padding20' >
                                        <Col span={24} >
                                            <Alert type="info" message="Select membership tier to include in Or Click on + Add tier button to create new membership to include in." showIcon={false} />

                                        </Col>
                                        <Col lg={{ span: 24 }} xs={{ span: 24 }} className="md20">
                                            <Row gutter={20}>
                                                <Col lg={{ span: 19 }} xs={{ span: 24 }}>
                                                    <Radio.Group className="radio-list w-100" value={selected} onChange={(evt) => setSelectedTiers(evt.target.value)}>
                                                        {
                                                            tiers.map((item, i) => {
                                                                return <Radio value={item?.tier_id} key={i} size='large' className="w-100 mb-2">
                                                                    <Row className="py-1" >
                                                                        <Col lg={{ span: 6 }} xs={{ span: 8}} className='text-center'>
                                                                            {/* <Avatar size={70} src={item?.banner_url} shape='square'></Avatar> */}
                                                                            <div style={{ backgroundImage: `url(${item?.banner_url})` }} className="h-100 membership-title">
                                                                                {/* <img className="md20" src={item?.banner_url} height={"100%"} alt="" /> */}
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={{ span: 18 }} xs={{ span: 16 }}>
                                                                            <p className="m-0 ps-2 fs14 fw400">{item?.title} </p>
                                                                            <Space align="center">
                                                                                <p className="fs12 fw300 ps-2">₹{item?.price}/month</p>
                                                                                <Badge status="default" />
                                                                                <p className="fs12 fw300">0 subscribers </p>
                                                                            </Space>
                                                                            <div className="md5 membership-collapse mb-2 ps-1 ">
                                                                                <Collapse bordered={false} collapsible="header" defaultActiveKey={[]}>
                                                                                    <Panel header="Benefits" key="1">
                                                                                        {
                                                                                            item?.benefits.map((item2, index) => {
                                                                                                return <li key={index}>{item2?.title}</li>
                                                                                            })
                                                                                        }                                                                                       
                                                                                    </Panel>
                                                                                </Collapse>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Radio>
                                                            })
                                                        }
                                                    </Radio.Group>
                                                </Col>
                                                <Col lg={{ span: 5 }} xs={{ span: 24 }} >
                                                    <Row>
                                                        <Col lg={{ span: 24 }} xs={{ span: 12 }} className='m-md15'>
                                                            <Button href="#" className='primary-light-btn' type="text" icon={<PlusOutlined />}> Add tier</Button>
                                                        </Col>
                                                        <Col lg={{ span: 24 }} xs={{ span: 12 }} className='md10 m-md15'>
                                                            <Button type="primary" onClick={() => settiresModal(false)}> Save & Close</Button>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Modal>
                            </Row>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
const mapStateToProps = (state) => ({
    _tiers: state.profile.membership_tiers,
});
const mapDispatchToProps = (dispatch) => ({
    getMemebrship: (v) => dispatch(actions.getMembershipTiers(v)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateWhatsApp)
);