import React, { useState, useEffect } from "react";
import { Avatar, Button, Col, Row, Tag, Skeleton, Radio, Space, Modal } from "antd";
import { CheckCircle } from "@phosphor-icons/react";
import Cookies from "js-cookie";
import axios from "../../store/axios";
import BlankState from "../BlankState";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { CAMPUS_WEB } from "../../utils";


const CampusSubscription = (props) => {

    const [data, setData] = useState([1, 2, 3, 4, 5]);
    const [offset, setOffset] = useState(0);
    const [loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [modal_data, setModal_data] = useState();

    const history = useHistory();


    useEffect(() => {
        MentorshipAPI();

    }, [])


    function MentorshipAPI() {
        setLoader(true)
        axios({
            method: 'get',
            url:  `v1.0/membership/subscriptions?count=10&offset=${offset}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            setLoader(false)
            const data = res.data.data
            setData(data)
        })
        setOffset(offset + 10)
    }

    function ModalData(item) {
        setModal_data(item)
        setModal(true)
    }




    return (
        <Col span={24} >
            {
                loader ? <Skeleton active></Skeleton> :
                    <Row>
                        <Col span={24}>
                            <Radio.Group
                                defaultValue={0} style={{ display: "inline-flex", marginBottom: "21px" }}
                                buttonStyle="solid"
                            >

                                <Radio.Button
                                    value={0}
                                    className="margin-right"
                                    style={{ width: "max-content" }} >
                                    Active  </Radio.Button>
                                <Radio.Button
                                    value={1}
                                    className="margin-right"
                                    style={{ width: "max-content" }} >
                                    In-active  </Radio.Button>
                            </Radio.Group>
                        </Col>
                        {
                            data.map((item, i) => {
                                return <Col key={i} span={24} className='hub-member mb-4' >
                                    <Row>
                                        <Col xs={16} lg={12} className='md5'>
                                            <p className="fs16 fw600" >{item?.name} </p>
                                            <Tag color="gold" className="md10"> {item?.title} </Tag>
                                        </Col>
                                        <Col xs={8} lg={12} className='text-end'>
                                            <Space size={10}>
                                                <p className="fs12 fw600 text-grey" > {item?.creator?.full_name} </p>
                                                <Avatar shape="square" size={34} src={item?.creator?.avatar_url}>  </Avatar>
                                            </Space>
                                        </Col>
                                    </Row>
                                    <Row className="md20">
                                        <Col xs={24} lg={12}>

                                            <p className="fs12 fw500 text-grey" >Subscribed on: {moment(item?.updated_at).format("ll")} | Expire on: {moment(item?.active_till).format("ll")}</p>
                                        </Col>
                                        <Col xs={24} lg={12} className='text-end'>
                                            <Button type="link" onClick={() => ModalData(item)} >View Detail</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            })
                        }

                        {
                            data?.length === 0 &&
                            <Col span={24} className="md60 text-center">
                                <BlankState img={require("../../assets/images/blank/no-data-found.svg").default} height={200} />

                            </Col>

                        }



                    </Row>
            }

            <Modal title="Axis45 Campus Subscription" open={modal} footer={false} onCancel={() => setModal(false)}>
                <Row className="md20 mb-5">
                    <Col span={22} offset={1}>
                        <Row >
                            <Col span={16}>
                                <p className="fs16 fw600" style={{ color: "#013238" }}>{modal_data?.title} </p>
                            </Col>
                            {/* <Col span={8} className="text-right">
                                <p className="fs16 text-grey">{modal_data?.price} Coins /month</p>
                            </Col> */}
                            <Col span={24} className="md15">
                                <p className="fs14 text-grey">Lorem ipsum dolor sit amet,
                                    consectetur adipiscing elit. Fusce for the faucibus placerat
                                    ligula pellentesque molestie. Proin eu finibus lor</p>
                            </Col>

                            {
                                modal_data?.benefits && modal_data?.benefits?.map((item, i) => {
                                    return <Col span={24} key={i} className="md10">
                                        <Space size={12}>
                                            <CheckCircle size={20} weight="fill" color="var(--primary-color)" />
                                            <p className="fs14">{item?.title} </p>
                                        </Space>
                                    </Col>
                                })
                            }

                        </Row>
                        <Row className="card-plan-gredient md30">
                            <Col xs={24} lg={12} className=''>
                                <p className="fs12 fw500 text-grey" > Subscribed on: {moment(modal_data?.updated_at).format("ll")}
                                    | Expire on: {moment(modal_data?.active_till).format("ll")}</p>


                            </Col>
                            <Col xs={24} lg={12} className='text-end'>
                                <p className="fw500 text-grey" >Tenure: {modal_data?.tenure_month}  months</p>
                                <p className="fw500 text-grey md10" >Amount: ₹ {modal_data?.price} /-</p>

                            </Col>

                            <Col xs={24} lg={12} className="md20">
                                <Space size={20}>
                                    <Avatar size={46} shape="square" src={modal_data?.creator?.avatar_url} ></Avatar>
                                    {/* <Space size={20}></Space> */}
                                    <p className="fs16 fw600" > {modal_data?.creator?.full_name} </p>
                                </Space>
                            </Col>
                            <Col xs={24} lg={12} className='text-end md20'>
                                <Button type="link" href={`${CAMPUS_WEB}campus/${modal_data?.campus_slag}`}>Campus</Button>

                            </Col>
                        </Row>

                    </Col>
                </Row>

            </Modal>

        </Col>
    );
}
export default CampusSubscription;