import { Col, Row, Space, Typography, Button, Skeleton, Tag, Dropdown, Modal, message } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { DotsThreeVertical, Export, Globe, PencilSimple, Trash } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import BlankState from "../BlankState";
import { RWebShare } from "react-web-share";

const { Paragraph } = Typography

const ProfileCourse = (props) => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [more, setMore] = useState(false)
    const [offset, setOffset] = useState(0)
    const history = useHistory();
    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        callAPI();
    }, [])

    async function callAPI(reload = false) {

        let o = offset;
        if (reload) {
            o = 0
        }

        await axios({
            method: 'get',
            url:  "v1.0/courses?&user_code=" + props?._user_id + "&count=10&offset=" + o,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setData(data)
            setLoader(false)
            setMore(data.length === 10)
        })
        if (!reload) {
            setOffset(offset + 10)
        }

    }

    const items = (item) => {
        var i = [
            {
                label: (
                    <span className="fw400"> Update</span>
                ),
                key: '1',
                icon:  <PencilSimple size={24} color="#000000" weight="thin" />
            },
            {
                key: '3',
                icon: <RWebShare
                    data={{
                        text: "Course",
                        url: `${process.env.REACT_APP_URL}course/${item.course_id}`,
                        title: item?.title,
                    }}

                ><span><Export size={24} color="#000000" weight="thin" className="me-1" /> Share</span>
                </RWebShare>
            },
        ]

        i.push({
            label: (
                <span className="fw400"> Delete Course</span>
            ),
            key: '2',
            icon:  <Trash size={24} color="#000000" weight="thin" />
        })
        return i;
    };

    const confirm = (item) => {
        modal.confirm({
            centered: true,
            icon: <></>,
            content: <>
                <div >
                    Are you sure? Want to delete this course? <br /> <p className="fw600">{item?.title}</p>
                </div>
            </>,
            className: "mentorship-delete",
            okText: 'Delete Course',
            cancelText: 'Cancel',
            onOk: (async () => {
                await axios({
                    method: 'delete',
                    url:  "v1.0/course?id=" + item?.course_id,
                    headers: {
                        Authorization: Cookies.get("Logintoken")
                    },
                }).then(() => {
                    callAPI(true);
                    message.success('Deleted Successfully!');
                });
            })
        });
    };

    const menuClick = ({ key, item }) => {
        if (key == '1') {
            history.push(`/course/${item?.course_id}/edit`);
        }
        else if (key == '2') {
            confirm(item);
        }
    };
    return (
        <Row>
            <Col span={24} className='md15 mb-5'>
                {
                    loader && <>
                        <Row gutter={{ xs: 15, sm: 16, md: 20 }}>

                            <Col xs={24} xxl={8} md={12} sm={12} className='md15'>
                                <Skeleton active={true} shape="square" ></Skeleton>
                            </Col>
                            <Col xs={24} xxl={8} md={12} sm={12} className='md15'>
                                <Skeleton active={true} shape="square" ></Skeleton>
                            </Col>
                            <Col xs={24} xxl={8} md={12} sm={12} className='md15'>
                                <Skeleton active={true} shape="square" ></Skeleton>
                            </Col>
                            <Col xs={24} xxl={8} md={12} sm={12} className='md15'>
                                <Skeleton active={true} shape="square" ></Skeleton>
                            </Col>
                        </Row>
                    </>
                }
                
                <Row gutter={{ xs: 15, sm: 16, md: 20 }}>
                    {contextHolder}
                    {
                        data.map((item, i) => {
                            return <Col key={i} xs={24} xxl={12} md={12} sm={12} className="md20">
                                <div className="min-course-card">
                                    <a href={`/course/${item?.course_id}`} style={{ flexGrow: 1 }}>
                                        <img src={item?.img_url}></img>
                                        <div style={{ padding: "16px" }}>
                                            <div className="title">
                                                <Paragraph className="fw600" ellipsis={{ rows: 2 }}>{item.title}</Paragraph>
                                                <div className="menu-surface-anchor">
                                                    {
                                                        props?.menu && <Dropdown trigger={['click']} menu={{ items: items(item), onClick: ({ key }) => menuClick({ key: key, item: item }) }} placement={"bottomCenter"} overlayStyle={{ minWidth: "120px" }}>
                                                            <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                                        </Dropdown>
                                                    }

                                                </div>

                                            </div>
                                            <span className="update" >Updated {moment(item?.updated_at).fromNow()}</span>
                                        </div>
                                    </a>
                                    <div className="foot tag-bg">
                                        {
                                            item?.language !== "" && <Space>
                                                <Globe size={18} color="#8a8d8f" weight="thin" />
                                                <p className="fs13 fw300">{item?.language}</p>
                                            </Space>
                                        }
                                        <Tag> {item?.is_paid ? "Paid" : "Free"}</Tag>

                                    </div>
                                </div>
                            </Col>
                        })
                    }

                </Row>

                <Row>
                    {
                        more && <Col span={24} className="text-center md30">
                            <Button type="text" onClick={() => callAPI()}>Load more</Button>
                        </Col>
                    }

                </Row>
                {
                    data?.length === 0 && <Row>
                        <Col span={24} className="md60 text-center">
                            <BlankState img={require("../../assets/images/blank/no-data-found.svg").default} height={200} />

                        </Col>
                    </Row>

                }

            </Col >
        </Row>


    );
}
export default ProfileCourse;
