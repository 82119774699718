import { Col, Row, Avatar, Button, Skeleton, Space } from "antd";
import React from "react";
import BlankState from "../BlankState";

const NewChannels = ({ channels, loader }) => {
    return (

        <Row gutter={16}>

            {
                loader ? <Skeleton></Skeleton> : channels.length === 0 ? <Col className="gutter-row" span={24}>
                    <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} /></Col> :
                    channels.map((item, k) => {
                        return <Col key={k} xs={{ span: 24 }} lg={{ span: 12 }} className="md15">
                            <div className="new-channel-card">
                                <Space size={12} align="top">
                                    <Avatar src={item?.img_url} size={60} />
                                    <div>
                                        <a href={`/channel/${item.uid}/${item.channel_id}`}  className="fs16 fw500">{item?.name}</a>
                                        <p className="fs12 fw400 text-grey"> {item?.members} members </p>
                                        <Button size="small" type="primary" href={`/channel/${item.uid}/${item.channel_id}`} className='m-pl8 text-white md10'> {item?.joined ? "GO TO CHANNEL" : "JOIN"}  </Button>
                                    </div>
                                </Space>
                            </div>
                        </Col>
                    })
            }


        </Row>

    );
}
export default NewChannels;