import { Button } from "antd";
import React from "react";

const LoadMoreView = ({loader, data, onLoadmore }) => {

    return (
        <div className="text-center md20 mb-5">
            {
                data && data.length >= 10 && <Button loading={loader} type="primary" onClick={onLoadmore}>Load more</Button>
            }
            
        </div>
    );
}
export default LoadMoreView;
