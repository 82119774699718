import { Avatar, Button, Checkbox, Col, Divider, Progress, Row, Space, Tag, Tooltip, Menu, Dropdown, Skeleton, message } from "antd";
import { CaretLeft, Copy, DotsThreeOutlineVertical, Export } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import "./react-alice.scss"
import 'react-alice-carousel/lib/alice-carousel.css';
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { useHistory, useParams } from "react-router";
import { RWebShare } from "react-web-share";
import MentorshipCard from "./MentorshipCard";
import moment from "moment";



const MentorshipBooking = (props) => {

    const [loader, setLoader] = useState(true)
    const [data, setData] = useState(null)
    const params = useParams();

    const [messageApi, contextHolder] = message.useMessage();
    const key = 'mentorshipComplete';

    const history = useHistory();
    const booking_id = params["id"];

    const goToProfile = (code) => {
        history.push("/profile/" + code)
    };

    const getData = () => {
        axios({
            method: 'get',
            url:  `v1.0/mentorship/booking/${booking_id}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            setData(res.data.data)
            setLoader(false)
        }).catch((error) => {
            console.log(error)
        })
    }



    useEffect(() => {
        getData();
    }, []);

    const markComplete = (completed, item, i) => {
        const payload = {
            key_id: item.key_id,
            completed: completed.target.checked
        }
        messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
        });
        axios({
            method: 'post',
            url:  `v1.0/mentorship/booking/${booking_id}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            },
            data: payload
        }).then(res => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Updated!',
                duration: 2,
            });
            let _new = { ...data };
            _new.syllabus[i].completed = completed.target.checked
            _new.progress = res.data.data.p;
            setData(_new);
        }).catch((error) => {
            messageApi.open({
                key,
                type: 'error',
                content: error.message,
            });
        })

    }



    const menu = (data) => (
        <Menu
            items={[

                {
                    label: (
                        <span className="fs12 fw400"> <Copy size={18} color="#181816" weight="thin" /> Copy Link</span>
                    ),
                    key: '1',
                },
            ]}
        />

    );


    const showJoinButton = (date) => {
        const givenDatetime = moment(date);
        const currentDatetime = moment();
        if (givenDatetime.isSameOrAfter(currentDatetime, "date")) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <Row>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 14, offset: 5 }} sm={{ span: 22, offset: 1 }} className='md50'>
                {
                    loader ? <Skeleton /> :
                        <Row>
                            <Col xs={{ span: 18 }} lg={{ span: 19 }} sm={{ span: 18 }} >
                                <Space>
                                    <Button onClick={() => history.goBack()} className="p-0" type="link" icon={<CaretLeft size={24} color="#1c1c1c" />}> </Button>
                                    <p className="fs14 fw400">Back</p>
                                </Space>
                            </Col>
                            <Col xs={{ span: 6 }} lg={{ span: 5 }} sm={{ span: 6 }} className='text-right'>


                                <RWebShare
                                    data={{
                                        text: "Mentorship",
                                        url: `${process.env.REACT_APP_URL}mentorship/${data?.mentorship_id}`,
                                        title: data?.name,
                                    }}

                                >
                                    <Button type="text" className="me-3" shape={"circle"} icon={<Export size={20} color="#586A89" />}></Button>
                                </RWebShare>

                                <Dropdown trigger={['click']} overlay={menu(data)} placement={"bottomCenter"} overlayStyle={{ minWidth: "141px" }}>

                                    <Button type="text" shape="circle" className="thread-icon"
                                        icon={<DotsThreeOutlineVertical size={17} color="#586A89" weight="fill" />}></Button>
                                </Dropdown>

                            </Col>
                            <Col xs={{ span: 22, offset: 1 }} md={{ span: 24, offset: 0 }} className="md20">
                                <MentorshipCard {...data} showAmount={true} />
                            </Col>
                        </Row>
                }

                {
                    loader ? <Skeleton active avatar /> :
                        <Row>
                            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24 }} sm={{ span: 22, offset: 1 }}>
                                <Row gutter={16} className='md30'>
                                    <Col xs={{ span: 12 }} lg={{ span: 8 }} sm={{ span: 12 }} >
                                        <Row className="cursor" onClick={() => goToProfile(data?.collaborator?.user_code)}>
                                            <Col xs={{ span: 7 }} lg={{ span: 5 }} sm={{ span: 6 }}>
                                                <Avatar size={40} src={data?.creator.img}> </Avatar>
                                            </Col>
                                            <Col xs={{ span: 17 }} lg={{ span: 19 }} sm={{ span: 18 }}>
                                                <p className="fs14 fw500">{data?.creator?.name}</p>
                                                <p className="fs12 text-grey">Host</p>

                                            </Col>
                                        </Row>

                                    </Col>
                                    {
                                        data?.collaborator !== null &&
                                        <Col xs={{ span: 12 }} lg={{ span: 8 }} sm={{ span: 12 }}>
                                            <Row className="cursor" onClick={() => goToProfile(data?.collaborator?.user_code)}>
                                                <Col xs={{ span: 6 }} lg={{ span: 5 }} sm={{ span: 6 }}>
                                                    <Avatar size={40} src={data?.collaborator?.img_url}> </Avatar>
                                                </Col>
                                                <Col xs={{ span: 18 }} lg={{ span: 19 }} sm={{ span: 18 }}>
                                                    <p className="fs14 fw500">{data?.collaborator?.name}</p>
                                                    <p className="fs12 text-grey">Collaborator</p>

                                                </Col>
                                            </Row>

                                        </Col>
                                    }

                                </Row>
                            </Col>
                        </Row>

                }
                <Row>
                    <Col span={22} offset={1}>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} sm={{ span: 24 }} className='booking-card md40'>
                                <Row className=''>
                                    {
                                        data?.isme && <Col span={14}>
                                            <Row>
                                                <Col span={3} >
                                                    <Avatar size={40} src={data?.learner.img}> </Avatar>
                                                </Col>
                                                <Col span={7} className=' md5'>
                                                    <p className="fs14 fw500">{data?.learner.name}</p>
                                                    <p className="fs12 text-grey">Learner</p>

                                                </Col>
                                            </Row>
                                        </Col>
                                    }

                                    {/* <Col span={24} className='booking-prog md15'>
                                        <p className="fs14 fw500">Progress ({data?.progress}%)</p>
                                        <Progress percent={data?.progress} showInfo={false} />
                                    </Col> */}
                                </Row>

                                <Row gutter={16} className={data?.isme ? 'md15' : ""}>
                                    <Col span={8}>
                                        <p className="fs14 fw500">Booked At</p>
                                        <p className="fs12 text-grey">{moment(data?.created_at).format("DD MMM YYYY")}</p>
                                    </Col>
                                    <Col span={8}>
                                        <p className="fs14 fw500">Schedule</p>
                                        <p className="fs12 text-grey">{moment(data?.datetime).format("DD MMM YYYY, h:mm A")}</p>
                                    </Col>
                                    {
                                        data?.venue ?
                                        <Col span={8}>
                                            <p className="fs14 fw500">Venue</p>
                                            <p className="fs12 text-grey">{data?.venue}</p>
                                        </Col>: 
                                        <Col span={8}>
                                        <p className="fs14 fw500">Type</p>
                                        <p className="fs12 text-grey">{data?.mentorship_type === 1?"Online":"Offline"}</p>
                                    </Col>
                                    }

                                </Row>


                            </Col>
                            <Col span={24} className=' md35'>
                                <p className="fs14 fw600">Few Learnings: </p>
                                <Row className='md20'>
                                    {contextHolder}
                                    {
                                        data?.syllabus.map((item, i) => {
                                            return <>
                                                <Col span={16} key={i}>
                                                    <ul key={i}>
                                                        <li>{item?.key} </li>
                                                    </ul>
                                                </Col>
                                                {
                                                    data?.isme && <Col span={8} className="text-right">
                                                        <span className="text-right checkbox">
                                                            <Tooltip title={item.completed ? "Mark as incomplete" : "Mark as completed"}>
                                                                <Checkbox checked={item.completed}
                                                                    onChange={(e) => markComplete(e, item, i)}></Checkbox>
                                                            </Tooltip>
                                                        </span>

                                                    </Col>
                                                }
                                            </>
                                        })
                                    }

                                </Row>
                                <Row>
                                    {
                                        data?.status === "BOOKED" && showJoinButton(data?.datetime) && data?.mentorship_type === 1 && <Col span={24} className='md20'>
                                            <Button type="primary" onClick={() => history.push(`/mentorship-booking/${booking_id}/join`)}>{data?.isme ? "Start" : "Join"} Now</Button>
                                        </Col>
                                    }

                                    <Col span={24} className='md20 '>
                                        <Divider />
                                    </Col>

                                    <Col span={24} className='md10 mb-4 '>
                                        <Space>
                                            {
                                                data?.tags.map((item, i) => {
                                                    return <Tag key={i}>{item}</Tag>
                                                })}
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Col>
        </Row>

    );
}
export default MentorshipBooking;

