import React, { useEffect, useState } from "react";
import { Col, Row, Divider, Button, Space, Menu, Tag, Dropdown, Avatar, Modal, Alert, Table, message } from "antd";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { MoreOutlined } from "@ant-design/icons";
import axios from "../../store/axios";
import moment from "moment";
import Cookies from "js-cookie";
import Toast from "../utils/Toast";




const columns = [
    {
        title: 'Txn. date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (key) => moment(key).format("DD/MM/YYYY")
    },
    {
        title: 'Amount',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Remark',
        dataIndex: 'remarks',
        key: 'remark',
    },
    {
        title: 'Payment Method',
        dataIndex: 'payment_method_type',
        key: 'payment_method',
    },
];





const SettingSubscription = (props) => {
    const [tiers, setTiers] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paymentModal, setpaymentModal] = useState(false)
    const [paymentsData, setpaymentData] = useState([])

    const [item, setitem] = useState("")
    useEffect(() => {
        props.getMembershipSubscriptions();
    }, [])

    useEffect(() => {
        setTiers(props._tiers)
    }, [props._tiers])

    const onClick = (key) => {

        showModal()
        setitem(key)
    };


    const getPayments = (item) => {
        axios({
            method: 'get',
            url:  `v1.0/membership/subscription/${item?.subscription_id}/trans`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            setpaymentData(res.data.data)
            setpaymentModal(true);
        })
    }

    const menu = (item) => {
        return <Menu>
            <Menu.Item key={1} onClick={() => getPayments(item)}>Payments</Menu.Item>
            {
                item?.status === "Active" && <Menu.Item key={2} onClick={() => onClick(item)}>Cancel Subscription</Menu.Item>
            }
        </Menu>

    };
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {

        axios({
            method: 'post',
            url:  `v1.0/membership/subscription/${item?.subscription_id}/cancel`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            Toast(res.data.message,"success")
            setIsModalOpen(false);
            props.getMembershipSubscriptions();
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };



    return (
        <Row style={{ padding: "40px 0px 30px 30px" }} className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18 fw400">Subscriptions</p>
                <Divider></Divider>
            </Col>

            < Col lg={{ span: 24 }} xs={{ span: 24 }} className=''>

                {
                    tiers.map((item) => {
                        return <Row className="md15 setting-membership-card">

                            {
                                item?.subscription_type === 3 ? <>
                                    <Col lg={{ span: 3 }} xs={{ span: 5 }} className=''>
                                        <Avatar src={item?.img} size={50}></Avatar>
                                    </Col>
                                    <Col lg={{ span: 17 }} xs={{ span: 14 }} className='m-px5 mb-3'>
                                        <p className="m-0 fs16 fw500">{item?.title} Telegram Channel</p>
                                        <Space >
                                            <img src={require('../.././assets/images/WhatsAppImg/telegram.png')} height='25' alt="" />
                                            <p className="fs12 fw400">Telegram Channel</p>
                                        </Space>

                                    </Col>
                                    <Col lg={{ span: 4 }} xs={{ span: 4 }} className='text-right '>
                                        <Dropdown trigger={"click"} overlay={menu(item)} placement="bottom">
                                            <Button type="text" shape="circle" onClick={(e) => e.preventDefault()} icon={<MoreOutlined />}>
                                            </Button>
                                        </Dropdown>
                                    </Col>

                                </> : <>

                                    <Col lg={{ span: 6 }} xs={{ span: 5 }} className=' '>
                                        <img src={item?.banner_url} alt="" style={{ height: '119px', width: '119px', borderRadius: '3px' }} />
                                    </Col>
                                    <Col lg={{ span: 14 }} xs={{ span: 14 }}>
                                        <p className="m-0 fs16 fw500">{item?.title} </p>
                                        <Space align="center" className="md5">
                                            <p className="fs14 fw400"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce faucibus placerat ... </p>
                                        </Space>
                                        <Space align="center" className="md10">
                                            <Avatar size={35} src={item?.creator?.avatar_url}></Avatar>
                                            <p className="fs14 fw500">{item?.creator?.full_name}</p>
                                        </Space>

                                    </Col>

                                    <Col lg={{ span: 4 }} xs={{ span: 4 }} className='text-right'>
                                        <Dropdown trigger={"click"} overlay={menu(item)} placement="bottom">
                                            <Button type="text" shape="circle" onClick={(e) => e.preventDefault()} icon={<MoreOutlined />}>
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                    <Col span={24} className='md20 ps-0 mb-2'>
                                        {/* {
                                    item?.benefits.map((item2, index) => {
                                        return <li key={index}>{item2?.title}</li>
                                    })
                                } */}
                                    </Col>
                                </>
                            }
                            <Divider className="m-0"></Divider>
                            <Col span={24} className="md25 mb-3">
                                <Row>
                                    <Col span={18}>
                                        <p className="fs14 fw400">Amount</p>
                                        <p className="fs14 fw400 text-grey">₹{item?.price} </p>
                                        <div className="md20">
                                            <p className="fs14 fw400">Status</p>
                                            <Tag color={item?.status === "Active" ? "success" : "warning"}>{item?.status}</Tag>
                                            {
                                                item?.status === "Cancelled" && <p className="fs14 fw400 text-grey">Cancelled at: {moment(item?.updated_at).format("DD/MM/YYYY")}</p>
                                            }
                                        </div>

                                    </Col>
                                    <Col span={6} className="">
                                        <p className="fs14 fw400">Last Payment</p>
                                        <p className="fs14 fw400 text-grey">{item?.last_payment && moment(item?.last_payment).format("DD/MM/YYYY")} <span className={item?.last_payment_status === "Success" ? "text-green" : ""}>({item?.last_payment_status})</span></p>
                                        <div className="md20">
                                            <p className="fs14 fw400">Subscribed At</p>
                                            <p className="fs14 fw400 text-grey">{item?.created_at && moment(item?.created_at).format("DD/MM/YYYY")} </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={18} className="">
                                        <div >
                                            <p className="fs14 fw400">Upcoming charges</p>
                                            <p className="fs14 fw400 text-grey">{item?.next_payment_date && moment(item?.next_payment_date).format("DD/MM/YYYY")} </p>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <p className="fs12 fw300">₹{item?.price}/month</p>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    })

                }
            </Col>
            <Modal title="Cancel subscription" open={isModalOpen} className='subscription-modal' width={500} footer={false} closable={false}>
                <Row className="nomargin padding20">
                    <Col span={22} offset={1} >
                        <Row>
                            <Col span={18} offset={3} className='text-center md20'>
                                <Alert type="info" message="Stop your subscription from renewing, Once cancelled, you cannot renew or reactivate it." showIcon={false} />
                                <div className="md40 mb-3">
                                    <Space orientation="center ">
                                        <Button type="text" className="back-btn" onClick={() => handleCancel()}>No, don’t</Button>
                                        <Button type="primary" className="" onClick={() => handleOk()}>Yes, Cancel</Button>
                                    </Space>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>

            </Modal>


            <Modal title="Payments History" open={paymentModal} width={650} onCancel={() => setpaymentModal(false)} footer={false} >
                <Row className="nomargin padding20">
                    <Col span={24} >
                        <Table dataSource={paymentsData} columns={columns} className='setting-table mb-3' Disable pagination={false} />
                    </Col>
                </Row>

            </Modal>


        </Row >
    );
}
const mapStateToProps = (state) => ({
    _tiers: state.profile.membership_tiers,
});
const mapDispatchToProps = (dispatch) => ({
    getMembershipSubscriptions: (v) => dispatch(actions.getMembershipSubscriptions(v)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SettingSubscription)
);