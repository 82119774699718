import React, { useState, useEffect } from "react";
import { Avatar, Button, Col, Divider, Progress, Row, message, Tag, Skeleton } from "antd";
import { DotsThreeVertical } from "@phosphor-icons/react";
import Cookies from "js-cookie";
import axios from "../../store/axios";
import BlankState from "../BlankState";
import moment from "moment";
import { useHistory } from "react-router";
import LoadMoreView from "../utils/LoadMoreView";


const MentorshipMyBookingCard = (props) => {

    const [data, setData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [lastItems, setLastItems] = useState([]);
    const [loader, setLoader] = useState(true);
    const history = useHistory()


    useEffect(() => {
        MentorshipAPI();
    }, [])


    async function MentorshipAPI() {
        setLoader(true)
        await axios({
            method: 'get',
            url:  `v1.0/mentorship/bookings?count=10&offset=${offset}&mybooking=true`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            setLoader(false);
            const data = res.data.data;
            setLastItems(data);
            setData((o) => [...o, ...data]);
        })
        setOffset(offset + 10)
    }

    const onLoadmore = () => {
        MentorshipAPI();
    }


    return (
        <Col span={24} >
            <Row>
                {
                    data.map((item, i) => {
                        return <Col key={i} span={24} className='hub-member mb-4' >
                            <Row gutter={10} className="cursor" onClick={() => history.push("/mentorship-booking/" + item?.booking_id)}>
                                <Col xs={21} lg={20} md={20} xl={20} className='md5' >
                                    <p className="fs16 fw600 m-fs14" >{item?.mentorship_name}</p>
                                    <p className="fs12 md5 text-grey"><strong>At {moment(item?.datetime).format("h:mm A, ll")}</strong></p>
                                </Col>
                                <Col xs={3} lg={4} md={4} xl={4} className='text-end'>
                                    <Button type="text" icon={<DotsThreeVertical size={25} color="#54514f" />}></Button>
                                </Col>

                            </Row>
                            <Row className='md10'>
                                <Col xs={18} lg={19} md={18} xl={18} >
                                    <p className="fs12 md5 text-grey">Booked at: {moment(item?.created_at).format("YYYY-MM-DD h:mm A")}</p>
                                </Col>
                                <Col xs={6} lg={5} md={6} xl={6} className='text-end'>
                                    {
                                        item?.status === "Active" ? <Tag color="green">Active</Tag>
                                            : <Tag color="magenta">Completed</Tag>
                                    }

                                </Col>

                            </Row>
                        </Col>
                    })
                }
                {
                    loader && <Skeleton active></Skeleton>
                }

                {
                    data?.length === 0 &&
                    <Col span={24} className="md60 text-center">
                        <BlankState img={require("../../assets/images/blank/no-data-found.svg").default} height={200} />

                    </Col>

                }

                <Col span={24}>
                    <LoadMoreView loader={loader} data={lastItems} offset={offset} onLoadmore={() => onLoadmore()} />
                </Col>

            </Row>
        </Col>
    );
}
export default MentorshipMyBookingCard;