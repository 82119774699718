import { Button, Col, Row, Skeleton, Space, Tag, Avatar } from "antd";
import {GlobalOutlined} from "@ant-design/icons"
import axios from "../../../store/axios";
import { useHistory } from "react-router-dom";
import { CheckCircle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import userToken from "../../../store/useToken"

import Toast from "../../utils/Toast";

const Onboard_ReviewBusinessInfo = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const history = useHistory();
    const { token } = userToken();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        await axios({
            method: 'get',
            url: "account/auth/onboard/preview",
            headers: {
                Authorization: token
            }
        }).then((res) => {
            const data = res.data.data
            setData(data)

        }).catch((err) => {
            Toast(err.response.data.message, "error")
        })
        setLoading(false);

    };

    const next = () => {
        if (data?.is_preferanced || data?.role_id === 9 || data?.role_id === 10 || data?.role_id ===8) {
            history.push('/dashboard');
        } else {
            history.push('/preference');
        }
    }
    return (
        <Row justify={"center"} className="m-padding20 md70">
            <Col lg={20} xs={24}>
                <Row gutter={[48, 48]} >
                    {
                        loading ? <><Skeleton active /> <Skeleton active /></> : <>
                            <Col lg={12} xs={24} >
                                <div className="grey-card md35 padding45">
                                <Row >
                                    <Col span={24} className="text-center" style={{}}>
                                        {
                                            data?.logo && <Avatar src={data?.logo} alt="business logo" size={120} />
                                        }
                                        <p className="fs22 fw600 md15"> {data?.name} </p>
                                        <p className="fs16 fw400  text-grey"> {data?.org_type||"NA"} </p>
                                        <p className="fs14 fw600 text-grey"> @{data?.username} </p>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={12} className="md30">
                                                {
                                                    data?.website ?
                                                        <Space>
                                                            <GlobalOutlined />
                                                            <a href={data?.website[0]} >{data?.website[0]} </a>
                                                        </Space>
                                                        // <Button href={data?.website} type="link" style={{ backgroundColor: '#efefef' }}> {data?.website[0]} </Button>
                                                        : ""
                                                }
                                            </Col>
                                            <Col span={12} className="text-right md30 ">
                                                <p className="fs16 fw400  text-grey"> Expertise </p>

                                                {
                                                    data?.experties && data.experties.map((item, i) => {
                                                        return <Tag key={i} className="md10"> {item}</Tag>
                                                    })
                                                }

                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                                </div>
                            </Col>
                            <Col lg={12} md={12} xs={24} >
                                <div className="grey-card md35 m-p15 padding45">
                                    <Row>
                                        <Col span={24}>
                                            <p className="fs18 fw500"> To make your business easier we have created the followings </p>
                                            <small className={"text-grey "}>You can change the names by going to Settings/Console</small>
                                        </Col>
                                        <Col span={20} className={"md20"}>
                                            <Space>
                                                <CheckCircle size={20} weight="fill" color="#3B6BE3" />
                                                <p className="fs14 fw400 ">{data?.name} Campus</p>

                                            </Space>
                                        </Col>
                                    </Row>
                                    <Row className="md10">
                                        <Col span={20}>
                                            <Space>
                                                <CheckCircle size={20} weight="fill" color="#3B6BE3" />
                                                <p className="fs14 fw400 ">{data?.name} TV</p>

                                            </Space>
                                        </Col>
                                        {/* <Col span={4} className='text-right'>
                                                <Button type="link"  href="/campus"> Edit </Button>
                                            </Col> */}
                                    </Row>
                                    
                                    <Row className="md10">
                                        <Col span={20}>
                                            <Space>
                                                <CheckCircle size={20} weight="fill" color="#3B6BE3" />
                                                <p className="fs14 fw400 ">Creator’s Suite</p>
                                            </Space>
                                            <div className="ps-4 md10">
                                                <ul>
                                                    <li>Newsletter</li>
                                                    <li>Poll</li>
                                                    <li>Report </li>
                                                    <li>  Event </li>
                                                    <li>  Note  </li>
                                                    <li>   & more </li>

                                                </ul>
                                            </div>
                                        </Col>

                                    </Row>

                                </div>
                            </Col>
                            <Col span={24} className='text-center md30 mb-4'>
                                <Button disabled={data === null} size="large" type="primary" onClick={() => next()}> Continue </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Col>
        </Row >
    );
}
export default Onboard_ReviewBusinessInfo;