import { Button, Card, Col, Input, message, Row, Space, Switch, Tag } from "antd";
import { Buildings, GraduationCap, ChalkboardTeacher } from '@phosphor-icons/react';
import axios from "../../store/axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import userToken from "../../store/useToken"
import Cookies from "js-cookie";
import Toast from "../utils/Toast";

const SignUpNext = (props) => {
    const history = useHistory();
    const { token } = userToken();

    const [loader, setLoader] = useState(false);
    const [professional, setProfessional] = useState(false);
    const [role, setRole] = useState("");
    const [erroText, setErrorText] = useState("Username is not available!");
    const [username, setUsername] = useState('');
    const [isAvailable, setIsAvailable] = useState(true);

    const [messageApi, contextHolder] = message.useMessage();
    const key = 'onboard';

    useEffect(() => {
        getUsername();
    }, []);

    const getUsername = async () => {

        await axios({
            method: 'get',
            url:  "account/auth/onboard",
            headers: {
                Authorization: token
            }
        }).then((res) => {
            const data = res.data.data
            if (data.account_step !== "_onboard") {
                history.push('/login');
                return
            }
            setUsername(data.username);
            setRole(data.role);

        }).catch((err) => {
            messageApi.open({
                key,
                type: 'error',
                content: err.response.data.message,
                duration: 3,
            });
        })

    };

    const skip = async () => {
        if (!isAvailable) {
            return
        }
        if (username === "") {
            Toast("Enter valid username", "error")
            return
        }
        setLoader(true);
        const payload = {
            account_step: "_registered",
            username: username
        }

        await axios({
            method: 'put',
            url:  "account/user/profile",
            data: payload,
            headers: {
                Authorization: token
            }
        }).then(() => {
            history.push("/preference");
        }).catch((err) => {
            Toast(err.response.data.message, "error")
        })
        setLoader(false)

    };


    const saveData = async () => {
        if (!professional) {
            skip();
            return
        }
        if (!isAvailable) {
            return
        }

        if (username === "") {
            Toast("Enter valid username!", "error")
            return
        }
        if (role === "") {
            Toast("Select role!", "error")
            return
        }
        setLoader(true);

        messageApi.open({
            key,
            type: 'loading',
            content: 'Creating account...',
        });

        const payload = {
            account_type: role,
            username: username
        }

        await axios({
            method: 'post',
            url:  "account/auth/onboard",
            data: payload,
            headers: {
                Authorization: token
            }
        }).then(() => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Account updated!',
                duration: 3,
            });
            if (role === 9 || role === 10) { // business or Educational
                history.push("/onboard/business/" + role)
            }else {
                history.push("/onboard/creator")
            }
            Cookies.set("__role", role);
        }).catch((err) => {
            setLoader(false);

            messageApi.open({
                key,
                type: 'error',
                content: err.response.data.message,
                duration: 3,
            });
        })
        setLoader(false)

    }

    const checkUsername = async (key) => {

        await axios({
            method: 'get',
            url:  `account/auth/username/verify?username=${key}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setErrorText("Username is not available!");
            setIsAvailable(!res.data.data.exists)
        }).catch((err) => {

        })

    };

    const onUsernameChange = (value) => {
        // logic
        setUsername(value);
        const re = new RegExp('^(?=.*[a-zA-Z])[a-zA-Z0-9._]+$');
        if (!re.test(value) || value.length < 2 || value.length > 32) {
            setErrorText('Invalid username!');
            setIsAvailable(false);
            return
        } else {
            setIsAvailable(true);
        }
        checkUsername(value);

    }


    return (
        <Row justify={"center"}>

            <Col lg={8} xs={24} className="m-padding20 md30">
                <Row>
                    <Col span={24} className='md30'>
                        <h3 className="login-heading-text">Yay! 🎉 <br />
                            Your Account has been created.</h3>

                    </Col>


                    <Col span={20} className="md30">
                        <p className="fs14 fw600">Username </p>
                        <Input status="warning" value={username} onChange={(e) => onUsernameChange(e.target.value)} placeholder="i.e, myname_official" className="md10" maxLength={32} />

                        {
                            !isAvailable && <p className="fs12 fw400 md5 text-red">{erroText}</p>
                        }
                        <p className="fs12 fw400 md5 " style={{ color: "#728096", paddingLeft: '3px' }}>
                            We have created username for you. You may update this. It’s a public identity for your account at Syllo.
                            Allowed only alpha-numeric characters including underscore (_) and dot (.)</p>
                    </Col>
                    <Col lg={{ span: 24 }} xs={{ span: 24 }} className='md40 onboard-card'>
                        <Row>
                            <Col span={20} >
                                <p className="fs15 fw500 "> Are you a professional ? </p>
                                <p className="fs12 fw300 text-grey">Don’t worry! you can change this later.</p>
                            </Col>
                            <Col span={4} className='text-right '>
                                <Switch checked={professional} onChange={(e) => setProfessional(e)}></Switch>
                            </Col>
                            {
                                professional && <>
                                    <Col span={24} className='md35'>
                                        <Row gutter={[10, 12]}>
                                            <Col lg={{ span: 24, }} xs={{ span: 24, }} className="m-md20" style={{ border: '1px,1px 5px,1px' }}>
                                                <Card
                                                    onClick={() => setRole(9)}
                                                    style={{

                                                        padding: 18
                                                    }}
                                                    className={role === 9 ? 'business-card cursor height140px' : "cursor height140px"}
                                                >
                                                    <Space>
                                                        <Buildings size={35} />
                                                        <div className="md15" >
                                                            <p className="fs14 fw500">Business</p>
                                                            <p className="fs12 fw300">Best fit for brands, retail shops, service providers and organizations.</p>
                                                        </div>
                                                    </Space>

                                                </Card>
                                            </Col>
                                            <Col lg={{ span: 24, }} xs={{ span: 24, }} className="m-md20">
                                                <Card
                                                    onClick={() => setRole(8)}
                                                    style={{

                                                        padding: 18
                                                    }}
                                                    className={role === 8 ? 'business-card cursor height140px' : "cursor height140px"}

                                                >
                                                    <Space>
                                                        <ChalkboardTeacher size={35} className="md10" />
                                                        <div >
                                                            <p className="fs14 fw500">Expert</p>
                                                            <p className="fs12 fw300">Best fit for public figures, artists, influencers and bloggers. </p>
                                                        </div>
                                                    </Space>


                                                </Card>
                                            </Col>
                                            <Col lg={{ span: 24, }} xs={{ span: 24, }} className="m-md20">
                                                <Card
                                                    onClick={() => setRole(10)}
                                                    style={{

                                                        padding: 18
                                                    }}
                                                    className={role === 10 ? 'business-card cursor height140px' : "cursor height140px"}

                                                >
                                                    <Space>
                                                        <GraduationCap size={35} className="md10" />
                                                        <div >
                                                            <p className="fs14 fw500">Educational Instituation</p>
                                                            <p className="fs12 fw300">Best fit for school and universities. </p>

                                                        </div>
                                                    </Space>

                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>

                                    {/* <Col span={24} className='md30'>
                                                <p className="fs14 fw600 ">Business Name</p>
                                                <Input required placeholder="Enter business name" value={name} onChange={(e) => setName(e.target.value)} type="text" className="md10" maxLength={56}></Input>
                                            </Col> */}

                                </>
                            }

                        </Row>
                    </Col>
                    <Col span={24} className='md40 mb-3 text-center'>
                        {contextHolder}
                        <Button size="large" block disabled={loader} onClick={() => saveData()} type="primary" className="fs14 fw500" >
                            Continue
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row >
    );
}

export default SignUpNext;