import React, { Component } from "react";
import "../../assets/css/style.scss";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import moment from "moment";
import { Modal, Tabs, Button, Skeleton, Col, Row, Table, Space, Tooltip } from "antd";
import { Calendar, momentLocalizer } from "react-big-calendar";
import './results.scss';
import Cookies from "js-cookie";
import { Bell, Circle, Heart, Calendar as CalendarIcon, ListNumbers, ArrowLeft, CalendarPlus } from "@phosphor-icons/react";
import EventMenu from "./EventMenu";


const localizer = momentLocalizer(moment);
var _ = require("lodash");
const { TabPane } = Tabs

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      role: Cookies.get("__role"),
      selectedDate: [],
      _selectedDate: "",
      time: "0",
      today: moment().format("MMM YYYY"),
      allresults: [],
      modal: false,
      loader: true,
      isList: true,
      thisweekData: []
    };
  }

  async componentDidMount() {
    await this.getData(0);
    await this.getDataThisWeek()
  }

  async getDataThisWeek() {
    await this.props
      .CorporateResults({
        name: "",
        time: "thisw",
        date: "",
      })
      .then(() => {
        this.setState({ thisweekData: this.props._corporate_results })
      });
  }

  async getData(key) {
    // this.setState({ loader: true })
    await this.props
      .CorporateResults({
        name: "",
        time: key === 0 ? 0 : moment(key).format("MM-YYYY"),
        date: "",
      })
      .then(() => {
        let result = [];
        let _datewisedata = _.groupBy(
          this.props._corporate_results,
          "schedule_date"
        );
        Object.keys(_datewisedata).map((item,) => {
          let _a = _.sumBy((_datewisedata[item]), function (o) { return o.schedule_type === 1 })
          let _b = _.sumBy((_datewisedata[item]), function (o) { return o.schedule_type === 2 })
          let _c = _.sumBy((_datewisedata[item]), function (o) { return o.related === true })
          result.push({
            start: moment(item).format("YYYY-MM-DD"),
            end: moment(item).format("YYYY-MM-DD"),
            title: <>
              <p> {_a === 0 || _a === false ? "" : <> <Circle weight={"fill"} ></Circle> {_a === true ? 1 + " Financial Results " : _a + " Financial Results "} </>}
              </p>
              <p > {_b === 0 || _b === false ? "" : <> <Circle weight={"fill"}  ></Circle> {_b === true ? 1 + " Syllo Events" : _b + " Syllo Events"} </>} </p>
              <p className="text-warning">  {_c === 0 || _c === false ? "" : <> <Circle weight={"fill"} className="text-warning" ></Circle> {_c === true ? 1 + " Saved Events" : _c + " Saved Events"} </>}  </p>
            </>,
            date: item,
          });
        });

        this.setState({
          results: result,
          allresults: this.props._corporate_results,
          current_month: key === 0 ? moment().format("MMMM") : moment(key).format("MMMM"),
          loader: false
        });
      });
  }

  openModal(evt) {
    let exist = _.groupBy(this.state.allresults, { schedule_date: evt.date });
    // console.log(exist["true"]);
    this.setState({
      selectedDate: exist["true"],
      modal: true,
    });
  }
  onChannelSelect(evt) {
    let value = evt
    if (evt === undefined) { value = "" }
    this.setState({ channel_id: value })
  }







  render() {
    const data = this.state.selectedDate;
    const { role } = this.state;
    const now = new Date();

    return (
      <Row>

        <Col lg={{ span: 24, offset: 0 }} xs={{ span: 24 }} className='mb-4 m-result-header'>
          <Row>
            <Col lg={{ span: 16, offset: 4 }} md={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }} className="md30">
              <Row>
                <Col lg={{ span: 24, offset: 0 }} xs={{ span: 24 }} >
                  <Row className=" m-ipad-p10 ">
                    <Col lg={{ span: 24, offset: 0 }} xs={{ span: 24 }}>
                      <Row  >
                        <Col lg={{ span: 6 }} md={6} xs={{ span: 3 }} className="md20">
                          <div className="text-center" style={{ borderRadius: '24px', background: 'rgba(29, 29, 31, 0.05)', padding: '23px', width: '150px', height: '140px' }}>
                            <h1 className="m-0"> {now.getDate()} </h1>
                            <p className="fs18 fw400"> {moment(now).format('MMM')} ‘{moment(now).format('YY')} </p>
                          </div>
                        </Col>
                        <Col lg={{ span: 14 }} md={13} xs={{ span: 24 }} className="m-md20">
                          <p className="fs12 fw600 text-grey"> This Week </p>
                          <div style={{ borderRadius: "16px", backgroundColor: "#1d1d1f0d", padding: "12px 16px 5px", marginTop: "5px", overflow: "hidden", minHeight: "130px" }}>

                            {
                              this.state.thisweekData.slice(0, 3).map((item, i) => {
                                return <Row key={i} style={{ height: "40px", alignItems: "center" }} className="event-menu">
                                  <Col span={24} >
                                    <Space>
                                      <img src={item.exchange === null ? item.profile.avatar_url : require('../../assets/images/Bell.svg').default} alt="" height={20} />
                                      <p className="fs12 fw400 cursor"> {item.name} </p>
                                    </Space>
                                  </Col>
                                  <Col span={24} style={{ height: "0" }}><EventMenu item={item} /></Col>
                                </Row>
                              })
                            }

                          </div>

                        </Col>
                        <Col lg={{ span: 1 }} md={0} xs={{ span: 0 }}></Col>
                        <Col lg={{ span: 3 }} md={5} xs={{ span: 24 }} className="text-right md25 m-align-explore m-md20">
                          <Button type="primary" className="fs12 fw400" onClick={() => this.setState({ isList: !this.state.isList })}
                            icon={this.state.isList ? <CalendarIcon size={18} /> : <ListNumbers size={18} />}>

                            <span className="ps-1" > {this.state.isList ? "Calender" : "List"} View </span>
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={{ span: 12 }} xs={{ span: 12 }} className=" text-right pe-3 nopadding" >
                          {
                            role === 8 && <Button type="primary" onClick={() => this.openFormOnClickAddEvent()} >Add Event</Button>
                          }
                        </Col>
                      </Row>

                      {/* ***************LIST VIEW***************** */}

                      <Row className="md20">

                        <Col lg={{ span: 24, offset: 0 }} xs={{ span: 24 }} className=" m-fs11 m-p0 m-ps0 nopadding" >

                          {this.state.loader === true ? <>
                            <Skeleton active></Skeleton><Skeleton active></Skeleton><Skeleton active></Skeleton></>
                            : !this.state.isList ?
                              <Calendar
                                style={{ height: "500px", marginTop: "10px", borderRadius: "16px" }}
                                onSelectEvent={(evt) => this.openModal(evt)}
                                onNavigate={(evt) => this.getData(evt)}
                                popup={true}
                                toolbar={false}
                                localizer={localizer}
                                events={this.state.results}
                                startAccessor="start"
                                endAccessor="end"
                                defaultDate={this.state._selectedDate}
                                // views={["month"]}
                                tooltipAccessor={false}
                              // components={{
                              //   toolbar: CustomToolbar
                              // }}
                              /> :
                              // <Table columns={columns} dataSource={this.state.allresults} size="middle" />
                              <>
                                <Col lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }} className="nopadding md10" >
                                  <Table dataSource={this.state.allresults} columns={[{
                                    title: '',
                                    dataIndex: 'type',
                                    key: 'name',
                                    render: (key, r) => <>{r.related ? <Heart size={22} color="#e65100" weight="fill" /> : ""}</>
                                  },
                                  {
                                    title: 'Upcoming Events',
                                    dataIndex: 'name',
                                    key: 'name',
                                  },
                                  {
                                    title: 'Date/Time',
                                    dataIndex: 'schedule_date_from',
                                    key: 'schedule_date_from',
                                    render: (key, r) => r.exchange === null ? moment(key).format("lll") : moment(key).format("DD MMM YYYY")
                                  },
                                  {
                                    title: 'Type of event',
                                    dataIndex: 'type',
                                    key: 'type',
                                    responsive:['md','lg']
                                  },
                                  {
                                    title: 'Posted By',
                                    dataIndex: 'by',
                                    key: 'by',
                                    render: (key, r) => <span>{r.profile && <a href={`/${r.profile?.is_business ? "business" : "profile"}/${r.profile?.user_code}`}> {r.profile?.full_name} </a>}</span>
                                    
                                  } ,]}
                                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}></Table>
                                </Col>
                              </>
                          }
                        </Col>
                      </Row>
                      {/* List View */}


                      <Modal
                        open={this.state.modal}
                        centered
                        title={false}
                        footer={false}
                        className="event-modal"
                        closable={false}
                        onCancel={() => this.setState({ modal: false })}
                        maskClosable={true}
                      >
                        <Row>
                          <Col span={24} className="px-3">
                            <h5 className="smaller-heading-text md20">
                              <ArrowLeft size={22} className="cursor" onClick={() => this.setState({ modal: false })}></ArrowLeft> Events
                            </h5>
                          </Col>

                          <Col span={24} className="">
                            <Tabs defaultActiveKey="1">
                              <TabPane tab={`Corporate Results (${data.filter((i) => i.type !== "Event").length})`} key="1">
                                <Row style={{ height: "50vh", overflowY: "scroll", alignContent: "flex-start" }}>
                                  {data.filter((i) => i.type !== "Event").map((item, index) => {
                                    return (
                                      <Col span={24} className={"finance"} >
                                        <Row>
                                          <Col lg={{ span: 4 }} className='ps-2' xs={{ span: 4 }}>
                                            <div className="date ">
                                              <p
                                                className="md10"
                                                style={{
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                {index + 1}
                                              </p>
                                            </div>
                                          </Col>
                                          <Col lg={{ span: 16 }} xs={{ span: 16 }}>
                                            <Row>
                                              <Col span={24}>
                                                <h5 className="sub-text fw500 m-ml8 ">
                                                  <strong>{item.name}</strong>
                                                </h5>
                                                <p className="text-grey fs12">
                                                  {moment(
                                                    item.schedule_date_from
                                                  ).format(
                                                    "Do MMM 'YY"
                                                  )}
                                                </p>

                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col lg={{ span: 4 }} xs={{ span: 4 }} className="text-right pe-3">

                                            <Tooltip title='Save to Google Calendar'>
                                              <Button icon={<CalendarPlus size={24} color="#728096" />} type="link" target="_blank"
                                                href={
                                                  "https://calendar.google.com/calendar/u/0/r/eventedit?text=" +
                                                  item.name +
                                                  "&dates=" +
                                                  item.schedule_date_from +
                                                  "Z&details=" +
                                                  item.name +
                                                  `More at ${process.env.REACT_APP_URL}`
                                                }></Button>
                                            </Tooltip>
                                          </Col>
                                        </Row>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </TabPane>
                              <TabPane tab={`Syllo Events (${data.filter((i) => i.type === "Event").length})`} key="2">
                                <Row style={{ height: "50vh", overflowY: "scroll", alignContent: "flex-start" }}>

                                  {data.filter((i) => i.type === "Event").map((item, index) => {

                                    return (
                                      <Col span={24} className={"finance"} >
                                        <Row>
                                          <Col lg={{ span: 4 }} className='ps-2' xs={{ span: 4 }}>
                                            <div className={item.related === true ? "orange-date" : "date"}>
                                              <p
                                                className="md10"
                                                style={{
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                {index + 1}
                                              </p>
                                            </div>
                                          </Col>
                                          <Col lg={{ span: 16 }} xs={{ span: 16 }} >
                                            <Row>
                                              <Col span={24}>
                                                <a href={"/event/" + item.schedule_uid}><h5 className="sub-text cursor">
                                                  <strong>{item.name}</strong>
                                                </h5>
                                                </a>
                                                <p className="text-grey fs12">
                                                  {moment(
                                                    item.schedule_date_from
                                                  ).format(
                                                    "Do MMM 'YY | h:mm a"
                                                  )}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col lg={{ span: 4 }} xs={{ span: 4 }} className="text-right pe-3" >
                                            <Tooltip title='Save to Google Calendar'>
                                              <Button icon={<CalendarPlus size={24} color="#728096" />} type="link" target="_blank"
                                                href={
                                                  "https://calendar.google.com/calendar/u/0/r/eventedit?text=" +
                                                  item.name +
                                                  "&dates=" +
                                                  item.schedule_date_from +
                                                  "Z&details=" +
                                                  item.name +
                                                  `More at ${process.env.REACT_APP_URL}`
                                                }></Button>
                                            </Tooltip>
                                          </Col>
                                        </Row>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </TabPane>

                            </Tabs>
                          </Col>
                        </Row>

                      </Modal>



                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="col-md-2 "></div>
            </Col>

          </Row>

        </Col>
      </Row>

    );
  }
}

const mapStateToProps = (state) => ({
  _channels: state.channel.channel_list,
  _corporate_results: state.results.corporate_results,
});
const mapDispatchToProps = (dispatch) => ({
  CorporateResults: (v) => dispatch(actions.CorporateResults(v)),
  createEvent: (v) => dispatch(actions.createEvent(v)),
  ChannelList: (v) => dispatch(actions.ChannelList(v)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Results)
);
