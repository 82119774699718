import { Avatar, Badge, Layout, Menu } from 'antd';
import AppHeader from "../Header/Header"
import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom"
import { useEffect } from 'react';
import { Circle, Compass, GameController, Gift, GraduationCap, House, Radio, Television, } from "@phosphor-icons/react";
import "./style.scss"
import useToken from '../../store/useToken';
import {CAMPUS_WEB} from '../../utils';

const { Header, Content, Sider } = Layout;

const App = ({ children }) => {
  const [active, setActive] = useState("")
  const webSocket = useRef(null);
  const { token } = useToken();
  const [items, setItems] = useState([]);
  // const [loader, setLoader] = useState(false)
  // const [items, setItems] = useState(localStorage.getItem("channels") && JSON.parse(localStorage.getItem("channels")) || []);


  // useEffect(() => {
  //   let menu = localStorage.getItem("channels") && JSON.parse(localStorage.getItem("channels"))
  //   if (menu === null || menu === undefined) {
  //     setLoader(true)
  //     axios({
  //       method: 'get',
  //       url:  "channel/list/creator?id=",
  //       headers: {
  //         Authorization: Cookies.get("Logintoken"),

  //       }
  //     }).then(res => {
  //       const data = res.data.data
  //       setItems(_.cloneDeep(data))
  //       setLoader(false)
  //       localStorage.setItem("channels", JSON.stringify(data))
  //     })
  //   }
  // }, [])

  useEffect(() => {
    webSocket.current = new WebSocket(process.env.REACT_APP_WS_BASE_URL + `wss/live?token=${token}`);
    webSocket.current.onmessage = evt => {
      const message = JSON.parse(evt.data);
      setItems(message);
    }

    return () => webSocket.current.close();
  }, [])


  useEffect(() => {
    let url = window.location.href.split("/");

    if (url[3] === "dashboard" || url[3] === '') {
      setActive("1")
    }
    else if (url[3] === "live-talks") {
      setActive("3")
    }
    else if (url[3] === "campus") {
      setActive("4")
    }
    else if (url[3] === "bookmarked") {
      setActive("5")
    }
    else if (url[3] === "channel") {
      setActive(url[4])
    }
    else if (url[3] === "reels") {
      setActive("7")
    }
    else if (url[4] === "tv") {
      setActive("8")
    }
    else if (url[3] === "explore") {
      setActive("2")
    }
    else if (url[3] === "rewards") {
      setActive("9")
    }
  })


  return (
    <Layout >

      <Sider style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,

      }} collapsed={true} className='nav-bg m-hide'>
        <div className="logo" />

        <Menu theme="light" className='sidebar-menu ' selectedKeys={[active]} onClick={(evt) => setActive(evt?.key)} mode="vertical"  >
          <Menu.Item key="1" icon={<House size={30} weight={active === "1" ? "fill" : "thin"} />}>
            <Link to={"/dashboard"}>  Dashboard </Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<Compass size={30} weight={active === "2" ? "fill" : "thin"} />}>
            <Link to={"/explore"}>Explore</Link>
          </Menu.Item>
          {/* <Menu.Item key="3" icon={<MonitorPlay size={30} weight={ active === "3"? "fill":"thin"}  /> }>
                <Link to={"/live-talks"}>Live Talks</Link>
              </Menu.Item> */}
          <Menu.Item key="4" icon={<GraduationCap size={30} weight={active === "4" ? "fill" : "thin"} />}>
            {/* <Link to={{ pathname:  }}>Campus</Link> */}
            <a href={`${CAMPUS_WEB}campus/me`}>Campus</a>
          </Menu.Item>

          {/* <Menu.Item key="7" icon={<Radio size={30} weight={active === "7" ? "fill" : "thin"} />}>
            <Link to={"/reels/view"}>Reels</Link>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<Television size={30} weight={active === "8" ? "fill" : "thin"} />}>
            <Link to={"/explore/tv"}>Syllo TV</Link>
          </Menu.Item>

          <Menu.Item key="9" icon={<Gift size={30} weight={active === "9" ? "fill" : "thin"} />} >
            <Link to={"/rewards"}>Rewards</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<GameController size={30} weight={active === "6" ? "fill" : "thin"} />}>
            <Link to={{ pathname: "https://games.syllo.co/dashboard" }} replace={true} target="_blank">Game</Link>
          </Menu.Item>
          <hr></hr>
              {
                 items.slice(0, 3).map((item) => {
                  return <Menu.Item key={item?._id} icon={<Badge count={<img src={require("../../assets/images/waveform.svg").default} height={20} />} style={{transform: "translate(50%, 110%)", right:"4px"}} ><Avatar style={{border:"1px solid green"}} size={35} shape="circle" src={item.streamer?.avatar_url}>  </Avatar></Badge>}>
                    <Link to={`/go-live/${item?._id}`} >{item?.streamer.full_name}</Link>
                  </Menu.Item>
                })
              }
              {/* {
                items.length >= 3 &&  <p className={"text-center"}><small><Link to={"/my-hub"}>View All</Link></small></p>
              } */}
        </Menu>


      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 80, backgroundColor: "#f0f0f0" }}>
        <Header
          style={{
            padding: 0,
            zIndex: "10000"
          }}
        >
          <AppHeader left={true} right={true} showBorder={true} />
        </Header>
        <Content
          style={{
            margin: '0 10px 0px 10px',
            backgroundColor: "white",
            borderRadius: "10px", minHeight: "calc(100vh - 69px)"
          }}
        >
          {children}
        </Content>

      </Layout>
    </Layout>
  );
};
export default App;