import React, { useState } from "react";
import { Col, Row, Space, Upload, Button, message, Input, Divider, Avatar, Tooltip, Spin } from "antd";
import { PencilSimple, Plus } from "@phosphor-icons/react";
import request from "../../store/request";
import TextArea from "antd/lib/input/TextArea";
import useToken from "../../store/useToken"
import axios from "../../store/axios";

import Toast from "../utils/Toast";


const ManagePage = (props) => {
    const [name, setName] = useState("");
    const [about, setDesc] = useState("");
    const [button1, setBtutton1] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    const { token } = useToken();

    const updateSettings = async (data) => {
        setLoading(true);
        await axios({
            method: 'post',
            url:  `v1.0/business/page/settings`,
            headers: {
                Authorization: token
            },
            data: data
        }).then(res => {
            // const data = res.data.data
            // setData(data);
            props?.reload();
            Toast('Updated',"success");
            setBtutton1(false)
        })
        setLoading(false);
    };

    const props1 = {
        name: 'file',
        accept: ".png,.jpg,.JPEG",
        action:  "account/file/upload",
        data: { type: "tiers" },
        headers: {
            Authorization: token,
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                messageApi.open({
                    key,
                    type: 'loading',
                    content: 'Uploading...',
                });
            }
            if (info.file.status === 'done') {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Uploaded!',
                    duration: 4,
                });
                updateSettings({ 'logo': info.file.response.data.url })
            } else if (info.file.status === 'error') {
                messageApi.open({
                    key,
                    type: 'error',
                    content: `${info.file.name} file upload failed.`,
                    duration: 4,
                });
            }
        },
        customRequest: (options) => request.customRequest(options, "tiers"),

    };

    const props2 = {
        name: 'file',
        accept: ".png,.jpg,.JPEG",
        action:  "account/file/upload",
        headers: {
            Authorization: token,
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                messageApi.open({
                    key,
                    type: 'loading',
                    content: 'Uploading...',
                });
            }
            if (info.file.status === 'done') {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Uploaded!',
                    duration: 4,
                });
                updateSettings({ 'banner_url': info.file.response.data.url })
            } else if (info.file.status === 'error') {
                messageApi.open({
                    key,
                    type: 'error',
                    content: `${info.file.name} file upload failed.`,
                    duration: 4,
                });
            }
        },
        customRequest: (options) => request.customRequest(options, "file"),

    };

    const saveName = () => {
        let payload = {}
        if (name !== ''){
            payload['name'] = name
        }
        if (about !== ''){
            payload['about'] = about
        }
                
        updateSettings(payload);
    }

    return (
        <Row>
            <Col span={24} className="md20">
                <Spin spinning={loading}>
                    <Row>
                        <Col span={24}>
                            <Space>
                                <p className="fs14 fw400">Banner Image</p>
                            </Space>
                        </Col>
                        <Col span={24}>
                            <Upload
                                className="avatar-uploader md10 upload-w100"
                                listType="picture-card"
                                showUploadList={false}
                                {...props2}
                            >
                                {props.page?.banner_url ? (
                                    <div className="uploaded-banner" style={{ backgroundImage: `url(${props.page?.banner_url})` }}>
                                        <Tooltip title={'Change banner image'}>
                                            <Space style={{ backgroundColor: "#ffffff2e", borderRadius: "4px", padding: "4px" }}>
                                                <PencilSimple size={13} color="#000000" weight="bold" />
                                                <p style={{ color: '#000', marginTop: '3px' }}> Update Image</p>
                                            </Space>
                                        </Tooltip>

                                    </div>
                                ) : (
                                    <Tooltip title={'Add banner image'}>
                                        <Space>
                                            <Plus size={13} color="#000000" weight="bold" />
                                            <p style={{ color: '#8E8E8E', marginTop: '3px' }}> Add Image</p>
                                        </Space>
                                    </Tooltip>
                                )}
                            </Upload>
                        </Col>
                    </Row>
                    <Row className='md30'>
                        <Col span={19}>
                            <p className="fs14 fw400">Change Icon</p>

                        </Col>
                        <Col span={5} className="text-right">
                            <div style={{ float: "right" }}>
                                <Avatar src={props.page?.logo} shape={"circle"} size={60} className="edit-avatar" ></Avatar>
                                <Upload className="edit-avatar-upload"  {...props1} showUploadList={false} preview={false}>
                                    <Button className="edit-avatar-btn" type="ghost" size="small" icon={<PencilSimple size={17} />} ></Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row className='md30'>
                        <Col span={24}>
                            <p className="fs14 fw400">Business Name</p>
                            <Input placeholder="Business name" className='md15' value={name !== "" ? name : props.page?.name} onChange={(evt) => (setBtutton1(true), setName(evt.target.value))} />
                        </Col>
                        <Col span={24} className='md30'>
                            <p className="fs14 fw400 ">About</p>
                            <TextArea rows={4} placeholder="Enter description" className='md15 manag_area' showCount={true} maxLength={250}
                                onChange={(evt) => (setBtutton1(true), setDesc(evt.target.value))} value={about !== "" ? about : props.page?.about} />
                        </Col>
                        <Col span={24} className='md30 text-right'>
                            {button1 && (name !== "" || about !== "") && <Button onClick={() => saveName()} type="primary"> Save Changes </Button>}
                        </Col>
                        <Col span={24} className='md20 '>
                            <Divider />
                            {contextHolder}
                        </Col>


                    </Row>
                </Spin>
            </Col>
        </Row>
    );
}
export default ManagePage;