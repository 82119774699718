export const CAMPUS_WEB = process.env.REACT_APP_CAMPUS_WEB;

export function convertDuration(itunesDuration) {
  if (itunesDuration === undefined) {
    return ""
  }
  const arr = itunesDuration.split(':');
  var hours = 0;
  var minutes =0;
  var seconds = 0;

  if (arr.length === 1) {
    seconds = arr[0];
  }
  else if (arr.length === 2) {
    seconds = arr[1];
    minutes = arr[0];
  }
  else if (arr.length === 3) {
    // seconds = arr[2];
    minutes = arr[1];
    hours = arr[0];
  } else {
    return itunesDuration;
  }
  
  const formattedDuration = [
    hours > 0 ? `${hours}h` : '',
    minutes > 0 ? `${minutes}m` : '',
    seconds > 0 ? `${seconds}s` : '',
  ].filter(Boolean).join(' ');

  return formattedDuration;
}
