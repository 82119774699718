import React, { Component } from "react";
import "./../../assets/css/style.scss";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { Skeleton, Tabs, Tag, Space, Checkbox, Button, Popover, message, Row, Col } from "antd";
import { SyncOutlined } from '@ant-design/icons';
import './newdashboard.scss';
import NewEvent from "./NewEvent.jsx";
import NewTrending from "./NewTrending";
import NewsFeed from "../NewsFeed/NewsFeed";
// import Watch from "./Watch";
import { Article, ChartBarHorizontal, GameController, Newspaper, Note, Notebook, Sliders, X } from "@phosphor-icons/react";


var _ = require("lodash");
const { TabPane } = Tabs;

const entityTypes = [{
    key: "entity=0",
    value: "All"
}, {
    key: "entity=1",
    value: "News"
}, {
    key: "entity=2",
    value: "Newsletter"
}, {
    key: "entity=3",
    value: "Posts"
}, {
    key: "entity=4",
    value: "Reports"
}, {
    key: "entity=6",
    value: "Polls"
}, {
    key: "entity=10",
    value: "Notes"
}]

class MainDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            results: [],
            time: "today",
            loader: true,
            newsloader: true,
            news: [],
            activeTab: "a",
            allnewsletter: [],
            market: [],
            todaythought: "",
            todaythoughts: [],
            active: ["entity=0"],
            active_status: sessionStorage.getItem("active"),
            related: [], Filtertags: "", found: false, allCaught: false, loadMore: true,
            init_feed_count: 0, init_feed_countnews: 0, topics: [], stocksList: [], search: false, stocks: [], sectors: [], selectedtopics: [], UserPrefereces: false, trending: [], topResult: [], activeResulttab: "1", tagloader: true, resultLoader: false, data_count: 0,
            loadmorebtn: false, VideosTags: [], activeEventtab: 1, allfilter: true, tag: [], media: "", author: "", allnews: [],scrollPosition: sessionStorage.getItem("scrollPosition") || 0
        };
    }

    onCollapse = (collapsed) => {
        this.setState({ collapsed: collapsed });
    };

    async componentDidMount() {

        this.getDashboardData(0)
        this.getRestData()
        this.TabChangeResult(this.state.activeEventtab)
        // this.gettabsData(this.state.activeResulttab)

        this.props.UserTagPreferences({ key: 1 }).then(() => {
            this.setState({
                trending: this.props?._user_selected,
                tagloader: false
            })
        })
     
    //     const savedScrollPosition = this.state?.scrollPosition;
    // if (savedScrollPosition !== undefined) {
    //     document.getElementById("scrollableDiv1").scrollTo(0, savedScrollPosition);
    // }
    }






    componentWillUnmount() {
        clearInterval(window.timer)
    //    sessionStorage.setItem("scrollPosition",document.getElementById("scrollableDiv1").scrollTop)
    }

    async getDashboardData(offset) {
        let domain = "/market/feed/all?" + (this.state.active).join("&") + "&offset=" + offset + "&tags=" + this.state.tag + "&media=" + this.state.media + "&author=" + (this.state.author !== "" ? this.state.author.user_code : "" + "&last=" + 0 || this.props?._allmarket?.last)


        await this.props.allMarketNewsData(domain).then(() => {
            this.props?._allmarket.records.map((item) => {
                return item.collapsed = false
            })
            if (this.props?._allmarket.records.length < 10) {
                this.setState({
                    allCaught: true, loadMore: false
                })
            }
            else {
                this.setState({
                    allCaught: false, loadMore: true
                })
            }
            this.setState({
                market: this.props?._allmarket.records,
                newsloader: false,
                found: false, init_feed_count: offset
            });

            // document.getElementById("scrollableDiv").scrollTo({
            //     top: 0,
            //     behavior: "smooth"
            // })
        });
    }

    timer = (key) => window.setInterval(() => {
        let LastData = this.props?._allmarket.records[0]
        if (!this.state.found && LastData !== undefined) {
            this.props.getNewData({ id: LastData?.entity_id, type: (key ? key : 0), etype: LastData.entity_type }).then(() => {
                if (this.props._new_data.found === true) {
                    this.setState({
                        found: true
                    })
                }
            })
        }
    }, 100000);

    async getRestData() {
        let key = this.state.active
        this.timer(key)
    }




    loadMore() {
        this.setState({ loadmorebtn: true });
        this.getNewData();
    }


    async getNewData() {
        let newcount = this.state.init_feed_count + 10;

        let domain = "/market/feed/all?" + (this.state.active).join("&") + "&offset=" + newcount + "&tags=" + this.state.tag + "&media=" + this.state.media + "&author=" + (this.state.author !== "" ? this.state.author.user_code : "" + "&last=" + 0 || this.props?._allmarket?.last)
        this.props
            .allMarketNewsData(domain)
            .then(() => {
                let _oldData = this.state.market
                this.props?._allmarket.records.map((item) => {
                    return item.collapsed = false
                })

                if (this.props?._allmarket.records.length !== 0) {
                    this.props?._allmarket.records.map((item) => {
                        return _oldData.push(item)
                    })
                }
                if (this.props?._allmarket.records.length < 10) {
                    this.setState({
                        allCaught: true, loadMore: false
                    })
                }
                else {
                    this.setState({
                        allCaught: false, loadMore: true
                    })
                }

                this.setState({
                    market: _oldData,
                    newsloader: false,
                    found: false,
                    init_feed_count: newcount,
                    data_count: this.props?._allmarket.records.length,
                    loadmorebtn: false
                });
            });
    }


    async getAllData(evt) {
        let newcount = 0
        this.setState({
            newsloader: true,
            allfilter: evt, active: ["entity=0"],
        });
        let domain = null
        domain = "/market/feed/all?entity=0" + "&offset=" + newcount + "&tags=" + this.state.tag + "&media=" + this.state.media + "&author=" + (this.state.author !== "" ? this.state.author.user_code : "" + "&last=" + 0 || this.props?._allmarket?.last)
        this.props
            .allMarketNewsData(domain)
            .then(() => {
                if (this.props?._allmarket.records.length < 10) {
                    this.setState({
                        allCaught: true, loadMore: false
                    })
                }
                else {
                    this.setState({
                        allCaught: false, loadMore: true
                    })
                }
                this.setState({
                    market: this.props?._allmarket.records,
                    newsloader: false,
                    init_feed_count: newcount,
                });
            });
    }




    async changeTab(evt) {

        if (evt.length === 0) {
            // this.setState({allfilter:true});
            this.getAllData("entity=0")
            return
        }

        this.setState({
            active: evt,
            newsloader: true,
            allfilter: false
        });
        let domain = "/market/feed/all?" + (evt).join("&") + "&offset=0" + "&tags=" + this.state.tag + "&media=" + this.state.media + "&author=" + (this.state.author !== "" ? this.state.author.user_code : "" + "&last=" + 0 || this.props?._allmarket?.last)

        this.props
            .allMarketNewsData(domain)
            .then(() => {
                if (_.includes(this.state.active, "entity=0")) {
                    if (this.props?._allmarket.records.length < 10) {
                        this.setState({
                            allCaught: true, loadMore: false
                        })
                    }
                    else {
                        this.setState({
                            allCaught: false, loadMore: true
                        })
                    }
                }
                else {
                    if (this.props?._allmarket.records.length < 10) {
                        this.setState({
                            allCaught: true, loadMore: false
                        })
                    }
                }
                this.setState({
                    market: this.props?._allmarket.records,
                    newsloader: false,
                });
            });
    }

    details(record) {
        this.props.history.push("/feed-view/" + record?.entity_uuid)
    }

    likeFeed(data, record) {
        let _data = _.find(this.state.market, { "entity_uuid": record?.entity_uuid })
        _data.liked = data
        _data.likes = data ? (_data.likes + 1) : (_data.likes - 1)
        this.setState({
            ...this.state.market
        })
        this.props
            .likeFeed({
                id: record?.entity_uuid,
                type: record.type,
                requesttype: data === true ? "post" : "delete",
            })

    }



    TabChangeResult(_type) {
        this.setState({ resultLoader: true });
        // this.props.CorporateResultsTop({ type: _type }).then(() => {
        //     this.setState({ topResult: this.props._corporate_results, resultLoader: false })
        // })
    }


    reload(id, key, type) {
        if (key === true) {
            this.props.relatedTopics({ id: id, type: type }).then(() => {
                this.setState({
                    related: this.props._related
                })
            })
        }
        else {
            this.setState({ ...this.state })
        }
    }
    async getFilterByTags(_tag, media, author,checked) {
       const {tag} = this.state
        const nextSelectedTags = checked
      ? [...tag, _tag]
      : tag.filter((t) => t !== _tag);

       
        this.setState({
            newsloader: true,
            tag: nextSelectedTags, media: media, author: author, init_feed_count: 0
        })
        let domain
        if (this.state.active.length === 0) {
            domain = "/market/feed/all?entity=0" + "&tags=" + (nextSelectedTags).join("&tags=") + "&media=" + media + "&author=" + (author !== "" ? author.user_code : "") + "&offset=" + 0 + "&last=" + 0 || this.props?._allmarket?.last
        }
        else {
            domain = "/market/feed/all?" + this.state.active.join("&") + "&tags=" + (nextSelectedTags).join("&tags=") + "&media=" + media + "&author=" + (author !== "" ? author.user_code : "") + "&offset=" + 0 + "&last=" + 0 || this.props?._allmarket?.last
        }

        this.props
            .allMarketNewsData(domain)
            .then(() => {
                this.props?._allmarket.records.map((item) => {
                    return item.collapsed = false
                })
                if (this.props?._allmarket.records.length < 10) {
                    this.setState({
                        allCaught: true, loadMore: false
                    })
                }else{
                    this.setState({
                        allCaught: false, loadMore: true
                    })
                }

                this.setState({
                    market: this.props?._allmarket.records,
                    newsloader: false,
                    Filtertags: tag || media || author?.name
                });
            });

    }


    async gettabsData(evt) {
        this.setState({ activeResulttab: evt, Filtertags: "", newsloader: true })

        if (evt === "1") {
            this.setState({
                active: ["entity=0"], allCaught: false, loadMore: true, tag: "", media: "", author: "", newsloader: false,
            });
        }

        if (evt === "2") {
            let domain = "news/feed?count=10&offset=0"
            this.props
                .allMarketNews(domain)
                .then(() => {
                    if (this.props._all_news.length < 10) {
                        this.setState({
                            allCaught: true, loadMore: false
                        })
                    }
                    else {
                        this.setState({
                            allCaught: false, loadMore: true
                        })
                    }
                    this.setState({
                        allnews: this.props._all_news,
                        newsloader: false,
                        active: ["entity=1"]
                    });
                });
            return
        }
    }


    async getMoreNews() {
        let newcountnews = this.state.init_feed_countnews + 10
        let domain = "news/feed?count=10&offset=" + newcountnews
        this.props
            .allMarketNews(domain)
            .then(() => {
                // if (this.props._all_news.length < 10) {
                //     this.setState({
                //         allCaught: true, loadMore: false
                //     })

                // }
                // else {
                //     this.setState({
                //         allCaught: false, loadMore: true
                //     })
                // }
                let _oldData = this.state.allnews
                if (this.props._all_news.length !== 0) {
                    this.props._all_news.map((item) => {
                        return _oldData.push(item)
                    })
                }
                this.setState({
                    allnews: _oldData,
                    newsloader: false,
                    init_feed_countnews:newcountnews
                });
            });
    }

    async getNewsFilterByTags(tag, media, author) {
        this.setState({
            newsloader: true,
            tag: tag, media: media, author: author, init_feed_count: 0
        })
        let domain = "news/feed?" + "tags=" + tag + "&media=" + media + "&author=" + (author !== "" ? author.user_code : "") + "&offset=" + 0
        this.props
            .allMarketNews(domain)
            .then(() => {
                if (this.props._all_news.length < 10) {
                    this.setState({
                        allCaught: true, loadMore: false
                    })
                }
                else {
                    this.setState({
                        allCaught: false, loadMore: true
                    })
                }
                this.setState({
                    allnews: this.props._all_news,
                    newsloader: false,
                    Filtertags: tag || media || author?.name
                });
            });
    }

    onSave(totalTime){
        console.log('Total time spent by user', totalTime);
      }

    render() {
        const content = (
            <><div className="feed-filter">
                <div className="checkbox fs14 fw400">
                    <Checkbox value={"entity=0"} onChange={(evt) => this.getAllData("entity=0")} checked={this.state.allfilter}> All </Checkbox>
                </div>
                <Checkbox.Group value={this.state.active} onChange={(evt) => this.changeTab(_.reverse(evt))} style={{ width: "100%" }}>

                    {/* <div className="checkbox fs14 fw400"><Checkbox value={"entity=1"}> <NewspaperClipping size={24} /> News </Checkbox></div> */}
                    <div className="checkbox fs14 fw400"><Checkbox value={"entity=3"}> <Article size={24} /> Posts </Checkbox></div>
                    <div className="checkbox fs14 fw400"><Checkbox value={"entity=2"}> <Newspaper size={24} /> Newsletters </Checkbox></div>
                    {/* <div className="checkbox fs14 fw400"><Checkbox value={"entity=7"}> <Headphones size={24}   /> Podcasts </Checkbox></div> */}
                    <div className="checkbox fs14 fw400"><Checkbox value={"entity=4"}> <Notebook size={24} /> Reports</Checkbox></div>

                    {/* <div className="checkbox fs14 fw400"><Checkbox value={"entity=11"}> <MonitorPlay size={24}   /> Reels </Checkbox></div>*/}
                    <div className="checkbox fs14 fw400"><Checkbox value={"entity=6"}> <ChartBarHorizontal size={24} /> Polls </Checkbox></div>
                    <div className="checkbox fs14 fw400" ><Checkbox value={"entity=10"}> <Note size={24} /> Notes </Checkbox></div>
                    <div className="checkbox fs14 fw400" ><Checkbox value={"entity=8"}> <GameController size={24} /> Games </Checkbox></div>
                    {/* <span className="checkbox fs14 fw400">
                        <Checkbox disabled > Essays <span className="fs10 fw400">Coming soon...</span></Checkbox>
                    </span> 
                    <br />
                    <span className="checkbox fs14 fw400" ><Checkbox disabled> Courses </Checkbox></span> */}
                </Checkbox.Group>

                {/* <Divider className="my-2"></Divider> */}
            </div>
                {/* <div>
                    <p className="fs14 fw400 text-grey">Publisher</p>

                    <Checkbox.Group className="checkbox">
                        <Checkbox className="" value={"entity=1"} disabled> Moneycontrol </Checkbox>
                        <Checkbox value={"entity=3"} disabled> NDTV </Checkbox>
                        <Checkbox value={"entity=2"} disabled> CNBC </Checkbox>
                        <Checkbox value={"entity=7"} disabled>  Podcasts </Checkbox>
                        <Checkbox value={"entity=4"} disabled>  Report </Checkbox>

                    </Checkbox.Group>
                </div> */}
                </>
        );

        return (

            <Row  >
                <Col xxl={7} md={6} xs={0} className={' pe-0 m-md20 m-pl3 padding15'}  style={{height:"calc(100vh - 69px)",overflowY: "scroll", overflowX: "hidden"}}>
                    <NewTrending newsloader={this.state.tagloader} selectedTags={this.state.tag}  Filtertags={this.state.Filtertags} trending={
                        this.state.trending} getFilterByTags={(tag, media, author,checked) =>
                            this.state.activeResulttab === "1" ? this.getFilterByTags(tag, media, author,checked) : this.getNewsFilterByTags(tag, media, author,checked)} />
                </Col>

                <Col xxl={10} md={11} xs={24} style={{ borderLeft: "1px solid #f0f0f0", borderRight: "1px solid #f0f0f0", padding: "0px", overflowY: "scroll", overflowX: "hidden", maxWidth:"600px" }}>
                    <Row className="">
                        <Col span={24} className="md20 m-md10 m-tabs dashboard-tab">
                            <Tabs
                                onChange={(evt) => this.gettabsData(evt)}
                                className="col-lg-12 ps-0 "
                                tabBarExtraContent={<>
                                    {
                                        this.state.activeResulttab === "1" &&
                                        <>
                                            <Tag>{_.filter(entityTypes, { "key": this.state.active[0] })[0]?.value}</Tag>
                                            {
                                                this.state.active.length > 1 && <Tag>+{(this.state.active.length - 1)}</Tag>
                                            }

                                            <Popover content={content} trigger={"click"} title="View Filter" className="popover-text" overlayClassName="main-feed-filter">
                                                <Button className="m-filtersize" size="middle" type={"text"}
                                                    icon={<Sliders size={17} color="var(--primary-color)" />} > </Button>
                                            </Popover>
                                        </>
                                    }

                                </>
                                } active={this.state.activeResulttab}
                            >
                                <TabPane tab="Crisps" key={"1"} >
                                    <div className="row nomargin">
                                        {
                                            this.state.found && <Col span={17} className=" text-center no-padding" style={{ height: "0px", zIndex: "1000" }}>

                                                <Tag icon={<SyncOutlined size={17} spin style={{ display: "inline-flex" }} />} color="processing" className="cursor tag-more"
                                                    onClick={() => this.setState({ found: false, Filtertags: "" }, () => this.getDashboardData(0))}>New Feeds</Tag>


                                            </Col>
                                        }



                                        {/* <div className="col-md-12">
                                            {this.state.Filtertags !== "" && <div className="py-3"><Space size="small" align="center"> Tags : <span className="tags-round">{this.state.Filtertags} <span><X style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => this.setState({ Filtertags: "", newsloader: true, init_feed_count: 0, tag: "", author: "", media: "" }, () => this.getDashboardData(0))}></X></span>  </span>  </Space> </div>}
                                        </div> */}
                                        <div className="col-md-12 nopadding">
                                            {this.state.newsloader ? (
                                                <div style={{ padding: "10px" }}>

                                                    <Skeleton
                                                        className="md20"
                                                        avatar
                                                        active
                                                        paragraph={{ rows: 4 }}
                                                    />
                                                    <Skeleton
                                                        className="md20"
                                                        avatar
                                                        active
                                                        paragraph={{ rows: 4 }}
                                                    />
                                                    <Skeleton
                                                        className="md20"
                                                        avatar
                                                        active
                                                        paragraph={{ rows: 4 }}
                                                    />

                                                </div>
                                            ) : this.state.market.length === 0 ? <>

                                                <p className="sub-text text-center md200">No Data Available</p>

                                            </> : (
                                                <>
                                                    <NewsFeed
                                                    id={"scrollableDiv1"}
                                                        Filtertags={this.state.Filtertags}
                                                        data={this.state.market}
                                                        related={this.state.related}
                                                        getFilterByTags={(tag, media, author) => this.getFilterByTags(tag, media, author)}
                                                        loadMoreData={() => this.loadMore()}
                                                        showLike={true}
                                                        loadMore={this.state.loadMore}
                                                        componentDidMount={() => this.componentDidMount()}
                                                        reload={(id, key, type) => this.reload(id, key, type)}
                                                        details={(record) => this.details(record)}
                                                        height={"calc(100vh - 136px)"}
                                                        showFooter={true}
                                                    />


                                                </>
                                            )}
                                        </div>

                                        <div className="col-md-12 "></div>
                                    </div>
                                </TabPane>
                                {/* <TabPane tab="Markets" key="2" >
                                                            <MarketWatch />
                                                        </TabPane> */}
                                {/* <TabPane tab="News" key={"2"} >

                                    <div className="row nomargin">
                                        {
                                            this.state.found && <Col span={17} className=" text-center no-padding" style={{ height: "0px", zIndex: "1000" }}>

                                                <Tag icon={<SyncOutlined size={17} spin style={{ display: "inline-flex" }} />} color="processing" className="cursor tag-more"
                                                    onClick={() => this.setState({ found: false, Filtertags: "" }, () => this.gettabsData("2"))}>New News</Tag>


                                            </Col>
                                        }



                                        <div className="col-md-12">
                                            {this.state.Filtertags !== "" && <div className="py-3"><Space size="small" align="center"> Tags : <span className="tags-round">{this.state.Filtertags} <span><X style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => this.setState({ Filtertags: "", newsloader: true, init_feed_count: 0, tag: "", author: "", media: "" }, () => this.gettabsData("2"))}></X></span>  </span>  </Space> </div>}
                                        </div>
                                        <div className="col-md-12 nopadding">
                                            {this.state.newsloader ? (
                                                <div style={{ padding: "10px" }}>

                                                    <Skeleton
                                                        className="md20"
                                                        avatar
                                                        active
                                                        paragraph={{ rows: 4 }}
                                                    />
                                                    <Skeleton
                                                        className="md20"
                                                        avatar
                                                        active
                                                        paragraph={{ rows: 4 }}
                                                    />
                                                    <Skeleton
                                                        className="md20"
                                                        avatar
                                                        active
                                                        paragraph={{ rows: 4 }}
                                                    />

                                                </div>
                                            ) : this.state.market.length === 0 ? <>

                                                <p className="sub-text text-center md200">No Data Available</p>

                                            </> : (
                                                <>
                                                    <NewsFeed
                                                        Filtertags={this.state.Filtertags}
                                                        data={this.state.allnews}
                                                        related={this.state.related}
                                                        getFilterByTags={(tag, media, author) => this.getFilterByTags(tag, media, author)}
                                                        id={"scrollableDiv"}
                                                        loadMoreData={() => this.getMoreNews()}
                                                        showLike={true}
                                                        loadMore={this.state.loadMore}
                                                        componentDidMount={() => this.componentDidMount()}
                                                        reload={(id, key, type) => this.reload(id, key, type)}
                                                        details={(record) => this.details(record)}
                                                        height={"calc(100vh - 136px)"}
                                                        showFooter={true}
                                                        newsloader={this.state.newsloader}
                                                    />


                                                </>
                                            )}
                                        </div>

                                        <div className="col-md-12 "></div>
                                    </div>

                                </TabPane> */}
                            </Tabs>
                        </Col>

                    </Row>
                </Col>
                <Col xxl={7} md={7} xs={0} className={'m-md10 '} style={{height: 'calc(100vh - 99px)', overflowY:"scroll"}}>
                    <NewEvent resultLoader={this.state.resultLoader} activeResulttab={this.state.activeEventtab} TabChangeResult={(type) => this.TabChangeResult(type)} topResult={this.state.topResult} />
                </Col>
            </Row>

        );
    }
}

const mapStateToProps = (state) => ({
    _corporate_results: state.results.corporate_results,
    _news_feed: state.results.news_feed,
    _allnewsletter: state.newsletter.allnewsletter,
    _allmarket: state.newsletter.all_market,
    _related: state.results.related,
    _new_data: state.newsletter.new_data,
    _user_selected: state.publics.user_selected,
    _topics: state.publics.sectors,
    _all_news: state.newsletter.all_news
});

const mapDispatchToProps = (dispatch) => ({
    CorporateResultsTop: (v) => dispatch(actions.CorporateResultsTop(v)),
    NewsFeed: (v) => dispatch(actions.NewsFeed(v)),
    allMarketNewsltter: (v) => dispatch(actions.allMarketNewsltter(v)),
    allMarketNewsData: (v) => dispatch(actions.allMarketNewsData(v)),
    allMarketNews: (v) => dispatch(actions.allMarketNews(v)),
    likeFeed: (v) => dispatch(actions.likeFeed(v)),
    relatedTopics: (v) => dispatch(actions.relatedTopics(v)),
    savedFeeds: (v) => dispatch(actions.savedFeeds(v)),
    getNewData: (v) => dispatch(actions.getNewData(v)),
    UserTagPreferences: (v) => dispatch(actions.UserTagPreferences(v)),
    UserVideoTagPreferences: (v) => dispatch(actions.UserVideoTagPreferences(v)),
    Sectors: (v) => dispatch(actions.Sectors(v)),
    UserPreferences: (v) => dispatch(actions.UserPreferences(v)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainDashboard)
);
