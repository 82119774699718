import React, { useEffect, useState } from "react";
import { Col, Row, Space, Upload, Button, message, Input, Divider, Radio, Avatar, Switch, Popconfirm, Skeleton, Tooltip } from "antd";
import { PencilSimple, Plus, Question } from "@phosphor-icons/react";
import request from "../../../store/request";
import Cookies from "js-cookie";
import TextArea from "antd/lib/input/TextArea";
import { useHistory } from "react-router";

import Toast from "../../utils/Toast";


const ManageSetting = (props) => {
    const [name, setName] = useState("");
    const [description, setDesc] = useState("");
    const [paidChannel, setPaidChannel] = useState(false)
    const [button1, setBtutton1] = useState(false)
    const [button2, setBtutton2] = useState(false)
    const [amount, setAmount] = useState(null);
    const [tenure, setTenure] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    const history = useHistory();

    useEffect(() => {
        setPaidChannel(props.channel?.is_paid);
        setAmount(props.channel?.amount);
        setTenure(props.channel?.tenure);
    }, [props.channel]);

    const props1 = {
        name: 'file',
        accept: ".png,.jpg,.JPEG",
        action:  "account/file/upload",
        data: { type: "tiers" },
        headers: {
            Authorization: Cookies.get("Logintoken"),
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                messageApi.open({
                    key,
                    type: 'loading',
                    content: 'Uploading...',
                });
            }
            if (info.file.status === 'done') {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Uploaded!',
                    duration: 4,
                });
                props.updateChannel(info.file.response.data.url, "img_url");
            } else if (info.file.status === 'error') {
                messageApi.open({
                    key,
                    type: 'error',
                    content: `${info.file.name} file upload failed.`,
                    duration: 4,
                });
            }
        },
        customRequest: (options) => request.customRequest(options, "tiers"),

    };

    const props2 = {
        name: 'file',
        accept: ".png,.jpg,.JPEG",
        action:  "account/file/upload",
        headers: {
            Authorization: Cookies.get("Logintoken"),
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                messageApi.open({
                    key,
                    type: 'loading',
                    content: 'Uploading...',
                });
            }
            if (info.file.status === 'done') {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Uploaded!',
                    duration: 4,
                });
                props.updateChannel(info.file.response.data.url, "banner");
            } else if (info.file.status === 'error') {
                messageApi.open({
                    key,
                    type: 'error',
                    content: `${info.file.name} file upload failed.`,
                    duration: 4,
                });
            }
        },
        customRequest: (options) => request.customRequest(options, "file"),

    };

    const saveName = () => {
        const payload = {
            name: name,
            description: description
        }
        props.updateChannel(payload, "name");
    }

    const onPaidChnage = (e) => {
        setPaidChannel(e);
        if (e === false) {
            props.updateChannel(e, "is_paid");
        }
    }
    const savepaidchannel = () => {
        if (amount < 10){
            return message.warning("Min amount of coins 10.")
        }
        const payload = {
            amount: amount,
            tenure: tenure,
            is_paid: paidChannel
        }
        props.updateChannel(payload, "amount");
    }
    const deleteChannel = async () => {
        await props?.deleteChannel();
        Toast("Channel deleted!", "success");
        history.goBack();
    }
    return (
        <Row>
            <Col span={24} className='padding40'>
                {
                    props?.loader ? <><Skeleton active /><Skeleton active /><Skeleton active /></> : <>
                        <Row>
                            <Col span={24}>
                                <Space>
                                    <p className="fs14 fw400">Banner Image</p>
                                    {/* <Info size={20} color="#BDC2CC" weight="light" /> */}
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Upload
                                    className="avatar-uploader md30 "
                                    listType="picture-card"
                                    showUploadList={false}
                                    {...props2}
                                // onChange={handleChange}
                                >
                                    {props.channel?.banner_img_url ? (
                                        <div className="uploaded-banner" style={{ backgroundImage: `url(${props.channel?.banner_img_url})` }}>
                                            <Tooltip title={'Change banner image'}>
                                                <Space style={{ backgroundColor: "#ffffff2e", borderRadius: "4px", padding: "4px" }}>
                                                    <PencilSimple size={13} color="#000000" weight="bold" />
                                                    <p style={{ color: '#000', marginTop: '3px' }}> Update Image</p>
                                                </Space>
                                            </Tooltip>

                                        </div>
                                    ) : (
                                        <Tooltip title={'Add banner image'}>
                                            <Space>
                                                <Plus size={13} color="#000000" weight="bold" />
                                                <p style={{ color: '#8E8E8E', marginTop: '3px' }}> Add Image</p>
                                            </Space>
                                        </Tooltip>
                                    )}
                                </Upload>
                            </Col>
                        </Row>
                        <Row className='md30'>
                            <Col span={19}>
                                <p className="fs14 fw400">Channel Image</p>

                            </Col>
                            <Col span={5} className="text-right">
                                <div style={{ float: "right" }}>
                                    <Avatar src={props.channel?.img_url} shape={"circle"} size={60} className="edit-avatar" ></Avatar>
                                    <Upload className="edit-avatar-upload"  {...props1} showUploadList={false} preview={false}>
                                        <Button className="edit-avatar-btn" type="ghost" size="small" icon={<PencilSimple size={17} />} ></Button>
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                        <Row className='md30'>
                            <Col span={24}>
                                <p className="fs14 fw400">Channel Name</p>
                                <Input placeholder="Channel name" className='md15' value={name !== "" ? name : props.channel?.name} onChange={(evt) => (setBtutton1(true), setName(evt.target.value))} />
                            </Col>
                            <Col span={24} className='md30'>
                                <p className="fs14 fw400 ">Description</p>
                                <TextArea rows={4} placeholder="Enter description" className='md15 manag_area' showCount={true} maxLength={500}
                                    onChange={(evt) => (setBtutton1(true), setDesc(evt.target.value))} value={description !== "" ? description : props.channel?.desc} />
                            </Col>
                            <Col span={24} className='md30 text-right'>
                                {button1 && (name !== "" || description !== "") && <Button onClick={() => saveName()} type="primary"> Save Changes </Button>}
                            </Col>
                            <Col span={24} className='md20 '>
                                <Divider />
                                {contextHolder}
                            </Col>


                        </Row>
                    </>
                }

                {
                    props?.loader ? <><Skeleton active /><Skeleton active /><Skeleton active /></> : <>

                        <Row className='md30'>
                            <Col span={24}>
                                <p className="fs14 fw400">Channel Privacy</p>
                                <p className="fs12 fw300 md5 text-grey">This is a private channel. Public can only discover this channel through
                                    an invite or share link.</p>
                            </Col>
                            <Col xs={{span: 24}} lg={{span:24}} className='md20'>
                                <Row>
                                    <Col xs={{span: 20}} lg={{span:20}} >
                                        <p className="fs14 fw400">Private</p>

                                    </Col>
                                    <Col xs={{span: 4}} lg={{span:4}}  className=' text-right'>
                                        <Switch onChange={(e) => props.updateChannel(e, "private")} checked={props.channel?.channel_type === 1} checkedChildren={"Private"} unCheckedChildren={"Public"} />
                                    </Col>
                                </Row>
                                <Row className='md35'>
                                    <Col span={20}>
                                        <p className="fs14 fw400">Show Members</p>
                                        <p className="fs12 fw300 text-grey">Show member list to members</p>

                                    </Col>
                                    <Col span={4} className=' text-right'>
                                        <Switch onChange={(e) => props.updateChannel(e, "member_list")} checked={props.channel?.show_member_online_list} checkedChildren={"Show"} unCheckedChildren={"Hide"} />
                                    </Col>
                                </Row>
                                <Row className='md25'>
                                    <Col span={20}>
                                        <p className="fs14 fw400">Message History </p>
                                        <p className="fs12 fw300 text-grey"> Show past messages to new member joined</p>

                                    </Col>
                                    <Col span={4} className=' text-right'>
                                        <Switch onChange={(e) => props.updateChannel(e, "show_chat_history")} checked={props.channel?.show_chat_history} checkedChildren={"Show"} unCheckedChildren={"Hide"} />
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={24} className='md20 '>
                                <Divider />
                            </Col>

                        </Row>
                        <Row className='md30'>
                            <Col span={24}>
                                <p className="fs14 fw400">Free/ Paid</p>
                                <p className="fs12 fw300 md5 text-grey">Users can join this channel after payment.</p>
                            </Col>
                            <Col span={24} className='md15'>
                                <Row>
                                    <Col span={20}>
                                        <p className="fs14 fw400">Paid</p>

                                    </Col>
                                    <Col span={4} className=' text-right'>
                                        <Switch checked={paidChannel} checkedChildren={"Yes"} unCheckedChildren={"No"}
                                            onChange={(e) => onPaidChnage(e)} />
                                    </Col>
                                </Row>

                            </Col>
                            {
                                paidChannel &&

                                <Col span={24} className='md20 grey-card '>
                                    <Row>
                                        <Col span={24}>
                                            <p className="fs14 fw400"> How many coins you'll charge? </p>
                                            <Input value={amount} onChange={(e) => (setBtutton2(true), setAmount(e.target.value))} placeholder="Enter coins" width={50} className='md10' />
                                        </Col>
                                        <Col span={24} xs={24} className='md30'>
                                            <p className="fs14 fw400"> Subscription Tenure </p>
                                            <small className="text-grey">User will have to join again after this time peroid</small>
                                            <Radio.Group
                                                style={{ display: "inline-flex", marginBottom: "21px" }}
                                                buttonStyle="solid" className="md15" value={tenure} onChange={(e) => (setBtutton2(true), setTenure(e.target.value))}  >
                                                <Radio.Button
                                                    value={720}
                                                    className="margin-right"
                                                    style={{ width: "max-content" }} key={0}>
                                                    Until Leave
                                                </Radio.Button>
                                                <Radio.Button
                                                    value={1}
                                                    className="margin-right"
                                                    style={{ width: "max-content" }} key={1}>
                                                    1 Month
                                                </Radio.Button>
                                                <Radio.Button
                                                    value={3}
                                                    className="margin-right"
                                                    style={{ width: "max-content" }} key={2} >
                                                    3 Month
                                                </Radio.Button> 
                                                <Radio.Button
                                                    value={6}
                                                    className="margin-right"
                                                    style={{ width: "max-content" }} key={3}>
                                                    6 Month
                                                </Radio.Button>
                                              
                                                <Radio.Button
                                                    value={12}
                                                    className="margin-right"
                                                    style={{ width: "max-content" }} key={4}>
                                                    1 Year
                                                </Radio.Button>
                                                


                                            </Radio.Group>
                                        </Col>
                                        {/*<Col span={24} className='25'>
                                            <p className="fs14 fw400"> Select Bank Account </p>
                                            <p className="fs12 fw300"> Lorem ipsum is a placeholder text commonly used to demonstrate  </p>

                                        </Col>
                                        <Col span={24} className='25'>
                                            <Row className='bank-card md20' >
                                                <Col xs={{ span: 22 }} lg={{ span: 24 }} style={{ padding: "18px" }}>
                                                    <Space >
                                                        <p className="fs15 fw400">State Bank of India</p>
                                                        <p className="fs14 fw400 text-grey">*** **** *** 67452</p>

                                                    </Space>
                                                    <p className="fs12 fw300 text-grey md5">State Bank Of India, Bilari, Moradabad, Uttar Pradesh, 244411</p>

                                                </Col>


                                            </Row>
                                            <Row className='bank-card md20' >
                                                <Col xs={{ span: 22 }} lg={{ span: 24 }} style={{ padding: "18px" }}>
                                                    <Space >
                                                        <p className="fs15 fw400">State Bank of India</p>
                                                        <p className="fs14 fw400 text-grey">*** **** *** 67452</p>

                                                    </Space>
                                                    <p className="fs12 fw300 text-grey md5">State Bank Of India, Bilari, Moradabad, Uttar Pradesh, 244411</p>

                                                </Col>


                                            </Row>
                                        </Col> */}
                                        <Col span={24} className='md35 text-right'>
                                            {
                                                button2 && <Button type="primary" onClick={() => savepaidchannel()}> Save Changes </Button>
                                            }

                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col span={24} className='md10'>
                                <Divider></Divider>
                            </Col>
                            <Col span={24}>
                                <Row style={{ backgroundColor: "#e1080517", borderRadius: "16px", padding: "16px" }}>
                                    <Col span={24} className='md15'>
                                        <p className="fs14 fw400"> Delete Channel</p>
                                        <p className="fs12 fw300"> All data published in this channel will be deleted, Once you delete this group it can't be undone so be very careful!</p>
                                    </Col>
                                    <Col span={24} className='md20 mb-2'>
                                        <Popconfirm
                                            placement="bottom"
                                            title={<p>Are you sure you want to delete this channel? <br /> All data published in this channel will be deleted, this action cannot be undo.</p>}
                                            description="Are you sure you want to delete this channel"
                                            icon={<Question size={20} color="red" weight="light" />}
                                            onConfirm={(e) => (e.preventDefault(), deleteChannel())}

                                            okText="Delete"
                                            cancelText="No"

                                        >
                                            <Button className="red-border"> Delete channel </Button>
                                        </Popconfirm>

                                    </Col>
                                </Row>
                            </Col>


                        </Row>
                    </>
                }
            </Col>
        </Row>
    );
}
export default ManageSetting;