
// Import our top-level sass file.
import './assets/css/style.scss'
import React from "react";
import ReactDOM from "react-dom";
import Cookies from "js-cookie"
import { Route, Redirect, BrowserRouter as Router, useLocation, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import Reducers from "./store/reducers/index";
import jwt_decode from "jwt-decode";

import { GoogleOAuthProvider } from '@react-oauth/google';

//  Routing Includes

import Results from './components/Results/Results';
import Login from "./components/Login/Login"
import Register from "./components/SignUp/SignUp"
import UserPreferences from "./components/UserPreferences/UserPreferences"
import EmailVerify from "./components/EmailVerify/EmailVerify"
import ForgotPassword from "./components/ForgotPassword/ForgotPassword"
import ResetPassword from "./components/ResetPassword/ResetPassword"

import CreateChannel from "./components/Channels/Create-Channel"

import Signals from "./components/SignalMarket/Signals"
import CreateSignal from "./components/SignalMarket/CreateSignal"
import NewsLetterView from "./components/GlobalComponents/FeedDetails.jsx"
import Channel from './components/Channel/v3/ChannelDetailV3';
// import AllEvents from './components/Event/AllEvents';
import VideoApp from "./components/VideoRoom/EventRoom"
// import Subscription from "./components/Subscription/Subscription"
// import Live from './components/Live/Live';
import LiveChat from './components/Live/eventLive';
// import AdditionalInformation from "./components/SignUp/CompanyDetails.jsx"
import Podcasts from "./components/Podacast/Podcasts";
import CreatePodcast from "./components/Podacast/PodcastClaim";

import moment from 'moment';
import 'moment/locale/en-gb';
// import EventDetails from './components/Event/EventDetails';
// import AllPayments from './components/Payments/Payments';
import Messanger from './components/Messanger/Messanger';
// import RecordPodcast from './components/Podacast/RecordPodcast';

import MyBookmarked from './components/MyUniverse/MyBookmarked';
import ChannelCreateV3 from './components/Channel/v3/CreateChannelv3';
import GoLivev2 from './components/Live/GoLivev2';
import Pollv2 from './components/MyHub/PollView';
import MyHubv2 from './components/MyHub/MyHub';
import MainDashboard from './components/MainDashboard/MainDashboard';
import ExploreCreator from './components/MainDashboard/ExploreCreator';
import ExploreTagSelected from './components/MainDashboard/ExploreTagSelected';
import CreateEvent from './components/MainEvents/CreateEvent';
import NewCreatePodcast from './components/MainPodcast/NewCreatePodcast';
import CreatePoll from './components/MainPodcast/CreatePoll';
import Settings from './components/Settings/Settings'
// import TriviaGame from './components/MainTrivia/TriviaGame';
import LiveTalks from './components/MainLive/LiveTalks';
import Desktop from './components/MainDesktop/Desktop';
import UpdateProfile from './components/Settings/Update/EditProfile'
import EventDetailView from './components/MainEvents/EventDetailView';
import CreateMentorship from './components/Mentorship/CreateMentorship';
import CreateMemberShip from './components/MemberShip/CreateMemberShip';
import CreateCourses from './components/Courses/CreateCourses';
import AddLesson from './components/Courses/AddLesson';
// import CreateMentorshipSec from './components/Mentorship/CreateMentorshipSec';

import * as serviceWorker from './serviceWorker';
import Notes from './components/Notes/Notes';
import CreateNotesReader from './components/Notes/CreateNotesReader';
import CreateWhatsApp from './components/WhatsApp/CreateWhatsApp';
import Reels from './components/NewsFeed/Reels';
import ReelsView from './components/Reels/ReelsView';
import GlobalLayout from './components/GlobalLayout';
import PublicLayout from "./components/GlobalLayout/publicLayout"
import MentorshipDetails from './components/Mentorship/MentorshipDetails';
import { useParams } from "react-router-dom";
import CreateResarchReport from './components/Newsletter/CreateResarchReport';
import MentorshipBooking from './components/Mentorship/MentorshipBooking';
import SignUpOnboard from './components/Login/SignUpOnboard';
import CreatorTVPlayList from './components/TV/CreatorTVPlayList';
import CreatorTV from './components/TV/CreatorTV';
import SearchTV from './components/TV/SearchTV';
import TVDetails from './components/TV/TVDetails';
import TVDetailVideos from './components/TV/TVDetailVideos';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import RewardDashboard from "./components/Reward/RewardDashboard"
import RewardActivity from './components/Reward/RewardActivity';
import SylloMate from './components/SylloMate/SylloMate';
import ConnectApp from './components/Settings/SocialApps/ConnectApp';
import Onboard_ReviewBusinessInfo from './components/SignUp/business/Onboard_ReviewBusinessInfo';
import Onboard_BusinessInfo from './components/SignUp/business/Onboard_BusinessInfo';
import Onboard_CreatorInfo from './components/SignUp/creator/Onboard_CreatorInfo';
import Preferences from './components/SignUp/Preferences';
// import MasterProgram from './components/CampusV2/MasterProgram';
import CreateCampusV2 from './components/CampusV2/CreateCampusV2';
// import MainCampusV2 from './components/CampusV2/MainCampus';
import AdminConsole from './components/Business/AdminConsole';
import ManageRequests from './components/Business/ManageRequests';
import WriteNewsletter from './components/Newsletter/WriteNewsletter';
import BusinessPage from './components/Business/BusinessPage';
import BusinessMembers from './components/Business/BusinessMembers';
import NewProfileV2 from './components/NewProfile';
import SylloCoin from './components/Wallet/SylloCoin';
import SylloCoinHistory from './components/Wallet/SylloCoinHistory';
import { Toaster } from 'react-hot-toast';
// import LiveManage from './components/LiveClass/LiveManage';
// import ZoomMeteingView from './components/LiveClass/ZoomMeteingView';
import KidsCampus from './components/CampusV2/kids/KidsCampus';
import KidsLearning from './components/CampusV2/kids/KidsLearning';
// import LiveClassDetail from './components/LiveClass/LiveClassDetail';
import CreateCampusKids from './components/CampusV2/kids/CreateCampusKids'
import MentorshipLiveView from './components/Mentorship/MentorshipLiveView';
import CreatePodcastV2 from './components/Podcastv2/CreatePodcastV2.jsx';
import PodcastDetails from './components/Podcastv2/PodcastDetails.jsx';
import ContentApproval from './components/Business/contentApproval/index.jsx';
import CourseDetails from './components/Courses/CourseDetails.jsx';

// Sentry.init({
//     dsn: "https://af4f5c3affcd4adb9833ab1ce6500aa8@o4504852000866304.ingest.sentry.io/4504852013645824",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
// });

moment.locale('en-gb', {
    weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
});
const history = createBrowserHistory();

Number.prototype.convertToMoney = function () {
    var _value = this.valueOf();
    if (_value >= 10000000) _value = (_value / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr';
    else if (_value > 100000) _value = (_value / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
    else if (_value === 100000) _value = (_value / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
    else if (_value >= 1000) _value = (_value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    else _value = parseInt(_value);
    return _value || 0;
};

Number.prototype.convertToTenure = function () {
    var _value = this.valueOf();
    if (_value === 12) return '1 year';
    if (_value === 6) return '6 months';
    if (_value === 3) return '3 months';
    if (_value === 1) return 'month';
    if (_value === 0) return 'free';
    
};
Number.prototype.secondsToMMSS = function() {
    var seconds = this.valueOf();
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  


const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25
        })) ||
    compose;
const store = createStore(Reducers, composeEnhancers(applyMiddleware(thunk)));

const isAuthenticated = () => {
    const tokenString = Cookies.get("Logintoken");
    if (tokenString) {
        // verify JWT
        try {
            var decoded = jwt_decode(tokenString,);
            if (Date.now() >= decoded.exp * 1000) { // validate exp
                return false;
            }
        } catch {
            return false;
        }
    } else {
        return false;
    }
    return tokenString;
}


// if you  want Layout use this
const PrivateRoute = ({ component: Component, ...rest }) => {

    let token = isAuthenticated();
    const location = useLocation();
    let pathname = location.pathname
    let newLoginPath = '/login'
    if (!token && pathname !== '/login' && pathname !== '/logout') {
        newLoginPath = newLoginPath + "?redirect=" + pathname;
        localStorage.setItem('redirect', pathname);
    }

    return (<Route {...rest} render={(props) => (
        token
            ? <GlobalLayout><Component {...props} /> </GlobalLayout>
            : <Redirect to={newLoginPath} />
    )} />
    );
};

// Permission: Business Role Pages
const BusinessRoute = ({ component: Component, ...rest }) => {

    let token = isAuthenticated();
    const location = useLocation();
    let pathname = location.pathname
    let newLoginPath = '/login'
    if (!token && pathname !== '/login' && pathname !== '/logout') {
        newLoginPath = newLoginPath + "?redirect=" + pathname;
        localStorage.setItem('redirect', pathname);
    }
    const role = parseInt(Cookies.get('__role'));
    // if user is login and not business role then exit
    const _current_profile = localStorage.getItem("current");

    if (token && (role !== 9 && _current_profile !== "business")) {
        token = false
        newLoginPath = '/settings'
    }

    return (<Route {...rest} render={(props) => (
        token
            ? <GlobalLayout><Component {...props} /> </GlobalLayout>
            : <Redirect to={newLoginPath} />
    )} />
    );
};

// if you don't want Layout use this
const AloneRoute = ({ component: Component, ...rest }) => {
    let token = isAuthenticated();
    // const {token} = useToken();
    const location = useLocation();
    let pathname = location.pathname
    let newLoginPath = '/login'
    if (!token && pathname !== '/login' && pathname !== '/logout') {
        newLoginPath = newLoginPath + "?redirect=" + pathname;
        localStorage.setItem('redirect', pathname);
    }

    return (<Route {...rest} render={(props) => (
        token
            ? <Component {...props} />
            : <Redirect to={newLoginPath} />
    )} />
    );
};


// if you don't want Layout use this
const PublicRoute = ({ component: Component, ...rest }) => {

    let token = isAuthenticated();
    return (<Route {...rest} render={(props) => (
        token
            ? <GlobalLayout><Component {...props} /> </GlobalLayout>
            : <PublicLayout token={token}> <Component {...props} /></PublicLayout>
    )} />

    );
};




const routing = (
    <Provider store={store} >

            <div><Toaster/></div>
        <Router history={history}>
     
            <Switch>
                <Route exact path="/login" component={() => { return <GoogleOAuthProvider clientId="142098571419-tjrb7kahlnbrj5ksvvg80botq38f2dqe.apps.googleusercontent.com"><Login /></GoogleOAuthProvider> }} name="Syllo Login " />
                <Route exact path="/sign-up" component={() => { return <GoogleOAuthProvider clientId="142098571419-tjrb7kahlnbrj5ksvvg80botq38f2dqe.apps.googleusercontent.com"><Register /></GoogleOAuthProvider> }} name="Syllo Register " />
                <AloneRoute exact path="/onboard" component={() => { return <SignUpOnboard /> }} name="Signup Login " />
                <Route exact path="/onboard/business/:role" component={() => { return <Onboard_BusinessInfo /> }} name="Business Info" />
                <Route exact path="/onboard/creator" component={() => { return <Onboard_CreatorInfo /> }} name="Creator Info" />
                <Route exact path="/onboard/:user_code/review" component={() => { return <Onboard_ReviewBusinessInfo /> }} name="Business Info Review" />
                <Route exact path="/preference" component={() => { return <Preferences /> }} name="Preferences"></Route>
                {/* <AloneRoute exact path="/additional-information" component={() => { return <AdditionalInformation /> }} name="All Creator Additional Information" ></AloneRoute> */}
                <Route exact path="/forgot-password" component={() => { return <ForgotPassword /> }} name="Syllo forgot password module " />
                <Route exact path="/reset-password" component={() => { return <ResetPassword /> }} name="Syllo reset password module " />

                <PrivateRoute exact path="/dashboard" component={() => { return <MainDashboard /> }} name="Dashboard " />
                <PublicRoute exact path="/" component={() => { return <MainDashboard /> }} name="Dashboard " />



                <PrivateRoute exact path="/results" component={() => { return <Results /> }} name="Corporates Results " />
                <PrivateRoute exact path="/podcasts" component={() => { return <Podcasts /> }} name="Createpodcast " />
                <PrivateRoute exact path="/create-podcast" component={() => { return <CreatePodcast /> }} name="PodcastSection " />
                {/* <PrivateRoute exact path="/record-podcast" component={() => { return <RecordPodcast /> }} name="Record Podcast " /> */}
                <AloneRoute exact path="/user-preference" component={() => { return <UserPreferences /> }} name="Syllo user choices " />
                <AloneRoute exact path="/email-verify" component={() => { return <EmailVerify /> }} name="Syllo user email Verify " />

                <PrivateRoute exact path="/create-channel" component={() => { return <CreateChannel /> }} name="Syllo create channel  " />

                <PrivateRoute exact path="/signals" component={() => { return <Signals /> }} name="Syllo Signals Market" />
                <PrivateRoute exact path="/create-signal" component={() => { return <CreateSignal /> }} name="Syllo Signals Creation" />
                {/* <PrivateRoute exact path="/newsletter" component={() => { return <NewsLetter /> }} name="Newsletter Module" /> */}
                <PrivateRoute exact path="/feed-view/:uuid" component={() => { return <NewsLetterView params={useParams()} /> }} name="Newsletter Module View" />
                <AloneRoute exact path="/newsletter/:uuid" component={() => { return <WriteNewsletter /> }} name="Newsletter Module Create" />
                <PublicRoute exact path="/profile/:id" component={() => { return <Desktop /> }} name="Profile" />
                <PrivateRoute exact path="/channel/:uuid/:id" component={() => { return <Channel /> }} name="Channel Details" />
                {/* <PrivateRoute exact path="/event" component={() => { return <EventDetails /> }} name="Event Details" /> */}
                {/* <PrivateRoute exact path="/all-events" component={() => { return <AllEvents /> }} name="All Event Details" /> */}
                <PrivateRoute exact path="/event-room" component={() => { return <VideoApp /> }} name="All Event App" />
                {/* <PrivateRoute exact path="/subscriptions" component={() => { return <Subscription /> }} name="All Subscriptions" /> */}
                {/* <PrivateRoute exact path="/payments" component={() => { return <AllPayments /> }} name="All Payments" /> */}
                <PrivateRoute exact path="/messenger" component={() => { return <Messanger /> }} name="All Meessanges" />
                {/* <PrivateRoute exact path="/live" component={() => { return <Live /> }} name="All Live Talks" /> */}
                {/* <PrivateRoute exact path="/go-live" component={() => { return <LiveStreaming /> }} name="Go Live" /> */}
                <PrivateRoute exact path="/go-live/:id" component={() => { return <GoLivev2 /> }} name="Go Livev2" />

                <PrivateRoute exact path="/bookmarked" component={() => { return <MyBookmarked /> }} name="My Bookmarked"></PrivateRoute>
                <PrivateRoute exact path="/ChannelBank" component={() => { return <ChannelCreateV3 /> }} name="ChannelCreateV3"></PrivateRoute>
                <PrivateRoute exact path="/poll-view" component={() => { return <Pollv2 /> }} name="Pollv2"></PrivateRoute>
                <PrivateRoute exact path="/my-hub" component={() => { return <MyHubv2 /> }} name="MyHubv2"></PrivateRoute>
                <PrivateRoute exact path="/my-hub/:tab" component={() => { return <MyHubv2 /> }} name="MyHubv2"></PrivateRoute>

                <PublicRoute exact path="/explore" component={() => { return <ExploreCreator /> }} name="ExploreCreator"></PublicRoute>

                <PublicRoute exact path="/explore/tag" component={() => { return <ExploreTagSelected /> }} name="ExploreEvents"></PublicRoute>
                <PrivateRoute exact path="/event/new" component={() => { return <CreateEvent /> }} name="Create new event"></PrivateRoute>
                <PrivateRoute exact path="/event/:id" component={() => { return <EventDetailView /> }} name="Eventdetails"></PrivateRoute>
                <PrivateRoute exact path="/event/:id/live" component={() => { return <LiveChat /> }} name="Live Talk Chat" />
                <PrivateRoute exact path="/newcreate-podcast" component={() => { return <NewCreatePodcast /> }} name="CreatePodcast"></PrivateRoute>
                <PrivateRoute exact path="/poll/new" component={() => { return <CreatePoll /> }} name="CreatePoll"></PrivateRoute>
                <PrivateRoute exact path="/settings" component={() => { return <Settings /> }} name="Settings"></PrivateRoute>
                <PrivateRoute exact path="/profile-update" component={() => { return <UpdateProfile /> }} name="Settings"></PrivateRoute>
                <PrivateRoute exact path="/live-talks" component={() => { return <LiveTalks /> }} name="LiveTalks"></PrivateRoute>
                <PrivateRoute exact path="/mentorship-new" component={() => { return <CreateMentorship /> }} name="CreateMentorship"></PrivateRoute>
                <PrivateRoute exact path="/course" component={() => { return <CreateCourses /> }} name="CreateCourses"></PrivateRoute>
                <PrivateRoute exact path="/course/:id/edit" component={() => { return <CreateCourses /> }} name="CreateCourses"></PrivateRoute>
                <PrivateRoute exact path="/course/:id/lesson" component={() => { return <AddLesson /> }} name="CreateCourses"></PrivateRoute>
                <PrivateRoute exact path="/course/:id" component={() => { return <CourseDetails /> }} name="CreateDetail"></PrivateRoute>
                <PrivateRoute exact path="/membership/new" component={() => { return <CreateMemberShip /> }} name="CreateMemberShip"></PrivateRoute>
                {/* <PrivateRoute exact path="/notification" component={()=>{return<Notification/>}} name="Notification"></PrivateRoute>  */}
                <PrivateRoute exact path="/reels" component={() => { return <Reels /> }} name="Reels"></PrivateRoute>
                <PrivateRoute exact path="/notes" component={() => { return <Notes /> }} name="Notes"></PrivateRoute>
                <PrivateRoute exact path="/note/:uuid" component={() => { return <CreateNotesReader /> }} name="Notes"></PrivateRoute>
                <PrivateRoute exact path="/group/create" component={() => { return <CreateWhatsApp /> }} name="WhatsApp/Telegram"></PrivateRoute>
                <PublicRoute exact path="/reels/view" component={() => { return <ReelsView /> }} name="ReelsView"></PublicRoute>
                <PrivateRoute exact path="/mentorship/:id" component={() => { return <MentorshipDetails /> }} name="MentorshipDetails"></PrivateRoute>
                <PrivateRoute exact path="/mentorship-booking/:id" component={() => { return <MentorshipBooking /> }} name="MentorshipBooking"></PrivateRoute>
                <AloneRoute exact path="/mentorship-booking/:id/join" component={() => { return <MentorshipLiveView /> }} name="MentorshipLiveView"></AloneRoute>
                {/* <PrivateRoute exact path="/campus" component={() => { return <CampusCreated /> }} name="CampusCreated/"></PrivateRoute> */}
                {/* <PublicRoute exact path="/campus/:campus_id" component={() => { return <MainCampusV2 /> }} name="MainCampusV2/"></PublicRoute> */}
                {/* <PublicRoute exact path="/campus/:campus_id/master-program" component={() => { return <MasterProgram /> }} name="MasterProgram"></PublicRoute> */}
                <PrivateRoute exact path="/create-report" component={() => { return <CreateResarchReport /> }} name="CreateResarchReport/"></PrivateRoute>
                <PrivateRoute exact path="/tv" component={() => { return <CreatorTVPlayList /> }} name="TVPlayList/"></PrivateRoute>
                <PrivateRoute exact path="/tv/:playlist_id/play/:epd_id" component={() => { return <TVDetailVideos /> }} name="TVDetails/"></PrivateRoute>
                {/* <PrivateRoute exact path="/tv/:user_code" component={() => { return <CreatorTVPlayList/> }} name="TVPlayList/"></PrivateRoute> */}
                <PrivateRoute exact path="/tv/:user_code" component={() => { return <TVDetails /> }} name="TVDetails/"></PrivateRoute>
                <PrivateRoute exact path="/tv/:playlist_id/add" component={() => { return <GoogleOAuthProvider clientId="142098571419-tjrb7kahlnbrj5ksvvg80botq38f2dqe.apps.googleusercontent.com"><CreatorTV /></GoogleOAuthProvider> }} name="CreatorTV/"></PrivateRoute>
                <PrivateRoute exact path="/explore/tv" component={() => { return <SearchTV /> }} name="SearchTV/"></PrivateRoute>
                <PrivateRoute exact path="/rewards" component={() => { return <RewardDashboard /> }} name="Rewards"></PrivateRoute>
                <PrivateRoute exact path="/rewards/activity" component={() => { return <RewardActivity /> }} name="Activity"></PrivateRoute>
                <PrivateRoute exact path="/syllomate" component={() => { return <SylloMate /> }} name="Activity"></PrivateRoute>
                <PrivateRoute exact path="/connect/app/:app" component={() => { return <ConnectApp /> }} name="ConnectApp"></PrivateRoute>
                <PrivateRoute exact path="/campus-update" component={() => { return <CreateCampusV2 /> }} name="CreateCampusV2"></PrivateRoute>
                <PrivateRoute exact path="/campus-kids/marketplace" component={() => { return <KidsCampus /> }} name="KidsCampus"></PrivateRoute>
                <PrivateRoute exact path="/campus-kids/new" component={() => { return <CreateCampusKids /> }} name="CreateCampusKids"></PrivateRoute>
                <PrivateRoute exact path="/campus-kids/:campus_id" component={() => { return <KidsLearning /> }} name="KidsLearning"></PrivateRoute>
                {/* <PrivateRoute exact path="/live-class" component={() => { return <LiveManage /> }} name="LiveManage"></PrivateRoute> */}
                {/* <PrivateRoute exact path="/live-class/:class_id" component={() => { return <LiveClassDetail /> }} name="LiveClassDetail"></PrivateRoute> */}
                {/* <AloneRoute exact path="/live-class/:class_id/join" component={() => { return <ZoomMeteingView /> }} name="ZoomMeteingView"></AloneRoute> */}
                <BusinessRoute exact path="/console" component={() => { return <AdminConsole /> }} name="AdminConsole"></BusinessRoute>
                <BusinessRoute exact path="/business/connection" component={() => { return <ManageRequests /> }} name="ManageRequests"></BusinessRoute>
                <BusinessRoute exact path="/business/members" component={() => { return <BusinessMembers /> }} name="BusinessMembers"></BusinessRoute>
                <BusinessRoute exact path="/business/post-request" component={() => { return <ContentApproval /> }} name="ContentApproval"></BusinessRoute>
                <PrivateRoute exact path="/business/:username" component={() => { return <BusinessPage /> }} name="BusinessPage"></PrivateRoute>
                <PrivateRoute exact path="/newprofilev2" component={() => { return <NewProfileV2/> }} name="NewProfileV2"></PrivateRoute>
                <PrivateRoute exact path="/coins" component={() => { return <SylloCoin/> }} name="SylloCoin"></PrivateRoute>
                <PrivateRoute exact path="/coins/history" component={() => { return <SylloCoinHistory /> }} name="SylloCoinHistory"></PrivateRoute>
                <PrivateRoute exact path="/publish-podcast" component={() => { return <CreatePodcastV2 /> }} name="CreatePodcastV2"></PrivateRoute>
                <PrivateRoute exact path="/podcast/:id" component={() => { return <PodcastDetails /> }} name="PodcastDetails"></PrivateRoute>
            </Switch>
        </Router>

    </Provider>
);

ReactDOM.render(routing, document.querySelector('#app'));
serviceWorker.register();