import { Col, Row, Input, Button, Select } from "antd";
import React from "react";

const { Option } = Select;

const NewCreatePodcast = () => {
    return (


        <Row >
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }} md={{ span: 20, offset: 2 }} className='md35  padding20' >
                <Row>
                    <Col span={24} className='text-center'>
                        <p className="fs16 fw500">Create Poll</p>
                    </Col>
                    <Col span={24} className='md70'>
                        <p className="fs18 fw400">Link to RSS Feed <span className="fs12 fw400 text-grey">*Paste the full URL in the field</span></p>
                        <Input placeholder="https://feed.example.com/rss/podcast-my" className='md10' />
                    </Col>
                    <Col span={24} className='md70 text-center'>
                        <p className="fs14 fw300">Make sure your podcast content meets our <span className="fs14 fw300 " style={{ color: ' #1DB954', textDecoration: 'underline' }}>guidelines.</span></p>
                    </Col>
                    <Col span={24} className='md20 text-center'>
                        <Button type="primary" className='continue-btn '>Verify link</Button>
                    </Col>
                </Row>
                <Row className="md45">
                    <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} className='podcast-card' >
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 19, offset: 0 }} md={{ span: 19, offset: 0 }}>
                                <p className="fs16 fw700">Test RSS Feed Podcast</p>
                                <p className="fs14 fw400 md5">By Jason Roach</p>
                                <p className="fs14 fw400 md20">Testing out an podcast RSS feed.</p>
                                <p className="fs14 fw400 md20" style={{ color: ' #1DB954' }}>It’s already uploaded. Claim it</p>
                            </Col>
                            <Col xs={{ span: 18, offset: 3 }} lg={{ span: 5, offset: 0 }} md={{ span: 20, offset:2 }} className='image-radius m-md10' style={{border:'1px solid red'}}>
                                <p className="padding25 md25 text-center"> image</p>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={24} className='md40  podcast-select'>
                        <p className="fs16 fw400"> Tags (optional)</p>
                        <Select
                            mode="multiple"
                            style={{ width: '50%' }}
                            placeholder="select one country"
                            defaultValue={['English']}
                            optionLabelProp="label"
                            className="md10"
                        >
                            <Option value="English" label="English">
                                <div className="demo-option-label-item">
                                    <span role="img" aria-label="English">
                                        🇨🇳
                                    </span>
                                    English (中国)
                                </div>
                            </Option>


                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col span={14} offset={5} className='md200 text-center mb-5'>
                        <Button type="primary" className='continue-btn '> Continue </Button>
                    </Col>
                </Row>
            </Col>
        </Row>


    );
}
export default NewCreatePodcast;
