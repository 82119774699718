import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    token_details: null,
}
const StreamingReducer = handleActions({
    STREAMING_TOKEN: (state, action) => {
        return { ...state, token_details: action.payload };
    }
}, INITIAL_STATE);

export default StreamingReducer;
