import React, { useState } from "react";
import { Row, Col, Space, Button, Avatar, Tag, Modal, Input } from "antd";
import { ChartBarHorizontal, ChatCircle, DotsThreeOutline, EnvelopeSimple, Heart, Newspaper, PaperPlaneTilt, SpeakerHigh, SpeakerSlash, Pause, Play, DotsThreeVertical } from "@phosphor-icons/react";
import ReactPlayer from 'react-player'
import { RWebShare } from "react-web-share";


const ReelsViewCard = ({ data, muteVideo, startVideo, likeFeed }) => {

    const [comments, showComments] = useState(false)
    return (


        <Row className="reel-video-box nopadding section-scroll" justify={"center"} >
            <Col lg={{ span: 7, offset: 1 }} xs={{ span: 24 }} className='bg-reels-img  nopadding'>

                <ReactPlayer loop playIcon={true} playing={data?.play} muted={data?.mute} url={data?.link} height={"100%"} width={"100%"} style={{ "width": "100%", "height": "100%", "left": "0px", "top": "0px", position: "relative", zIndex: "1" }} ></ReactPlayer>

                <div className="text-right" style={{ position: "absolute", top: "10px", zIndex: "100", right: "10px" }}>
                    {
                        !data?.mute ? <Button shape="circle" type="text" onClick={() => muteVideo(data, true)} icon={<SpeakerHigh size={22} color="#ffffff" weight="fill" />}></Button> : <Button shape="circle" type="text" onClick={() => muteVideo(data, false)} icon={<SpeakerSlash size={22} color="#ffffff" weight="fill" />}></Button>
                    }

                </div>
                <div className="text-left" style={{ position: "absolute", top: "10px", zIndex: "100", left: "10px" }}>
                    {
                        data?.play ? <Button shape="circle" type="text" onClick={() => startVideo(data, false)} icon={<Pause size={22} color="#ffffff" weight="fill" />}></Button> : <Button shape="circle" type="text" onClick={() => startVideo(data, true)} icon={<Play size={22} color="#ffffff" weight="fill" />}></Button>
                    }

                </div>


                <div style={{ position: "absolute", bottom: "0px", padding: "20px 5px", zIndex: "100", width: "100%", background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)" }}>
                    <p className="title">{data?.title}</p>
                    <Row className="md15">
                        <Col span={3} className='mb-0'>
                            <Avatar size={'default'} src={data?.profile_pic}></Avatar>
                        </Col>
                        <Col  lg={{ span: 15, offset: 0 }}   xs={{ span: 16, offset: 0 }}>
                            <p className="user ">{data?.name}</p>

                            <div className="">
                                {data?.tags.map((item) => {
                                    return <Tag style={{ marginTop: '5px' }}>{item}</Tag>
                                })}
                            </div>

                        </Col>
                        {/* <Col lg={{ span: 6, offset: 0 }}   xs={{ span: 5, offset: 0 }} className="reels-circle-bg">
                            <Space className="reels-icons">
                                <Button shape="circle" type="text" icon={<Newspaper size={20} color="#FFFFFF" weight="regular" />} ></Button>
                                <Button shape="circle" type="text" icon={<ChartBarHorizontal size={20} color="#FFFFFF" weight="regular" />}></Button>
                            </Space>
                            <div className="text-center" style={{ marginTop: '-15px' }}>

                                <Button shape="circle" type="text" icon={<EnvelopeSimple size={20} color="#FFFFFF" weight="regular" />}></Button>
                            </div>
                        </Col> */}
                    </Row>
                </div>


            </Col>
            <Col lg={{ span: 4, offset: 0 }} xs={{ span: 24 }} style={{ position: "relative", alignSelf: 'flex-end' }}>
                <Row >
                    <Col span={6} className='text-center' style={{ justifyContent: "end", alignItems: "end" }}>
                        <Space align="center" direction="vertical">

                            <div>

                                <Button shape="circle" className={"blue-hover"} onClick={() => window.location.href = "/feed-view/" + data?.entity_uuid} type="text" icon={<ChatCircle className={"border-blue"} size={28} weight="thin" />}></Button>
                                <p className="fs11">{data?.comments}</p>
                            </div>

                            <div>

                                {
                                    data.liked === true ?
                                        <Button type={"text"} className={"heart-hover"} shape={"circle"} icon={<Heart size={28} weight="fill" color={"#e71313"} />} onClick={() => likeFeed(data, false)}>  </Button>
                                        : <Button type={"text"} className={"heart-hover"} shape={"circle"} icon={<Heart size={28} weight="thin" className={"border-red"} />} onClick={() => likeFeed(data, true)}>     </Button>
                                }

                                <p className="fs11">{data?.likes}</p>
                            </div>

                            <div style={{ marginBottom: '40px' }}>
                                <RWebShare data={{
                                    text: data.description,
                                    url: window.location.href,
                                    title: data.title,
                                }}>
                                    <Button shape="circle" type="text" className={"blue-hover"} icon={<PaperPlaneTilt size={28} weight="thin" className={"border-blue"} />}></Button>
                                </RWebShare>


                            </div>

                            {/* <div style={{ marginBottom: '40px' }}>

                                <Button shape="circle" type="text" icon={<DotsThreeOutline size={28} weight="thin" />}></Button>

                            </div> */}

                        </Space>

                    </Col>
                </Row>
            </Col>



            <Modal title="Comments" open={comments} width={580} onCancel={() => showComments(false)} footer={false}>
                <Row>
                    <Col span={24} className='padding25'>
                        <Row>
                            <Col span={3}>
                                <Avatar size={40}></Avatar>
                            </Col>
                            <Col span={21}>
                                <Input placeholder="Write a comment..." className="m-ps-0 m-iphonex-ml  " rows={2} style={{ width: "100%" }} />

                            </Col>
                            <Col span={24} className='text-right md20'>
                                <Space>
                                    <Button type={"text"} className="reels-btn" >Cancel</Button>
                                    <Button type={"primary"} >Comment</Button>
                                </Space>
                            </Col>
                        </Row>
                        <Row className="md35">
                            <Col span={3}>
                                <Avatar size={40}></Avatar>
                            </Col>
                            <Col span={21} className='reels-cmnt-bg'>
                                <Row>
                                    <Col span={19}>
                                        <p className="fs14 fw500">Avishek Maskara</p>
                                        <p className="fs13 fw400 md5">I was just testing how does comment system work programatically?</p>
                                    </Col>
                                    <Col span={5} className='text-right'>
                                        <Space>
                                            <p className="fs12 fw300 text-grey">1 day ago</p>
                                            <DotsThreeVertical size={20} color="#3b3c3f" />
                                        </Space>
                                    </Col>
                                </Row>

                            </Col>

                        </Row>
                        <Row className="md20 mb-3">
                            <Col span={3}>
                                <Avatar size={40}></Avatar>
                            </Col>
                            <Col span={21} className='reels-cmnt-bg'>
                                <Row>
                                    <Col span={19}>
                                        <p className="fs14 fw500">Avishek Maskara</p>
                                        <p className="fs13 fw400 md5">I was just testing how does comment system work programatically?</p>
                                    </Col>
                                    <Col span={5} className='text-right'>
                                        <Space>
                                            <p className="fs12 fw300 text-grey">1 day ago</p>
                                            <DotsThreeVertical size={20} color="#3b3c3f" />
                                        </Space>
                                    </Col>
                                </Row>

                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Modal>


        </Row>


    );
}
export default ReelsViewCard;