import React, {useState } from "react";
import { useParams } from "react-router";
import RedirectComponent from "../redirectToOther";


const MentorshipLiveView = () => {
    const params = useParams();
    const [bookingId,] = useState(params['id']);

    return <RedirectComponent to={`m/${bookingId || params['id']}`}/>;
}
export default MentorshipLiveView;