import React, { useState } from "react";
import { Col, Row, Input, Tag, Space, Button, Divider, Modal, Alert, Skeleton, message } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import Toast from "../utils/Toast";

const Profile = ({ setting, loader }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'emailsend';


    const [otp, setOtp] = useState("");
    const showModal = () => {
        axios({
            method: 'get',
            url:  "account/auth/email",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
            setIsModalVisible(true);
        })
    };


    const verifyOTP = () => {
        if (otp === "") {
            Toast("Enter OTP Received over Email","error")
            return
        }
        axios({
            method: 'post',
            url:  "account/auth/email",
            data: {
                "otp": otp,
                "code": false
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
            setIsModalVisible(false);
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        })
    }


    const sendEmailVerification = () => {
        messageApi.open({
            key,
            type: 'loading',
            content: 'Sending...',
        });
        axios({
            method: 'get',
            url:  "account/auth/email/link",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            messageApi.open({
                key,
                type: 'success',
                content: res.data.message,
                duration: 2,
            });
        }).catch((error)=>{
            messageApi.open({
                key,
                type: 'error',
                content: error.response.data.message,
                duration: 2,
            });
        })
    }

    return (

        <Row style={{ padding: "40px 0px 30px 30px" }} className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18">Profile</p>
                <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 18 }}>
                        <p className="text-grey">Manage Account Information</p>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }} className="text-right">
                        <Button href="/profile-update" className="primary-light-btn" type="text">Edit Profile</Button>
                    </Col>
                </Row>

                <Divider></Divider>

            </Col>

            {
                loader ? <Skeleton></Skeleton> : <Col span={24}>
                    <p className="fw600">Username</p>
                    <p className="md5">@{setting?.username}</p>

                    <p className="fw600 md20">Display Name</p>
                    <p className="md5">{setting?.full_name}</p>

                    <p className="fw600 md20">Mobile Number</p>
                    <p className="md5">{setting?.mobile_number}</p>

                    <p className="fw600 md20">Gender</p>
                    <p className="md5">{setting?.gender === 1 ? "Male" : "Female"}</p>

                    <p className="fw600 md20">Email</p>
                    <Space aria-orientation="horizontal">
                        <p className="md5">{setting?.email}</p>
                        {setting?.is_email_verified ? <Tag className="verified-tag"> Verified </Tag> :
                            <> {contextHolder} <Tag className="not-verified-tag"> Not verified </Tag> <Button type="link" onClick={() => sendEmailVerification()}>Resend email</Button></>
                        }

                    </Space>
                    <p className="fw600 md20">Account type</p>
                    <p className="md5">{setting?.account_type}</p>
                    {/* <Row className="md10">
                        <Col xs={{ span: 24 }} lg={{ span: 12 }} className="primary-box">
                            <p className="fs20">{setting?.account_type}</p>
                            <p style={{ color: "#000000b0" }} className="text-grey md15">Best fit for brands, retail shops, service providers and organizations.</p>
                        </Col>
                    </Row> */}

                    {
                        setting?.account_type === "Creator" && <>
                            <Divider></Divider>

                            <p className="fw600 md20">Business Name</p>
                            <p className="md5">{setting?.business_name}</p>

                            <p className="fw600 md20">GST Reg. Number</p>
                            <p className="md5">{setting?.gst_reg_no ? setting?.gst_reg_no: "NA" }</p>

                            <p className="fw600 md20">Registered Address</p>
                            <p className="md5">{setting?.gst_address ? setting?.gst_address: "NA"}</p>

                            <p className="fw600 md20">State/UT</p>
                            <p className="md5">{setting?.gst_state ? setting?.gst_state: "NA"}</p>
                        </>
                    }



                </Col>
            }


            <Modal title="Verify Email" visible={isModalVisible} footer={false}>
                <Row style={{ padding: '30px' }}>
                    <Col span={24} >
                        <Alert message="A six digit verification code successfully sent" type="success" />
                        <p style={{ color: "#000000b0" }} className="sub-text-light md30">Enter One Time Password</p>
                        <Input className="md10" value={otp} onChange={(evt) => setOtp(evt.target.value)} placeholder="i.e. 123456"></Input>
                    </Col>
                    <Col span={24} className="md30 text-right">
                        <Button onClick={() => verifyOTP()} type="primary">Verify</Button>

                    </Col>
                </Row>

            </Modal>
        </Row>

    );
}
export default Profile;
