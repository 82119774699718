import React, { useEffect, useState } from "react";
import { Col, Row, Button, Divider, Alert, Radio } from "antd";
import axios from "../../../store/axios";
import Cookies from "js-cookie"
import { useHistory } from "react-router";
import Toast from "../../utils/Toast";
const ChangeRole = () => {
    const [role, setRole] = useState("")
    const history = useHistory()
    const [roles, setRoles] = useState([])
    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("profile"))
        setRole(data?.role)
    }, [localStorage.getItem("profile")])



    useEffect(() => {
        axios({
            method: 'get',
            url:  "account/master/roles",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            setRoles(res.data.data)
        }).catch((err) => {
            Toast(err.response.data.message, "error")
        })
    }, [])

    const UpdateProfile = async () => {

        await axios({
            method: 'put',
            url:  "account/user/profile",
            data: {
                account_type: role,
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
            // localStorage.removeItem("profile")
            Cookies.set("__role", role);
            // history.replace("/login")
            history.goBack();

        }).catch((err) => {
            Toast(err.response.data.message, "error")
        })
    }

    return (

        <Row style={{ padding: "40px 0px 30px 30px" }} className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18">Change Account Type</p>
                <Row>
                    <Col span={18}>
                        <p className="text-grey">Personal account information</p>
                    </Col>
                </Row>
                <Divider></Divider>
            </Col>

            <Col span={24} className="create-event-radio">
                <p style={{ color: "#484848" }} className="sub-text-light">Account type</p>
                <Radio.Group value={role} className="md10" onChange={(evt) => setRole(evt.target.value)}>

                    <Row direction="horizontal" className="md10">
                        {
                            roles.map((item, e) => {
                                return <Col span={12} key={e} className="md10"> <Radio value={item?.role_id} className='p-3' style={{minHeight:"100px"}}>
                                    <p className="fs14 bold">{item?.name}</p>
                                    <p className="text-grey md10">{item?.description}</p>
                                </Radio>
                                </Col>
                            })
                        }

                    </Row>
                </Radio.Group>

                <Alert className="md40" icon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1.80078C5.02266 1.80078 1.8 5.02344 1.8 9.00078C1.8 12.9781 5.02266 16.2008 9 16.2008C12.9773 16.2008 16.2 12.9781 16.2 9.00078C16.2 5.02344 12.9773 1.80078 9 1.80078ZM9.6 12.6008H8.4V8.40078H9.6V12.6008ZM9 6.90078C8.50313 6.90078 8.1 6.49766 8.1 6.00078C8.1 5.50391 8.50313 5.10078 9 5.10078C9.49688 5.10078 9.9 5.50391 9.9 6.00078C9.9 6.49766 9.49688 6.90078 9 6.90078Z" fill="#146EB4" />
                </svg>
                } message="Changing your username will also change your Syllo account address." type="info" showIcon />

            </Col>

            <Col span={24} className="md30 text-right">
                <Button type="primary" onClick={() => UpdateProfile()}>Save Changes</Button>

            </Col>
        </Row>

    );
}
export default ChangeRole;
