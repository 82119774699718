
import { Row, Col, Button, Space, Divider, Avatar, Skeleton, message, Tabs, Badge, Typography, Popover, Tag, Modal } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Cookies from "js-cookie";
import axios from "../../store/axios";
import ViewNotes from "./ViewNotes";
import { useHistory, withRouter } from "react-router";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import '../../assets/css/style.scss';
import { BookmarkSimple, Export, FileArrowDown, ArrowLeft, ChartBar } from "@phosphor-icons/react";
import { Chat } from "@phosphor-icons/react";
import { RWebShare } from "react-web-share";
import moment from "moment";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import PaymentCreditPage from "../utils/paymentCredit";

import Toast from "../utils/Toast";
import EntityAnalytics from "../FeedEntity/EntityAnalytics";
const { Paragraph } = Typography
var _ = require("lodash")




const content = (data) => (
  <Row style={{ width: "300px" }}>
    <Col span={24} className='notes-pop'>
      {
        data.map((item, index) => {
          return <Row style={{ alignItems: "center" }}>
            <Col span={18} >
              <p className="fs14 fw400">{(item?.name).substring(0, 20)} ...</p>
            </Col>
            <Col span={6} className='text-right'>
              <Space><Button type="text" href={item?.uri} target={"_blank"} icon={<FileArrowDown size={18} color="#000000b3" />}></Button>
                {/* <Button type="text" icon={<Export size={18} color="#000000b3" />}></Button> */}
              </Space>

            </Col>
            {
              index < data.length - 1 && <Divider style={{ margin: "8px 0" }}></Divider>
            }

          </Row>
        })
      }



    </Col>
  </Row>
);


const CreateNotesReader = (props) => {

  const [data, setdata] = useState([]);
  const params = useParams();
  const [loader, setloader] = useState(true);
  const [notes, setnotes] = useState(false);
  const [item, setItem] = useState(null);
  const [comments, setcomments] = useState([]);
  const [subcomments, subsetcomments] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [modal, setModal] = useState(false);

  const [popOverData, setPopOverData] = useState([]);
  const [creditModal, setCreditModal] = useState(false);
  const history = useHistory();

  let timer;


  useEffect(() => {
    let uuid = ""
    if (props.id) {
      uuid = props.id?.entity_uuid
    }
    else {
      uuid = params['uuid']
    }
    async function fetchData() {
      await props.NewsletterDetails({
        id: uuid,
      }).then(() => {
        showComment()
      })
    }
    fetchData();
    return () => {
      window.clearInterval(timer);
    };
  }, [])

  useEffect(() => {
    timer = window.setInterval(() => {
      setSelectedComment(null)
      clearInterval(timer)
    }, 5000);
  }, [selectedComment])




  useEffect(() => {
    if (props._newsletterdetails.length !== 0) {
      setdata(props._newsletterdetails)
    }
  }, [props, props._newsletterdetails])


  const showComment = () => {
    axios({
      method: 'get',
      url:  'market/feed/comment?id=' + params['uuid'],
      headers: {
        Authorization: Cookies.get("Logintoken"),

      },
    })
      .then((res) => {
        let _data = []
        if (res?.data?.data.length !== 0) {
          const a = res?.data?.data?.map((item) => {
            return _data.push({ ...item, show_reply: false })
          })
          setcomments(_data)
          setnotes(true)
        }
        setloader(false)
      }).catch((error) => {
        Toast(error?.response?.data?.message, "error")
      });
  }

  const showSubComment = (item) => {
    axios({
      method: 'get',
      url:  `market/feed/comment/${item?.uuid}/reply`,
      headers: {
        Authorization: Cookies.get("Logintoken"),
      },
    })
      .then((res) => {
        subsetcomments(res?.data?.data)

        if (res?.data?.data.length !== 0) {
          setcomments(comments =>
            comments.map(obj => {
              if (obj.uuid === item?.uuid) {
                return { ...obj, show_reply: true };
              }
              else {
                return { ...obj, show_reply: false };
              }
            }))
        }
      }).catch((error) => {
        Toast(error.response.data.message, "error")
      });
  }

  const scrooltoView = (item) => {
    const element = document.getElementById(item?.block_id);
    element.scrollIntoView();
    setSelectedComment(item)
  }

  const SaveFeed = (id, entity) => {
    setdata({ ...data, saved: true })
    props.SaveFeed({
      "id": id,
      "entity_type": entity,
      "type": "Post"
    }).then(() => {
      Toast('Saved Successfully !', "success");
    })
  }

  const deleteFeed = (id, entity) => {
    setdata({ ...data, saved: false })
    props.SaveFeed({
      "id": id,
      "entity_type": entity,
      "type": "Delete"
    });
  }


  const getPopConetent = () => {
    axios({
      method: 'get',
      url:  `market/feed/note/${params['uuid']}/likethis`,
      headers: {
        Authorization: Cookies.get("Logintoken")
      }
    }).then(res => {
      const data = res.data.data
      setPopOverData(data)
    })
  }

  let _popdata = (_.groupBy(popOverData, "tag"))

  const popcontent = (data) => (
    <Row >
      <Col span={24} className='md10'>
        <Tabs
          className='pop-tab'
          style={{ padding: '0px' }}
          tabPosition={"left"}
        >
          {
            _popdata && Object.keys(_popdata).map((item, index) => {
              return <Tabs.TabPane tab={item} key={index + 1} >
                <div style={{ height: "360px", overflowY: "scroll" }}>
                  {
                    Object.values(_popdata[item]).map((_index, i) => {
                      return <Col key={i} span={24} className="tag-people ps-2">
                        <a className="fs14 fw600" href={"/note/" + _index?.entity_uuid}>{_index?.title}</a>
                        <br />
                        <Space className="md5 ">
                          <Avatar size={24} src={_index?.avatar_url}></Avatar>
                          <p className="fs12">{_index?.name}</p>
                          <Badge status="default text-center"></Badge>
                          <p className="text-grey fs12 fw300 m-fs10 text-center">{moment(_index?.updated_at).format("Do MMM YYYY")}</p>
                        </Space>
                      </Col>
                    })
                  }
                </div>

              </Tabs.TabPane>
            })
          }
        </Tabs>
      </Col>
    </Row>
  );

  const onCreditUpdate = (res) => {
    setCreditModal(res.modal);
    if (res.status === "success") {
      // window.location.reload()
      props.NewsletterDetails({
        id: params['uuid']
      })
    }
  }



  return (
    <Row justify={"center"}>
      <Col xs={{ span: 24, offset: 0 }} lg={{ span: 22, offset: 1 }} >
        <Row>

          {
            loader ? <Col xs={{ span: 24, offset: 0 }} lg={{ span: 11, offset: 5 }} className='md50  radius10'>
              <Skeleton className='md20' avatar={{ shape: 'square' }} paragraph={{ rows: 2, }} />
              <Space>
                <Skeleton.Button shape='square' size={'small'} />
                <Skeleton.Avatar size={'small'} />
                <Skeleton.Input size={'small'} />
              </Space>
              <Skeleton className='md20' />
              <Skeleton className='md20' />
            </Col>
              :
              <>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 11, offset: 5 }} className='md50 m-md20 radius10'>
                  <Button type="link" shape="circle" className="fs-16" style={{ color: "#504e4e", fontWeight: "400" }} onClick={() => props.history.goBack()} icon={<ArrowLeft size={20} color="#030303" weight="light" style={{ marginRight: "5px" }} />} > Back</Button>

                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 11, offset: 5 }} className='md5 m-md20 radius10'
                  style={{ minHeight: "70vh", overflow: "auto", backgroundColor: data?.editor_info?.bg, borderRadius: data?.editor_info?.radius, padding: "20px" }} >
                  <Row >

                    <Col md={18} xs={24}>
                      <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 24 }} className='md20 m-pl15 '>
                          <Space className="notes-input" size={20}>
                            <Emoji emojiStyle={EmojiStyle.GOOGLE} unified={data?.editor_info?.emoji} size={40}></Emoji>
                            <Paragraph className="fs25 fw500" ellipsis={{ rows: 2, expandable: false }}>
                              <p className="fs25 fw500">{data?.title}</p>
                            </Paragraph>

                          </Space>

                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={24} className="md20 text-right">
                      <Row>
                        <Col span={16} >
                          <p className=" fs15 fw400 m-0 cursor" onClick={() => history.push(`/profile/${data?.creator?.user_code}`)}>{data?.creator?.full_name}</p>
                          <p className="fs11 text-grey" >{moment(data?.updated_at).format("Do MMM YYYY")}</p>

                        </Col>
                        <Col span={8} className="note-align" style={{ paddingLeft: "10px" }}>
                          <Avatar style={{ border: "none" }} size={30} src={data?.creator?.avatar_url}></Avatar>

                        </Col>
                      </Row>


                    </Col>


                    <Col span={17}>
                      {
                        data?.meta_title && <div className="note-div md15">
                          <Paragraph className="fs13" ellipsis={{ rows: 3, expandable: true }}>
                            {data?.meta_title}
                          </Paragraph>
                        </div>
                      }

                    </Col>
                    <Col span={7} className='text-right md5'>
                      <Space className="md10 m-mx0 ">
                        <Button type="text" shape="circle" className="radius50" onClick={() => data?.saved ? deleteFeed(data?.entity_id, data?.entity_type) : SaveFeed(data?.entity_id, data?.entity_type)}>
                          <BookmarkSimple size={22} weight={data?.saved ? "fill" : "thin"} />
                        </Button>



                        <RWebShare data={{
                          text: data?.meta_title,
                          url: data?.image_url,
                          title: data?.title,
                        }}>
                          <Button type="text" shape="circle" className="radius50">
                            <Export size={22} weight="thin" />
                          </Button>
                        </RWebShare>
                      </Space>
                    </Col>

                    <Col span={19} className={data?.editor_info?.bg ? "md10 note-tag-bg" : "md10"}>
                      {
                        data?.tags.map((item, k) => {
                          return <Tag key={k} style={{ borderRadius: "30px !important", marginTop: "5px" }}>{item}</Tag>
                        })
                      }
                    </Col>
                    <Col span={5} className={"md10 text-right"}>
                      {
                        data?.tags.length !== 0 && <Popover placement="bottom" onClick={() => getPopConetent()} overlayClassName="more_tags note-tag-card" content={popcontent()} trigger="click">
                          <Button type="link" className={'text-blue'}>more</Button>
                        </Popover>
                      }
                    </Col>
                    {
                      data?.isme && 
                      <Col span={24}>
                      <Divider></Divider>
                      <Button onClick={() => setModal(true)} type="text" style={{ color: "#4B5669" }}>
                        <Space>
                          <ChartBar size={24} color="#4B5669" />
                          <span style={{ color: "#4B5669" }}>View Analytics</span>
                        </Space>
                      </Button>

                    </Col>
                    }
                    


                    {
                      data?.attachments.length !== 0 && <Col span={24} className={"md10"}> <Popover overlayClassName="more_tags" placement="bottomRight" content={() => content(data?.attachments)} trigger="click">
                        <span className={"cursor md10 blue-text"}>{data?.attachments.length} Attachments</span>
                      </Popover> </Col>
                    }



                    <Col span={24} className="text-center ">
                      <Divider></Divider>
                      {/* <Space>
                        <Badge color="rgb(45, 183, 245)" />
                        <Badge color="rgb(45, 183, 245)" />
                        <Badge color="rgb(45, 183, 245)" />
                        <Badge color="rgb(45, 183, 245)" />
                        <Badge color="rgb(45, 183, 245)" />
                      </Space> */}
                    </Col>
                    {
                      !data.isme && !data.unlocked && data.price > 0 ?
                        <Col span={24} className="md10 text-center">
                          This is a paid content <br />
                          <Button className="md20" type="primary" onClick={() => setCreditModal(true)}>Unlock Now</Button>
                        </Col>
                        :
                        <Col span={24} className={"no-display"}>

                          {
                            data?.editor_info.blocks.map((item) => {
                              return <Row className={"md10 content"} id={item?.id}>
                                <Col span={2} className={"showHide"}>
                                  <Button type="text" onClick={() => {
                                    setItem(item)
                                    setnotes(true)
                                  }} icon={<Chat size={20} weight="thin" />}></Button>

                                </Col>
                                <Col span={22} id={"removeClass"} className={selectedComment?.block_id === item?.id ? "yellow-bg" : ""}>
                                  <p className={"md5"} style={{ wordBreak: "break-all" }}> {
                                    item?.data?.text
                                  }</p>
                                </Col>
                              </Row>
                            })
                          }
                        </Col>
                    }

                  </Row>
                </Col>
              </>
          }

          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 0 }} className='px-4 m-px0 ' style={{ overflow: "auto", marginTop: "165px" }}>
            {
              notes && !loader ? <ViewNotes showSubComment={(item) => showSubComment(item)} comments={comments} showComment={() => showComment()} setSelectedComment={(item) => scrooltoView(item)} item={item} uuid={params['uuid']} setItem={(item) => setItem(item)} setnotes={() => setnotes()} subcomments={subcomments} /> : ""
            }
          </Col>
        </Row>


        {/* <Button onClick={()=>}>dfsdghjavkb,ds uvk</Button> */}

        <Modal centered open={creditModal} onCancel={() => setCreditModal(false)} footer={false} >
          <PaymentCreditPage onChange={(val) => onCreditUpdate(val)}
            title={data?.title} entity={"Note"}
            amount={data?.price}
            entity_type="NOTES"
            id={data?.entity_uuid}
          ></PaymentCreditPage>
        </Modal>

        <Modal title={"Post Analytics"} open={modal} footer={false} onCancel={() => setModal(false)} maskClosable={false}>
          <Row>
            <Col span={22} offset={1}>
              <EntityAnalytics item={data} />
            </Col>
          </Row>

        </Modal>
      </Col >
    </Row >
  );

}





const mapStateToProps = (state) => ({
  _newsletterdetails: state.newsletter.newsletterdetail,
});
const mapDispatchToProps = (dispatch) => ({
  NewsletterDetails: (v) => dispatch(actions.NewsletterDetails(v)),
  SaveFeed: (v) => dispatch(actions.SaveFeed(v))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateNotesReader)
);