import { Col, Row, Tag, Space, Badge } from "antd";
import React from "react";
import moment from "moment"
import EventTrigger from "../utils/EventTrigger";

const LiveOnline = ({ data }) => {
    return (


        <Col span={24} className='md15'>
            <a href={"/event/" + data?.schedule_uid}><div className='yujaevent-card'>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 6 }} className='bg-event-img'
                        style={{ backgroundImage: `url(${data?.img_url})`, backgroundPosition: "center", backgroundSize: "cover" }}   >

                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 18 }} className='padding20'>
                        <Row>
                            <Col span={20} >
                                <p className="fs12 fw500 text-grey ">{(data?.category).toUpperCase()} </p>
                            </Col>
                            <Col span={4} className='bg-pink live-color text-center '>
                                <p> <Badge color={"pink"} /> LIVE</p>
                            </Col>

                            <Col span={24} className='md5'>
                                <p className="fs20 fw600 m-0 lh24">{data?.name}</p>
                            </Col>
                            <Col span={24} className='md10'>
                                <p className="fs12 fw400 m-0">{moment(data?.schedule_date_from).format("Do MMM | h:mm a")} - {moment(data?.schedule_date_to).format("Do MMM YY | h:mm A")} </p>
                                <p className="fs12 fw400 m-0">{data?.is_paid ? "Paid offering  |" : ""}  {data?.venue}</p>

                                <p className="fs12 fw500 md15 ">
                                    <Space><Tag className="online-bg" > {(data?.medium).toUpperCase()} </Tag>
                                        <EventTrigger data={data}></EventTrigger>
                                    </Space></p>
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </div></a>
        </Col>


    );
}
export default LiveOnline;
