import { handleActions } from "redux-actions";

const INITIAL_STATE = {
    corporate_results: [],
    news_feed:[],
    indices:[],
    related:[],
    eventCreated: null
}
const CorporateResults = handleActions({
    CORPORATE_RESULTS: (state, action) => {
        return { ...state, corporate_results: action.payload };
    },
    NEWS_FEED: (state, action) => {
        return { ...state, news_feed: action.payload };
    },
    INDICES: (state, action) => {
        return { ...state, indices: action.payload };
    },
    RELATED_NEWS_FEED: (state, action) => {
        return { ...state, related: action.payload };
    },
    EVENT_CREATED: (state, action) => {
        return { ...state, eventCreated: action.payload };
    },
}, INITIAL_STATE);

export default CorporateResults;
