import React from 'react';
import {Pie} from '@ant-design/charts';
import moment from 'moment';

const Page = ({result,type}) => {
  const data = result.map((item)=>{
      return {
        month:moment(item?.created_at).format("MMMM"),value:item?.count
      }
  });

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 10,
      },
    },
    
  };
  
    return <Pie height={200} {...config} />;
  
  
};
export default Page;