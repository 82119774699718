import React from "react";
import { Col, Row, Button, Radio, Skeleton, } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import MentorshipCard from '../Mentorship/MentorshipCard'
import MentorshipMembers from './MentorshipMembers'
import MentorshipCollaborate from './MentorshipCollaborate'

import { useHistory } from "react-router";
import BlankState from "../BlankState";
import MentorshipMyBookingCard from "./MentorshipMyBookingCard";


const MyHubMentorship = (props) => {
    const role = parseInt(Cookies.get("__role"));
    const [loader, setloader] = useState(true);
    const [data, setData] = useState([])
    const [offset, setOffset] = useState(0)
    const [radioValue, setRadioValue] = useState(role!==7?0:4);
    const [user_code, setUserCode] = useState("");

    const history = useHistory();

    useEffect(() => {
        setUserCode(props?._user_id);
    }, [props])

    useEffect(() => {
        MentorshipAPI();
    }, [])

    async function MentorshipAPI() {
        setloader(true) 
        await axios({
            method: 'get',
            url:  `v1.0/mentorship?user_code=${props?._user_id}&count=10&offset=${offset}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setData(data)
            setloader(false);
        });
        setOffset(offset + 10)
    }

    const ChangeFilter = (e) => {
        setRadioValue(e.target.value)
    }



    return (
        <Row>
            {
                (user_code === undefined || user_code === null) &&
                <>
                    <Col xs={24} lg={19} md={19} xl={19} className='md10 overflow-scroll-x'>
                        {
                            role !== 7 &&
                            <Radio.Group onChange={(e) => ChangeFilter(e)}
                                defaultValue={0} value={radioValue} style={{ display: "inline-flex", marginBottom: "21px" }}
                                buttonStyle="solid" className={"m-mb10"}>
                                <Radio.Button
                                    value={0}
                                    className="margin-right"
                                    style={{ width: "max-content" }} key={1}>

                                    All
                                </Radio.Button>
                                <Radio.Button
                                    value={2}
                                    className="margin-right"
                                    style={{ width: "max-content" }} key={3}>
                                    Members  </Radio.Button>
                                <Radio.Button
                                    value={3}
                                    className="margin-right"
                                    style={{ width: "max-content" }} key={4}>
                                    Collaboration </Radio.Button>
                                <Radio.Button
                                    value={4}
                                    className="margin-right"
                                    style={{ width: "max-content" }} key={5} >
                                    My Bookings  </Radio.Button>

                            </Radio.Group>
                        }
                    </Col>
                    <Col xs={24} lg={5} md={5} xl={5} className='md10 text-right m-md0 m-mb10'>
                        <Button className="primary-light-btn" onClick={() => history.push("/mentorship-new")} type="text"> Create New </Button>
                    </Col></>
            }

            {
                loader ? <><Skeleton active></Skeleton><Skeleton active></Skeleton></> :

                    radioValue === 2 ? <MentorshipMembers />
                        : radioValue === 3 ? <MentorshipCollaborate />
                            : radioValue === 4 ? <div className="w-100" id="2"><MentorshipMyBookingCard /></div>
                                : radioValue === 0 ? <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} >
                                    {
                                        data.map((item, i) => {
                                            return <MentorshipCard key={i} {...item} menu={true} />
                                        })

                                    }
                                    {
                                        data.length === 0 && <div className="md60">
                                            <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={250} />
                                        </div>
                                    }

                                </Col>
                                    : <></>
            }

        </Row>
    );
}
export default MyHubMentorship;