import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { message, Dropdown, Input, Radio, Tabs, Checkbox, Badge, Image, Skeleton, Button } from "antd"
import Cookies from "js-cookie"
import { Fragment } from "react";
import { X,ArrowRight } from "@phosphor-icons/react";

import Toast from "../utils/Toast";
const { TabPane } = Tabs

var _ = require('lodash');


class UserPreferences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topics: [], active: "Stock", stocksList: [], search: false, stocks: [], sectors: [], selectedtopics: [], loader: true
        };
    }

    async componentDidMount() {
        await this.props.Sectors({ tag: "" }).then(() => {
            this.setState({
                topics: this.props._topics
            })
        })
        await this.props.UserSelectedPreferences().then(() => {
            this.setState({
                stocks: this.props?._user_selected["stock"],
                sectors: this.props?._user_selected["sector"],
                selectedtopics: this.props?._user_selected["topic"],
                loader: false
            })
        })
    }

    async search(key, type) {
        if (type === "Sector") {
            await this.props.listofSectors({ value: key }).then(() => {
                this.setState({
                    stocksList: this.props._stcoks,
                    search: true
                });
            });
            return
        }
        if (type === "tag") {
            await this.props.Sectors({ tag: key }).then(() => {
                this.setState({
                    topics: this.props._topics
                })
            })
            return
        }
        await this.props.listofStocks({ value: key }).then(() => {
            this.setState({
                stocksList: this.props._stcoks,
                search: true
            });
        });
    }


    addTopics(evt, type) {
        const { stocks, sectors } = this.state
        if (type === "Stock") {
            if (stocks.indexOf(evt) === -1) {
                stocks.push(evt)
            }
            else {
                Toast("Already Selected","error")
            }
        }
        else {
            if (sectors.indexOf(evt) === -1) {
                sectors.push(evt)
            }
            else {
                Toast("Already Selected","error")
            }
        }
        this.setState({ stocks: stocks, sectors: sectors, search: false })
    }


    remove(evt, type, index) {
        const { stocks, sectors, selectedtopics } = this.state
        if (type === "Stock") {
            stocks.splice(index, 1)
        }
        if (type === "Topics") {
            selectedtopics.splice(index, 1)
        }
        else {
            sectors.splice(index, 1)
        }
        this.setState({ stocks: stocks, sectors: sectors, search: false })
    }

    setPreference(evt) {
        evt.preventDefault()

        // if (this.state.stocks.length === 0) {
        //     Toast("Select atleast one stock")
        //     return
        // }
        // else if (this.state.sectors.length === 0) {
        //     Toast("Select atleast one sector !")
        //     return
        // }
        // else {
        this.props.UserPreferences({
            sector: this.state.sectors,
            stock: this.state.stocks,
            topic: this.state.selectedtopics
        }).then(() => {
            this.props.history.push("/dashboard")
        })
        // }

    }

    logout() {
        Cookies.remove("Logintoken");
        Cookies.remove("CSRFToken");
        Cookies.remove("channels");
        Cookies.remove("user_pic");
        Cookies.remove("user_code");
        Cookies.remove("user_name");
        Cookies.remove("_role");
        localStorage.removeItem("profile");
        localStorage.removeItem("channels");
        window.location.href = "/login";
        window.location.href = "/login";
    }

    render() {

        const menu = (
            <div className="row nomargin">
                <div className="col-md-12 search-dropdown">
                    <div className="row">
                        <div className="col-md-12 md10">
                            {
                                this.state.stocksList.map((item) => {
                                    return <div className="row border-top cursor" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                        <div className={this.state.active === "Stock"?"col-md-7 col-xsm-6 md5":"col-md-12 md5"} >
                                            <p className="smaller-text" onClick={() => this.addTopics(item.name, this.state.active)}>{item.name}</p>
                                            <small>{item.exchange}</small>
                                        </div>

                                        {
                                            this.state.active === "Stock" ? <div className="col-md-5 col-xsm-6">
                                                <div className="row">
                                                    <div className="col-md-6 col-xsm-6 text-center">
                                                        <p className="sub-text md10"> ₹ {item.current_price}</p>
                                                    </div>
                                                    <div className="col-md-6 col-xsm-6 text-right">
                                                        {
                                                            item.onedayreturn <= 0 ? <p className="sub-text text-danger md10">  {item.onedayreturn} %</p> : <p className="sub-text text-success md10">  {item.onedayreturn} %</p>
                                                        }

                                                    </div>
                                                </div>
                                            </div> : ""
                                        }

                                    </div>
                                })
                            }

                        </div >
                    </div>
                </div>

            </div >
        );

        return (
            <div className="row ">
                <div className="col-md-12  grey-background">
                    <div className="row">
                        <div className="col-md-10 offset-md-1 col-xsm-10 offset-1">
                            <p className="heading-text text-center md10"> User Preferences   </p>
                        </div>
                    </div>

                </div>
                <div className="col-md-10 offset-md-1   md50">





                    {
                        this.state.loader === true ? <div className="col-md-7 offset-md-3 "> <div className="row"> <Skeleton active></Skeleton></div> </div> : <div className="row md20">
                            <div className="col-md-10 offset-md-1 text-center" onMouseLeave={() => this.setState({ search: false })}>
                                <Tabs defaultActiveKey="1" centered >

                                    <TabPane tab="Topics" key="1">
                                        <div className="row text-left">
                                            <div className="col-md-8 offset-md-2" style={{ height: "350px", overflowY: "scroll" }}>



                                                <Input className={"border-bottom-border"} placeholder={"Search Topics"} onFocus={() => this.setState({ search: true })} onChange={(evt) => this.search(evt.target.value, "tag")}></Input>


                                                <Checkbox.Group buttonStyle="solid" className="topics-checkbox" style={{ width: "100%" }} value={this.state.selectedtopics} onChange={(evt) => this.setState({ selectedtopics: (evt) })}>
                                                    {
                                                        this.state.topics.map((item) => {
                                                            return <Checkbox value={item.tag} className="md10">#{item.tag}</Checkbox>
                                                        })
                                                    }
                                                </Checkbox.Group>

                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Markets" key="2">
                                        <div className="row">
                                            <div className="col-md-8 offset-md-2 text-center">
                                                <Radio.Group defaultValue={this.state.active} buttonStyle="outline" onChange={(evt) => this.setState({ active: evt.target.value, stocksList: [] })}>
                                                    <Radio.Button value="Stock">Stock</Radio.Button>
                                                    <Radio.Button value="Sector">Sector</Radio.Button>
                                                    <Radio.Button value="c" disabled>Crypto Currency</Radio.Button>
                                                </Radio.Group>
                                            </div>
                                            <div className="col-md-8 offset-md-2 m-p12 " style={{ height: "350px", overflowY: "scroll" }}>
                                                <Dropdown overlayClassName="search-dropdown-width" overlay={menu} visible={this.state.search} placement="bottomLeft" >
                                                    <Input className={"border-bottom-border"} placeholder={"Search" + " " + this.state.active} onFocus={() => this.setState({ search: true })} onChange={(evt) => this.search(evt.target.value, this.state.active)}></Input>
                                                </Dropdown>

                                                {
                                                    (this.state.sectors.length === 0 && this.state.stocks.length === 0) ? <Image preview={false} src={require("../../assets/images/login/no-data.png")} className="img-fluid md20"></Image> : ""
                                                }


                                            </div>
                                        </div>
                                    </TabPane>

                                </Tabs>
                                <div className="row">
                                    {

                                        this.state.loader === true ? <Skeleton active></Skeleton> :

                                            this.state.stocks.length === 0 && this.state.sectors.length === 0 && this.state.selectedtopics.length === 0 ? <div className="col-md-12 md20">

                                            </div> : <Fragment>

                                                <div className="col-md-12 md30">
                                                    <hr></hr>
                                                </div>
                                                <div className="col-md-2 col-xsm-12 m-align-explore">
                                                    <p className="smaller-heading-text md10">Selections:</p>
                                                </div>
                                                <div className="col-md-10 col-xsm-12  text-center">
                                                    <p className="sub-text">
                                                        {
                                                            this.state.stocks.map((item, index) => {
                                                                return <Badge className="margin-right selected-badge"> {item}
                                                                    <span style={{ width: "20px", display: "inherit" }}> <X className="fa fa-times cursor hoverme" weight="thin" style={{ marginLeft: "10px", width: "20px" }} onClick={() => this.remove(item, "Stock", index)}></X></span> </Badge>
                                                            })
                                                        }

                                                        {
                                                            this.state.sectors.map((item, index) => {
                                                                return <Badge className="margin-right selected-badge"> {item} <span style={{ width: "20px", display: "inherit" }}> <X className="fa fa-times cursor hoverme" weight="thin" onClick={() => this.remove(item, "Sector", index)}></X></span> </Badge>
                                                            })
                                                        }

                                                        {
                                                            this.state.selectedtopics.map((item, index) => {
                                                                return <Badge className="margin-right selected-badge"> {item} <span style={{ width: "20px", display: "inherit" }}> <X className="fa fa-times cursor hoverme" weight="thin" onClick={() => this.remove(item, "Topics", index)}></X></span> </Badge>
                                                            })
                                                        }

                                                    </p>
                                                </div>
                                            </Fragment>
                                    }

                                    <div className="col-md-12 col-xsm-12 md50 text-right  ">
                                        <Button type="primary" className="m-margin-right" style={{ float: "right" }} onClick={(evt) => this.setPreference(evt)}>Next <ArrowRight weight="regular" className="ms-2"></ArrowRight> </Button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="col-md-12 height100px"></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    _topics: state.publics.sectors,
    _stcoks: state.publics.stocks,
    _user_selected: state.publics.user_selected
});
const mapDispatchToProps = (dispatch) => ({
    Sectors: (v) => dispatch(actions.Sectors(v)),
    listofStocks: (v) => dispatch(actions.listofStocks(v)),
    listofSectors: (v) => dispatch(actions.listofSectors(v)),
    UserPreferences: (v) => dispatch(actions.UserPreferences(v)),
    UserSelectedPreferences: (v) => dispatch(actions.UserSelectedPreferences(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPreferences));
