import React, { useEffect, useState } from "react";
import { CaretRightOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Divider, Dropdown, Avatar, Row, Space, Modal, Input, Skeleton } from "antd";
import { DotsThreeVertical, PencilSimple, Smiley, Television, Trash, Export, Plus } from "@phosphor-icons/react";
import Cookies from "js-cookie";
import { useParams, useHistory } from "react-router-dom";
import IconsList from "../utils/Icons";
import IconsView from "../utils/IconsView"

import Toast from "../utils/Toast";
import axiosInstance from "../../store/axios";

const { Panel } = Collapse


const CreatorTVPlayList = () => {
    const [loading, setLoading] = useState(true);
    const [iconslist, setIconList] = useState(false);
    const [icon, setIcon] = useState(null);
    const [plalist, setPlaylist] = useState(false);
    const [data, setData] = useState([]);
    const [playlistLoader, setPlaylistLoader] = useState(false);
    const [text, setText] = useState(null);
    const [update, setUpdate] = useState(false);
    const [playlistId, setPlaylistId] = useState(null)

    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        TvData();
    }, [])
    function TvData() {
        setLoading(true)
        axiosInstance({
            method: 'get',
            url: `v1.0/tv/playlist/get?user_code=${params["user_code"]}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data

            setData(data)
            setLoading(false)
        })

    }

    function CreateTvData() {
        setUpdate(false)
        setPlaylistLoader(true)
        axiosInstance({
            method: update ? 'put' : 'post',
            url: "v1.0/tv/playlist",
            data: {
                title: text,
                icon: icon,
                playlist_id: playlistId
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            Toast("Playlist added!", "success");
            setPlaylistLoader(false)
            TvData()
            setPlaylist(false)
            setText(null)
            setIcon(null)
        })

    }

    const items_video = [

        {
            label: (
                <span className="fs12 fw400"> Share</span>
            ),
            key: '1',
            icon: <Export size={18} color="#000000" weight="thin" />
        },
        {
            label: (
                <span className="fs12 fw400"> Delete video</span>
            ),
            key: '2',
            icon: <Trash size={18} color="#000000" weight="thin" />
        }

    ];


    const videoMenuClick = ({ key, id, _id }) => {
        if (key === '2') {
            axiosInstance({
                method: 'delete',
                url: "v1.0/tv/playlist/" + id + "/episode?epd_id=" + _id,
                headers: {
                    Authorization: Cookies.get("Logintoken")
                }
            }).then(() => {
                Toast("Deleted Successfully", "success")
                TvData()
            })
        }
    };

    const items_playlist = [

        {
            label: (
                <span className="fs12 fw400"> Add video</span>
            ),
            key: '3',
            icon: <Plus size={18} color="#000000" weight="thin" />
        }, {
            label: (
                <span className="fs12 fw400"> Rename</span>
            ),
            key: '1',
            icon: <PencilSimple size={18} color="#000000" weight="thin" />
        },
        {
            label: (
                <span className="fs12 fw400"> Update icon</span>
            ),
            key: '2',
            icon: <Smiley size={18} color="#000000" weight="thin" />
        },

        {
            label: (
                <span className="fs12 fw400"> Delete playlist</span>
            ),
            key: '4',
            icon: <Trash size={18} color="#000000" weight="thin" />
        }

    ];


    const playlistMenuClick = ({ key, item }) => {
        if (key === '2' || key === '1') {
            setUpdate(true)
            setText(item?.title)
            setIcon(item?.icon)
            setPlaylistId(item?.playlist_id)
            setPlaylist(true)

        }
        else if (key === '4') {
            axiosInstance({
                method: 'delete',
                url: "v1.0/tv/playlist?playlist_id=" + item?.playlist_id,
                headers: {
                    Authorization: Cookies.get("Logintoken")
                }
            }).then(() => {
                Toast("Deleted Successfully", "success")
                TvData()
            })
        }
        else if (key === '3') {
            // setIsModalVisible(true)
            // setPlaylistId(id)
            history.push(`/tv/${item?.playlist_id}/add`);
        }

    };

    const genExtra = (item) => (

        <Dropdown trigger={['click']} menu={{ items: items_playlist, onClick: ({ key }) => playlistMenuClick({ key: key, item: item }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "130px" }}>
            <Button type="text" icon={<MenuOutlined />}
                onClick={(event) => {
                    event.stopPropagation();
                }}>
            </Button>
        </Dropdown>

    );

    const panelStyle = {
        marginBottom: 12,
        background: "#fafafa",
        borderRadius: "8px",
        border: 'none',
    };


    return (
        <Row>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 3 }} sm={{ span: 24, offset: 0 }} className='mb-4' >
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }} className="text-center md70">
                        <Space  >
                            {
                                loading ? <Skeleton.Input active size={"small"} /> :
                                    <><Television size={25} weight="light" color="#000000" />
                                        <span className="fs24 fw800" style={{ color: data?.creator.color }}> {data?.creator.name} </span>
                                        <span className="fs24 fw800 blue-text"> TV </span></>
                            }


                        </Space>
                    </Col>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 16, offset: 4 }} className='TV-card md20 m-p15 m-p0'>
                        <Row className='md20'>
                            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 12, offset: 0 }}>
                                <p className="fs16 fw600"> Your Library </p>
                            </Col>
                            <Col xs={{ span: 10, offset: 0 }} md={{ span: 10, offset: 0 }} lg={{ span: 10, offset: 1 }} className='text-right'>
                                {
                                    data?.isme && <Button className="blue-text playlist" onClick={() => setPlaylist(true)}>Create Playlist</Button>
                                }

                            </Col>
                            <Col span={24} className='md20'>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>

                                {
                                    loading ? <Skeleton></Skeleton> :

                                        <Collapse
                                            bordered={false}
                                            defaultActiveKey={[]}
                                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            className="site-collapse-custom-collapse md15" style={{ backgroundColor: "white" }}
                                        >
                                            {
                                                data && data?.library.map((item, i) => {
                                                    return <Panel header={<p className="fs14 fw500" key={i}><IconsView icon={item.icon} weight={'light'} /> {item?.title}</p>}
                                                        className="panel-align-items-center" style={panelStyle} extra={data?.isme ? genExtra(item) : <></>}>

                                                        {
                                                            item.items.map((_item, l) => {
                                                                return <Row key={l}>
                                                                    <Col xs={{ span: 7 }} lg={{ span: 6 }} xl={{ span: 4 }} xxl={{ span: 3 }}>
                                                                        <Avatar src={_item?.image_url} size={70} style={{ borderRadius: '4px' }} />
                                                                    </Col>
                                                                    <Col xs={{ span: 13 }} lg={{ span: 16 }} xl={{ span: 17 }} xxl={{ span: 19 }}>
                                                                        <p className="fs14 md10">{_item?.title}</p>
                                                                        <p className="fs12 fw300 text-grey md5">{_item?.ep_type}</p>

                                                                    </Col>
                                                                    <Col xs={{ span: 4 }} lg={{ span: 2 }} xl={{ span: 3 }} xxl={{ span: 2 }} className='text-right'>
                                                                        {
                                                                            data?.isme &&


                                                                            <Dropdown trigger={['click']} menu={{ items: items_video, onClick: ({ key, }) => videoMenuClick({ key: key, id: item?.playlist_id, _id: _item?.epd_id }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                                                <Button type="text" shape="circle" onClick={(e) => e.preventDefault()}
                                                                                    icon={<DotsThreeVertical size={17} color="black" weight="bold" />}></Button>
                                                                            </Dropdown>
                                                                        }

                                                                    </Col>
                                                                    <Col span={24} >
                                                                        <Divider className="my-3" />
                                                                    </Col>
                                                                </Row>
                                                            })
                                                        }




                                                    </Panel>
                                                })
                                            }
                                        </Collapse>
                                }

                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Col >
            <Modal centered title={update ? "Update Playlist" : "Create Playlist"} open={plalist} onCancel={() => (setPlaylist(false),
                setUpdate(false))} width={550} footer={false} className="playlist-modal">
                <Row className=" padding20 md10 mb-5">
                    <Col xs={{ span: 16, offset: 0 }} lg={{ span: 20, offset: 0 }} sm={{ span: 17, offset: 0 }} >
                        <p className="fs14 fw600">Playlist Title</p>
                        <Space className=" smile-padding w160  md10">

                            <Button onClick={() => setIconList(true)} className="md5" style={{ height: "40px" }}> {
                                icon ? <IconsView icon={icon} weight={'light'} /> : <Smiley size={20} color="#000000" weight="light" />
                            } </Button>
                            <Input placeholder="Enter title" value={text} onChange={(evt) => setText(evt.target.value)}
                                type="text" size="large" className="md5 w160 "></Input>

                        </Space>
                    </Col>
                    <Col xs={{ span: 8, offset: 0 }} lg={{ span: 4, offset: 0 }} sm={{ span: 7, offset: 0 }} className='text-right' >
                        <Button type="primary" className="ms-1" loading={playlistLoader} onClick={() => CreateTvData()}>{update ? "Update" : "Create"}</Button>
                        <Button type={"text"} onClick={() => (setPlaylist(false), setUpdate(false))} className='md10 playlist blue-text'>Cancel</Button>

                    </Col>
                </Row>

            </Modal>


            <Modal centered title="Select icon" open={iconslist} onCancel={() => setIconList(false)} width={400} footer={false} >
                <Row className=" padding20 md10 mb-4">
                    <Col span={24} >

                        <IconsList setIcon={setIcon} closeModal={setIconList} />

                    </Col>
                    {/* <Col span={4} >
                        <Button type="primary" onClick={() => CreateTvData()}> Create</Button>
                        <Button type={"text"} className='md10 playlist blue-text'>Cancel</Button>

                    </Col> */}
                </Row>

            </Modal>
        </Row >
    );
}
export default CreatorTVPlayList;