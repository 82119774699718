
import React from "react";
import { Col, Row } from "antd";
import moment from "moment";

const MessageTextCard = ({ item, userCode }) => {

    return <Row className="md20">
       
        <Col span={24} >
            {
                item[4] === userCode ?
                    <div className="Text-message-right text-right messanger-width  chat-message-me ">
                        <p className="sub-heading-text word-break">{item[3]}  <small className="grey-text fs11" style={{float:"right",marginTop:"10px",marginLeft:"10px"}}>
                            {" "}
                            {moment(item[5]).format("h:mm a")}
                        </small></p>

                        {/* {item[12] !== null && <p> <span className="cursor margin-right  tags-round cursor">{"#" + item[12]}</span></p>} */}

                    </div>
                    :
                    <div className="Text-message-left messanger-width chat-message">
                        <h5 style={{ color: item[11], fontSize: "16px", fontWeight: "500" }}>{item[2]}</h5>
                        <p className="sub-heading-text word-break">{item[3]}    
                        <small className="grey-text fs11" style={{float:"right",marginLeft:"10px",marginTop:"10px"}}>
                            {" "}
                            {moment(item[5]).format("h:mm a")}
                        </small>
                        </p>
                        {/* {item[12] !== null && <p> <span className="cursor margin-right  tags-round cursor">{"#" + item[12]}</span></p>} */}
                       
                    </div>
            }
        </Col>
    </Row>
}

export default MessageTextCard;