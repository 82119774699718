import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Space, message, Modal, Upload, Input, Avatar } from "antd";
import { ArrowLeft, UploadSimple, FolderLock, LinkSimple } from "@phosphor-icons/react";
import React, { useState } from "react";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { useParams, useHistory } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';

import Toast from "../utils/Toast";


const CreatorTV = () => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [title, setTitle] = useState(null);
    const [uid, setUID] = useState(null);
    const [url, setUrl] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleSaved, setIsModalSaved] = useState(false);
    const [isVault, setIsVault] = useState(false);
    const [shoHideVaultSave, setShoHideVaultSave] = useState(false);
    const [imageFile, setImageFile] = useState([]);
    const [ytVideos, setYoutubeVideos] = useState([]);
    const params = useParams();

    const playlistId = params['playlist_id'];
    const history = useHistory();

    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    const handleCancel = () => {
        setIsModalVisible(false);
        setTitle(null)
        setUrl(null)
        setImageFile([])
        setImageUrl(null)
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const propsBanner = {
        accept: ".png,.jpg,.JPEG",
        onRemove: file => {
            const index = imageUrl.indexOf(file);
            const newFileList = imageFile.slice();
            newFileList.splice(index, 1);
            setImageUrl("");
        },
        beforeUpload: file => {
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
               Toast("You can only upload JPG/PNG file!","error");
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
               Toast("Image must smaller than 2MB!","error");
                return;
            }
            getBase64(file, (url) => {
                setImageUrl(url);
            })
            setImageFile([...imageFile, file]);
            return false;
        },
        imageFile,
    };


    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <UploadSimple size={22} color="#666666" weight="light" />}

        </div>
    );

    const createPlaylist = () => {
        if (title === null || title === "") {
           Toast("Enter title","error")
            return
        }
        if (url === null || url === "") {
           Toast("Enter url of video","error")
            return
        }
        if (imageFile.length === 0) {
           Toast("Upload title image!","error")
            return
        }
        const formData = new FormData();
        imageFile.forEach(file => {
            formData.append('file', file);
            formData.append('title', title);
            formData.append('url', url);
        });
        setLoading(true)

        axios({
            method: 'post',
            url:  `v1.0/tv/playlist/${playlistId}/episode`,
            data: formData,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            }
        }).then(() => {
            // TvData()
            setLoading(false)
            Toast("Added Successfully","success")
            handleCancel()
            history.goBack()
        })
    }

    const handleLogin = async (credentialResponse) => {
        setYoutubeVideos([])
        messageApi.open({
            key,
            type: 'loading',
            content: 'Fetching...',
        });

        const config = {
            method: 'GET',
            url:  `lighthouse/auth/google/yt?code=${credentialResponse?.access_token}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }

        await axios(config).then((res) => {
            console.log(res.data.data)
            const _data = res.data.data
            messageApi.open({
                key,
                type: 'success',
                content: 'Loaded!',
                duration: 2,
            });
            setYoutubeVideos(_data.items)
            setUID(_data.uid)
            setIsVault(false)
            setShoHideVaultSave(true)
            setIsModalSaved(true)
        }).catch((error) => {
            messageApi.open({
                key,
                type: 'error',
                content: error.response.data.message,
            });
        })
    }
    const login = useGoogleLogin({
        onSuccess: handleLogin,
        flow: "implicit",
        ux_mode: "popup",
        // select_account: true,
        scope: "https://www.googleapis.com/auth/youtube.readonly",
        redirect_uri: "http://localhost:3000/tv/2e5ab270-11b8-490b-a732-42b39acdb1d2/add"
    });

    const addVideo = async (item, i) => {
        if (uid === null && !isVault) {
            return
        }

        messageApi.open({
            key,
            type: 'loading',
            content: 'Saving...',
        });

        const data = {
            playlist_id: playlistId,
            uid: uid,
            _id: isVault ? item.epd_id : item?._id,
            is_vault: isVault
        }
        const config = {
            method: 'POST',
            url:  `v1.0/tv/saved`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            },
            data: data
        }

        await axios(config).then((res) => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Saved!',
                duration: 2,
            });

            let videos = [...ytVideos]
            videos[i].saved = "saved"
            setYoutubeVideos(videos)

        }).catch((error) => {
            messageApi.open({
                key,
                type: 'error',
                content: error.response.data.message,
            });
        })
    }

    const SaveAllInVault = async () => {
        if (uid === null) {
            return
        }

        messageApi.open({
            key,
            type: 'loading',
            content: 'Saving...',
        });

        const data = {
            playlist_id: playlistId,
            uid: uid,
        }
        const config = {
            method: 'POST',
            url:  `v1.0/tv/vault`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            },
            data: data
        }

        await axios(config).then((res) => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Saved!',
                duration: 2,
            });
            setShoHideVaultSave(false)
        }).catch((error) => {
            messageApi.open({
                key,
                type: 'error',
                content: error.response.data.message,
            });
        })

        setUID(null)
    }


    const getVaultVideos = async () => {
        setYoutubeVideos([])

        messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
        });

        const config = {
            method: 'GET',
            url:  `v1.0/tv/saved?count=20`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            },
        }

        await axios(config).then((res) => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Loaded!',
                duration: 2,
            });

            setYoutubeVideos(res.data.data)
            setIsVault(true)
            setIsModalSaved(true)
            setShoHideVaultSave(false)

        }).catch((error) => {
            messageApi.open({
                key,
                type: 'error',
                content: error.response.data.message,
            });
        })
    }


    return (

        <Row>
            <Col lg={{ span: 17, offset: 4 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 14, offset: 5 }} className='mb-5'>
                <Row>
                    {/* <Col span={24} className="text-center ">
                        <Space  >
                            <Television size={25} weight="light" color="#000000" />
                            <span className="fs24 fw800"> Revere </span>
                            <span className="fs24 fw800 blue-text"> TV </span>

                        </Space>
                    </Col> */}
                    <Col lg={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 22, offset: 1 }} className="md70">
                        <Space className="cursor" onClick={() => history.goBack()} >
                            <ArrowLeft size={20} color="#000000" weight="light" style={{ webkitBoxAlign: 'baseline' }} />
                            <p className="fs14 fw400"> Back </p>
                        </Space>
                    </Col>
                    <Col lg={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 22, offset: 1 }} className='TV-card md20 mb-4'>
                        <Row>
                            <Col lg={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 24, offset: 0 }} className="text-center">
                                <p className="fs16 fw400"> Add video from </p>
                                <p className="fs12 fw300 md15"> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a docum </p>

                            </Col>
                            <Col lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }} className='10'>
                                <Divider />
                            </Col>


                        </Row>
                        <Row gutter={24} className='md30'>
                            <Col lg={{ span: 12, offset: 0 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 12, offset: 0 }}>
                                <div className="TV-video-card text-center cursor " onClick={() => getVaultVideos()}>
                                    <Space className='md15'>
                                        <FolderLock size={22} color="#000009" weight="light" />
                                        <p className="fs16 fw400 cursor">Vault </p>
                                    </Space>
                                </div>
                            </Col>
                            <Col lg={{ span: 12, offset: 0 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 12, offset: 0 }} className="">
                                <div className="TV-video-card text-center cursor m-md10 m-md20 m-top0 m-mt20" onClick={() => setIsModalVisible(true)}>
                                    <Space className='md15 '>
                                        <LinkSimple size={22} color="#000009" weight="light" />
                                        <p className="fs16 fw400 m-fs14"> Custom Link </p>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={24} className='md10 mb-5'>
                            <Col lg={{ span: 12, offset: 0 }} xs={{ span: 24, offset: 0 }} xxl={{ span: 12, offset: 0 }}>
                                {contextHolder}
                                <div className="TV-video-card text-center cursor m-md11 m-top0 m-top10" onClick={() => login()}>
                                    <img src={require('../../assets/images/TvImages/youtube.png')} className='md10' alt="" />
                                </div>
                            </Col>
                            <Col lg={12}  xs={{ span: 24, offset: 0 }}  className=" m-md20 text-center">
                                <p className="md50"> coming soon... </p>
                                
                            </Col>
                        </Row>

                    </Col>
                </Row>

            </Col>
            <Modal centered key={"modal1"} title="Add Video custom link" open={isModalVisible} onCancel={handleCancel} width={550} footer={false} >
                <Row className=" padding20 md10 mb-3">
                    <Col span={24} >
                        <p className="fs14 fw600"> Title</p>
                        <Input placeholder="Enter title" type="text" value={title} onChange={(evt) => setTitle(evt.target.value)} size="large" className="md10"></Input>
                    </Col>
                    <Col span={24} className='md20' >
                        <p className="fs14 fw600"> Video URL</p>
                        <Input placeholder="Past here video link" value={url} onChange={(evt) => setUrl(evt.target.value)} type="text" size="large" className="md10"></Input>
                    </Col>
                    <Col span={24} className='md25' >
                        <Row>
                            <Col span={4} className='tv-upload'>
                                <p className="fs14 fw600">Title image</p>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader md10 "
                                    showUploadList={false}
                                    {...propsBanner}
                                >
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={24} className='text-right' >
                        <Space>
                            <Button type={"ghost"} onClick={() => setIsModalVisible(false)} className='me-3'>Cancel</Button>
                            <Button type="primary" loading={loading} onClick={() => createPlaylist()}> Add</Button>
                        </Space>
                    </Col>
                </Row>

            </Modal>

            <Modal centered key={"modal2"} title="Add Video" open={isModalVisibleSaved} onCancel={() => setIsModalSaved(false)} width={550} footer={false} >
                <Row className="padding20 mb-3 m-p10" style={{ height: "85vh", overflowY: "scroll" }}>
                    <Col span={24} className='text-center' >
                        <p className="fs12 fw300 "> The data listed below fetched from YouTube platform. We’re not responsible for any
                            kind of misconception in this data
                        </p>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }} className="md20">
                        {
                            ytVideos.map((item, i) => {
                                return <Row key={i}>
                                    {/* <Col xs={{span:5}} lg={{span:5}}>
                                        <Avatar src={item?.image_url} size={80} style={{ borderRadius: '4px' }} />
                                    </Col> */}
                                    <Col xs={{ span: 19 }} lg={{ span: 18 }}>
                                        <Row>
                                            <Col xs={{ span: 9 }} lg={{ span: 6 }}>
                                                <Avatar src={item?.image_url} size={75} style={{ borderRadius: '4px' }} />
                                            </Col>
                                            <Col xs={{ span: 15 }} lg={{ span: 18 }}>

                                                <p className="fs14 fw600  ">{item?.title}</p>

                                                {/* <p className="fs12 fw300">2 min</p> */}
                                                <img src={require('../../assets/images/TvImages/button.png')} alt="" style={{ borderRadius: '4px' }} />
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col xs={{ span: 5 }} lg={{ span: 6 }} className='text-right'>
                                        {
                                            item?.saved !== "saved" && <Button className="blue-text playlist  " onClick={() => addVideo(item, i)}>Add</Button>
                                        }


                                    </Col>
                                    <Col span={24} >
                                        <Divider />
                                    </Col>
                                </Row>
                            })
                        }

                    </Col>
                    {
                        shoHideVaultSave && ytVideos.length > 0 &&

                        <Col span={24} className="text-right">
                            <Button type="primary" onClick={() => SaveAllInVault()}>Save data in vault</Button>
                        </Col>
                    }


                </Row>

            </Modal>

        </Row>
    );
}
export default CreatorTV;