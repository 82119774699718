import React, { useEffect, useState } from 'react';
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
// import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";

import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
// import { HashtagNode } from '@lexical/hashtag';

import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import {HashtagPlugin} from "./plugins/HashtagPlugin";
import './style.scss'
import { MentionNode } from './nodes/MentionNode';
import NewMentionsPlugin from './plugins/MentionsPlugin';
import { HashtagNode } from './nodes/HashtagNode';

function Placeholder() {
  return <div className="editor-placeholder">NA</div>;
}

export default function PostViewer(props) {

  const _editorConfig = {
    // The editor theme
    namespace: 'sylloEditor',
    theme: ExampleTheme,
    onError(error) {
      // throw error;
    },
    nodes: [
      HashtagNode,
      AutoLinkNode,
      LinkNode,
      MentionNode
    ],
    // editorState: '{"root":{"children":[{"children":[{"detail":1,"format":0,"mode":"segmented","style":"","text":"abhay_braja","type":"mention","version":1,"mentionName":"abhay_braja"},{"detail":0,"format":0,"mode":"normal","style":"","text":" and ","type":"text","version":1},{"detail":1,"format":0,"mode":"segmented","style":"","text":"mukesh","type":"mention","version":1,"mentionName":"mukesh"},{"detail":0,"format":0,"mode":"normal","style":"","text":" are developers.","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}',
    editorState: props?.intState !== ''? props?.intState: null,
    editable: false
  };
  const [editorConfig, setConf] = useState(_editorConfig)
 
  useEffect(()=>{
    if (props?.intState != ""){
      const c = {...editorConfig,  editorState: props?.intState}
      setConf(c);
    }
  },[props?.intState]);

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={props.className + ""}>
        <div className="editor-inner" style={{color:"#4b5669"}}>
          <RichTextPlugin
            contentEditable={<ContentEditable className="tor-input post-editor" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HashtagPlugin />
          <NewMentionsPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
        </div>
      </div>
    </LexicalComposer>
  );
}
