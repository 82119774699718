import React, { useState, useEffect } from "react";
import { Row, Col, Radio, Button, Avatar, Space, Skeleton } from "antd";
import Carousel from 'better-react-carousel'
import { Hash, MonitorPlay, PuzzlePiece } from "@phosphor-icons/react";
import EventsCount from "./EventsCount";
import RewardActivity from "./RewardActivity";
import axios from "../../store/axios";
import useToken from '../../store/useToken';
import { useHistory } from "react-router-dom";




const NewEvent = () => {
  const [radioVal, setRadioValue] = useState(1);
  const [counts, setCounts] = useState({});
  const [creators, setCreators] = useState([]);
  const [creatorsLoading, setCreatorsLoading] = useState(true);
  const [countLoading, setCountLoading] = useState(true);
  const { token } = useToken();
  const history = useHistory();

  useEffect(() => {
    if (token) { // if login else do not hit API
      getCounts();
      // getCreators();
    }
  }, []);

  const getCounts = async () => {
    axios({
      method: 'get',
      url:  `event/upcoming_count`,
      headers: {
        Authorization: token
      }
    }).then(res => {
      setCounts(res.data.data);
      setCountLoading(false)
    })
  }
  const getCreators = async () => {
    axios({
      method: 'get',
      url:  `market/feed/recommended/creators?count=6&offset=0`,
      headers: {
        Authorization: token
      }
    }).then(res => {
      setCreators(res.data.data);
      setCreatorsLoading(false)
    })
  }

  return (<>   {token ?
    <Row style={{ padding: "12px" }}>
      {/* {
        creatorsLoading || creators.length > 0 &&
        <>
          <Col span={24} className=''>
            <p className="fs14 fw600 md35"> Recommended Creators </p>
          </Col>
          <Col span={24} className="trending-card md15">
            <div className='md20'>
              {
                creatorsLoading ? <>
                  <Space align="center" size={50}>
                    <div className="text-center">
                      <Skeleton.Avatar size={50} active /> <br />
                      <Skeleton.Button active className="md5" />
                    </div>
                    <div className="text-center">
                      <Skeleton.Avatar size={50} active /><br />
                      <Skeleton.Button active className="md5" />
                    </div>
                    <div className="text-center">
                      <Skeleton.Avatar size={50} active /><br />
                      <Skeleton.Button active className="md5" />
                    </div>
                  </Space>
                </> :
                  <Carousel cols={4} rows={1} gap={20} loop className="carousel-btn">
                    {
                      creators && creators?.map((item, i) => {
                        return <Carousel.Item key={i} >
                          <div className="text-center cursor" onClick={() => history.push(`/profile/${item?.user_code}`)}>
                            <Avatar size={50} src={item?.img_url} />
                            <p className="fs13 fw300 md10">{(item?.fname).substring(0, 10)} .. </p>
                            {
                              item?.username && <p className="fs11 fw300 " style={{ color: '#4B5669' }}>@{(item?.username)}</p>
                            }

                          </div>
                        </Carousel.Item>
                      })
                    }

                  </Carousel>
              }

            </div>
          </Col>

        </>

      } */}
      <p className="fs14 fw600 md30"> Updates You’d Like </p>
      <Col span={24} className="trending-card md15">
        <Row>
          <Col span={24}>

            <Radio.Group className="md10" defaultValue={radioVal} onChange={(evt) => setRadioValue(evt.target.value)}>

              <Radio.Button value={1}>Events</Radio.Button>
              <Radio.Button value={0}>
                Rewards
              </Radio.Button>
              {/* <Radio.Button value="c" className="md5">Campus</Radio.Button> */}
            </Radio.Group>
          </Col>
          <Col span={24} className='md20'>
            {
              radioVal === 0 ? <RewardActivity loading={countLoading} /> : <EventsCount loading={countLoading} counts={counts} />
            }

          </Col>
        </Row>

      </Col>

      {/* <Col span={24} className='dashboard-radio radio'>
        <Radio.Group className={"yuja-tag"} defaultValue={activeResulttab} onChange={(evt) => TabChangeResult(evt.target.value)}>
          <Radio.Button value={1}> Financial Market</Radio.Button>
          <Radio.Button value={2} className='ms-4'>Syllo Events</Radio.Button>
        </Radio.Group>
      </Col> */}


      {/* <Col span={24} style={{ overflowY: "scroll", overflowX: "hidden", height: "calc(100vh - 210px)" }}>
        <Row >

          {resultLoader ? <Skeleton></Skeleton> :
            topResult.map((item, i) => {
              return <Col key={i} xs={{ span: 24 }} md={{ span: 21 }} className='event-card md10'>
                <Row>
                  <Col span={24}>

                    <Tag color="blue" className="fs10">{item?.category} </Tag>
                    <p className="fs11 fw300 float-right">  {moment(item?.schedule_date_from).format("Do MMM YYYY")} </p>

                  </Col>

                  <Col span={24} className=''>


                    {item?.schedule_type === 2 ? <a href={"event/" + item?.schedule_uid} className="fs13 fw400 md10"> {item?.name}</a> : <p className="fs13 fw400 md10"> {item?.name}</p>}




                  </Col>
                </Row>
              </Col>
            })
          }

        </Row>

      </Col> */}




    </Row>
    : <Row style={{ padding: "12px" }}>
      <Space direction="vertical" size={8} >
        <Button type="link" href="/explore" className="dashboard-link" icon={<Hash size={20} weight="bold" />}> Explore</Button>
        <Button type="link" href="/explore/tv" className="dashboard-link" icon={<MonitorPlay size={20} weight="bold" />}> Syllo TV</Button>
        <Button type="link" href="https://games.syllo.co/" className="dashboard-link" icon={<PuzzlePiece size={20} weight="bold" />}> Games</Button>
      </Space>
      <Col span={24} className="trending-card md30">
        <p style={{ color: "#728096" }}>Sign in to follow profiles or hashtags, favourite,
          share and reply to posts. You can also interact from your account on a different server.</p>
        <Button href="/login" className="md30" type="primary" shape="round" block> Sign in</Button>
        <Button href="/sign-up" className="md15 mb-2" type="default" shape="round" block> Create account</Button>
      </Col>
    </Row>}</>
  );
}

export default NewEvent;
