
import { Col, Divider, Input, Row, Select, Tag, Button, Skeleton, Space, Avatar,  Alert, Popconfirm } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState, useEffect } from "react";
import useToken from "../../store/useToken"
import axios from "../../store/axios";
import moment from "moment";
import { PlugsConnected } from "@phosphor-icons/react";

import Toast from "../utils/Toast";
const { Option } = Select;


const Connection = () => {
    const [name, setname] = useState('');
    const [empId, setempId] = useState('');
    const [empEmail, setempEmail] = useState('');
    const [remark, setremark] = useState('');
    const [data, setData] = useState({});
    const [orgs, setOrgs] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loader2, setLoader2] = useState(false);
    const { token } = useToken();

    const [value, setValue] = useState([]);

    useEffect(() => {
        getConnection();
    }, [])

    const getConnection = async () => {
        await axios({
            method: 'get',
            url:  "account/business/connection/req",
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setData(data)
        });
        setLoader(false)
    }

    const createConnection = async () => {
        if (name === "" || name === " ") {
            return Toast('Enter employee name!',"error")
        }
        if (value === "" || value === " ") {
            return Toast('Search business name!',"error")
        }
        if (empId === "" || empId === " ") {
            return Toast('Enter employee id!',"error")
        }
        const _data = {
            "name": name,
            "org_code": value,
            "emp_id": empId,
            "remark": remark,
            "emp_email": empEmail
        }
        setLoader2(true);

        await axios({
            method: 'post',
            url:  "account/business/connection/req",
            headers: {
                Authorization: token
            },
            data: _data
        }).then(res => {
            getConnection();
        });
        setLoader2(false)
    }

    const searchOrganization = async (key) => {
        if (key.length <= 2) {
            return
        }
        await axios({
            method: 'get',
            url:  `account/business/search?key=${key}&apply_allow_conn=false`,
            headers: {
                Authorization: token
            },
        }).then(res => {
            const data = res.data.data
            setOrgs(data)
            // var _d = []
            // for (let i = 0; i < data.length; i++) {
            //     _d.push({
            //         label: data[i].name,
            //         value: data[i].username,
            //     })
            // }
            // return _d
        });
    }

    const handleSearch = (newValue) => {
        searchOrganization(newValue)
    };
    const handleChange = (newValue) => {

        setValue(newValue);
    };

    const deleteReq = async () => {
        await axios({
            method: 'delete',
            url:  "account/business/connection/req",
            headers: {
                Authorization: token
            }
        }).then(res => {
            getConnection();
            setname('');
            setempId("");
            setempEmail('');
            setremark('');
            setValue('');
        });
        setLoader(false)
    }


    const editReq = () => {
        setname(data.emp_name);
        setempId(data.emp_id);
        setempEmail(data.emp_email);
        setremark(data.remark);
        setValue(data.organization.username);
        setData({ ...data, status: null });
    };

    return (
        <Row style={{ padding: "40px 0px 0px 30px" }} className="m-nopadding">
            <Col span={24} >
                <p className="m-0 fs23 fw600">Connection</p>
                <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 18 }}>
                        <p className="text-grey fs12 fw300"> Manage your Business Connection </p>
                    </Col>
                </Row>
                <Divider />
            </Col>
            {
                loader ? <Col span={24} ><Skeleton active /><Skeleton active /></Col> :

                    data.status === null ? <Col span={24} className="md10">


                        <Row>
                            <Col span={24}>
                                <p className="fs14 fw400"> Business </p>
                            </Col>
                            <Col span={24} className="md10 connection">
                                <Select
                                    showSearch
                                    value={value}
                                    style={{ width: '100%' }}
                                    className="search-in-select"
                                    placeholder='Select Company/Business'
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    onChange={handleChange}
                                    notFoundContent={null}
                                >
                                    {(orgs || []).map((d) => {
                                        return <Option value={d.username} label={d.name}>
                                            <Space>
                                                <Avatar size={"small"} src={d.logo}></Avatar>
                                                <div style={{ lineHeight: "16px" }}>
                                                    <p>{d.name}</p>
                                                    <p className="fs12 text-grey">{d.username}</p>
                                                </div>
                                            </Space>
                                        </Option>
                                    })}

                                </Select>
                            </Col>
                            <Col span={24} className="md25">
                                <p className="fs14 fw400"> Registered Employee Name </p>
                            </Col>
                            <Col span={24} className="md10 connection">
                                <Input value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
                            </Col>
                            <Col span={24} className="md25">
                                <p className="fs14 fw400"> Employee ID </p>
                            </Col>
                            <Col span={24} className="md10 connection">
                                <Input value={empId} onChange={(e) => setempId(e.target.value)} placeholder="Enter your Employee ID" />
                            </Col>
                            <Col span={24} className="md25">
                                <p className="fs14 fw400"> Work Email ID </p>
                            </Col>
                            <Col span={24} className="md10 ">
                                <Input value={empEmail} onChange={(e) => setempEmail(e.target.value)}
                                    placeholder="Enter professional email"
                                />
                            </Col>

                            <Col span={24} className="md25">
                                <p className="fs14 fw400"> Remarks  </p>
                            </Col>
                            <Col span={24} className="md10 connt-area ">
                                <TextArea value={remark} onChange={(e) => setremark(e.target.value)}
                                    rows={3} placeholder="Add Remarks" maxLength={250} showCount style={{ borderRadius: '16px' }} />
                            </Col>
                            <Col span={24} className="md25">
                                <p className="fs14 fw400"> Note  </p>
                                <p className="fs14 fw400 md10" style={{ color: '#728096' }}>Kindly Add all the details correctly. <br />
                                    Users are only allowed to request to a certain company <span className="fw-bold">3 times</span>.</p>
                            </Col>
                            <Col span={24} className="text-center md40 mb-5">
                                <Button loading={loader2} type="primary"
                                    onClick={() => createConnection()} className="fs14 fw300"> SUBMIT </Button>
                            </Col>
                        </Row>
                    </Col> :

                        <>


                            <Col md={20} xs={20} className="md10">
                                <p className="fs14 fw600">Business Name</p>
                                <p className="fs14 fw500 md10"> {data.organization.name} <a href={`/business/${data.organization?.username}`} className="fs12 text-grey">( @{data.organization?.username} )</a> </p>
                            </Col>
                            <Col md={4} xs={4} className="md10 text-right">
                                <Avatar shape="square" src={data.organization.logo} size={60}></Avatar>
                            </Col>
                            <Col span={24} className="md20">
                                <p className="fs14 fw600">Employee Name</p>
                            </Col>
                            <Col span={24} className="md10">
                                <p className="fs14 fw500"> {data.emp_name}</p>
                            </Col>
                            <Col span={24} className="md20">
                                <p className="fs14 fw600">Employee ID</p>
                            </Col>
                            <Col span={24} className="md10">
                                <p className="fs14 fw500">{data.emp_id}</p>
                            </Col>
                            {
                                data.email && <>
                                    <Col span={24} className="md20">
                                        <p className="fs14 fw600">Work Email ID</p>
                                    </Col>
                                    <Col lg={12} md={12} xs={{span:24}} className="md10">
                                        <Space>
                                            <p className="fs14 fw500"> {data.email}</p>
                                            {
                                                !data.email_verified && <Tag color="pink">Not verified</Tag>
                                            }

                                        </Space>
                                    </Col>
                                    {
                                        !data.email_verified && <Col lg={12} md={12} xs={{span:5}} className="m-align-explore text-right md5">
                                            <Button type="link" className="m-fs12" >Verify your email</Button>
                                        </Col>
                                    }

                                </>
                            }


                            <Col span={24} className="md20">
                                <p className="fs14 fw600">Remarks</p>
                            </Col>
                            <Col span={24} className="md10">
                                <p className="fs14 fw400"> {data.remark}</p>
                            </Col>
                            <Col span={24} className="md20">
                                <p className="fs14 fw600">Status</p>
                            </Col>
                            <Col span={24} className="md10">
                                <p className="fs14 fw400"> {data.status_str} <span className="fs12 text-grey">(Last updated at {moment(data.updated_at).format('DD-MM-YYYY')})</span> </p>
                            </Col>
                            {
                                data.status === 2 && <Col span={24} className="md20">
                                    <Alert type="warning" showIcon message={<>Approval Pending from <a href={`/company/${data.organization.username}`}><strong>{data.organization.name}</strong></a></>}></Alert>
                                </Col>
                            }
                            {
                                data.status === 3 && <Col span={24} className="md20">
                                    <Alert type="error" showIcon message={<>Approval Rejected</>}></Alert>
                                </Col>
                            }
                            <Col span={24} className="md40 mb-5 text-center">
                                {
                                    (data.status === 2 || data.status === 3) && <Space>
                                        <Button type="primary" onClick={() => editReq()}>Edit Request</Button>
                                        <Button type="dashed" onClick={() => deleteReq()}> Delete Request </Button>
                                    </Space>
                                }
                                {
                                    data.status === 1 &&
                                    <Popconfirm
                                        onConfirm={() => deleteReq()}
                                        okText="Remove" cancelText="Cancel"
                                        title={<span>Are you sure to remove connection with {data.organization.name}?
                                         <br /> This action cannot be undo!</span>}
                                        icon={<PlugsConnected size={28} color="red" weight="fill" />}
                                    >
                                        <Button type="" danger onClick={(e) => e.preventDefault()}>
                                            Remove Connection
                                        </Button>
                                    </Popconfirm>

                                }
                            </Col>
                        </>



            }


        </Row>
    );
}

export default Connection;