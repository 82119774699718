import { Avatar, Col, Row} from "antd";
import React from "react";
import FollowShare from "../utils/FollowShare";


const ExploreBusiness = ({data}) => {
    return (
        <Row gutter={30} className="m-padding20">
            {
                data && data.map((item, k)=>{
                    return <Col xs={24} md={8} key={k} className="md30">
                    <div className="expl-business-card ">
                        
                        <img className="bn" src={item.banner_url?item.banner_url: require('../../assets/images/pref-bg.svg').default} alt="" />
                        <div className="card-b">
                            <Avatar style={{ border: "none",marginTop: "-45px" }} src={item.logo} size={80} ></Avatar>
                            <a href={`/business/${item.username}`}>  <p className="fs20 fw600">{item.name}</p> </a>
                            <p className="fs12 text-grey">@{item.username}</p>
                            <Row className="md20">
                                <Col span={8}>
                                    <p className="fs12 text-grey md5">{item.followers} Followers</p>
                                </Col>
                                <Col span={16} className="text-right">
                                  <Row>
                                  <FollowShare  profile={data}></FollowShare>
                                  </Row>
                                </Col>
                            </Row>
                        </div>
                       
    
                    </div>
                </Col>
                })
            }
        </Row>
    );
}
export default ExploreBusiness;