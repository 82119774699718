import { createAction } from "redux-actions";
import axios from "../axios";
import Cookies from "js-cookie"


function writeCookie (cname, cvalue, days) {
    var dt, expires,host;
    dt = new Date();

    if(window.location.host.includes(".syllo.co")){
        host = ".syllo.co"
    }
    else{
        host = window.location.host.split(":")[0]
    }
    dt.setTime(dt.getTime()+(days*24*60*60*1000));
    expires = "; expires="+dt.toGMTString();
    document.cookie = cname+"="+cvalue+expires+'; domain='+host;
    }


const REGISTER = createAction("REGISTER");
export const Register = (values) => dispatch => {
    return axios({
        method: 'post',
        url: 'account/auth/signup',
        data: values
      }).then(res => {
            dispatch(REGISTER(res.data.data));
            writeCookie("Logintoken",res.data.data.token,1)
            writeCookie("CSRFToken",res.data.csrftoken,1)
            sessionStorage.setItem("active",res.data.data.is_email_verified)
            Cookies.set("__role",res.data.data.role)
        })
};
