import { Info, UserPlus, XCircle } from "@phosphor-icons/react";
import { Avatar, Col, Row, Space, Layout, Button, Tag, Menu, Modal, Input, Radio, Select } from "antd";
import React, { useState } from "react";


const { Sider } = Layout

const KidsPremium = () => {
    const [menuKey, setMenuKey] = useState('1')



    const menu = () => {
        return <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[menuKey]}
            onSelect={(info) => setMenuKey(info.key)}
            items={[{
                key: "1",
                label: `Recommended`,
            }, {
                key: "2",
                label: `Following`,
            }, {
                key: "3",
                label: `Topics`,
            },


            ]
            }
            style={{ backgroundColor: "#ffffff", minHeight: "72vh" }}
        />
    }

    return (

        <Row>
            <Col span={24}>
                <Row gutter={32} className="md20">
                    <Col span={7}>
                        <div>
                            <Layout className="live-class-layout kids-campus-layout">
                                <Sider
                                    breakpoint="lg"
                                    collapsedWidth="0"
                                    onBreakpoint={(broken) => {
                                        console.log(broken);
                                    }}
                                    onCollapse={(collapsed, type) => {
                                        console.log(collapsed, type);
                                    }}
                                    className="md20"
                                >
                                    {menu()}
                                </Sider>
                               
                            </Layout>

                        </div>
                    </Col>

                    <Col span={17}>
                        <Row className="budgeting-card">
                            <Col span={16} >
                                <Space>
                                    <Avatar size={30} />
                                    <p className="fs14 fw500" style={{ color: '#4B5669' }}> Chris Evan </p>
                                </Space>
                            </Col>
                            <Col span={8} className="text-right">

                                <Space>
                                    <p className="fs14 fw500" style={{ color: '#4B5669' }}> Axis Bank </p>
                                    <img src={require("../../../assets/images/Campusimgae/axis.png")} alt="" height={40} />
                                </Space>
                            </Col>

                            <Col span={24}>
                                <p className="fs16 fw600"> Kids’ Infinity Stone </p>
                            </Col>
                            <Col span={24} className="md20">
                                <Tag> Budgeting </Tag>
                                <Tag> Stock Market </Tag>
                                <Tag> G20 Summit </Tag>
                            </Col>
                        </Row>
                        <Row className="budgeting-card md20">
                            <Col span={16} >
                                <Space>
                                    <Avatar size={30} />
                                    <p className="fs14 fw500" style={{ color: '#4B5669' }}> Chris Evan </p>
                                </Space>
                            </Col>
                            <Col span={8} className="text-right">

                                <Space>
                                    <p className="fs14 fw500" style={{ color: '#4B5669' }}> Smallcase </p>
                                    <img src={require("../../../assets/images/login.jpg")} alt="" height={35} />
                                </Space>
                            </Col>

                            <Col span={24}>
                                <p className="fs16 fw600"> Kids’ Infinity Stone </p>
                            </Col>
                            <Col span={24} className="md20">
                                <Tag> Budgeting </Tag>
                                <Tag> Stock Market </Tag>
                                <Tag> G20 Summit </Tag>
                            </Col>
                        </Row>
                        <Row className="budgeting-card md20">
                            <Col span={16} >
                                <Space>
                                    <Avatar size={30} />
                                    <p className="fs14 fw500" style={{ color: '#4B5669' }}> Chris Evan </p>
                                </Space>
                            </Col>
                            <Col span={8} className="text-right">

                                <Space>
                                    <p className="fs14 fw500" style={{ color: '#4B5669' }}> Axis Bank </p>
                                    <img src={require("../../../assets/images/Campusimgae/axis.png")} alt="" height={40} />
                                </Space>
                            </Col>

                            <Col span={24}>
                                <p className="fs16 fw600"> Kids’ Infinity Stone </p>
                            </Col>
                            <Col span={24} className="md20">
                                <Tag> Budgeting </Tag>
                                <Tag> Stock Market </Tag>
                                <Tag> G20 Summit </Tag>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="text-center md40">
                                <Button type="primary"> Load more </Button>
                            </Col>
                        </Row>
                    </Col>

                </Row >

            </Col>
            <Col span={24} >
               
                {/* <Modal title="Edit benefits" visible={editbenefits} onOk={editOk} onCancel={editCancel} width={550} footer={false} >
                    <Row className="nomargin padding20">
                        <Col span={22} offset={1} >
                            <Row>
                                <Col span={24}>
                                    <p className="fs16 fw400"> Title </p>
                                    <Input size="small" placeholder="Tagline" className=" explo-input md10" />

                                </Col>
                                <Col span={24} className="md20">
                                    <p className="fs16 fw400"> Category </p>
                                    <div className="membership-select md10">
                                        <Select style={{ width: "100%", }} className="" placeholder="Select benefit category">
                                            <Option value="">Select benefit category</Option>
                                            <Option > LiveClass </Option>
                                        </Select>
                                    </div>

                                </Col>
                                <Col span={24} className="md20">
                                    <Radio.Group
                                        defaultValue={0} style={{ display: "inline-flex", marginBottom: "21px" }}
                                        buttonStyle="solid" className={"m-mb10"}>
                                        <Radio.Button
                                            value={0}
                                            className="margin-right"
                                            style={{ width: "max-content" }} key={1}>

                                            All
                                        </Radio.Button>
                                        <Radio.Button
                                            value={2}
                                            className="margin-right"
                                            style={{ width: "max-content" }} key={3}>
                                            Custom  </Radio.Button>
                                        <Radio.Button
                                            value={3}
                                            className="margin-right"
                                            style={{ width: "max-content" }} key={4}>
                                            20 </Radio.Button>


                                    </Radio.Group>
                                </Col>
                                <Col span={24} className='membership-input md25 text-center'>
                                    <a href="" className="fs12 fw400" style={{ color: '#146EB4', textDecoration: 'underline' }}> Make sure this benefit meets guidelines</a>
                                </Col>
                                <Col span={24} className='text-center md20 mb-3'>
                                    <Space>
                                        <Button type={"dashed"}>Cancel</Button>
                                        <Button type="primary" > Save</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Modal> */}
              
            </Col>
        </Row>
    );
}
export default KidsPremium;