import React, { useState,useEffect} from "react";
import { Col, Row, Input, Button, Divider, Checkbox } from "antd";
import Cookies from "js-cookie"
import Toast from "../../utils/Toast";
import axios from "../../../store/axios";
const BusinessProfile = () => {

    const [buisnessname, setBuisnessName] = useState("")
    const [gst_reg_no, setgst_reg_no] = useState("")
    const [gst_state, setgst_state] = useState("")
    const [gst_address, setgst_address] = useState("")



    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("profile"))
        setBuisnessName(data?.business_name)
        setgst_reg_no(data?.gst_reg_no)
        setgst_state(data?.gst_state)
        setgst_address(data?.gst_address)
    }, [localStorage.getItem("profile")])

    const UpdateProfile = () => {
        
        axios({
            method: 'put',
            url:  "account/user/profile",
            data: {
                buisnessname: buisnessname,
                gst_reg_no:gst_reg_no,
                gst_state:gst_state,
                gst_address:gst_address
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(() => {
            window.location.href = "/settings"
        }).catch((err)=>{
            Toast(err.response.data.message,"error")
        })
    }

    
    return (

        <Row style={{ padding: "40px 0px 30px 30px" }}  className="m-nopadding">
            <Col span={24}>
                <p className="m-0 fs18">Business Profile</p>
                <Row>
                    <Col span={18}>
                        <p className="text-grey">Manage your business account information</p>
                    </Col>

                </Row>

                <Divider></Divider>

            </Col>
            
            <Col span={24} className="">
                <p style={{ color: "#484848" }} className="sub-text-light">Registered Business Name</p>
                <Input placeholder="Name" value={buisnessname} onChange={(evt)=>setBuisnessName(evt.target.value)} className="md10"></Input>

                <p style={{ color: "#484848" }} className="sub-text-light md30">GST Reg. Number</p>
                <Input placeholder="e.g. GST1234567890" maxLength={15} value={gst_reg_no} onChange={(evt)=>setgst_reg_no(evt.target.value)}  className="md10"></Input>

                <p style={{ color: "#484848" }} className="sub-text-light md30">Registered Address</p>
                <Input placeholder="Address"   value={gst_address} onChange={(evt)=>setgst_address(evt.target.value)} className="md10"></Input>

                <p style={{ color: "#484848" }} className="sub-text-light md20">State/UT</p>
                <Input placeholder="Enter State/UT" value={gst_state} onChange={(evt)=>setgst_state(evt.target.value)} className="md10"></Input>

                <Checkbox className="agree md30"> I/We abide by <span className="primary-color link">GST undertaking</span> </Checkbox>
            </Col>

            <Col span={24} className="md30 text-right">
                <Button type="primary" onClick={()=>UpdateProfile()}>Save Changes</Button>

            </Col>
        </Row>

    );
}
export default BusinessProfile;
