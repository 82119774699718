import React, { Component } from "react";
import { Input, Checkbox, message } from "antd";
import SignalCard from "./SignalCard";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import Loader from "../Loader/Loader";

import Toast from "../utils/Toast";
class Signals extends Component {
  constructor(props) {
    super(props);
    this.state = { loader: true, all_signals: [], search: "" };
  }

  componentDidMount() {
    this.props.allSignals().then(() => {
      this.props._all_signals.map((item) => {
        item.ltpshow = true;
      });
      this.setState({
        all_signals: this.props._all_signals,
        loader: false,
      });
    });

    // setInterval(() => {
    //   this.props.allSignals().then(() => {
    //     this.props._all_signals.map((item) => {
    //       item.ltpshow = true;
    //     });
    //     this.setState({
    //       all_signals: this.props._all_signals,
    //       loader: false,
    //     });
    //   });
    // }, 3000);
  }

  changeContent(data, value) {
    data.ltpshow = value;
    this.setState({
      ...this.state.all_signals,
    });
  }

  FilterTags(item, value) {
    this.props
      .FilterTags({
        type: item.type || value,
        tag: item.label || item.join(","),
      })
      .then(() => {
        this.setState({
          all_signals: this.props._all_signals,
          loader: false,
        });
      });
  }

  PinSignal(data, value) {
    this.props.PinSignal({ signal_id: data, pin: value }).then(() => {
      Toast("Pinned Successfully");
      this.componentDidMount();
    });
  }

  render() {
    const options = [
      { label: "Intraday", value: "Intraday", type: "product" },
      { label: "Overnight", value: "Overnight", type: "product" },
      { label: "Short Term", value: "Short Term", type: "product" },
      { label: "Long Term", value: "Long Term", type: "product" },
    ];
    let _a = this.state.all_signals;
    let filterSignal = _a.filter((_mf) => {
      return (
        _mf.scrip_name
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1
      );
    });
    filterSignal = filterSignal ? filterSignal : [];
    return (
      <div className="row">    
          <div className="col-md-11  col-xsm-12 navigation-body-width">
            <div className="row">          
              <div className="col-md-6 border-left height100vh">
                <div className="row">
                  <div className="col-md-8 md35">
                    <Input
                      className="white-background"
                      placeholder="Search Stock"
                      onChange={(evt) =>
                        this.setState({ search: evt.target.value })
                      }
                    ></Input>
                  </div>
                  <div className="col-md-4 text-right md35">
                    <h5 className="heading-text ">
                      <a href="/create-signal">
                        <i
                          class="fa fa-plus-circle md10 cursor"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </h5>
                  </div>
                  <div className="col-md-12 nopadding">
                    <hr></hr>
                  </div>

                  <div className="col-md-10">
                    <Checkbox.Group
                      options={options}
                      onChange={(evt) => this.FilterTags(evt, "product")}
                    />
                  </div>
                  <div className="col-md-2 md10 text-right">
                    <p
                      className="sub-text cursor"
                      onClick={() => window.location.reload()}
                    >
                      Clear All
                    </p>
                  </div>

                  {this.state.loader === true ? (
                    <div className="col-md-12 text-center md100 padding30t">
                      <Loader></Loader>
                    </div>
                  ) : filterSignal.length === 0 ? (
                    <div className="col-md-12 text-center md100 padding30t">
                      <p className="sub-text md100">No Signal Found</p>
                    </div>
                  ) : (
                    <div className="col-md-12 padding30t">
                      {filterSignal.map((item) => {
                        return (
                          <SignalCard
                            data={item}
                            PinSignal={(data, value) =>
                              this.PinSignal(data, value)
                            }
                            FilterTags={(item) => this.FilterTags(item)}
                            changeContent={(data, value) =>
                              this.changeContent(data, value)
                            }
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 height100vh border-left"></div>
            </div>
          </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  _all_signals: state.publics.all_signals,
});
const mapDispatchToProps = (dispatch) => ({
  allSignals: (v) => dispatch(actions.allSignals(v)),
  FilterTags: (v) => dispatch(actions.FilterTags(v)),
  PinSignal: (v) => dispatch(actions.PinSignal(v)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Signals)
);
