import React from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import './style.scss'
import NewMentionsPlugin from './plugins/MentionsPlugin';
import { MaxLengthPlugin } from './plugins/MaxLength';

function Placeholder() {
  return <div className="editor-placeholder">Enter text...</div>;
}

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ],
 
};


export default function Editor(props) {

  const onChange = (editorState, editor) => {
    // editorState.read(() => {
    //   // Read the contents of the EditorState here.
    //   const root = $getRoot();
    //   const selection = $getSelection();
    // });
    // JSON formate
    // const stringifiedEditorState = JSON.stringify(editorState.toJSON());
    // console.log(editorState.toJSON())

    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor);
      if (props?.onChange){
        props.onChange(htmlString)
      }
      
      // console.log(htmlString)
    })


  }
  const {isMaxLength} = props
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={props.className + " editor-container"}>
        <ToolbarPlugin tools={props?.tools} style={props?.borderBottom ? { borderBottom: "1px solid #d5d5d5" } : {}} />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
           {isMaxLength && <MaxLengthPlugin maxLength={30} />}
          <OnChangePlugin onChange={onChange} />
          <HistoryPlugin />
          {/* <TreeViewPlugin /> */}
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <NewMentionsPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>
  );
}
