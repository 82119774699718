import { SearchOutlined } from "@ant-design/icons";
import { AppleLogo, BookOpenText, Buildings, Certificate, Chair, ChalkboardTeacher, Devices, GooglePlayLogo, Megaphone, Newspaper } from "@phosphor-icons/react";
import { Col, Row, Space, Button, Avatar, Input, Tag } from "antd";
import React from "react";




const KidsLearning = () => {


    return (
        <Row className="mb-5">
            <Col span={24} className="camp-kids-bg" >
                <Row >
                    <Col span={18} offset={3} className="md80">
                        <Row gutter={10}>
                            <Col span={12} className="mb-5">
                                <Space size={24} >
                                    <Button className="Radius8 text-grey fs14 fw600">Campus</Button>
                                    <Button type="text" className="kids">Campus for kids</Button>
                                </Space>
                                <p className="fs16 fw600 md15" style={{ color: '#2E48CD' }}> Syllo for kids </p>
                                <Space className="md15" >
                                    <Avatar src={require("../../../assets/images/check.png")} />
                                    <p className="fs20 fw600 "> Axis Bank </p>
                                </Space>
                                <h1 className="fs44 fw500 md20" >Learning about finance made fun for Kids <br />
                                    <span style={{ color: '#2F49CF' }}> 7-15 </span> </h1>
                                <p className="fs14 fw400 ">
                                    Lorem ipsum may be used as a placeholder before final copy is available.
                                </p>
                                <Button type="primary" className="md20">  Start A Course </Button>

                            </Col>
                            <Col span={12} className="text-end m-text-center">
                                <img src={require("../../../assets/images/Desktop/12079894_4906331.svg").default} className="md80" alt="" height={250} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={18} offset={3} className="md80">
                <Row>
                    <Col span={16}>
                        <p className="fs30 fw600"> Explore More  <span style={{ color: '#2E48CD' }}> Topics </span>  </p>
                    </Col>
                    <Col span={8}>
                        <Input size="small" placeholder="Search your Courses" className=" explo-input" suffix={<SearchOutlined style={{ marginRight: '10px', fontSize: '20px' }} />} />

                    </Col>
                </Row>
                <Row gutter={20} className="md25">
                    <Col span={6}>
                        <div className="topics-cards text-center md20">
                            <Buildings size={40} color="#3d3d4a" weight="fill" />
                            <p className="fs16 fw600"> Topic 1 </p>

                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="topics-cards text-center md20">
                            <Devices size={40} color="#3d3d4a" weight="fill" />
                            <p className="fs16 fw600"> Topic 1 </p>

                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="topics-cards text-center md20">
                            <Buildings size={40} color="#3d3d4a" weight="fill" />
                            <p className="fs16 fw600"> Topic 1 </p>

                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="topics-cards text-center md20">
                            <Devices size={40} color="#3d3d4a" weight="fill" />
                            <p className="fs16 fw600"> Topic 1 </p>

                        </div>
                    </Col>

                </Row>
                <Row gutter={20} className="md10">
                    <Col span={6}>
                        <div className="topics-cards text-center md20">
                            <Buildings size={40} color="#3d3d4a" weight="fill" />
                            <p className="fs16 fw600"> Topic 1 </p>

                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="topics-cards text-center md20">
                            <Devices size={40} color="#3d3d4a" weight="fill" />
                            <p className="fs16 fw600"> Topic 1 </p>

                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="topics-cards text-center md20">
                            <Buildings size={40} color="#3d3d4a" weight="fill" />
                            <p className="fs16 fw600"> Topic 1 </p>

                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="topics-cards text-center md20">
                            <Devices size={40} color="#3d3d4a" weight="fill" />
                            <p className="fs16 fw600"> Topic 1 </p>

                        </div>
                    </Col>

                </Row>
            </Col>
            <Col span={20} offset={2} className="camp-kids-bg md80 padding50">
                <Row>
                    <Col span={17}>
                        <h2 className="fw600 md20" >Learning from Expert Know about <br />
                            <span style={{ color: '#2F49CF' }}> Finance</span> </h2>
                    </Col>
                    <Col span={7} className="md20 text-right">
                        <Button type="primary"> Drop information </Button>
                    </Col>
                </Row>
            </Col>
            <Col span={18} offset={3} className="md80">
                <Row>
                    <Col span={24}>
                        <p className="fs30 fw600"> How it  <span style={{ color: '#2E48CD' }}> Work </span>  </p>
                    </Col>

                </Row>
                <Row gutter={20} className="md25">
                    <Col span={8}>
                        <div className="camp-kids-bg  md20 padding25 Radius8">
                            <BookOpenText size={40} color="#1240ca" weight="regular" />
                            <p className="fs16 fw600 md10"> Find your Course </p>
                            <p className="fs14 fw400 md5"> text commonly used to demonstrate the visual form of a document or </p>

                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="camp-kids-bg  md20 padding25 Radius8">
                            <Chair size={40} color="#1240ca" weight="regular" />
                            <p className="fs16 fw600 md10"> Book A Seat</p>
                            <p className="fs14 fw400 md5"> text commonly used to demonstrate the visual form of a document or </p>


                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="camp-kids-bg  md20 padding25 Radius8">
                            <Certificate size={40} color="#1240ca" weight="regular" />
                            <p className="fs16 fw600 md10"> Get Certificate </p>
                            <p className="fs14 fw400 md5"> text commonly used to demonstrate the visual form of a document or </p>


                        </div>
                    </Col>


                </Row>

            </Col>
            <Col span={20} offset={2} className=" md80 padding50" style={{ backgroundColor: '#3B6BE3' }}>
                <Row>
                    <Col span={16}>
                        <p className="fs16 fw500  text-white">  Ready to start? </p>
                        <h2 className="fw500  text-white" >Download our
                            <span className="text-black"> mobile app</span>.<br />for easy to start your course.
                        </h2>
                    </Col>
                    <Col span={8} >
                        <Space size={24} className="md20">
                            <Button className="Radius8  fs14 fw600" icon={<GooglePlayLogo size={20} color="#12cabd" weight="fill" className="me-2" />}>Google Play</Button>
                            <Button className="Radius8  fs14 fw600" icon={<AppleLogo size={20} color="#00000" weight="fill" className="me-2" />}>Google Play</Button>
                        </Space>

                    </Col>
                </Row>
            </Col>
            <Col span={18} offset={3} className=" md80  syllo-kids-card">
                <Row>
                    <Col span={24}>
                        <p className="fs30 fw500" >  Syllo Kids <span style={{ color: '#2F49CF' }}> Premium</span></p>
                        <p className="fs14 fw400 text-grey md10">Some description about Premium package</p>
                    </Col>
                    <Col span={24} className="md50">
                        <Row>
                            <Col span={14} >
                                <p className="fs16 fw600  md15"> What you’ll get? </p>
                                <div className="ps-2 md20">
                                    <Space>
                                        <img src={require("../../../assets/images/Campusimgae/grey-img.png")} alt="" height={16} />
                                        <p className="fs12 fw400 sub-text "> 10 Live Classes </p>
                                    </Space> <br />
                                    <Space className="md5">
                                        <Megaphone size={25} color="#4b5669" />
                                        <p className="fs12 fw400 sub-text ">   4 Private Channels  </p></Space> <br />

                                    <Space className="md5">
                                        <ChalkboardTeacher size={25} color="#4b5669" />
                                        <p className="fs12 fw400 sub-text ">
                                            1-to-1 Mentorship </p></Space><br />
                                    <Space className="md5">
                                        <Newspaper size={25} color="#4b5669" />
                                        <p className="fs12 fw400 sub-text "> Premium Articles </p></Space>
                                </div>
                            </Col>
                            <Col span={10} className="md15">
                                <p className="fs16 fw600 text-black"> Tenure </p>
                                <Row className="md25">

                                    <Col span={24} className="kids-tag">
                                        <Space wrap>
                                            <Tag >3 months</Tag>
                                            <Tag>6 months</Tag>
                                            <Tag>9 months</Tag>

                                        </Space>
                                    </Col>

                                    <Col span={24} className="md20">
                                        <p className="fs16 fw600 text-black"> Price </p>
                                        <p className="fs14 fw500 text-grey md15"> ₹ 599/month </p>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <p className="fs16 fw600  md15"> Topics </p>
                            </Col>
                            <Col span={24} className="md25 ">
                                <Space size={20} className="stock-tag">
                                    <Tag.CheckableTag checked> Stock Market</Tag.CheckableTag>
                                    <Tag.CheckableTag >Budgeting</Tag.CheckableTag>
                                    <Tag.CheckableTag >G20 Summit</Tag.CheckableTag>
                                </Space>
                            </Col>
                            <Col span={16} className="md15">
                                <p className="fs14 fw300 text-grey">
                                    Once your server is configured to receive payloads, it'll listen for
                                    any payload sent to the endpoint you configured. For security reasons,
                                    you probably want to limit requests to those coming from GitHub.
                                </p>

                            </Col>
                        </Row>

                    </Col>

                </Row>
            </Col>


        </Row>
    );
}
export default KidsLearning;