import React, { useEffect, Fragment } from 'react';
import { Row, Col,  Avatar, Button, Tabs, Space, Skeleton, message,  Modal } from 'antd';
import { useParams, withRouter } from "react-router-dom"
import { connect } from "react-redux";
import actions from "../../../store/actions/index";
import '../style.scss'
import axios from "../../../store/axios";
import NewsFeed from '../../NewsFeed/NewsFeed';
import { useState } from 'react';
// import ChannelEvent from './ChannelEvent'

import Cookies from 'js-cookie';
import Chats from '../../ChannelLiveChat/Chat';
import ChannelThreads from './ChannelThreads';
import ChannelDrops from './ChannelDrops';
import ChannelManage from './ChannelManage';
import BlankState from '../../BlankState';
import ChannelAbout from './ChannelAbout';
import ChannelMember from './ChannelMember';
import PaymentCreditPage from '../../utils/paymentCredit';

import Toast from '../../utils/Toast';

const { TabPane } = Tabs;

const ChannelDetailV3 = (props) => {
    const params = useParams();

    var _uid = params['uuid'];

    const [loader, setLoader] = useState(true);
    const [loaderMember, setLoaderMember] = useState(false);
    const [newsloader, setNewsLoader] = useState(true);
    const [channel_details, setChannelDetails] = useState(null);
    const [channel_id, setChannelId] = useState(null);
    const [isme, setisme] = useState(null);
    const [market, setMarket] = useState([]);
    const [ws, setWs] = useState(null);
    const [memberdata, setMemberData] = useState([]);
    const [tabKey, setTabKey] = useState("1");
    const [creditModal, setCreditModal] = useState(false);

    // const [desc, setDesc] = useState("")

    useEffect(() => {
        setLoader(true);
        setMarket([]);
        setWs(new WebSocket(process.env.REACT_APP_WS_BASE_URL + "wss/channel/feed?token=" + Cookies.get("Logintoken") + "&channel_id=" + _uid))
        props.ChannelDetails({ id: _uid });
    }, [_uid])

    useEffect(() => {
        if (ws) {
            ws.onmessage = evt => {
                const _message = JSON.parse(evt.data)
                _message.collapsed = false
                var _market = _message.concat(market)
                setMarket(_market)
                setNewsLoader(false)
            }
        }
    }, [ws])

    useEffect(() => {
        if (props._channel_details.length === undefined) {
            setChannelDetails(props._channel_details)
            setChannelId(props._channel_details.channel_id)
            setisme(props._channel_details.isme)
            // setDesc(props._channel_details.desc)
            setLoader(false)

            if ( props._channel_details?.settings?.show_member_online_list) {
                loadMoreMembers({});
            }
        }
    }, [props._channel_details])

    useEffect(() => {
        if (props._members) {
            setMemberData(props._members);
            setLoaderMember(false)
        }
    }, [props._members])

    useEffect(() => {
        setisme(false)
    }, [])

    const loadMoreMembers = (params) => {
        if (loaderMember) {
            return
        }
        setLoaderMember(true);
        props.channelsMembers({ id: _uid, ...params });
    }


    const onCreditUpdate = (res) => {
        setCreditModal(res.modal);
        props.ChannelDetails({ id: _uid });
    }
    

    const reload = (data) => {
        axios({
            method: 'post',
            url:  "lighthouse/payment/verify",
            data: data,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            if (res?.data?.data?.status) {
                setLoader(true)
                window.location.reload()
            }
            else {
               Toast("Error","error")
            }
        })

    }

    
    function details(record) {
        if (record.entity_type === "News") {
            axios({
                method: 'patch',
                url: process.env.REACT_APP_PUBLIC_API_BASE_URL + 'account/user/preferences?id=' + record.entity_id + '&entity_type=News',
                headers: {
                    Authorization: Cookies.get("Logintoken")
                }
            }).then(function () {
                window.open(record.link)
            })
                .catch(function () {
                    window.open(record.link)
                })
            return
        }
        if (record.entity_type === "Notes") {
            window.location.href = "/note/" + record.entity_uuid
            return
        }
        window.location.href = "/feed-view/" + record?.entity_uuid
    }


    const refreshPage = () => {
        props.ChannelDetails({ id: _uid });
    }

    const joinFreeChannel=()=>{
        props.followChannelCreator({ id: _uid }).then(() => {
            props.ChannelDetails({ id: _uid });
        });
    
    }

    

    return (

        <Row className="m-minute10-marginx">
            <Col span={24} >

                <Row>
                    <Col span={24} className="channel-details-v3" style={{padding:"0px"}}>
                        <Row className='header-banner' style={{ background: `linear-gradient(90deg, rgba(0, 0, 0, 0.8) 4.15%, rgba(0, 0, 0, 0) 21.63%), url(${channel_details?.banner_img_url})`}}>
                            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }} >
                                <Row className='md55' style={{padding:"40px 0"}}>
                                    <Col span={24}>
                                        <Space>
                                            <Avatar className='header-icon' size={80} src={channel_details?.img_url} />
                                            {
                                                !channel_details?.isme && !channel_details?.joined && channel_details?.amount > 0 ? <Button type='primary' className='ms-3 fs11' onClick={() => setCreditModal(true)}>Join Channel</Button>: channel_details?.joined ? "":
                                                <Button type='primary' className='ms-3 fs11' onClick={() => joinFreeChannel()}>Join Channel</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                   
                                </Row>
                            </Col>
                        </Row>
                        <Row>

                            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 22, offset: 1 }} >

                                <Row gutter={16}>
                                    <Col xs={{ span: 0, offset: 0 }} lg={{ span: 6, offset: 0 }} style={{ borderRight: '1px solid  #F5F5F5'}}>
                                        <ChannelAbout channel_details={channel_details} tab={tabKey} memberdata={memberdata} />
                                        {/* {
                                    loader ? <Space className={"md20"}>
                                        <Skeleton.Button active={true} size={"default"} shape={"square"} />
                                        <Skeleton.Avatar active={true} size={"default"} shape={"circle"} />
                                        <Skeleton.Input style={{ width: 200 }} active={true} size={"default"} />
                                    </Space> : <Row>
                                        <Col span={'auto'}>
                                            <Avatar className='header-icon' size={70} src={channel_details?.img_url} />
                                        </Col>
                                        <Col span={'auto'} className="channel-title ps-2 pe-4">
                                            <h1>{channel_details?.name}</h1>
                                            <p><a href={"/profile/" + channel_details?.creator?.user_code} style={{ color: "#898989" }}>{channel_details?.creator?.full_name}</a></p>
                                        </Col>
                                        <Col span={'auto'}>
                                            {
                                                !channel_details?.isme && !channel_details?.joined && <Button className='md10 channel-join' type="primary" shape="round" onClick={() => paytoSubcribe()}>Join</Button>
                                            }
                                        </Col>
                                    </Row>
                                } */}


                                    </Col>

                                    <Col xs={{ span: 24, }} lg={{ span: 18 }} >
                                        <Row>
                                            <Col span={24} className='m-md9 m-md40 '>

                                                <Tabs defaultActiveKey="1"  size={"small"} tabBarGutter={25} className='md10' onTabClick={(k)=>setTabKey(k)}>
                                                    <TabPane tab="Wall" key="1">
                                                        <Row gutter={16}>
                                                            <Col className="gutter-row" lg={{ span: 16 }} xs={{ span: 24 }} >
                                                                {/* <Row className='border-card1 cardv3-head no-border'>
                                                    <Col span={24}>
                                                        <a className='btn cardv3-btn me-2' onClick={() => ws.send(JSON.stringify({ entity: 0 }))} type="" shape="round" size={"large"}>
                                                            All</a>
                                                        <a onClick={() => ws.send(JSON.stringify({ entity: 3 }))} className='btn cardv3-btn me-2' type="" shape="round" size={"large"}>
                                                            Post</a>

                                                        <a onClick={() => ws.send(JSON.stringify({ entity: 2 }))} className='btn cardv3-btn me-2' type="" shape="round" size={"large"}>
                                                            Newsletter</a>

                                                        <a onClick={() => ws.send(JSON.stringify({ entity: 4 }))} className='btn cardv3-btn me-2' type="" shape="round" size={"large"}>
                                                            Report</a>
                                                        <a onClick={() => ws.send(JSON.stringify({ entity: 7 }))} className='btn cardv3-btn me-2' type="" shape="round" size={"large"}>
                                                            Podcast
                                                        </a>
                                                        <a className='btn cardv3-btn me-2' type="" shape="round" size={"large"}>

                                                            <Dropdown overlay={menu} trigger={['click']}>
                                                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                                    <EllipsisOutlined />
                                                                </a>
                                                            </Dropdown>
                                                        </a>

                                                    </Col>
                                                </Row> */}
                                                                {newsloader ? (
                                                                    <Fragment>
                                                                        <Skeleton
                                                                            className="md20"
                                                                            avatar
                                                                            paragraph={{ rows: 4 }}
                                                                        />
                                                                        <Skeleton
                                                                            className="md20"
                                                                            avatar
                                                                            paragraph={{ rows: 4 }}
                                                                        />
                                                                    </Fragment>
                                                                ) : market.length === 0 ? <>

                                                                    <p className="sub-text text-center md50">No Data Available</p>
                                                                    <div className={"text-align-center"}>
                                                                        <BlankState img={require("../../../assets/images/blank/no-data-found.svg").default} height={200} />
                                                                    </div>


                                                                </> : (
                                                                    <div className={"white-background  m-p0 m-ps0"}>
                                                                        <NewsFeed
                                                                            style={{}}
                                                                            data={market}
                                                                            loadMoreData={() => this.loadMore()}
                                                                            details={(record) => details(record)}
                                                                            reload1={(id, key, type) => reload(id, key, type)}
                                                                            height={"calc(100vh - 236px)"}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Col>
                                                            <Col className="gutter-row m-md10" lg={{ span: 8 }} xs={{ span: 0 }}>
                                                                <ChannelMember memberdata={memberdata} data={channel_details} />
                                                            </Col>
                                                            {/* <Col className="gutter-row right-panel md30 m-md10" lg={{ span: 8, offset: 0 }} xs={{ span: 22, offset: 1 }}>
                                                <Row className='panel' >
                                                    <Col span={24} className="panel-head ">
                                                        <span>About</span>

                                                    </Col>

                                                    {
                                                        loader ? <Skeleton></Skeleton> : <Col span={24} className="panel-body ">
                                                            <p>{channel_details?.desc}</p>

                                                            <Row className='mt-4'>
                                                                <Col span={12}>
                                                                    <h5>{channel_details?.members?.members}</h5>
                                                                    <p>Members</p>

                                                                </Col>
                                                                <Col span={12}>
                                                                    <h5>{channel_details?.members?.online}</h5>
                                                                    <p>Online</p>

                                                                </Col>

                                                            </Row>
                                                        </Col>
                                                    }



                                                </Row>
                                                      
                                                {
                                                    channel_details !== null && channel_details?.events.length !== 0 && <><Divider /><Row className='panel'>
                                                        <Col span={24} className="panel-head ">
                                                            <span>Events</span>
                                                        </Col>
                                                        <Col span={24} className="panel-body event">
                                                            {
                                                                loader ? <Skeleton></Skeleton> : channel_details !== null && channel_details.length !== 0 && channel_details?.events.map((item, i) => {
                                                                    return <ChannelEvent key={i}
                                                                        title={item?.schedule?.name}
                                                                        desc={item?.schedule?.description}
                                                                        timestamp={moment(item?.schedule?.schedule_date_from).format("LLL  A")}
                                                                        schedule_id={item?.schedule?.schedule_uid}
                                                                    />
                                                                })
                                                            }
                                                            {
                                                                channel_details !== null && channel_details.length > 3 && <a style={{ backgroundColor: "white" }} href={"/explore/search"} className='btn cardv3-btn me-2 mb-2' shape="round" size={"large"}>See More</a>
                                                            }

                                                        </Col>
                                                    </Row></>
                                                }

                                            </Col> */}
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tab="Chat" key="2">
                                                        {
                                                            !loader &&
                                                            <Chats id={channel_id} joined={channel_details.joined} isme={channel_details.isme} _uid={_uid} ></Chats>
                                                        }

                                                    </TabPane>
                                                    <TabPane tab="Threads" key="7">
                                                        {
                                                            !loader && <ChannelThreads id={channel_id} _uid={_uid} />
                                                        }

                                                    </TabPane>
                                                    <TabPane tab="Drops" key="8" >
                                                        {
                                                            !loader && <ChannelDrops joined={channel_details.joined} id={channel_id} _uid={_uid} />
                                                        }


                                                    </TabPane>



                                                    {       
                                                !loader && isme && <TabPane tab="Manage" key="9">
                                                    <Row>
                                                        <Col span={24}>
                                                            <ChannelManage refreshPage={refreshPage} id={_uid} />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            }

                                                    {/* {
                                                !loader && !isme && settingdata?.show_member_online_list && <TabPane tab="Members" key="5" >
                                                    <Row className={"white-background"} style={{ minHeight: "70vh" }}>
                                                        <Col xs={{ span: 24 }} lg={{ span: 16 }} md={{ span: 16 }}>
                                                            <ManageMembers notAdmin={true} members={memberdata} loadMoreMembers={loadMoreMembers} loader={loaderMember} />
                                                        </Col>

                                                    </Row>

                                                </TabPane>
                                            } */}


                                                </Tabs>
                                            </Col>


                                            {/* <Col span={7}>
                                                {
                                                    recent_data === false ? <RecentFile /> :
                                                        <ChannelMember />
                                                }
                                            </Col> */}
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                    <Modal centered open={creditModal} onCancel={() => setCreditModal(false)} footer={false} >
                <PaymentCreditPage onChange={(val) => onCreditUpdate(val)} 
                    title={channel_details?.name} entity={"Channel"} 
                    amount={channel_details?.amount}
                    entity_type="CHANNEL"
                    id={_uid}
                    user_code={channel_details?.creator?.user_code}
                    ></PaymentCreditPage>
            </Modal>
                </Row>
            </Col>
        </Row>

    );
}



const mapStateToProps = (state) => ({
    _channel_details: state.channel.channeldetail,
    _live_updates: state.channel.live_updates,
    _payment_link: state.channel.channel_payment,
    _members: state.channel.channel_members,
    _games: state.gamify.games_categories
});
const mapDispatchToProps = (dispatch) => ({
    ChannelDetails: (v) => dispatch(actions.ChannelDetails(v)),
    followChannelCreator: (v) => dispatch(actions.followChannelCreator(v)),
    unfollowChannelCreator: (v) => dispatch(actions.unfollowChannelCreator(v)),
    getWebsocketData: (v) => dispatch(actions.getWebsocketData(v)),
    paytoSubcribe: (v) => dispatch(actions.paytoSubcribe(v)),
    channelsMembers: (v) => dispatch(actions.channelsMembers(v)),
    createAnnouncement: (v) => dispatch(actions.createAnnouncement(v)),
    deleteAnnouncement: (v) => dispatch(actions.deleteAnnouncement(v)),
    ChannelSetting: (v) => dispatch(actions.ChannelSetting(v)),
    allMarketNewsData: (v) => dispatch(actions.allMarketNewsData(v)),
    SaveFeed: (v) => dispatch(actions.SaveFeed(v)),
    savedFeeds: (v) => dispatch(actions.savedFeeds(v)),
    likeFeed: (v) => dispatch(actions.likeFeed(v)),
    relatedTopics: (v) => dispatch(actions.relatedTopics(v)),
    cancelSubscription: (v) => dispatch(actions.cancelSubscription(v)),
    allGamesCategories: (v) => dispatch(actions.allGamesCategories(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ChannelDetailV3)
);

