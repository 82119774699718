import { Col, Row, Input, Tag, Avatar, Space, Button, Radio, Skeleton, Dropdown } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import moment from "moment";
import { Bug, DotsThreeVertical, Export, Prohibit } from "@phosphor-icons/react";
import { RWebShare } from "react-web-share";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import ExploreNote from "../Explorev2/ExploreNote";
import ExploreBusiness from "../Explorev2/ExploreBusiness";
import ExploreNewsletter from "../Explorev2/ExploreNewsletter";
import ExploreNotes from "../Explorev2/ExploreNotes";
import FollowShare from "../utils/FollowShare";
import ExploreCampus from "./ExploreCampus";
import ExplorePodcast from "../Explorev2/ExplorePodcast";


const ExploreCreator = ({ props }) => {
    const [data, setData] = useState([])
    const [tags, setTags] = useState([])
    const [parent, setParent] = useState("1")
    const [child, setChild] = useState("0")
    const [loader, setLoader] = useState(null)
    const [moreloader, setmoreloader] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [offset, setOffset] = useState(0)


    const history = useHistory();
    const [distionary,] = useState([{
        key: "0", value: "All",
    }, {
        key: "3", value: "Post",
    }, {
        key: "4", value: "Report",
    }, {
        key: "7", value: "Podcast"
    }, {
        key: "6", value: "Campus"
    }])

    const searchUser = async(evt, more, _offset) => {
        if (more) {
            setmoreloader(true)
        }
        else {
            setData([])
            setLoader(true);
            setOffset(0);
        }
        if (parent === "1") {
            await axios({
                method: 'get',
                url:  `market/feed/creators?count=10&offset=${offset + _offset}&entity=` + distionary.find((a) => a.key === child).key + "&key=" + evt,
                headers: {
                    Authorization: Cookies.get("Logintoken")
                }
            }).then(res => {
                const __data = res.data.data
                if (__data && __data.items.length >= 10) {
                    setLoadMore(true)
                    setOffset(offset + _offset)
                }
                else {
                    setLoadMore(false)
                }
                let _data = data
                if (more) {
                    setData(_data.concat(__data.items))
                    setTags(tags.concat(__data.tags))
                }
                else {
                    setData(__data.items)
                    setTags(__data.tags)
                }
                setLoader(false)
                setmoreloader(false)
            })
        }
        else if (parent === "2") {
            setLoader(true)
            await axios({
                method: 'get',
                url:  `calendar/schedule/event?count=10&offset=${offset + _offset}&key=` + evt,
                headers: {
                    Authorization: Cookies.get("Logintoken")
                }
            }).then(res => {
                const __data = res.data.data
                if (__data && __data.items.length >= 10) {
                    setLoadMore(true)
                    setOffset(offset + _offset)
                }
                else {
                    setLoadMore(false)

                }
                let _data = data
                if (more) {
                    setData(_data.concat(__data.items))
                    setTags(tags.concat(__data.tags))
                }
                else {
                    setData(__data.items)
                    setTags(__data.tags)
                }
                setLoader(false)
                setmoreloader(false)
            })
        }
        else if (parent === "3") {
            setLoader(true)
            await axios({
                method: 'get',
                url:  `channel/explore?category=&count=10&offset=${offset + _offset}&key=` + evt,
                headers: {
                    Authorization: Cookies.get("Logintoken")
                }
            }).then(res => {
                const __data = res.data.data
                if (__data && __data.items.length >= 10) {
                    setLoadMore(true)
                    setOffset(offset + _offset)
                }
                else {
                    setLoadMore(false)

                }
                let _data = data
                if (more) {
                    setData(_data.concat(__data.items))
                    setTags(tags.concat(__data.tags))
                }
                else {
                    setData(__data.items)
                    setTags(__data.tags)
                }
                setLoader(false)
                setmoreloader(false)
            })
        }
        else if (parent === "Business" || parent === "Newsletter" || parent === "Note" || parent === "Podcast") {
            // setLoader(true)
            await axios({
                method: 'get',
                url:  `market/explore/${parent}?count=10&offset=${offset + _offset}&key=` + evt,
                headers: {
                    Authorization: Cookies.get("Logintoken")
                }
            }).then(res => {
                const __data = res.data.data
                if (__data && __data.length >= 10) {
                    setLoadMore(true)
                    setOffset(offset + _offset)
                }
                else {
                    setLoadMore(false)

                }
                let _data = data
                if (more) {
                    setData(_data.concat(__data));
                }
                else {
                    setData(__data);
                }
                setLoader(false);
                setmoreloader(false);
            })
        }
        else if (parent === "Campus") {
            await axios({
                method: 'get',
                url:  `v1.0/campus/explore/more?topic=${evt}&offset=${offset + _offset}`,
                headers: {
                    Authorization: Cookies.get("Logintoken")
                }
            }).then(res => {
                const __data = res.data.data
                if (__data && __data.length >= 10) {
                    setLoadMore(true)
                    setOffset(offset + _offset)
                }
                else {
                    setLoadMore(false)

                }
                let _data = data
                if (more) {
                    setData(_data.concat(__data));
                }
                else {
                    setData(__data);
                }
                setLoader(false)
                setmoreloader(false)
            })
        }


    }
    const menuClick = ({ key, id }) => {
        if (key === '4') {

        }
    };

    const menuItems = (item) => {
        return [

            {
                // label: (
                //     <span className="fs12 fw400"> Share</span>
                // ),
                key: '1',
                icon: <RWebShare
                    data={{
                        text: "Syllo Channel",
                        url: `${process.env.REACT_APP_URL}channel/${item.uid}/${item.channel_id}`,
                        title: item?.name,
                    }}

                ><span><Export size={18} color="#000000" weight="thin" className="me-2" /> Share</span>
                </RWebShare>
            },
            // {
            //     label: (
            //         <span className="fs12 fw400"> Delete video</span>
            //     ),
            //     key: '2',
            //     icon: <Trash size={18} color="#000000" weight="thin" />
            // }

        ]
    };

    const onClickPeople = async ({ key, id }) => {

        if (key === '2') {

        }
    };

    const itemsPeople = (item) => {

        return [
            {
                label: (
                    <span className="fs12 fw400"> Block @{item?.username}</span>
                ),
                key: '1',
                icon: <Prohibit size={18} color="#000000" />
            },
            {
                label: (
                    <span className="fs12 fw400"> Report Issue</span>
                ),
                key: '2',
                icon: <Bug size={18} color="#000000" />
            }

        ]
    };



    return (

        <Row className={"height100vh m-minute10-marginx"}>


            <Col span={24} className="explore-v2-search">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 7 }} className=" md50">
                        <h1 className="m-0 text-center"> Explore </h1>
                        <p className="fs18 fw400 text-center md10"> Search anyone & everything </p>

                        <Input size="small" placeholder="Search..." onChange={(evt) => searchUser(evt.target.value, false, 0)} className="md30 explo-input" suffix={<SearchOutlined style={{ marginRight: '10px', fontSize: '20px' }} />} />
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 14, offset: 5 }} className="text-center">

                        <Radio.Group size="small" value={parent} type={"button"} className="md20" onChange={(evt) => {
                            setParent(evt.target.value)
                            setLoader(null)
                            setData([])
                        }}>

                            <Radio className="yuja-tag" value={"1"}>Expert</Radio>
                            <Radio className="yuja-tag" value={"Business"}>Business</Radio>
                            <Radio className="yuja-tag" value={"2"}>Event</Radio>
                            <Radio className="yuja-tag" value={"3"}>Channel</Radio>
                            <Radio className="yuja-tag" value={"Newsletter"}>Newsletter</Radio>
                            <Radio className="yuja-tag" value={"Note"}>Note</Radio>
                            <Radio className="yuja-tag" value={"Podcast"}>Podcast</Radio>
                            <Radio className="yuja-tag" value={"Campus"}>Campus</Radio>

                        </Radio.Group>
                    </Col>

                </Row>
            </Col>

            <Col xxl={{ span: 14, offset: 5 }} md={{ span: 16, offset: 4 }} xs={{ span: 22, offset: 1 }} className="md25 mb-5">

                {loader === null ? <Row className="md20">
                    <Col span={24} className="">
                        <ExploreNote />
                    </Col>
                </Row> :
                    loader ?
                        <Skeleton></Skeleton> : <>
                            <Space wrap >
                                {
                                    tags.map((item) => {
                                        return <Tag className=" cursor" onClick={() => window.location.href = "/explore/tag?tag=" + item}>{item}</Tag>
                                    })
                                }
                            </Space>

                            {
                                parent === 'Business' ? <ExploreBusiness data={data} /> :
                                    parent === 'Note' ? <ExploreNotes data={data} /> :
                                        parent === 'Newsletter' ? <ExploreNewsletter data={data} /> :
                                        parent === 'Podcast' ? <ExplorePodcast data={data} /> :
                                        parent === 'Campus' ? <ExploreCampus _data={data} />:
                                            <Row gutter={30}>
                                                {


                                                    data && data.map((item) => {
                                                        return item?.entity === "Creator" ?
                                                            <Col xs={{ span: 24 }} lg={{ span: 12 }} >
                                                                <div className='creator-card md30'>
                                                                    <Row className="p-3">
                                                                        <Col span={21} className=''>
                                                                            <Space size={10} align="top">

                                                                                <Avatar size={60} src={item?.img_url}> </Avatar>
                                                                                <div>
                                                                                    <a href={"/profile/" + item?.user_code}>  <p className="fs16 fw600 mb-0"> {item?.fullname} </p> </a>
                                                                                    <span className="fs12 fw400" style={{ color: '#728096' }}>@{item?.username}</span> <br />

                                                                                    <FollowShare profile={item}></FollowShare>
                                                                                </div>

                                                                            </Space>
                                                                        </Col>
                                                                        <Col span={3} className="text-right">
                                                                            <div className="menu-surface-anchor">
                                                                                {
                                                                                    <Dropdown trigger={['click']} menu={{ items: itemsPeople(item), onClick: ({ key }) => onClickPeople({ key: key, id: item }) }} placement={"bottomCenter"} overlayStyle={{ minWidth: "120px" }}>
                                                                                        <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={22} color="#54514f" />} />
                                                                                    </Dropdown>
                                                                                }

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col> : item?.entity === "channel" ? <Col xs={{ span: 24 }} lg={{ span: 12 }} >

                                                                <div className='new-channel-card md30'>
                                                                    <Row className="">
                                                                        {/* <Col span={24} className='text-right exp-bg-img pt-2' style={{ backgroundColor: "rgb(224 224 224)", backgroundImage: `url(${item?.banner_img_url})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                                                    <Dropdown trigger={['click']} menu={{ items: menuItems(item), onClick: ({ key, }) => menuClick({ key: key, id: item }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                                        <Button type="text" shape="circle" onClick={(e) => e.preventDefault()}
                                                                            icon={<DotsThreeVertical size={20} color="#000" />}></Button>
                                                                    </Dropdown>
                                                                </Col> */}
                                                                        <Col className="cursor" span={24} onClick={() => history.push(`/channel/${item.uid}/${item.channel_id}`)}>
                                                                            <Space size={18} align="top">
                                                                                <Avatar size={44} src={item?.img_url} />
                                                                                <div className="">
                                                                                    <p className="fs16 fw600  "> {item?.name}</p>
                                                                                    <span className="fs12 fw400 " style={{ color: '#90949A' }}>@{item?.creator_name}</span>


                                                                                    {
                                                                                        item?.category.map((_item, i) => {
                                                                                            return <p key={i} className="text-grey">{_item?.name}</p>
                                                                                        })
                                                                                    }

                                                                                    <p className="text-grey">{item?.member_count} members</p>
                                                                                    <Button size="small" type="primary" href={`/channel/${item.uid}/${item.channel_id}`} className='m-pl8 text-white md10'> {item?.joined ? "GO TO CHANNEL" : "JOIN"}  </Button>

                                                                                </div>


                                                                            </Space>

                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col> :

                                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}> <a href={"/event/" + item.schedule_uid}><div className='yujaevent-card'>
                                                                    <Row>
                                                                        <Col span={24} className='bg-event-img'
                                                                            style={{ backgroundImage: `url(${item?.img_url})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
                                                                        >
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={24} className='padding20'>
                                                                            <Row className=''>
                                                                                <Col span={20} >
                                                                                    <p className="fs12 fw500 text-grey ">{(item?.is_paid)} </p>
                                                                                </Col>

                                                                                <Col span={24} className='md10'>
                                                                                    <p className="fs20 fw600 m-0 lh24">{item?.name}</p>
                                                                                </Col>
                                                                                <Col span={24} className='md10'>
                                                                                    <p className="fs12 fw400 m-0">{moment(item?.schedule_date_from).format("Do MMM | h:mm a")} - {moment(item?.schedule_date_to).format("Do MMM YY | h:mm A")} </p>
                                                                                    <p className="fs12 fw400 m-0">{item?.is_paid ? "Paid offering |" : ""}  {item?.venue}</p>
                                                                                    <p className="fs12 fw500 md15 "><Tag color="blue" > {(item?.medium)} </Tag> {item?.is_paid && <Tag color="orange" >Paid</Tag>} </p>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>

                                                                </div></a></Col>
                                                    })
                                                }


                                            </Row>
                            }

                            {
                                moreloader && <Skeleton></Skeleton>
                            }

                            {
                                loadMore && <Col span={24} className={"md20 text-center"}>
                                    <Button onClick={() => searchUser(parent, true, 10)}>Load More</Button>
                                </Col>
                            }

                        </>
                }


            </Col>




        </Row>

    );
}
export default ExploreCreator;
