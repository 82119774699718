import React, { useState, useEffect } from 'react';
import { Row, Col, Avatar, Skeleton, Button, Dropdown, Space } from 'antd';
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { DotsThreeVertical, Export } from "@phosphor-icons/react";
import BlankState from '../BlankState';
import { RWebShare } from 'react-web-share';
import { useHistory } from "react-router-dom";

const MyHubChannel = (props) => {
    const [loader, setLoader] = useState(true)
    const [channels, setChannels] = useState([])
    const [allchannels, setAllChannels] = useState(null)
    const history = useHistory();

    useEffect(() => {
        setLoader(true)
        props.ChannelList(props.channelFilter)
    }, [props.channelFilter])

    useEffect(() => {
        if (allchannels !== null) {
            if (props.is_owned === 1) {
                setChannels(allchannels.owned)
            } else {
                setChannels(allchannels.subscribed)
            }
        }
    }, [allchannels, props.is_owned])



    useEffect(() => {
        if (props?._channels.length !== 0) {
            setAllChannels(props?._channels)
            setLoader(false)
        }
    }, [props])

    const menuClick = ({ key, id }) => {
        if (key === '4') {

        }
    };

    const menuItems = (item) => {
        return [

            {
                // label: (
                //     <span className="fs12 fw400"> Share</span>
                // ),
                key: '1',
                icon: <RWebShare
                    data={{
                        text: "Syllo Channel",
                        url: `${process.env.REACT_APP_URL}channel/${item.uid}/${item.channel_id}`,
                        title: item?.name,
                    }}

                ><span><Export size={18} color="#000000" weight="thin" className="me-1" /> Share</span>
                </RWebShare>
            },

        ]
    };

    return (<>

        {
            loader === true ? <Row gutter={24}><Col xs={{ span: 24 }} lg={{ span: 12 }} ><Skeleton active></Skeleton></Col><Col xs={{ span: 24 }} lg={{ span: 12 }} ><Skeleton active></Skeleton></Col><Col xs={{ span: 24 }} lg={{ span: 12 }} ><Skeleton active></Skeleton></Col><Col xs={{ span: 24 }} lg={{ span: 12 }} ><Skeleton active></Skeleton></Col></Row> :
                channels.length === 0 ? <Row>

                    <Col span={24} className="md60 text-center">
                        <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={250} />

                    </Col>
                </Row> :
                    <Row gutter={24}>
                        {
                            channels.map((item, i) => {
                                return <Col xs={{ span: 24 }} lg={{ span: 12 }} >
                                    <div className='new-channel-card md30'>
                                        <Row className="">
                                            {/* <Col span={24} className='text-right exp-bg-img p-2'>
                                                <Dropdown trigger={['click']} menu={{ items: menuItems(item), onClick: ({ key, }) => menuClick({ key: key, id: item }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                    <Button shape="circle" className="mentorship-btn m-0" onClick={(e) => e.preventDefault()}
                                                        icon={<DotsThreeVertical size={20} color="#000" />}></Button>
                                                </Dropdown>
                                            </Col> */}
                                            <Col className="cursor" span={24} onClick={() => history.push(`/channel/${item.uid}/${item.channel_id}`)}>

                                                <Space size={18} align="top">
                                                    <Avatar size={44} src={item?.img_url} />

                                                    <div>
                                                        {
                                                            item?.creator !== null && <Space>
                                                                <Avatar size={20} src={item?.creator?.avatar_url}></Avatar>
                                                                <p key={i} className="fs12 fw300">{item?.creator?.full_name}</p>
                                                            </Space>
                                                        }
                                                        <p className="fs16 fw600 mb-0"> {item?.name}</p>
                                                        <p className="fs12 fw300 text-grey">{item?.members} <span className="fs12 fw300">members </span> </p>

                                                        {/* <Button size="small" type="primary" href={`/channel/${item.uid}/${item.channel_id}`} className='m-pl8 text-white md10'> {item?.joined ? "GO TO CHANNEL" : "JOIN"}  </Button> */}


                                                    </div>


                                                </Space>
                                            </Col>
                                        </Row>
                                    </div>

                                </Col>
                            })
                        }


                        {/* {
                            channels.map((item, i) => {
                                return <Row key={i} className='single-channel-view' style={{ minHeight: "136px" }}>
                                    <Col md={3} xs={5}>
                                        <Avatar src={item.img_url} size={{ xs: 45, sm: 50, md: 55, lg: 65, xl: 70, xxl: 70 }}></Avatar>
                                    </Col>
                                    <Col md={21} xs={19}>

                                        <Row>
                                            <Col span={20}>
                                                <a href={"/channel/" + item.uid + "/" + item.channel_id}>
                                                    <h5 className='cursor'>{item.name}</h5></a>
                                                {/* <p className='desc'>{(item.content)}</p> 
                                                <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }} className="sub-text m-text-ms15 ">{(item.content)}</Paragraph>
                                                <p className='members m-text-ms15  '>
                                                    {
                                                        item.creator !== null ? <> Owner <strong> {item.creator.full_name} </strong> <div className="dot-small mr-2"></div></> : <></>
                                                    }


                                                    Members <strong> {item.members}</strong> </p>
                                            </Col>
                                            <Col span={4} className="text-right pt-3 show-on-channel-hover">

                                                <Tooltip placement="top" title={"Share"}>

                                                    <Button shape="circle" type={'text'} icon={<Export size={22} color="#686464" weight="light" />}></Button>

                                                </Tooltip>

                                            </Col>
                                        </Row>
                                        <Divider></Divider>
                                    </Col>

                                </Row>
                            })
                        } */}

                    </Row>
        }


    </>
    );
}

const mapStateToProps = (state) => ({
    _channels: state.channel.channel_list,
    _channel_details: state.channel.channeldetail,
    _chat: state.channel.channel_chat
});
const mapDispatchToProps = (dispatch) => ({
    NewsFeed: (v) => dispatch(actions.NewsFeed(v)),
    ChannelDetails: (v) => dispatch(actions.ChannelDetails(v)),
    ChannelList: (v) => dispatch(actions.ChannelList(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MyHubChannel)
);
