import { Button, Col, Row, Skeleton, Space, Tag } from "antd";
import { LinkSimple, LinkedinLogo, Newspaper, Note, Calendar, InstagramLogo, TwitterLogo, Notebook, Megaphone, Article } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import useToken from "../../store/useToken"
import axios from "../../store/axios";
import _ from "lodash";

const BusinessAbout = ({ data }) => {
    const [username, setUN] = useState(data?.username);
    const [_data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { token } = useToken();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await axios({
            method: 'get',
            url:  `v1.0/business/${username}/feed_count`,
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data11 = res.data.data
            console.log(data11)
            setData(data11);
        });
        setLoading(false);
    };
    return (
        <Row className="m-minute10-marginx">
            <Col span={24} className="business-about-card md15 m-p10">
                {
                    data?.org_expertises.length > 0 && <Row gutter={36}>
                        <Col xs={8} md={5} lg={3} className="md10">
                            <p className="fs16 fw600"> Segments: </p>
                        </Col>
                        <Col xs={16} md={19} lg={21} className="md10">
                            <Space wrap size={4}>
                                {
                                    data?.org_expertises.map((item, k) => {
                                        return <Tag key={k} style={{ background: '#f0f0f0' }}> {item.name} </Tag>
                                    })
                                }
                            </Space>
                        </Col>
                    </Row>
                }
                {
                    data?.social_links.length > 0 &&
                    <Row gutter={12} className="md10">
                        <Col xs={8} md={5} lg={3} className="md10">
                            <p className="fs16 fw600"> Socials: </p>
                        </Col>
                        <Col xs={16} md={19} lg={21} className="md10">
                            <Space wrap>

                                {
                                    data?.social_links.map((item, k) => {
                                        return <Button href={item.link} key={k} style={{ backgroundColor: '#f0f0f0', padding: '4px 15px', }}
                                            icon={item.name === 'LinkedIn' ? <LinkedinLogo size={18} weight="regular" /> :
                                                item.name === 'Twitter' ? <TwitterLogo size={18} weight="regular" /> :
                                                    item.name === 'Instagram' ? <InstagramLogo size={18} weight="regular" /> :
                                                        <LinkSimple size={18} className="me-2" weight="regular" />}>
                                            {' '} {item.name === 'Website' ? item.link : ''}  </Button>
                                    })
                                }
                            </Space>

                        </Col>
                    </Row>
                }


                <Row className="md30">
                    <Col lg={{ span: 18, offset: 3 }} xs={{ span: 24, offset: 0 }}>
                        {
                            loading ? <Skeleton active /> :
                                <Row gutter={{ lg: 40, md:30, xs: 20 }}>
                                    <Col lg={8} md={12} xs={{ span: 12}}>
                                        <div className="small-card  cursor md15">
                                            <Space size={10}>
                                                <Button className="radius25 m-busiW45 " icon={<Newspaper size={28} color="#000000" weight="light" />} style={{ height: '55px', width: '55px' }}></Button>
                                                <div>
                                                    <h3 className=" fw700 mb-0 "> {_.find(_data?.counts, { entity_type: "Newsletter" })?.count || 0} </h3>
                                                    <p className="fs12 fw300 m-fs10" style={{ color: '#728096' }}> Newsletters  </p>
                                                </div>
                                            </Space>
                                        </div>
                                    </Col>
                                    <Col lg={8} md={12} xs={{ span: 12}}>
                                        <div className="small-card cursor md15">
                                            <Space size={10}>
                                                <Button className="radius25 m-busiW45" icon={<Notebook size={28} color="#000000" weight="light" />} style={{ height: '55px', width: '55px' }}></Button>
                                                <div>
                                                    <h3 className=" fw700 mb-0 "> {_.find(_data?.counts, { entity_type: "Report" })?.count || 0}  </h3>
                                                    <p className="fs12 fw300 " style={{ color: '#728096' }}> Reports  </p>
                                                </div>
                                            </Space>
                                        </div>
                                    </Col>
                                    <Col lg={8} md={12} xs={{ span: 12}}>
                                        <div className="small-card cursor md15">
                                            <Space size={10}>
                                                <Button className="radius25 m-busiW45" icon={<Note size={28} color="#000000" weight="light" />} style={{ height: '55px', width: '55px' }}></Button>
                                                <div>
                                                    <h3 className=" fw700 mb-0 "> {_.find(_data?.counts, { entity_type: "Notes" })?.count || 0} </h3>
                                                    <p className="fs12 fw300 " style={{ color: '#728096' }}> Notes  </p>
                                                </div>
                                            </Space>
                                        </div>
                                    </Col>

                                    <Col lg={8} md={12} xs={{ span: 12}} >
                                        <div className="small-card cursor md15">
                                            <Space size={10}>
                                                <Button className="radius25 m-busiW45" icon={<Article size={28} color="#000000" weight="light" />} style={{ height: '55px', width: '55px' }}></Button>
                                                <div>
                                                    <h3 className=" fw700 mb-0 ">{_.find(_data?.counts, { entity_type: "Post" })?.count || 0} </h3>
                                                    <p className="fs12 fw300 " style={{ color: '#728096' }}> Posts  </p>
                                                </div>
                                            </Space>

                                        </div>
                                    </Col>
                                    {
                                        (_.find(_data?.counts, { entity_type: "Channel" })?.count || 0) > 0 &&
                                        <Col lg={8} md={12} xs={12}>
                                            <div className="small-card cursor md15">
                                                <Space size={10}>
                                                    <Button className="radius25 m-busiW45" icon={<Megaphone size={28} color="#000000" weight="light" />} style={{ height: '55px', width: '55px' }}></Button>
                                                    <div>
                                                        <h3 className=" fw700 mb-0 "> {_.find(_data?.counts, { entity_type: "Channel" })?.count || 0} </h3>
                                                        <p className="fs12 fw300 " style={{ color: '#728096' }}> Channels </p>
                                                    </div>
                                                </Space>
                                            </div>
                                        </Col>
                                    }

                                    <Col lg={8} md={12} xs={{ span:12}} >
                                        <div className="small-card cursor md15">
                                            <Space size={10}>
                                                <Button className="radius25 m-busiW45" icon={<Calendar size={28} color="#000000" weight="light" />} style={{ height: '55px', width: '55px' }}></Button>
                                                <div>
                                                    <h3 className=" fw700 mb-0 ">  {_.find(_data?.counts, { entity_type: "Events" })?.count || 0}  </h3>
                                                    <p className="fs12 fw300 " style={{ color: '#728096' }}> Events  </p>
                                                </div>
                                            </Space>
                                        </div>
                                    </Col>
                                </Row>
                        }
                    </Col>
                </Row>
                <Row>

                </Row>
            </Col>
        </Row>
    );
}
export default BusinessAbout;