import React, { useEffect, useState } from "react";
import { Input, message, Col, Row, Divider, Space, Spin, Button } from "antd";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter, useHistory } from "react-router";
import { AppleLogo, Eye, EyeSlash } from "@phosphor-icons/react";
import { useGoogleLogin } from '@react-oauth/google';
import Toast from "../utils/Toast";

const SignUp = (props) => {
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [name, setName] = useState("");

  const history = useHistory();
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'signup';

  const Register = async (evt) => {
    evt.preventDefault();
    if (name === "" || name === " ") {
      Toast("Enter valid full name", "error");
      return;
    }
    if (email === "") {
      Toast("Enter valid email address", "error");
      return;
    }
    if (password === "") {
      Toast("Enter password!", "error");
      return;
    }

    setLoader(true);
    message.open({
      key,
      type: 'loading',
      content: 'Creating account...',
    })

    await props
      .Register({
        name: name,
        password: password,
        email: email,
      })
      .then(() => {
        message.open({
          key,
          type: 'success',
          content: 'Account created!',
          duration: 3,
        });

        // let route = "/account/user/profile"
        // props.Profile(route).then(() => {
        //   localStorage.setItem("profile", JSON.stringify(props._profile))
        // });
        history.push("/onboard", {
          verified: false,
          is_preferanced: props._register.is_preferanced
        });

      }).catch(err => {
        message.open({
          key,
          type: 'error',
          content: err.response.data.message
        });
        setLoader(false);
      });
    setLoader(false);
  }

  useEffect(() => {
    if (props._login_social !== null) {
      messageApi.open({
        key,
        type: 'success',
        content: 'Account created!',
        duration: 3
      });
      setLoader1(false)

      if (props._login_social.account_step === "_onboard") {
        history.push("/onboard", {
          verified: false,
        });
      }
      else if (props._login_social.is_preferanced === false) {
        history.push("/preference", {
          verified: false,
        });
      }
      else {
        sessionStorage.setItem("active", props._login_social.is_email_verified)
        history.push("/dashboard", {
          verified: false,
        });
      }

    }

  }, [props._login_social])


  const handleLogin = async (credentialResponse) => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Signing up...',
    });

    const payload = {
      code: credentialResponse.code
    }
    await props.LoginSocial(payload);

  }
  const loginGoogle = useGoogleLogin({
    onSuccess: handleLogin,
    flow: "auth-code",
    ux_mode: "popup",
    select_account: true,
    scope: "email profile",
  });


  return (
    <Row justify={"center"}>
      <Col lg={12} xs={24} className='md70  m-hide m-padding20'>
        <Row>
          <Col lg={{ span: 24 }} xs={{ span: 24 }} className="text-center">
            <h5 className="login-heading-text mb-0"> Create an account </h5>
          </Col>
          <Col span={24} className="text-center">
            <p className="sub-text md10"> Already  have  an  account ? <a href="/login" className="blue-text cursor">Sign In</a></p>
          </Col>
          <Col lg={{ span: 16, offset: 4 }} xs={{ span: 22, offset: 1 }} className="md70">
            <Row>
              <Col span={20} offset={2}>
                <form className="row" onSubmit={(evt) => Register(evt)}>
                  < Col span={24}>
                    <label>Full Name</label> <br></br>
                    <Input
                      size="large"
                      type="text"
                      autoComplete="off"
                      className="logininput md5"
                      required
                      placeholder="Full Name"
                      value={name}
                      onChange={(evt) =>
                        setName(evt.target.value)
                      }
                    />
                  </Col>

                  <Col span={24} className="md20">
                    <label>Email</label> <br></br>
                    <Input
                      size="large"
                      type="email"
                      autoComplete="off"
                      className="logininput md5"
                      required
                      placeholder="Email address"
                      value={email}
                      onChange={(evt) =>
                        setEmail(evt.target.value)
                      }
                    />
                  </Col>

                  <Col span={24} className=" md20">
                    <label>Password</label> <br></br>
                    <Input.Password
                      minLength={8}
                      size="large"
                      className="md5"
                      autoComplete="off"
                      required
                      maxLength={20}
                      value={password}
                      onChange={(evt) =>
                        setpassword(evt.target.value)
                      }
                      iconRender={(visible) =>
                        visible ? <Eye size={20} color="#999999" weight="light" /> : <EyeSlash size={20} color="#999999" weight="light" />
                      }
                      placeholder="Password"
                      type="password"
                    />

                  </Col>

                  <Col span={24} className="md45  ">
                    <p className="fs13 fw400">
                      By signing up, you confirm that you’ve read and accepted our <span style={{ textDecoration: 'underline' }}>Terms & Conditions</span> and <span style={{ textDecoration: 'underline' }}>Privacy Policy</span> </p>
                  </Col>
                  <Col span={24} className="md25 mb-2">
                    {contextHolder}
                    <Button
                      disabled={loader}
                      type="primary"
                      block size="large"
                      htmlType="submit"
                      className="m-bottom"
                    >
                      Sign Up
                    </Button>
                  </Col>


                </form>
              </Col>
              <Col span={20} offset={2}>
                <Row gutter={24}>
                  <Col lg={{ span: 12, offset: 0 }} xs={{ span: 24, offset: 0 }} className=" md25 ">
                    <div className="div-img  cursor" onClick={() => { setLoader1(true); loginGoogle(); }}>
                      <Space>
                        {
                          loader1 ? <Spin /> : <>
                            <img src={require('../../assets/images/login/google.png')} height={20} />
                            <p className="fs13 fw400 ">Sign up with Google</p>
                          </>
                        }
                      </Space>
                    </div>
                  </Col>
                  <Col lg={{ span: 12, offset: 0 }} xs={{ span: 24, offset: 0 }} className=" md25 ">
                    <div className="div-img  cursor">
                      <Space>
                        {
                          loader1 ? <Spin /> : <>
                            <AppleLogo size={20} color="#000000" weight="fill" />
                            <p className="fs13 fw400 ">Sign up with Apple</p>
                          </>
                        }
                      </Space>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Col>
        </Row>
      </Col>



    </Row >
  )
};

const mapStateToProps = (state) => ({
  _register: state.register.register_details,
  _profile: state.publics.profile,
  _login_social: state.login.login_social
});
const mapDispatchToProps = (dispatch) => ({
  Register: (v) => dispatch(actions.Register(v)),
  Profile: (v) => dispatch(actions.Profile(v)),
  LoginSocial: (v) => dispatch(actions.LoginSocial(v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
