import React from "react";
import { Row, Col, Avatar, Tag, Space } from "antd";
import Carousel from 'better-react-carousel'
import ChannelFooter from "./ChannelFooter";


const ChannelMember = ({ memberdata, data }) => {
    return (
        <Row style={{}}>
            <Col span={24} className="" style={{ borderLeft: '1px solid  #F5F5F5', padding: '10px' }}>
                {
                    memberdata && <Row>
                        <Col span={24} className="trending-card">

                            <p className="fs13 fw600"> Most Active Members </p>

{
    memberdata.length !== 0 ?  <div className='md10'>
    <Carousel cols={3} rows={1} gap={10} loop className="carousel-btn">
        {
            memberdata.map((i, j) => {
                return <Carousel.Item key={j}>
                    <div className="text-center">
                        <Avatar size={40} src={i?.dp} />
                        <p className="fs12 text-black md10">{i?.name}</p>
                        <p className="fs10 text-grey fw300">@{i?.username}</p>
                    </div>
                </Carousel.Item>
            })
        }
    </Carousel>
</div> :  <div className='md10'>
                                <p className={"sub-text"}>No Active Member Available</p>
                            </div>
}

                           
                        </Col>
                    </Row>
                }

                <Row>
                    {
                        data && data?.category && data?.category.length !== 0 && <Col span={24} className="trending-card md20">
                        <p className="fs13 fw600"> Channel Tags </p>
                        <div className="new-dashb-tag dashboard-tag-scroll md10">
                            <Space size={5} wrap>
                            {
                                data && data?.category.map((i, j) => {
                                    return <Tag key={j}>{i?.name}</Tag>
                                })
                            }
                            </Space>
                            {/* <Button type="primary
                            "> see more </Button> */}
                        </div>
                    </Col>
                    }
                    
                    <Col span={20} offset={2} className="md20 mb-3 ">
                        <ChannelFooter />
                    </Col>

                </Row>

            </Col>
        </Row>
    )
}
export default ChannelMember;