import React from "react";
import "./Newsletter.scss";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { Input, message, Space, Select, Col, Row, Tooltip, Modal, Divider } from "antd";
import { Upload, Button } from 'antd';
import Cookies from "js-cookie"
import { useHistory } from "react-router";
import _ from "lodash";
import { useState } from "react";
import axios from "../../store/axios";
import NewsletterEditor from "../Editor/NewsletterEditor";
import { ArrowLeft, CloudArrowUp, Sliders } from "@phosphor-icons/react";
import ContentAdvancedFeature from "../utils/ContentAdvancedFeature";

import Toast from "../utils/Toast";

const { Option } = Select

const CreateResarchReport = () => {

  const [file, setFile] = useState("");
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState("");
  const [sub_title, setSubTitle] = useState("");
  const [total_tags, setTotalTags] = useState([])
  const [children, setchildren] = useState([])
  const [loader, setLoader] = useState(false)
  const [uploading, setUploading] = useState(false)
  const history = useHistory();
  const [advancedPayload, setAdvancedPayload] = useState({});
  const [modal, setModal] = useState(false);

  const handleChange = (value) => {
    if (value.length >= 6) {
      return
    }
    setTotalTags(value);
  };

  async function createReport(isDraft) {
    if (title === "") {
      Toast("Enter report title!", "error")
      return
    }
    // if (content === "") {
    //   Toast("Provide Details")
    //   return
    // }
    setLoader(true)
    let _data = {
      "title": title,
      "content": content,
      "sub_title": sub_title,
      "tags": total_tags,
      "file_url": file,
      'publish': !isDraft,
      ...advancedPayload
    }
    await axios({
      method: 'post',
      url:  "market/feed/rr",
      data: _data,
      headers: {
        Authorization: Cookies.get("Logintoken")
      }
    }).then((res) => {
      sessionStorage.removeItem("image");
      Toast("Created Successfully!", "success")
      history.push(`/feed-view/${res.data.data.id}`);
    }).catch((error) => {
      Toast(error.response.data.message, "error")
    });

    setLoader(false)

  }
  const handelSearch = (evt) => {
    axios({
      method: 'get',
      url:  'market/tag/search?key=' + evt,

      headers: {
        Authorization: Cookies.get("Logintoken"),
      },
    }).then((res) => {
      setchildren(res?.data?.data)
    }).catch((error) => {
      Toast(error.response.data.message, "error")
    });
  }


  const props1 = {
    name: "file",
    accept: ".pdf,.docx,.doc",

    action:  "account/file/upload",
    data: { type: "rr" },
    headers: {
      Authorization: Cookies.get("Logintoken"),

    },
    onRemove: (file) => {
      setFile("")
    },
    onChange(info) {
      if (info.file.status === "uploading") {
        setUploading(true);
      }
      if (info.file.status === "done") {
        setUploading(false);
        setFile(info.file.response.data.url)
      } else if (info.file.status === "error") {
        Toast(`${info.file.name} file upload failed.`, "error");
      }
    },
  };

  const uploadButton = (
    <Upload {...props1}>
      <Tooltip title={<ul>
        <li className="fs12 fw400">Files must not exceed 5 MB</li>
        <li className="fs12 fw400">Only .doc, .docx or PDF file will be accepted</li>
        <li className="fs12 fw400">File should not password protected</li>
      </ul>
      }>
        <Button loading={uploading} type="dashed" className="upload-report-btn text" >Add Attachment</Button>
      </Tooltip>
    </Upload>
  )
  return (

    <Row className="md50" style={{ minHeight: "calc(100vh - 69px)" }}>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 14, offset: 5 }} lg={{ span: 14, offset: 5 }}>

        <Row>
          <Col xs={{ span: 14 }} md={{ span: 18 }} lg={{ span: 18 }} className=" md20">
            <Space size={0}>
              <Button onClick={() => history.goBack()} type="text" icon={<ArrowLeft size={20} />}></Button>
              <p className=" fs16 mt-1">Create Report</p>
            </Space>
          </Col>
        </Row>
        <Row gutter={20} className="md10">
          <Col md={16} xs={13}>
            <Row>
              <Col span={24} className=" md10">
                <Input maxLength={100} className="" placeholder="Enter report title" value={title} onChange={(evt) => setTitle(evt.target.value)} />
              </Col>
              <Col span={24} className="md20">
                <Input.TextArea rows={3} maxLength={200} placeholder="Enter some description" value={sub_title} onChange={(evt) => setSubTitle(evt.target.value)} />
              </Col>
              <Col span={24} className=" nopadding md20">
                <Select
                  mode="tags"
                  bordered={true}
                  size="large"
                  allowClear
                  style={{
                    width: '100%',
                  }}
                  placeholder="Enter tags"
                  value={total_tags}
                  maxTagCount={5}
                  onChange={(evt) => handleChange(evt)}
                  onSearch={(evt) => handelSearch(evt)}
                >
                  {children.map((item) => {
                    return <Option value={item}>{item}</Option>
                  })}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col md={8} xs={11} >
            <Row>
              <Col span={24} className="text-right">
                <Space>
                  <Tooltip title="Save to draft">
                    <Button type="dashed" loading={loader} className="fs14" size={{ xs: "small", sm: "small", md: "large", }} onClick={() => createReport(false)} icon={<CloudArrowUp size={20} weight='regular' />}> </Button>
                  </Tooltip>
                  <Button type="primary" loading={loader} className="fs14" size={{ xs: "small", sm: "small", md: "large", }} onClick={() => createReport(true)}>Publish  </Button>
                </Space>
              </Col>
            </Row>
            <Row className="md30">
              <Col span={24} className="text-right">

                <Button className="p-0" type="text" onClick={() => setModal(true)}
                > More Options </Button>
              </Col>
            </Row>

          </Col>
        </Row>
        <Row>
          <Col span={24} className='mb-5 md20'>
            <NewsletterEditor tools={{ attachment: false, history: false }} uploadFile={uploadButton} onChange={(html) => setContent(html)} />
          </Col>
        </Row>


        <Modal open={modal} onCancel={() => setModal(false)} maskClosable={false} footer={[
          <Button type="dashed" onClick={() => setModal(false)}>  Close </Button>]}>
          <Row className="padding20">
            <Col span={24}>
              <Space>
                <Sliders size={20} />
                <p className="fs16 fw600">Settings</p>
              </Space>
              <Divider className="md10" />
            </Col>

            <Col span={24} style={{ marginBottom: "20px" }} >
              <ContentAdvancedFeature onUpdate={(val) => setAdvancedPayload(val)} />
            </Col>
          </Row>
        </Modal>

      </Col>


    </Row>
  );
}

const mapStateToProps = (state) => ({
  _channels: state.channel.channel_list,
});
const mapDispatchToProps = (dispatch) => ({
  ChannelList: (v) => dispatch(actions.ChannelList(v)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateResarchReport)
);
