import React from "react";
import { Col, Row, Space, Divider, Input, Button, Checkbox, message, List, Avatar, Upload, Modal, Select } from "antd";
import '../Campus/campus.scss';
import { Note, UploadSimple, UserPlus, Users, X } from "@phosphor-icons/react";
import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import { useState } from "react";
import axios from "../../store/axios";
import { LoadingOutlined } from "@ant-design/icons";
import request from "../../store/request";
import { useHistory, useLocation } from "react-router-dom";
import useToken from "../../store/useToken";
import ReactPlayer from "react-player";

import Toast from "../utils/Toast";

const { Option } = Select;

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUploadT = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
       Toast('You can only upload JPG/PNG file!',"error");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
       Toast('Image must smaller than 2MB!',"error");
    }
    return isJpgOrPng && isLt2M;
};
const beforeUploadB = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
       Toast('You can only upload JPG/PNG file!',"error");
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
       Toast('Image must smaller than 4MB!',"error");
    }
    return isJpgOrPng && isLt2M;
};

const CreateCampusV2 = () => {
    const history = useHistory();
    const location = useLocation();

    const [loader, setLoader] = useState(false);
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [module_desc, setModuleDescription] = useState(null);
    const [categories, setCategories] = useState([]);
    const [programs, setPrograms] = useState([1]);
    const [video_url, setVideoUrl] = useState(null);
    const [topics, setTopics] = useState([]);
    const [check, setCheck] = useState(false);
    const [children, setChildren] = useState([]);
    const [campusdata, setCampusData] = useState(null);

    // testimonials
    const [testimonialModal, setTestimonialModal] = useState(false);
    const [testimonials, setTestimonials] = useState([]);
    const [loadingT, setLoadingT] = useState(false);
    const [imageUrlT, setImageUrlT] = useState("");
    const [avatarT, setAvatarT] = useState("");
    const [nameT, setNameT] = useState("");
    const [descT, setDescT] = useState("");

    // Modules/Benefits
    const [benefitModal, setBenefitModal] = useState(false);
    const [modules, setModules] = useState([]);
    const [nameM, setNameM] = useState("");
    const [catIdM, setCatIdM] = useState(null);

    // banner 1
    const [banner1_url, setBanner1Url] = useState("");
    const [loadingB1, setLoadingB1] = useState(false);
    const [blobB1, setBlobB1] = useState("");
    // banner 2
    const [banner2_url, setBanner2Url] = useState("");
    const [loadingB2, setLoadingB2] = useState(false);
    const [blobB2, setBlobB2] = useState("");
    // banner 3
    const [banner3_url, setBanner3Url] = useState("");
    const [loadingB3, setLoadingB3] = useState(false);
    const [blobB3, setBlobB3] = useState("");

    const [messageApi, contextHolder] = message.useMessage();

    const key = 'campusCreate';
    const { token } = useToken();


    useEffect(() => {
        axios({
            method: 'get',
            url:  "v1.0/campus",
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data
            setCampusData(data);
            if (data?.name === null || data?.name === undefined){
                return
            }
            setName(data?.name)
            setDescription(data?.description);
            setBanner1Url(data?.banner1_url);
            setBanner2Url(data?.banner2_url);
            setBanner3Url(data?.banner3_url);
            setBlobB1(data?.banner1_url);
            setBlobB2(data?.banner2_url);
            setBlobB3(data?.banner3_url);
            setVideoUrl(data?.video_url);
            setTestimonials(data?.testimonials);
            setModules(data?.benefits);
            setTopics(data?.topics);
            setModuleDescription(data?.module_description)
            let arr = []
            for (let i=1;i<=data?.programs.length;i++){
                arr.push(i);
            }
            if (arr.length > 0){
                setPrograms(arr);
            }
        })
    }, []);

    useEffect(() => {
        axios({
            method: 'get',
            url:  "v1.0/membership/benefit/category",
            headers: {
                Authorization: token
            }
        }).then(res => {
            const data = res.data.data;
            let arr = []
            for (let i = 0; i < data.length; i++) {
                arr.push({
                    value: data[i].category_id,
                    label: data[i].title,
                })
            }
            setCategories(arr);
        })
    }, []);

    const createCampus = async () => {
        if (token === false){
            history.push(`/login?redirect=${location.pathname}`)
        }
        if (name === "" || name === " " ||name === null) {
           Toast("Please enter valid name of campus!","error")
            return
        }
        if (description === "" || description === " "|| description === null) {
           Toast("Please write a short description about this campus!","error")
            return
        }
        if (banner1_url === "") {
           Toast("Please upload main banner image!")
            return
        }
        if (modules.length < 3) {
           Toast("Please add at-least three modules; you are offering!","error")
            return
        }
        if (!check) {
           Toast("Check our terms & condition","error")
            return
        }
        let data = {
            "name": name,
            "description": description,
            "topics": topics,
            "banner1_url": banner1_url,
            "banner2_url": banner2_url,
            "banner3_url": banner3_url,
            "video_url": video_url,
            "module_desc": module_desc,
            "programs": programs,
            "testimonials": testimonials,
            "modules": modules
        }
        
        messageApi.open({
            key,
            type: 'loading',
            content: 'Updating campus...',
        });
        setLoader(true);
        await axios({
            method: 'post',
            url:  "v1.0/campus",
            data: data,
            headers: {
                Authorization: token
            }
        }).then(() => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Campus updated!',
                duration: 3,
            });
            history.push('/campus/me');
            return
        }).catch(()=>{
        setLoader(false);
        })
        setLoader(false);
    }


    const handelSearch = (evt) => {
        axios({
            method: 'get',
            url:  'market/tag/search?key=' + evt,

            headers: {
                Authorization: token,
            },
        }).then((res) => {
            setChildren(res?.data?.data)
        }).catch((error) => {
           Toast(error.response.data.message,"error")
        });
    }

    const handleChange = (value) => {
        setTopics(value)
    };
    const props1 = {
        action:  "account/file/upload",
        headers: {
            Authorization: token,

        },
        customRequest: (options) => request.customRequest(options, "camp"),
    };

    const propsImg1 = {
        name: 'file',
        multiple: false,
        accept : ".png,.jpeg,.jpg",
        ...props1,
        onChange(info) {
            if (info.file.status === 'uploading') {
                // console.log(info.file, info.fileList);
                setLoadingB1(true);
                return;
            }
            if (info.file.status === 'done') {
                setBanner1Url(info.file.response.data.url);
                Toast(`${info.file.name} file uploaded.`,"success");
                getBase64(info.file.originFileObj, (url) => {
                    setLoadingB1(false);
                    setBlobB1(url);
                });
            }
            else if (info.file.status === 'error') {
               Toast(`${info.file.name} file upload failed.`,"error");
            }
        }
    };
    const propsImg2 = {
        name: 'file',
        multiple: false,
        accept : ".png,.jpeg,.jpg",
        ...props1,
        onChange(info) {
            if (info.file.status === 'uploading') {
                // console.log(info.file, info.fileList);
                setLoadingB2(true);
                return;
            }
            if (info.file.status === 'done') {
                setBanner2Url(info.file.response.data.url);
                Toast(`${info.file.name} file uploaded.`,"success");
                getBase64(info.file.originFileObj, (url) => {
                    setLoadingB2(false);
                    setBlobB2(url);
                });
            }
            else if (info.file.status === 'error') {
               Toast(`${info.file.name} file upload failed.`,"error");
            }
        }
    };
    const propsImg3 = {
        name: 'file',
        multiple: false,
        accept : ".png,.jpeg,.jpg",
        ...props1,
        onChange(info) {
            if (info.file.status === 'uploading') {
                // console.log(info.file, info.fileList);
                setLoadingB3(true);
                return;
            }
            if (info.file.status === 'done') {
                setBanner3Url(info.file.response.data.url);
                Toast(`${info.file.name} file uploaded.`,"success");
                getBase64(info.file.originFileObj, (url) => {
                    setLoadingB3(false);
                    setBlobB3(url);
                });
            }
            else if (info.file.status === 'error') {
               Toast(`${info.file.name} file upload failed.`,"error");
            }
        }
    };

    const options = [
        {
            label: 'Master Program',
            value: 1,
            disabled: true,
            checked: true
        },
        {
            label: 'Workshops',
            value: 2,
        },
        {
            label: 'Certification',
            value: 3,
        },
    ];
    const masterPrograms = ['Master Program', 'Workshops', 'Certification'];

    const onChangeProgram = (checkedValues) => {
        setPrograms(checkedValues);
    };
    const testimonialsAdd = () => {
        if (nameT === "" || descT === "") {
            return
        }
        let s = testimonials;
        s.push({ avatar: avatarT, name: nameT, description: descT });
        setTestimonials(s);
        setNameT(""); setDescT(""); setAvatarT(""); setImageUrlT(null);
        setTestimonialModal(false);
    };
    const modulesAdd = () => {
        if (catIdM === null || nameM === "") {
           Toast("Provide details","error")
            return
        }
        let s = modules;
        s.push({ category_id: catIdM, name: nameM });
        setModules(s);
        setCatIdM(null); setNameM("");
        setBenefitModal(false);
    };
    const testimonialsDelete = (index) => {
        setTestimonials(testimonials.filter((v, i) => i !== index));
    };
    const modulesDelete = (index) => {
        setModules(modules.filter((v, i) => i !== index));
    };

 

    const uploadButton = (
        <div>
            {loadingT ? <LoadingOutlined /> : <UploadSimple />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const handleChangeImgT = (info) => {
        if (info.file.status === 'uploading') {
            setLoadingT(true);
            return;
        }
        if (info.file.status === 'done') {
            setAvatarT(info.file.response.data.url)
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoadingT(false);
                setImageUrlT(url);
            });
        }
    };

    const onCHangeBenefits=(v, option)=>{
        setCatIdM(v);
        setNameM(option.label)
    }


    return (

        <Row>
            <Col md={8} xs={24} className="padding20">
                <Row>
                    <Col span={24} className=''>
                        <p className="fs20 fw600">Create your Campus</p>
                    </Col>
                    <Col span={24}>
                        <Divider></Divider>
                    </Col>
                    <Col span={24} className='md1hjg0 campus-input'>
                        <Input max={50} maxLength={50} value={name}
                            onChange={(evt) => setName(evt.target.value)} placeholder="Name of Campus" type="text" />
                    </Col>
                    <Col span={24} className='md20 '>
                        <TextArea max={500} showCount maxLength={500} value={description}
                            onChange={(evt) => setDescription(evt.target.value)} placeholder="Description..."> </TextArea>
                    </Col>
                    <Col span={24} className='md20 '>

                        <Upload
                            name="avatar-testi"
                            listType="picture-card"
                            className="avatar-uploader upload-block"
                            showUploadList={false}
                            beforeUpload={beforeUploadB}
                            {...propsImg1}>
                            {blobB1 ? (
                                <img
                                    src={blobB1}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                <div>
                                    {loadingB1 ? <LoadingOutlined /> : <UploadSimple />}
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload Banner 1
                                    </div>
                                </div>
                            )}
                            {/* <p className="ant-upload-drag-icon">
                                <UploadSimple size={24} />
                            </p>
                            <p className="ant-upload-hint">
                                Upload banner 1
                            </p> */}
                        </Upload>
                    </Col>
                    <Col span={24} className='md20 checkbox'>
                        <Checkbox.Group options={options} value={programs} defaultValue={[1]} onChange={onChangeProgram} />

                    </Col>
                    <Col span={24} className='md20'>
                        <p className="fs16 fw600">Testimonials</p>

                        <div className="campus-blue-card md20">
                            <List
                                dataSource={testimonials}
                                renderItem={(item, i) => (
                                    <List.Item key={i}
                                        actions={[<Button onClick={() => testimonialsDelete(i)} type="text" icon={<X size={20} />}></Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.avatar} />}
                                            title={<span className="fw600">{item.name}</span>}
                                            description="Instagram"
                                        />
                                    </List.Item>
                                )}
                            />
                            <Button block icon={<UserPlus size={18} style={{ marginRight: "5px" }} />}
                                onClick={() => setTestimonialModal(true)}> Add more</Button>

                        </div>

                    </Col>

                    <Col span={24} className='md20'>
                        <p className="fs16 fw600">Master Program</p>

                        <Row gutter={10} className='md20'>
                            <Col xs={24} md={12}>
                                <Upload
                                    name="avatar-testi"
                                    listType="picture-card"
                                    className="avatar-uploader upload-block"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadB}
                                    {...propsImg2}>
                                    {blobB2 ? (
                                        <img
                                            src={blobB2}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) : (
                                        <div>
                                            {loadingB2 ? <LoadingOutlined /> : <UploadSimple />}
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                }}
                                            >
                                                Upload banner 2
                                            </div>
                                        </div>
                                    )}

                                </Upload>
                            </Col>
                            <Col xs={24} md={12}>
                                <Upload
                                    name="avatar-testi"
                                    listType="picture-card"
                                    className="avatar-uploader upload-block"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadB}
                                    {...propsImg3}>
                                    {blobB3 ? (
                                        <img
                                            src={blobB3}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) : (
                                        <div>
                                            {loadingB3 ? <LoadingOutlined /> : <UploadSimple />}
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                }}
                                            >
                                                Upload banner 3
                                            </div>
                                        </div>
                                    )}

                                </Upload>
                            </Col>
                        </Row>

                    </Col>

                    <Col span={24} className='md20'>
                        <p className="fs16 fw600">Modules</p>

                        <div className="campus-blue-card md20">
                            <List
                                dataSource={modules}
                                renderItem={(item, i) => (
                                    <List.Item key={i}
                                        actions={[<Button type="text" onClick={() => modulesDelete(i)} icon={<X size={20} />}></Button>]}
                                    >
                                        <List.Item.Meta
                                            avatar={<Note size={20} />}
                                            title={<span style={{ color: "#013238" }}>{item.name}</span>}
                                        />
                                    </List.Item>
                                )}
                            />
                            <Button onClick={() => setBenefitModal(true)} block icon={<UserPlus size={18} style={{ marginRight: "5px" }} />}> Add more</Button>


                        </div>

                    </Col>
                    <Col span={24} className='md20'>
                        <p className="fs16 fw600">Paste Module Introduction Video URL</p>
                        <Row>
                            <Col span={24} className="md10">
                                {/* <Upload {...propsVideo}>
                                    <Button icon={<UploadSimple />}>Upload Video</Button>
                                </Upload> */}
                                <Input value={video_url} placeholder="Paste Video URL" onChange={(evt)=>setVideoUrl(evt.target.value)}></Input>
                                <small className="mb-1 fs12 text-grey">Should be valid URL.</small>
                            </Col>
                            
                        </Row>
                        <TextArea className="md15" rows={4} max={1000} showCount maxLength={1000} value={module_desc}
                            onChange={(evt) => setModuleDescription(evt.target.value)} placeholder="A short description"> </TextArea>


                    </Col>
                    <Col span={24} className='md20 '>

                        <Select
                            mode="tags"
                            bordered={true}
                            allowClear
                            style={{
                                width: '100%',
                                marginTop: "15px", borderRadius: "4px"
                            }}
                            placeholder="Enter tags"
                            value={topics}
                            onChange={(evt) => handleChange(evt)}
                            onSearch={(evt) => handelSearch(evt)}
                        >
                            {children.map((item) => {
                                return <Option value={item}>{item}</Option>
                            })}
                        </Select>
                    </Col>


                    <Col span={24} className='md60 text-center '>

                        <p className="fs14 fw400 checkbox"> <Checkbox checked={check} onChange={(evt) => setCheck(evt.target.checked)} className="margin-right">You’re agree with <span className="blue-text">terms & condition  </span></Checkbox>
                        </p>

                    </Col>
                    <Col span={24} className='md30 text-center '>
                        {contextHolder}
                        <Button loading={loader} onClick={()=>createCampus()} type="primary">Create Campus</Button>
                    </Col>
                </Row>
            </Col>
            <Col md={16} xs={0}>
                <Row>
                    <Col span={24} className="camp-bg-img" style={{
                        height: "450px",
                        background: `linear-gradient(92.89deg, rgba(0, 0, 0, 0.88) 2.06%, rgba(0, 0, 0, 0) 98.29%), url(${blobB1})`
                    }}>
                        <Row>
                            <Col span={20} offset={2} className="md100">
                                <Space>
                                    <Avatar shape="square" size={20} src={campusdata?.icon} alt="" />
                                    <p className="fs14 fw400 text-white">{campusdata?.creator_name}’s</p>
                                </Space>
                                <h1 className="fw600 fs28 md5 text-white" style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{name}</h1>
                                <p className="fs12 fw400 text-white">{description}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} className="camp-gry-crd">
                        <Row className="mb-5">
                            <Col span={20} offset={2}>
                                <Row gutter={42} style={{ marginTop: '-160px' }}>
                                    {
                                        programs && programs.map((i, j) => {
                                            return <Col key={j} span={8}>
                                                <div className="camp-card padding20" style={{ minHeight: "200px" }}>
                                                    <h5 className="text-white">{masterPrograms[i - 1]}</h5>

                                                    <div className="text-center md65">
                                                        <Button type="primary" className=""> Know More </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        })
                                    }
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={24} className="md70">
                                <h5 className="text-center" style={{ color: "#013238" }}> Testimonials</h5>
                            </Col>
                            <Col span={18} offset={3} className="text-center md55">
                                <Row gutter={28}>
                                    {
                                        testimonials && testimonials.map((i, j) => {
                                            return <Col span={8} key={j}>
                                                <Avatar size={100} src={i.avatar} />
                                                <p className="fs12 fw400 md10">{i.description} </p>
                                            </Col>
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>

                    </Col>

                    <Col span={24} className="md100">
                        <Row>
                            <Col span={10} >
                                <div className="head-banners" style={{ height: "263px", backgroundImage: `url(${blobB2})` }}></div>
                                <div className="head-banners" style={{ height: "147px", backgroundImage: `url(${blobB3})` }}></div>
                            </Col>
                            <Col span={14}>
                                <div className="head-banners" style={{ height: "410px", backgroundImage: `url(${blobB1})` }}></div>
                            </Col>
                            <div style={{ position: "absolute", background: "linear-gradient(92.89deg, rgba(0, 0, 0, 0.88) 2.06%, rgba(0, 0, 0, 0) 98.29%)", width: "100%" }}>
                                <div style={{ height: "410px", width: "100%" }}>
                                    <Row>
                                        <Col span={20} offset={2}>
                                            <Row justify="end" align={'middle'} style={{ height: "410px" }}>
                                                <Col span={7} flex={'end'}>
                                                    <div className="camp-card padding20" style={{ minHeight: "200px" }}>
                                                        <h5 className="text-white">Master Program</h5>

                                                        <div className="text-center md65">
                                                            <Button type="primary" className="" disabled> Join Now </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </Row>
                    </Col>

                    <Col span={20} offset={2} className="md100">
                        <h5 style={{ color: "#013238" }}>  Know more this Campus </h5>
                        <Row gutter={24} className="md25">
                            {
                                modules && modules.slice(0, 3).map((i, j) => {
                                    return <Col span={8} key={j}>
                                        <div className="camp-program ">
                                            <div className="bg-img2" style={{ height: "100px", width: "100px" }}>
                                                <Users size={30} color="#3B6BE3" weight="fill" style={{ marginLeft: '35px', }} className="md30" /> </div>
                                            <p className="fs16 fw600 my-2"> {i.name} </p>


                                        </div>
                                    </Col>
                                })
                            }
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row className="campus-enroll md100">
                            <Col span={24}>
                                <Row gutter={30} className="md20">
                                    <Col span={15}>
                                        <Row>
                                            <Col span={24} className="modul-bg text-center">
                                                {
                                                    video_url  && <ReactPlayer controls url={video_url} height={"360px"}></ReactPlayer>
                                                }

                                                {/* <Button type="text" className="md200" icon={<PlayCircle size={52} color="#010101" weight="fill" />}> </Button> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={9} >
                                        <Row style={{ background: 'rgba(255, 255, 255, 0.44)', borderRadius: '24px', padding: '30px' }}>
                                            <Col span={24}>
                                                <p className="fw600" style={{ color: "#013238" }}> Modules </p>
                                                <List
                                                    itemLayout="horizontal"
                                                    className="list-padd"
                                                    dataSource={modules}
                                                    renderItem={(item) => (
                                                        <List.Item>
                                                            <List.Item.Meta
                                                                avatar={<Note size={20} />}
                                                                title={<a >{item.name}</a>}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24} className="md30">
                                        <p className="fs16 fw400" style={{ color: '#4B5669' }}>{module_desc}</p>
                                    </Col>
                                </Row>
                            </Col>


                        </Row>
                    </Col>
                </Row>
            </Col>
            <Modal title="Add testimonial" open={testimonialModal}
                onCancel={() => setTestimonialModal(false)} okText="Add" onOk={testimonialsAdd}>
                <Row className="md30">
                    <Col span={20} offset={2}>
                        <Space size={20}>
                            <Upload
                                name="avatar-testi"
                                listType="picture-circle"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUploadT}
                                onChange={handleChangeImgT}
                                {...props1}
                            >
                                {imageUrlT ? (
                                    <img
                                        src={imageUrlT}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>

                            <Input max={30} maxLength={30} value={nameT}
                                onChange={(evt) => setNameT(evt.target.value)} placeholder="Username" />
                        </Space>

                        <TextArea className="md20" max={150} showCount maxLength={150} value={descT}
                            onChange={(evt) => setDescT(evt.target.value)} placeholder="Description..."> </TextArea>

                        <div className="mb-5"></div>
                    </Col>

                </Row>
            </Modal>
            <Modal title="Add module" open={benefitModal}
                onCancel={() => setBenefitModal(false)} okText="Add" onOk={modulesAdd}>
                <Row className="md30">
                    <Col span={20} offset={2}>
                        <Select
                            defaultValue=""
                            value={catIdM}
                            style={{
                                width: "100%",
                            }}
                            placeholder="Select..."
                            onChange={(v, op) => onCHangeBenefits(v, op)}
                            options={categories}
                        />

                        <Input className="md20" max={30} maxLength={30} value={nameM}
                            onChange={(evt) => setNameM(evt.target.value)} placeholder="E.g., Premium Newsletter" />

                        <div className="mb-5"></div>
                    </Col>

                </Row>
            </Modal>
        </Row>

    );
}
export default CreateCampusV2;