import React from "react";
import { Row, Col, Button, Tag, Badge, Avatar} from 'antd';
import moment from "moment";

const LiveBody = ({ item, details }) => {
    return <Row className="live-main-card">

    <Col span={24} className="text-right" style={{ paddingLeft: "0" }}>
        <span className="sub-text fs12">{moment(item?.published_at).fromNow()}</span>{" "}
    </Col>

    <Col span={16} offset={4} style={{ padding: "20px" }}>
        <div className="image">
            <div className="blurry">
                <div>
                    <Tag > <Badge status="processing" />{item?.entity_type}</Tag>{" "}
                    <Avatar src={item?.streamer?.avatar_url} size={45}></Avatar>
                    <p className="fs-24" style={{ color: "white" }}>{item?.streamer?.full_name} is Live Now</p>
                    <Button type="primary" href={"/go-live/" + item?.channel_name}>Watch Now</Button>


                </div>
            </div>
        </div>
    </Col>

</Row>
}
export default LiveBody;