import React from "react";
import { Avatar, Input } from "antd"

const CoinInput = ({ value, onChange, className = "" }) => {

    const onChangeCoins = (val) => {
        if (val < 0 || val > 20000) {
            return
        }
        onChange(val);
    }

    return <Input type={"number"} value={value} onChange={(e)=>onChangeCoins(e.target.value)}
        prefix={<Avatar style={{ border: "none" }} size={24} src={require('../../assets/images/newdashboard/sylloCoin.svg').default} />}
        placeholder="499" className={`${className} prefix-icons`} suffix={<span className="text-grey">Coins</span>} />
}

export default CoinInput;