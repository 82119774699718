import React, { useState, useEffect } from "react";
import { Avatar, Row, Col, Button, Tabs, Skeleton, Space, Drawer, Affix, Tooltip, Tag, Dropdown } from "antd";
import About from "./About";
import NewChannels from "./NewChannels";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import { useHistory, useParams, withRouter } from "react-router";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import NewsFeed from "../NewsFeed/NewsFeed";
import { RWebShare } from "react-web-share";
import Mentorship from "./Mentorship";
import _ from "lodash";
import { Bug, DotsThreeVertical, Export, Prohibit, SealCheck, User } from "@phosphor-icons/react";
import ProfileCourse from "./ProfileCourse";
import BlankState from "../BlankState";
import IconsView from "../utils/IconsView";


const { TabPane } = Tabs;

var url = new URL(window.location.href);

var _state = url.searchParams.get("state");
var _code = url.searchParams.get("code");
var _scope = url.searchParams.get("scope");
const Profile = (props) => {

    const [getAllFollowers, setAllFollowers] = useState([])
    const [container, setContainer] = useState(null);
    const [profile, setProfile] = useState([])
    const [loader, setLoader] = useState(true);
    const [init_feed_count, setinit_feed_count] = useState(0);
    const params = useParams();
    const [_url] = useState(window.location.href)
    const [following, setfollowing] = useState(false)
    const [followers, setfollowers] = useState(true)
    const [channels, setAllChannels] = useState([])
    const [followersModal, setfollowersModal] = useState(false)
    const [posts, setPosts] = useState([])
    const [followerLoader, setFollowerLoader] = useState(true)
    var _user_id = params['id'] || "";


    const history = useHistory();

    async function gauth() {
        if (_state && _code && _scope) {
            await axios({
                method: 'get',
                url:  `lighthouse/auth/google/oauth2callback?state=${_state}&code=${_code}&scope=${_scope}&authuser=0&prompt=consent`,
                headers: {
                    Authorization: Cookies.get("Logintoken")
                }
            }).then(res => {
                window.location.href = "/profile"
            })
        }
        reload();
    }


    useEffect(() => {
        gauth();
        fetchUserPosts();
    }, []);

    const fetchUserPosts = async () => {
        let newcount = init_feed_count + 10;

        await axios({
            method: 'get',
            url:  `market/feed/all/personal?user_code=${_user_id}&count=10&offset=${newcount}`,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data?.data?.data
            setPosts(data);
            setinit_feed_count(newcount);
        })
    }


    const reload = async () => {
        let route = "/account/user/profile"
        if (_user_id === "me") {
            route = "/account/user/profile?user_code="
        }
        else if (_user_id !== null) {
            route = "/account/user/profile?user_code=" + _user_id
        }
        props.Profile(route);
    }
    useEffect(() => {
        if (props._profile.length === undefined) {
            setProfile(props._profile)
            setLoader(false)
            getChannels(_user_id)
        }

    }, [props._profile])


    async function getChannels(user) {
        await axios({
            method: 'get',
            url:  'channel/list/public?user_code=' + user,
            headers: {
                Authorization: Cookies.get("Logintoken"),
            },
        }).then((res) => {
            setAllChannels(res.data.data.public)
        });
    }

    function FollowUser(key, id, type) {
        var _user_id = profile.user_code;
        let data = {
            following_user_code: id === undefined ? _user_id : id,
            follower: key
        }
        let method = type
        props.FollowUnFollowUser({ method, data }).then(() => {
            reload()
            setfollowersModal(false)
        })
    }


    async function onChangeTab(key) {
        setFollowerLoader(true)
        setfollowersModal(true)
        var _user_id = profile.user_code
        if (key === true) {
            let followerroute = "account/user/followers?user_code=" + _user_id + "&to=&from="
            await props.getMyFollowers(followerroute)

            setfollowing(false)
            setfollowers(true)

        }
        else {
            let followerroute = "account/user/followings?user_code=" + _user_id + "&to=&from="
            await props.getMyFollowers(followerroute)

            setfollowing(true)
            setfollowers(false)

        }
    }


    useEffect(() => {
        setAllFollowers(props._followers)
        setFollowerLoader(false)
    }, [props._followers])

    // function addLink(name, link, type) {
    //     if (name === "" || link === "") {
    //         toast.error("Provide Name and Link")
    //         return
    //     }
    //     props.addLink({
    //         name: name,
    //         link: link,
    //         type: type
    //     }).then(() => {
    //         forceUpdate()
    //         window.location.reload()
    //     })
    // }

    // function ProfileEdit(user_about, user_tags) {
    //     let data = {
    //         desc: {
    //             main: user_about,
    //             main_keys: user_tags
    //         }
    //     }
    //     props.ProfileEdit(data).then(() => {
    //         window.location.reload()
    //     })
    // }
    const details = (record) => {
        window.location.href = "/feed-view/" + record?.entity_uuid
    }

    const likeFeed = (data, record) => {
        props
            .likeFeed({
                id: record.id,
                type: record.type,
                requesttype: data === true ? "post" : "delete",
            })
            .then(() => {
                reload()
            });
    }

    const SaveFeed = (id, entity) => {
        props.SaveFeed({
            "id": id,
            "entity_type": entity,
            "type": "Post"
        }).then(() => {
            reload()
        })
    }

    const getFilterByTags = () => {

    }

    const onClickPeople = async ({ key, id }) => {

        if (key === '3') {
            history.push("/profile-update")
        }
    };

    const itemsPeople = (item) => {

        return [
            !item?.isme &&
            {
                label: (
                    <span className="fs12 fw400"> Block @{item?.username}</span>
                ),
                key: '1',
                icon: <Prohibit size={18} color="#000000" />
            },
            !item?.isme && {
                label: (
                    <span className="fs12 fw400"> Report Issue</span>
                ),
                key: '2',
                icon: <Bug size={18} color="#000000" />
            },
            item?.isme && {
                label: (
                    <span className="fs12 fw400"> Edit Profile</span>
                ),
                key: '3',
                icon: <User size={18} color="#000000" />,
            }

        ]
    };

    return (

        <Row className="dashboard-page">

            <Col span={24} className="scrollable-container" ref={setContainer}>
                <Row>



                    {
                        loader ? <>
                            <Col lg={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }} className="md70">
                                <Row gutter={32}>
                                    <Col lg={{ span: 6 }} xs={{ span: 24, }} className="text-center">
                                        <Skeleton.Avatar active size={100} /> <br /> <br />
                                        <Skeleton.Button active></Skeleton.Button> <br />
                                        <Skeleton.Input className="md10" active></Skeleton.Input>  <br /> <br />
                                        <Space>
                                            <Skeleton.Button active></Skeleton.Button>
                                            <Skeleton.Button active></Skeleton.Button>
                                        </Space>
                                    </Col>


                                    <Col lg={{ span: 13 }} xs={{ span: 24 }} className="m-md20">
                                        <Skeleton.Input active></Skeleton.Input> <br /> <br />
                                        <Skeleton active></Skeleton>
                                        <br />
                                        <Space>
                                            <Skeleton.Input active></Skeleton.Input>
                                            <Skeleton.Input active></Skeleton.Input>
                                        </Space>
                                    </Col>
                                    <Col lg={{ span: 5, }} xs={{ span: 24, }} className="m-md20 text-right">
                                        <Skeleton.Input active></Skeleton.Input>


                                    </Col>

                                </Row>


                            </Col>
                            <Col className="md50" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}  >
                                <Row gutter={32}>
                                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 5 }} className=' tab-padding ' >

                                        <Skeleton active></Skeleton>
                                        <Skeleton active></Skeleton>
                                    </Col>
                                </Row>
                            </Col>
                        </> :
                            <Col lg={{ span: 24 }} >
                                <Row>
                                    <Col lg={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }} className="md70">
                                        <Row gutter={32}>
                                            <Col lg={{ span: 6 }} xs={{ span: 24, }} className="text-center">
                                                <Avatar size={100} src={profile?.img_url} />
                                                <p className="fs23 fw500 md10"> {profile?.full_name} </p>
                                                <p className="dark-grey fs14 fw300">@{profile?.username}</p>

                                                {
                                                    profile?.actions.length > 0 && <Space size={15} className='text-center profile-cap-icon profile-tv-icon md15'>
                                                        {
                                                            profile?.actions?.map((item, i) => {
                                                                return <Tooltip title={item?.title}>
                                                                    {
                                                                        item.slug.includes('campus') ?
                                                                            <Button key={i} type="link" href={`https://campus.syllo.co${item.slug}`} style={{ backgroundColor: `${item?.color}36` }} icon={<IconsView icon={item?.icon} size={18} color={item?.color} weight="bold" />}></Button>
                                                                            :
                                                                            <Button key={i} onClick={() => history.push(item.slug)} style={{ backgroundColor: `${item?.color}36` }} icon={<IconsView icon={item?.icon} size={18} color={item?.color} weight="bold" />}></Button>

                                                                    }
                                                                </Tooltip>
                                                            })
                                                        }
                                                    </Space>
                                                }

                                            </Col>
                                            <Col lg={{ span: 13 }} xs={{ span: 24 }} className="m-md20">
                                                {
                                                    profile?.connection && <Button onClick={() => history.push(`/business/${profile?.connection?.username}`)} className="Radius8 md30" style={{ color: '#114410', background: '#E0E9EE' }} icon={<SealCheck size={20} className="pe-1" color="green" weight="fill" />}>
                                                        {profile?.connection?.username}
                                                    </Button>
                                                }


                                                <p className="fs12 fw400 md10 " style={{ color: '#4B5669' }}> {profile?.designation}</p>
                                                <p className="fs14 fw400 md10">{profile?.user_about}</p>
                                                <Space size={16} className="md20">
                                                    {
                                                        !profile?.isme && !profile?.following ? <Button type="primary" className="radius18" style={{ width: "100px" }} onClick={() => FollowUser(false, profile.user_code, "post")}> Follow </Button>
                                                            : !profile?.isme && profile?.following ? <Button type="dashed" className="radius18" style={{ width: "100px" }} onClick={() => FollowUser(true, profile.user_code, "delete")}> Following </Button> : ""
                                                    }

                                                    {
                                                        !profile?.isme && <Button type="dash" className="radius18"> Send a message </Button>
                                                    }

                                                    <RWebShare
                                                        data={{
                                                            text: profile?.full_name,
                                                            url: `${process.env.REACT_APP_URL}profile/` + profile?.user_code,
                                                            title: profile?.full_name,
                                                        }}

                                                    >
                                                        <Button shape="circle" type="text" icon={<Export size={24} color="#4B5669" />}> </Button>
                                                    </RWebShare>

                                                    {

                                                        <Dropdown trigger={['click']} menu={{ items: itemsPeople(profile), onClick: ({ key }) => onClickPeople({ key: key }) }} placement={"bottomLeft"} overlayStyle={{ minWidth: "120px" }}>
                                                            <Button onClick={(e) => e.preventDefault()} type="text" shape="circle" icon={<DotsThreeVertical size={24} color="#4B5669" />} />
                                                        </Dropdown>
                                                    }


                                                </Space>
                                            </Col>
                                            <Col lg={{ span: 5, }} xs={{ span: 24, }} className="m-md20">
                                                <Row>
                                                    <Col span={24} className="text-right m-align-explore">
                                                        {
                                                            profile?.org ? <a href={`/business/${profile?.org?.username}`}><Space><Avatar src={profile?.org?.logo}></Avatar>  {profile?.org?.name} </Space></a> :
                                                                <Tag color="#fff" className="tag-p30 cursor" style={{ background: 'linear-gradient(90deg, rgba(231,151,218,1) 0%, rgba(130,120,240,1) 39%)' }}> {profile?.account_type} </Tag>
                                                        }

                                                    </Col>

                                                </Row>
                                                <Row className="md45" gutter={20}>
                                                    <Col span={12} >
                                                        <div onClick={() => onChangeTab(true)} className="cursor trending-card">
                                                            <p className="fs18 fw600 text-center"> {profile?.followers} </p>
                                                            <p className="fs12 fw300 text-center"> Followers </p>
                                                        </div>
                                                    </Col>
                                                    <Col span={12}  >
                                                        <div onClick={() => onChangeTab(false)} className="cursor trending-card">
                                                            <p className="fs18 fw600 text-center"> {profile?.followings} </p>
                                                            <p className="fs12 fw300 text-center"> Followings </p>
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <Col span={24} style={{
                                    paddingBottom: "20px", backgroundImage: `url('${profile?.banner_url}')`, height: "225px", backgroundPosition: "center",
                                    backgroundRepeat: "repeat-x", backgroundColor: "#216EB4",borderTopLeftRadius:"10px",borderTopRightRadius:"10px"
                                }}>

                                </Col> */}

                                    <Col className="md15" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}  >
                                        <Row>
                                            {/* <Col xs={{ span: 22, offset: 1 }} lg={{ span: 16, offset: 4 }} className='md50  profile-top-card text-center m-p0' >
                                                <Row>
                                                    <Col span={6} className='md10'>
                                                        <Article size={36} color={"#146EB4"} weight="light" />
                                                        <h5 className="dark-blue  mb-0 fw600 m-fs14 md15"> {_.find(profile?.counts, { entity_type: "Post" })?.count || 0} </h5>
                                                        <p className="md10  fw500 m-fs11 text-normal m-mb10"> Post </p>
                                                    </Col>
                                                    <Col span={6} className='md10'>
                                                        <ChartBarHorizontal size={36} color={"#146EB4"} weight="light" />
                                                        <h5 className="dark-blue mb-0 fw600 m-fs14 md15">{_.find(profile?.counts, { entity_type: "Poll" })?.count || 0} </h5>
                                                        <p className="md10  fw500 m-fs11 m-mb10"> Poll </p>
                                                    </Col>
                                                    <Col span={6} className='md10' >
                                                        <Newspaper size={36} color={"#146EB4"} weight="light" />

                                                        <h5 className="dark-blue md15 mb-0 fw600 m-fs14" style={{}}> {_.find(profile?.counts, { entity_type: "Newsletter" })?.count || 0} </h5>
                                                        <p className="md10  fw500 m-fs11 m-mb10"> Newsletter </p>
                                                    </Col>
                                                    <Col span={6} className='md10' >
                                                        <Notebook size={36} color={"#146EB4"} weight="light" />

                                                        <h5 className="dark-blue md15 mb-0 fw600 m-fs14"> {_.find(profile?.counts, { entity_type: "Report" })?.count || 0} </h5>
                                                        <p className="md10 fw500 m-fs11 m-mb10 m-px5">Report </p>
                                                    </Col>

                                                </Row>
                                            </Col> */}

                                            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }} xl={{ span: 16, offset: 5 }} className=' tab-padding ' >
                                                <Affix target={() => container}  >

                                                    <Tabs defaultActiveKey="1" className="profile-tabs">

                                                        {
                                                            profile?.role !== 7 && <TabPane tab="Posts" key="1">
                                                                {
                                                                    loader ? <Col span={24} className='md10' >
                                                                        <Skeleton active></Skeleton> </Col> :
                                                                        posts.length === 0 ? <BlankState img={require("../../assets/images/blank/no-channel.svg").default} height={200} /> :
                                                                            <NewsFeed
                                                                                data={posts}
                                                                                likeFeed={(data, record) =>
                                                                                    likeFeed(data, record)
                                                                                }
                                                                                showLike={false}
                                                                                details={(record) => details(record)}
                                                                                SaveFeed={(id, entity) => SaveFeed(id, entity)}
                                                                                getFilterByTags={(tag, media, author) => getFilterByTags()}
                                                                                reload={(id, key, type) => { }}

                                                                                height={"calc(100vh - 300px)"}
                                                                                id={'profile-posts'}
                                                                                loadMoreData={() => fetchUserPosts()}

                                                                            />
                                                                }

                                                            </TabPane>
                                                        }

                                                        <TabPane tab="About" key="2">
                                                            <About profile={profile} />
                                                        </TabPane>
                                                        <TabPane tab="Channels" key="3">
                                                            <Col span={24} className="m-p0 "> <NewChannels channels={channels} /> </Col>
                                                        </TabPane>
                                                        {/* <TabPane tab="Resources" key="4">
                                                        <Resources _user_id={_user_id} profile={profile} />
                                                    </TabPane> */}
                                                        <TabPane tab="Mentorship" key="5">
                                                            <Mentorship _user_id={_user_id} />
                                                        </TabPane>
                                                        <TabPane tab="Mini Course" key="6">
                                                            <ProfileCourse _user_id={_user_id} />
                                                        </TabPane>

                                                    </Tabs>
                                                </Affix>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>


                            </Col>
                    }

                </Row>


                <Drawer title={<span className={"text-white "}>sss</span>} className='m-drawer' visible={followersModal} onClose={() => setfollowersModal(false)}>
                    <Row>
                        <Col span={24} className="md20">
                            <Row>
                                <Col lg={{ span: 12 }} xs={{ span: 12 }} md={{ span: 12 }} className="border-right follow-btn follow-px">
                                    <Button type={followers ? "primary" : "text"} className='text-center ' onClick={() => onChangeTab(true)} block>Followers</Button>
                                </Col>
                                <Col lg={{ span: 12 }} xs={{ span: 12 }} md={{ span: 12 }} className='follow-btn text-right follow-px'>
                                    <Button type={following ? "primary" : "text"} className='text-center ' onClick={() => onChangeTab(false)} block> Followings</Button>
                                </Col>
                                <Col span={24} xs={{ span: 24 }} className="md25">
                                    {
                                        followerLoader ? <Skeleton></Skeleton> :
                                            getAllFollowers.map((item) => {
                                                return <p className="smaller-heading-text fs14  md20"><Avatar src={item.img_url} className='me-2'></Avatar> {item.name}   <span style={{ float: "right" }}>
                                                    {
                                                        // if clicked on followers
                                                        followers ?

                                                            !profile.isme && (Cookies.get("user_code") === item.user_code ? "" :
                                                                <button style={{ marginTop: "-2px", fontSize: '13px' }} className="btn btn-small" onClick={() => FollowUser(true, item.user_code, item.connected ? "delete" : "post")}>{item.connected ? "Followed" : "Follow"}
                                                                </button>)

                                                            // if clicked on followings
                                                            : Cookies.get("user_code") === item.user_code ? ""
                                                                : <button style={{ marginTop: "-2px", fontSize: '13px' }} className="btn btn-small" onClick={() => FollowUser(false, item.user_code, item.connected ? "delete" : "post")}>
                                                                    {item.connected ? "Followed" : "Follow"}
                                                                </button>

                                                    }

                                                </span>  </p>
                                            })
                                    }


                                </Col>



                            </Row>

                        </Col>
                    </Row>
                </Drawer>

            </Col>

        </Row>

    );
}

const mapStateToProps = (state) => ({
    _profile: state.profile.profile,
    _profile_catalogue: state.profile.profile_catalogue,
    _followers: state.profile.followers
});
const mapDispatchToProps = (dispatch) => ({
    Profile: (v) => dispatch(actions.Profile(v)),
    ProfileEdit: (v) => dispatch(actions.ProfileEdit(v)),
    ProfileCatalogues: (v) => dispatch(actions.ProfileCatalogues(v)),
    addLink: (v) => dispatch(actions.addLink(v)),
    deleteNote: (v) => dispatch(actions.deleteNote(v)),
    FollowUnFollowUser: (v) => dispatch(actions.FollowUnFollowUser(v)),
    getMyFollowers: (v) => dispatch(actions.getMyFollowers(v)),
    changePassword: (v) => dispatch(actions.changePassword(v)),
    SaveFeed: (v) => dispatch(actions.SaveFeed(v)),
    likeFeed: (v) => dispatch(actions.likeFeed(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Profile)
);