import { Col, Row, Button, Avatar, Tag, Space, Modal, Image, Skeleton } from "antd";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import moment from "moment";
import EventTrigger from "../utils/EventTrigger";
import { Globe, User } from "@phosphor-icons/react";
import PaymentCreditPage from "../utils/paymentCredit";
import NewsletterReadView from "../Editor/NewsletterReadView";


const EventDetailView = (props) => {
    const [loader, setloader] = useState(true)
    const [details, setdetails] = useState(null);
    const [payment_status_modal, setpayment_status_modal] = useState(false);
    const [creditModal, setCreditModal] = useState(false);
    const [buyNowModal, setBuyNowModal] = useState(false);

    const params = useParams();
    const [_id, _] = useState(params['id']);
    const history = useHistory();

    useEffect(() => {
        props.EventDetails({ id: _id });
    }, [])


    useEffect(() => {
        if (props._details.length !== 0) {
            setdetails(props._details)
            setloader(false);
        }

    }, [props._details])

    const onCreditUpdate = (res) => {
        setCreditModal(res.modal);
        if (res.status === "success") {
            window.location.reload()
        }
    }

    const notStarted = moment(details?.schedule_date_from) >= moment();

    return (

        <Row className="m-minute10-marginx">
            {
                loader ? <Loader></Loader> : <>
                    <Col span={24} className='event-bg-img  padding20' style={{ backgroundImage: `url(${details?.banner_img_url})`, backgroundPosition: "center", backgroundRepeat: 'no-repeat' }} > </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 16, offset: 4 }} md={{ span: 22, offset: 1 }}>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 7 }} className='m-align-explore' style={{ marginTop: '-40px' }} >
                                <Avatar className="bg-event-img" size={219} src={details?.img_url}></Avatar>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 17 }} className='md10 m-align-explore'>
                                <Row className='m-pl50'>
                                    <Col span={24} className='md10'>
                                        <p className="fs20 fw600 m-0 lh24">{details?.name}</p>
                                    </Col>
                                    <Col span={24} className='md10'>
                                        <Space>
                                            <p className="fs14 text-grey m-0">{moment(details?.schedule_date_from).format("Do MMM | h:mm a")} - {moment(details?.schedule_date_to).format("Do MMM YY | h:mm A")} </p>
                                            {/* <p className='bg-pink live-color2 m-md10 ms-2'> <Badge color={"pink"} /> LIVE</p> */}
                                        </Space>
                                        <p className="fs14 text-grey m-0">{details?.is_paid ? "Paid offering |" : `Free`}  {details?.venue}</p>
                                        <p className="fs12 fw500 md35 ">
                                            <Space>

                                                { // Event has been closed
                                                    moment(details?.schedule_date_to) <= moment() ? <Button disabled>Event closed</Button>
                                                        :
                                                        details.isme ?
                                                            <Button type={"primary"} href={`/event/${details.schedule_uid}/live`}>Join now</Button>
                                                            :
                                                            details.joined === true
                                                                ? <Space>
                                                                    <Button type={"primary"} href={notStarted ? "" : `/event/${details.schedule_uid}/live`} disabled={notStarted}>Join now</Button>
                                                                    {
                                                                        notStarted && <p className="fs12 fw400 text-grey">will start at {moment(details?.schedule_date_from).format("Do MMM, YY| h:mm a")}</p>
                                                                    }
                                                                </Space>
                                                                : <Button type={"primary"} onClick={() => setBuyNowModal(true)}>Buy Now</Button>
                                                }

                                                { // Event has been started but not closed
                                                    moment(details?.schedule_date_from) < moment() && moment(details?.schedule_date_to) > moment() &&
                                                    <Tag color="green">Running</Tag>

                                                }

                                                <EventTrigger data={details}></EventTrigger>
                                            </Space>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} className='md40 '>
                                <Row className="padding20">
                                    <Col xs={{ span: 24 }} lg={{ span: 8 }}  >
                                        <Row>
                                            <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                                                <Space>
                                                    <Globe size={20} color="#728096" />
                                                    <p className="fs14 fw400">{details?.language}  </p>
                                                </Space>
                                                <br />
                                                <Space>
                                                    <User size={20} color="#728096" />
                                                    <p className="fs14 fw400">{details?.medium} </p>
                                                </Space>

                                            </Col>
                                            {/* <Col xs={{ span: 8 }} lg={{ span: 6 }} className='bg-pink live-color m-md10 ' style={{ height: '25px', marginRight: '15px' }}>
                                                                <p className="ms-3"> <Badge color={"pink"} /> LIVE</p>
                                                            </Col>
                                                            <Col xs={{ span: 12 }} lg={{ span: 12 }} className='md30 m-md10 m-text-right'>
                                                                <Button type="text" className="online-bg"> {details?.medium} </Button>
                                                            </Col> */}
                                        </Row>
                                    </Col>
                                    <Col xs={{ span: 24 }} lg={{ span: 8 }} className='borderleft m-noborder ps-3 m-px0 m-md20' >
                                        <p className="fs14 fw500 text-grey"> Host </p>
                                        <Row className="m-md10">
                                            <Col span={24}>
                                                <Space className="cursor" onClick={()=>history.push(`/profile/${details?.user_code}`)}>
                                                    <Avatar src={details?.creator_img} size={24} >  </Avatar>
                                                    <p className="fs14 fw400"> {details?.creator}</p>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {details?.venue && <Col xs={{ span: 24 }} lg={{ span: 8 }} className='borderleft m-noborder m-md20 ps-3 m-px0' >
                                        <p className="fs16 fw500 text-grey"> Venue </p>
                                        <p className="fs14 fw400 text-grey md10 lh24">{details.venue}</p>
                                    </Col>}

                                </Row>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} className='md40 m-px10'>
                                <p className="fs14 fw400">
                                    {/* {details?.description} */}
                                    {
                                        loader ? <Skeleton></Skeleton> : <NewsletterReadView intState={details?.description} />
                                    }

                                </p>
                            </Col>
                            {/* <Col xs={{ span: 24 }} lg={{ span: 24 }} className='md70 m-md45 m-align-explore'>
                                                <Space aria-orientation="horizontal">
                                                    <Radio.Group type={"button"} >
                                                        <Radio className="yuja-tag " value={"0"}>Workshop</Radio>
                                                        <Radio className="yuja-tag " value={"3"}>Courses</Radio>
                                                        <Radio className="yuja-tag " value={"4"}>Technology</Radio>
                                                        <Radio className="yuja-tag" value={"7"}>Wealth Management</Radio>
                                                    </Radio.Group>
                                                </Space>
                                            </Col> */}


                            {
                                !loader && details?.related && details?.related.length !== 0 && <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }} className='md40 mb-5'>
                                    <p className="fs16 fw400">Related events</p>
                                    <Row gutter={30}>

                                        {
                                            !loader && details?.related && details?.related.map((item) => {
                                                return item?.entity === "Creator" ? <Col lg={{ span: 8 }} xs={{ span: 24 }} >
                                                    <a href={"/profile/" + item?.user_code}><div className='creator-card md30'>
                                                        <Row className="p-3">
                                                            <Col span={20} className=''>
                                                                <Avatar size={50} src={item?.img_url}> </Avatar>
                                                            </Col>
                                                            <Col span={4} className='text-right'>
                                                                <Button type="text" shape="round"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                                                                    <rect width="18" height="18" fill="url(#pattern0)" />
                                                                    <defs>
                                                                        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                                            <use href="#image0_665_1089" transform="scale(0.0416667)" />
                                                                        </pattern>
                                                                        <image id="image0_665_1089" width="24" height="24" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAXklEQVRIie2SQRGAMAwEN9gAR/iIJjoVgiDwcUggZZoX2Xcye4+F4gWLHrr7DjRAkrz3fkb+loExB7ACm5m16NOI4BNhgSQHbuAyM8+b9Dsq03mCyjSNynSeoDJN4wH1RDAQOJ+vlwAAAABJRU5ErkJggg==" />
                                                                    </defs>
                                                                </svg></Button>
                                                            </Col>
                                                            <Col span={24} className='md10'>
                                                                <p className="fs16 fw600 mb-0"> {item?.fullname} </p>
                                                                <span className="fs12 fw400 mt-0" style={{ color: '#90949A' }}>@{item?.username}</span>
                                                            </Col>
                                                        </Row>
                                                        <Row className="p-3 bg-green md20">
                                                            <Col span={24} >
                                                                <p className="fs14 fw500 text-white m-0">
                                                                    <Space split={"|"}>
                                                                        {item?.counts.map((_item) => {
                                                                            return <span>{_item?.entity_type} : {_item?.count}</span>
                                                                        })}
                                                                    </Space>

                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </div></a></Col> : <Col lg={{ span: 8 }} xs={{ span: 24 }} >
                                                    <a href={"/event/" + item.schedule_uid}><div className='yujaevent-card'>
                                                        <Row>
                                                            <Col span={24} className='bg-event-img'
                                                                style={{ backgroundImage: `url(${item?.img_url})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
                                                            >
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24} className='padding20'>
                                                                <Row className=''>
                                                                    <Col span={19} >
                                                                        <p className="fs12 fw500 text-grey ">{(item?.category)} </p>
                                                                    </Col>


                                                                    <Col span={24} className='md10'>
                                                                        <p className="fs20 fw600 m-0 lh24">{item?.name}</p>
                                                                    </Col>
                                                                    <Col span={24} className='md10'>
                                                                        <p className="fs12 fw400 m-0">{moment(item?.schedule_date_from).format("Do MMM | h:mm a")} - {moment(item?.schedule_date_to).format("Do MMM YY | h:mm A")} </p>
                                                                        <p className="fs12 fw400 m-0">{item?.is_paid ? "Paid offering |" : ""}  {item?.venue}</p>
                                                                        <p className="fs12 fw500 md15 "><Tag color="blue" > {(item?.medium)} </Tag> {item?.is_paid && <Tag color="orange" >Paid</Tag>}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                    </div></a></Col>
                                            })
                                        }




                                    </Row>
                                </Col>
                            }

                        </Row>
                    </Col>

                </>
            }

            <Modal centered maskClosable={false}
                footer={false} open={buyNowModal}
                onCancel={() => setBuyNowModal(false)}
                title="">
                <Row className="p-4">

                    <Col span={24} className="text-center md20">
                        <h5>{details?.name}</h5>
                        <p className="fs12 text-grey">{moment(details?.schedule_date_from).format("Do MMM, YY | h:mm a")}</p>


                        { // Event has been started but not closed
                            moment(details?.schedule_date_from) < moment() && moment(details?.schedule_date_to) > moment() &&
                            <div className="md10">
                                <Tag color="green">Running</Tag>
                            </div>
                        }
                        { // Event is paid
                            details?.amount > 0 ? <Space size={10} className="md20">
                                <Button type={"primary"} onClick={() => setCreditModal(true)}>Buy Now</Button>
                                <Button className="credit">
                                    <Space size={5} style={{ cursor: 'default' }}>
                                        <Avatar style={{ border: "none" }} size={32} src={require('../../assets/images/newdashboard/sylloCoin.svg').default} />
                                        <p> {details?.amount} Coins</p>
                                    </Space>
                                </Button>
                            </Space> :
                                <Button type={"primary"} className="md20" onClick={() => setCreditModal(true)}>Join now</Button> // Hit API to join event free
                        }

                        <p className="fs12 text-grey md50">We'll send an email with event ticket details on registered email address.</p>



                    </Col>

                </Row>

            </Modal>

            <Modal centered maskClosable={false}
                onCancel={() => setpayment_status_modal(false)}
                footer={false} open={payment_status_modal}
                width={800}
                className='game-category-modal'
                title={<p className="text-center mt-2">Payment Status</p>}>
                <Row className="p-4" style={{ marginBottom: "50px" }}>

                    <Col span={24} xs={24} className="text-center">

                        <Image
                            width={100}
                            preview={false}
                            src={require("../../assets/images/check.png")}
                        />

                        <p>Payment successfully done !</p>
                        <Button className="mt-3" type="primary" onClick={() => window.location.reload()}>Close</Button>
                    </Col>

                </Row>

            </Modal>


            <Modal centered open={creditModal} onCancel={() => setCreditModal(false)} footer={false} >
                <PaymentCreditPage onChange={(val) => onCreditUpdate(val)}
                    title={details?.name} entity={"Event"}
                    amount={details?.amount}
                    entity_type="EVENT"
                    id={_id}
                ></PaymentCreditPage>
            </Modal>
        </Row>

    );
}

const mapStateToProps = (state) => ({
    _details: state.channel.event_detail,
});
const mapDispatchToProps = (dispatch) => ({
    EventDetails: (v) => dispatch(actions.EventDetails(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EventDetailView)
);