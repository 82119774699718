import React, { useState, useEffect } from "react";
import { Col, Row, Button, Radio, Space, Select, Input, Switch, Upload, DatePicker } from "antd";

import { CalendarOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { useHistory, withRouter } from "react-router";
import moment from "moment";
import Dragger from "antd/lib/upload/Dragger";
import { Desktop, Mountains, Slideshow } from "@phosphor-icons/react";
import ContentAdvancedFeature from "../utils/ContentAdvancedFeature";
import NewsletterEditor from "../Editor/NewsletterEditor";

import Toast from "../utils/Toast";

const { Option } = Select;
const { TextArea } = Input;




const CreateEvent = (props) => {

    const [step, setStep] = useState(1);
    const [medium, setMedium] = useState("1");
    const [name, setName] = useState("");
    const [event_date, setevent_date] = useState("");
    const [event_date_to, setevent_date_to] = useState("");
    const [description, setdescription] = useState("");
    const [venue, setvenue] = useState("");
    const [no_of_slots, setno_of_slots] = useState("0");
    const [age_group, setage_group] = useState("1");
    const [language_medium, setlanguage_medium] = useState("1");
    const [banner, setbanner] = useState([]);
    const [title_img, settitle_img] = useState([]);
    const [bannerurl, setbannerurl] = useState("");
    const [title_imgurl, settitle_imgurl] = useState("");
    const [loader, setLoader] = useState(false);
    const [limited, setLimited] = useState(false);
    const [advancedPayload, setAdvanecPayload] = useState({});
    const history = useHistory();


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };


    const propsBanner = {
        accept: ".png,.jpg,.JPEG",
        onRemove: file => {
            const index = banner.indexOf(file);
            const newFileList = banner.slice();
            newFileList.splice(index, 1);
            setbanner(newFileList);
            setbannerurl("");
        },
        beforeUpload: file => {
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                Toast("You can only upload JPG/PNG file!", "error");
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                Toast("Image must smaller than 5MB!", "error");
                return;
            }
            getBase64(file, (url) => {
                setbannerurl(url);
            })
            setbanner([...banner, file]);
            return false;
        },
        banner,
    };

    const propsEvent = {
        accept: ".png,.jpg,.JPEG",
        onRemove: file => {
            const index = title_img.indexOf(file);
            const newFileList = title_img.slice();
            newFileList.splice(index, 1);
            settitle_img(newFileList);
            settitle_imgurl("");
        },
        beforeUpload: file => {
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                Toast("You can only upload JPG/PNG file!", "error");
                return;
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                Toast("Image must smaller than 5MB!", "error");
                return;
            }
            getBase64(file, (url) => {
                settitle_imgurl(url);
            });
            settitle_img([...title_img, file]);
            return false;
        },
        title_img,
    };

    const createEvent = () => {
        if (banner.length === 0) {
            Toast("Upload banner image!", "error")
            return
        }
        if (title_img.length === 0) {
            Toast("Upload Title image!", "error")
            return
        }

        const formData = new FormData();
        banner.forEach(file => {
            formData.append('banner', file);
        });
        title_img.forEach(file => {
            formData.append('title_img', file);
        });
        formData.append('name', name);
        formData.append('event_date', moment(event_date).format('YYYY-MM-DD HH:mm'));
        formData.append('event_date_to', moment(event_date_to).format('YYYY-MM-DD HH:mm'));
        formData.append('description', description);
        formData.append('medium', medium);
        // formData.append('category_id', category_id);
        formData.append('venue', venue);
        formData.append('no_of_slots', no_of_slots);
        formData.append('age_group', age_group);
        formData.append('language_medium', language_medium);
        // formData.append('channel_id', channel_id);

        for (var key in advancedPayload) {
            formData.append(key, advancedPayload[key]);
        }
        setLoader(true);

        props.createEvent(formData).catch((err) => {
            Toast(err.response.data.message, "error")
        });
        setLoader(false);

    }


    useEffect(() => {
        if (props.eventCreated) {
            Toast("Created Successfully", "success");
            history.push("/event/" + props.eventCreated.id);
        }

    }, [props?.eventCreated])

    const uploadButton = (
        title_imgurl ? (
            <img
                src={title_imgurl}
                alt="avatar"
                style={{
                    width: '100%',
                    height: "100%",
                    borderRadius: "10px"
                }}
            />
        ) : (
            <div>
                <PlusOutlined />
                <div
                    style={{
                        marginTop: 8,
                    }}
                >
                    Upload Image
                </div>
            </div>
        )
    );


    const gotoStep = () => {
        if (step === 1) {
            if (medium === "") {
                Toast("Choose Event Type", "error")
                return
            }
            else {
                setStep(2)
            }
        }
        else if (step === 2) {
            // if (category_id === "") {
            //     Toast("Choose Event Category")
            //     return
            // }
            if (name === "") {
                Toast("Enter Event Name", "error")
                return
            }

            if (event_date === "") {
                Toast("Choose Event Start Date", "error")
                return
            }
            if (event_date_to === "") {
                Toast("Choose Event Start Date", "error")
                return
            }
            if (moment(event_date).format("YYYY-MM-DD HH:mm") <= moment().format("YYYY-MM-DD HH:mm")) {
                Toast("Event Start Date should be after current time", "error")
                return
            }
            if (event_date > event_date_to) {
                Toast("Event end Date should be after start date", "error")
                return
            }
            if (medium === "2" && venue === "") {
                Toast("Enter Event Venue", "error")
                return
            }


            if (description === "") {
                Toast("Enter Event Description", "error")
                return
            }

            else {
                setStep(3)
            }
        }
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current.valueOf() < Date.now();
    }



    return (
        <Row>


            <Col xs={{ span: 24, offset: 0 }} md={{ span: 22, offset: 1 }} lg={{ span: 12, offset: 6 }} className='md50  padding20' >

                {
                    step === 1 ?

                        <Row>
                            <Col span={24} className='text-center'>
                                <p className="fs16 fw500"> Create Event </p>
                            </Col>
                            <Col span={24} className='md80 m-md20 text-center'>
                                <p className="lh24"> Select the  event type</p>
                            </Col>

                            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 14, offset: 5 }} className='md30 create-event-radio'>
                                <Radio.Group defaultValue={medium} onChange={(evt) => setMedium(evt.target.value)}>
                                    <Space direction="vertical">
                                        <Radio value={"1"} className='create-event-card'>

                                            <Row >
                                                <Col xs={{ span: 24 }} lg={{ span: 5 }} className='md15 m-align-explore'>
                                                    <Desktop size={45} color="var(--primary-color)" weight={medium === "1" ? "fill" : "regular"} />
                                                </Col>
                                                <Col xs={{ span: 24 }} lg={{ span: 19 }} className='md10 mb-2 m-pl10 m-align-explore'>
                                                    <p className="fs14 fw500">Digital</p>
                                                    <p className="fs12 fw300">Digitally host events with Syllo. From booking to reserving slots, you can do it all with Syllo Events.</p>
                                                </Col>
                                            </Row>

                                        </Radio>
                                        <Radio value={"2"} className='create-event-card md10'>
                                            <Row>
                                                <Col xs={{ span: 24 }} lg={{ span: 5 }} className='md15 m-align-explore'>
                                                    <Mountains size={45} color="var(--primary-color)" weight={medium === "2" ? "fill" : "regular"} />


                                                </Col>
                                                <Col xs={{ span: 24 }} lg={{ span: 19 }} className='md10 m-pl10  mb-2 m-align-explore'>
                                                    <p className="fs14 fw500">Offline</p>
                                                    <p className="fs12 fw300">Get more exposure and market your upcoming events with Syllo. Announce your events with Syllo.</p>
                                                </Col>
                                            </Row>
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Col>

                            <Col span={24} className='text-center md80 mb-3'>
                                <Button type="primary" onClick={() => gotoStep(2)} className='fs14 fw400 continue-btn'> Continue </Button>
                            </Col>
                        </Row> :
                        step === 2 ? <>
                            {/* <p className="fs16 fw400 m-0">Event category</p>
                            <Row className='md20 create-event-radio'>
                                <Col span={24} className="m-align-explore">
                                    <Radio.Group value={category_id} onChange={(evt) => setcategory_id(evt.target.value)}>
                                        <Radio value={"3"} className="category-card category-border text-center m-md10">
                                            <Avatar shape="square" size={35} src={require('../../assets/images/Category/workshops.svg').default} alt="" />
                                            <p className="fs12 fw400 text-grey md5"> Workshop</p>
                                        </Radio>
                                        <Radio value={"2"} className="category-card category-border text-center m-md10">
                                            <Avatar shape="square" size={35} src={require('../../assets/images/Category/session.svg').default} alt="" />
                                            <p className="fs12 fw400 text-grey md5"> Session</p></Radio>
                                        <Radio value={'4'} className="category-card category-border text-center m-md10">
                                            <Avatar shape="square" size={35} src={require('../../assets/images/Category/game.svg').default} alt="" />
                                            <p className="fs12 fw400 text-grey md5"> Games</p>

                                        </Radio>
                                        <Radio value={'5'} className="category-card category-border text-center m-md10">
                                            <Avatar shape="square" size={35} src={require('../../assets/images/Category/music.svg').default} alt="" />
                                            <p className="fs12 fw400 text-grey md5"> Music</p>
                                        </Radio>
                                        <Radio value={'6'} className="category-card   category-border text-center m-md10 m-top10">
                                            <Avatar shape="square" size={35} src={require('../../assets/images/Category/kids.svg').default} alt="" height={43} />
                                            <p className="fs12 fw400 text-grey md5 "> Kids</p>
                                        </Radio>
                                        <Radio value={"1"} className="category-card category-border text-center m-md10 m-top10">
                                            <Avatar shape="square" size={35} src={require('../../assets/images/Category/meetup.svg').default} alt="" />
                                            <p className="fs12 fw400 text-grey md5 "> Meetup</p>
                                        </Radio>

                                    </Radio.Group>

                                </Col>

                            </Row> */}
                            <Row >
                                <Col span={24}>
                                    <p className=" fw400">Name of event </p>
                                    <Input value={name} max={50} maxLength={50} onChange={(evt) => setName(evt.target.value)} placeholder="Enter event name" block className='md20' />
                                </Col>
                            </Row>


                            <Row className='md30'>
                                <Col lg={{ span: 24 }} xs={{ span: 24 }} >
                                    <Row>
                                        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
                                            <p className=" fw400">Start Date and time</p>
                                            <DatePicker
                                                format={"YYYY-MM-DD HH:mm"}
                                                showTime showSecond={false}
                                                disabledDate={disabledDate}
                                                value={event_date} onChange={(evt) => setevent_date(evt)}
                                                placeholder="10 Oct 2021 9:00"
                                                className='md20'
                                                showNow={false}
                                                style={{ width: "100%", borderRadius: "24px" }}
                                                suffix={
                                                    <CalendarOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                }
                                            />
                                        </Col>
                                        <Col lg={{ span: 6, offset: 1 }} xs={{ span: 24 }}>
                                            <p className=" fw400 ">End Date and time</p>
                                            <DatePicker
                                                format={"YYYY-MM-DD HH:mm"}
                                                placeholder="10 Oct 2021 9:00"
                                                className='md20'
                                                disabledDate={disabledDate}
                                                showTime showSecond={false}
                                                value={event_date_to} onChange={(evt) => setevent_date_to(evt)}
                                                style={{ width: "100%", borderRadius: "24px" }}
                                                suffix={
                                                    <CalendarOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='md30' gutter={30}>


                                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                    <p className=" fw400">What age group is best suited for?</p>

                                    <Select key="age_group" value={age_group}
                                        onChange={(evt) => setage_group(evt)} className="md20" style={{ width: '100%' }} >
                                        <Option value="1">18+</Option>
                                        <Option value="2">Under 18</Option>
                                        <Option value="3">All</Option>
                                    </Select>
                                </Col>
                                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                    <p className=" fw400"> What language this event will be in?</p>

                                    <Select key="language_medium" value={language_medium}
                                        onChange={(evt) => setlanguage_medium(evt)} className="md20" style={{ width: '100%' }} >
                                        <Option key={1} value="1">English</Option>
                                        <Option key={2} value="2">Punjabi</Option>
                                        <Option key={3} value="3">Hindi</Option>

                                    </Select>
                                </Col>
                            </Row>
                            {
                                medium === "2" && <Row className='md30'>
                                    <Col lg={{ span: 12 }} xs={{ span: 24 }} >
                                        <p className=" fw400">Event Venue</p>
                                        <TextArea value={venue} onChange={(evt) => setvenue(evt.target.value)} showCount placeholder='Enter event venue' maxLength={150} className='md20' />
                                    </Col>


                                </Row>
                            }

                            <Row className="md30">
                                <Col span={24}>
                                    <p className=" fw400 ">Description</p>
                                    <NewsletterEditor className={"md20"} onChange={(json) => setdescription(json)} />
                                    {/* <TextArea value={description} onChange={(evt) => setdescription(evt.target.value)} rows={3} showCount maxLength={1000} placeholder='Enter description' className='md20' /> */}
                                </Col>
                            </Row>
                            <Row className="md10">

                                <Col lg={{ span: 6 }} xs={{ span: 24 }} >

                                    <p><Space><span>Limited Seats</span><Switch value={limited} onChange={(evt) => {
                                        setLimited(evt)
                                        setno_of_slots(0)
                                    }} /></Space></p>

                                </Col>
                                {
                                    limited && <Col lg={{ span: 6 }} xs={{ span: 24 }} >
                                        <p className=" fw400"> Total Seats </p>
                                        <Input type={'number'} value={no_of_slots} onChange={(evt) => setno_of_slots(evt.target.value)} placeholder="Number of seats" className='md10' />

                                    </Col>
                                }

                            </Row>
                            <Row className="md30">
                                <Col span={23} className='text-center md70 mb-3'>
                                    <Space size={"large"}>
                                        <Button type="dashed" onClick={() => setStep(1)}>Back</Button>
                                        <Button type="primary" onClick={() => gotoStep(3)} >Next</Button>
                                    </Space>
                                </Col>
                            </Row>

                        </> :
                            <>

                                <Row >
                                    <Col span={24}>
                                        <Dragger
                                            className="avatar-uploader"
                                            listType="picture-card"
                                            showUploadList={false}
                                            {...propsBanner}
                                        >
                                            {bannerurl ? (
                                                <img
                                                    src={bannerurl}
                                                    style={{
                                                        width: '100%',
                                                        height: "100px"
                                                    }}
                                                />
                                            ) : (
                                                <Col lg={{ span: 24 }} xs={{ span: 22, offset: 1 }}>
                                                    <p className="ant-upload-drag-icon">
                                                        <Slideshow size={36} color="var(--primary-color)" />
                                                    </p>
                                                    <p className="ant-upload-text">Upload banner image</p>
                                                    <p className="ant-upload-hint">
                                                        Upload a banner image to relevant to the event. <br />
                                                        Recommended resolution: 1400 x 230
                                                    </p>
                                                </Col>
                                            )}
                                        </Dragger>
                                    </Col>

                                </Row>
                                <Row gutter={20} >
                                    <Col lg={{ span: 6 }} xs={{ span: 24 }} md={{ span: 7 }} className='md30'>
                                        <p className="fw400">Upload Title Image</p>
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader md15 upload-w100"
                                            showUploadList={false}
                                            {...propsEvent}
                                        >
                                            {uploadButton}
                                        </Upload>

                                    </Col>
                                    <Col lg={{ span: 18 }} xs={{ span: 24 }} md={{ span: 17 }} className='md30'>
                                        <ContentAdvancedFeature eventOptions={true} onUpdate={(val) => setAdvanecPayload(val)} />

                                    </Col>
                                </Row>
                                <Row className="md30">
                                    <Col span={24} className='text-center md50'>
                                        <p className="fs14 fw400">Make sure your event content meets our guidelines.</p>
                                    </Col>
                                    <Col lg={{ span: 24 }} xs={{ span: 24 }} className='text-center md20 mb-3'>
                                        <Space >
                                            <Button type="dashed" className='' onClick={() => setStep(2)}> Back </Button>
                                            <Button loading={loader} type="primary" onClick={() => createEvent()} className='ms-4 '>Create Event</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </>
                }
            </Col>

        </Row>

    );
}


const mapStateToProps = (state) => ({
    _channels: state.channel.channel_list,
    eventCreated: state.results.eventCreated,
});
const mapDispatchToProps = (dispatch) => ({
    createEvent: (v) => dispatch(actions.createEvent(v)),
    ChannelList: (v) => dispatch(actions.ChannelList(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateEvent)
);

