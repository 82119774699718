import { createAction } from "redux-actions";
import axios from "../axios";
import Cookies from "js-cookie"


function writeCookie(cname, cvalue, days) {
    var dt, expires, host;
    dt = new Date();

    if (window.location.host.includes(".syllo.co")) {
        host = ".syllo.co"
    }
    else {
        host = window.location.host.split(":")[0]
    }
    dt.setTime(dt.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + dt.toGMTString();
    document.cookie = cname + "=" + cvalue + expires + '; domain=' + host;
}


const LOGIN = createAction("LOGIN");
export const Login = (values) => dispatch => {
    return axios({
        method: 'post',
        url: '/account/auth',
        data: values,
        withCredentials: true
    }).then(res => {
        dispatch(LOGIN(res.data.data));
        Cookies.set("__role", res.data.data.role);
        // Cookies.set("Logintoken", res.data.data.token,{ domain: '.yuja.one,localhost',expires: 1, path: '/' });
        writeCookie("Logintoken", res.data.data.token, 1);
        // document.cookie = "Logintoken" +"=" + res.data.data.token + ";domain=yuja.one;path=/";
    })
};

const LOGIN_SOCIAL = createAction("LOGIN_SOCIAL");
export const LoginSocial = (values) => dispatch => {
    return axios({
        method: 'post',
        url: '/account/auth/social/web',
        data: values
    }).then(res => {
        dispatch(LOGIN_SOCIAL(res.data.data));
        Cookies.set("__role", res.data.data.role);
        writeCookie("Logintoken", res.data.data.token, 1)
    })
};


export const EmailVerify = (values) => dispatch => {
    return axios({
        method: 'post',
        url: 'account/auth/email/verify',
        data: { u: values.u },
        headers: {
            Authorization: values.token
        }
    }).then(res => {
        dispatch(LOGIN(res.data.data));
        Cookies.set("Logintoken", res.data.data.token);
    })
};


export const ForgotPassword = (values) => () => {
    return axios({
        method: 'post',
        url: 'account/auth/forgot-password',
        data: values
    })
};



export const ResetPassword = (values) => dispatch => {
    return axios({
        method: 'post',
        url: 'account/auth/email/verify',
        data: {
            "u": values.userId,
            "password": values.password
        },
        headers: {
            Authorization: values.token
        }
    }).then(res => {
        dispatch(LOGIN(res.data.data));
        writeCookie("Logintoken", res.data.data.token, 1);
    })
};
