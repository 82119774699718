import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {  Col,Modal } from "antd";
// import ChannelDetails from "./ChannelDetails";
import './podcast.scss';
import CreatePodcast from "./ChannelUploadpodcast";




class Podcasts extends Component {
  constructor(props) {
    super(props);
    this.state = {
        podcast : false
    };
  }

  Createpodcast() {
    this.setState({
      podcast:true
    })
  }


  render() {
    const { role } = this.state
    
    return (
      <div className="row">
        <div className="col-md-12 height100vh">
          <div className="row">
            

            <div className="col-xsm-12 navigation-body-width">
              <div className="row">
                
                <Col span={18} offset={3}>
                  <div className="col-md-11  col-xsm-12 navigation-body-width ">
                    <div className="row ">
                      <div className="col-md-8 height100vh border-right" >

                        <div className="row">
                          <div className="col-md-12 md50 ">
                            <h5 className="sub-text md10 ">
                              <i className="fa fa-arrow-left  cursor ms-1" onClick={() => this.props.history.goBack()}
                                style={{ fontSize: "16px", color: "#666666", marginRight: "5px" }}></i> Dashboard
                            </h5>
                            <div className="row md20">
                              <div className="col-md-6 pb-0">
                                <h5 className="ms-1 weight500 podcast-heading md10">My Podcasts</h5>
                              </div>
                              <div className="col-md-6 text-right pb-2">
                                <button className="btn podcast-btn " onClick={() => this.Createpodcast()} > <i className="fa fa-plus margin-right" style={{ color: "#146eb4" }}></i> Create Podcast</button>

                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 nopadding ">
                                <hr style={{ width: "100%", height: "0px", marginTop: "0px" }}></hr>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className="row">
                          <div className="col-md-8 offset-md-2 text-center md100">

                            <img src={require('../../assets/images/newsletter/no_podcast.png')} alt="" srcset="" />
                          </div>
                        </div>

                      </div>
                      <div
                        className="col-md-4 height100vh "
                        style={{ marginLeft: "-1px" }}>

                      </div>
                    </div>

                  </div>
                </Col>
              </div>
            </div>
            <Modal width={"50%"} style={{minHeight:"500px"}} footer={false} visible={this.state.podcast} onCancel={()=>this.setState({podcast:false})}>
                <CreatePodcast  reload={()=>this.setState({podcast:false})} ></CreatePodcast>
            </Modal>




          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Podcasts)
);
