import React, { useState, useEffect } from 'react';
import { Row, Col, Space, Progress, Button, Skeleton } from 'antd';
import './reward.scss'
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { Gift } from "@phosphor-icons/react";
import CreditsRewards from './CreditsRewards';
import Carousel from 'better-react-carousel'
import axios from '../../store/axios';
import Cookies from 'js-cookie';



const RewardDashboard = () => {
    const [loading1, setLoading1] = useState(true);
    const [data, setdata] = useState({});
    const history = useHistory();


    useEffect(() => {
        getrewards();
    }, [])


    const getrewards = async () => {
        await axios({
            method: 'get',
            url:  "v1.0/reward/dashboard",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then((res) => {
            setLoading1(false);
            setdata(res.data.data);
        })

    }

    return (
        <Row className='md50 mb-4'>
            <Col xxl={{ span: 18, offset: 3 }} lg={{ span: 16, offset: 4 }} md={{ span: 22, offset: 1 }} xs={{ span: 24, offset: 0 }}
                className="" >
                <Row>
                    <Col span={21}>
                        <Space>
                            <Gift size={29} color="#00009"  weight="fill"/>
                            <h4 className='fs16  mb-0 md5' style={{ color: 'black' }}> Rewards</h4>


                        </Space>
                    </Col>
                    <Col span={3} className='text-center'>
                        <img src={require('../../assets/images/call/yellowstar.png')} className='m-w35' alt="" style={{height:'38px'}} />
                    </Col>
                </Row>
                <Row gutter={32} className='md20'>
                    <Col xxl={16} md={24} xs={24} lg={16}>
                        <div className='reward-card m-p10'>
                            {
                                loading1 ? <><Skeleton active /><Skeleton active /></> :
                                    <>
                                        <Row >
                                            <Col className='sub-card' xxl={11} md={24} xs={24}>
                                                <Row gutter={20}>
                                                    <Col xxl={6} md={6} xs={7} >
                                                        <p className='fs16 fw300 ' style={{ bottom: 0, position: "absolute", lineHeight: "normal" }}> Streak Status </p>
                                                    </Col>
                                                    <Col xxl={9} md={9} xs={10} className=' md10 text-center'>
                                                        <Progress strokeLinecap="butt" type="circle" strokeColor={'#3B6BE3'}
                                                            percent={(data?.post_streak?.completed * 100) / data?.post_streak?.target} showInfo={false} width={90} />

                                                    </Col>
                                                    <Col xxl={9} md={9} xs={7} className='text-right'>
                                                        <p className='fs20 fw500'> {data?.post_streak?.completed}/{data?.post_streak?.target} </p>
                                                        <span className='fs14 fw400 md20   reward-text'> days </span>

                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xxl={1} md={1} xs={0}></Col>
                                            <Col xxl={12} md={24} xs={24}>
                                                <Row gutter={{md:42, lg:8}} className='md20 '>
                                                    <Col xxl={8} md={6} xs={8}>
                                                        <div className="reward-circle">
                                                            <div>
                                                                <p className='fs20 fw400'> {data?.likes} </p>
                                                                <span className='fs10 fw300 reward-text'>Likes</span>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col xxl={8} md={6} xs={8}>

                                                        <div className="reward-circle">
                                                            <div>
                                                                <p className='fs20 fw400'>{data?.comments}</p>
                                                                <span className='fs10 fw300 reward-text'>Comments</span>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col xxl={8} md={6} xs={8}>
                                                        <div className="reward-circle">
                                                            <div>
                                                                <p className='fs20 fw400'>{data?.shares}</p>
                                                                <span className='fs10 fw300 reward-text'>Share</span>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className='md30' gutter={20}>
                                            <Col xxl={13} lg={14} md={24} xs={24} className=''>
                                                <Row gutter={20}>
                                                    <Col span={14} >
                                                        <div className="sub-card ">
                                                            <p className='fs30 fw500'>{data?.days_spent?.spent} <span className='fs16 fw300 '>/{data?.days_spent?.target}</span></p>
                                                            <p className='fs13 fw300  reward-text'> days spent on Syllo</p>
                                                        </div>
                                                    </Col>

                                                    <Col span={10} className='text-center'>
                                                        <div className="sub-card text-center">
                                                            <Space >
                                                                <Progress type="circle" strokeColor={'#3B6BE3'} percent={100} width={60} strokeWidth={15} showInfo={false} />
                                                            </Space>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22} offset={1} className='md50'>
                                                        {/* <h2 >Hi Avishek </h2> */}
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xxl={11} lg={10} md={24} xs={24} className=''>
                                                <div className="sub-card ">
                                                    <Row>
                                                        <Col span={24}>
                                                            {
                                                                data?.counters && data?.counters.map((item, i) => {
                                                                    return <Row key={i} className={i > 0 ? 'md25' : ""}>
                                                                        <Col xxl={14} md={14} xs={14}>
                                                                            <p className='fs12 fw300 reward-text'> {item?.name} </p>
                                                                        </Col>
                                                                        <Col xxl={10} md={10} xs={10} className='text-right'>
                                                                            <p className='fs12 fw400 ' style={{ color: '#013238' }}> {item?.count}/{item?.target} </p>
                                                                        </Col>
                                                                        <Progress
                                                                            percent={(item?.count * 100) / item?.target}
                                                                            showInfo={false}
                                                                            strokeColor={{
                                                                                '40%': '#000000',
                                                                                '20%': '#3B6BE30',

                                                                            }}
                                                                        />
                                                                    </Row>
                                                                })
                                                            }

                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </div>
                        <Row className='md40'>
                            <Col span={12}>
                                <p className='fs18 fw300'> Earn Points </p>
                            </Col>
                            <Col span={12} className='text-end'>
                                <Button type='primary' icon={<Gift size={22} weight="fill" />} onClick={() => history.push('/rewards/activity')}> {' '} All Rewards</Button>

                            </Col>
                        </Row>
                        <div className='md40'>
                            <Carousel cols={3} rows={1} gap={12} loop>
                                <Carousel.Item>
                                    <img width="100%" src="https://picsum.photos/800/600?random=1" />
                                    <p>200 Rs. on PhonePe Recharge on your next transaction</p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://picsum.photos/800/600?random=2" />
                                    <p>200 Rs. on PhonePe Recharge on your next transaction</p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://picsum.photos/800/600?random=3" />
                                    <p>200 Rs. on PhonePe Recharge on your next transaction</p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://picsum.photos/800/600?random=4" />
                                    <p>200 Rs. on PhonePe Recharge on your next transaction</p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://picsum.photos/800/600?random=5" />
                                    <p>200 Rs. on PhonePe Recharge on your next transaction</p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://picsum.photos/800/600?random=6" />
                                    <p>200 Rs. on PhonePe Recharge on your next transaction</p>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img width="100%" src="https://picsum.photos/800/600?random=7" />
                                    <p>200 Rs. on PhonePe Recharge on your next transaction</p>
                                </Carousel.Item>
                            </Carousel>
                        </div>

                    </Col>
                    <Col xxl={8} md={24} lg={8} xs={24} className='m-md20' >
                        <CreditsRewards />
                    </Col>
                </Row>

            </Col>
        </Row>
    );
}


const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RewardDashboard)
);

