
import React from "react";
import { Col, Row } from "antd";
import moment from "moment";

const MessageDocCard = ({ item, userCode }) => {

    return <Row className="md20">
        <Col span={24} >
            {
                item[4] === userCode ?
                    <div className="Text-message-right chat-message-me">
                        <Row>
                            <Col span={14} >
                                <h5 style={{ color: item[11], fontSize: "16px", fontWeight: "500" }}>{item[2]}</h5>
                                <p className="sub-heading-text word-break"> {item[3] !== "" ? item[3] : item[6].substring(0, 10)}</p>
                                <p className="sub-text">{parseFloat(item[9] / 1024).toFixed(2)} Kb</p>
                                {item[12] !== null && <p> <span className="cursor margin-right  tags-round cursor">{"#" + item[12]}</span></p>}
                            </Col>
                            <Col span={10} >

                                {
                                    item[7] === "pdf" ? <p className="doc-container" onClick={() => window.open(item[8])}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.852 15.492a.189.189 0 0 0 .23.053.189.189 0 0 0 .066-.053l2.625-3.32a.187.187 0 0 0-.148-.303h-1.737V3.938a.188.188 0 0 0-.187-.188h-1.407a.188.188 0 0 0-.187.188v7.928H9.375a.187.187 0 0 0-.148.303l2.625 3.323zm8.726-.82h-1.406a.188.188 0 0 0-.188.187v3.61H5.016v-3.61a.188.188 0 0 0-.188-.187H3.422a.188.188 0 0 0-.188.187V19.5c0 .415.336.75.75.75h16.032a.75.75 0 0 0 .75-.75v-4.64a.188.188 0 0 0-.188-.188z" fill="#000" fill-opacity=".6" />
                                        </svg>
                                    </p> : <p className="doc-container" onClick={() => window.open(item[8])}>  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27.667 27.667V17.333L23 15V2H4.333v28h23.334v-2.333zm-4.667 0v-9.111l2.333 1.222v7.889H23z" fill="#969696" />
                                        <path d="M17.65 25.56h3.45v.992h-3.45v-.993zM17.65 23.26h3.45v.993h-3.45v-.993zM17.65 20.96h3.45v.993h-3.45v-.993zM17.65 18.66h3.45v.993h-3.45v-.993zM17.65 16.36h3.45v.993h-3.45v-.993zM17.65 14.06h3.45v.993h-3.45v-.993zM17.65 11.76h3.45v.993h-3.45v-.993zM17.65 9.46h3.45v.994h-3.45V9.46zM17.65 7.161h3.45v.993h-3.45v-.993zM16.237 26.72h3.45v.994h-3.45v-.993zM16.237 24.421h3.45v.993h-3.45v-.993zM16.237 22.121h3.45v.993h-3.45v-.993zM16.237 19.821h3.45v.993h-3.45v-.993zM16.237 17.522h3.45v.993h-3.45v-.993zM17.65 27.859h3.45v.993h-3.45v-.993zM16.237 15.222h3.45v.993h-3.45v-.993zM16.237 12.922h3.45v.993h-3.45v-.993zM16.237 10.622h3.45v.993h-3.45v-.993zM16.237 8.322h3.45v.993h-3.45v-.993zM16.237 29.018h3.45v.993h-3.45v-.993z" fill="#4D4D4D" />
                                        <path d="M20.653 4.627a.554.554 0 0 0-.762-.021l-.007.006.376.41.068-.061 1.85 1.715-1.661 1.52L18.974 6.2l.07-.064-.376-.41-.033.03a.552.552 0 0 0-.045.76l6.906 8.007a.515.515 0 0 0 .738.043l1.794-1.642a.512.512 0 0 0 .02-.738l-7.395-7.56zm6.07 8.678a1.171 1.171 0 1 1 .377-.813 1.17 1.17 0 0 1-.38.811l.003.002z" fill="url(#a)" />
                                        <path d="M18.096 3.802h1.24v3.054h-1.24V3.802z" fill="url(#b)" />
                                        <path d="M21.4 4.56a2.55 2.55 0 0 0-2.549-2.549h-.276a2.55 2.55 0 0 0-2.436 3.3v.006c.225.834.313 1.7.261 2.561a1.811 1.811 0 0 0 .641 1.271l.11-3.44a.593.593 0 0 1 .6-.552h.134v-1.57h1.653v1.57h.1a.6.6 0 0 1 .605.551l.121 3.462a1.813 1.813 0 0 0 .666-1.292V7.72a7.692 7.692 0 0 1 .236-2.359c.087-.258.133-.528.134-.8z" fill="url(#c)" />
                                        <defs>
                                            <linearGradient id="a" x1="149.285" y1="62.674" x2="158.118" y2="54.586" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#4D4D4D" />
                                                <stop offset=".5" stop-color="#fff" />
                                                <stop offset="1" stop-color="#4D4D4D" />
                                            </linearGradient>
                                            <linearGradient id="b" x1="40.535" y1="20.077" x2="42.072" y2="20.077" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#4D4D4D" />
                                                <stop offset=".5" stop-color="#fff" />
                                                <stop offset="1" stop-color="#4D4D4D" />
                                            </linearGradient>
                                            <linearGradient id="c" x1="102.163" y1="42.037" x2="131.042" y2="42.037" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#4D4D4D" />
                                                <stop offset=".5" stop-color="#fff" />
                                                <stop offset="1" stop-color="#4D4D4D" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    </p>
                                }
                            </Col>
                            <Col span={24} >
                                <p className="text-right md-10"><small className="grey-text">
                                    {" "}
                                    {moment(item[5]).format("h:mm a")}
                                </small></p>
                            </Col>

                        </Row>


                    </div>
                    :
                    <div className="Text-message-left  chat-message">
                        <Row>
                            <Col span={14} >
                                <h5 style={{ color: item[11], fontSize: "16px", fontWeight: "500" }}>{item[2]}</h5>
                                <p className="sub-heading-text word-break"> {item[3] !== "" ? item[3] : item[6].substring(0, 10)}</p>
                                <p className="sub-text">{parseFloat(item[9] / 1024).toFixed(2)} Kb</p>
                                {item[12] !== null && <p> <span className="cursor margin-right  tags-round cursor">{"#" + item[12]}</span></p>}
                            </Col>
                            <Col span={10} >

                                {
                                    item[7] === "pdf" ? <p className="doc-container" onClick={() => window.open(item[8])}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.852 15.492a.189.189 0 0 0 .23.053.189.189 0 0 0 .066-.053l2.625-3.32a.187.187 0 0 0-.148-.303h-1.737V3.938a.188.188 0 0 0-.187-.188h-1.407a.188.188 0 0 0-.187.188v7.928H9.375a.187.187 0 0 0-.148.303l2.625 3.323zm8.726-.82h-1.406a.188.188 0 0 0-.188.187v3.61H5.016v-3.61a.188.188 0 0 0-.188-.187H3.422a.188.188 0 0 0-.188.187V19.5c0 .415.336.75.75.75h16.032a.75.75 0 0 0 .75-.75v-4.64a.188.188 0 0 0-.188-.188z" fill="#000" fill-opacity=".6" />
                                        </svg>
                                    </p> : <p className="doc-container" onClick={() => window.open(item[8])}>  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27.667 27.667V17.333L23 15V2H4.333v28h23.334v-2.333zm-4.667 0v-9.111l2.333 1.222v7.889H23z" fill="#969696" />
                                        <path d="M17.65 25.56h3.45v.992h-3.45v-.993zM17.65 23.26h3.45v.993h-3.45v-.993zM17.65 20.96h3.45v.993h-3.45v-.993zM17.65 18.66h3.45v.993h-3.45v-.993zM17.65 16.36h3.45v.993h-3.45v-.993zM17.65 14.06h3.45v.993h-3.45v-.993zM17.65 11.76h3.45v.993h-3.45v-.993zM17.65 9.46h3.45v.994h-3.45V9.46zM17.65 7.161h3.45v.993h-3.45v-.993zM16.237 26.72h3.45v.994h-3.45v-.993zM16.237 24.421h3.45v.993h-3.45v-.993zM16.237 22.121h3.45v.993h-3.45v-.993zM16.237 19.821h3.45v.993h-3.45v-.993zM16.237 17.522h3.45v.993h-3.45v-.993zM17.65 27.859h3.45v.993h-3.45v-.993zM16.237 15.222h3.45v.993h-3.45v-.993zM16.237 12.922h3.45v.993h-3.45v-.993zM16.237 10.622h3.45v.993h-3.45v-.993zM16.237 8.322h3.45v.993h-3.45v-.993zM16.237 29.018h3.45v.993h-3.45v-.993z" fill="#4D4D4D" />
                                        <path d="M20.653 4.627a.554.554 0 0 0-.762-.021l-.007.006.376.41.068-.061 1.85 1.715-1.661 1.52L18.974 6.2l.07-.064-.376-.41-.033.03a.552.552 0 0 0-.045.76l6.906 8.007a.515.515 0 0 0 .738.043l1.794-1.642a.512.512 0 0 0 .02-.738l-7.395-7.56zm6.07 8.678a1.171 1.171 0 1 1 .377-.813 1.17 1.17 0 0 1-.38.811l.003.002z" fill="url(#a)" />
                                        <path d="M18.096 3.802h1.24v3.054h-1.24V3.802z" fill="url(#b)" />
                                        <path d="M21.4 4.56a2.55 2.55 0 0 0-2.549-2.549h-.276a2.55 2.55 0 0 0-2.436 3.3v.006c.225.834.313 1.7.261 2.561a1.811 1.811 0 0 0 .641 1.271l.11-3.44a.593.593 0 0 1 .6-.552h.134v-1.57h1.653v1.57h.1a.6.6 0 0 1 .605.551l.121 3.462a1.813 1.813 0 0 0 .666-1.292V7.72a7.692 7.692 0 0 1 .236-2.359c.087-.258.133-.528.134-.8z" fill="url(#c)" />
                                        <defs>
                                            <linearGradient id="a" x1="149.285" y1="62.674" x2="158.118" y2="54.586" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#4D4D4D" />
                                                <stop offset=".5" stop-color="#fff" />
                                                <stop offset="1" stop-color="#4D4D4D" />
                                            </linearGradient>
                                            <linearGradient id="b" x1="40.535" y1="20.077" x2="42.072" y2="20.077" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#4D4D4D" />
                                                <stop offset=".5" stop-color="#fff" />
                                                <stop offset="1" stop-color="#4D4D4D" />
                                            </linearGradient>
                                            <linearGradient id="c" x1="102.163" y1="42.037" x2="131.042" y2="42.037" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#4D4D4D" />
                                                <stop offset=".5" stop-color="#fff" />
                                                <stop offset="1" stop-color="#4D4D4D" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    </p>
                                }


                            </Col>
                            <Col span={24} >
                                <p className="text-right md-10"><small className="grey-text">
                                    {" "}
                                    {moment(item[5]).format("h:mm a")}
                                </small></p>
                            </Col>
                        </Row>
                    </div>
            }
        </Col>
    </Row>
}

export default MessageDocCard;