import React, { useState, useEffect } from "react";
import { Tag, Button, Col, Row, Space, message, Popconfirm } from "antd";
import axios from "../../store/axios";
import Cookies from "js-cookie";
import moment from "moment";
import { Question } from "@phosphor-icons/react";
import BlankState from "../BlankState";



const MentorshipCollaborate = () => {
    const [data, setData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loader, setLoader] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const key = 'update';

    const sendRequestGain = (id, action) => {
        messageApi.open({
            key,
            type: 'loading',
            content: 'Updating...',
        });
        axios({
            method: 'put',
            url:  `v1.0/mentorship/${id}/collaborate`,
            data: {
                action: action
            },
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            messageApi.open({
                key,
                type: 'success',
                content: res.data.message,
                duration: 10,
            });
            setTimeout(()=>{
                setOffset(0)
                MentorshipAPI();
            }, 1000)
            
        }).catch((error) => {
            messageApi.open({
                key,
                type: 'error',
                content: error.response.data.message,
                duration: 2,
            });
        })
    }

    useEffect(() => {
        MentorshipAPI();
    }, [])

    function MentorshipAPI() {
        axios({
            method: 'get',
            url:  "v1.0/mentorship/collaborations?count=10&offset=" + offset,
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setData(data)
        })
        setOffset(offset + 10)
    }
    return (
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} >
            <Row>
                {
                    data.map((item, i) => {
                        return <Col key={i} span={24} className='hub-collab'>
                            <Row>

                                <Col span={18} className='padding20'>
                                    <p className="fs16 fw500 cursor" onClick={() => window.location.href = "/mentorship/" + item?.mentorship_d}>{item.name}</p>
                                    <p className="fs12 fw400 md10">{item.amount > 0 && "₹" + item.amount + " | "} {item.tenure} months | {moment(item.updated_at).format("DD MMM YYYY")}</p>
                                    {
                                        item.status === "ACPT" ? <Tag color="green" className="md25">Active</Tag> :
                                            item.status === "DECL" ? <Tag color="red" className="md25">Declined</Tag> : <Tag color="orange" className="md25">Pending</Tag>
                                    }

                                </Col>
                                {
                                    item.status === "PEND" ? <Col span={6} className='text-end padding20'> {contextHolder}
                                        <Space direction="vertical" size={20}>
                                            <Button type="primary" className="md15" onClick={() => sendRequestGain(item.mentorship_d, "ACPT")} >Accept</Button>
                                            <Popconfirm
                                            placement="bottom"
                                                title="Are you sure to decline this request?"
                                                description="Are you sure to decline this request?"
                                                icon={<Question size={20} color="red" weight="light" />}
                                                onConfirm={() => sendRequestGain(item.mentorship_d, "DECL")}
                                                okText="Yes, Decline"
                                                cancelText="Don't"
                                            >
                                                <Button type="ghost" >Decline</Button>
                                            </Popconfirm>
                                        </Space>

                                    </Col> :
                                        <Col span={6} className='text-end bg-imge'
                                            style={{ backgroundImage: "url('" + item?.img_url + "')" }}>
                                        </Col>
                                }


                            </Row>

                        </Col>

                    })
                }
                {
                        data?.length === 0 &&
                        <Col span={24} className=" md60 text-center">
                            <BlankState img={require("../../assets/images/blank/no-data-found.svg").default} height={200} />
        
                        </Col>
        
                }


            </Row>
        </Col>
    );
}
export default MentorshipCollaborate;