import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import actions from "../../../store/actions/index";
import { withRouter } from "react-router";
import { Row, Col, Radio, Button, Steps, Input, Image, Checkbox, Tag, Tooltip, Modal, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import '../style.scss'
import { ArrowLeft } from "@phosphor-icons/react";
const { TextArea } = Input;
const { Step } = Steps;


const ChannelCreateV3 = (props) => {
    // const [loader, setLoader] = useState(true);
    const [step, setStep] = useState(0);
    const [freepaid, setfreepaid] = useState(false);
    const [channel_privacy, setchannel_privacy] = useState(1);
    const [channel_categories, setChannel_categories] = useState([]);
    // const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [newCategories, setNewCategories] = useState([]);
    const [isModalVisible, setisModalVisible] = useState(false);
    const [bankModal, setBankModal] = useState(false);
    const [newTag, setNewTag] = useState("");

    useEffect(() => {
        setChannel_categories(props._channel_category)
    }, [props._channel_category]);

    useEffect(() => {
        props.channelCategories().then(() => {
        })
    }, []);

    const pushCategories = (obj) => {
        if (obj.name === "") {
            return
        }
        let arr = selectedCategories
        arr.push(obj)
        setSelectedCategories(arr)

        if (obj.category_id === null) {
            let newarr = newCategories
            newarr.push(obj)
            setNewCategories(newarr)
            setisModalVisible(false)
            setNewTag("")
        }

    }


    return (


        <Row>
            <Col span={24} className="channel-details-v3-1" >
                <Row>
                    <Col lg={{ span: 22, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                        <Row>
                            <Col span={24} className="channel-details-v3 mb-5">


                                <Row className='m-px10' style={{ backgroundColor: "#fff" }}>
                                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 3 }} style={{ marginTop: "52px" }} >
                                        <Row className='create-channel-v3 '>
                                            <Col md={24} xs={24}>
                                                <Button type="link" shape="circle" className="fs-16" style={{ color: "#504e4e", fontWeight: "400" }} onClick={() => this.props.history.goBack()} icon={<ArrowLeft size={20} color="#030303" weight="light" style={{ marginRight: "5px" }} />} > Go Back</Button>

                                            </Col>
                                            <Col md={12} xs={12} className='mt-4'>
                                                <h5 className='headingn'>Create a channel</h5>
                                                <p className='heading-desc'>In publishing and graphic design, Lorem ipsum is a placeholder</p>

                                            </Col>
                                            <Col md={12} xs={12} className='mt-4' style={{ textAlign: "right" }}>
                                                <Steps className='steps' current={step}>
                                                    <Step />
                                                    <Step />
                                                </Steps>
                                            </Col>
                                            <Col md={24} xs={24} className='boxed mt-4'>


                                                {
                                                    step === 0
                                                        ? <><Row className='md30 mob-text-center '>
                                                            <Col md={14} xs={24} >
                                                                <h5 className='heading14 mt-1'>Channel Name</h5>
                                                                <Input placeholder="Enter name of the channel" className='channelborder input-title' bordered={false} showCount maxLength={250} />
                                                                <h5 className='heading14 mt-5'>Channel Description</h5>
                                                                <TextArea placeholder="Enter description of the channel" className='channelborder' bordered={false} showCount maxLength={250} />
                                                                <h5 className='heading14  mt-5' style={{ marginTop: '80px' }}>Channel Category</h5>
                                                                <p className='heading-desc'>In publishing and graphic design, Lorem ipsum is a placeholder</p>
                                                            </Col>

                                                            <Col span={3}  > </Col>
                                                            <Col md={7} xs={24} className=" px-4 m-md24 ">
                                                                <div className='image-box'>
                                                                    <div className='text-center'>
                                                                        <div className='dotted'>
                                                                            <ImgCrop rotate className='dotted'>
                                                                                <Upload
                                                                                    name="avatar"
                                                                                    multiple={false}
                                                                                    listType="picture-card"
                                                                                    style={{ borderRadius: "100px" }}
                                                                                    showUploadList={true}
                                                                                    shape="circle"
                                                                                    Tooltip={false}
                                                                                    {...props}
                                                                                >
                                                                                    {<p className="sub-text"> + <br></br> Upload Image</p>}
                                                                                </Upload>
                                                                            </ImgCrop>

                                                                        </div>
                                                                    </div>
                                                                    <ol className='rules'>
                                                                        <li>Image must be in jpg, jpeg or png format.</li>
                                                                        <li>Image size should not be greater than 500 Kb.</li>
                                                                        <li>Not be greater than height: 300px and width: 300px</li>
                                                                    </ol>


                                                                </div>

                                                            </Col>
                                                        </Row>
                                                            <Row gutter={16} className="mt-2 no-border">
                                                                <Checkbox.Group
                                                                    className='mob-text-center m-md10'
                                                                    defaultValue="a"
                                                                    // value={categories}
                                                                    onChange={(evt) => pushCategories(evt[0])}
                                                                >
                                                                    {
                                                                        channel_categories.map((item) => {
                                                                            return <Checkbox value={item} className="no-color">
                                                                                <div className="channel-radio">
                                                                                    <Image preview={false} src={item.icon}></Image>
                                                                                </div>
                                                                                {item.name}
                                                                            </Checkbox>
                                                                        })
                                                                    }
                                                                    <Tooltip title="Add custom category">
                                                                        <Button onClick={() => setisModalVisible(true)} className='channel-radio m-md10' style={{ verticalAlign: "middle", marginTop: "10px" }}>
                                                                            +
                                                                        </Button>
                                                                    </Tooltip>


                                                                </Checkbox.Group>
                                                            </Row>
                                                            <Row className='md20'>
                                                                <Col md={24} xs={22} className="added-tags mob-text-center">
                                                                    {
                                                                        newCategories.map((item) => {
                                                                            return <Tag closable>
                                                                                {item.name}
                                                                            </Tag>
                                                                        })
                                                                    }
                                                                </Col>

                                                                <Col md={24} xs={24} className='text-right md50 mob-text-center'>
                                                                    <Button type="primary" className="btn btn-default-noround create-btn m-create-btn fs16" onClick={() => setStep(1)} style={{ color: "white" }}>Next</Button>
                                                                </Col>
                                                            </Row></>
                                                        : <><Row className='md30'>
                                                            
                                                            <Col md={14} xs={24}>
                                                                <h4 className='fs16 '>Is this channel private or public?</h4>
                                                                <p>In publishing and graphic design, Lorem ipsum is a placeholder</p>
                                                                <Radio.Group
                                                                    defaultValue="a"
                                                                    buttonStyle="solid"
                                                                    className='md20'
                                                                    style={{ display: "inline-flex" }}
                                                                    value={channel_privacy}
                                                                    onChange={(evt) => setchannel_privacy(evt.target.value)}
                                                                >
                                                                    <Radio.Button value={1} className="no-color iconed-radio ">
                                                                        <div className="channel-radio">
                                                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M20 4a8 8 0 1 0 6.246 13H20v-2h7.42c.254-.63.43-1.3.52-2H20v-2h7.94a7.95 7.95 0 0 0-.52-2H20V7h6.246A7.987 7.987 0 0 0 20 4zM10.018 22A4.001 4.001 0 0 0 6 26c0 3.382 1.666 5.932 4.27 7.594C12.834 35.228 16.29 36 20 36c3.388 0 6.564-.644 9.04-2H20v-2h11.672a8.509 8.509 0 0 0 1.416-2.01H20v-2h13.792A9.38 9.38 0 0 0 34 26v-.01H20v-2h13.46A4 4 0 0 0 30 22H10.018z" fill="#000" fill-opacity=".7" />
                                                                            </svg>
                                                                        </div>
                                                                        Private
                                                                    </Radio.Button>{" "}
                                                                    <br></br>
                                                                    <Radio.Button
                                                                        value={0}
                                                                        className="no-color iconed-radio">
                                                                        <div className="channel-radio">
                                                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M20 3.333C10.8 3.333 3.333 10.8 3.333 20c0 9.2 7.467 16.666 16.667 16.666 9.2 0 16.667-7.466 16.667-16.666S29.2 3.333 20 3.333zm-1.667 29.883C11.75 32.4 6.667 26.8 6.667 20c0-1.034.133-2.017.35-2.984L15 25v1.666C15 28.5 16.5 30 18.333 30v3.216zm11.5-4.233a3.307 3.307 0 0 0-3.166-2.317H25v-5C25 20.75 24.25 20 23.333 20h-10v-3.334h3.334c.916 0 1.666-.75 1.666-1.666v-3.334h3.334c1.833 0 3.333-1.5 3.333-3.333V7.65c4.883 1.983 8.333 6.766 8.333 12.35 0 3.466-1.333 6.616-3.5 8.983z" fill="#545454" />
                                                                            </svg>
                                                                        </div>

                                                                        Public
                                                                    </Radio.Button>{" "}
                                                                    <br></br>
                                                                </Radio.Group>

                                                                <h5 className='fs16' style={{ marginTop: '120px' }}>Is this channel free or paid?</h5>
                                                                <p className='heading-desc'>In publishing and graphic design, Lorem ipsum is a placeholder</p>
                                                            </Col>

                                                            <Col span={3}> </Col>
                                                            <Col md={7} xs={24} className="px-4 m-md24">
                                                                <div className='image-box'>
                                                                    <div className='text-center'>
                                                                        <div className='dotted'>
                                                                            <ImgCrop rotate className='dotted'>
                                                                                <Upload
                                                                                    name="avatar"
                                                                                    multiple={false}
                                                                                    listType="picture-card"
                                                                                    style={{ borderRadius: "100px" }}
                                                                                    showUploadList={true}
                                                                                    shape="circle"
                                                                                    Tooltip={false}
                                                                                    {...props}
                                                                                >
                                                                                    {<p className="sub-text"> + <br></br> Upload Image</p>}
                                                                                </Upload>
                                                                            </ImgCrop>
                                                                        </div>
                                                                    </div>
                                                                    <ol className='rules'>
                                                                        <li>Image must be in jpg, jpeg or png format.</li>
                                                                        <li>Image size should not be greater than 500 Kb.</li>
                                                                        <li>Not be greater than height: 300px and width: 300px</li>
                                                                    </ol>


                                                                </div>

                                                            </Col>
                                                        </Row>
                                                            <Row gutter={5}>
                                                                <Col md={6} xs={24} className='m-md24 radio-text'>
                                                                    <Radio.Group style={{ width: "100%" }} value={freepaid} onChange={(evt) => setfreepaid(evt.target.value)}>
                                                                        <Radio value={false} >Free</Radio>
                                                                        <Radio value={true}>Paid</Radio>
                                                                    </Radio.Group>
                                                                </Col>

                                                                {
                                                                    freepaid === true ? <Col span={4}  >
                                                                        <Input placeholder="4999" className='channelborder fs16' bordered={true} showCount maxLength={250} />

                                                                    </Col> : <></>
                                                                }


                                                            </Row>
                                                            <Row gutter={8} className='md40'>
                                                                <Col md={24} xs={24} className='radio-text'>
                                                                    <h5 className='fs16' > Subscription Tenure</h5>
                                                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder</p>
                                                                    <Radio.Group style={{ width: "100%", marginTop: "20px" }} defaultValue={0}>
                                                                        <Radio value={0}>No Limit</Radio>
                                                                        <Radio value={1}>1 Month</Radio>
                                                                        <Radio value={6} className="m-md10">6 Months</Radio>
                                                                        <Radio value={12}>1 Year</Radio>
                                                                    </Radio.Group>
                                                                </Col>

                                                            </Row>
                                                            {
                                                                freepaid === true ? <>
                                                                    <Row className='md40'>
                                                                        <Col md={24} xs={24}>
                                                                            <h5 className='fs16'>Select Bank Account</h5>
                                                                            <p>All Channel money will be accepted on this selected account</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={24} xs={24} className='mt-3'>
                                                                            <Radio.Group
                                                                                defaultValue={0}
                                                                                style={{ width: "100%" }}
                                                                                className={"bank-radio"}>
                                                                                <Radio className="md20" value={1}>
                                                                                    State Bank of India <br></br> <span className="grey-text"> XXXX-XXX-34</span>
                                                                                </Radio>
                                                                                <Radio className="md20" value={2}>
                                                                                    State Bank of India <br></br> <span className="grey-text"> XXXX-XXX-34</span>
                                                                                </Radio>

                                                                            </Radio.Group>
                                                                        </Col>
                                                                        <Col md={4} xs={24} className='text-right md20'>
                                                                            <Button onClick={() => setBankModal(true)} type="primar" className="btn btn-default-noround create-btn fs16 bg-btn ">
                                                                                + add bank</Button>
                                                                        </Col>
                                                                    </Row></> : <></>
                                                            }

                                                            <Row>
                                                                <Col md={24} xs={12} className='text-right md50 mob-text-center'>
                                                                    <Button type="primary" className="btn btn-default-noround create-btn m-create-btn fs16 bg-previous" onClick={() => setStep(0)} >
                                                                        Previous</Button>
                                                                </Col>
                                                                <Col md={4} xs={12} className='text-right md50 mob-text-center'>
                                                                    <Button type="primary" className="btn btn-default-noround create-btn m-create-btn fs16" onClick={() => setStep(1)} style={{ color: "white" }}>Next</Button>

                                                                </Col>
                                                            </Row></>
                                                }
                                            </Col>
                                        </Row>


                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <Modal title="Add Category" visible={isModalVisible} footer={false} onCancel={() => setisModalVisible(false)}>
                            <Row className='md50'>
                                <Col span={18} offset={3} className={'text-center'}>
                                    <Input value={newTag} onChange={(e) => setNewTag(e.target.value)} placeholder='Enter category'></Input>
                                    <Button className='md20 mb-5 px-5' onClick={() => pushCategories({ category_id: null, name: newTag })}> Add </Button>
                                </Col>
                            </Row>
                        </Modal>

                        {/* add bank modal */}
                        <Modal title="Enter Payment Details" visible={bankModal} footer={false} onCancel={() => setBankModal(false)}>
                            <Row className='md50'>
                                <Col span={18} offset={3}>
                                    <p>Account Number</p>
                                    <Input value={newTag} placeholder='Enter Account Number'></Input>
                                    <p className='mt-3'>Account Name</p>
                                    <Input value={newTag} placeholder='Enter Account Name'></Input>
                                    <p className='mt-3'>IFSC</p>
                                    <Input value={newTag} placeholder='IFSC'></Input>
                                    <Button onClick={() => setBankModal(false)} className='md20 mb-5 px-5' > Add </Button>
                                </Col>
                            </Row>
                        </Modal>

                    </Col>


                </Row>


            </Col>

        </Row>

    );
}

const mapStateToProps = (state) => ({
    _bank: state.profile.bank_details,
    _user_bank: state.profile.user_bank,
    _channel_category: state.profile.channel_category
});
const mapDispatchToProps = (dispatch) => ({
    getAccountDetails: (v) => dispatch(actions.getAccountDetails(v)),
    getBankDetails: (v) => dispatch(actions.getBankDetails(v)),
    addBank: (v) => dispatch(actions.addBank(v)),
    channelCategories: (v) => dispatch(actions.channelCategories(v)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ChannelCreateV3)
);
