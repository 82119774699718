
import React from "react";
import { Avatar, Badge, Button, Col, Row, Space } from "antd";
import moment from "moment";
import { DotsThreeCircleVertical, DotsThreeOutlineVertical, PlusCircle } from "@phosphor-icons/react";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";

const MessageDiscussCard = ({ oOpenDission, item, userCode }) => {

    return <Row className="md20 cursor" onClick={() => oOpenDission(item)}>
        <Col span={24} >
            {
                item[4] === userCode ?
                    <div className="Text-message-right chat-message-me chat-message-discussion">
                        {/* <Row className="discussion-head">                            
                            <Col span={24} className="text-right" style={{ color: "#ffffffb3" }}>
                                <Space align="center">
                                    <p>{item[13]} replies</p>
                                    <i className="fa fa-circle" style={{ fontSize: "7px", color: "#ffffffb3" }}></i>
                                    <img src={require('../../assets/images/channel/discussion-white.svg').default} alt="" />
                                </Space>
                            </Col>
                        </Row> */}
                        <Row style={{ padding: '12px' }}>
                            <Col span={18} >
                                <p className="sub-heading-text word-break md5">{item[3]}</p>
                            </Col>
                            <Col span={6} className="text-right">
                                <Button type="text" className="discu md5" icon={<DotsThreeOutlineVertical size={12} color="#000000" weight="fill" />} style={{ color: '#000009' }}></Button>
                            </Col>
                            {/* <Col span={10} >
                                {item[12] !== null && <p> <span className="cursor margin-right  tags-round cursor">{"#" + item[12]}</span></p>}
                            </Col> */}
                            <Col span={24} className="md15">
                                <Row gutter={8}>
                                    <Col lg={3} xxs={5} md={3}>
                                        <Avatar size={40} className="md20" />
                                    </Col>
                                    <Col lg={21} xxs={19} md={21} className="m-pl15">
                                        <p className="fs12 fw500 md20 "> {item[2]} </p>
                                        <Space className="">
                                            <p className="fs10 fw400 text-grey"> {moment(item[5]).format("yyy/MM/d h:mm a")}</p>
                                            <Badge status="default" />
                                            {
                                                item[13] > 0 && <p className="fs10 fw400"> {item[13]}+ replies </p>
                                            }
                                            
                                            {/* <p className="text-right "><small className="grey-text">
                                                {" "}
                                                {moment(item[5]).format("h:mm a")}
                                            </small></p> */}
                                        </Space>

                                    </Col>

                                   
                                </Row>
                            </Col>

                        </Row>
                    </div >
                    :
                    <div className="Text-message-left chat-message chat-message-discussion">
                        {/* <Row className="discussion-head">
                            <Col span={16}>
                                <p style={{ color: 'white', fontSize: "16px", fontWeight: "500" }}>{item[2]}</p>
                            </Col>
                            <Col span={8} className="text-right" style={{ color: "#ffffffb3" }}>
                                <Space align="center">
                                    <p> {item[13]} replies</p>
                                    <i className="fa fa-circle" style={{ fontSize: "7px", color: "#ffffffb3" }}></i>
                                    <img src={require('../../assets/images/channel/discussion-white.svg').default} alt="" />
                                </Space>
                            </Col>
                        </Row> */}
                        <Row style={{ padding: '12px' }}>
                            <Col span={18} >
                                <p className="sub-heading-text word-break md5">{item[3]}</p>
                            </Col>
                            <Col span={6} className="text-right">
                                <Button type="text" className="discu md5" icon={<DotsThreeOutlineVertical size={12} color="#000000" weight="fill" />} style={{ color: '#000009' }}></Button>
                            </Col>
                            {/* <Col span={10} >
                                {item[12] !== null && <p> <span className="cursor margin-right  tags-round cursor">{"#" + item[12]}</span></p>}
                            </Col> */}
                            <Col span={24} className="md15">
                                <Row gutter={16}>
                                    <Col span={3} className="ps-1">
                                        <Avatar size={40} className="md20" />
                                    </Col>
                                    <Col span={14} >
                                        <p className="fs12 fw500 md20">  {item[2]} </p>
                                        <Space className="">
                                            <p className="fs10 fw400 text-grey"> {moment(item[5]).format("yyy/MM/d h:mm a")}</p>
                                            <Badge status="default" />
                                            {
                                                item[13] > 0 && <p className="fs10 fw400"> {item[13]}+ replies </p>
                                            }
                                            
                                            {/* <p className="text-right "><small className="grey-text">
                                                {" "}
                                                {moment(item[5]).format("h:mm a")}
                                            </small></p> */}
                                        </Space>

                                    </Col>

                                    
                                </Row>
                            </Col>

                        </Row>
                    </div>
            }
        </Col >
    </Row >
}

export default MessageDiscussCard;