import axios from "axios";
import "antd/dist/antd.css";

import Nanobar from "nanobar";
import Toast from "../components/utils/Toast";
// import * as Sentry from "@sentry/browser";


var options = {
    classname: "my-class",
    id: "my-id",
    target: document.getElementById("myDivId"),
};
var nanobar = new Nanobar(options);


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const _error = (_message) => {
    Toast(_message,"error")
};

axiosInstance.interceptors.request.use(
    async function (config) {
        nanobar.go(50);
        config.withCredentials = true;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        nanobar.go(100);
        return response;
    },
    function (error) {
        if (!error.response) {
            Toast("Error: Network Error","error")
            // Sentry.captureException(error);
            nanobar.go(100);
            return
        }
        if (error.response.status === 401) {
            Toast("Session Expired Login Again","error")
            window.location.href = "/login";
            return
        }
        if (error.response.status === 403) {
            // Toast("Session Expired Login Again","error")
            window.location.href = "/login";
            return
        }
        if (error.response.status === 413) {
            Toast("File size exceeded","error");
            return
        }
        else {
            _error(error.response.data.message)
            nanobar.go(100);
            // Sentry.captureException(error);
        }
        return Promise.reject(error);
    }
);
export default axiosInstance;
