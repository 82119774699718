import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import actions from "../../store/actions/index";
import { withRouter } from "react-router";
import { Col, Row, Avatar, Space, Menu, Button, Drawer } from "antd";
import './settings.scss'
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import BankAccounts from "./BankAccounts";
import PushNotification from "./PushNotification";
import TagPreferences from "./TagPreferences";
import Cookies from "js-cookie";
import { MenuFoldOutlined } from "@ant-design/icons";
import InsightsProfile from "./InsightsProfile";
import PostProfile from "./PostProfile";
// import Payments from "../Payments/Payments";
import Subscription from "../Subscription/Subscription";
import MembershipTierRedirect from "./MembershipTierRedirect";
import EmailSettings from "./EmailSettings";
import SettingSubscriptions from "./SettingSubscriptions";
import { GearSix } from "@phosphor-icons/react";
import SettingConnect from "./SocialApps/SettingApps";
import Connection from "./Connection";
import axios from "../../store/axios";





const Settings = () => {
    const [loader, setloader] = useState(true);
    const [setting, setSetting] = useState([]);
    const [current, setCurrent] = useState("1");
    const [openDrawer, setOpenDrawer] = useState(false);
    const role = parseInt(Cookies.get('__role'));

    useEffect(() => {
        axios({
            method: 'get',
            url: "account/settings",
            headers: {
                Authorization: Cookies.get("Logintoken")
            }
        }).then(res => {
            const data = res.data.data
            setSetting(data)
            localStorage.setItem("profile", JSON.stringify(data))
            setloader(false)
        })
    }, [])




    function onSelectTab(e) {
        setCurrent(e.key)
        setOpenDrawer(false)
    }


    const settings_menu = (
        <Menu className="setting-menu" defaultSelectedKeys={[current]} onSelect={(e) => onSelectTab(e)}>
            <Space aria-orientation="horizontal">
                <Avatar shape="square" src={<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 2.125C6.74438 2.125 5.3125 3.55688 5.3125 5.3125C5.3125 7.06812 6.74438 8.5 8.5 8.5C10.2556 8.5 11.6875 7.06812 11.6875 5.3125C11.6875 3.55688 10.2556 2.125 8.5 2.125ZM8.5 8.5C5.57397 8.5 3.1875 10.8865 3.1875 13.8125H4.25C4.25 11.4592 6.14673 9.5625 8.5 9.5625C10.8533 9.5625 12.75 11.4592 12.75 13.8125H13.8125C13.8125 10.8865 11.426 8.5 8.5 8.5ZM8.5 3.1875C9.67871 3.1875 10.625 4.13379 10.625 5.3125C10.625 6.49121 9.67871 7.4375 8.5 7.4375C7.32129 7.4375 6.375 6.49121 6.375 5.3125C6.375 4.13379 7.32129 3.1875 8.5 3.1875Z" fill="#7D7D7D" />
                </svg>
                } size={17}></Avatar>
                <p className="menu-head">Personal</p>
            </Space>
            <Menu.Item key={'1'}>Profile</Menu.Item>
            <Menu.Item key={'2'}>Change Password</Menu.Item>

            {
                role === 8 && <Menu.Item key={'9'}>Connection</Menu.Item>
            }
            {/* {
                role !== 7 && <>
                    <Menu.Item key={'3'}>Bank Account</Menu.Item>
                </>
            } */}

            {/* <Menu.Item key={'14'}>Subscriptions</Menu.Item> */}

            <Space aria-orientation="horizontal" className="md10">
                <Avatar shape="square" src={<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.70977 2.83301C3.41638 2.83304 3.17855 3.07087 3.17852 3.36426C3.17852 4.0549 2.62958 4.60384 1.93893 4.60384C1.64554 4.60387 1.40771 4.8417 1.40768 5.13509V11.8643C1.40771 12.1576 1.64554 12.3955 1.93893 12.3955C2.62958 12.3955 3.17852 12.9444 3.17852 13.6351C3.17855 13.9285 3.41638 14.1663 3.70977 14.1663H13.2723C13.5657 14.1663 13.8035 13.9285 13.8035 13.6351C13.8035 12.9444 14.3525 12.3955 15.0431 12.3955C15.3365 12.3955 15.5743 12.1576 15.5744 11.8643V5.13509C15.5743 4.8417 15.3365 4.60387 15.0431 4.60384C14.3525 4.60384 13.8035 4.05491 13.8035 3.36426C13.8035 3.07087 13.5657 2.83304 13.2723 2.83301H3.70977ZM4.13311 3.89551H12.8489C13.053 4.72442 13.6829 5.35438 14.5119 5.55843V11.4409C13.6829 11.645 13.053 12.2749 12.8489 13.1038H4.13311C3.92909 12.2751 3.29892 11.6451 2.47018 11.4409V5.55843C3.29892 5.35427 3.92909 4.72429 4.13311 3.89551ZM4.07293 6.72884C3.88134 6.72613 3.70313 6.82679 3.60655 6.99227C3.50996 7.15776 3.50996 7.36243 3.60655 7.52791C3.70313 7.69339 3.88134 7.79405 4.07293 7.79134H12.9271C13.1187 7.79405 13.2969 7.69339 13.3935 7.52791C13.4901 7.36243 13.4901 7.15776 13.3935 6.99227C13.2969 6.82679 13.1187 6.72613 12.9271 6.72884H4.07293ZM5.13543 9.20801C4.94384 9.2053 4.76563 9.30596 4.66905 9.47144C4.57246 9.63692 4.57246 9.84159 4.66905 10.0071C4.76563 10.1726 4.94384 10.2732 5.13543 10.2705H11.8646C12.0562 10.2732 12.2344 10.1726 12.331 10.0071C12.4276 9.84159 12.4276 9.63692 12.331 9.47144C12.2344 9.30596 12.0562 9.2053 11.8646 9.20801H5.13543Z" fill="#7D7D7D" />
                </svg>
                } size={17}></Avatar>
                <p className="menu-head">Feed</p>
            </Space>
            <Menu.Item key={'4'}>User Preferences</Menu.Item>
            <Menu.Item key={'8'}>Apps</Menu.Item>


            <Space aria-orientation="horizontal" className="md10">
                <Avatar shape="square" src={require('../../assets/images/newdashboard/insights.svg').default
                } size={17}></Avatar>
                <p className="menu-head">Insights</p>
            </Space>
            <Menu.Item key={'6'}>Profile</Menu.Item>
            <Menu.Item key={'7'}>Post</Menu.Item>

            {
                role !== 7 && <Space aria-orientation="horizontal" className="m10">
                    <Avatar shape="square" src={<svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.1915 1.54167H8.30729L8.75 0.125H0.69271L0.250001 1.54167H3.08333C4.17204 1.54167 5.0865 2.24787 5.42013 3.22396H0.69271L0.250001 4.64062H5.47467C5.19842 5.70808 4.23721 6.5 3.08333 6.5H0.25V7.588L5.47396 12.875H7.33333V12.6979L2.375 7.73958C2.80992 7.73958 3.32629 7.73958 3.4375 7.73958C5.27917 7.73958 6.79713 6.398 7.09392 4.64062H8.30729L8.75 3.22396H7.06558C6.92604 2.58362 6.61438 2.012 6.1915 1.54167L6.1915 1.54167Z" fill="#7D7D7D" />
                    </svg>

                    } size={17}></Avatar>

                    <p className="menu-head">Membership</p>
                </Space>
            }

            {
                role !== 7 && <Menu.Item key={'15'}>Membership Programs</Menu.Item>
            }

            {/* <Menu.Item key={'16'}>Subscriptions</Menu.Item> */}
            {/* <Space aria-orientation="horizontal" className="md10">
                <Avatar shape="square" src={<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.50001 1.41699C7.91351 1.41699 7.43751 1.89299 7.43751 2.47949V2.97201C5.60518 3.44431 4.25001 5.10372 4.25001 7.08366V11.3337L2.83334 12.7503V13.4587H7.27426C7.14953 13.6739 7.08368 13.9182 7.08334 14.167C7.08334 14.9494 7.71761 15.5837 8.50001 15.5837C9.28241 15.5837 9.91668 14.9494 9.91668 14.167C9.91591 13.9181 9.84959 13.6738 9.72437 13.4587H14.1667V12.7503L12.75 11.3337V7.08366C12.75 5.10372 11.3948 3.44431 9.56251 2.97201V2.47949C9.56251 1.89299 9.08651 1.41699 8.50001 1.41699ZM8.50001 4.25033C10.0626 4.25033 11.3333 5.52108 11.3333 7.08366V11.3337V11.9202L11.4551 12.042H5.54493L5.66668 11.9202V11.3337V7.08366C5.66668 5.52108 6.93743 4.25033 8.50001 4.25033H8.50001Z" fill="#7D7D7D" />
                </svg>
                } size={17}></Avatar>
                <p className="menu-head">Notifications & Email</p>
            </Space> */}
            {/* <Menu.Item key={'5'}>Notifications</Menu.Item>
            <Menu.Item key={'18'}>Email Settings</Menu.Item>

            <Space aria-orientation="horizontal" className="m10">
                <Avatar shape="square" src={<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.96875 1.0625C7.24243 1.0625 6.62817 1.56055 6.44141 2.23291C6.25464 2.17065 6.05957 2.125 5.84375 2.125C4.96802 2.125 4.25 2.84302 4.25 3.71875C4.25 3.90967 4.2832 4.08813 4.34546 4.25H2.65625C1.84277 4.25 1.0625 4.79785 1.0625 5.62378V13.5012C1.0625 14.3271 1.84277 14.875 2.65625 14.875H13.2813C14.0947 14.875 14.875 14.3313 14.875 13.5012V5.62378C14.875 4.79785 14.0947 4.25 13.2813 4.25H11.592C11.6543 4.08813 11.6875 3.90967 11.6875 3.71875C11.6875 2.84302 10.9695 2.125 10.0938 2.125C9.87793 2.125 9.68286 2.17066 9.49609 2.23291C9.30933 1.56055 8.69507 1.0625 7.96875 1.0625H7.96875ZM7.96875 2.125C8.26758 2.125 8.5 2.35742 8.5 2.65625C8.5 2.6438 8.46265 2.83887 8.37549 3.05469C8.37134 3.06299 8.36304 3.07544 8.35889 3.08374C8.17627 3.29956 8.05176 3.48633 7.96875 3.62744C7.88574 3.48633 7.76123 3.29956 7.57861 3.08374C7.57446 3.07544 7.56616 3.06299 7.56201 3.05469C7.47485 2.83887 7.4375 2.6438 7.4375 2.65625C7.4375 2.35742 7.66992 2.125 7.96875 2.125ZM5.84375 3.1875C6.12598 3.1875 6.43726 3.40332 6.69043 3.6814C6.77759 3.87231 6.8689 4.06738 6.96436 4.25H6.19653C6.19653 4.25 5.92261 4.2417 5.67773 4.15039C5.55322 4.10474 5.45361 4.04248 5.39966 3.98023C5.3457 3.91797 5.3125 3.86816 5.3125 3.71875C5.3125 3.41992 5.54492 3.1875 5.84375 3.1875H5.84375ZM10.0937 3.1875C10.3926 3.1875 10.625 3.41992 10.625 3.71875C10.625 3.86816 10.5918 3.91797 10.5378 3.98023C10.4839 4.04248 10.3843 4.10474 10.2598 4.15039C10.0149 4.2417 9.74097 4.25 9.74097 4.25H8.97314C9.0686 4.06738 9.15991 3.87231 9.24707 3.6814C9.50024 3.40332 9.81152 3.1875 10.0938 3.1875H10.0937ZM2.65625 5.3125H7.4375V7.4375H2.125V5.62378C2.125 5.52417 2.29932 5.3125 2.65625 5.3125ZM8.5 5.3125H13.2812C13.6423 5.3125 13.8125 5.52002 13.8125 5.62378V7.4375H8.5V5.3125ZM2.125 8.5H13.8125V13.5012C13.8125 13.605 13.6382 13.8125 13.2812 13.8125H8.5V9.5625H7.4375V13.8125H2.65625C2.29517 13.8125 2.125 13.605 2.125 13.5012V8.5Z" fill="#7D7D7D" />
                </svg>
                } size={17}></Avatar>
                <p className="menu-head">Rewards</p>
            </Space> */}
            {/* <Menu.Item key={'8'}>Refer & Earn</Menu.Item>
            <Menu.Item key={'9'}>Coins</Menu.Item>
*/}
            <Space aria-orientation="horizontal" className="m10">
                <Avatar shape="square" src={require('../../assets/images/logo.svg').default} size={17}></Avatar>
                <p className="menu-head">App</p>
            </Space>
            <Menu.Item key={'10'}> <a href="https://syllo.co/privacy.html">Privacy Policy</a></Menu.Item>
            <Menu.Item key={'11'}><a href="https://syllo.co/terms-of-services.html">Terms and Conditions</a></Menu.Item>
            <Menu.Item key={'12'}><a href="https://syllo.co/about.html">About</a></Menu.Item>

        </Menu>
    )

    return (



        <Row className="">

            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }} md={{ span: 22, offset: 1 }} className="md35">
                <Row >
                    <Col span={24} className="m-text-center">
                        <Space aria-orientation="horizontal">
                            <GearSix size={26} color="#000000" />
                            <p className="m-0 fs16">Settings</p>
                        </Space>
                    </Col>
                </Row>
                <Row className="settings-card md30 m-settings-card">
                    <Col xs={{ span: 0 }} lg={{ span: 6 }} md={{ span: 6 }} className='border-right py40'>
                        {settings_menu}
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 0 }} md={{ span: 0 }} className='mb-2'>
                        <Button onClick={() => setOpenDrawer(true)} type="default" icon={<MenuFoldOutlined />}></Button>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 18 }} md={{ span: 18 }} >
                        {current === '1' && <Profile setting={setting} loader={loader}></Profile>}
                        {current === '2' && <ChangePassword />}
                        {current === '3' && <BankAccounts />}
                        {current === '4' && <TagPreferences />}
                        {current === '5' && <PushNotification />}
                        {current === '6' && <InsightsProfile />}
                        {current === '7' && <PostProfile />}
                        {/* {current === '13' && <Payments />} */}
                        {current === '14' && <Subscription />}
                        {current === '15' && <MembershipTierRedirect />}
                        {current === '18' && <EmailSettings />}
                        {current === '16' && <SettingSubscriptions />}
                        {current === '8' && <SettingConnect />}
                        {current === '9' && <Connection />}

                    </Col>

                    <Drawer size='default' placement="left" onClose={() => setOpenDrawer(false)} visible={openDrawer} >
                        <Row>
                            <Col span={24} className="px-3 md20">
                                {settings_menu}

                            </Col>
                        </Row>
                    </Drawer>


                </Row>
            </Col>

        </Row>
    );
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
    EventDetails: (v) => dispatch(actions.EventDetails(v)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Settings)
);
